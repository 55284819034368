import React, {FC} from "react"

import GlobalFormErrorMessage
  from "../../../components/commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"

import {useParseV4ErrorTypeSearchParamToErrorMessage} from "./V4SearchParamErrorMessage.hook"

const V4SearchParamErrorMessage: FC = () => {
  const errorMessage = useParseV4ErrorTypeSearchParamToErrorMessage()

  return errorMessage
    ? (
      <GlobalFormErrorMessage
        message={errorMessage}
      />
    ) : null
}

export default V4SearchParamErrorMessage
