import React, {Dispatch, FC} from "react"
import { useTranslation } from "react-i18next"
import { Typography } from "@material-ui/core"

import AppDialog from "../common/appDialog/AppDialog.component"
import LoginAccountsTable from "./LoginAccountsTable.component"
import {LoginAccount} from "../../store/loginAccounts/loginAccounts.types"

type LoginAccountsModalProps = {
  open: boolean;
  handleClose: VoidFunction;
  users: LoginAccount[];
  chooseAccount: Dispatch<LoginAccount>;
}

const LoginAccountsModal: FC<LoginAccountsModalProps> = ({
  open,
  handleClose,
  users,
  chooseAccount
}) => {
  const {t} = useTranslation()

  return (
    <AppDialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      title={t("login:chooseAccount")}
    >
      <Typography>
        {t("login:chooseAccountInfo")}
      </Typography>

      <LoginAccountsTable
        users={users}
        chooseAccount={chooseAccount}
      />
    </AppDialog>
  )
}

export default LoginAccountsModal
