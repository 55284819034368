import React, { FC, useState } from "react"
import {FormProvider, useForm} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {
  Box,
  Button,
  Typography,
} from "@material-ui/core"
import {AxiosError} from "axios"
import dayjs from "dayjs"

import api from "../../api/api"
import {putResetPasswordConfig} from "../../api/routes"
import useGetFormValidationRules from "../../hooks/useGetFormValidationRules"
import { analytics, LogEventType } from "../../services/analytics"
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader.component"
import PageSection from "../common/pageSection/PageSection.component"
import DatePicker from "../commonFormItems/datePicker/DatePicker.component"
import GlobalFormErrorMessage from "../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import PasswordInputController from "../commonFormItems/passwordInputController/PasswordInputController.component"
import {handleReactHookFormErrors, redirectToError500Page} from "../../utils/handleErrors"
import {ResetPasswordSession} from "../../pages/forgotPassword/ForgotPasswordPage.types"
import { SendNewPasswordFormValues } from "./ForgotPassword.types"
import {useForgotPasswordStyles} from "./ForgotPassword.styles"

import ForgotPasswordTitleNode from "./common/ForgotPasswordTitleNode"
import {userPasswordResetValidationSchema} from "./ForgotPassword.validation"

interface SendNewPasswordFormProps {
  session: ResetPasswordSession;
  setSuccessPasswordChanged: (success: boolean) => void;
}

// in <form> tag should not be any other inputs than 'plainPassword' and 'plainPasswordRepeat' to prevent autofill username in browser save password prompt
const SendNewPasswordForm: FC<SendNewPasswordFormProps> = ({session, setSuccessPasswordChanged}) => {
  const { t, i18n } = useTranslation()
  const classes = useForgotPasswordStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const [globalError, setGlobalError] = useState<AxiosError | null>(null)
  const globalErrorMessage = globalError?.response?.data.detail
    ? t(`errors:${globalError?.response?.data.detail}`)
    : t("errors:unknownError")

  const form = useForm<SendNewPasswordFormValues>({
    mode: "all",
    resolver: useGetFormValidationRules(userPasswordResetValidationSchema(session.birthdate_required)),
    defaultValues: {
      birthdate: "",
      plainPasswordRepeat: "",
      plainPassword: "",
    }
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    setGlobalError(null)
    setLoading(true)

    try {
      await api.request({
        ...putResetPasswordConfig(session), data: {
          plain_password: values.plainPassword,
          lang: i18n.language,
          reset_type: 1,
          birthdate: session.birthdate_required ? dayjs(form.getValues("birthdate")).format("YYYY-MM-DD") : undefined,
        }
      })
      setLoading(false)
      setSuccessPasswordChanged(true)
      analytics.sendEventWithDefaultParams(LogEventType.PATIENTS_CHANGE_PASSWORD_SUCCESS)
    } catch (e) {
      const errorLog = {
        "error_code": e.response?.status,
        "error_name": e.response?.data?.detail,
        "login_name": session.username,
      }
      analytics.sendEventWithDefaultParams(LogEventType.PATIENTS_CHANGE_PASSWORD_ERROR, errorLog)
      console.error(e)
      redirectToError500Page(e)

      // set errors associated with form fields
      if (e.response?.data?.errors) {
        for (const [fieldName, fieldValue] of Object.entries(e.response.data.errors)) {
          const message = (fieldValue as string[]).map(errorMessage => t(`errors:${errorMessage}`)).join("\u000A")
          form.setError(
            fieldName as keyof SendNewPasswordFormValues,
            {
              type: "manual",
              message
            })
        }
        handleReactHookFormErrors<SendNewPasswordFormValues>(e.response.data.errors, form)
      }
      // e/o errors associated with form fields

      // Some errors are not associated with form fields, we call them "global"
      // They stay out of form scope, so we keep them in useState hook
      if (e.response?.data?.detail) {
        setGlobalError(e)
      }
      // e/o "global" errors

      setLoading(false)
    }
  })

  return (
    <PageSection titleNode={
      <ForgotPasswordTitleNode title="enterNewPassword"/>
    }>
      <Box className={classes.sectionBox}>
        <Box mb={1}>
          <Typography variant={"body1"}>
            <Trans
              i18nKey={
                session.birthdate_required
                  ? "forgotPassword:writeYourNewPasswordAndBirthdate"
                  : "forgotPassword:writeYourNewPassword"
              }
            >
              0 <strong>1</strong> 2
            </Trans>
          </Typography>
        </Box>

        {globalError && (
          <GlobalFormErrorMessage
            message={globalErrorMessage}
          />
        )}

        {
          session.birthdate_required && (
            <DatePicker
              value={form.watch("birthdate") ?? ""}
              onChange={(value) => {
                form.setValue("birthdate", value?.toString() ?? "")
                if (value) {
                  form.clearErrors("birthdate")
                }
              }}
              className={classes.birthDateInput}
              name='birthdate'
              disableFuture
              autoOk
              fullWidth={false}
              views={["year", "month", "date"]}
              okLabel={null}
              openTo="year"
              helperText=""
              error={false}
              format="yyyy-MM-dd"
              errorMessage={form.formState.errors.birthdate?.message}
              DialogProps={{
                className: classes.birthDateCalendar,
              }}
              InputProps={{
                className: classes.birthDateInput
              }}
            />
          )
        }

        <FormProvider {...form}>
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
          >
            <PasswordInputController
              name="plainPassword"
              placeholder={t("user:plainPassword")}
              label={t("user:plainPassword")}
            />
            <PasswordInputController
              name="plainPasswordRepeat"
              placeholder={t("user:plainPasswordRepeat")}
              label={t("user:plainPasswordRepeat")}
            />
            <Box
              display="flex"
              justifyContent="flex-end"
              mt={3}
            >
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                type="submit"
                startIcon={loading && <ButtonLoader position="prefix"/>}
              >
                {t("send")}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </PageSection>
  )
}

export default SendNewPasswordForm
