import React, {FC} from "react"
import {Box, BoxProps, CircularProgress} from "@material-ui/core"

const Loader: FC<BoxProps> = (props) => {

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <CircularProgress
        color={"primary"}
      />
    </Box>
  )
}

export default Loader
