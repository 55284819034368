import {useEffect, useState} from "react"
import {useDispatch} from "react-redux"

import {setChosenLaboratory} from "../../../../store/medicalExamsPoints/medicalExamsPoints.slice"
import {MedicalExamPoint} from "../../../../store/medicalExamsPoints/medicalExamsPoints.types"

const INCREMENT_VALUE = 3

export const useAlabListManger = (laboratories: MedicalExamPoint[]): {
  visibleLaboratoryNumber: number,
  handleIncrementLaboratoryNumber: VoidFunction
} => {
  const [visibleLaboratoryNumber, setVisibleLaboratoryNumber] = useState(INCREMENT_VALUE)

  useEffect(() => {
    setVisibleLaboratoryNumber(INCREMENT_VALUE)
  }, [laboratories, setVisibleLaboratoryNumber])
  
  const handleIncrementLaboratoryNumber =  () => {
    setVisibleLaboratoryNumber(previousState => previousState + INCREMENT_VALUE)
  }

  return {
    visibleLaboratoryNumber,
    handleIncrementLaboratoryNumber
  }
}

export const useLaboratoryManager = (): {
  handleChoseLaboratory: (laboratory: MedicalExamPoint) => VoidFunction
} => {
  const dispatch = useDispatch()

  const handleChoseLaboratory = (laboratory: MedicalExamPoint) => () => {
    dispatch(setChosenLaboratory(laboratory))
  }

  return {
    handleChoseLaboratory
  }
}