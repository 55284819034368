import React, {FC, useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { CancelTokenSource } from "axios"

import api from "../../api/api"
import { getPaymentConfig } from "../../api/routes"
import useInterval from "../../hooks/useInterval"
import { selectUser } from "../../store/user/user.selectors"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import { useSuccessfulPaymentPageStyles } from "../../components/successfulPayment/SuccessfulPayment.styles"

type PaymentStatusPageParams = {
  paymentId: string;
}

const CHECK_PAYMENT_STATUS_DELAY = 5000
const CHECK_PAYMENT_STATUS_LIMIT = 10

const PaymentStatusPage: FC = () => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [checkLimitCount, setCheckLimitCount] = useState<number>(1)
  const userData = useSelector(selectUser)
  const { paymentId } = useParams<PaymentStatusPageParams>()
  const classes = useSuccessfulPaymentPageStyles()
  const { push } = useHistory()

  const checkPaymentStatus = async () => {
    const response = await getPaymentStatus()
    let isPaid = null

    if (response?.success === true) {
      isPaid = true
    }

    if (response?.failed === true) {
      isPaid = false
    }

    setCheckLimitCount(checkLimitCount + 1)

    if (isPaid !== null || checkLimitCount === CHECK_PAYMENT_STATUS_LIMIT) {
      goToPaymentSummaryPage(isPaid || false, response?.voucherCodeToBuy)
    }
  }

  const { start, stop } = useInterval(checkPaymentStatus, CHECK_PAYMENT_STATUS_DELAY)

  const goToPaymentSummaryPage = (isPaid: boolean, voucherCodeToBuy?: string|null) => {
    if (voucherCodeToBuy) {
      const route = isPaid ? `/${i18n.language}/voucher/payment/success` : `/${i18n.language}/voucher/payment/declined`
      push(route + `?voucherCode=${voucherCodeToBuy}`)
      
      return
    }

    push(isPaid ? `/${i18n.language}/payment/success` : `/${i18n.language}/payment/declined`)
  }

  const getPaymentStatus = async (cancelToken?: CancelTokenSource["token"]) => {
    if (!userData) {
      return null
    }

    try {
      const { data } = await api.request({
        ...getPaymentConfig(paymentId),
        cancelToken,
      })

      return data
    } catch (e) {
      if (api.isCancel(e)) return
    }
  }

  useEffect(() => {
    start()
    return (): void => {
      stop()
    }
  }, [start, stop])

  useEffect(() => {
    if (userData) {
      checkPaymentStatus()
    }
  }, [userData])

  return (
    <Box
      className={classes.pageSection}
      component="section"
    >
      <Box className={classes.content}>
        <Typography
          variant={isMdUp ? "h3" : "h5"}
          component="h2"
          className={classes.title}
        >
          { t("payments:checkingStatus") }
        </Typography>
        <Box>
          <LoaderBox />
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentStatusPage
