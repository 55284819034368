import { Theme } from "@material-ui/core"

import {boxShadows} from "../../../themes/shadows"
import {createStyles, makeStyles} from "@material-ui/core/styles"

export const useDocumentationsTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      boxShadow: boxShadows.shadow1,
    },
    table: {
      [theme.breakpoints.up("sm")]: {
        minWidth: 650,
        maxWidth: "100%",
      },
    },
    tableRow: {
      maxWidth: "100%",
    },
    noBorderRow: {
      "& td, & th": {
        borderBottom: "none"
      }
    }
  })
)
