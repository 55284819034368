import theme from "../../../themes/default/theme"

export const stripeInputStyles = {
  style: {
    base: {
      fontSize: `${theme.spacing(2)}px`,
      fontWeight: 400,
      color: theme.palette.text.primary,
      fontFamily: [
        "'Ping 4'",
        "Arial",
        "sans-serif",
      ].join(),
      "::placeholder": {
        color: theme.palette.grey[200],
        fontFamily: "sans-serif, Arial",
        fontWeight: 300,
      },
    },
    invalid: {
      color: theme.palette.text.primary,
    }
  },
}

export const stripeInputFont = [{
  src: "url(https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011668-002844-592567efceab69f64c9d65225e4009a2.woff)",
  family: "'Ping 4'",
}]
