import React, { FC, useEffect, useRef, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ApolloError } from "@apollo/client"
import {
  Box,
  Button,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import * as yup from "yup"

import useGetFormValidationRules from "../../../../../hooks/useGetFormValidationRules"
import { AddBloodPressureResponse } from "../../../api/mutations/addBloodPressure"
import { AddGlycemiaResponse } from "../../../api/mutations/addGlycemia"
import { AddPulseResponse } from "../../../api/mutations/addPulse"
import { AddWaistResponse } from "../../../api/mutations/addWaist"
import { AddWeightResponse } from "../../../api/mutations/addWeight"
import CloseButton from "../../../../../components/common/closeButton/CloseButton.component"
import DatePickerController from "../../../../../components/commonFormItems/datePickerController/DatePickerController.component"
import TextFieldController from "../../../../../components/commonFormItems/textFieldController/TextFieldController.component"
import ControllerKeyboardTimePicker from "./controllerKeyboardTimePicker/ControllerKeyboardTimePicker.component"
import { GenericInputData } from "../DiaryMeasurement.types"
import { useDiaryMeasurementDataInputStyles } from "./DiaryMeasurementDataInput.styles"

interface DiaryMeasurementDataInputProps {
  getRecords: () => void;
  validationSchema: () => yup.AnySchema;
  addMeasurement: (value: MaterialUiPickersDate, input: string[]) => void;
  data: AddGlycemiaResponse | AddBloodPressureResponse | AddPulseResponse | AddWaistResponse | AddWeightResponse | undefined | null;
  loading: boolean;
  error: ApolloError | undefined;
  genericInputData: GenericInputData[];
  inputTitle: string;
}

const DiaryMeasurementDataInput: FC<DiaryMeasurementDataInputProps> = ({
  getRecords,
  validationSchema,
  addMeasurement,
  data,
  loading,
  error,
  genericInputData,
  inputTitle,
}) => {
  const {t, i18n} = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const {
    button,
    closeBtn,
    adornment,
    inputWrapper,
    rootDialog,
  } = useDiaryMeasurementDataInputStyles()
  const datePickerButtonRef = useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const setDefaultValues = (inputData: GenericInputData[]) => {
    return inputData.map( ({ name }) => name).reduce<Record<string, string>>((acc, key) => {
      acc[key] = ""
      return acc
    }, {})
  }
  
  const formMethods = useForm({
    defaultValues: {
      inputs: setDefaultValues(genericInputData),
      time: new Date()
    },
    mode: "all",
    resolver: useGetFormValidationRules(() => yup.object().shape({
      inputs: validationSchema(),
      time: yup.date()
        .required(i18n.t("errors:required"))
        .typeError(i18n.t("errors:invalidTime"))
    })),
  })

  const handleSubmit = async (date: MaterialUiPickersDate) => {
    const time = formMethods.getValues("time")

    if (!date || !time) {
      return
    }

    const isValid = await formMethods.trigger(
      [...genericInputData.map( ({ name }) => `inputs.${name}` as const), "time"]
    )
    const input = formMethods.getValues(genericInputData.map( ({ name }) => `inputs.${name}` as const))
    const value = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes()
    )

    if(isValid) {
      addMeasurement(value, input)
    }
  }

  useEffect(() => {
    if(data) {
      setIsOpen(false)
      formMethods.reset()
      getRecords()
    }
  }, [data, loading, error])

  return (
    <FormProvider {...formMethods}>
      <form>
        <DatePickerController
          okLabel={t("healthPlanner:add")}
          cancelLabel=""
          onAccept={handleSubmit}
          name="addMeasurementDatePicker"
          open={isOpen}
          disableFuture
          DialogProps={{
            className: rootDialog
          }}
          orientation="portrait"
          ToolbarComponent={() => {
            return (
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant={isMdUp ? "h3" : "h4"}
                    component="h3"
                  >
                    {inputTitle}
                  </Typography>
                  <CloseButton
                    className={closeBtn}
                    onClick={() => {
                      setIsOpen(false)
                      setTimeout(()=>{
                        formMethods.reset()
                      },500)
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  {genericInputData.map( ({ name, unit }) => {
                    return (
                      <TextFieldController
                        key={name}
                        name={`inputs.${name}`}
                        label={t(`healthPlanner:measurementLabels:${name}`)}
                        className={inputWrapper}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment className={adornment} position="start">{unit}</InputAdornment>,
                        }}
                      />
                    )
                  })}
                  <ControllerKeyboardTimePicker/>
                </Box>
              </Box>
            )
          }}
          TextFieldComponent={() => {
            return (
              <Button
                ref={datePickerButtonRef}
                className={button}
                color="primary"
                variant="outlined"
                onClick={() => setIsOpen(true)}
              >
                {t("healthPlanner:addMeasurement")}
              </Button>
            )
          }}
        />
      </form>
    </FormProvider>
  )
}

export default DiaryMeasurementDataInput