import {gql} from "@apollo/client"

export const GET_LAST_MESSAGE_FOR_THREAD = gql`
  subscription getLastMessageForThread ($threadId: uuid!) {
    chatMessagesAggregate: chat_messages_aggregate(
      limit: 1,
      order_by: {created_at: desc},
      where: {thread_id: {_eq: $threadId}}
    ) {
      nodes {
        id
        body
      }
    }
  }
`