export interface ConsultationChatNewItem {
  consultationId: string;// "2020-06-26T12:48:00+02:00";
  createdAt: number;// 1627477072; seconds (not miliseconds!) since January 1 1970
  message: ChatMessageMessageType | string; // "chat.consultation_closed"
  messageRaw: string;// "Utworzono konsultację na: 2021-08-12 16:00 z: Alergolog stacjonarny"
  messageRawType: ChatMessageType; // 2
  messageType: ChatMessageType; // 2
  messageText?: string;
  type: number; // unused
  sender: null | string;// ID OSOBY WYSYŁAJĄCEJ (null is in messages from system)
}

export interface ConsultationChatItem extends ConsultationChatNewItem {
  // only from chat/history endpoint
  id?: string; // "f260caa3-5e76-4445-8042-0d0abd3044c6";
  datetime?: string; // "2021-07-28 14:57:52"
}

export enum ChatMessageType {
  OWN_NORMAL = 0, // USER + DOCTOR MESSAGE (from pusher event new message)
  USER = 1, // USER + DOCTOR MESSAGE (from history messages)
  SYSTEM = 2, // SYSTEM MESSAGE
  HELP = 3, // ONLY PATIENT MESSAGE, CONSULTATION DETAILS
  RECOMMENDATIONS_LINK = 4, // FILE MESSAGE
  FILE = 5, // FILE MESSAGE (PDF)
  AI_QUESTION = 6, // unused
  PRESCRIPTION = 7, // FILE MESSAGE
  REFERRAL = 8, // FILE MESSAGE
  GIVE_SYMPTOMS = 10, // unused
  NORMAL_BOOT = 11, // unused
  BEGIN_DOCTOR_SUMMARY = 12, // unused
  BEGIN_DOCTOR_FILES = 13, // unused
  EPRESCRIPTION = 14, // FILE MESSAGE
  WELCOME = 15, // SYSTEM MESSAGE
  TRANS_PRESCRIPTION = 16, // FILE MESSAGE
  AI_QUESTIONAIRE = 17, // unused
  AI_QUESTIONAIRE_MESSAGE = 18, // unused
  SELECT_LANGUAGE = 19, // SYSTEM MESSAGE
  LOG_TRANSLATION = 20, // unused
  PDF = 21, // FILE MESSAGE
  PHOTO = 22, // IMAGE MESSAGE
  VIDEO_REQUEST = 23, // unused
  EXAMINATION_CART = 25, // unused (exams shopping cart box)
  EXAMINATION_CART_PAID = 26, // unused
  EZLA = 27, // FILE MESSAGE
  EREFERRAL = 28, // E-REFERRAL
  MEDICAL_SURVEY_RESULTS = 33, // MEDICAL SURVEY ANSWERS - EPRESCRIPTION
  CONSULTATION_REASONS = 34, // CONSULTATION REASONS - SURVEY
  SICK_LEAVE_DETAILS_MESSAGE = 35, // SICK LEAVE - PERIOD
  SICK_LEAVE = 36, // SICK LEAVE - SURVEY
  CONSULTATION_SURVEY = 38 // CONSULTATION - SURVEY
}

export enum ChatMessageMessageType {
  START_MESSAGE = "consultation.detailed_created_log",
  CHAT_CLOSED_MESSAGE = "chat.consultation_closed",
  NEW_SENDING_PATIENT_MESSAGE = "new-patient-message-sending", // frontend value for discriminate sending message
  CHAT_REOPENED_MESSAGE = "consultation.message.reopened",
  E_ZLA_ISSUED = "chat.doctor_issue_ezla.inability",
  CHANGE_TERM = "magic_link.change_term.patient_consultation_message",
}

export enum ChatEventType {
  NEW_MESSAGE = "client-message",
  CLIENT_VIDEO_INVITE = "client-video-invite",
  CLIENT_VIDEO_CLOSE = "client-video-close",
  CLIENT_CONSULTATION_ENDED = "end", // unused (closed by doctor)
  CLIENT_TYPING = "client-typing-message", // unused
  DOCTOR_TYPING = "client-is-typing", // doctor-typing event debounced by 3-6seconds
  DOCTOR_DATA = "doctor-data",
  PUSHER_CHANNEL_SUBSCRIPTION_SUCCEEDED = "pusher:subscription_succeeded",
}

export enum ChatTriggerEventType {
  INIT_VIDEO = "client-video-android", // prevent the stream from disconnecting after 30s (V4 bugs)
  CLOSE_VIDEO_CHAT = "client-video-close",
}

export interface PusherKey {
  key: string;
}

export enum VideoChatTypes {
  TWILIO_VIDEO = "twilio_video",
  OPEN_TOK  = "open_tok"
}

export interface InstantChatDoctorDataType {
  doctorName?: string;
  avatarUrl?: string;
}
