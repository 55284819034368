import React from "react"
import { useTranslation } from "react-i18next"
import {useHistory, useParams} from "react-router"
import { push } from "connected-react-router"
import i18next from "i18next"

import api from "../../api/api"
import { postChatMessageConfig } from "../../api/routes"
import { useAppSelector } from "../../hooks/storeHooks"
import store from "../../store"
import { selectUser } from "../../store/user/user.selectors"
import { Gender } from "../../store/user/user.types"
import { ChatMessageType } from "../consultationChat/ConsultationChat.types"
import {medicalSurveyData, medicalSurveyFields, surveyExtraQuestions} from "./MedicalSurvey.types"

const inputFields = [
  "alergyInput",
  "chemicalsInput",
  "chronicDiseasesInput",
  "cerebralIschemicAttackInput",
  "drugsFieldInput",
  "heartValveDiseaseInput",
  "immobilizationInput",
  "inflammationOfTheIntestinesInput",
  "metabolicDisordersInput",
  "migraineInput",
  "myocardialInfarctionInput",
  "pancreaticDiseasesInput",
  "prohibitionOfHormonalContraceptionInput",
  "smokingInput",
  "strokeInput",
  "thromboembolismInput",
  "tumorsInput",
  "venousDiseaseInFamilyInput",
  "venousDiseaseInput",
  "chestPainsInput"
]

const EXTRA_QUESTION_PREFIX = "extraQuestion"

export const useMedicalSurvey = () => {
  const { t } = useTranslation()
  const user = useAppSelector(selectUser)
  const history = useHistory()

  const { consultationId } = useParams<{ consultationId: string }>()

  const getUserGender = () => user?.gender != null ? t(`user:${Gender[user?.gender]}`) : ""

  const sendMessage = async (
    message: string,
    setSaving: React.Dispatch<React.SetStateAction<boolean>>,
    medicalSurveyData: medicalSurveyData[] | null,
    values: medicalSurveyFields,
    isBasicType: boolean,
    manualPrescription: boolean,
    medicineCategory?: string,
  ) => {
    try {
      await api.request({
        ...postChatMessageConfig,
        data: {
          consultation: consultationId,
          messageType: ChatMessageType.MEDICAL_SURVEY_RESULTS,
          message: message.trim(),
          params: JSON.stringify({
            basic: isBasicType ? generateBasicQuestionsJson(values) : generateCustomQuestionsJson(values),
            dynamic: medicalSurveyData,
            medicine_id: medicineCategory,
            manual_prescription: manualPrescription,
          }),
        }
      })

      store.dispatch(push((`/${i18next.language}/consultation/${consultationId}`)))
      setSaving(false)
    } catch (e) {
      console.error(e)
      setSaving(false)
    }
  }

  const getTemperatureValues = (min: number, max: number) => {
    const options = []

    for(let i = min; i <= max; i++) {
      if (i === max) {
        options.push({
          value: i,
          label: `${i.toString()}`
        })
      } else {
        for(let subIndex = 0; subIndex <= 9; subIndex++) {
          options.push({
            value: i,
            label: subIndex ? `${i.toString()}.${subIndex}` : i.toString()
          })
        }
      }
    }
    return options
  }

  const getValuesInterval = (min: number, max: number, unit: string) => {
    const options = []

    for(let i = min; i <= max; i++) {
      options.push({
        value: i,
        label: `${i} ${unit}`
      })
    }
    return options
  }

  const formatBasicQuestion = (id: number, question: string, answer: string) => {
    return {
      id,
      question,
      answer
    }
  }

  const getSimpleAnswer = (answer: string) => {
    return parseInt(answer) ? "TAK" : "NIE"
  }

  const generateCustomQuestionsJson = (values: medicalSurveyFields) => {
    const options: medicalSurveyData[] = []
    let id = 0

    for (const key in values) {
      const fieldValueExists = values[key] !== ""

      id++

      if (!key.includes(EXTRA_QUESTION_PREFIX)) {
        if (values[key]?.label) { // get select fields data
          options.push({
            id,
            question: t(`prescription:surveyQuestions:${key}`),
            answer: values[key]?.label
          })
        } else if (inputFields.includes(key)) { // get additional information fields data
          if (fieldValueExists) {
            options.push({
              id,
              question: t(`prescription:surveyQuestions:${key}`),
              answer: values[key]
            })
          }
        } else { // get checkbox fields data
          if (fieldValueExists) {
            options.push({
              id,
              question: t(`prescription:surveyQuestions:${key}`),
              answer: getSimpleAnswer(values[key])
            })
          }
        }
      }
    }

    return options
  }

  const generateBasicQuestionsJson = (values: medicalSurveyFields) => {
    const basicQuestionsList = []

    const gender = formatBasicQuestion(1, t("user:gender"), getUserGender())
    const pregnantAnswer = formatBasicQuestion(2, t("prescription:surveyQuestions:pregnant"), getSimpleAnswer(values.pregnant))
    const breastFeedAnswer = formatBasicQuestion(3, t("prescription:surveyQuestions:breastFeed"), getSimpleAnswer(values.breastFeed))
    const temperatureAnswer = formatBasicQuestion(4, t("prescription:temperaturPlaceholder"), values?.temperature?.label)
    const weightAnswer = formatBasicQuestion(5, t("prescription:weightPlaceholder"), values?.weight?.label)
    const heightAnswer = formatBasicQuestion(6, t("prescription:heightPlaceholder"), values?.height?.label)
    const chronicDiseasesAnswer = formatBasicQuestion(7, t("prescription:surveyQuestions:chronicDiseases"), getSimpleAnswer(values.chronicDiseases))
    const chronicDiseasesAnswerText = formatBasicQuestion(8, t("prescription:surveyQuestions:chronicDiseasesTextFieldLabel"), values.chronicDiseasesInput)
    const smokingAnswer = formatBasicQuestion(9, t("prescription:surveyQuestions:smoking"), getSimpleAnswer(values.smoking))
    const smokingAnswerText = formatBasicQuestion(10, t("prescription:surveyQuestions:smokingInput"), values.smokingInput)
    const drugsAnswer = formatBasicQuestion(11, t("prescription:surveyQuestions:drugs"), getSimpleAnswer(values.drugs))
    const drugsAnswerText = formatBasicQuestion(12, t("prescription:surveyQuestions:drugsTextFieldLabel"), values.drugsInput)
    const treatmentContinuationAnswer = formatBasicQuestion(13, t("prescription:surveyQuestions:treatmentContinuation"), getSimpleAnswer(values.treatmentContinuation))
    const alergyAnswer = formatBasicQuestion(14, t("prescription:surveyQuestions:alergy"), getSimpleAnswer(values.alergy))
    const alergyAnswerText = formatBasicQuestion(15, t("prescription:surveyQuestions:alergyTextFieldLabel"), values.alergyInput)
    const importantInformations = formatBasicQuestion(16, t("prescription:surveyQuestions:importantInformations"), values.importantInformations)

    basicQuestionsList.push(
      gender,
      pregnantAnswer,
      breastFeedAnswer,
      temperatureAnswer,
      weightAnswer,
      heightAnswer,
      chronicDiseasesAnswer,
      chronicDiseasesAnswerText,
      smokingAnswer,
      smokingAnswerText,
      drugsAnswer,
      drugsAnswerText,
      treatmentContinuationAnswer,
      alergyAnswer,
      alergyAnswerText,
      importantInformations,
    )

    return basicQuestionsList
  }

  const generateDynamicQuestionsJson = (questions: string[]) => {
    let questionsList = ""

    questions.map(item => {
      questionsList += `${item}\n`
    })

    return questionsList
  }

  const createCustomMessageTemplate = (values: medicalSurveyFields, medicineData: surveyExtraQuestions[]) => {
    const dynamicQuestions: string[] = []
    let msgTemplate = ""

    medicineData?.map((item: surveyExtraQuestions) => {
      dynamicQuestions.push(`${item.question}: ${parseInt(values[`extraQuestion_${item.id}`]) ? "TAK" : "NIE"}\n`)
    })

    for (const key in values) {
      const fieldValue = values[key]
      const fieldValueExists = fieldValue !== ""

      if (!key.includes(EXTRA_QUESTION_PREFIX)) {
        if (fieldValue?.label) { // get select fields data
          msgTemplate += `${t(`prescription:surveyQuestions:${key}`)} ${fieldValue?.label}\n`
        } else if (inputFields.includes(key)) { // get additional information fields data
          if (fieldValueExists) {
            msgTemplate += `${t(`prescription:surveyQuestions:${key}`)}: ${fieldValue}\n`
          }
        } else { // get checkbox fields data
          if (fieldValueExists) {
            msgTemplate += `${t(`prescription:surveyQuestions:${key}`)}: ${getSimpleAnswer(fieldValue)}\n`
          }
        }
      }
    }

    msgTemplate += generateDynamicQuestionsJson(dynamicQuestions)

    return msgTemplate
  }

  const createMessageTemplate = (values: medicalSurveyFields, medicineData: surveyExtraQuestions[]) => {
    const dynamicQuestions: string[] = []

    const gender = `${t("user:gender")}: ${getUserGender()}`
    const pregnantAnswer = `${t("prescription:surveyQuestions:pregnant")}: ${getSimpleAnswer(values.pregnant)}`
    const breastFeedAnswer = `${t("prescription:surveyQuestions:breastFeed")}: ${getSimpleAnswer(values.breastFeed)}`
    const temperatureAnswer = `${t("prescription:temperaturPlaceholder")}: ${values?.temperature?.label}`
    const heightAnswer = `${t("prescription:heightPlaceholder")}: ${values?.height?.label}`
    const weightAnswer = `${t("prescription:weightPlaceholder")}: ${values?.weight?.label}`
    const chronicDiseasesAnswer = `${t("prescription:surveyQuestions:chronicDiseases")}: ${getSimpleAnswer(values.chronicDiseases)}`
    const chronicDiseasesAnswerText = parseInt(values.chronicDiseases) ? `${t("prescription:surveyQuestions:chronicDiseasesTextFieldLabel")}: ${values.chronicDiseasesInput}` : ""
    const smokingAnswer = `${t("prescription:surveyQuestions:smoking")}: ${getSimpleAnswer(values.smoking)}`
    const smokingAnswerText = parseInt(values.smoking) ? `${t("prescription:surveyQuestions:smokingInput")}: ${values.smokingInput}` : ""
    const drugsAnswer = `${t("prescription:surveyQuestions:drugs")}: ${getSimpleAnswer(values.drugs)}`
    const drugsAnswerText = parseInt(values.drugs) ? `${t("prescription:surveyQuestions:drugsTextFieldLabel")}: ${values.drugsInput}` : ""
    const treatmentContinuationAnswer = `${t("prescription:surveyQuestions:treatmentContinuation")}: ${getSimpleAnswer(values.treatmentContinuation)}`
    const alergyAnswer = `${t("prescription:surveyQuestions:alergy")}: ${getSimpleAnswer(values.alergy)}`
    const alergyAnswerText = parseInt(values.alergy) ? `${t("prescription:surveyQuestions:alergyTextFieldLabel")}: ${values.alergyInput}` : ""
    const importantInformations = `${t("prescription:surveyQuestions:importantInformations")}: ${values.importantInformations}`

    medicineData?.map((item: surveyExtraQuestions) => {
      dynamicQuestions.push(`${item.question}: ${parseInt(values[`extraQuestion_${item.id}`]) ? "TAK" : "NIE"}\n`)
    })

    const template = `
      ${gender}\n
      ${pregnantAnswer}\n
      ${breastFeedAnswer}\n
      ${temperatureAnswer}\n
      ${heightAnswer}\n
      ${weightAnswer}\n
      ${chronicDiseasesAnswer}
      ${chronicDiseasesAnswerText}
      ${smokingAnswer}
      ${smokingAnswerText}\n
      ${drugsAnswer}
      ${drugsAnswerText}\n
      ${treatmentContinuationAnswer}\n
      ${alergyAnswer}
      ${alergyAnswerText}\n
      ${generateDynamicQuestionsJson(dynamicQuestions)}
      ${importantInformations}
    `
    
    return template
  }

  const parseAndGetSearchParameters = () => {
    const decodedSearchParams = decodeURIComponent(history.location.search).replace("&amp;", "&")
    history.replace(`${history.location.pathname}${decodedSearchParams}`)
    const searchParams = new URLSearchParams(history.location.search)
    const detailedSource = searchParams.get("detailedSource")
    const utmSource = searchParams.get("utm_source")
    return {
      detailedSource,
      utmSource
    }
  }

  return {
    createMessageTemplate,
    createCustomMessageTemplate,
    sendMessage,
    consultationId,
    getValuesInterval,
    getTemperatureValues,
    parseAndGetSearchParameters
  }
}
