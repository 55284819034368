import {gql} from "@apollo/client"

export const GET_CLINIC_ID = gql`
  query getClinicSettings($patientDashboardUrl: String!) {
    clinics(dashboardUrl: $patientDashboardUrl) {
      edges {
        node {
          frontendSettings {
            clinicId
          }
        }
      }
    }
  }
`
