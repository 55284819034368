import {createStyles, makeStyles} from "@material-ui/core"

export const useAlabSearchEngineStyles = makeStyles(theme => createStyles({
  root: {
    maxWidth: theme.spacing(75)
  },
  listWrapper: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    }
  }
}))