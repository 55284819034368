import {createStyles, makeStyles} from "@material-ui/core"

export const useActionButtonStyles = makeStyles(theme => createStyles({
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
}))