import {createStyles, makeStyles, Theme} from "@material-ui/core"

type Props = {
  size?: "small" | "large"
  probability: number
}

export const useRiskLevelStyles = makeStyles<Theme, Props>((theme) => createStyles({
  container: {
    marginTop: theme.spacing(1.5),
    position: "relative",
    width: "100%",
    maxWidth: (props) => props.size === "small"
      ? "initial"
      : 280,
    height: 8,
    borderRadius: 5,
    background: "linear-gradient(90deg, #33BE88 0%, rgba(231, 140, 74, 0.62) 61.98%, rgba(222, 39, 39, 0.5) 94.27%)"
  },
  indicator: {
    position: "absolute",
    width: 20,
    height: 2,
    top: "50%",
    left: (props) => props.probability * 100 * 0.8 + "%",
    borderRadius: 5,
    background: `
      linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(271.66deg, #3DBD87 -11.44%, #EF9393 104.3%)
    `,
    transform: "rotate(90deg)"
  },
  labelsWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    maxWidth: (props) => props.size === "small"
      ? "initial"
      : 280,
    alignItems: "space-between",
    justifyContent: "space-between",
    marginTop: theme.spacing(1.5)
  }
}))