import { boxShadows } from "../../../../../../../themes/shadows"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const MINIFY_IMAGE_HEIGHT = 180

export const useDoctorChatMessageStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageItemBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(5),
      },
    },
    cloudMessageWrapper: {
      flex: "1",
      background: theme.palette.background.paper,
      height: "100%",
      padding: theme.spacing(3, 3.5),
      borderRadius: theme.shape.borderRadius,
      boxShadow: boxShadows.shadow1,
      wordBreak: "break-word",
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(4, 5),
        width: "unset",
      },
    },
    messageTime: {
      color: theme.palette.grey["200"],
    },
    doctorCloudMessageWrapper: {
      position: "relative",
      paddingRight: theme.spacing(5),
      marginRight: theme.spacing(5),
      [theme.breakpoints.up("md")]: {
        paddingRight: theme.spacing(9),
        marginRight: theme.spacing(9),
      }
    },
    doctorImage: {
      position: "absolute",
      width: theme.spacing(8),
      height: theme.spacing(8),
      top: theme.spacing(-1),
      right: theme.spacing(-4),
      [theme.breakpoints.up("md")]: {
        right: theme.spacing(-6.5),
        width: theme.spacing(13),
        height: theme.spacing(13),
      }
    },
    leftAlignBoxMessage: {
      justifyContent: "flex-start",
    },
  })
)
