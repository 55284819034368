import React, {FC} from "react"
import {Controller} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box, Checkbox, FormControlLabel, Typography} from "@material-ui/core"

import GlobalFormErrorMessage from "../../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import {useOrderMedicinesFormStyles} from "../OrderMedicinesForm.styles"

interface OrderMedicinesTermsProps {}

const OrderMedicinesTerms: FC<OrderMedicinesTermsProps> = () => {
  const classes = useOrderMedicinesFormStyles()
  const {t} = useTranslation()

  return (
    <Controller
      name="terms_and_conditions"
      render={({
        field: {onChange},
        fieldState: {error},
      }) => (
        <Box className={classes.terms}>
          { error && (
            <GlobalFormErrorMessage message={error?.message || ""}/>
          )}
          <FormControlLabel
            control={
              <Checkbox
                onChange={onChange}
                className={classes.termsCheckbox}
                size="medium"
              />
            }
            label={<Typography variant="body2">{t("orderMedicines:dozTerms")}</Typography>}
          />
        </Box>
      )}
    />
  )
}

export default OrderMedicinesTerms
