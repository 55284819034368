import i18next from "i18next"
import * as yup from "yup"

import { postalCodeSchema } from "../../../../informationPanel/fillUserDataInformationPanel/UserData.validation"

type UserAddresDataValidation = {
  // Patient values
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  country: string;
  // Parent values
  parentStreet: string;
  parentHouseNumber: string;
  parentPostalCode: string;
  parentCity: string;
  parentCountry: string;
}

type schemaType = {
  fields: {
    [fieldKey: string]: string
  }
}

export const userEditAddressDataSchema = (): yup.SchemaOf<UserAddresDataValidation> => {
  const requiredMessage = i18next.t("errors:required")

  return yup.object().shape({
    // -------------- Patient Fields --------------
    street: yup.string()
      .required(requiredMessage),
    houseNumber: yup.string()
      .required(requiredMessage),
    postalCode: postalCodeSchema("country"),
    city: yup.string()
      .required(requiredMessage),
    country: yup.string()
      .required(requiredMessage),

    // -------------- Parent Fields --------------
    parentStreet: yup.string()
      .required(requiredMessage),
    parentHouseNumber: yup.string()
      .required(requiredMessage),
    parentPostalCode: postalCodeSchema("parentCountry"),
    parentCity: yup.string()
      .required(requiredMessage),
    parentCountry: yup.string()
      .required(requiredMessage),
  }, [
    ["pesel", "pesel"],
    ["parentPesel", "parentPesel"]
  ]).required().when((values: string, schema: schemaType) => {
    for (const key in schema.fields) {
      const currentField = document.getElementById(key)

      if (currentField === null) {
        delete schema.fields[key]
      }
    }
  })
}