import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useOrderMedicinesFormStyles = makeStyles((theme) => createStyles({
  root: {
    margin: theme.spacing(5, 0, 2, 0),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3)
    }
  },
  leftColumn: {
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(0),
      borderRight: "none",
    },
  },
  rightColumn: {
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(0),
    },
  },
  terms: {
    marginTop: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(11),
    }
  },
  termsCheckbox: {
    marginRight: theme.spacing(2),
    "&.Mui-checked": {
      "& svg": {
        fill: theme.palette.primary.main,
        "& path[stroke-width]": {
          stroke: theme.palette.primary.main,
        },
        "& path[stroke-linecap]": {
          stroke: theme.palette.common.white,
        }
      },
    },
  },
  errorText: {
    marginTop: theme.spacing(2)
  },
  errorBox: {
    padding: theme.spacing(1),
    "& p": {
      fontSize: "13px",
    }
  }
}))
