import React, {FC} from "react"
import Calendar from "react-calendar"
import i18next from "i18next"

import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"

import "react-calendar/dist/Calendar.css"

interface CalendarBoxProps {
  startDate: string;
  endDate: string;
}

const CalendarBox: FC<CalendarBoxProps> = ({startDate, endDate}) => {
  const { language } = i18next

  const getArrowIcon = (iconClass: string) => <IconByIntegrationType {...{ component: "span", variant: "h3" }}
    iconName={iconClass}
    returnTypography={true}
  />

  const formatDateRange = () => {
    const dateFrom = startDate.split("-")
    const dateTo = endDate.split("-")

    return {
      startDate: {
        year: parseInt(dateFrom[0]),
        month: parseInt(dateFrom[1]) - 1,
        day: parseInt(dateFrom[2])
      },
      endDate: {
        year: parseInt(dateTo[0]),
        month: parseInt(dateTo[1]) - 1,
        day: parseInt(dateTo[2])
      }
    }
  }

  const dateRange = formatDateRange()

  return (
    <Calendar
      className="calendar-container"
      locale={`${language}-${language.toUpperCase()}`}
      prev2Label={null}
      next2Label={null}
      nextLabel={getArrowIcon("icon-caret-right")}
      prevLabel={getArrowIcon("icon-caret-left")}
      value={
        [
          new Date(dateRange.startDate.year, dateRange.startDate.month, dateRange.startDate.day),
          new Date(dateRange.endDate.year, dateRange.endDate.month, dateRange.endDate.day),
        ]
      }
    />
  )
}

export default CalendarBox
