import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useReferralItemsListStyles = makeStyles((theme: Theme) => createStyles({
  list: {
    listStyle: "none",
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    "& > $listItem": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  listItem: {
    "& > div": {
      borderBottom: `1px solid ${theme.palette.grey["100"]}`,
      color: theme.palette.text.primary,
    },
    "&:last-child > div": {
      border: "none",
    },
  },
  borderBottom: {
    "&:last-child > div": {
      borderBottom: "none",
    },
  },
}))
