import API from "@openreplay/tracker"

export type MakeConsultationWidgetDataState = {
  isModalOpen: boolean;
  loading: boolean;
  rendered: boolean;
  config: MakeConsultationWidgetDataConfig;
}

export type MakeConsultationWidgetDataConfig = {
  targetElementID: string;
  displayMode: ConsultationWidgetDisplayMode;
  apiKey?: string;
  apiHost?: string;
  widgetHost?: string;
  logoImage: string | null;
  splashScreen: boolean;
  showPaidVisitsOnly: boolean;
  showPrices: boolean;
  sendCookiesToV4?: boolean;
  splashScreenBackgroundImage: string;
  visitDatePicker: boolean | ConsultationWidgetVisitDatePicker;
  fullWidthLayout: boolean;
  formTitle?: string;
  formDescription: string;
  specializationID: string;
  whiteList?: string[];
  termsOfRulesAPI?: string;
  privacyPolicyAPI?: string;
  GTMDataLayerName?: string;
  quickPaidRegisterPath?: boolean;
  quickPaidRegisterPathFields?: string[];
  onDeclarationSuccess?: () => void;
  showUserSessionStatus?: boolean;
  defaultStrategy?: string;
  embeddedInDashboard?: boolean;
  additionalPaymentLinkRedirectURL?: boolean;
  dashboardHost?: string;
  language?: string;
  instantChatEnabled?: boolean;
  defaultChannel?: ConsultationChannelTypes;
  fusionAuthEnabled: boolean;
  fusionAuthHost: string;
  themeColors?: string[];
  smallSlotsEnabled: boolean;
  clinicId: string;
  medicalDrugLimit?: number;
  medicalDrugsTotalLimit?: number;
  prescriptionSpecializationId?: string;
  prescriptionExtensionSpecializationId?: string;
  enableCheckMedicineExposed?: boolean;
  signalIdunaVerificationEnabled?: boolean;
  sickLeaveSpecializationId?: string;
  telemediSubscriptionEnabled?: boolean;
  enabledTelemediGoInEprescription?: boolean;
  enabledTelemediGoInSickLeave?: boolean;
  enabledTelemediGoForInternist?: boolean;
  prescriptionForPozGlobal?: boolean;
  checkConsultationReasonAvailability?: boolean;
  pushMethod?: unknown|null;
  defaultDoctorId?: string;
  detailedSource?: string;
  openReplayTracker?: API | null;
  controlVisit?: boolean;
  showPhoneNumberOnCompleteDataView?: boolean;
  hideChangePassword?: boolean;
  pozGlobalMedicalDrugLimit?: number;
  pozGlobalMedicalDrugsTotalLimit?: number;
  otherMessagesForB2bClinicEnabled?: boolean;
  enabledS7HealthIntegration?: boolean;
  enabledAllianzEwkaIntegration?: boolean;
  enabledAllianzOnyxIntegration?: boolean;
}

export enum ConsultationChannelTypes {
  CHAT = "CHAT",
  PHONE = "PHONE",
  VIDEO = "VIDEO"
}

export enum ConsultationWidgetVisitDatePicker {
  PAID_VISIT_DATA_PICKER = "paid",
  MIXED_VISIT_DATA_PICKER = "mixed",
  CONSBOOK_VISIT_DATA_PICKER = "consbook",
  STATIONARY_VISIT_DATA_PICKER = "stationary", // POZ stationary appointment
  PRESCRIPTION_ONLY = "prescription", // prescription only consultation
  SICK_LEAVE_ONLY = "sickleave", // sick leave only consultation
  RESIGN_POZ= "resignpoz",
}

export enum ConsultationWidgetDisplayMode {
  REGULAR_DISPLAY_MODE = "regular",
  POPUP_DISPLAY_MODE = "popup",
}

export enum DetailedSourceWidgetType {
  REMOTE = "pp_kons",
  PRESCRIPTION = "pp_rec",
  SICK_LEAVE = "pp_zwol",
  RESIGN_POZ= "pp_resign",
}

/*
CONFIG - state on 12.06.2021

- `displayMode`: (default `regular`) `['regular', 'popup']` Określa sposób wyswietlania widgetu. Ustaw `popup` dla wersji w popupie, `regular` dla wersji zintegrowanej ze strukturą HTML
- `apiKey`: Klucz API Telemedico
- `apiHost`: (default `https://staging.telemedi.co`) Określa Telemedi API host
- `widgetHost`: (default `https://staging.telemedi.co`) Określa Widget API host
- `logoImage`: (default `null`) Adres URL logo
- `splashScreen`: (default `false`) Określa czy powinien wyświetlić się splash screen przed procesem rezerwacji teleporady
- `splashScreenBackgroundImage`: (default `https://widget.telemedi.netkata.io/background-image.png`) Określa obrazek tła splash screenu
- `visitDatePicker`: (default `false`) `[false, true, 'paid', 'mixed']` Określa czy jako pierwszy krok powinien wyświetlić się element z wyborem daty i godziny wizyty oraz czy wyświetlana rezerwacja jest opcją płatną, bezpłatną lub mieszaną)
- `fullWidthLayout`: (default `false`)
- `formTitle`: (default `Uzyskaj bezpłatną teleporadę`)
- `formDescription`: (default `Wypełniając formularz składasz deklarację wyboru Punktu Opieki Zdrowotnej do lekarza, pielęgniarki i położnej podstawowej opieki zdrowotnej.`)
- `specializationID`: (default `164b5f7f-b72e-4038-a67c-5308082734a9`) Domyślne ID specjalizacji, które jest domyślnie wybrane przy umawianiu konsultacji
- `whiteList`: (default `[]`) Jest to lista IDków specjalizacji, które mają się wyświetlać. Jeśli jest pusta - wyświetlają się wszystkie z API
- `termsOfRulesAPI`: (default `https://b2b.telemedi.co/tm-api/wp/v2/pages?slug=regulamin`)
- `privacyPolicyAPI`: (default `https://b2b.telemedi.co/tm-api/wp/v2/pages?slug=polityka-prywatnosci-widget`)
- `GTMDataLayerName`: (default `GTM-MP9KQBH`) Google Tag Manager data layer name
- `quickPaidRegisterPath`: (default `false`) Pozwala na szybsze umówienie wizyty płatnej dla zalogowanych i nowych użytkowników. Po jego włączeniu krok "Sprawdź swoje dane" nie pokazywany, zamiast tego od razu następuje próba umówienia wizyty.
- `quickPaidRegisterPathFields`: (default `[]`) - <strong>Wymaga ustawienia  wartości `true` dla pola `quickPaidRegisterPath`</strong> Jest to lista dodatkowych pól do szybszej ściezki umawiania wizyty. Pola `Email`, `Password`, `Phone` są wymagane na stałe i nie można ich zmieniać.
Pola dostępne do ustawienia w parametrze to:
  - `Name`
  - `Surname`
  - `Pesel`
- `onDeclarationSuccess`: (default `(widgetAPI) => {}`) Callback uruchamiany w momencie przejścia na strone sukcesu składania deklaracji POZ

E/O CONFIG
* */
