import React, {FC} from "react"
import {useController, useWatch} from "react-hook-form"
import {useTranslation} from "react-i18next"
import { useDispatch } from "react-redux"
import {Typography} from "@material-ui/core"
import {useDebounce} from "use-debounce/esm"

import {useGetNearbyPharmaciesQuery} from "../../../../store/doz/doz.api"
import {setSelectedPharmacyData} from "../../../../store/dozPharmacy/dozPharmacy.slice"
import DozErrorParser from "../../dozErrorParser/DozErrorParser.component"
import NearbyPharmaciesLoader from "../nearbyPharmaciesLoader/NearbyPharmaciesLoader.component"
import PharmacyRadioButton from "../pharmacyRadioButton/PharmacyRadioButton.component"
import {usePharmacyIdFormValueCleanUp} from "../PharmacySearchEngine.utils"
import {DozOrder, NearbyPharmacy} from "../../../../store/doz/doz.types"
import {Coordinates} from "../PharmacySearchEngine.types"

interface PharmaciesListProps {
  coordinates: Coordinates;
}

const PharmaciesList: FC<PharmaciesListProps> = ({ coordinates }) => {
  const {t} = useTranslation()
  const watched_requested_item_list = useWatch<DozOrder, "requested_item_list">({ name: "requested_item_list" })
  const [requested_item_list] = useDebounce(watched_requested_item_list, 1000)
  const dispatch = useDispatch()
  const {
    data: pharmacies = [],
    isFetching: isLoadingPharmacies,
    error,
    isError
  } =  useGetNearbyPharmaciesQuery({
    requested_item_list,
    ...coordinates
  }, {
    skip: !coordinates.gps_latitude || !coordinates.gps_longitude
  })
  const { field: { value, onChange } } = useController<DozOrder, "pharmacy_id">({ name: "pharmacy_id" })
  const handleOnChange = (pharmacy: NearbyPharmacy) => {
    onChange(pharmacy.id)
    dispatch(setSelectedPharmacyData(pharmacy))
  }

  usePharmacyIdFormValueCleanUp(pharmacies, value, onChange)

  if (isError) {
    return <DozErrorParser error={error} />
  }

  if (isLoadingPharmacies) {
    return <NearbyPharmaciesLoader/>
  }

  if (!pharmacies.length) {
    return (
      <Typography color="error">
        {t("orderMedicines:notPharmaciesFoundError")}
      </Typography>
    )
  }

  return (
    <>
      <Typography variant="h4">
        {t("orderMedicines:pharmaciesListLabel")}
      </Typography>
      {
        pharmacies.map((pharmacy) => (
          <PharmacyRadioButton
            key={pharmacy.id}
            {...pharmacy}
            isChecked={value === pharmacy?.id?.toString()}
            onClick={() => handleOnChange(pharmacy)}
          />
        ))
      }
    </>
  )

}

export default PharmaciesList
