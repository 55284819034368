import {createStyles, makeStyles} from "@material-ui/core"

export const useNavigationButtonStyles = makeStyles((theme) => createStyles({
  container: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  nextButton: {
    marginLeft: theme.spacing(1),
  }
}))
