import theme from "../../themes/default/theme"

export const responsiveCarouselSettings = {
  mobile: {
    breakpoint: { 
      min: 0, 
      max: theme.breakpoints.values.md 
    },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 30
  }
}

export const carouselExtraSettings = {
  dotListClass: "custom-carousel-dot-list",
  customTransition: "all 350ms",
  transitionDuration: 350,
}