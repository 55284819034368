import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date"
import {add} from "date-fns"

import {isAfterDate} from "../../../utils/dateFns/isAfterDate"

export const checkDateValidity = (visitAtDate: string) => (date: MaterialUiPickersDate): boolean => {
  if (date) {
    const referralValidity = add(new Date(visitAtDate), {days: 30})
    return isAfterDate(date, referralValidity)
  }
  return true
}