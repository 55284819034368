import TagManager from "react-gtm-module"

import { tracker } from "../../hooks/useOpenReplayTracker"

import { LogEventType } from "./index"

export default class Analytics {
  constructor() {
    TagManager.initialize({ gtmId: "GTM-TDSDRR"})
  }

  sendEvent = (
    event: LogEventType,
    params?: {[key: string]: string | null},
  ) => {
    // Google tag manager events
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...params
      }
    })
    // e/o Google tag manager events

    // Open Replay events
    tracker.event(event, params)
    // e/o Open Replay events
  }

  sendEventWithDefaultParams = (
    event: LogEventType,
    params?: {[key: string]: string | null},
  ) => {
    const domain = window.location.hostname
    const uniqueSessionId = sessionStorage.getItem("sessionId")

    const parameters = {
      "domain_name": domain,
      sessionId: uniqueSessionId,
      ...params
    }

    this.sendEvent(event, parameters)
  }
}