import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { generatePath } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Typography } from "@material-ui/core"

import { useQueryParam } from "../../../../hooks/useSearchParams"
import { ActionType } from "../../api/queries/getUserActionById"
import {
  GET_USER_DISEASE_PROBABILITY_BY_QUESTIONNAIRE_ID,
  GetUserDiseaseProbabilityResponse,
  GetUserDiseaseProbabilityVariables
} from "../../api/queries/getUserDiseaseProbabilityByQuestionnaireId"
import LoaderBox from "../../../../components/common/loaderBox/LoaderBox.component"
import ActionThankYouPage from "../actionThankYouPage/ActionThankYouPage.component"
import { useGetLabelByProbability } from "./ThankYouPageQuestionnaire.utils"
import { RoutePath } from "../../../../routes/Routes.types"
import {HealthPreventionPrograms} from "../../../../store/clinic/clinicSettings.types"
import { QuestionnaireTemplate } from "../../pages/preventionQuestionnaire/genericStepForm/GenericStepForm.types"
import { useThankYouPageQuestionnaireStyles } from "./ThankYouPageQuestionnaire.styles"

interface ThankYouPageQuestionnaireProps {
  points?: number;
  questionnaireId?: string;
  template?: QuestionnaireTemplate;
}

const ThankYouPageQuestionnaire: FC<ThankYouPageQuestionnaireProps> = ({
  points,
  questionnaireId,
  template
}) => {
  const { t, i18n } = useTranslation()
  const { paragraphItem } = useThankYouPageQuestionnaireStyles()
  const { questionnaireId: questionnaireIdParam } = useParams<{ questionnaireId: string }>()
  const type = useQueryParam("type")
  const isMentalHealthProgram = type === HealthPreventionPrograms.PROGRAM_MENTAL_HEALTH

  const { data, loading } = useQuery<
  GetUserDiseaseProbabilityResponse,
  GetUserDiseaseProbabilityVariables
  >(
    GET_USER_DISEASE_PROBABILITY_BY_QUESTIONNAIRE_ID,
    {
      context: { clientName: "HealthPlanner" },
      variables: {
        questionnaireId: questionnaireId ?? questionnaireIdParam
      }
    }
  )

  const diseaseProbabilityLabel = useGetLabelByProbability(data?.usersDiseasesProbability[0]?.probability)
  
  if (loading) {
    return <LoaderBox boxType='appDialog' />
  }
  
  const isEmptyResponse = data?.usersDiseasesProbability.length === 0
  
  const getButtonLabel = () => {
    if (isMentalHealthProgram) {
      return t("goToHomepage")
    }

    return isEmptyResponse
      ? t("healthPlanner:backToProphylaxis")
      : t("healthPlanner:manageDisease")
  }
  
  const getButtonLink = () => {
    if (isMentalHealthProgram) {
      return `/${i18n.language}${RoutePath.MENTAL_HEALTH}`
    }
    
    return isEmptyResponse
      ? `/${i18n.language}${RoutePath.PREVENTION}`
      : generatePath(
        `/${i18n.language}${RoutePath.PREVENTION_DISEASE_DETAILS}`,
        { diseaseId: data?.usersDiseasesProbability[0]?.disease.id ?? "#" }
      )
  }
  
  return (
    <ActionThankYouPage
      points={points}
      template={template}
      actionType={ActionType.FILL_QUESTIONNAIRE}
      buttonLink={getButtonLink()}
      buttonLabel={getButtonLabel()}
    >
      {
        isEmptyResponse ? (
          <Typography
            variant={"body1"}
            className={paragraphItem}
          >
              Trwa obliczanie twojego ryzyka zachorowania, wróć później do zakładki &quot;Zarządzanie chorobami&quot;
          </Typography>
        ) : (
          <Typography
            variant={"body1"}
            className={paragraphItem}
          >
            {
              t(
                "healthPlanner:questionnaireSummaryDescription",
                {
                  diseaseName: data?.usersDiseasesProbability[0]?.disease?.name,
                  diseaseProbabilityLabel,
                  diseaseProbability: (data?.usersDiseasesProbability[0]?.probability ?? 0) * 100
                }
              )
            }
          </Typography>
        )
      }
    </ActionThankYouPage>
  )
}

export default ThankYouPageQuestionnaire