import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Button, ListItem, ListItemText} from "@material-ui/core"
import Box from "@material-ui/core/Box"

import {analytics, LogEventType} from "../../../../../services/analytics"
import ImageByIntegrationType from "../../../../imageByIntegrationType/ImageByIntegrationType"
import {NestedMenuListItem} from "./NestedMenu.types"
import {useNestedMenuStyles} from "./NestedMenu.styles"

interface NestedMenuItemProps {
  listItem: NestedMenuListItem
  handleMenuClose: () => void
}

const NestedMenuItem: FC<NestedMenuItemProps> = ({ listItem, handleMenuClose }) => {
  const classes = useNestedMenuStyles()
  const { t } = useTranslation()
  const handleListItemClick = (listItem: NestedMenuListItem): void => {
    if(listItem.googleAnalyticsEvent){
      analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_MENU_ORDER_REMOTE_CONSULTATION_CLICK)
    }
    handleMenuClose()
    listItem.onClickHandler()
  }

  return (
    <ListItem
      id={listItem.value}
      key={listItem.text}
      className={classes.listItem}
      component={Button}
      onClick={() => {handleListItemClick(listItem)}}
    >
      {
        listItem.image && (
          <ImageByIntegrationType
            step={listItem.value}
            className={classes.listItemImg}
            imageSrc={listItem.image}
            imagePath={listItem.imagePath}
          />
        )}
      <Box className={classes.listItemDetails}>
        <ListItemText
          className={classes.listItemHeader}
          primary={t(listItem.text)}
          primaryTypographyProps={{variant: "h5", component: "div"}}
        />
        <ListItemText
          className={classes.listItemLabel}
          primary={listItem.additionalText ? t(listItem.additionalText) : null}
          primaryTypographyProps={{variant: "body2", component: "div"}}
        />
      </Box>
    </ListItem>
  )
}

export default NestedMenuItem