import React, {useEffect} from "react"
import { useTranslation } from "react-i18next"
import {Box, Button, Link, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import {telemediUrl} from "../../app.config"
import clinicNotFoundImage from "../../assets/images/clinic-not-found.png"
import useOpenReplayTracker from "../../hooks/useOpenReplayTracker"
import ClinicSelect from "../../components/clinicSelect/ClinicSelect.component"
import LangDropdowndOnBasePage from "../../components/common/langDropdown/LangDropdownOnBasePage.component"
import PageSection from "../../components/common/pageSection/PageSection.component"
import ImageByIntegrationType from "../../components/imageByIntegrationType/ImageByIntegrationType"
import {useClinicNotFoundStyles} from "./ClinicNotFound.styles"

const ClinicNotFoundPage = () => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const {t} = useTranslation()
  const {trackerStart} = useOpenReplayTracker()
  const classes = useClinicNotFoundStyles()

  useEffect(() => {
    trackerStart()
  }, [])

  return (
    <Box className={classes.container}>
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <LangDropdowndOnBasePage/>
      </Box>
      <Box className={classes.root}>
        <PageSection>
          <Box
            borderRadius={theme.shape.borderRadius}
            bgcolor={theme.palette.background.paper}
            className={classes.pageBox}
          >
            <Box className={classes.section}>
              <ImageByIntegrationType
                imageSrc={clinicNotFoundImage}
                alt="clinicNotFoundImage"
                className={classes.image}
                imagePath={"clinic-not-found.png"}
              />
            </Box>
            <Box className={classes.section}>
              <Box mb={3.5} mt={isMdUp ? 0 : 4}>
                <Typography variant={isMdUp ? "h2" : "h3"}>
                  {t("errors:urlError")}
                </Typography>
              </Box>
              <Box mb={3.5}>
                <Typography className={classes.label}>
                  {t("errors:checkLabel")}
                </Typography>
              </Box>
              <Link
                color="inherit"
                href={`${telemediUrl}/pl`}
                target="_blank"
                underline="none"
                rel="noopener noreferrer"
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  {t("errors:goToTelemedi")}
                </Button>
              </Link>
              <ClinicSelect/>
            </Box>
          </Box>
        </PageSection>
      </Box>
    </Box>
  )
}

export default ClinicNotFoundPage
