import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const MOBILE_MAP_HEIGHT_PX = 364

export const useReferralSectionStyles = makeStyles((theme: Theme) => createStyles({
  referralAccordionSummary: {
    zIndex: 2, // above referralAccordionDetails on expanded content
  },
  referralAccordionDetails: {
    position: "relative",
    transition: "transform .3s ease-in-out",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      marginBottom: 0,
    }
  },
  content: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      flexBasis: "50%",
      maxWidth: "100%"
    },
  },
  expand: {
    flex: 1,
    transition: "opacity 300ms",
  },
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
    pointerEvents: "none",
  },
  actionButton: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  referralPurchaseStatusContainer: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    padding: "10px",
    borderRadius: theme.spacing(1),
    color: theme.palette.common.white,
    marginBottom: theme.spacing(5),
  },
  checkIcon: {
    width: "22px",
    minWidth: "22px",
    height: "20px",
    borderRadius: "50%",
    position: "relative",
    marginRight: theme.spacing(1.5),

    "&.whiteVariant": {
      marginTop: "1px",
      backgroundColor: theme.palette.common.white,
    },
  },
  checkIconImg: {
    width: theme.spacing(3.5),
    fill: theme.palette.primary.main,
    position: "absolute",
    left: "2px",
    top: "-2px",

    "&.whiteVariant": {
      minWidth: theme.spacing(2),
      width: theme.spacing(2),
      top: "2px",
      left: "3px",
      fill: theme.palette.primary.main,
    },
  },
}))
