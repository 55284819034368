import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Link, Typography} from "@material-ui/core"

import {useAppSelector} from "../../../../hooks/storeHooks"
import {useGetExamPointsQuery} from "../../../../store/medicalExamsPoints/medicalExamsPoints.api"
import {selectChosenLaboratory} from "../../../../store/medicalExamsPoints/medicalExamsPoints.selectors"
import ListItemRadioButton from "../../../listItemRadioButton/listItemRadioButton.component"
import Loader from "../../../loader/Loader.component"
import {useAlabListManger, useLaboratoryManager} from "./AlalbList.utils"
import {useAlabListStyles} from "./AlabList.styles"

interface AlabListProps {
  postalCode: string
}

const AlabList: FC<AlabListProps> = ({ postalCode }) => {
  const classes = useAlabListStyles()
  const {t} = useTranslation()
  const chosenLaboratory =  useAppSelector(selectChosenLaboratory)
  const { handleChoseLaboratory } = useLaboratoryManager()
  const { data = [], isFetching } = useGetExamPointsQuery(postalCode)
  const { visibleLaboratoryNumber, handleIncrementLaboratoryNumber } = useAlabListManger(data)
  const visibleLaboratories = data.slice(0, visibleLaboratoryNumber)
  const isFoundAnyLaboratory = data.length > 0

  if (isFetching) {
    return <Loader className={classes.root} />
  }

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
    >
      {
        visibleLaboratories.map(laboratory => (
          <ListItemRadioButton
            key={laboratory.id}
            name={laboratory.pointType.toUpperCase()}
            isChecked={chosenLaboratory?.id === laboratory.id}
            onClick={handleChoseLaboratory(laboratory)}
          >
            <Typography
              variant="subtitle1"
            >
              {laboratory.street}, {laboratory.postalCode} {laboratory.city}
            </Typography>
          </ListItemRadioButton>
        ))
      }
      {
        isFoundAnyLaboratory && (
          <Link
            className={classes.loadMoreLabel}
            onClick={handleIncrementLaboratoryNumber}
          >
            {t("referrals:showMoreLabsLabel")}
          </Link>
        )
      }
    </Box>
  )
}

export default AlabList
