import {createStyles, makeStyles, Theme} from "@material-ui/core"

interface Props {
  size?: number;
  customBackgroundColor?: string;
}

export const useUserAvatarStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: (props: Props) => theme.spacing(props.size || 6),
    height: (props: Props) => theme.spacing(props.size || 6),
  },
  colorDefault: {
    background: (props: Props) => props.customBackgroundColor || `linear-gradient(45deg, ${theme.palette.secondary.darker}, ${theme.palette.secondary.gradient})`,
  },
}))
