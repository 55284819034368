export type SessionState = {
  id?: string; //uuid
  username?: string;
  token?: string;
  accountType?: AccountType | null;
  users?: null;
  refresh_token?: string;
}

export const SESSION_STORAGE_KEY = "widgetSession"
// session data used in application is also used in consultation-widget
// consultation-widget requires key name "widgetSession" for auto-login feature

export type DecodedTokenType = {
  exp: Date;
  iat: Date;
}

export enum AccountType {
  PATIENT = 0,
  DOCTOR = 1,
  // OTHER -> null
}
