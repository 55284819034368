import React, {FC, useEffect, useMemo} from "react"
import {useForm} from "react-hook-form"
import { FormProvider } from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {Grid, Typography} from "@material-ui/core"

import useGetFormValidationRules from "../../hooks/useGetFormValidationRules"
import { analytics, LogEventType } from "../../services/analytics"
import {useCreateOrderByEanMutation} from "../../store/doz/doz.api"
import {selectUser} from "../../store/user/user.selectors"
import {MedicalDocuments} from "../documentation/Documentation.type"
import ActionButtons from "../actionButtons/ActionButtons.component"
import LoaderBox from "../common/loaderBox/LoaderBox.component"
import DozErrorParser from "./dozErrorParser/DozErrorParser.component"
import DozRegulations from "./dozRegulations/DozRegulations.component"
import OrderMedicinesList from "./orderMedicinesList/OrderMedicinesList.component"
import OrderMedicinesTerms from "./orderMedicinesTerms/OrderMedicinesTerms.component"
import {PharmacySearchEngine} from "./pharmacySearchEngine/PharmacySearchEngine.component"
import ThankYouPageView from "./thankYouPageView/ThankYouPageView.component"
import UserDetailsSection from "./userDetailsSection/UserDetailsSection.component"
import generateUuid from "../../utils/generateUuid"
import {mapDozItemsToRequestedItemList} from "./OrderMedicinesForm.utils"
import {DozOrder} from "../../store/doz/doz.types"
import {useOrderMedicinesFormStyles} from "./OrderMedicinesForm.styles"

import { orderMedicinesFormValidationSchema } from "./OrderMedicinesForm.validation"

interface OrderMedicinesFormProps {
  medicineEprescriptions: MedicalDocuments["medicine_eprescriptions"];
}

const OrderMedicinesForm: FC<OrderMedicinesFormProps> = ({ medicineEprescriptions }) => {
  const classes = useOrderMedicinesFormStyles()
  const {t} = useTranslation()
  const userData = useSelector(selectUser)
  const fixedCacheKey = useMemo(
    () => generateUuid(),
    []
  )
  const [createOrderByEan, {data, isLoading, isSuccess, isError, error}] = useCreateOrderByEanMutation({
    fixedCacheKey,
  })
  const dozOrderId = data?.data?.order_id
  const formMethods = useForm<DozOrder>({
    defaultValues: {
      requested_item_list: mapDozItemsToRequestedItemList(medicineEprescriptions.doz.items),
      phone_number: userData?.phone_national_number,
      terms_and_conditions: false,
    },
    resolver: useGetFormValidationRules(orderMedicinesFormValidationSchema),
    criteriaMode: "all",
    mode: "onSubmit",
  })
  
  useEffect(() => analytics.sendEventWithDefaultParams(LogEventType.DOZ_SUMMARY_PAGE_LOAD), [])

  if (isSuccess && dozOrderId) {
    return <ThankYouPageView
      fixedCacheKey={fixedCacheKey}
    />
  }

  return (
    <>
      {
        isLoading && <LoaderBox boxType="fullPage" />
      }
      {
        !isSuccess && (
          <Typography variant="h2">
            {t("orderMedicines:header")}
          </Typography>
        )
      }
      <FormProvider {...formMethods}>
        <form
          className={classes.root}
          onSubmit={formMethods.handleSubmit(createOrderByEan)}
        >
          <Grid container spacing={0}>
            <Grid
              className={classes.leftColumn}
              item
              xs={12}
              sm={6}
            >
              <OrderMedicinesList
                medicineEprescriptions={medicineEprescriptions}
              />
            </Grid>

            <Grid
              className={classes.rightColumn}
              item
              xs={12}
              sm={6}
            >
              <UserDetailsSection
                userData={userData}
              />
              <PharmacySearchEngine/>
            </Grid>
          </Grid>
          <OrderMedicinesTerms />
          <ActionButtons actionSource="OrderMedicinesForm" />
          {
            isError && (
              <DozErrorParser error={error} />
            )
          }
          <Typography
            variant="subtitle2"
            color="error"
            className={classes.errorText}
          >
            {(isSuccess && !dozOrderId) && t("errors:unknownError")}
            {formMethods.formState.errors.pharmacy_id?.message}
          </Typography>
        </form>
      </FormProvider>
      <DozRegulations />
    </>
  )
}

export default OrderMedicinesForm
