import React, { FC } from "react"
import { Box } from "@material-ui/core"

import { UserDataType } from "../../../store/user/user.types"

import { useExtraQuestions } from "./ExtraQuestions.hooks"
import { questions } from "./ExtraQuestions.survey"

interface ExtraQuestionsProps {
  userData: UserDataType | null;
}

export const ExtraQuestions: FC<ExtraQuestionsProps> = ({ userData }) => {
  const { renderQuestions } = useExtraQuestions(userData)

  return (
    <Box>
      {renderQuestions(questions)}
    </Box>
  )
}
