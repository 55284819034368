import React, {FC} from "react"
import {Trans} from "react-i18next"
import { useSelector } from "react-redux"
import {Box, Link, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import { selectClinicSettings } from "../../../../store/clinic/clinicSettings.selectors"
import { DefaultRegulationsLinks } from "./UserRegulations.utils"

interface UserRegulationsProps {}

const UserRegulations: FC<UserRegulationsProps> = () => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const {widgetRegisterSettings: { linkToRegulations, linkToPrivacyPolicy }} = useSelector(selectClinicSettings)

  return (
    <Box
      mt={isMdUp ? "auto" : 5}
      ml="auto"
    >
      <Typography
        variant="body2"
      >
        <Trans
          i18nKey="user:regulations"
        >
          Zapoznaj się z <Link href={linkToRegulations || DefaultRegulationsLinks().regulations} target="_blank">Regulaminem Serwisu</Link>
        </Trans>
      </Typography>
      <Typography
        variant="body2"
      >
        <Trans
          i18nKey="user:privacyPolicy"
        >
          Zapoznaj się z <Link href={linkToPrivacyPolicy || DefaultRegulationsLinks().privacyPolicy} target="_blank">Polityką Prywatności</Link>
        </Trans>
      </Typography>
    </Box>
  )
}

export default UserRegulations
