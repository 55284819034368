import {useRef, useState} from "react"
import {useParams} from "react-router"
import i18next from "i18next"

import {sendMessage} from "../ConsultationSurveyPage.utils"
import {
  CompletedQuestion,
  QuestionnaireTemplate,
} from "../../healthPlanner/pages/preventionQuestionnaire/genericStepForm/GenericStepForm.types"
import {Question} from "../ConsultationSurveyPage.types"

export const useGenericStepForm = (
  questionnaireTemplate: QuestionnaireTemplate,
) => {
  const questionsLength = questionnaireTemplate.questions.length
  const { consultationId } = useParams<{ consultationId: string }>()

  const [saving, setSaving] = useState(false)
  const [stepNumber, setStepNumber] = useState(0)
  const [isCompleted, setIsCompleted] = useState(false)
  const [completedQuestions, setCompletedQuestions] = useState<CompletedQuestion[]>([])

  const generateChatMessage = (completedQuestionsToSave: Question[]) => {
    let answerData = ""

    const addRelatedAnswers = (item: Question) => {
      let relatedAnswers = ""

      item?.related_questions?.forEach((item) => {
        const answersData = item?.answers?.[0]

        relatedAnswers += `${item?.title}: ${answersData?.name || answersData?.value}\n\n`

        if (item?.related_questions?.length) {
          relatedAnswers += addRelatedAnswers(item)
        }
      })

      return relatedAnswers
    }

    completedQuestionsToSave.forEach((item) => {
      const answersData = item?.answers?.[0]

      answerData += `${item?.title}: ${answersData?.name || answersData?.value}\n\n`

      if (item?.related_questions?.length) {
        answerData += addRelatedAnswers(item)
      }
    })

    return answerData
  }

  const goToPreviousQuestion = () => {
    setStepNumber(previousState => previousState - 1)
  }

  const saveAnswer = (index: number) => (completedQuestion: CompletedQuestion) => {
    const completedQuestionsToSave = [...completedQuestions]
    let asyncConsultationAgreement = false
    completedQuestionsToSave[index] = completedQuestion
    setCompletedQuestions(completedQuestionsToSave)

    const isLastQuestionCompleted = questionsLength === index + 1

    if (isLastQuestionCompleted) {
      asyncConsultationAgreement = completedQuestionsToSave?.[index]?.answers?.[0]?.name === i18next.t("prescription:yesLabel")
      setSaving(true)
      sendMessage(generateChatMessage(completedQuestionsToSave), consultationId, setSaving, completedQuestionsToSave, setIsCompleted, asyncConsultationAgreement)
    } else {
      setStepNumber(index + 1)
    }
  }

  return {
    stepNumber,
    completedQuestions,
    progress: (stepNumber+1)/(questionsLength) * 100,
    saveAnswer,
    goToPreviousQuestion,
    isCompleted,
    saving
  }
}

export const useScrollHandler = () => {
  const containerRef = useRef<HTMLDivElement>()

  const handleScrollToQuestion = () => {
    containerRef.current?.scrollIntoView()
  }

  return {
    containerRef,
    handleScrollToQuestion
  }
}
