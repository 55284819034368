import {gql} from "@apollo/client"

export const GET_MEDICINES_ITEMS_FROM_CATEGORY = gql`
  query getMedicinesFromCategory($categoryId: Int!) {
    medicine(distinct_on: medical_product_id where: { disease_category_id: { _eq: $categoryId } }) {
      id
      power
      productName: product_name
      size
      unit
      activeSubstances: active_substances
      ean
      characterName: character_name
      medicalProductId: medical_product_id
    }
  }
`