import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import { Box, Grid, Link, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"
import i18next from "i18next"

import useIsB2BClinic from "../../hooks/useIsB2BClinic"
import store from "../../store"
import {colors} from "../../themes/colors"
import Logotype from "../common/logotype/Logotype"
import { usePageFooterStyles } from "./PageFooter.styles"

interface PageFooterProps {}

const PageFooter: FC<PageFooterProps> = () => {
  const theme = useTheme()
  const {t, i18n} = useTranslation()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const classes = usePageFooterStyles()
  const {B2BClinic} = useIsB2BClinic()
  const serviceDeskEmail = t(B2BClinic ? "serviceDeskEmailB2B" : "serviceDeskEmail")
  const { contactPhoneNumber, style } = store.getState().clinic?.clinicSettings?.frontendSettings || {}
  const showMainClinicLogoInFooter = style?.dynamicStylesEnabled && style?.mainClinicLogoInFooterEnabled

  const logoType = (
    <NavLink
      className={classes.logotypeLink}
      to={`/${i18n.language}`}
    >
      <Logotype
        title={t("mainPage")}
        logoFill={showMainClinicLogoInFooter ? colors.telemediGreen30 : theme.palette.common.white}
        textFill={showMainClinicLogoInFooter ? theme.palette.text.primary : theme.palette.common.white}
      />
    </NavLink>
  )

  const writeToUsSection = (
    <Box
      className={B2BClinic ? classes.writeToUsB2b : ""}
    >
      <Typography
        variant={isMdUp ? "body1" : "subtitle1"}
      >
        {t("pageFooter:writeUs")}
      </Typography>

      <Link
        color="inherit"
        variant={isMdUp ? "body1" : "subtitle1"}
        underline="always"
        href={`mailto:${serviceDeskEmail}`}
      >
        {serviceDeskEmail}
      </Link>
    </Box>
  )

  if (B2BClinic) {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isSmUp ? "row" : "column"}
        className={clsx(classes.root, classes.rootB2b)}
      >
        <Box
          className={classes.logoB2b}
        >
          { logoType }
        </Box>
        { writeToUsSection }
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      { logoType }
      <Grid container spacing={isMdUp ? 3 : 2}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography
            variant={isMdUp ? "body1" : "subtitle1"}
          >
            {t("pageFooter:getMoreInfo")}
          </Typography>
          <Link
            color="inherit"
            variant={isMdUp ? "body1" : "subtitle1"}
            underline="always"
            href={`https://telemedi.com/${i18next.language}/faq/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("pageFooter:help")}
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          { writeToUsSection }
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography
            variant={isMdUp ? "body1" : "subtitle1"}
          >
            {t("pageFooter:callUs")}
          </Typography>

          <Link
            color="inherit"
            variant={isMdUp ? "body1" : "subtitle1"}
            underline="always"
            href={`tel:${contactPhoneNumber ?? t("serviceDeskPhone")}`}
          >
            {contactPhoneNumber ?? t("serviceDeskPhone")}
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography
            variant={isMdUp ? "body1" : "subtitle1"}
          >
            {t("pageFooter:submitIssues")}
          </Typography>

          <Link
            color="inherit"
            variant={isMdUp ? "body1" : "subtitle1"}
            underline="always"
            href={`mailto:${t("pageFooter:submitIssuesEmail")}`}
          >
            {t("pageFooter:submitIssuesEmail")}
          </Link>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PageFooter
