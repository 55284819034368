import React, { FC, useEffect,useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Box } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import {push} from "connected-react-router"

import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks"
import useGoToConsultationPage from "../../../hooks/useGoToConsultationPage"
import {selectClinicSettings} from "../../../store/clinic/clinicSettings.selectors"
import {
  selectCancelledConsultationsVisible,
  selectComingConsultationsTotal,
  selectLatestConsultations,
} from "../../../store/consultations/consultations.selectors"
import {
  clearLatestConsultations,
  setCancelledVisible,
  setLatestConsultations,
  setLatestConsultationsTotal,
} from "../../../store/consultations/consultations.slice"
import {selectComingExamsItems} from "../../../store/exams/exams.selectors"
import {selectLogInRedirectUrl} from "../../../store/logIn/logIn.selectors"
import { selectLoading,selectUserAddress, selectUserAddressEditModalOpen } from "../../../store/user/user.selectors"
import { setUserAddressEditModalOpen } from "../../../store/user/user.slice"
import PageSection from "../../common/pageSection/PageSection.component"
import ComingExams from "../../exam/comingExams/ComingExams.component"
import UserAddressDataEditModal from "../../user/userProfile/userAddressData/UserAddressDataEditModal/UserAddressDataEditModal.component"
import LatestConsultationsList from "../latestConsultationsList/LatestConsultationsList.component"
import LatestConsultationsTitleNode from "./LatestConsultationsTitleNode.component"
import { checkFeatureAvailability } from "../../../store/clinic/clinicSettings.utils"
import { redirectToError500Page } from "../../../utils/handleErrors"
import {filterCurrentExams} from "../../exam/Exam.utils"
import { canBuyVoucherCode } from "../../voucher/voucherCodeToBuy/VoucherCodeToBuy.utils"
import { getConsultations, LATEST_CONSULTATION_ITEMS_LIMIT } from "../Consultation.utils"
import { RoutePath } from "../../../routes/Routes.types"
import {
  ConsultationListParamsStatus,
  ConsultationStatusNumber,
  GetConsultationsListParams,
} from "../Consultation.types"

interface LatestConsultationsSectionProps {
  lcpage: number|null;
  reloadingLatestConsultations: boolean;
  setReloadingLatestConsultations(reloading: boolean): void;
  B2BClinic: boolean;
}

const LatestConsultationsSection: FC<LatestConsultationsSectionProps> = (
  {
    lcpage,
    reloadingLatestConsultations,
    setReloadingLatestConsultations,
    B2BClinic,
  }) => {
  const consultations = useAppSelector(selectLatestConsultations)
  const comingConsultationsTotal = useAppSelector(selectComingConsultationsTotal)
  const cancelledVisible = useAppSelector(selectCancelledConsultationsVisible)
  const userAddress = useAppSelector(selectUserAddress)
  const userAddressEditModalOpen = useAppSelector(selectUserAddressEditModalOpen)
  const userDataLoading = useAppSelector(selectLoading)
  const exams = useAppSelector(selectComingExamsItems)
  const clinicSettings = useAppSelector(selectClinicSettings)
  const redirectData = useAppSelector(selectLogInRedirectUrl)
  const outdatedExams = filterCurrentExams(exams)
  const {i18n} = useTranslation()
  const {replace} = useHistory()
  const dispatch = useAppDispatch()
  const [onlyCancelledConsultations, setOnlyCancelledConsultations] = useState<boolean>(false)
  const latestConsultationsAvailable = consultations.length
  const { goToMakeConsultationPage } = useGoToConsultationPage()
  const voucherCodeToBuy = checkFeatureAvailability().voucherCodeToBuy
  const canBuyVoucher = canBuyVoucherCode()

  const getLatestConsultations = async (archivedVisible: boolean): Promise<void> => {
    const params: GetConsultationsListParams = {
      limit: LATEST_CONSULTATION_ITEMS_LIMIT,
      lang: i18n.language,
    }
    if (archivedVisible) {
      params.status = ConsultationListParamsStatus.ARCHIVED
    } else {
      params.statusNumber = [ConsultationStatusNumber.FINISHED]
    }

    try {
      const {data: {items, total}} = await getConsultations(params)
      await dispatch(setLatestConsultationsTotal(total))
      await dispatch(setLatestConsultations(items))

      if (!total && archivedVisible) {
        setOnlyCancelledConsultations(false)

        if (!redirectData && !comingConsultationsTotal && B2BClinic && !latestConsultationsAvailable && clinicSettings.hasBookConsultationAbility && exams.length === 0) {
          if (voucherCodeToBuy && canBuyVoucher) {
            dispatch(push(`/${i18n.language}${RoutePath.VOUCHERS_BUY}?withoutRedirect=1`))

            return
          }

          goToMakeConsultationPage()
        }
      }
    } catch (e) {
      console.error(e)
      redirectToError500Page(e)
    }
  }

  const toggleCancelledVisibilityChange = async () => {
    setReloadingLatestConsultations(true)
    const archivedVisible = !cancelledVisible
    await dispatch(setCancelledVisible(archivedVisible))
    await dispatch(clearLatestConsultations())
    replace(`/${i18n.language}`)
    await getLatestConsultations(archivedVisible)
    setReloadingLatestConsultations(false)
  }

  const getCancelledConsultations = async () => {
    setOnlyCancelledConsultations(true)
    setReloadingLatestConsultations(true)
    await getLatestConsultations(true)
    setReloadingLatestConsultations(false)
  }

  const consultationBoxSkeletonBox = (
    <Box my={1}>
      <Skeleton variant="text"/>
    </Box>
  )

  const cancelledConsultationsLoader = (
    <Box pb={16}>
      { consultationBoxSkeletonBox }
      { consultationBoxSkeletonBox }
      { consultationBoxSkeletonBox }
    </Box>
  )

  useEffect(() => {
    if (!latestConsultationsAvailable) {
      getCancelledConsultations()
    }
  }, [])

  if ((!reloadingLatestConsultations || onlyCancelledConsultations) && !latestConsultationsAvailable) {
    if (reloadingLatestConsultations) {
      return (
        cancelledConsultationsLoader
      )
    }

    return null
  }

  return (
    <PageSection
      titleNode={<LatestConsultationsTitleNode
        onClick={toggleCancelledVisibilityChange}
        disabled={reloadingLatestConsultations}
        onlyCancelledConsultations={onlyCancelledConsultations}
      />}
    >
      {
        cancelledVisible && (
          <ComingExams
            isOutdatedVariant
            items={outdatedExams}
          />
        )
      }
      {consultations.length > 0 && (
        <>
          {
            !userDataLoading && (
              <UserAddressDataEditModal
                address={userAddress}
                open={userAddressEditModalOpen}
                onClose={() => dispatch(setUserAddressEditModalOpen(false))}
              />
            )
          }
          <LatestConsultationsList
            items={consultations}
            lcpage={lcpage}
          />
        </>
      )}

      {reloadingLatestConsultations && (
        cancelledConsultationsLoader
      )}
    </PageSection>
  )
}

export default LatestConsultationsSection
