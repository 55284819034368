import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {
  Box,
  Button,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import * as Sentry from "@sentry/react"
import clsx from "clsx"

import api from "../../api/api"
import { refreshTokenConfig } from "../../api/routes"
import {localStorageKeys} from "../../constants/localStorageKeys"
import {useAppDispatch, useAppSelector} from "../../hooks/storeHooks"
import useInterval from "../../hooks/useInterval"
import {OPEN_REPLAY_ANONYMOUS_KEY} from "../../hooks/useOpenReplayTracker"
import {selectSessionExpirationDate, selectTokenRefresh} from "../../store/session/session.selectors"
import { clearSession, setSession } from "../../store/session/session.slice"
import {selectRandomSessionId} from "../../store/user/user.selectors"
import IconByIntegrationType from "../iconByIntegrationType/IconByIntegrationType"
import SessionManagerModal from "./sessionManagerModal/SessionManagerModal.component"
import { redirectToError500Page } from "../../utils/handleErrors"
import {useSessionManagerStyles} from "./SessionManager.styles"

let init = false
const SHOW_SESSION_MANAGER_TIME = 15 * 60 // 15min
const SHOW_ALERT_TIME = 60 // 1min
const ANIMATION_TIME = 1000 // 1s
const BUTTON_VISIBLE_TIME = 60 * 1000 // 60s

interface SessionManagerProps {}

const SessionManager: FC<SessionManagerProps> = () => {
  const theme = useTheme()
  const {t} = useTranslation()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [now, setNow] = useState<Date>(new Date)
  const [isIconAnimating, setIsIconAnimating] = useState<boolean>(false)
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false)
  const [isRefreshDisabled, setIsRefreshDisabled] = useState<boolean>(false)
  const [sessionModalOpen, setSessionModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const expirationDate = useAppSelector(selectSessionExpirationDate)
  const refreshToken = useAppSelector(selectTokenRefresh)
  const dispatch = useAppDispatch()
  const refreshSessionTranslation = t("sessionManager:refreshSession")
  const classes = useSessionManagerStyles()
  const randomSessionId = useSelector(selectRandomSessionId)

  const getNow = () => {
    setNow(new Date)
  }
  const {start, stop} = useInterval(getNow, 1000)

  useEffect(() => {
    start()
    return (): void => {
      stop()
    }
  }, [start, stop])

  useEffect(() => {
    if (loading && init) {
      setIsIconAnimating(true)
      setIsButtonVisible(true)

      setTimeout(() => {
        setIsButtonVisible(false)
        setIsRefreshDisabled(false)
      }, BUTTON_VISIBLE_TIME)
      setTimeout(() => {
        setIsIconAnimating(false)
      }, ANIMATION_TIME)
    }

    init = true
  }, [loading])

  const secondsLeft = expirationDate ? Math.ceil((+expirationDate - +now / 1000)) : null

  if (!secondsLeft) {
    return null
  }

  if (secondsLeft < 1) {
    dispatch(clearSession())
  }

  if (secondsLeft === SHOW_ALERT_TIME && !sessionModalOpen) {
    setSessionModalOpen(true)
  }

  const minutes = Math.floor(secondsLeft / 60)
  const seconds = secondsLeft % 60

  const refresh = async () => {
    if (secondsLeft < 1) {
      return false
    }

    setLoading(true)

    try {
      const {data} = await api.request(
        {
          ...refreshTokenConfig,
          data: { refreshToken },
        }
      )

      dispatch(setSession({
        token: data?.token?.accessToken,
        refresh_token: data?.token?.refreshToken,
      }))
    } catch (e) {
      console.error(e)
      redirectToError500Page(e)
    }

    setIsRefreshDisabled(true)
    setLoading(false)
  }

  useEffect(() => {
    if (localStorage.getItem(localStorageKeys.isFirstSessionInvoked)) {
      return
    }

    localStorage.setItem(localStorageKeys.isFirstSessionInvoked, "true")

    const isInitialSessionIsLessThanOneMinute = secondsLeft < 60 && secondsLeft > 0

    if (isInitialSessionIsLessThanOneMinute) {
      Sentry.captureMessage("ShortInitialSessionV2", {
        level: Sentry.Severity.Debug,
        extra: {
          openReplaySessionId: `${OPEN_REPLAY_ANONYMOUS_KEY}${randomSessionId}`,
          expirationDate: expirationDate,
          currentDate: now,
          secondsLeft: secondsLeft,
        }
      })
    }
  }, [])

  const timer = (
    <>
      <Box
        mr={1}
        className={clsx(
          classes.iconWrapper,
          (loading || isIconAnimating) && classes.animate
        )}
      >
        <IconByIntegrationType {...{ component: "span", variant: "h3" }}
          iconName={"icon-time-lapse"}
          returnTypography={true}
        />
      </Box>
      <Typography component="span" className={classes.time}>
        {minutes.toString().padStart(2, "0")} min {seconds.toString().padStart(2, "0")} s
      </Typography>
    </>
  )

  const sessionButtonLabel = (
    <>
      <Box mr={1}>
        <IconByIntegrationType {...{ component: "span", variant: "h3" }}
          iconName={"icon-check"}
          returnTypography={true}
        />
      </Box>
      <Typography component="span" className={classes.time}>
        {t("sessionManager:sessionRefreshed")}
      </Typography>
    </>
  )

  return (
    <>
      {isMdUp && (
        <>
          <Tooltip
            title={secondsLeft > SHOW_SESSION_MANAGER_TIME ? "" : refreshSessionTranslation}
            classes={{
              tooltip: classes.tooltip,
            }}
          >
            <div>
              <Button
                classes={{
                  root: classes.root,
                }}
                className={clsx(isButtonVisible && classes.fade)}
                disabled={loading || isIconAnimating || isRefreshDisabled}
                variant="outlined"
                color="primary"
                size="small"
                onClick={refresh}
              >
                {secondsLeft > SHOW_SESSION_MANAGER_TIME
                  ? sessionButtonLabel
                  : timer
                }
              </Button>
            </div>
          </Tooltip>
        </>
      )}
      <SessionManagerModal
        open={sessionModalOpen}
        onClose={() => {setSessionModalOpen(false)}}
        seconds={seconds.toString().padStart(2, "0")}
        refresh={refresh}
      />
    </>
  )
}

export default SessionManager
