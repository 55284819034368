import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useLoaderBoxStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    zIndex: theme.zIndex.tooltip,
    padding: theme.spacing(8),
    "&.b2b-loader": {
      padding: 0,

      "& > div": {
        color: theme.palette.grey[300],
      }
    },
  },
  fullPage: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: "100vh",
  },
  appDialog: {
    padding: theme.spacing(6, 6, 8),
  }
}))
