import {gql} from "@apollo/client"

export interface UserPointsSummaryResponseModel {
  aggregate: {
    sum: {
      amount: string;
    }
  }
}

export interface AwardsResponseModel {
  validTo: string;
  points: number;
}

export interface UserPointsResponseModel {
  awards: AwardsResponseModel[];
  userPoints: UserPointsSummaryResponseModel
}

export const GET_USER_POINTS = gql`
  query getUserPoints {
    awards(order_by: {points: asc}, where: {is_active: {_eq: true}}) {
      validTo: valid_to
      points
    }
    userPoints: points_transactions_aggregate {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`
