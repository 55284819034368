import React, { FC, useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import InputMask, { Props } from "react-input-mask"
import { TextField } from "@material-ui/core"

import TextFieldController from "../textFieldController/TextFieldController.component"
import { formatPolandPostalCode } from "./PostalCodeInput.utils"
import { MainCountriesTypes } from "../../../store/user/user.types"
import { CountryAbbreviation } from "../countrySelect/Country.types"

type PostalCodeInputControllerProps = {
  country: CountryAbbreviation;
  name: string;
  loading: boolean;
  label: string;
  shrink?: boolean;
  required?: boolean;
  additionalProps?: any;
}

export const PostalCodeInputController: FC<PostalCodeInputControllerProps> = ({
  country,
  name,
  loading,
  label,
  shrink,
  required,
  additionalProps
}) => {
  const form = useFormContext()
  const isPolandCountry = country === MainCountriesTypes.PL

  useEffect(() => {
    if (!isPolandCountry) {
      return
    }

    const currentValue = form.getValues(name)
    if (!currentValue) {
      return
    }

    const formattedCode = formatPolandPostalCode(currentValue)
    form.setValue(name, formattedCode)
  }, [isPolandCountry])

  return (
    <>
      { isPolandCountry
        ? (
          <Controller
            name={name}
            control={form.control}
            render={({
              field: { onChange, value },
              fieldState: { error, invalid },
            }) => (
              <InputMask
                id={name}
                mask="99-999"
                value={value}
                onChange={onChange}
                disabled={loading}
              >
                {(props: Props) => (
                  <TextField
                    helperText={error?.message}
                    error={invalid}
                    disabled={loading}
                    required={required ?? false}
                    fullWidth
                    label={label}
                    placeholder={label}
                    InputProps={additionalProps}
                    {...props}
                    InputLabelProps={{
                      shrink: shrink ?? false
                    }}
                  />
                )}
              </InputMask>
            )}
          />
        )
        : (
          <TextFieldController
            name={name}
            label={label}
            disabled={loading}
            InputProps={{endAdornment: null}}
            InputLabelProps={{
              shrink: shrink ?? false
            }}
          />
        )}
    </>
  )
}