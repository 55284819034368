import {TOP_BAR_HEIGHT_SPACING, TOP_BAR_MARGIN_BOTTOM_MD_UP_SPACING} from "../components/topBar/TopBar.styles"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

const useLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    pageContainerWithFixedTopBar: {
      display: "flex",
      flex: "1",
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(TOP_BAR_HEIGHT_SPACING + TOP_BAR_MARGIN_BOTTOM_MD_UP_SPACING)
      }
    },
    sideBarLessContainer: {
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(TOP_BAR_HEIGHT_SPACING + TOP_BAR_MARGIN_BOTTOM_MD_UP_SPACING)
      }
    },
    containerWrapper: {
      width: "100%",
      height: "100%"
    },
    contentContainer: {
      "&&": {
        paddingLeft: 0,
        paddingRight: 0, 
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }
    },
    fixedMenu: {
      position: "fixed",
    },
  })
)

export default useLayoutStyles
