import React, { ChangeEvent, FC, useState } from "react"
import { Box } from "@material-ui/core"

import ActionListTabs, { TabInfo } from "./actionListTabs/ActionListTabs.component"
import DynamicActionListPanels, { PanelData } from "./dynamicActionListPanels/DynamicActionListPanels.component"
import { useActionsListStyles } from "./ActionsList.styles"

interface ActionsListProps {
  tabs: TabInfo[];
  panelsData: PanelData[];
  loading: boolean;
  error: boolean;
}

const ActionsList: FC<ActionsListProps> = ({
  tabs,
  panelsData,
  loading,
  error
}) => {
  const classes = useActionsListStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box className={classes.boxCard}>
      <ActionListTabs
        tabs={tabs}
        value={value}
        handleChange={handleChange}
      />
      <DynamicActionListPanels 
        panelsData={panelsData}
        loading={loading}
        value={value}
        error={error}
      />
    </Box>
  )
}

export default ActionsList
