import React, {FC, useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { push } from "connected-react-router"
import dayjs from "dayjs"

import api from "../../api/api"
import {getUserDataConfig} from "../../api/routes"
import useIsB2BClinic from "../../hooks/useIsB2BClinic"
import store from "../../store"
import { selectUserId } from "../../store/session/session.selectors"
import { selectUser, selectUserPozDeclarationActiveStatus } from "../../store/user/user.selectors"
import PageSection from "../../components/common/pageSection/PageSection.component"
import { checkIfLastPeselCharIsEven } from "../../components/topBar/LoyalityProgramBadge/LoyalityProgramBadge.utils"
import {UserDataType} from "../../store/user/user.types"
import { useLoyalityProgramPageStyles } from "./LoyalityProgramPage.styles"

export const isDeclarationSignedAfterSpecificDate = (userDetails?: null|UserDataType): boolean => {
  const dateAfter = "2023-07-01"
  const userData = userDetails ? userDetails : store.getState().user.userData
  const date = dayjs(dateAfter)
  const createdAt = dayjs(userData?.poz.active_declaration_created_at)

  return date.isSame(createdAt, "day") || createdAt.isAfter(date)
}

interface LoyalityProgramPageProps {
  showMedicalSurvey?: boolean;
}

const LoyalityProgramPage: FC<LoyalityProgramPageProps> = ({showMedicalSurvey}) => {
  const { i18n } = useTranslation()
  const userId = useSelector(selectUserId)
  let iframeUrl = `https://loyality.telemedi.co?userid=${userId}`
  const classes = useLoyalityProgramPageStyles()
  const dispatch = useDispatch()
  const { B2BClinic } = useIsB2BClinic()
  const [userData, setUserData] = useState<UserDataType | null>(useSelector(selectUser))
  const [dataFetched, setDataFetched] = useState(false)
  const isDeclarationActive = useSelector(selectUserPozDeclarationActiveStatus)

  useEffect(() => {
    async function fetchData() {
      if (showMedicalSurvey && !dataFetched) {
        try {
          const response = await api.request({ ...getUserDataConfig(userId) })
          if (response.data) {
            setUserData(response.data)
          }
          setDataFetched(true)
        } catch (error) {}
      }
    }

    fetchData()
  }, [showMedicalSurvey, dataFetched])

  if (showMedicalSurvey) {
    iframeUrl += "&gdzie=thxpage"
  }

  if (!userData && showMedicalSurvey) {
    return null
  }

  const signedAfterDate = isDeclarationSignedAfterSpecificDate(userData)
  const isPozDeclarationActive = isDeclarationActive || userData?.isPozDeclarationActive === true
  const accessAllowed = checkIfLastPeselCharIsEven(userData?.pesel) && isPozDeclarationActive && !B2BClinic && signedAfterDate

  if (!accessAllowed) {
    if (showMedicalSurvey) {
      return null
    }

    dispatch(push(`/${i18n.language}/404`))
  }

  return (
    <PageSection>
      <div className={classes.iframeContainer}>
        <iframe className={classes.iframe} src={iframeUrl}></iframe>
      </div>
    </PageSection>
  )
}

export default LoyalityProgramPage
