import React, {FC, useEffect, useState} from "react"
import {FormProvider,useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box, Button, Checkbox, Typography} from "@material-ui/core"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"

import {useAppSelector} from "../../hooks/storeHooks"
import useGetFormValidationRules from "../../hooks/useGetFormValidationRules"
import { selectUser } from "../../store/user/user.selectors"
import SickLeaveSummaryItem from "./SickLeaveSurveySummaryItem.component"
import {getSickLeaveEmploymentTypeLabel} from "../consultation/Consultation.utils"
import {generateForeignWorkerCompanyAddress, generatePatientAddress, getSickLeavePeriod} from "./SickLeaveSurvey.utils"
import {SetState} from "../../types/SetState"
import {OrderedSickLeaveDetails} from "../consultation/Consultation.types"
import {SickLeaveSurveyStatus, SurveyFields} from "../SickLeaveSurvey/SickLeaveSurvey.types"
import {useSickLeaveSectionStyles} from "./SickLeaveSurvey.styles"

import {sickLeaveSummarySurveySchema} from "./SickLeaveSurvey.validation"

type SickLeaveSummaryProps = {
  formData: SurveyFields;
  orderedSickLeave: OrderedSickLeaveDetails;
  handleGoToNextStep(event?: React.FormEvent): Promise<void>;
  setSurveyStatus: SetState<number>;
  isLoading: boolean;
  renderCheckboxField(name: string, label: string): JSX.Element;
  startDate: string;
  endDate: string;
}

type NipItem = {
  nipField: string;
  nipItem: string;
}

const SINGLE_NIP = 1
const TOTAL_AGREEMENTS_CONFIRMATIONS = 4

const SickLeaveSummary: FC<SickLeaveSummaryProps> = ({
  formData, 
  orderedSickLeave,
  handleGoToNextStep,
  setSurveyStatus,
  isLoading,
  renderCheckboxField,
  startDate,
  endDate
}) => {
  const {t} = useTranslation()
  const userData = useAppSelector(selectUser)
  const classes = useSickLeaveSectionStyles()
  const [allChecked, setAllChecked] = useState(false)

  const form = useForm({
    defaultValues: {
      orderConfirmation1: false,
      orderConfirmation2: false,
      orderConfirmation3: false,
      orderConfirmation4: false,
    },
    mode: "all",
    resolver: useGetFormValidationRules(sickLeaveSummarySurveySchema),
  })

  const generateNipData = () => {
    const nipData = formData?.nip
    const firstNipItem = nipData?.[0]?.nipItem
    const nipElements: JSX.Element[] = []

    if (!nipData?.length || firstNipItem === "") {
      return null
    } else if (nipData.length === SINGLE_NIP) {
      return <SickLeaveSummaryItem label={t("consultation:sickLeave:nip")} value={firstNipItem || ""} />
    } else {
      nipData.forEach((item: NipItem, index: number) => {
        nipElements.push(
          <SickLeaveSummaryItem
            key={index}
            label={t("consultation:sickLeave:multipleNip", {index: index + 1})}
            value={item.nipItem || ""}
          />
        )
      })
    }

    return nipElements
  }

  const onSubmit = form.handleSubmit(async () => handleGoToNextStep())

  const handleSelectAll = () => {
    for (let index = 1; index < TOTAL_AGREEMENTS_CONFIRMATIONS + 1; index++) {
      const orderConfirmation = document?.getElementById?.(`orderConfirmation${index}`)

      if (!(orderConfirmation as HTMLInputElement).checked) {
        orderConfirmation?.click()
      }

      setAllChecked(true)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box
      component="form"
      width="100%"
    >
      <FormProvider {...form}>
        <Box mb={5}>
          <Typography variant="h3">{t("consultation:sickLeave:confirmationHeader")}</Typography>
        </Box>
        <Typography variant="body1">{t("consultation:sickLeave:confirmationMessage")}</Typography>
        <Box className={classes.summaryContainer}>
          <Box className={classes.summaryContent}>
            <SickLeaveSummaryItem label={t("consultation:sickLeave:period")} value={getSickLeavePeriod(startDate, endDate)} />
            <SickLeaveSummaryItem label={t("consultation:sickLeave:mainAddress")} value={generatePatientAddress(formData, true)} />
            {formData?.street_otherAddress && (
              <SickLeaveSummaryItem
                label={t("consultation:sickLeave:addressInSickLeavePeriod")}
                value={generatePatientAddress(formData)}
              />
            )}
            <SickLeaveSummaryItem label={`${t("user:pesel")}:`} value={userData?.pesel || ""} />
          </Box>
          <Box className={classes.summaryContent}>
            {generateNipData()}
            <SickLeaveSummaryItem label={t("consultation:sickLeave:employmentType")} value={getSickLeaveEmploymentTypeLabel(orderedSickLeave?.employment_type)} />
            {formData?.street_foreignWorker && (
              <SickLeaveSummaryItem
                label={`${t("consultation:companyData")}:`}
                value={generateForeignWorkerCompanyAddress(formData)}
              />
            )}
            {formData?.uniformedServiceType && (
              <SickLeaveSummaryItem
                label={t("consultation:uniformedServiceType").replace(" *", ":")}
                value={formData.uniformedServiceType}
              />
            )}
          </Box>
        </Box>
        {!allChecked && (
          <Box
            onClick={() => handleSelectAll()}
            className={classes.checkAllFields}
            my={3}
            display="flex"
          >
            <Checkbox
              id="selectAll"
              checkedIcon={<CheckBoxIcon color="primary"/>}
              icon={<CheckBoxOutlineBlankIcon />}
              size="medium"
              checked={allChecked}
            />
            <Typography variant="body1" className={classes.allRequiredFieldsLabelL}>
              {t("consultation:checkAllFields")}
            </Typography>
          </Box>
        )}
        <Box my={5} className={classes.orderInfo}>
          {renderCheckboxField("orderConfirmation1", t("consultation:sickLeave:orderInfo"))}
        </Box>
        <Box my={5} className={classes.orderInfo}>
          {renderCheckboxField("orderConfirmation2", t("consultation:sickLeave:orderInfo2"))}
        </Box>
        <Box my={5} className={classes.orderInfo}>
          {renderCheckboxField("orderConfirmation3", t("consultation:sickLeave:orderInfo3"))}
        </Box>
        <Box my={5} className={classes.orderInfo}>
          {renderCheckboxField("orderConfirmation4", t("consultation:sickLeave:orderInfo4"))}
        </Box>
        <Box className={classes.confirmActions}>
          <Button
            variant="outlined"
            color="primary"
            disabled={isLoading}
            onClick={() => setSurveyStatus(SickLeaveSurveyStatus.START)}
            className={classes.button}
          >
            {t("defaultTranslations:goBack")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {t("prescription:approveAndSend")}
          </Button>
        </Box>
      </FormProvider>
    </Box>
  )
}

export default SickLeaveSummary
