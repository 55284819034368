import { makeStyles } from "@material-ui/core"

import { createStyles } from "@material-ui/core/styles"

export const useHealthPreventionIframeViewStyles = makeStyles(theme => createStyles({
  iframeContainer: {
    width: "100%",
    height: "110vh",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
  },
  backBtn: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto"
    }
  },
}))
