import React, {FC} from "react"
import {Divider, Grid, Radio, Typography} from "@material-ui/core"

import {useItemListRadioButton} from "./listItemRadioButton.styles"

export interface ItemListRadioButtonProps {
  name: string
  isChecked: boolean
  onClick: VoidFunction
}

const ListItemRadioButton: FC<ItemListRadioButtonProps> = ({
  children,
  name,
  isChecked,
  onClick
}) => {
  const classes = useItemListRadioButton( { isChecked })
  
  return (
    <>
      <Grid
        className={classes.root}
        container
        direction="row"
        alignItems="center"
        wrap="nowrap"
        onClick={onClick}
      >
        <Grid>
          <Radio
            className={classes.radioButton}
            checked={isChecked}
          />
        </Grid>
        <Grid>
          <Typography
            className={classes.boldText}
            variant="subtitle1"
            color="textPrimary"
          >
            {name}
          </Typography>
          {children}
        </Grid>
      </Grid>
      <Divider/>
    </>
  )
}

export default ListItemRadioButton
