import {gql} from "@apollo/client"

export const GET_MEDICAL_QUESTIONS_BY_MEDICINE_ID = gql`
  query getMedicalQuestionsByMedicineId($medicineId: String!) {
    medical_question(where: { medicine: {ean: {_eq: $medicineId}}}) {
      id
      question
    }
  }
`
