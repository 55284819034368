export interface Prescriptions {
  total: number;
  items: PrescriptionModel[];
  doz: Doz;
}

export interface PrescriptionModel {
  id: string; //"00ff4db1-8dd0-11e9-8481-00ff4db57dc7" | "12f5bafc-8dd0-11e9-8481-00ff4db57dc7"
  name: string; //"E-recepta - Beata Gruca - 2019-10-30 12:55"
  date: string; //"2019-06-13 13:43:43"
  url: string; //"https://telemedico.localhost/api/v2/medical-documents/00ff4db1-8dd0-11e9-8481-00ff4db57dc7?_locale=pl"
  status: PrescriptionStatus; //"success"
  type: PrescriptionType; //"0"
  packageCode: string; //"8011"
  realizationDate: string; //"2019-06-13 13:43"
  consultation_id: string; //"63614b29-fba3-40aa-8373-945fe74eca27"
  specialization_name: string; //"internista"
  specialization_id: string; //"acc96366-7c58-4c01-92fb-4e2cc0f8f102"
  visit_at: string; // "2019-10-30 12:55:00"
  positions: PrescriptionPositionModel[];
}

export enum PrescriptionStatus {
  STATUS_FAILURE = "failure",
  STATUS_WAITING = "waiting",
  STATUS_SUCCESS = "success",
}

export enum PrescriptionType {
  TYPE_PRODUCT = 0,
  TYPE_RECIPE = 1,
}

export interface PrescriptionPositionModel {
  type: string; //"foodstuff"
  name: string; //"Bebilon pepti 1 DHA 400 g"
  text: string; // 3 x dziennie"
  code_e_a_n: string //"05900852031724"
  common_name: null //???
  package: string; //"400 g"
  medicine_type: null; //???
  short_name: string; //"Bebilon pepti 1 DHA 400 g"
  proportions: null; //??
  quantity: number; //1
  refoundation: RefoundationType | null; //"refoundation_100"
  replacement: boolean; //false
  refund_type: RefoundationType[]; //["refoundation_30"]
  way_of_preparation: null; //????
  active_substance: null; //???
  canceled: boolean; //false
  accessibility_category: null //???
  hiddenBorder: boolean; //false
}

export enum RefoundationType {
  REFOUNDATION_100 = "refoundation_100",
  REFOUNDATION_50 = "refoundation_50",
  REFOUNDATION_30 = "refoundation_30",
  REFOUNDATION_FLAT_RATE = "refoundation_flat_rate",
  REFOUNDATION_FREE = "refoundation_free",
}

export interface Doz {
  total: number; //3
  price: string|null; //"41.24 PLN"
  items: DozItem[];
}

export interface DozItem {
  ean: number; //5909990296026 | 8718117600625 | 5909990031719
  quantity: number; //1
  quantityDescription: string; //"6 tabl." | "450 g" | "1 butelka 100 ml"
  refundType: string; //"refoundation_100"
  category: null; //???
  name: string; //"Apap 500 mg 6 tabl." | "Bebilon pepti MCT 2155 kJ/100 g 450 g" | "Acodin Duo (50 mg + 15 mg)/5 ml 1 butelka 100 ml"
  price: number; //0 | 41.24
  availability: Availability; //"eprescription_cart.product_not_active" | ""
}

export enum Availability {
  ACTIVE = "",
  NOT_ACTIVE = "eprescription_cart.product_not_active",
  NOT_AVAILABLE = "eprescription_cart.product_not_available",
  NOT_FOUND = "eprescription_cart.product_by_ean.not_found",
}

export interface PrescriptionCartResponse {
  url: URL["href"];
}

export interface PrescriptionCartErrorResponse {
  status: number; //0
  type: string; //"not found"
  title: string; //"Not Found"
  errorType: number; //0
}
