import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useConsultationChatTopBoxStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    doctorDataWrapper: {
      display: "flex",
      maxWidth: "350px",
      marginBottom: theme.spacing(4),
    },
    doctorImage: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginRight: theme.spacing(1.5),
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(13),
        height: theme.spacing(13),
        marginRight: theme.spacing(3),
      }
    },
    iconsWrapper: {
      display: "flex",
      alignItems: "center",
    },
    consultationDate: {
      margin: `${theme.spacing(2.5)}px 0 0 ${theme.spacing(2.5)}px`,
      lineHeight: `${theme.spacing(2.5)}px`,
      "&::first-letter": {
        textTransform: "capitalize",
      }
    }
  })
)
