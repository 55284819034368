import React, {FC, MouseEvent} from "react"
import {useTranslation} from "react-i18next"
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle, useMediaQuery, useTheme,
} from "@material-ui/core"

import { useAppSelector } from "../../../hooks/storeHooks"
import { selectClinicSettings } from "../../../store/clinic/clinicSettings.selectors"
import CloseButton from "../../common/closeButton/CloseButton.component"
import {useAppDialogStyles} from "./AppDialog.styles"

enum CancelModalReason {
  ESCAPE_KEY_DOWN = "escapeKeyDown",
  BACKDROP_CLICK = "backdropClick",
}

interface AppDialogProps extends DialogProps {
  open: boolean;
  onClose?(): void;
  title: string;
  disableBackdropClick?: boolean;
  actionButtonText?: string;
  actionButtonProps?: ButtonProps;
  closeIcon?: boolean
  closeButton?: boolean;
  closeButtonText?: string;
  closeButtonProps?: ButtonProps;
  disabled?: boolean;
  footerChildren?: React.ReactNode;
}

const AppDialog: FC<AppDialogProps> = (
  {
    open,
    onClose,
    title,
    disableBackdropClick = false,
    actionButtonText,
    actionButtonProps,
    closeIcon = true,
    closeButton = true,
    closeButtonText,
    closeButtonProps,
    disabled = false,
    footerChildren,
    children,
    ...props
  }
) => {
  const { t } = useTranslation()
  const classes = useAppDialogStyles()

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))

  const {
    clinicAllianzSettings: { ewkaVerificationEnabled, onyxVerificationEnabled }
  } = useAppSelector(selectClinicSettings)

  const isAllianzEnabled = ewkaVerificationEnabled || onyxVerificationEnabled

  const onDialogClose = () => (event: MouseEvent, reason: CancelModalReason) => {
    if (disableBackdropClick && reason === CancelModalReason.BACKDROP_CLICK) {
      return
    }
    onClose?.()
  }

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      fullScreen={isSmUp ? props.fullScreen : true}
      {...props}
      classes={{
        paper: classes.dialogBox,
        ...props.classes
      }}
    >
      {
        closeIcon && (
          <CloseButton
            disabled={disabled}
            onClick={onClose}
          />
        )
      }

      <DialogTitle
        classes={{
          root: classes.dialogTitle,
        }}
      >
        { title }
      </DialogTitle>

      <DialogContent>
        { children }
      </DialogContent>

      <DialogActions
        classes={{
          root: classes.dialogActions,
          spacing: classes.dialogActionsSpacing
        }}
      >
        {closeButton && (
          <Button
            variant={`${isAllianzEnabled ? "contained" : "outlined"}`}
            color={"primary"}
            onClick={onClose}
            disabled={disabled}
            {...closeButtonProps}
          >
            { closeButtonText || t("close") }
          </Button>
        )}

        { (actionButtonText && actionButtonProps) &&
          <Button
            variant="contained"
            color={"primary"}
            disabled={disabled}
            {...actionButtonProps}
          >
            { actionButtonText }
          </Button>
        }
        {footerChildren}
      </DialogActions>
    </Dialog>
  )
}

export default AppDialog
