import {makeStyles} from "@material-ui/core"

import { colors } from "../../themes/colors"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useSendTwoFactorCodeStyles = makeStyles((theme: Theme) => createStyles({
  pageSection: {
    margin: "auto",
    width: "100%",
    height: "100%",
  },
  sectionBox: {
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6),
    },
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "stretch",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      flexDirection: "row",
    },
  },
  button: {
    marginTop: theme.spacing(2),
  },
  resendButton: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  sendCodeSuccess: {
    color: colors.telemediGreen,
    border: `1px solid ${colors.telemediGreen}`,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))
