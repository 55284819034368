import {createStyles,makeStyles} from "@material-ui/core"

export const useRiskStyles = makeStyles((theme) => createStyles({
  container: {
    width: "100%"
  },
  riskLabel: {
    marginTop: theme.spacing(1)
  },
  labelsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "space-between",
    justifyContent: "space-between",
    marginTop: theme.spacing(1.5)
  }
}))