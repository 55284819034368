import {gql} from "@apollo/client"

import { AwardsModel } from "./getUserAwardsQuery"
import { UserPointsSummaryResponseModel } from "./getUserPointsQuery"

export interface AwardByIdModel {
  id: string;
  validTo: string;
  points: number;
  name: string;
  description: string;
  imageUrl: string;
}
    
export interface AwardByIdResponseData {
  awards: AwardsModel[];
  userPoints: UserPointsSummaryResponseModel;
}

export type AwardByIdVariables = {
  awardId: string;
}

export const GET_AWARD_BY_ID = gql`
  query getAwardById($awardId: uuid!) {
    awards(where: {id: {_eq: $awardId}}) {
      id
      validTo: valid_to
      points
      name
      description
      imageUrl: image_url
    }
    userPoints: points_transactions_aggregate {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`
