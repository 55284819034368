import {useMutation} from "@apollo/client"

import {CompleteActionResponse, CompleteActionVariables} from "./completeAction.types"

import {COMPLETE_ACTION} from "./completeAction.query"

export const useCompleteActionMutation = (
  variables?: CompleteActionVariables
) => {
  return useMutation<
  CompleteActionResponse,
  CompleteActionVariables
  >(
    COMPLETE_ACTION,
    {
      context: { clientName: "HealthPlanner" },
      variables
    }
  )
}