import React, {FC, useEffect, useState} from "react"
import { Controller, FormProvider,useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, Checkbox, DialogContentText, FormControlLabel, Tooltip, Typography } from "@material-ui/core"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"

import api from "../../../../../api/api"
import { patchUserPersonalDataConfig, registerUserParentConfig, updateUserParentPersonalDataConfig } from "../../../../../api/routes"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks"
import useGetFormValidationRules from "../../../../../hooks/useGetFormValidationRules"
import { useUserIsUnderage } from "../../../../../hooks/useUserIsUnderage"
import { selectUserId } from "../../../../../store/session/session.selectors"
import { getUserData } from "../../../../../store/user/user.slice"
import AppDialog from "../../../../common/appDialog/AppDialog.component"
import ButtonLoader from "../../../../common/buttonLoader/ButtonLoader.component"
import UserParentPersonalDataEditForm from "../../userParentPersonalData/UserParentPersonalDataEditForm/UserParentPersonalDataEditForm.component"
import UserPersonalDataEditForm from "../UserPersonalDataEditForm/UserPersonalDataEditForm.component"
import {checkFeatureAvailability} from "../../../../../store/clinic/clinicSettings.utils"
import { getGenderFromPesel } from "../../../../../utils/getGenderFromPesel"
import { handleReactHookFormErrors, redirectToError500Page } from "../../../../../utils/handleErrors"
import { isAdult } from "../../../../../utils/isAdult"
import { getDocumentTypesForCountry, setProperDefaultDocumentType,setProperDocumentType } from "../UserPersonalData.utils"
import {Gender, UserDataType, UserDocumentData, UserDocumentType} from "../../../../../store/user/user.types"
import { CountryOption } from "../../../../commonFormItems/countrySelect/Country.types"
import { TimeZoneOption } from "../../../../commonFormItems/timeZoneSelect/TimeZoneSelect.types"
import { useUserProfileStyles } from "../../UserProfile.styles"

import { isAdultFromPesel, isValidBirthDate, isValidPesel, userEditPersonalDataSchema } from "./UserPersonalDataEditModal.validation"

interface PersonalDataValues {
  underageUserCheckbox: boolean;
  //patient data values
  firstName?: string | null;
  lastName?: string | null;
  pesel?: string | null;
  hasPesel?: boolean;
  identityNumber?: string | null;
  nationality?: CountryOption["value"] | null;
  gender?: Gender | null;
  defaultTimezone?: TimeZoneOption | null;
  documentType?: UserDocumentData["value"] | null;
  birthDate?: string;
  //parent data values
  parentFirstName?: string | null;
  parentLastName?: string | null;
  parentPesel?: string | null;
  parentIdentityNumber?: string | null;
  parentNationality?: CountryOption["value"] | null;
  parentGender?: Gender | null;
  parentDefaultTimezone?: TimeZoneOption | null;
  parentDocumentType?: UserDocumentData["value"] | null;
  parentBirthDate?: string;
}

interface UserPersonalDataEditModalProps {
  open: boolean;
  onClose(): void;
  user: UserDataType;
  userDocumentData: UserDocumentData;
  userParentData: UserDataType["parent"];
  userParentDocumentData: UserDocumentData;
}

const UserPersonalDataEditModal: FC<UserPersonalDataEditModalProps> = ({
  open,
  onClose,
  user,
  userDocumentData,
  userParentData,
  userParentDocumentData
}) => {
  const {t} = useTranslation()
  const userId = useAppSelector(selectUserId)
  const dispatch = useAppDispatch()
  const userHasNoPesel = user.identity_document_type !== null
  const userParentHasNoPesel = userParentData?.identity_document_type !== null
  const underageUser = useUserIsUnderage()
  const benefitSystemIntegrationEnabled = checkFeatureAvailability().enableBenefitSystemIntegration  // todo change to general checkbox (hide underage checkbox)
  const { coloredSubtitle } = useUserProfileStyles()

  let defaultValues: PersonalDataValues = {
    firstName: user.first_name || "",
    lastName: user.last_name || "",
    nationality: user.nationality || "",
    documentType: setProperDefaultDocumentType(user.nationality, user.pesel ?? user.identity_document_type)
      || setProperDefaultDocumentType(user.nationality, getDocumentTypesForCountry(user.nationality)[0].value)
      || null,
    identityNumber: user.identity_number || "",
    birthDate: user.birth_date || "",
    gender: user.gender !== null ? user.gender : null,
    defaultTimezone: user.default_timezone || "",

    underageUserCheckbox: underageUser ?? false,

    parentFirstName: userParentData?.first_name ?? "",
    parentLastName: userParentData?.last_name ?? "",
    parentNationality: userParentData?.nationality ?? user.nationality ?? "",
    parentDocumentType:
      setProperDefaultDocumentType(userParentData?.nationality, userParentData?.pesel ?? userParentData?.identity_document_type)
      ?? setProperDefaultDocumentType(userParentData?.nationality, getDocumentTypesForCountry(userParentData?.nationality)[0].value)
      ?? null,
    parentIdentityNumber: userParentData?.identity_number ?? "",
    parentBirthDate: userParentData?.birth_date ?? "",
    parentGender: userParentData?.gender ?? null,
    parentDefaultTimezone: userParentData?.default_timezone ?? user.default_timezone ?? "",
  }

  defaultValues =
    userHasNoPesel ? {
      ...defaultValues,
      identityNumber: userDocumentData.value ?? ""
    } : {
      ...defaultValues,
      pesel: userDocumentData.value ?? "",
    }

  defaultValues =
    userParentHasNoPesel ? {
      ...defaultValues,
      parentIdentityNumber: userParentDocumentData.value ?? ""
    } : {
      ...defaultValues,
      parentPesel: userParentDocumentData.value ?? "",
    }

  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<PersonalDataValues>({
    defaultValues,
    mode: "all",
    resolver: useGetFormValidationRules(() => userEditPersonalDataSchema(user.pesel, userParentData?.pesel ?? null)),
  })

  const underageUserChecked = form.watch("underageUserCheckbox")
  const peselValue = form.watch("pesel")
  const birthDateValue = form.watch("birthDate")

  useEffect(() => {
    form.clearErrors()
  },[underageUserChecked])

  const handleClose = () => {
    form.reset()
    setLoading(false)
    onClose()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    const patientPersonalData = {
      firstName: user.first_name
        ? undefined
        : (values.firstName || undefined),
      lastName: user.last_name
        ? undefined
        : (values.lastName || undefined),
      identityDocumentType: (user.pesel || (user.identity_document_type && user.identity_number) || values.documentType === UserDocumentType.PESEL || (!values.identityNumber && values.documentType !== UserDocumentType.PESEL) || !values.documentType)
        ? undefined
        : (setProperDocumentType(values.documentType) || undefined),
      pesel: (user.pesel || user.identity_document_type || values.documentType !== UserDocumentType.PESEL)
        ? undefined
        : (values.pesel || undefined),
      hasPesel: (!user.pesel && values.pesel && values.documentType === UserDocumentType.PESEL && !user.identity_document_type)
        ? true
        : undefined,
      identityNumber: (user.pesel || user.identity_document_type || values.documentType === UserDocumentType.PESEL)
        ? undefined
        : (values.identityNumber || undefined),
      identityDocumentCountryIssuer: (user.pesel || user.identity_document_type || values.documentType === UserDocumentType.PESEL || !values.identityNumber)
        ? undefined
        : values.nationality,
      birthDate: (user.pesel || user.identity_document_type || values.documentType === UserDocumentType.PESEL || user.birth_date || !values.birthDate)
        ? undefined
        : new Date(values.birthDate),
      gender: (values.pesel && values.documentType === UserDocumentType.PESEL)
        ? (!user.pesel || values.pesel) ? (getGenderFromPesel(values.pesel) ?? undefined) : undefined
        : (!user.pesel || !user.gender || values.gender) ? (values.gender ?? undefined) : undefined,
      nationality: user?.nationality === values.nationality
        ? undefined
        : (values.nationality || undefined),
      defaultTimezone: user.default_timezone === values.defaultTimezone
        ? undefined
        : (values.defaultTimezone || undefined)
    }

    const parentPersonalData = {
      firstName: userParentData?.first_name
        ? undefined
        : (values.parentFirstName || undefined),
      lastName: userParentData?.last_name
        ? undefined
        : (values.parentLastName || undefined),
      identityDocumentType: (userParentData?.pesel || (userParentData?.identity_document_type && userParentData?.identity_number) || values.parentDocumentType === UserDocumentType.PESEL || (!values.parentIdentityNumber && values.parentDocumentType !== UserDocumentType.PESEL) || !values.parentDocumentType)
        ? undefined
        : (setProperDocumentType(values.parentDocumentType) || undefined),
      pesel: (userParentData?.pesel || userParentData?.identity_document_type || values.parentDocumentType !== UserDocumentType.PESEL)
        ? undefined
        : (values.parentPesel || undefined),
      identityNumber: (userParentData?.pesel || userParentData?.identity_document_type || values.parentDocumentType === UserDocumentType.PESEL)
        ? undefined
        : (values.parentIdentityNumber || undefined),
      identityDocumentCountryIssuer: (userParentData?.pesel || userParentData?.identity_document_type || values.parentDocumentType === UserDocumentType.PESEL || !values.parentIdentityNumber)
        ? undefined
        : values.parentNationality,
      birthDate: (userParentData?.pesel || userParentData?.identity_document_type || values.parentDocumentType === UserDocumentType.PESEL || userParentData?.birth_date || !values.parentBirthDate)
        ? undefined
        : values.parentBirthDate,
      gender: (values.parentPesel && values.parentDocumentType === UserDocumentType.PESEL)
        ? (!userParentData?.pesel || values.parentPesel) ? (getGenderFromPesel(values.parentPesel) ?? undefined) : undefined
        : (!userParentData?.pesel || !userParentData?.gender || values.parentGender) ? (values.parentGender ?? undefined) : undefined,
      nationality: userParentData?.nationality === values.parentNationality
        ? undefined
        : (values.parentNationality || undefined),
      defaultTimezone: userParentData?.default_timezone === values.parentDefaultTimezone
        ? undefined
        : (values.parentDefaultTimezone || undefined)
    }

    const shouldUpdatePatientData = Object.values(patientPersonalData).some(val => val !== undefined)
    const shouldUpdateParentData = (Object.values(parentPersonalData).some(val => val !== undefined) && values.underageUserCheckbox)

    setLoading(true)
    try {
      if (shouldUpdatePatientData) {
        await api.request({
          ...patchUserPersonalDataConfig(userId),
          data: patientPersonalData,
        })
      }

      if(shouldUpdateParentData) {
        if (!userParentData) {
          await api.request({
            ...registerUserParentConfig,
            data: parentPersonalData,
          })
        } else {
          await api.request({
            ...updateUserParentPersonalDataConfig,
            data: parentPersonalData,
          })
        }
      }
      await dispatch(getUserData({id: userId}))
      handleClose()
    } catch (e) {
      console.error(e)
      redirectToError500Page(e)
      if (e.response?.data?.errors) {
        handleReactHookFormErrors<PersonalDataValues>(e.response.data.errors, form)
      }
    }
    setLoading(false)
  })

  const peselIsValid = !!peselValue && isValidPesel(peselValue)
  const birthDateIsValid = !!birthDateValue && isValidBirthDate(birthDateValue)

  useEffect(() => {
    if(peselIsValid) {
      form.setValue("underageUserCheckbox", !isAdultFromPesel(peselValue))
    } else if (birthDateIsValid) {
      form.setValue("underageUserCheckbox", !isAdult(birthDateValue))
    }
  },[peselValue, birthDateValue])

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t("user:personalInfo")}
      disabled={loading}
      actionButtonText={t("save")}
      actionButtonProps={{
        onClick: handleSubmit,
        startIcon: loading && <ButtonLoader position="prefix"/>,
      }}
    >
      <FormProvider {...form} >
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          {((underageUser ?? true) && !user.pesel && !benefitSystemIntegrationEnabled) ? (
            <>
              <Typography variant="body1">{t("user:underageUserMessage")}</Typography>
              <Box mt={1} mb={2}>
                <Tooltip title={(peselIsValid || birthDateIsValid) ? t("user:underageCheckboxDisabledTooltip") : ""} placement="bottom-end">
                  <FormControlLabel
                    label={<Typography variant="body2">{t("user:underageUserCheckboxLabel")}</Typography>}
                    control={
                      <Controller
                        name="underageUserCheckbox"
                        control={form.control}
                        render={({ field: {onChange, value}}) => (
                          <Checkbox
                            checked={value}
                            onChange={onChange}
                            checkedIcon={<CheckBoxIcon color={(peselIsValid || birthDateIsValid) ? "disabled" : "primary"}/>}
                            icon={<CheckBoxOutlineBlankIcon/>}
                            disabled={loading || peselIsValid || birthDateIsValid}
                          />
                        )}
                      />
                    }
                  />
                </Tooltip>
              </Box>
            </>
          ) : null}

          <Box>
            <DialogContentText classes={{ root: underageUserChecked ? coloredSubtitle : undefined}}>
              {underageUserChecked
                ? t("user:editYourDataUnderage")
                : t("user:editYourData")
              }
            </DialogContentText>

            <UserPersonalDataEditForm 
              userData={user}
              userDocumentData={userDocumentData}
              loading={loading}
            />
          </Box>

          {underageUserChecked && (
            <Box mt={2}>
              <DialogContentText classes={{ root: underageUserChecked ? coloredSubtitle : undefined }}>
                {t("user:editParentData")}
              </DialogContentText>

              <UserParentPersonalDataEditForm
                userParentData={userParentData}
                userParentDocumentData={userParentDocumentData}
                loading={loading}
                underageUserChecked={underageUserChecked}
              />
            </Box>
          )}
        </form>
      </FormProvider>
    </AppDialog>
  )
}

export default UserPersonalDataEditModal
