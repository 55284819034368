import {typography} from "../../../../themes/default/typography"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useVideoControlButtonsStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsWrapper: {
      position: "absolute",
      bottom: theme.spacing(8),
      left: 0,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0, 8),
        justifyContent: "flex-start",
      },
    },
    mitigatedViewButtonsWrapper: {
      justifyContent: "center",
      padding: theme.spacing(0, 2),
      bottom: theme.spacing(2),
    },
    button: {
      borderRadius: "50%",
      width: theme.spacing(6),
      minWidth: theme.spacing(6),
      maxWidth: theme.spacing(6),
      height: theme.spacing(6),
      padding: 0,
      background: "rgba(0, 0, 0, 0.4)",
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(3),
      },
      "&:hover, &:focus": {
        background: theme.palette.common.black,
      },
      [theme.breakpoints.up("md")]: {
        "&:first-child": {
          marginRight: "auto",
        },
        "&:last-child": {
          marginLeft: "auto",
        }
      },
    },
    closeButton: {
      width: theme.spacing(8),
      maxWidth: theme.spacing(8),
      height: theme.spacing(8),
      background: theme.palette.error.main,
      "&:hover, &:focus": {
        background: theme.palette.error.main,
      },
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(0, 6),
      }
    },
    offDeviceButton: {
      background: theme.palette.error.main,
      "&:hover, &:focus": {
        background: theme.palette.error.main,
      }
    },
    mitigatedViewButton: {
      width: theme.spacing(5),
      minWidth: theme.spacing(5),
      maxWidth: theme.spacing(5),
      height: theme.spacing(5),
      "&:first-child": {
        margin: "auto",
      },
      "&:last-child": {
        margin: 0,
        position: "absolute",
        bottom: 0,
        right: theme.spacing(2),
      }
    },
    buttonIcon: {
      ...typography.h4,
      color: theme.palette.common.white,
    },
    closeButtonIcon: {
      fontSize: "30px",
    },
  })
)
