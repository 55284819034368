import React, { FC } from "react"
import {useTranslation} from "react-i18next"

import error403Image from "../../assets/images/page-403.png"
import ErrorPageCard from "../../components/common/errorPageCard/ErrorPageCard.component"

const Error403Page: FC = () => {
  const {t} = useTranslation()

  return (
    <ErrorPageCard
      errorCode={403}
      title={t("errors:page403:title")}
      description={t("errors:page403:description")}
      image={error403Image}
      imagePath={"page-403.png"}
    />
  )
}

export default Error403Page