import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {useParams} from "react-router"
import {generatePath, Link} from "react-router-dom"
import {Box, Button, Typography, useMediaQuery} from "@material-ui/core"

import {
  useGetLabelByProbability
} from "../../../../components/thankYouPageQuestionnaire/ThankYouPageQuestionnaire.utils"
import RiskLevel from "../../../preventionDiseases/diseaseTile/risk/riskLevel/RiskLevel.component"
import {RoutePath} from "../../../../../../routes/Routes.types"
import {usePreventionDiseaseDetailsStyles} from "../../PreventionDiseaseDetails.styles"
import {useDiseaseRisk} from "./DiseaseRisk.styles"
import {Theme} from "@material-ui/core/styles"

type DiseaseRiskProps = {
  diseaseName: string;
  imageUrl: string;
  probability?: number;
}

const DiseaseRisk: FC<DiseaseRiskProps> = ({ diseaseName, imageUrl, probability }) => {
  const classes = usePreventionDiseaseDetailsStyles()
  const diseaseRiskClasses = useDiseaseRisk()
  const {t, i18n} = useTranslation()
  const { diseaseId } = useParams<{ diseaseId: string; }>()
  const probabilityLabel = useGetLabelByProbability(probability)
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"))

  return (
    <Box
      display="flex"
      flexDirection="row"
    >
      <Box>
        {
          probability !== undefined ? (
            <>
              <RiskLevel
                className={diseaseRiskClasses.riskLevelWrapper}
                probability={probability}
              />
              <Typography
                variant="body1"
              >
                {
                  t(
                    "healthPlanner:questionnaireSummaryDescription",
                    {
                      diseaseName,
                      diseaseProbabilityLabel: probabilityLabel
                    }
                  )
                }
              </Typography>
            </>
          ) : (
            <>
              <Typography
                className={classes.title}
                variant="body1"
              >
                {t("healthPlanner:fillQuestionnaire")}
              </Typography>

              <Button
                className={diseaseRiskClasses.button}
                component={Link}
                to={
                  generatePath(
                    `/${i18n.language}${RoutePath.PREVENTION_DISEASES_QUESTIONNAIRE}`,
                    { diseaseId }
                  )
                }
                variant="contained"
                color="primary"
              >
                {t("healthPlanner:goToQuestionnaire")}
              </Button>
            </>
          )
        }
      </Box>
      {
        !isMobile && (
          <img
            className={diseaseRiskClasses.image}
            src={imageUrl}
            alt="disease"
          />
        )
      }
    </Box>
  )
}

export default DiseaseRisk