import {
  ConsultationModel,
  ConsultationPaymentStatus,
  FINISHED_WITH_NO_SUCCESS_CONSULTATION_STATUS_NUMBERS
} from "../../components/consultation/Consultation.types"
import {isStationaryConsultation, isStationaryNonIntegrated} from "../../components/consultation/Consultation.utils"
import {UserDataType} from "../../store/user/user.types"
import {ConsultationEntryPermissionStatus} from "./ConsultationChatPage.types"

export const GP_SPECIALIZATION_ID = "164b5f7f-b72e-4038-a67c-5308082734a9" // id "internista"

export const getConsultationPermissionStatus = (
  consultationData:ConsultationModel,
  user: UserDataType
): ConsultationEntryPermissionStatus => {
  if (!consultationData) return ConsultationEntryPermissionStatus.NONE

  if (FINISHED_WITH_NO_SUCCESS_CONSULTATION_STATUS_NUMBERS.includes(consultationData.status_number)) {
    return ConsultationEntryPermissionStatus.ARCHIVED_WITHOUT_SUCCESS
  }

  const isStationaryType = isStationaryConsultation(consultationData.consultationType) || isStationaryNonIntegrated(consultationData.consultationType)
  const isConsultationPaid = consultationData.is_payed
  const isPoz = consultationData.is_interested_in_poz && (consultationData.specialization.id === GP_SPECIALIZATION_ID)
  const hasAllData = isPoz
    ? user.has_poz_declaration && user.hasCompletePersonalData
    : user.hasCompletePersonalData

  if (isPoz && (!hasAllData || !isConsultationPaid)) {
    return ConsultationEntryPermissionStatus.NFZ_VERIFICATION_NEEDED
  }

  /* not paid statuses */
  if (!isConsultationPaid) {
    const isPaymentInProgess = consultationData.paymentStatus && [
      ConsultationPaymentStatus.PENDING,
      ConsultationPaymentStatus.WAITNG_FOR_CONFIRMATION
    ].includes(consultationData.paymentStatus)

    if (isStationaryType) {
      return ConsultationEntryPermissionStatus.STATIONARY_CONSULTATION
    }

    if (consultationData.paymentStatus === ConsultationPaymentStatus.REFUNDED) {
      return ConsultationEntryPermissionStatus.PAYMENT_REFUNDED
    }

    return isPaymentInProgess
      ? ConsultationEntryPermissionStatus.PAYMENT_IN_PROGRESS
      : ConsultationEntryPermissionStatus.NOT_PAYED
  }
  /* e/o not paid statuses */

  if (!hasAllData) {
    return ConsultationEntryPermissionStatus.FILL_USER_DATA
  }

  return isStationaryType
    ? ConsultationEntryPermissionStatus.STATIONARY_CONSULTATION
    : ConsultationEntryPermissionStatus.SUCCESSFULLY_COMPLETED
}
