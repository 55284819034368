import React, {ChangeEvent, FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Box, TextField, Typography, useTheme} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {useDebounce} from "use-debounce/esm"

import { useGetPrescriptionsQuery } from "../../../../../../../../store/prescriptions/prescriptions.api"
import ButtonLoader from "../../../../../../../../components/common/buttonLoader/ButtonLoader.component"
import {Prescription} from "../../../../../../../../store/prescriptions/prescriptions.types"

type Props = {
  onChange(event: ChangeEvent<unknown>, newExam: null | Prescription): void;
  label: string;
  disabled: boolean;
  isErrorOccurred?: boolean;
  errorMessage?: string;
}

const PrescriptionSearchInput: FC<Props> = ({
  onChange,
  label,
  disabled,
  isErrorOccurred,
  errorMessage
}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const [query, setQuery] = useState("")
  const [debounceQuery] = useDebounce(query, 750)
  const { data = [], isLoading } = useGetPrescriptionsQuery(debounceQuery)

  // Reset query on disable
  useEffect(() => {
    if ( disabled ){
      setQuery("")
    }
  }, [disabled])
  
  const handleChange = (event: ChangeEvent<unknown>, prescription: null | Prescription | string) => {
    if ("string" === typeof prescription) {
      return
    }
    onChange(event, prescription)
  }

  const handleInputChange = (event: ChangeEvent<unknown>, value: string, reason: string) => {
    if ((reason) === "reset") {
      return
    }
    if (reason === "clear") {
      setQuery("")
      return
    }
    if (reason === "input") {
      setQuery((event as ChangeEvent<HTMLInputElement>)?.target?.value || "")
      return
    }
  }

  return (
    <>
      <Autocomplete
        onChange={handleChange}
        loading={isLoading}
        loadingText={<>
          <Box display="inline-block" mx={2}>
            <ButtonLoader/>
          </Box>
          {t("medicalQuestionnaire:prescriptionLoadingText")}
        </>}
        options={data}
        filterOptions={() => data}
        getOptionLabel={(option) => option.name}
        freeSolo
        clearText={t("exam:clear")}
        closeText={t("exam:close")}
        openText={t("exam:open")}
        handleHomeEndKeys
        onInputChange={handleInputChange}
        inputValue={query}
        noOptionsText={t("exam:noExamOptions")}
        renderOption={({ name, common_name, power, ...rest }) => (
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>{name}, <Typography component='span' color='textSecondary'>{`${common_name}, ${power}, ${rest.package}`}</Typography></Typography>
          </Box>
        )}
        disabled={disabled}
        renderInput={
          (params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              InputProps={{...params.InputProps, type: "search"}}
              error={isErrorOccurred}
              helperText={errorMessage}
            />
          )
        }
      />
      {!isLoading && data.length === 0 && query.length > 0 && (
        <Box
          bgcolor={theme.palette.common.white}
          borderRadius={theme.shape.borderRadius}
          p={2}
          mt={-0.5}
          mb={1.5}
        >
          <Typography>
            {t("exam:noExamOptions")}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default PrescriptionSearchInput
