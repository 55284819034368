import React, {FC} from "react"
import {Box} from "@material-ui/core"
import clsx from "clsx"

import DoctorImageAvatar from "../../../common/imageAvatar/doctorImageAvatar/DoctorImageAvatar.component"
import {useAvatarSectionStyles} from "./AvatarSection.styles"

interface AvatarSectionProps {
  src?: string | null;
  alt: string | null;
  className?: string | null;
}

const AvatarSection: FC<AvatarSectionProps> = ({src, alt, className}) => {
  const classes = useAvatarSectionStyles()

  return (
    <Box>
      <DoctorImageAvatar
        src={src || undefined}
        alt={alt || ""}
        className={clsx(classes.image, className ?? null)}
      />
    </Box>
  )
}

export default AvatarSection
