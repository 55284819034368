import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {useLazyQuery, useMutation} from "@apollo/client"
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date"

import {
  ADD_BLOOD_PRESSURE,
  AddBloodPressureResponse,
  AddBloodPressureVariables
} from "../../api/mutations/addBloodPressure"
import {ExaminationType} from "../../api/queries/getUserActionById"
import {
  BloodPressureMeasurementsData,
  GET_USER_BLOOD_PRESSURE_MEASUREMENTS
} from "../../api/queries/getUserBloodPressureMeasurements"
import DiaryMeasurement from "../../components/diaryMeasurement/DiaryMeasurement.component"
import {addBloodPressureSchema} from "../../components/diaryMeasurement/DiaryMeasurement.validation"
import PageWrapper from "../../components/pageWrapper/PageWrapper.component"

const genericInputData = [
  {
    name: "systolicPressure",
    unit: "mmHg"
  },
  {
    name: "diastolicPressure",
    unit: "mmHg"
  },
]

const DiaryBloodPressure: FC = () => {
  const { t } = useTranslation()

  const [getBloodPressureMeasurements, {
    data: { bloodPressureMeasurements = [] } = {},
    error: bloodPressureMeasurementsError,
    loading: bloodPressureMeasurementsLoading,
  }] = useLazyQuery<BloodPressureMeasurementsData>(GET_USER_BLOOD_PRESSURE_MEASUREMENTS, {
    context: {clientName: "HealthPlanner"},
  })

  const [addBloodPressure, {
    data: addBloodPressureData,
    loading: addBloodPressureLoading,
    error: addBloodPressureError
  }] = useMutation<AddBloodPressureResponse, AddBloodPressureVariables>(ADD_BLOOD_PRESSURE, {
    context: { clientName: "HealthPlanner" }
  })

  const addMeasurement = (value: MaterialUiPickersDate, input: string[]) => {
    addBloodPressure({
      variables: {
        createdAt: value,
        systolicPressure: input[0],
        diastolicPressure: input[1],
      }
    })
  }

  return (
    <PageWrapper
      title={t("healthPlanner:diaryTitles:bloodPressure")}
    >
      <DiaryMeasurement
        examinationType={ExaminationType.BLOOD_PRESSURE}
        diaryTitle={t("healthPlanner:diaryTitles:bloodPressure")}
        genericInputData={genericInputData}
        inputTitle={t("healthPlanner:addBloodPressure")}
        validationSchema={addBloodPressureSchema}
        getRecords={getBloodPressureMeasurements}
        addMeasurement={addMeasurement}
        data={bloodPressureMeasurements && bloodPressureMeasurements}
        dataLoading={bloodPressureMeasurementsLoading}
        dataFetchingError={bloodPressureMeasurementsError}
        addMeasurementData={addBloodPressureData}
        addMeasurementLoading={addBloodPressureLoading}
        addMeasurementError={addBloodPressureError}
      />
    </PageWrapper>
  )
}

export default DiaryBloodPressure
