import React from "react"
import { Box, Typography } from "@material-ui/core"

import {ReactComponent as InfoIcon} from "../../../assets/svg/info.svg"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import TextFieldController from "../../commonFormItems/textFieldController/TextFieldController.component"
import { CheckboxGroupSurvey } from "./CheckboxGroupSurvey/CheckboxGroupSurvey.component"
import { CollapseSurveyCheckbox } from "./CollapseSurveyCheckbox/CollapseSurveyCheckbox.component"
import { Gender, UserDataType } from "../../../store/user/user.types"
import { SurveyExtraQuestions } from "./ExtraQuestions.types"
import { useExtraQuestionsStyles } from "./ExtraQuestions.styles"

const QUESTION_FOR_WOMAN_ONLY = ["pregnant"]

export const useExtraQuestions = ( userData: UserDataType | null )=> {
  const { textarea, infoMessage } = useExtraQuestionsStyles()
  const isWoman = userData?.gender === Gender["female"]

  const renderQuestions = (questions: SurveyExtraQuestions[] | null) => questions?.map(question => {
    if(!isWoman && QUESTION_FOR_WOMAN_ONLY.includes(question.name)) {
      return null
    }

    switch(question.type) {
      case "radio":
        return (
          <CollapseSurveyCheckbox
            key={question.name}
            question={question}
            nestedItems={question.nested ? renderQuestions(question.nestedItems) : null}
          />
        )
      case "textarea":
        return (
          <Box
            key={question.name}
            mt={2}
          >
            <Typography
              variant="body1"
            >
              {question.title}
            </Typography>
            <TextFieldController
              name={question.name}
              multiline
              placeholder={"Tu wpisz wymagane informacje"}
              label=""
              InputProps={{ classes: { input: textarea } }}
            />
          </Box>
        )
      case "info":
        return (
          <Box
            key={question.name}
            display="flex"
            my={2}
          >
            <ImageByIntegrationType
              imageSrc={<InfoIcon/>}
              imagePath={"info.svg"}
              returnSvg={true}
            />
            <Typography
              className={infoMessage}
              variant="body1"
            >
              {question.title}
            </Typography>
          </Box>
        )
      case "checkboxGroup":
        return (
          <CheckboxGroupSurvey
            key={question.name}
            name={question.name}
            label={question.title}
            options={question.answers}
            parentName={question?.parentName}
          />
        )
    }
  })
  
  return {
    renderQuestions
  }
}
