import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useLoyalityProgramPageStyles = makeStyles(() => createStyles({
  iframeContainer: {
    width: "100%",
    height: "110vh",
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
  },
}))
