import i18next from "i18next"
import jwt_decode, { JwtPayload } from "jwt-decode"

import { ssoMiddlewareHost } from "../../app.config"
import store from "../../store"

export const getUserNameLabel = (B2BClinic: boolean): string => i18next.t(i18next.language !== "pl" && B2BClinic ? "b2bClinic:emailOrId" : "login:emailPeselId")

const adminRoles = [
  "ROLE_ALL_CLINICS_ADMIN",
  "ROLE_SUPER_ADMIN",
  "ROLE_ADMIN_CREATOR",
  "ROLE_ADMIN",
  "ROLE_CLINIC_ADMIN"
]

const operatorRoles = [
  "ROLE_OPERATOR",
  "ROLE_CLINIC_RECEPTION",
  "ROLE_CLINIC_ABOVE_RECEPTION",
]

const doctorRoles = [
  "ROLE_DOCTOR"
]

interface JwtTokenTypes extends JwtPayload {
  "v4-roles": string[];
}

export const getDifferentRoleRedirectUrl = (accessToken: string) => {
  let loginUrl = ""
  const decodedToken = jwt_decode<JwtTokenTypes>(accessToken)
  const userRoles = decodedToken?.["v4-roles"]
  const clinicSettings = store.getState().clinic.clinicSettings
  const clinicLoginUrl = `https://${clinicSettings.domain}/${i18next.language}`
  const userHasAdminRole = adminRoles.some(item => userRoles?.includes(item))
  const userHasOperatorRole = operatorRoles.some(item => userRoles?.includes(item))
  const userHasDoctorRole = doctorRoles.some(item => userRoles?.includes(item))
  const operatorShortLoginUrl = `${clinicLoginUrl}/clinic-operator/login`
  const adminShortLoginUrl = `${clinicLoginUrl}/verifier/login`
  const doctorShortLoginUrl = `${clinicLoginUrl}/login`
  const middlewareLogoutUrl = `${ssoMiddlewareHost}/logout?logout_redirect=${clinicLoginUrl}`
  const xTenant = clinicSettings.frontendSettings?.clinicId

  if (userHasAdminRole) {
    loginUrl = adminShortLoginUrl
  } else if (userHasOperatorRole) {
    loginUrl = operatorShortLoginUrl
  } else if (userHasDoctorRole) {
    loginUrl = doctorShortLoginUrl
  }

  if (!userRoles || !loginUrl) {
    return null
  }

  return {
    redirectLoginUrl: `${middlewareLogoutUrl}/${loginUrl.replace(`${clinicLoginUrl}/`, "")}&x-tenant=${xTenant}`,
    shortLoginUrl: loginUrl
  }
}

export const goToDedicatedLoginPage = (url: string|null) => {
  if (!url) {
    return null
  }

  window.location.href = url
}
