export enum InputType {
  PICKER = "picker",
  CHECKBOXES = "checkboxes",
  TEXT_FIELD = "textfield",
  TEXT_FIELDS = "textfields",
  NUMBER = "number",
  NUMBERS = "numbers",
  TEXTAREA = "textarea",
  DATE = "date",
}

export enum ValidationType {
  MAX = "max",
  MIN = "min",
  MIN_CHAR_COUNT = "minCharCount",
  MIN_DATE = "minDate"
}

export type Validation = {
  type: string;
  value: string;
}

export type Answer = {
  id: string;
  name: string;
  related_ids?: string[];
  validation?: Validation[];
  points?: number;
}

export type Question = {
  answers: Answer[];
  showSubtitle: boolean;
  enabled: boolean;
  subtitle: string;
  answer: string;
  id: string;
  title: string;
  type: InputType;
  skippable?: boolean;
  related_questions?: Question[]
  unit?: string;
  validation?: Validation[];
}

export type QuestionnaireTemplate = {
  points: number;
  priority: number;
  name: string;
  id: string;
  description: string;
  questions: Question[];
  completedQuestionnaireTitle?: string;
  specializationId?: string;
  calculateAnswersPoints?: boolean;
  canFilledAgain?: boolean;
}

export type CompletedAnswer = {
  id: string;
  value: string;
  name: string;
  unit?: string;
  related_ids?: string[];
}

export type CompletedQuestion = Omit<Question, "answers" | "related_questions"> & {
  answers: CompletedAnswer[];
  related_questions?: CompletedQuestion[]
}

export type CompletedQuestionnaireTemplate = Omit<QuestionnaireTemplate, "questions"> & {
  questions: CompletedQuestion[];
}