import React, {FC} from "react"
import {toast, ToastContainer} from "react-toastify"
import { Box } from "@material-ui/core"

import LoaderBox from "../../../../components/common/loaderBox/LoaderBox.component"
import {useCheckSignDeclarationAction, useSetGender, useUserRegister} from "./HealthPlannerProvider.utils"
import {useHealthPlannerProviderStyles} from "./HealthPlannerProvider.styles"

const HealthPlannerProvider: FC = ({ children }) => {
  const classes = useHealthPlannerProviderStyles()
  const { isRegistering } = useUserRegister()
  const { isChecking } = useCheckSignDeclarationAction()
  const { isSetting } = useSetGender()

  const isLoading = isRegistering || isChecking || isSetting

  return (
    <Box className={classes.container}>
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={4000}
        hideProgressBar
        closeOnClick={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {
        isLoading || isChecking
          ? <LoaderBox boxType="appDialog" />
          : children
      }
    </Box>
  )
}

export default HealthPlannerProvider
