import {boxShadows} from "../../../../themes/shadows"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useMenuItemsStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      color: theme.palette.text.primary,
      borderRadius: theme.shape.buttonBorderRadius,
      marginBottom: theme.spacing(2),
      transition: "background .3s ease-in-out",
      "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        backgroundColor: theme.palette.background.paper,
        boxShadow: boxShadows.shadow1,
        pointerEvents: "none",
      },
      "&:hover, &:focus": {
        backgroundColor: theme.palette.background.appBar,
        textDecoration: "none",
      }
    },
    disabled: {
      pointerEvents: "none",
    },
    icon: {
      color: theme.palette.text.primary,
      fontSize: "24px",
    }
  })
)
