import {useTranslation} from "react-i18next"

import {QuestionAnswer, Sex} from "../../../MedicalQuestionnaireStepperForm.types"

export const useTranslateOptions = () => {
  const { t } = useTranslation()

  const questionAnswerOptions = [
    {
      label: t("prescription:yesLabel"),
      value: QuestionAnswer.YES
    },
    {
      label: t("prescription:noLabel"),
      value: QuestionAnswer.NO
    },
  ]

  const sexOptions = [
    {
      label: t("user:female"),
      value: Sex.FEMALE,
    },
    {
      label: t("user:male"),
      value: Sex.MALE,
    }
  ]

  return {
    questionAnswerOptions,
    sexOptions
  }
}
