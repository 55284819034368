import i18next from "i18next"
import * as yup from "yup"

export interface ExamsRegulationsFormValues {
  referralConfirmation: boolean | undefined;
}

export const ExamsRegulationsValidationSchema = (): yup.SchemaOf<ExamsRegulationsFormValues> => yup.object().shape({
  referralConfirmation: yup.boolean().oneOf([true], i18next.t("errors:required")),
})
