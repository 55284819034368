import i18n from "i18next"
import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects"

import api from "../../api/api"
import { getMedicalDocumentsConfig } from "../../api/routes"
import { selectUserId } from "../session/session.selectors"
import { MedicalDocuments } from "../../components/documentation/Documentation.type"
import { redirectToError500Page } from "../../utils/handleErrors"

import {
  getPatientDocumentation,
  setPatientDocumentation,
  setPatientDocumentationError,
} from "./patientDocumentation.slice"

interface PatientMedicalDocumentsResponse {
  items: MedicalDocuments[];
  total?: number;
}

const getPatientMedicalDocuments = async (userId: string) => {
  const consultationPagePath = `/${i18n.language}/consultation/`
  const currentPath = window.location.pathname
  let consultationId = null

  if(currentPath.includes(consultationPagePath)) {
    consultationId = currentPath.replace(consultationPagePath, "")
  }

  return await api.request<PatientMedicalDocumentsResponse>({...getMedicalDocumentsConfig, params: consultationId ? {
    consultation: consultationId,
    lang: i18n.language
  } : {
    patient: userId,
    lang: i18n.language
  }})
}

function* getPatientDocumentationSaga() {
  const userId: ReturnType<typeof selectUserId> = yield select(selectUserId)
  if (!userId) {
    return
  }

  try {
    const {data: {items}} = yield call(getPatientMedicalDocuments, userId)
    yield put(setPatientDocumentation(items))
  } catch (e) {
    yield put(setPatientDocumentationError({
      data: e.response.data,
      status: e.response.status,
    }))
    redirectToError500Page(e)
  }

}

export default function* (): SagaIterator {
  yield takeLatest(getPatientDocumentation, getPatientDocumentationSaga)
}
