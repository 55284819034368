import {isDevApiEnv} from "../app.config"

interface useGetSubdomainNameType {
  subdomain: string;
}

const useGetSubdomainName = (): useGetSubdomainNameType => {
  const subdomain = isDevApiEnv ? "staging-dashboard." : `${window.location.hostname.split(".")?.[0]}.`

  return {
    subdomain
  }
}

export default useGetSubdomainName
