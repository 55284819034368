import React, {FC} from "react"

import useGoToConsultationPage from "../../../../../../hooks/useGoToConsultationPage"
import {PozFormDetailedSource} from "../../../../../../services/analytics/PozFormDetailedSource.enum"
import ActionButtons from "../actionButtons/ActionButtons.component"
import PreventionActionWrapper from "../preventionActionWrapper/PreventionActionWrapper.component"

type SignDeclarationProps = {
  title: string;
  description: string;
}

const SignDeclaration: FC<SignDeclarationProps> = ({ title, description }) => {
  const { goToMakeConsultationPage } = useGoToConsultationPage({visitDatePicker: false, detailedSource: PozFormDetailedSource.PP_SETTINGS})

  return (
    <PreventionActionWrapper
      title={title}
      description={description}
    >
      <ActionButtons
        onClick={goToMakeConsultationPage}
      />
    </PreventionActionWrapper>
  )
}

export default SignDeclaration
