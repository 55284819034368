import {makeStyles} from "@material-ui/core"

import {menuShadows} from "../../../../themes/shadows"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useMobileMenuStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    top: "auto",
    bottom: 0,
    boxShadow: menuShadows.shadow2,
  },
  toolbar: {
    height: theme.spacing(8),
    minHeight: theme.spacing(8),
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    width: theme.spacing(10),
    height: theme.spacing(10),
    top: theme.spacing(-5),//-50% height
    left: 0,
    right: 0,
    margin: "0 auto",
    boxShadow: theme.shadows[0],
  },
  disabledFabButton: {
    pointerEvents: "none",
    backgroundColor: theme.palette.grey["400"],
  },
  fabButtonLabel: {
    flexDirection: "column",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  fabButtonIcon: {
    fontSize: "24px",
  },
  fabButtonText: {
    fontSize: "10px",
    lineHeight: "12px",
    textAlign: "center",
  },
  link: {
    width: theme.spacing(12),
    textDecoration: "none",
    padding: theme.spacing(1),
    borderRadius: theme.shape.buttonBorderRadius,
    position: "relative",
    "&:nth-child(2)": {
      marginRight: theme.spacing(2),
    },
    "&:nth-child(3)": {
      marginLeft: theme.spacing(2),
    },
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.appBar,
    },
    "&.active::after": {
      content: "''",
      position: "absolute",
      bottom: 0,
      left: theme.spacing(0.5),
      right: theme.spacing(0.5),
      height: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.shape.buttonBorderRadius,
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: ".6",
  },
  linkIcon: {
    fontSize: "24px",
  },
  linkText: {
    textAlign: "center",
  },
}))
