import i18next from "i18next"

import { analytics, LogEventType } from "../../../../services/analytics"
import {MenuItemType} from "../MenuItems.types"

import usePreventionNestedMenuListItems from "./nestedMenu/preventionNestedMenu/PreventionNestedMenu.config"

export const useMenuElements = (
  B2BClinic: boolean,
  language: string,
) => {
  const {makePreventionNestedMenuItemList} = usePreventionNestedMenuListItems()
  
  const renderMenuElements = (): MenuItemType[] => {
    const listItemsArray: MenuItemType[] = [
      {
        text: "medicalConsultation",
        iconClass: "icon-stetoscope",
        navigateTo: `/${language}`,
        B2BClinicVisibility: true,
        onClickHandler: () => analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_MENU_CONSULTATIONS_CLICK),
      },
      {
        text: "documentation",
        iconClass: "icon-archive",
        navigateTo: `/${language}/documentation`,
        B2BClinicVisibility: true,
        onClickHandler: () => analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_MENU_DOCUMENTATION_CLICK),
      },
      {
        text: "prevention",
        iconClass: "icon-umbrella",
        nestedItems: makePreventionNestedMenuItemList(),
        B2BClinicVisibility: true,
        onClickHandler: () => analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_MENU_HEALTHPLANNER_CLICK),
        skipIconOverwrite: true
      },
      {
        text: "help",
        iconClass: "icon-lifebuoy",
        navigateTo: `https://telemedi.com/${i18next.language}/faq/`,
        isExternalLink: true,
        onClickHandler: () => analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_MENU_HELP_CLICK),
        skipIconOverwrite: true
      }
    ]
    return B2BClinic
      ? listItemsArray.filter(item => item.B2BClinicVisibility === true)
      : listItemsArray 
  }

  return {
    renderMenuElements
  }
  
}
