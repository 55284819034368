import {useTranslation} from "react-i18next"

import {EmploymentType} from "../../../MedicalQuestionnaireStepperForm.types"

export const useTranslateOptions = () => {
  const {t} = useTranslation()

  const employmentTypeOptions = [
    {
      value: EmploymentType.CIVILIAN_PERSONNEL,
      label: t("user:employmentTypes:civilianPerson")
    },
    {
      value: EmploymentType.STUDENT,
      label: t("user:employmentTypes:student")
    },
    {
      value: EmploymentType.UNIFORMED_SERVICES,
      label: t("user:employmentTypes:uniformedServices")
    },
    {
      value: EmploymentType.FOREIGN_COMPANY,
      label: t("user:employmentTypes:foreignCompany")
    },
  ]

  return {
    employmentTypeOptions
  }
}
