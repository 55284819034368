import React, {FC, useEffect, useState} from "react"

import {useAppDispatch, useAppSelector} from "../../hooks/storeHooks"
import {logInReset} from "../../store/logIn/logIn.slice"
import {selectLoginAccounts} from "../../store/loginAccounts/loginAccounts.selectors"
import {clearLoginAccounts, setSelectedLoginAccount} from "../../store/loginAccounts/logInAccounts.slice"
import LoginAccountsModal from "./LoginAccountsModal.component"
import {LoginAccount} from "../../store/loginAccounts/loginAccounts.types"

const WrapperLoginAccountModal: FC = () => {
  const dispatch = useAppDispatch()
  const users = useAppSelector(selectLoginAccounts)

  const [open, setOpen] = useState<boolean>(false)
  const handleClose = () => {
    dispatch(logInReset())
    setOpen(false)
    dispatch(clearLoginAccounts())
  }
  const chooseAccount = (user: LoginAccount) => {
    dispatch(setSelectedLoginAccount(user))
    handleClose()
  }

  useEffect(() => {
    if (users.length > 0) {
      setOpen(true)
    }
  }, [users])

  return (
    <LoginAccountsModal
      open={open}
      users={users}
      handleClose={handleClose}
      chooseAccount={chooseAccount}
    />
  )
}

export default WrapperLoginAccountModal
