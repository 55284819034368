
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useMessengerThreadListStyles = makeStyles((theme: Theme) =>
  createStyles({
    threadList: {
      display: "flex",
      flexDirection: "column",
      background: theme.palette.background.paper,
      borderRadius: "24px",
      overflow: "hidden",
      margin: theme.spacing(2, 0)
    },
  })
)
