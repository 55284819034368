import {RequestedItem} from "../../../../store/doz/doz.types"
import {DozItem} from "../../../prescription/Prescription.types"

export const calculateFullPrice = (
  dozMedicinesMap: Map<number, DozItem>,
  requestedItems: RequestedItem[]
): string => {
  const price = requestedItems.reduce<number>(
    (previousValue, currentValue) => {
      const dozItemPrice = (dozMedicinesMap.get(currentValue.ean)?.price ?? 0) * currentValue.quantity
      return previousValue + dozItemPrice
    },
    0
  )

  return price.toFixed(2)
}