import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import { useDispatch } from "react-redux"
import { useTheme } from "@material-ui/core"

import { useAppSelector } from "../../../../hooks/storeHooks"
import { selectLoading, selectUserAddressEditModalOpen } from "../../../../store/user/user.selectors"
import { setUserAddressEditModalOpen } from "../../../../store/user/user.slice"
import UserDataListItem from "../../common/UserDataListItem"
import LoaderBox from "../../../common/loaderBox/LoaderBox.component"
import UserDataInfoSection from "../../common/UserDataInfoSection.component"
import UserAddressDataEditModal from "./UserAddressDataEditModal/UserAddressDataEditModal.component"
import {UserDataType} from "../../../../store/user/user.types"

interface UserAddressDataProps {
  user: UserDataType;
  underageUserWithRegisteredParent: boolean;
  patientEditAllowed: boolean
}

const UserAddressData: FC<UserAddressDataProps> = ({
  user,
  underageUserWithRegisteredParent,
  patientEditAllowed
}) => {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const theme = useTheme()
  const loading = useAppSelector(selectLoading)
  const userAddressEditModalOpen = useAppSelector(selectUserAddressEditModalOpen)

  const handleOpenCloseUserAddressEditModal = (open: boolean): void => {
    dispatch(setUserAddressEditModalOpen(open))
  }

  const userStreetAddress = (() => {
    if (!user.address) return "—"
    let streetAddress = `${user.address?.street || ""} ${user.address?.house_number || ""}`
    if (user.address?.apartment_number) {
      streetAddress += ` / ${user.address.apartment_number}`
    }
    return streetAddress
  })()

  const userCityAddress = (() => {
    if (user.address?.postal_code && user.address?.city) {
      return `${user.address?.postal_code || ""}, ${user.address?.city || ""}`
    }
    return user.address?.city || "—"
  })()

  if (loading) {
    return (
      <UserDataInfoSection
        title={t("user:addressInfo")}
      >
        <LoaderBox size={theme.spacing(8)} thickness={2}/>
      </UserDataInfoSection>
    )}

  return (
    <>
      <UserDataInfoSection
        title={underageUserWithRegisteredParent ? t("user:patientAddressInfo") : t("user:addressInfo")}
        withButton={patientEditAllowed}
        onClick={() => handleOpenCloseUserAddressEditModal(true)}
        buttonText={t("edit")}
      >
        <UserDataListItem
          label={t("user:street")}
          description={userStreetAddress}
          descriptionProps={{noWrap: true}}
        />

        <UserDataListItem
          label={t("user:city")}
          description={userCityAddress}
          descriptionProps={{noWrap: true}}
        />

        <UserDataListItem
          label={t("user:country")}
          description={user.address?.country || "—"}
          descriptionProps={{noWrap: true}}
        />
      </UserDataInfoSection>

      <UserAddressDataEditModal
        userData={user}
        userParentData={user?.user_parent}
        underageUserWithRegisteredParent={underageUserWithRegisteredParent}
        open={userAddressEditModalOpen}
        onClose={() => handleOpenCloseUserAddressEditModal(false)}
      />
    </>
  )
}

export default UserAddressData
