import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useNfzResignPageStyles = makeStyles(() => createStyles({
  iframe: {
    width: "100%",
    height: "100%",
    minHeight: "700px",
    border: "none"
  }
}))
