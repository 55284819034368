import {createStyles, makeStyles} from "@material-ui/core"

export const useAlabListStyles = makeStyles(theme => createStyles({
  root: {
    marginTop: theme.spacing(2),
  },
  loadMoreLabel: {
    marginTop: theme.spacing(3),
    alignSelf: "flex-end",
    cursor: "pointer"
  }
}))