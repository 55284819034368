import {useState} from "react"
import {useHistory, useParams} from "react-router"
import {useMutation, useQuery} from "@apollo/client"

import {ADD_ANSWER, AddAnswerResponse, AddAnswerVariables} from "../../api/mutations/addAnswer"
import {
  COMPLETE_ACTION,
  CompleteActionResponse,
  CompleteActionVariables
} from "../../api/mutations/completeAction"
import {
  GET_USER_ACTIONS_BY_QUESTIONNAIRE_ID, UserActionsByQuestionnaireIdResponse,
  UserActionsByQuestionnaireIdVariables
} from "../../api/queries/getUserActionsByQuestionnaireId"
import {RoutePath} from "../../../../routes/Routes.types"
import {CompletedQuestionnaireTemplate} from "./genericStepForm/GenericStepForm.types"

import { BASIC_QUESTIONNAIRE_ID } from "./PreventionQuestionnaire.config"

export const usePreventionQuestionnaireManager = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { questionnaireId } = useParams<{ questionnaireId: string }>()
  const { push } = useHistory()

  const {
    data: templateData,
    loading: isLoadingTemplate,
    error: questionnaireTemplateError
  } = useQuery<
  UserActionsByQuestionnaireIdResponse,
  UserActionsByQuestionnaireIdVariables
  >(
    GET_USER_ACTIONS_BY_QUESTIONNAIRE_ID,
    {
      variables: {
        questionnaireId,
      },
      onCompleted: (data) => {
        const isUserAlreadyCompleteQuestionnaire = data?.usersActions.length === 0

        if (isUserAlreadyCompleteQuestionnaire) {
          push(RoutePath.PREVENTION)
        }
      },
      context: { clientName: "HealthPlanner" }
    }
  )
  
  const [
    handleCompleteAction,
    {
      data: completeActionResponse,
      error: completeActionError
    }
  ] = useMutation<
  CompleteActionResponse,
  CompleteActionVariables
  >(
    COMPLETE_ACTION,
    {
      context: { clientName: "HealthPlanner" }
    }
  )

  const [
    handleSendAnswer,
    {
      error: addAnswerError
    }
  ] = useMutation<
  AddAnswerResponse,
  AddAnswerVariables
  >(
    ADD_ANSWER,
    {
      context: { clientName: "HealthPlanner" }
    }
  )

  const handleSubmit = async (data: CompletedQuestionnaireTemplate) => {
    setIsLoading(true)

    await handleSendAnswer({
      variables: {
        templateId: questionnaireId,
        answer: data
      }
    })
    await new Promise(resolve => setTimeout(resolve, 2000))//temporary solution TODO: set subscription for disease probability
    await handleCompleteAction({
      variables: {
        actionId: templateData?.usersActions[0]?.id ?? ""
      }
    })
      
    setIsLoading(false)
  }

  return {
    //TODO: https://telemedico.atlassian.net/browse/LESS-1615
    isBasicQuestionnaire: questionnaireId === BASIC_QUESTIONNAIRE_ID,
    isErrorOccurred: questionnaireTemplateError ?? completeActionError ?? addAnswerError,
    isSuccess: completeActionResponse?.completeAction?.status,
    points: templateData?.usersActions[0]?.action.points ?? 0,
    template: templateData?.usersActions[0]?.action.fillQuestionnaire.questionnaireTemplate.template,
    isLoading,
    handleSubmit,
    isLoadingTemplate,
  }
}