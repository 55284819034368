import { createStyles, makeStyles } from "@material-ui/core"

import { Theme } from "@material-ui/core/styles"

export const useActionsListStyles = makeStyles((theme: Theme) => createStyles({
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    maxWidth: "800px",
    width: "100%",
    height: "100%",
    background: theme.palette.background.paper,
  },
  tab: {
    borderBottom: `2px solid ${theme.palette.grey[400]}`,
    "&.Mui-selected": {
      color: theme.palette.primary.main
    }
  },
  imageWrapper: {
    flex: "1",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "100%",
  },
}))