import i18n from "i18next"

export const getFormattedBirthDate = (birthDate: Date, months?: number): string => {
  if (months) {
    birthDate.setMonth(birthDate.getMonth() + months)
  }
  
  return birthDate.toLocaleDateString(i18n.language, {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  }).replace(/\./g, "/")
}