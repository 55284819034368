import React, { FC } from "react"
import { Controller } from "react-hook-form"
import { useFormContext } from "react-hook-form"
import { TextField } from "@material-ui/core"
import { StandardTextFieldProps } from "@material-ui/core/TextField/TextField"

interface TextFieldControllerProps extends StandardTextFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  isRequired?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  inputProps?: any;
}

const TextFieldController: FC<TextFieldControllerProps> = (
  {
    name,
    label,
    placeholder = label,
    isRequired = false,
    fullWidth = true,
    disabled = false,
    inputProps,
    ...props
  }) => {
  const form = useFormContext()

  return (
    <Controller
      name={name}
      control={form.control}
      render={(
        {
          field: {
            onChange,
            value
          },
          fieldState: {
            error,
            invalid,
          }
        }) => (
        <TextField
          value={value}
          label={isRequired ? label + " *" : label}
          placeholder={placeholder}
          InputProps={inputProps}
          disabled={disabled}
          error={invalid}
          helperText={error?.message}
          inputProps={{"id": name}}
          fullWidth={fullWidth}
          onChange={onChange}
          {...props}
        />
      )}
    />
  )
}

export default TextFieldController
