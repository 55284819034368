import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, TextField} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"

import {useToggle} from "../../../../../hooks/useToggle"
import {Disease} from "../../../api/queries/getUserDiseasesFilteredByIds"
import AppDialog from "../../../../../components/common/appDialog/AppDialog.component"
import DiseaseTile from "../diseaseTile/DiseaseTile.component"
import ConfirmationModal from "./confirmationModal/ConfirmationModal.component"
import {useAddDiseases, useSearchDisease} from "./AddDiseaseModal.utils"
import {useAddDiseaseModalStyles} from "./AddDiseaseModal.styles"

type AddDiseaseModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  diseases: Disease[];
}

const AddDiseaseModal: FC<AddDiseaseModalProps> = ({ isOpen, onClose, diseases }) => {
  const {t} = useTranslation()
  const classes = useAddDiseaseModalStyles()
  const [isConfirmationModalOpen, { handleToggle }] = useToggle()
  const {
    chosenDiseaseIds,
    handleChangeDisease,
    updateUserDiseases,
    handleClearStateOnClose,
    isLoading
  } = useAddDiseases(onClose)
  const {
    searchValue,
    handleChange,
    handleClose,
    filteredDiseases
  } = useSearchDisease(diseases, handleClearStateOnClose)

  return (
    <AppDialog
      open={isOpen}
      onClose={handleClose}
      title={t("healthPlanner:addDiseaseDialogTitle")}
      actionButtonText={t("healthPlanner:add")}
      actionButtonProps={{
        onClick: handleToggle(true),
        disabled: chosenDiseaseIds.length === 0
      }}
    >
      <Box>
        <TextField
          className={classes.searchInput}
          fullWidth
          value={searchValue}
          size="small"
          placeholder={t("healthPlanner:searchDiseases")}
          onChange={handleChange}
          InputProps={{
            endAdornment: <SearchIcon/>
          }}
        />
        <Box className={classes.diseasesWrapper}>
          {
            filteredDiseases.map(disease => (
              <DiseaseTile
                id={disease.id}
                onClick={handleChangeDisease(disease.id)}
                isActive={chosenDiseaseIds.includes(disease.id)}
                isLinkDisabled
                key={disease.id}
                name={disease.name}
                imageUrl={disease.imageUrl}
              />
            ))
          }
        </Box>
      </Box>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={handleToggle(false)}
        onConfirm={updateUserDiseases}
        isConfirmButtonLoading={isLoading}
        diseasesNames={
          chosenDiseaseIds.map(chosenDiseaseId =>
            diseases.find(disease => disease.id === chosenDiseaseId)?.name ?? ""
          )
        }
      />
    </AppDialog>
  )
}

export default AddDiseaseModal
