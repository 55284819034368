import React, {FC, ReactNode} from "react"
import {Box, Divider, Typography} from "@material-ui/core"

import {useFormSectionStyles} from "./FormSection.styles"

type Props = {
  title: ReactNode;
  description?: string;
  children: ReactNode;
}

const FormSection: FC<Props> = ({ title, description, children }) => {
  const classes = useFormSectionStyles()
  return (
    <Box
      mb={3}
    >
      {title}
      {
        description && (
          <Typography
            className={classes.description}
            variant='body1'
            color='textSecondary'
          >
            {description}
          </Typography>
        )
      }
      {children}
      <Divider
        className={classes.divider}
      />
    </Box>
  )
}

export default FormSection
