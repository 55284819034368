import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import { Box, Typography } from "@material-ui/core"
import clsx from "clsx"

import {ReactComponent as CheckIcon} from "../../../assets/svg/check-fill.svg"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import { EXAMS_PURCHASE_STATUSES } from "../Referral.types"
import {useReferralSectionStyles} from "../ReferralSection.styles"

interface ReferralPurchaseStatusProps {
  totalExams: number;
  boughtExams: number;
}

const ReferralPurchaseStatus: FC<ReferralPurchaseStatusProps> = ({
  totalExams,
  boughtExams,
}) => {
  const {t} = useTranslation()
  const classes = useReferralSectionStyles()

  const getReferralPurchaseStatus = (totalExams: number, boughtExams: number) => {
    if (totalExams === boughtExams) {
      return {
        status: EXAMS_PURCHASE_STATUSES.ALL,
        label: t("referrals:examsBought"),
      }
    } else if (boughtExams > 0 && boughtExams < totalExams) {
      return {
        status: EXAMS_PURCHASE_STATUSES.PARTIALLY,
        label: t("referrals:examsPartiallyBought"),
      }
    }

    return {
      status: EXAMS_PURCHASE_STATUSES.NONE
    }
  }

  const referralPurchaseStatus = getReferralPurchaseStatus(totalExams, boughtExams)

  if (referralPurchaseStatus.status === EXAMS_PURCHASE_STATUSES.NONE) {
    return null
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      className={classes.referralPurchaseStatusContainer}
    >
      <Box className={clsx(classes.checkIcon, "whiteVariant")}>
        <ImageByIntegrationType
          imageSrc={<CheckIcon className={clsx(classes.checkIconImg, "whiteVariant")}/>}
          imagePath={"check-fill.svg"}
          className={clsx(classes.checkIconImg, "whiteVariant")}
          returnSvg={true}
        />
      </Box>
      <Typography variant="h4">
        { referralPurchaseStatus.label }
      </Typography>
    </Box>
  )
}

export default ReferralPurchaseStatus
