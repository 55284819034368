import { alpha, makeStyles } from "@material-ui/core"

import { colors } from "../../../../themes/colors"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useLangsListStyles = makeStyles((theme: Theme) => createStyles({
  langButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.background.default,
    borderRadius: 0,
    transition: "transform .3s",
    height: theme.spacing(7),
    padding: theme.spacing(0.5, 2, 0),
    "&:hover, &:focus": {
      background: colors.telemediGray12,
    },
    "&:not(:last-child)": {
      boxShadow: "inset 0px -1px 0px #EBEBEB",
    },
    "&:first-child": {
      borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    }
  },
  currentLangButton: {
    "&:hover, &:focus": {
      background: theme.palette.background.default,
    },
  },
  buttonOnBaseLayout: {
    background: "transparent",
    "&:hover, &:focus": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
  flagAndTextWrapper: {
    display: "flex",
  },
  image: {
    marginRight: theme.spacing(3),
  },
  menuItem: {
    padding: 0
  }
}))
