import {add} from "date-fns"

import {EXAMINATION_EXPIRATION_TIME_IN_DAYS} from "../components/consultation/Consultation.config"
import {ExamModel} from "../components/exam/Exam.types"

import { isAfterDate } from "./dateFns/isAfterDate"

export const filterOutdatedExams = (exams: ExamModel[]): ExamModel[] =>
  exams.filter(exam => !isAfterDate(
    new Date(),
    add(new Date(exam.created_at.replaceAll("-", "/")), { days: EXAMINATION_EXPIRATION_TIME_IN_DAYS })
  ))