import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useCollapseWrapperStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  collapse: {
    position: "relative",
    "&::after": {
      content: "''",
      position: "absolute",
      height: "74px",
      width: "100%",
      bottom: "0",
      left: "0",
      background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 75.71%)",
      transition: "opacity 300ms",
      opacity: "0",
      pointerEvents: "none",
    }
  },
  isClosed: {
    "&::after": {
      opacity: "1"
    }
  },
  buttonSection: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    }
  },
  buttonSectionLeft: {
    justifyContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      marginBottom: "-30px",
      marginTop: theme.spacing(3),
    }
  },
  buttonSectionRight: {
    marginBottom: theme.spacing(3),
    justifyContent: "flex-end",
  }
}))
