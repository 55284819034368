import {createStyles, makeStyles} from "@material-ui/core"

import {typography} from "../../themes/default/typography"

export const useSickLeaveSectionStyles = makeStyles(theme => createStyles({
  pageSection: {
    margin: "auto",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    padding: theme.spacing(3, 3, 2, 3),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      padding: theme.spacing(8, 8, 6, 8),
    },
  },
  countrySelect: {
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  textarea: {
    minHeight: 230,
    maxHeight: 230,
    borderRadius: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    ...typography.body1
  },
  checkboxOtherAddress: {
    paddingLeft: 0,
  },
  label: {
    margin: theme.spacing(2, 0, 0, 1),
  },
  nipField: {
    maxWidth: "564px",
  },
  addIcon: {
    top: "6px",
    left: "15px",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      top: "29px",
      left: theme.spacing(3)
    }
  },
  trashIcon: {
    top: "4px",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      top: "27px",
      left: theme.spacing(2)
    }
  },
  addNewItemText: {
    position: "relative",
    left: theme.spacing(2.5),
    [theme.breakpoints.up("md")]: {
      top: theme.spacing(3),
      left: theme.spacing(4)
    },
    fontSize: "14px",
  },
  uploadDropzone: {
    borderRadius: theme.spacing(1),
    width: "100%",
  },
  disabled: {
    overflow: "hidden",
    color: theme.palette.text.secondary,
    background: theme.palette.grey[100]
  },
  loading: {
    opacity: .5,
    pointerEvents: "none"
  },
  error: {
    color: theme.palette.error.main,
    "& svg": {
      "& path": {
        stroke: theme.palette.error.main,
      }
    }
  },
  summaryContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(8),

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    }
  },
  summaryContent: {
    borderRight: `2px solid ${theme.palette.grey[100]}`,
    width: "50%",
    "&:last-child": {
      borderRight: "none",
      paddingLeft: theme.spacing(5),
    },

    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
      width: "100%",
      "&:last-child": {
        paddingLeft: 0,
      }
    }
  },
  summaryLabel: {
    color: theme.palette.primary.main,
  },
  summaryValue: {
    margin: theme.spacing(1, 0, 3, 2),
    whiteSpace: "pre-line"
  },
  button: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginRight: theme.spacing(2),
    }
  },
  confirmActions: {
    display: "flex",
    justifyContent: "flex-end",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column-reverse"
    }
  },
  hidden: {
    display: "none"
  },
  orderInfo: {
    "& .MuiBox-root .MuiCheckbox-root": {
      alignSelf: "flex-start",
      paddingTop: 0,
    }
  },
  datePicker: {
    "& > div": {
      width: "100%"
    }
  },
  datePickerRoot: {
    padding: 0
  },
  calendar: {
    padding: 0,
    "& .MuiPickersDay-day": {
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(4)
      }
    },
    "& .MuiPickersDay-daySelected": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPickersBasePicker-pickerView, & .MuiPickersYearSelection-container, & .MuiPickersMonthSelection-container": {
        minWidth: "260px",
        maxWidth: "260px"
      },
      "& .MuiTextField-root": {
        width: "100%"
      }
    },
  },
  calendarInput: {
    width: "100%",
    "&& input": {
      textAlign: "left",
      fontWeight: 500,
    },
    "&& fieldset": {
      border: `1px solid ${theme.palette.grey[200]}`
    },
  },
  calendarIcon: {
    "& path": {
      stroke: theme.palette.text.primary
    }
  },
  checkAllFields: {
    position: "relative",
    left: "-10px"
  },
  allRequiredFieldsLabelL: {
    marginTop: theme.spacing(1),
    cursor: "pointer"
  }
}))
