import { createStyles, makeStyles, Theme } from "@material-ui/core"

import { colors } from "../../../../themes/colors"

export const useAwardsTileStyles = makeStyles((theme: Theme) => createStyles({
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "382px",
    width: "100%",
    height: "100%",
    background: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      height: "auto",
    },
  },
  arcProgressWrapper: {
    position: "relative",
    alignSelf: "center",
    marginTop: theme.spacing(3),
    maxHeight: "130px",
  },
  arcProgressWrapperRotated: {
    position: "relative",
    transform: "rotate(-90deg)",
    width: "200px",
  },
  bottomArc: {
    position: "relative",
    color: colors.telemediGray12,
  },
  topArc: {
    color: colors.telemediGreen,
    animationDuration: "550ms",
    position: "absolute",
    top: 0,
    left: 0,
  },
  pointsLabel: {
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  points: {
    position: "absolute",
    top: "60%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  nextAwardInfo: {
    position: "absolute",
    top: "90%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    whiteSpace: "nowrap",
  },
}))