import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {Reducer} from "redux"

import {ChannelType, ClinicSettingsState} from "./clinicSettings.types"

const initialState: ClinicSettingsState = {
  clinicSettings: {
    "domain": "",
    "collectMedicalDocument": false,
    "frontendSettings": {
      vPozMedicalSurveyEnabled: false,
      "clinicId": "",
      "__typename": "",
      "edges": [],
      "reactAppClinicType": "",
      "enableBenefitSystemIntegration": false,
      "disableReferralsInDashboard": false,
      enableHealthPlannerQuestionnaire: false,
      enableHealthPlannerFeature: false,
      enabledTelemediGo: false,
      enabledTelemediGoInEprescription: false,
      enabledTelemediGoInSickLeave: false,
      enabledTelemediGoForInternist: false,
      showPhoneNumberOnCompleteDataView: false,
      hideChangePassword: false,
      contactPhoneNumber: "",
      hideConsultationBooking: false,
      modules: [],
      "style": {
        "mainColor": "",
        "hoverColor": "",
        "lightBgColor": "",
        "gradientColor": "",
        "chatBgColor": "",
        "textDetailColor": "",
        "dashboardBackgroundColor": "",
        "dashboardHeaderColor": "",
        "dashboardFooterColor": "",
        "dashboardUserDotColor": "",
        "dashboardFooterTextColor": "",
        "buttonBorderRadius": "",
        "dynamicStylesEnabled": false,
        "dynamicImagesEnabled": false,
        "dynamicIconsEnabled": false,
        "dynamicFontsEnabled": false,
        "mainClinicLogoInFooterEnabled": false
      },
      "images": {
        "logo": "",
        "logoLogin": "",
        "logoWatermark": "",
        "favicon": ""
      }
    },
    "prescriptionsSettings": {
      "medicalDrugLimit": 1,
      "medicalDrugsTotalLimit": 1,
      "modules": ["1"],
      enableCheckMedicineExposed: false,
      pozGlobalMedicalDrugLimit: 1,
      pozGlobalMedicalDrugsTotalLimit: 1,
      otherMessagesForB2bClinicEnabled: false
    },
    widgetConsbookSettings: {
      enableMedicalDocumentationReminderInfo: false,
      enablePaymentProviderInfo: false,
      enableAddVoucherInSummaryFeature: false,
      enableSmallSlotsFeature: false,
      enableInstantChatFeature: false,
      defaultChannelType: ChannelType.TYPE_CHAT
    },
    consultationListSettings: {
      enableReferralPriceInfo: false,
    },
    examCartSettings: {
      enableReferralBasketFeature: false,
    },
    notificationSettings: {
      magicLinks: []
    },
    clinicSignalIdunaSettings: {
      insuranceVerificationEnabled: false,
      dashboardLaboratoryModuleEnabled: false,
    },
    name: "",
    "isB2b": false,
    "__typename": "",
    "id": "",
    "stripePublicKey": "",
    "pozformB2BActive": false,
    "apiUrl": "",
    "allowRegistration": true,
    "patientEditAllowed": true,
    "hasBookConsultationAbility": true,
    "showPrices": false,
    "stationaryVisitPatient": false,
    "buyExamButtonEnabled": false,
    "enabledLanguages": {
      "languages": [],
    },
    "seo": {
      "title": "",
      "description": ""
    },
    widgetRegisterSettings: {
      linkToRegulations: "",
      linkToPrivacyPolicy: "",
      showAreaCodeForLogin: false
    },
    sickLeaveSettings: {
      modules: ["1"]
    },
    patientLaboratories: [],
    clinicAllianzSettings: {
      ewkaVerificationEnabled: false,
      onyxVerificationEnabled: false
    },
    clinicVoucherSettings: {
      voucherToBuy: null
    },
    clinicHealthPreventionSettings: {
      programs: []
    },
    clinicLoginPagePopupSettings: {
      loginPagePopupTitle:  null,
      loginPagePopupContent:  null,
      modifiedAt: null
    }
  },
}

const clinicSettingsSlice = createSlice({
  name: "clinicSettings",
  initialState,
  reducers: {
    setClinicSettings: (state, action: PayloadAction<ClinicSettingsState["clinicSettings"]>) => {
      state.clinicSettings = action.payload
    },
  },
})

export const {
  setClinicSettings,
} = clinicSettingsSlice.actions

export default clinicSettingsSlice.reducer as Reducer<typeof initialState>
