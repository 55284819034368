export enum SsoErrorCode {
  ACCOUNT_ID_NOT_RECOGNIZED = "account-id-not-recognized",
  UNHANDLED_EXCEPTION = "unhandled-exception",
  SESSION_EXPIRED = "session-expired",
  INVALID_PASSWORDLESS_CODE = "invalid-passwordless-code",
  FUSION_AUTH_ERROR = "fusion-auth-error",
  MISSING_MAGIC_LINK_DATA = "missing-magic-link-data",
  MAGIC_LINK_NOT_MATCHED = "magic-link-not-matched",
  MAGIC_LINK_NOT_VALID = "magic-link-not-valid",
  PASSWORDLESS_INVALID_MAGIC_LINK_ID = "passwordless-invalid-magic-link-id",
  PASSWORDLESS_DATE_NOT_MATCH = "passwordless-birth-date-not-match",
  PASSWORDLESS_BIRTH_DATE_NOT_MATCH = "passwordless-birth-date-not-match",
  PASSWORDLESS_LOGIN_INVALID_BODY = "passwordless-login-invalid-body",
  PASSWORDLESS_LOGIN_MISSING_VERIFY_COOKIE = "passwordless-login-missing-verify-cookie",
  PASSWORDLESS_LOGIN_VERIFY_SESSION_EXPIRED = "passwordless-login-verify-session-expired",
}
