import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {generatePath, Link} from "react-router-dom"
import {Box,Paper, Typography} from "@material-ui/core"
import clsx from "clsx"

import Risk from "./risk/Risk.component"
import {RoutePath} from "../../../../../routes/Routes.types"
import {useDiseaseTileStyles} from "./DiseaseTile.styles"

export type DiseaseTileProps = {
  id: string;
  name: string;
  imageUrl: string;
  probability?: number;
  onClick?: VoidFunction;
  isActive?: boolean;
  isLinkDisabled?: boolean;
}

const DiseaseTile: FC<DiseaseTileProps> = ({ 
  id,
  imageUrl,
  name,
  probability,
  onClick,
  isActive,
  isLinkDisabled
}) => {
  const { i18n } = useTranslation()
  const classes = useDiseaseTileStyles({
    isActive
  })

  return (
    <Link
      className={classes.link}
      to={
        isLinkDisabled
          ? "#"
          : generatePath(
            `/${i18n.language}${RoutePath.PREVENTION_DISEASE_DETAILS}`,
            { diseaseId: id }
          )
      }
    >
      <Box
        className={classes.container}
        onClick={onClick}
      >
        <Box
          component={Paper}
          className={clsx(classes.container, classes.wrapper)}
        >
          <img
            src={imageUrl}
            alt="disease"
            className={classes.image}
          />
          <Typography
            className={classes.title}
            variant="subtitle2"
          >
            {name}
          </Typography>
        </Box>
        {
          probability !== undefined && (
            <Risk
              probability={probability}
            />
          )
        }
      </Box>
    </Link>
  )
}

export default DiseaseTile
