import React, { FC, useState } from "react"
import {Box, IconButton, useTheme} from "@material-ui/core"
import clsx from "clsx"
import {
  ChatCircleText,
  CornersOut,
  IconProps,
  Microphone,
  MicrophoneSlash,
  VideoCamera,
  VideoCameraSlash} from "phosphor-react"
import {Channel} from "pusher-js"
import {Room} from "twilio-video"

import useConsultationLang from "../../../../hooks/useConsultationLang"
import IconByIntegrationType from "../../../iconByIntegrationType/IconByIntegrationType"
import FinishVideoModal from "../../consultationVideo/closeVideoModal/FinishVideoModal.component"
import {ChatTriggerEventType} from "../../ConsultationChat.types"
import {useVideoControlButtonsStyles} from "./VideoControlButtons.styles"

interface VideoControlButtonsProps {
  finishVideoChat(): void;
  pusherChannel: Channel;
  consultationId: string;
  toggleFullScreenVideo(): void;
  setCameraOnOff(): void;
  setMicrophoneOnOff(): void;
  isVideoFullScreenOpen: boolean;
  storedRoom: Room|null;
  isCameraOn: boolean;
  isMicrophoneOn: boolean;
}

const VideoControlButtons: FC<VideoControlButtonsProps> = (
  {
    pusherChannel,
    finishVideoChat,
    consultationId,
    toggleFullScreenVideo,
    isVideoFullScreenOpen,
    storedRoom,
    setCameraOnOff,
    setMicrophoneOnOff,
    isCameraOn,
    isMicrophoneOn,
  }
) => {
  const consultationLang = useConsultationLang()
  const classes = useVideoControlButtonsStyles()
  const theme = useTheme()

  const [finishVideoModalOpen, setFinishVideoModalOpen] = useState<boolean>(false)

  const localParticipant = storedRoom?.localParticipant

  const iconProps: IconProps = {
    weight: "fill",
    color: theme.palette.common.white,
    size: 22,
  }

  const toggleCamera = () => {
    localParticipant?.videoTracks?.forEach(track => {
      if (isCameraOn) {
        track.track.disable()
        return
      }

      track.track.enable()
    })

    setCameraOnOff()
  }

  const toggleMicrophone = () => {
    localParticipant?.audioTracks?.forEach(track => {
      if (isMicrophoneOn) {
        track.track.disable()
        return
      }

      track.track.enable()
    })

    setMicrophoneOnOff()
  }

  const handleFinishVideoChat = () => {
    finishVideoChat()
    pusherChannel.trigger(ChatTriggerEventType.CLOSE_VIDEO_CHAT, {
      consultationId
    })
  }

  return (
    <>
      <Box
        className={clsx(
          classes.buttonsWrapper,
          !isVideoFullScreenOpen && classes.mitigatedViewButtonsWrapper
        )}
      >
        { isVideoFullScreenOpen &&
          <>
            <IconButton
              className={classes.button}
              title={consultationLang.getLabel("videoChatConsultation:turnFullScreenOff")}
              onClick={toggleFullScreenVideo}
            >
              <ChatCircleText {...iconProps}/>
            </IconButton>

            <IconButton
              className={clsx(classes.button, !isMicrophoneOn && classes.offDeviceButton)}
              title={consultationLang.getLabel(`videoChatConsultation:${isMicrophoneOn? "turnMicrophoneOff": "turnMicrophoneOn"}`)}
              onClick={toggleMicrophone}
            >
              { isMicrophoneOn
                ? <Microphone {...iconProps}/>
                : <MicrophoneSlash {...iconProps}/>
              }
            </IconButton>
          </>
        }

        <IconButton
          className={clsx(
            classes.button,
            classes.closeButton,
            !isVideoFullScreenOpen && classes.mitigatedViewButton,
          )}
          title={consultationLang.getLabel("videoChatConsultation:endVideoButton")}
          onClick={() => setFinishVideoModalOpen(true)}
        >
          <IconByIntegrationType 
            iconName={"icon-x"}
            className={clsx(
              classes.buttonIcon,
              isVideoFullScreenOpen && classes.closeButtonIcon,
            )}
          />
        </IconButton>

        { isVideoFullScreenOpen
          ? (
            <>
              <IconButton
                className={clsx(classes.button, !isCameraOn && classes.offDeviceButton)}
                title={consultationLang.getLabel(`videoChatConsultation:${isCameraOn ? "turnCameraOff" : "turnCameraOn"}`)}
                onClick={toggleCamera}
              >
                { isCameraOn
                  ? <VideoCamera {...iconProps}/>
                  : <VideoCameraSlash {...iconProps}/>
                }
              </IconButton>
            </>
          )
          : ( // for not isVideoFullScreenOpen
            <IconButton
              className={clsx(classes.button, classes.mitigatedViewButton)}
              title={consultationLang.getLabel("videoChatConsultation:turnFullScreenOn")}
              onClick={toggleFullScreenVideo}
            >
              <CornersOut {...iconProps} size={20}/>
            </IconButton>
          )
        }
      </Box>

      <FinishVideoModal
        open={finishVideoModalOpen}
        setClose={() => {setFinishVideoModalOpen(false)}}
        finishVideoChat={handleFinishVideoChat}
      />
    </>
  )
}

export default VideoControlButtons
