import i18next from "i18next"

import baFlag from "../../../assets/flags/ba.svg"
import bgFlag from "../../../assets/flags/bg.svg"
import csFlag from "../../../assets/flags/cs.svg"
import deFlag from "../../../assets/flags/de.svg"
import enFlag from "../../../assets/flags/en.svg"
import esFlag from "../../../assets/flags/es.svg"
import frFlag from "../../../assets/flags/fr.svg"
import hrFlag from "../../../assets/flags/hr.svg"
import itFlag from "../../../assets/flags/it.svg"
import ltFlag from "../../../assets/flags/lt.svg"
import plFlag from "../../../assets/flags/pl.svg"
import ptFlag from "../../../assets/flags/pt.svg"
import rsFlag from "../../../assets/flags/rs.svg"
import ruFlag from "../../../assets/flags/ru.svg"
import uaFlag from "../../../assets/flags/ua.svg"

export const getLangFlagImageSrc = (lang: string): string => {
  switch (lang) {
    case "pl": return plFlag
    case "en": return enFlag
    case "es": return esFlag
    case "ua": return uaFlag
    case "ru": return ruFlag
    case "pt": return ptFlag
    case "lt": return ltFlag
    case "fr": return frFlag
    case "rs": return rsFlag
    case "cs": return csFlag
    case "it": return itFlag
    case "hr": return hrFlag
    case "ba": return baFlag
    case "bg": return bgFlag
    case "de": return deFlag
    default: return ""
  }
}

export const getLangFlagImgTitle = (lang: string): string => {
  return i18next.t("consultation:language", { lang: lang.toUpperCase() })
}

export const languageSelection = (supportedLanguages: string[]): boolean => supportedLanguages.filter(lang => lang !== "cimode").length > 1
