export const SETTINGS_STORAGE_KEY = "stagingSettings"

export type SettingsState = {
  stagingSettingsLoading: boolean;
  lang: string | null;
  stagingSettings: StagingSettings;
}

export type StagingSettings = {
  comingEventsType: ComingEventType;
}

export enum ComingEventType {
  "ALL" = "ALL",
  "PAGINATION" = "PAGINATION"
}
