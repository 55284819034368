import {add} from "date-fns"

import {EXAMINATION_EXPIRATION_TIME_IN_DAYS} from "../consultation/Consultation.config"
import {isAfterDate} from "../../utils/dateFns/isAfterDate"
import {ExamModel, ExamSource} from "./Exam.types"

const getExamPriceFormat = (price: string | number): string => {
  return  Number(price)
    .toFixed(2)
    .replace(".", ",")
}

// temporary workaround - business requirement states that price for non referred exams are +20%,
// backend gives us 100% price
const getCustomExamPriceFormat = (price: string): string => {
  const customPrice = parseFloat(price)
  return getExamPriceFormat(customPrice) + "\u00A0zł"
}

const getReferralExamPriceFormat = (price: string | number): string => {
  return getExamPriceFormat(price) + "\u00A0zł"
}

const getExamSourceText = (source: ExamSource): string => {
  switch (source) {
    case ExamSource.RECEPTION:
      return "exam:source:reception"
    case ExamSource.DOCTOR:
      return "exam:source:doctor"
    case ExamSource.PATIENT:
      return "exam:source:patient"
    default:
      return source
  }
}

const filterCurrentExams = (exams: ExamModel[]): ExamModel[] =>
  exams.filter(exam => isAfterDate(
    new Date(),
    add(new Date(exam.created_at.replaceAll("-", "/")), { days: EXAMINATION_EXPIRATION_TIME_IN_DAYS })
  ))

export {
  filterCurrentExams,
  getCustomExamPriceFormat,
  getExamPriceFormat,
  getExamSourceText,
  getReferralExamPriceFormat}
