import {RootState} from "../store.types"
import {LogInState} from "./logIn.types"

export const selectLogInState: ((state: RootState) => LogInState) = ({logIn}) => logIn

export const selectLogInRedirectUrl: ((state: RootState) => LogInState["ref"]) = ({logIn}) => logIn.ref

export const selectLoginRedirectData: ((state: RootState) => LogInState["redirectData"]) = ({logIn}) => logIn.redirectData

export const selectLogInLoading: ((state: RootState) => LogInState["loading"]) = ({logIn}) => logIn.loading

export const selectLogInError: ((state: RootState) => LogInState["error"]) = ({logIn}) => logIn.error

export const selectMagicLinkExpired: ((state: RootState) => LogInState["magicLinkExpired"]) = ({logIn}) => logIn.magicLinkExpired
