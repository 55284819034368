import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useNearbyPharmaciesLoaderStyles = makeStyles((theme) => createStyles({
  root: {
    marginTop: theme.spacing(4)
  },
  label: {
    marginLeft: theme.spacing(2)
  }
}))
