import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import { RouteComponentProps } from "react-router"
import { push } from "connected-react-router"

import serverErrorImage from "../../assets/images/page-server-error.png"
import store from "../../store"
import ErrorPageCard from "../../components/common/errorPageCard/ErrorPageCard.component"

type ServerErrorProps = RouteComponentProps<{ code: string }>

const ServerErrorPage: FC<ServerErrorProps> = (props) => {
  const {t, i18n} = useTranslation()
  const contactPhoneNumber = store.getState().clinic?.clinicSettings?.frontendSettings?.contactPhoneNumber ?? t("serviceDeskPhone")

  const errorCode = Number(props.match.params.code)
  if (!errorCode || typeof errorCode !== "number" || errorCode < 500) {
    store.dispatch(push(`/${i18n.language}/404`))
  }

  return (
    <ErrorPageCard
      errorCode={errorCode}
      title={t("errors:serverErrorPage:title")}
      description={t("errors:serverErrorPage:description", {contactPhoneNumber: contactPhoneNumber})}
      image={serverErrorImage}
      imagePath={"page-server-error.png"}
    />
  )
}

export default ServerErrorPage