import {makeStyles} from "@material-ui/core"

import {colors} from "../../themes/colors"
import {createStyles} from "@material-ui/core/styles"

export const useDeclarationSignStyles = makeStyles((theme) => createStyles({
  container: {
    width: "100%",
    maxWidth: "1280px",
    margin: "auto"
  },
  root: {
    marginTop: "0!important",
    width: "100%",
    height: "100%",
  },
  pageBox: {
    width: "100%",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(7),
    },
  },
  button: {
    "& .MuiButton-label": {
      fontWeight: 700,
    }
  },
  section: {
    width: "100%",
  },
  stepNumber: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "18px",
    height: "18px",
    minWidth: "18px",
    maxHeight: "18px",
    borderRadius: "50%",
    backgroundColor: colors.telemediGray20,
    fontWeight: 700,
    fontSize: "13px",
    color: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      width: "20px",
      height: "20px",
      minWidth: "20px",
      maxHeight: "20px",
      marginRight: "2px",
    }
  },
  header: {
    display: "flex",
    flexDirection: "row",
    whiteSpace: "nowrap",
  },
  stepActiveNumber: {
    backgroundColor: theme.palette.primary.dark
  },
  stepperWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    paddingBottom: "32px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    }
  },
  steps: {
    display: "flex",
    flexDirection: "row",
    marginTop: "32px",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    }
  },
  label: {
    margin: "2px 6px",
    color: theme.palette.grey[400],
    fontSize: "11px",
    [theme.breakpoints.up("md")]: {
      fontSize: "13px",
    }
  },
  activeLabel: {
    color: theme.palette.text.primary
  },
  ourClinic: {
    color: theme.palette.primary.main,
    marginLeft: "4px"
  },
  statusInfo: {
    marginTop: "32px",
    display: "flex",
    justifyContent: "space-between"
  },
  declarationSignFailed: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    whiteSpace: "pre-line",
    lineHeight: "22px"
  },
  failMessage: {
    "&&": {
      marginTop: "32px",
      maxWidth: "520px"
    }
  },
  downloadAction: {
    display: "flex",
    flexDirection: "row",
  },
  downloadDeclaration: {
    color: colors.telemediGreen,
    fontSize: "16px",
    fontWeight: 700,
    textDecoration: "underline",
    cursor: "pointer",
    position: "relative",
    top: "4px",
    left: "6px",
  },
  isDownloading: {
    opacity: ".5",
    pointerEvents: "none"
  },
  statusInfoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    }
  },
  image: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      display: "block",
      marginTop: "65px"
    }
  },
  signAgain: {
    width: "285px!important"
  },
  actionBtn: {
    display: "block",
    marginTop: "24px",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginTop: "188px",
    },
    "& >button": {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "265px",
      },
      margin: "16px 0"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "24px",
    },
  }
}))
