import React, { ChangeEvent,FC } from "react"
import { useTranslation } from "react-i18next"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination,TableRow } from "@material-ui/core"

import { ReactComponent as CalendarIcon } from "../../../../../assets/svg/calendar.svg"
import { ReactComponent as ClockIcon } from "../../../../../assets/svg/clock.svg"
import ImageByIntegrationType from "../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { PreparedMeasurementsDataModel, PreparedMeasurementsDataValuesModel } from "../DiaryMeasurement.types"
import { useDiaryMeasurementDataTableStyles } from "./DiaryMeasurementDataTable.styles"

interface DiaryMeasurementDataTableProps {
  records: PreparedMeasurementsDataModel[];
}

export const DiaryMeasurementDataTable: FC<DiaryMeasurementDataTableProps> = ({ records }) => {
  const { t } = useTranslation()
  const { tableWrapper} = useDiaryMeasurementDataTableStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <TableContainer 
      component={Paper}
      className={tableWrapper}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <ImageByIntegrationType
                imageSrc={<CalendarIcon/>}
                imagePath={"calendar.svg"}
                returnSvg={true}
              />
            </TableCell>
            <TableCell>
              <ImageByIntegrationType
                imageSrc={<ClockIcon/>}
                imagePath={"clock.svg"}
                returnSvg={true}
              />
            </TableCell>
            {records[0]?.values.map((row) => <TableCell key={row.name}>{t(`healthPlanner:measurementLabels:${row.name}`)}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: PreparedMeasurementsDataModel) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {t("dates:dayMonthYearShortSlashes", { date: new Date(row.createdAt)})}
              </TableCell>
              <TableCell component="th" scope="row">
                {t("dates:time", { date: new Date(row.createdAt)})}
              </TableCell>
              {row.values.map((value: PreparedMeasurementsDataValuesModel, index: number) => <TableCell key={index}>{value.value} {value.unit}</TableCell>)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {records.length > 10 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={records.length}
          rowsPerPage={10}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  )
}