import React, { ChangeEvent, FC } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Typography } from "@material-ui/core"

import { CheckboxControllerOption } from "./CheckboxController.types"
import { useCheckboxControllerStyles } from "./CheckboxController.styles"

interface CheckboxControllerProps {
  name: string;
  label: string;
  options: CheckboxControllerOption[];
  placeholder?: string;
  isRequired?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  handleChange?: (onChange: (value: CheckboxControllerOption["value"]) => void) => (event: ChangeEvent<HTMLInputElement>) => void;
  row?: boolean;
}

const CheckboxController: FC<CheckboxControllerProps> = (
  {
    name,
    label,
    options,
    isRequired = false,
    fullWidth = true,
    disabled = false,
    handleChange,
    row = true,
  }) => {
  const form = useFormContext()
  const classes = useCheckboxControllerStyles()

  return (
    <Controller
      name={name}
      control={form.control}
      render={(
        {
          field: {
            onChange,
            value
          },
          fieldState: {
            error,
            invalid,
          }
        }) => (
        <FormControl
          component="fieldset"
          error={invalid}
          fullWidth={fullWidth}
          className={classes.form}
        >
          <FormLabel component="legend">
            <Typography variant="body2">
              { isRequired ? `${label} *` : label }
            </Typography>
          </FormLabel>
          <RadioGroup
            row={row}
            name={name}
            id={name}
            value={value}
            onChange={handleChange ? handleChange(onChange) : onChange}
          >
            { options.map((option: CheckboxControllerOption) => (
              <FormControlLabel
                key={option.value}
                value={String(option.value)}
                label={option.label}
                control={
                  <Radio
                    className={classes.radioBtn}
                    disabled={disabled}
                  />
                }
              />
            ))}
          </RadioGroup>

          <FormHelperText>
            { error?.message }
          </FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default CheckboxController
