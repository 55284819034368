import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useLangDropdownStyles = makeStyles((theme: Theme) => createStyles({
  listItemRoot: {
    padding: theme.spacing(0, 3, 0),
  },
  listItemIconRoot: {
    color: theme.palette.text.primary,
    ...theme.typography.h3,
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  listItemLanguageIconRoot: {
    color: theme.palette.text.primary,
    ...theme.typography.h3,
    marginRight: theme.spacing(2),
  },
  listItemTextRoot: {
    color: theme.palette.text.primary,
    ...theme.typography.h5,
    [theme.breakpoints.up("md")]: {
      ...theme.typography.h4,
    },
  },
  accordionRoot: {
    border: "none",
    boxShadow: "none",
    maxWidth: "100%",
    width: "100%",
  },
  accordionSummary: {
    padding: 0,
    pointerEvents: "none",
    height: "56px !important",
    minHeight: "56px !important",
  },
  accordionContent: {
    display: "flex",
    alignItems: "center",
  },
  accordionDetails: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  customHover: {
    "&:hover, &:focus": {
      background: theme.palette.common.white,
    },
    "&:hover .icon-caret-down, &:focus .icon-caret-down": {
      transform: "translate3d(0, 6px, 0)"
    },
  },
}))
