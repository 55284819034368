import {makeStyles} from "@material-ui/core"

import {colors} from "../../../../themes/colors"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useNfzStatusSwitchStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "block",
    width: theme.spacing(11),
    height: theme.spacing(5),
    position: "relative",
    transition: "all 0.2s",
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
  },
  clickable: {
    cursor: "pointer",
  },
  isOn: {
    background: theme.palette.primary.main,
    "& $switchText": {
      left: theme.spacing(2),
    },
    "& $switchIndicator": {
      right: 0,
      backgroundColor: theme.palette.primary.main,
    },
    "& $switchIcon": {
      fill: theme.palette.common.white,
    },
  },
  isOff: {
    background: colors.telemediGray60,
    "& $switchText": {
      left: `calc(100% - ${theme.spacing(5)}px)`,
    },
    "& $switchIndicator": {
      right: `calc(100% - ${theme.spacing(5)}px)`,
      backgroundColor: colors.telemediGray20,
    },
    "& $switchIcon": {
      fill: colors.telemediGray20,
    },
  },
  switchCheckbox: {
    height: 0,
    width: 0,
    position: "absolute",
    visibility: "hidden",
  },
  switchText: {
    transition: "all 0.2s",
  },
  switchIndicator: {
    transition: "all 0.2s",
    borderRadius: "100%",
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
  switchIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}))
