import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps, useHistory } from "react-router"
import { Box, Button, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import { analytics, LogEventType } from "../../services/analytics"
import GlobalFormSuccessMessage from "../../components/commonFormItems/globalFormSuccessMessage/GlobalFormSuccessMessage.component"
import { useSuccessfulPaymentPageStyles } from "../../components/successfulPayment/SuccessfulPayment.styles"

interface PaymentSuccessPageParams {}

const PaymentSuccessPage: FC<RouteComponentProps<PaymentSuccessPageParams>> = () => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const classes = useSuccessfulPaymentPageStyles()
  const { push } = useHistory()

  const goToHomePage = () => {
    analytics.sendEventWithDefaultParams(LogEventType.BACK_TO_HOME_PAGE_CLICK)
    push(`/${i18n.language}`)
  }

  return (
    <Box
      className={classes.pageSection}
      component="section"
    >
      <Box className={classes.content}>
        <Typography
          variant={isMdUp ? "h3" : "h5"}
          component="h2"
          className={classes.title}
        >
          <GlobalFormSuccessMessage message={t("payments:successPayment:title")}/>
        </Typography>

        <Box mt={5}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={goToHomePage}
          >
            {t("goToConsultationList")}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentSuccessPage
