import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, ButtonProps } from "@material-ui/core"

interface ShowMoreButtonProps extends ButtonProps {
  onClick?(): void;
}

const ShowMoreButton: FC<ShowMoreButtonProps> = ({onClick, ...props}) => {
  const {t} = useTranslation()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    (event.target as HTMLButtonElement).blur()

    if (onClick) {
      onClick()
    }
  }

  return (
    <Box
      marginY={4}
      textAlign="center"
    >
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClick}
        {...props}
      >
        {t("showMore")}
      </Button>
    </Box>
  )
}

export default ShowMoreButton
