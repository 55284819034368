import React, {ChangeEventHandler, FC} from "react"
import {IconButton, InputAdornment, TextField} from "@material-ui/core"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"

import {useToggle} from "../../../hooks/useToggle"

import {dataTestIdMap} from "./PasswordInput.config"

export type PasswordInputProps = {
  label?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>
  name?: string;
  placeholder?: string;
  isRequired?: boolean;
  isErrorOccurred?: boolean;
  isDisabled?: boolean;
  errorText?: string;
  helperText?: string;
}

const PasswordInput: FC<PasswordInputProps> = ({
  label,
  value,
  onChange,
  name,
  placeholder,
  isRequired,
  isErrorOccurred,
  isDisabled,
  errorText,
  helperText,
  ...props
}) => {
  const [isPasswordVisible, { toggle }] = useToggle(false)

  return (
    <TextField
      fullWidth
      name={name}
      label={label}
      placeholder={placeholder}
      type={isPasswordVisible ? "text" : "password"}
      disabled={isDisabled}
      error={isErrorOccurred}
      required={isRequired}
      helperText={errorText || helperText}
      value={value ?? ""}
      onChange={onChange}
      inputProps={{
        "data-testid": dataTestIdMap.input,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-testid={dataTestIdMap.passwordVisibilityButton}
              size="small"
              disabled={isDisabled}
              onClick={toggle}
            >
              {
                isPasswordVisible
                  ? <VisibilityOffIcon/>
                  : <VisibilityIcon/>
              }
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  )
}

export default PasswordInput
