import {createStyles, makeStyles} from "@material-ui/core"

export const useGenericStepFormStyles = makeStyles(theme => createStyles({
  container: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(4)
  },
  headerWrapper: {
    padding: theme.spacing(4, 10),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 4)
    }
  },
  title: {
    marginBottom: theme.spacing(4)
  },
  progress: {
    borderRadius: theme.shape.borderRadius
  },
}))