import {gql} from "@apollo/client"

export const GET_DISEASE_BY_ID = gql`
  query Disease($diseaseId: uuid!) {
    disease: diseases_by_pk(id: $diseaseId) {
      description
      imageUrl: image_url
      name
      questionnaireId: questionnaire_id
    }
    usersDiseaseProbability: users_diseases_probability(where: {disease_id: {_eq: $diseaseId}}) {
      probability
    }
    usersDisease: users_diseases(where: {disease_id: {_eq: $diseaseId}}) {
      id
    }
    diseasesActions: diseases_actions(where: {disease_id: {_eq: $diseaseId}}) {
      action {
        id
      }
    }
  }
`

export type GetDiseaseByIdResponse = {
  disease: {
    description: string;
    imageUrl: string;
    name: string;
    questionnaireId: string | null;
  },
  diseasesActions: {
    action: {
      id: string;
    }
  }[]
  usersDiseaseProbability: [{
    probability: number; //0 to 1
  }] | []
  usersDisease: [{
    id: string
  }] | []
}

export type GetDiseaseByIdVariables = {
  diseaseId: string;
}