import React, {FC} from "react"
import {Box, Hidden,Typography} from "@material-ui/core"
import clsx from "clsx"

import DoctorImageAvatar from "../../common/imageAvatar/doctorImageAvatar/DoctorImageAvatar.component"
import {DoctorImageType} from "../../doctor/Doctor.types"
import {ConsultationChatItem} from "../ConsultationChat.types"
import {useConsultationChatMessageItemStyles} from "./ConsultationChatMessageItem.styles"

import {parseMessageCreatedAt} from "./ConsultationChatMessageItem.config"

interface DoctorConsultationChatMessageItemProps extends ConsultationChatItem {
  doctorImage: DoctorImageType;
  doctorAvatarPseudorandomBoolean?: boolean;
}

const DoctorConsultationChatMessageItemComponent: FC<DoctorConsultationChatMessageItemProps> = (
  {
    messageRaw,
    createdAt,
    doctorImage,
    doctorAvatarPseudorandomBoolean,
  }
) => {
  const classes = useConsultationChatMessageItemStyles()
  const time = parseMessageCreatedAt(createdAt)

  return (
    <Box className={clsx(
      classes.messageItemBox,
      classes.leftAlignBoxMessage,
      classes.widthConditions,
    )}>
      <Box
        mr={2}
        className={clsx(
          classes.cloudMessageWrapper,
          classes.doctorCloudMessageWrapper,
        )}
      >
        <Typography className="break-spaces">
          { messageRaw }
        </Typography>

        <DoctorImageAvatar
          pseudorandomBoolean={doctorAvatarPseudorandomBoolean}
          className={classes.doctorImage}
          src={doctorImage.src || undefined}
          alt={doctorImage.alt || ""}
        />
      </Box>

      { time && (
        <Hidden smDown>
          <Typography className={classes.messageTime}>
            { time }
          </Typography>
        </Hidden>
      )}
    </Box>
  )
}

export default DoctorConsultationChatMessageItemComponent
