import React, {FC} from "react"
import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import {parseISO} from "date-fns"

import useConsultationLang from "../../../hooks/useConsultationLang"
import ConsultationDoctorDataSection from "../consultationDoctorDataSection/ConsultationDoctorDataSection.component"
import {removeOrphansHTML} from "../../../utils/removeOrphansHTML"
import {isInstantChatConsultation} from "../../consultation/Consultation.utils"
import {ConsultationType} from "../../consultation/Consultation.types"
import {DoctorDetailsType} from "../../doctor/Doctor.types"
import {ConsultationChatItem} from "../ConsultationChat.types"
import {useConsultationChatTopBoxStyles} from "./ConsultationChatTopBox.styles"

interface ConsultationChatTopBoxProps extends DoctorDetailsType {
  subtitle?: ConsultationChatItem;
  consultationType: ConsultationType;
  visitAt: string;
  addNewPatientMessage: (newPatientMessage: ConsultationChatItem) => void;
  consultationId: string;
  patientId?: string;
  consultationStatus?: number;
  isStationaryVisit?: boolean;
}

const ConsultationChatTopBoxComponent: FC<ConsultationChatTopBoxProps> = (
  {
    subtitle,
    consultationType,
    doctorImage,
    doctorSpecialization,
    doctorTitle,
    doctorAvatarPseudorandomBoolean,
    visitAt,
    isStationaryVisit
  }
) => {
  const classes = useConsultationChatTopBoxStyles()
  const theme = useTheme()
  const consultationLang = useConsultationLang()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const consultationDateWithDay = consultationLang.getLabel("dates:consultationDayWithDate", { date: parseISO(visitAt) })
  const consultationTime = consultationLang.getLabel("dates:time", { date: parseISO(visitAt) })
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))
  const consultationDateTime = isInstantChatConsultation(consultationType) || consultationType === ConsultationType.PRESCRIPTION_ONLY || consultationType === ConsultationType.SICK_LEAVE
    ? consultationDateWithDay
    : `${consultationDateWithDay} - ${consultationTime}`

  const titleSection = (
    <Box
      mb={2}
      display="flex"
      flexDirection={isMdUp ? "row" : "column"}
      alignItems={isMdUp ? "flex-start" : "center"}
    >
      <Typography
        variant={isMdUp ? "h2" : "h3"}
        component="h2"
      >
        { consultationLang.getLabel("chatConsultation:consultation") }
      </Typography>
      <Typography
        variant="h5"
        component="time"
        className={isMdUp ? classes.consultationDate : undefined}
      >
        { consultationDateTime }
      </Typography>
    </Box>
  )

  return (
    <Box
      component="section"
      px={2}
    >
      <Box className={classes.root} pb={2}>
        <Box
          width="100%"
          display={isMdUp && isInstantChatConsultation(consultationType) ? "flex" : "block"}
          justifyContent="space-between"
        >
          { titleSection }

          { (doctorTitle && (!isLgUp || isInstantChatConsultation(consultationType))) &&
            <ConsultationDoctorDataSection
              doctorImage={doctorImage}
              doctorSpecialization={doctorSpecialization}
              doctorTitle={doctorTitle}
              doctorAvatarPseudorandomBoolean={doctorAvatarPseudorandomBoolean}
              consultationType={consultationType}
              isStationaryVisit={isStationaryVisit}
            />
          }
        </Box>
      </Box>
      { subtitle &&
          <Box
            display="flex"
            justifyContent="center"
            mb={isMdUp ? 5 : 3}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              dangerouslySetInnerHTML={{ __html: removeOrphansHTML(subtitle.messageText || "") }}
            />
          </Box>
      }
    </Box>
  )
}

export default ConsultationChatTopBoxComponent
