import React, {useEffect, useState} from "react"
import { Box, useMediaQuery, useTheme } from "@material-ui/core"

import { useGetWordpressQuery } from "../../../../store/wordpress/wordpress.api"
import { ActionType } from "../../../healthPlanner/api/queries/getUserActionById"
import LoaderBox from "../../../../components/common/loaderBox/LoaderBox.component"
import ActionsList from "../../../healthPlanner/components/actionsList/ActionsList.component"
import ActionsListItem from "../../../healthPlanner/components/actionsList/actionsListItem/ActionsListItem.component"
import { PanelData } from "../../../healthPlanner/components/actionsList/dynamicActionListPanels/DynamicActionListPanels.component"
import {
  useSetGender,
  useUserRegister
} from "../../../healthPlanner/components/healthPlannerProvider/HealthPlannerProvider.utils"
import CrisisTile from "../CrisisTile/CrisisTile.component"
import MoodTile from "../MoodTile/MoodTile.component"
import ResourcesTile from "../ResourcesTile/ResourcesTile.component"
import { sanitizeHTML } from "../../../../utils/sanitizeHTML"
import { RoutePath } from "../../../../routes/Routes.types"
import { WordpressTag } from "../../../../store/wordpress/wordpress.types"

const mentalHealthTabs = [
  { label: "mentalHealth:yourArticles" },
]

export interface HealthPreventionArticleItem {
  key: number|string;
  points: number;
  id: string;
  imageUrl: string;
  name: string;
  description: string;
  type: ActionType,
  tag?: string;
  createdAt: string;
  redirectUrl: string;
  tags?: WordpressTag[];
}

const MentalHealthDashboard = () => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const { isRegistering } = useUserRegister()
  const { isSetting } = useSetGender()

  const [articleItems, setArticleItems] = useState<HealthPreventionArticleItem[]>([])
  const { data, isLoading, error } = useGetWordpressQuery({
    query: "posts&_embed=1&_fields=id,link,excerpt,content,_embedded,_links,title,date"
  })

  useEffect(() => {
    if (!data || isLoading) {
      return
    }

    const articleItems = data.slice(0, 3).map((item) => ({
      key: item.id,
      id: item.id,
      imageUrl: item._embedded["wp:featuredmedia"][0].source_url,
      name: item.title.rendered,
      description: sanitizeHTML(item.content.rendered),
      type: ActionType.HEALTH_PREVENTION_ARTICLES,
      tag: "Tag",
      createdAt: item.date,
      redirectUrl: item.link,
      points: 0,
      tags: item._embedded["wp:term"].flatMap(termArray =>
        termArray.map(tag => ({ taxonomy: tag.taxonomy }))
      )
    }))

    setArticleItems(articleItems)
  }, [data, isLoading])

  const renderArticles = articleItems?.map(articles => {
    if (!isLoading && !error) {
      return (
        <ActionsListItem
          key={articles.key}
          points={articles.points}
          id={articles.id}
          image_url={articles.imageUrl}
          name={articles.name}
          type={articles.type}
          description={articles.description}
          tag={articles.tag}
          createdAt={articles.createdAt}
          redirectUrl={articles.redirectUrl}
          backRoute={RoutePath.MENTAL_HEALTH}
          tags={articles.tags}
        />
      )
    }
  })
  
  const panelsData:PanelData[] = [
    {
      isEmpty: articleItems.length === 0,
      renderContent: renderArticles,
      emptyMessageLabel: "mentalHealth:noArticlesMessage",
      showImageOnEmptyList: false
    },
  ]

  if (isRegistering || isSetting || isLoading) {
    return <LoaderBox boxType="appDialog"/>
  }

  return (
    <Box
      display="flex"
      alignItems={isMdUp ? "flex-start" : "center"}
      flexDirection="column"
    >
      <Box
        display="flex"
        flexDirection={isMdUp ? "row" : "column"}
        alignItems={isMdUp ? "unset" : "center"}
        flexWrap={"wrap"}
        gridGap={theme.spacing(2)}
        mb={2}
        width="100%"
      >
        <MoodTile/>
        <ResourcesTile/>
        <CrisisTile/>
        <ActionsList
          tabs={mentalHealthTabs}
          panelsData={panelsData}
          loading={isLoading}
          error={!!error}
        />
      </Box>
    </Box>
  )
}

export default MentalHealthDashboard
