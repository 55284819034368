import {useSelector} from "react-redux"

import {selectClinicSettings} from "../store/clinic/clinicSettings.selectors"
import {selectUserActiveDeclarationType} from "../store/user/user.selectors"

export const usePrescriptionDrugLimits = ()=>{
  const {
    prescriptionsSettings: {
      medicalDrugLimit,
      medicalDrugsTotalLimit,
      pozGlobalMedicalDrugLimit,
      pozGlobalMedicalDrugsTotalLimit,
    }
  } = useSelector(selectClinicSettings)
  const userActiveDeclarationType = useSelector(selectUserActiveDeclarationType)

  const isDeclarationPozGlobal = userActiveDeclarationType === 2
  const perDrugLimit = isDeclarationPozGlobal ?  pozGlobalMedicalDrugLimit : medicalDrugLimit
  const totalDrugsLimit = isDeclarationPozGlobal ?  pozGlobalMedicalDrugsTotalLimit : medicalDrugsTotalLimit

  return {
    perDrugLimit,
    totalDrugsLimit
  }
}
