import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useButtonLoaderStyles = makeStyles(() => createStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  }
}))
