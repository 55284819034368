import React, { FC } from "react"
import { Box, Typography } from "@material-ui/core"

import {getLangFlagImageSrc} from "./LangFlag.utils"
import {useLangFlagStyles} from "./LangFlag.styles"

interface LangFlagProps {
  language: string;
  className?: string | undefined;
  title?: string;
  label?: string;
}

const LangFlag: FC<LangFlagProps> = ({language, className, title = "", label}) => {
  const classes = useLangFlagStyles()
  const langFlagImageSrc = getLangFlagImageSrc(language)

  return (
    <Box
      display={label ? "flex" : "initial"}
      alignItems="flex-start"
      pt={label ? 1 : 0}
    >
      {
        langFlagImageSrc && (
          <img
            src={langFlagImageSrc}
            alt={title}
            title={title}
            className={className}
          />
        )
      }
      {
        label && (
          <Typography 
            variant="body2"
            component="span"
            className={classes.label}
          >
            { label }
          </Typography>
        )
      }
    </Box>
  )
}

export default LangFlag
