import React, {FC} from "react"
import {get, useFieldArray, useFormContext} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box} from "@material-ui/core"

import PrescriptionDetails from "./PrescriptionDetails/PrescriptionDetails.component"
import PrescriptionSearchInput from "./PrescriptionSearchInput/PrescriptionSearchInput.component"
import {PatientsRequirementsStepData} from "../../../../MedicalQuestionnaireStepperForm.types"

type Props = {
  perDrugLimit: number;
  totalDrugsLimit: number;
}
const PrescriptionFields: FC<Props> = ({ perDrugLimit, totalDrugsLimit }) => {
  const {t} = useTranslation()
  const { fields, append, remove } = useFieldArray<PatientsRequirementsStepData, "prescription.prescriptions">({
    name: "prescription.prescriptions"
  })
  const { formState: { errors } } = useFormContext<PatientsRequirementsStepData>()
  const error = get(errors, "prescription.prescriptions")
  const disableDrugInput = fields?.length >= totalDrugsLimit
  const inputLabel = disableDrugInput 
    ? t("medicalQuestionnaire:prescriptionSearchInputLabelWhenDisabled")
    : t("medicalQuestionnaire:prescriptionSearchInputLabel")

  return (
    <Box
      mb={2}
    >
      <PrescriptionSearchInput
        onChange={(event, prescription) => {
          if (prescription) {
            append(prescription)
          }
        }}
        label={inputLabel}
        disabled={ disableDrugInput }
        isErrorOccurred={error?.type === "min"}
        errorMessage={error?.message}
      />
      {
        fields?.map((field, index) => {
          return <PrescriptionDetails
            medicalProductId={field.medical_product_id}
            commonName={field.common_name}
            power={field.power}
            packageType={field.package}
            key={field.id}
            name={field.name}
            index={index}
            onDelete={() => remove(index)}
            maxAmount={perDrugLimit}
          />
        })
      }
    </Box>
  )
}

export default PrescriptionFields
