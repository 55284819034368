import { makeStyles } from "@material-ui/core"

import { createStyles } from "@material-ui/core/styles"

export const useHealthPreventionSelfImprovementStyles = makeStyles(theme => createStyles({
  image: {
    width: "50%",
    height: "auto",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      objectFit: "contain",
    },
  },
  pageWrapper: {
    marginRight: theme.spacing(3) + "px",
    marginLeft: theme.spacing(3) + "px",
    marginBottom: theme.spacing(3) + "px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "unset",
    },
  },
  title: {
    marginBottom: 0,
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: theme.spacing(2.5) + "px",
    wordWrap: "break-word",
    alignSelf: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: "36px",
      marginBottom: theme.spacing(3),
      lineHeight: theme.spacing(5.5) + "px",
    },
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "auto",
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
  contentBoxImage: {
    [theme.breakpoints.up("md")]: {
      width: "25%"
    },
  },
  contentImage: {
    [theme.breakpoints.up("md")]: {
      width: "auto"
    },
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
      marginTop: theme.spacing(14),
    },
  },
  contentButtonsWrapper: {
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(2.5),
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    background: theme.palette.background.paper,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    cursor: "pointer"
  },
  boxTitle: {
    fontSize: `${theme.spacing(3)}px`,
    fontWeight: 700,
    lineHeight: `${theme.spacing(4)}px`,
    wordWrap: "break-word",
    marginBottom: theme.spacing(2),
  },
  boxContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: theme.spacing(2),
  },
  boxImage: {
    flex: "1 1 45%",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  description: {
    flex: "1 1 45%",
    marginBottom: theme.spacing(2),
  },
  img: {
    maxWidth: "100%",
    height: "auto",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  descriptionContent: {
    textAlign: "left",
    color: "rgba(120, 120, 120, 1)",
    fontSize: `${theme.spacing(1.75)}px`,
    fontWeight: 400,
    lineHeight: `${theme.spacing(2.5)}px`,
  },
  boxItemTitle: {
    marginBottom: 0,
    fontWeight: 700,
    fontSize: `${theme.spacing(2)}px`,
    lineHeight: `${theme.spacing(3)}px`,
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(2),
      fontSize: `${theme.spacing(3)}px`,
      lineHeight: `${theme.spacing(4)}px`,
    },
  },
  boxItemContent: {
    fontWeight: 400,
    fontSize: `${theme.spacing(1.75)}px`,
    lineHeight: `${theme.spacing(2)}px`,
    [theme.breakpoints.up("md")]: {
      fontSize: `${theme.spacing(2)}px`,
      lineHeight: `${theme.spacing(3)}px`,
    },
  },
  clinicColor: {
    color: theme?.palette?.primary?.main
  },
  boxBtnWrapper: {
    marginBottom: 0,
    marginTop: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(5.5),
    },
  },
  "@media (max-width: 500px)": {
    boxContent: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    descriptionContent: {
      textAlign: "center",
    },
    boxImage: {
      marginBottom: theme.spacing(2),
      marginRight: 0,
      width: "100%",
    },
    description: {
      flex: "unset",
    },
  },
}))
