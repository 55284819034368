import React, {FC} from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import clsx from "clsx"

import {useAppSelector} from "../../hooks/storeHooks"
import {selectClinicSettings} from "../../store/clinic/clinicSettings.selectors"
import ExaminationSection from "./examinationSection/ExaminationSection.component"
import ReferralContent from "./referralContent/ReferralContent.component"
import ReferralSectionFooter from "./referralSectionFooter/ReferralSectionFooter.component"
import ReferralSectionHeader from "./referralSectionHeader/ReferralSectionHeader.component"
import { ConsultationExamReferral } from "../exam/Exam.types"
import {useReferralSectionStyles} from "./ReferralSection.styles"

type ParentClassKey = "root"|"summaryRoot"|"detailsRoot"

interface ReferralSectionProps {
  id: string;
  onChange: AccordionProps["onChange"];
  expanded: AccordionProps["expanded"];
  expandIcon: AccordionSummaryProps["expandIcon"];
  parentClasses: Record<ParentClassKey, string>;
  items: ConsultationExamReferral[];
  consultationId: string;
  isPoz: boolean;
  referralId?: string;
  date?: string;
}

const ReferralSection: FC<ReferralSectionProps> = (
  {
    id,
    expanded,
    onChange,
    expandIcon,
    parentClasses,
    items,
    consultationId,
    referralId,
    date,
    isPoz,
  }
) => {
  const theme = useTheme()
  const classes = useReferralSectionStyles()
  const clinicSettings = useAppSelector(selectClinicSettings)

  const price = items.reduce((cost, item) => cost + parseFloat(item.lab_price), 0)

  const isButtonDisabled = items.filter(function (item) {
    return (item.is_bought === false && !clinicSettings.examCartSettings.enableReferralBasketFeature) || (clinicSettings.examCartSettings.enableReferralBasketFeature && item.included_in_package === true && item.is_bought === false)
  }).length === 0

  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      square
      elevation={0}
      classes={{
        root: parentClasses.root
      }}
    >
      <AccordionSummary
        id={`${id}-header`}
        aria-controls={`${id}-content`}
        expandIcon={expandIcon}
        classes={{
          root: clsx(parentClasses.summaryRoot, classes.referralAccordionSummary),
        }}
      >
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
        >
          <Grid item xs={12} md={3}>
            <Box display="flex" alignItems="center">
              <ReferralSectionHeader/>
            </Box>
          </Grid>
          { isMdUp && (
            <Grid
              item
              className={clsx(classes.expand, expanded ? classes.hidden : classes.visible)}
            >
              <ReferralSectionFooter
                price={price}
                isButtonDisabled={isButtonDisabled}
                consultationId={consultationId}
                referralId={referralId}
                date={date}
                isPoz={isPoz}
              />
            </Grid>
          )}
        </Grid>
      </AccordionSummary>

      <AccordionDetails
        classes={{ root: clsx(parentClasses.detailsRoot, classes.referralAccordionDetails) }}
      >
        {
          clinicSettings.examCartSettings.enableReferralBasketFeature ? (
            <ExaminationSection
              referralId={referralId}
              consultationId={consultationId}
              isBought={isButtonDisabled}
              isNoExaminationIncludedInPackage={isButtonDisabled}
              date={date}
              examinations={items}
            />
          ) : (
            <ReferralContent
              price={price}
              items={items}
              isBought={isButtonDisabled}
              consultationId={consultationId}
              referralId={referralId}
              date={date}
              expanded={expanded}
              isPoz={isPoz}
            />
          )
        }
      </AccordionDetails>
    </Accordion>
  )
}

export default ReferralSection
