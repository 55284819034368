import {createSlice, PayloadAction, Reducer} from "@reduxjs/toolkit"

import {MedicalExamsPointsState} from "./medicalExamsPoints.types"

const initialState: MedicalExamsPointsState = {
  items: [],
  itemsCoords: [],
  loading: false,
  error: null,
  geocodeLoading: false,
  geocodeError: null,
}

export const medicalExamsPointsSlice = createSlice({
  name: "medicalExamsPoints",
  initialState,
  reducers: {
    getMedicalExamsPoints: (state: MedicalExamsPointsState) => {
      state.loading = true
      state.error = null
      return
    },
    setMedicalExamsPoints: (state: MedicalExamsPointsState, action: PayloadAction<MedicalExamsPointsState["items"]>) => {
      state.items = action.payload
      state.loading = false
      return
    },
    setMedicalExamsPointsError: (state: MedicalExamsPointsState, action: PayloadAction<MedicalExamsPointsState["error"]>) => {
      state.error = action.payload
      state.loading = false
      return
    },
    getMedicalExamsPointsCoords: (state: MedicalExamsPointsState) => {
      state.geocodeLoading = true
      return
    },
    setMedicalExamsPointsCoords: (state: MedicalExamsPointsState, action: PayloadAction<MedicalExamsPointsState["itemsCoords"]>) => {
      state.itemsCoords = action.payload
      state.geocodeLoading = false
      return
    },
    setMedicalExamsPointsGeocodeError: (state: MedicalExamsPointsState, action: PayloadAction<MedicalExamsPointsState["geocodeError"]>) => {
      state.geocodeError = action.payload
      state.geocodeLoading = false
      return
    },
    setChosenLaboratory: (state: MedicalExamsPointsState, action: PayloadAction<MedicalExamsPointsState["chosenLaboratory"]>) => {
      state.chosenLaboratory = action.payload
    },
    clearChosenLaboratory: (state) => {
      state.chosenLaboratory = undefined
    },
    clearMedicalExamsPoints: () => {
      return {...initialState}
    }
  },
})

export const {
  getMedicalExamsPoints,
  setMedicalExamsPoints,
  getMedicalExamsPointsCoords,
  setMedicalExamsPointsCoords,
  setMedicalExamsPointsError,
  setMedicalExamsPointsGeocodeError,
  clearMedicalExamsPoints,
  setChosenLaboratory,
  clearChosenLaboratory
} = medicalExamsPointsSlice.actions

export default medicalExamsPointsSlice.reducer as Reducer<typeof initialState>
