import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useSendNewMessageFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      position: "relative",
      left: -theme.spacing(2),
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up("md")]: {
        maxWidth: "70%",
        padding: theme.spacing(0),
      },
    },
    textField: {
      ...theme.typography.body1,
      border: "none",
      background: theme.palette.grey["A100"],
      padding: theme.spacing(1.5),
    },
    buttonsWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: theme.spacing(3.5),
      "& div:last-child > button": {
        marginLeft: theme.spacing(2),
      },
    },
    icon: {
      "&::before": {
        fontSize: theme.spacing(3),
        color: theme.palette.primary.main,
        position: "relative",
        top: "4px",
      }
    }
  })
)
