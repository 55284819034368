import {gql} from "@apollo/client"

import { UserPointsSummaryResponseModel } from "./getUserPointsQuery"

export interface AwardsModel {
  name: string;
  description: string;
  points: number;
  validTo: string;
  imageUrl: string;
  id: string;
}

export interface UserAwardsModel {
  id: string;
  voucher: {
    code: string;
    award: {
      name: string;
      description: string;
      imageUrl: string;
      points: number;
    }
  }
}
    
export interface UserAwardsResponseData {
  awards: AwardsModel[]
  userPoints: UserPointsSummaryResponseModel;
  userAwards: UserAwardsModel[];
}

export const GET_USER_AWARDS = gql`
  query getUserAwards {
    awards(order_by: {points: asc}, where: {is_active: {_eq: true}}) {
      name
      description
      points
      validTo: valid_to
      imageUrl: image_url
      id
    }
    userPoints: points_transactions_aggregate {
      aggregate {
        sum {
          amount
        }
      }
    }
    userAwards: users_awards {
      id
      voucher {
        code
        award {
          name
          description
          imageUrl: image_url
          points
        }
      }
    }
  }
`
