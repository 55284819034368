import {makeStyles} from "@material-ui/core"

import {alpha, createStyles, Theme} from "@material-ui/core/styles"

export const useSessionManagerStyles = makeStyles((theme: Theme) => createStyles({
  "@keyframes rotateBack": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(-360deg)",
    },
  },
  "@keyframes slowlyFade": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
  root: {
    marginRight: theme.spacing(4),
    "&$fade": {
      animation: "$slowlyFade 10s 1 linear",
      animationDelay: "50s",
    }
  },
  fade: {},
  iconWrapper: {
    "&$animate": {
      animation: "$rotateBack 1s 1 linear",
    },
  },
  animate: {},
  time: {
    marginRight: theme.spacing(1),
    fontVariantNumeric: "tabular-nums",
  },
  tooltip: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 1.5),
    backgroundColor: alpha(theme.palette.common.black, 0.4),
    ...theme.typography.body2,
    color: theme.palette.common.white,
  },
  sessionCodeBox: {
    position: "absolute",
    zIndex: 1100,
    bottom: 0,
    left: 0,
    minWidth: "102px",
    height: "25px",
    padding: theme.spacing(0.5),
    fontWeight: "bold",
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  }
}))
