export enum LogEventType {
  LOGIN_PAGE_LOADED = "login_page_loaded",
  CLINIC_SETTINGS_SUCCESS = "clinic_settings_success",
  CLINIC_SETTINGS_ERROR = "clinic_settings_error",
  LOGIN_PASSWORD_GRANT_SUCCESS = "login_password_grant_success",
  LOGIN_PASSWORD_GRANT_ERROR = "login_password_grant_error",
  MANY_BY_LOGIN_SUCCESS = "many_by_login_success",
  MANY_BY_LOGIN_ERROR = "many_by_login_error",
  MIDDLEWARE_USERS_SUCCESS = "middleware_users_success",
  MIDDLEWARE_USERS_ERROR = "middleware_users_error",
  LOGIN_SUCCESS = "login_success",
  FORGOT_PASSWORD_PAGE_LOADED = "forgot_password_page_loaded",
  FORGOT_PASSWORD_SUCCESS = "forgot_password_success",
  FORGOT_PASSWORD_ERROR = "forgot_password_error",
  PATIENTS_ACCESS_CODE_SUCCESS = "patients_access_code_success",
  PATIENTS_ACCESS_CODE_ERROR = "patients_access_code_error",
  PATIENTS_CHANGE_PASSWORD_SUCCESS = "patients_change_password_success",
  PATIENTS_CHANGE_PASSWORD_ERROR = "patients_change_password_error",
  FORGOT_PASSWORD_BACK_TO_LOGIN = "forgot_password_back_to_login",
  CB_PAYU_BACK_SUCCESS = "cb_payu_back_success",
  CB_PAYU_BACK_FAIL = "cb_payu_back_fail",
  BACK_TO_HOME_PAGE_CLICK = "back_to_home_page_click",
  GO_TO_CONSULTATION_CLICK = "go_to_consultation_click",
  USER_DATA_PAGE_LOAD = "user_data_page_load",
  USER_DATA_BUTTON_CANCEL_CLICK = "user_data_button_cancel_click",
  USER_DATA_BUTTON_SAVE_CLICK = "user_data_button_save_click",
  USER_DATA_SAVE_SUCCESS = "user_data_save_success",
  USER_DATA_SAVE_ERROR = "user_data_save_error",
  SICK_LEAVE_SURVEY_PAGE_LOAD = "sick_leave_survey_page_load",
  SICK_LEAVE_SURVEY_BUTTON_NEXT_CLICK = "sick_leave_survey_button_next_click",
  SICK_LEAVE_SURVEY_BUTTON_CONFIRM_CLICK = "sick_leave_survey_button_confirm_click",
  SICK_LEAVE_SURVEY_CONFIRM_SUCCESS = "sick_leave_survey_confirm_success",
  SICK_LEAVE_SURVEY_CONFIRM_ERROR = "sick_leave_survey_confirm_error",
  CONSULTATION_SURVEY_START = "consultation_survey_start",
  CONSULTATION_SURVEY_END = "consultation_survey_end",
  CONSULTATION_SURVEY_ANSWER = "consultation_survey_answer",
  // https://telemedico.atlassian.net/browse/LESS-3669
  DASHBOARD_CONSULTATIONS_PAGE_LOAD = "dashboard_consultations_page_load",
  DOZ_ORDER_BUTTON_CLICK = "doz_order_button_click",
  DOZ_DOWNLOAD_PRESCRIPTION_BUTTON_CLICK = "doz_download_prescription_button_click",
  DOZ_SUMMARY_PAGE_LOAD = "doz_summary_page_load",
  DOZ_FILL_ADRESS_DATA_POPUP_LOAD = "doz_fill_adress_data_popup_load",
  DOZ_CONFIRM_ORDER_BUTTON_CLICK = "doz_confirm_order_button_click",
  DOZ_CANCEL_ORDER_BUTTON_CLICK = "doz_cancel_order_button_click",
  DOZ_REDIRECT_BUTTON_CLICK = "doz_redirect_button_click",
  DOZ_THANK_YOU_PAGE_LOAD = "doz_thank_you_page_load",
  // e/o https://telemedico.atlassian.net/browse/LESS-3669
  // https://telemedico.atlassian.net/browse/LESS-3671
  DASHBOARD_MENU_ORDER_CLICK = "dashboard_menu_order_click",
  DASHBOARD_MENU_CONSULTATIONS_CLICK = "dashboard_menu_consultations_click",
  DASHBOARD_MENU_DOCUMENTATION_CLICK = "dashboard_menu_documentation_click",
  DASHBOARD_MENU_HEALTHPLANNER_CLICK = "dashboard_menu_healthplanner_click",
  DASHBOARD_MENU_MENTAL_HEALTH_CLICK = "dashboard_menu_mental_health_click",
  DASHBOARD_MENU_HELP_CLICK = "dashboard_menu_help_click",
  DASHBOARD_MENU_ORDER_PRESCRIPTION_CLICK = "dashboard_menu_order_perscription_click",
  DASHBOARD_MENU_ORDER_REMOTE_CONSULTATION_CLICK = "dashboard_menu_order_remote_consultation_click",
  DASHBOARD_MENU_ORDER_SICK_LEAVE_CLICK = "dashboard_menu_order_sick_leave_click",
  DASHBOARD_MENU_ORDER_EXAMINATION_CLICK = "dashboard_menu_order_examination_click",
  DASHBOARD_MENU_ORDER_REFERRAL_CLICK = "dashboard_menu_order_referral_click",
  DASHBOARD_MENU_ORDER_STATIONARY_POZ_CONSULTATION_CLICK = "dashboard_menu_order_stationary_POZ_consultation_click",
  DASHBOARD_MENU_ORDER_STATIONARY_CONSULTATION_CLICK = "dashboard_menu_order_stationary_consultation_click",
  DASHBOARD_NAVIGATION_BAR_MY_DATA_CLICK = "dashboard_navigation_bar_my_data_click",
  DASHBOARD_NAVIGATION_BAR_FINANCE_CLICK = "dashboard_navigation_bar_finance_click",
  DASHBOARD_NAVIGATION_BAR_CHANGE_LANGUAGE_CLICK = "dashboard_navigation_bar_change_language_click",
  DASHBOARD_NAVIGATION_BAR_LOGOUT_CLICK = "dashboard_navigation_bar_logout_click",
  DASHBOARD_NAVIGATION_BAR_POZ_CLICK = "dashboard_navigation_bar_POZ_click",
  DASHBOARD_NAVIGATION_BAR_SWITCH_ACCOUNT_CLICK = "dashboard_navigation_bar_switch_account_click",
  DASHBOARD_CONSULTATION_MAKE_APPOINTMENT_AGAIN_BUTTON_CLICK = "dashboard_consultation_make_appointment_again_button_click",
  DASHBOARD_CONSULTATION_GO_TO_CHAT_LINK_CLICK = "dashboard_consultation_go_to_chat_link_click",
  DASHBOARD_CONSULTATION_HOW_IT_WORKS_LINK_CLICK = "dashboard_consultation_how_it_works_link_click",
  DASHBOARD_CONSULTATION_CANCEL_BUTTON_CLICK = "dashboard_consultation_cancel_button_click",
  DASHBOARD_CONSULTATION_PAY_BUTTON_CLICK = "dashboard_consultation_pay_button_click",
  DASHBOARD_CONSULTATION_CANCEL_ALERT_CANCEL_BUTTON_CLICK = "dashboard_consultation_cancel_alert_cancel_button_click",
  DASHBOARD_CONSULTATION_CANCEL_ALERT_CONFIRM_BUTTON_CLICK = "dashboard_consultation_cancel_alert_confirm_button_click",
  DASHBOARD_CONSULTATION_FILL_DATA_BUTTON_CLICK = "dashboard_consultation_fill_data_button_click",
  DASHBOARD_CONSULTATION_ENTER_BUTTON_CLICK = "dashboard_consultation_enter_button_click",
  DASHBOARD_DOCUMENTATION_PAGE_LOAD = "dashboard_documentation_page_load",
  DASHBOARD_DOCUMENTATION_ADD_FILE_BUTTON_CLICK = "dashboard_documentation_add_file_button_click",
  DASHBOARD_EXAMINATION_PAGE_LOAD = "dashboard_examination_page_load",
  DASHBOARD_EXAMINATION_EXAMS_SUCCESS = "dashboard_examination_exams_success",
  DASHBOARD_EXAMINATION_EXAMS_ERROR = "dashboard_examination_exams_error",
  DASHBOARD_EXAMINATION_ORDER_BUTTON_CLICK = "dashboard_examination_order_button_click",
  DASHBOARD_EXAMINATION_ORDER_CART_SUCCESS = "dashboard_examination_order_cart_success",
  DASHBOARD_EXAMINATION_ORDER_CART_ERROR = "dashboard_examination_order_cart_error",
  // e/o https://telemedico.atlassian.net/browse/LESS-3671
  // https://telemedico.atlassian.net/browse/LESS-4076
  PRESCRIPTION_SURVEY_PAGE_LOADED = "prescription_survey_page_loaded",
  POZ_COMPLETE_PAG_LOAD = "poz_complete_page_load"
  // e/o https://telemedico.atlassian.net/browse/LESS-4076
}