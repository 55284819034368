import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useSwitchAccountPageStyles = makeStyles((theme: Theme) => createStyles({
  switchAccountButton:{
    "&:hover, &:focus": {
      cursor:"pointer",
      backgroundColor: theme.palette.background.default
    },
  },
  highlightedSwitchAccountButton:{
    "&:hover, &:focus": {
      cursor:"pointer",
      backgroundColor: theme.palette.background.default
    },
    backgroundColor: theme.palette.background.default
  }

}))