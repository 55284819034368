import {postChatMessageConfig} from "../../api/routes"
import {v4Service} from "../store.service"
import {PostChatMessageType} from "./consultations.types"

export const consultationsApi = v4Service.injectEndpoints({
  endpoints: build => ({
    postChatMessage: build.mutation<{status: boolean}, PostChatMessageType>({
      query: ({consultationId, messageType, message, params}) => ({
        ...postChatMessageConfig,
        data: {
          consultation: consultationId,
          messageType,
          message,
          params
        }
      })
    })
  })
})

export const { usePostChatMessageMutation } = consultationsApi
