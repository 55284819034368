import {BaseQueryFn} from "@reduxjs/toolkit/query"
import {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios"

import {ssoMiddlewareHost} from "../app.config"

export const baseQuery = (axiosInstance: AxiosInstance): BaseQueryFn<{
  url: string
  method: AxiosRequestConfig["method"]
  data?: AxiosRequestConfig["data"]
  params?: Record<string, unknown>
  headers?: Record<string, unknown>
}> =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await axiosInstance({ url, method, data, params, headers })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data
        },
      }
    }
  }

export const ssoBaseQuery = (axiosInstance: AxiosInstance): BaseQueryFn<{
  url: string
  method: AxiosRequestConfig["method"]
  data?: AxiosRequestConfig["data"]
  withCredentials?: boolean
  params?: Record<string, unknown>
  headers?: Record<string, unknown>
}> =>
  async ({
    url,
    method,
    data,
    params,
    headers,
    withCredentials
  }) => {
    try {
      const result = await axiosInstance({ url: `${ssoMiddlewareHost}${url}`, method, data, params, headers, withCredentials })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data
        },
      }
    }
  }
