import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useMutation } from "@apollo/client"

import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks"
import { setGender } from "../../../../store/healthPlanner/healthPlanner.slice"
import { selectUser, selectUserInitialLoading } from "../../../../store/user/user.selectors"
import { useCompleteActionMutation } from "../../api/mutations/completeAction/completeAction.hook"
import { REGISTER_TO_HEALTH_PLANNER, RegisterResponse, RegisterVariables } from "../../api/mutations/register"
import { useGetSignDeclarationAction } from "../../api/queries/getSignDeclarationAction/getSignDeclarationAction.hook"
import {
  useGetUserQuestionnaireValues
} from "../../api/queries/getUserQuestionniareValues/getUserQuestionnaireValues.hook"
import { getGenderFromPesel } from "../../../../utils/getGenderFromPesel"
import { isNotNullish } from "../../../../utils/isNotNullish"
import { useSuccessToast } from "../successToast/SuccessToast.utils"
import { RoutePath } from "../../../../routes/Routes.types"
import { Gender as HealthPlannerGender } from "../../../../store/healthPlanner/healthPlanner.types"
import { Gender } from "../../../../store/user/user.types"
import { InputType } from "../../pages/preventionQuestionnaire/genericStepForm/GenericStepForm.types"

export const useUserRegister = () => {
  const { i18n } = useTranslation()
  const { push } = useHistory()
  const user = useAppSelector(selectUser)
  const isLoadingUserData = useAppSelector(selectUserInitialLoading)
  const [
    handleRegister,
    {
      loading: isLoadingRegister,
      called,
      error
    }
  ] = useMutation<RegisterResponse, RegisterVariables>(
    REGISTER_TO_HEALTH_PLANNER,
    {
      context: {clientName: "HealthPlanner"}
    }
  )

  useEffect(() => {
    if (error) {
      push(
        `/${i18n.language}${RoutePath.SERVER_ERROR.replace(":code", "500")}`
      )
    }
  }, [error])

  useEffect(
    () => {
      if (user && !called) {
        handleRegister({
          variables: {
            hasPozDeclaration: user.has_poz_declaration
          }
        })
      }
    },
    [user]
  )

  return {
    isRegistering: !called || isLoadingRegister || isLoadingUserData
  }
}

export const useCheckSignDeclarationAction = () => {
  const user = useAppSelector(selectUser)
  const showSuccessToast = useSuccessToast()
  const [handleCompleteAction, { loading: isCompleteActionLoading }] = useCompleteActionMutation()
  const { data, loading: isSignDeclarationActionLoading } = useGetSignDeclarationAction()
  const hasPozDeclaration = user?.has_poz_declaration

  useEffect(
    () => {
      const signDeclarationAction = data?.usersActions[0]
      if (hasPozDeclaration && signDeclarationAction && !signDeclarationAction.completedAt) {
        const { id, action: { name, points }} = signDeclarationAction
        handleCompleteAction({
          variables: {
            actionId: id
          }
        })
          .then(() => {
            showSuccessToast(name, points)
          })
      }
    },
    [hasPozDeclaration, data]
  )

  return {
    isChecking: isCompleteActionLoading || isSignDeclarationActionLoading
  }
}

//todo: https://telemedico.atlassian.net/browse/LESS-1615
const GENDER_GLOBAL_QUESTION_ID = "6cb06534-d3c8-4442-8287-9f6cc44871aa"
const FEMALE_ANSWER_ID = "1"

export const useSetGender = () => {
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const { data, loading } = useGetUserQuestionnaireValues()

  useEffect(() => {
    if (!data) {
      return
    }

    const genderGlobalQuestion = data.usersQuestionnaireValues.find(
      usersQuestionnaireValue => usersQuestionnaireValue.globalQuestionId === GENDER_GLOBAL_QUESTION_ID
    )

    if (!genderGlobalQuestion) {
      return
    }

    const answeredGender = genderGlobalQuestion?.type === InputType.PICKER
      ? genderGlobalQuestion?.pickerId === FEMALE_ANSWER_ID
        ? HealthPlannerGender.WOMEN
        : HealthPlannerGender.MAN
      : null

    if (answeredGender) {
      dispatch(setGender(answeredGender))
    }
  }, [data])

  useEffect(() => {
    if (!user) {
      return
    }

    const userGender = user.gender ?? getGenderFromPesel(user.pesel)

    if (isNotNullish(userGender)) {
      const gender =
        userGender === Gender.male
          ? HealthPlannerGender.MAN
          : HealthPlannerGender.WOMEN

      dispatch(setGender(gender))
    }
  }, [user, data])

  return {
    isSetting: loading
  }
}
