import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import {RouteComponentProps, useHistory} from "react-router"
import {Box, Button, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import paymentSuccessfulImage from "../../assets/images/payment-successful.png"
import ImageByIntegrationType from "../../components/imageByIntegrationType/ImageByIntegrationType"
import {useSuccessfulPaymentPageStyles } from "../../components/successfulPayment/SuccessfulPayment.styles"

interface ExamSuccessfulPaymentPageParams {}

const ExamSuccessfulPaymentPage: FC<RouteComponentProps<ExamSuccessfulPaymentPageParams>> = () => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const classes = useSuccessfulPaymentPageStyles()
  const {push} = useHistory()

  const goToExamsPage = () => {
    push(`/${i18n.language}`)
  }

  const imageBox = (
    <Box className={classes.imageWrapper}>
      <ImageByIntegrationType
        imageSrc={paymentSuccessfulImage}
        alt="paymentSuccessfulImage"
        className={classes.image}
        imagePath={"payment-successful.png"}
      />
    </Box>
  )

  return (
    <Box
      className={classes.pageSection}
      component="section"
    >
      <Box className={classes.content}>
        <Typography
          variant={isMdUp ? "h2" : "h4"}
          component="h2"
          className={classes.title}
        >
          { t("payments:successPayment:title") }
        </Typography>

        { !isMdUp &&
          imageBox
        }

        <Box mb={2}>
          <Typography variant={isMdUp ? "h4" : "h5"} component="span">
            {t("payments:successPayment:caption")}
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography className="break-spaces">
            {t("payments:successPayment:examsDescription")}
          </Typography>
        </Box>

        <Box className={classes.buttonsWrapper}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={goToExamsPage}
          >
            {t("payments:successPayment:goToExamsListPage")}
          </Button>
        </Box>
      </Box>

      { isMdUp &&
        imageBox
      }
    </Box>
  )
}

export default ExamSuccessfulPaymentPage
