import React, { FC, useEffect } from "react"
import {Controller, useForm} from "react-hook-form"
import { useTranslation } from "react-i18next"
import {useHistory} from "react-router"
import { Box, Button, TextField, Typography } from "@material-ui/core"

import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks"
import useGetFormValidationRules from "../../hooks/useGetFormValidationRules"
import useIsB2BClinic from "../../hooks/useIsB2BClinic"
import { selectSelectedLoginAccount } from "../../store/loginAccounts/loginAccounts.selectors"
import { clearSelectedLoginAccount } from "../../store/loginAccounts/logInAccounts.slice"
import {usePostForgotPasswordConfigMutation} from "../../store/patients/patients.api"
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader.component"
import PageSection from "../common/pageSection/PageSection.component"
import GlobalFormErrorMessage from "../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import WrapperLoginAccountModal from "../loginUsersModal/WrapperLoginAccountModal.component"
import {getUserNameLabel} from "../../pages/login/LoginPage.utils"
import {isBaseQueryError} from "../../store/store.utils"
import { SendUsernameFormValues } from "./ForgotPassword.types"
import {useForgotPasswordStyles} from "./ForgotPassword.styles"

import ForgotPasswordTitleNode from "./common/ForgotPasswordTitleNode"
import {sendUsernameValidationSchema} from "./ForgotPassword.validation"

interface SendUsernameFormProps {
  setUserId: (userId: string) => void;
}

const SendUsernameForm: FC<SendUsernameFormProps> = ({setUserId}) => {
  const { t, i18n } = useTranslation()
  const {goBack} = useHistory()
  const classes = useForgotPasswordStyles()
  const selectedAccount = useAppSelector(selectSelectedLoginAccount)
  const dispatch = useAppDispatch()
  const {B2BClinic} = useIsB2BClinic()
  const [handlePostForgotPassword, { isLoading, isError, error }] = usePostForgotPasswordConfigMutation()
  const globalErrorMessage = isBaseQueryError(error)
    ? t(`errors:${error?.data}`)
    : t("errors:unknownError")

  const form = useForm<SendUsernameFormValues>({
    mode: "all",
    resolver: useGetFormValidationRules(sendUsernameValidationSchema),
    defaultValues: {
      username: ""
    }
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    const data = await handlePostForgotPassword({
      lang: i18n.language,
      username: values.username,
    })
      .unwrap()
      .then(data => data)

    if ("id" in data) {
      setUserId(data.id)
    }
  })

  useEffect(() => {
    if (selectedAccount) {
      handlePostForgotPassword({
        lang: i18n.language,
        username: selectedAccount.id,
      })
        .unwrap()
        .then(() => {
          setUserId(selectedAccount.id)
          dispatch(clearSelectedLoginAccount())
        })
    }
  }, [selectedAccount])

  return (
    <PageSection titleNode={
      <ForgotPasswordTitleNode title="pageTitle"/>
    }>
      <Box className={classes.sectionBox}>
        <Box mb={1}>
          <Typography variant={"body2"}>
            {t(B2BClinic ? "b2bClinic:forgotPasswordPageDescription" : "forgotPassword:pageDescription")}
          </Typography>
        </Box>

        {isError && (
          <GlobalFormErrorMessage
            message={globalErrorMessage === "[object Object]" ? t("errors:unknownError") : globalErrorMessage}
          />
        )}

        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <Controller
            name="username"
            control={form.control}
            render={({
              field: {onChange, value},
              fieldState: {error, invalid}
            }) => (
              <TextField
                id="username"
                label={getUserNameLabel(B2BClinic)}
                placeholder={getUserNameLabel(B2BClinic)}
                fullWidth
                disabled={isLoading}
                value={value}
                onChange={onChange}
                error={invalid}
                helperText={error?.message}
                required={true}
                autoFocus
              />
            )}
          />

          <Box className={classes.buttonsWrapper}>
            <Button
              disabled={isLoading}
              variant="outlined"
              color="primary"
              type="button"
              className={classes.button}
              onClick={goBack}
            >
              {t("forgotPassword:backToLoginPage")}
            </Button>

            <Button
              disabled={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              startIcon={isLoading && <ButtonLoader position="prefix"/>}
            >
              {t("send")}
            </Button>
          </Box>
        </form>
      </Box>
      <WrapperLoginAccountModal />
    </PageSection>
  )
}

export default SendUsernameForm
