import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useDoctorDetailsStyles = makeStyles<Theme, { customConsultationTypeColor: string | null }>((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      marginBottom: theme.spacing(5),
      [theme.breakpoints.up("md")]: {
        marginBottom: 0,
      }
    },
    contentWrapper: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        flexWrap: "wrap",
      }
    },
    controlVisitSection: {
      marginTop: theme.spacing(4.5),
      width: "210px"
    },
    consultationDate: {
      marginBottom: "5px",
      display: "block",
      textTransform: "capitalize",
      letterSpacing: "0",
    },
    doctorDataSection: {
      display: "flex",
      flexDirection: "column",
      flex: "1",
    },
    doctorTitle: {
      marginRight: theme.spacing(2),
    },
    doctorImage: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(13),
        height: theme.spacing(13),
      }
    },
    doctorImageAvatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      border: 0,
      background: "none",
      margin: theme.spacing(1, 0, 8),
    },
    consultationDateTypeSection: {
      flex: "0 0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      marginLeft: "auto",
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-start",
        marginLeft: "0",
        order: "-1"
      }
    },
    consultationTypeImg: {
      color: props => props.customConsultationTypeColor || theme.palette.secondary.light,
    },
    consultationTypeSection: {
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        marginLeft: "0",
      }
    },
    buttonsSection: {
      [theme.breakpoints.up("md")]: {
        top: theme.spacing(-4),
        position: "relative",
        minWidth: "100%",
        textAlign: "right",
        whiteSpace: "nowrap",
      }
    },
    button: {
      marginTop: "-45px",
      [theme.breakpoints.up("sm")]: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(-1.5),
      },
      [theme.breakpoints.up("md")]: {
        marginRight: 0,
      },
    },
    prescriptionOnlyImage: {
      border: 0,
      background: "transparent",
    },
    newVisitButton: {
      position: "relative",
      marginTop: theme.spacing(3),

      [theme.breakpoints.up("md")]: {
        top: theme.spacing(-4),
        marginTop: 0,
      }
    },
    buttonGoToConsultation: {
      width: "100%",
      justifyContent: "flex-start",
      textAlign: "left",
      marginTop: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        textAlign: "right",
        justifyContent: "flex-end",
        margin: 0
      },
    },
    controlVisitSectionBottom: {
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(8)
      }
    }
  }),
)
