import React, { ChangeEvent, FC, KeyboardEvent } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Button, TextField, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"

import { useSendChatMessageMutation } from "../../../../api/queries/sendChatMessage/sendChatMessage.hook"
import IconByIntegrationType from "../../../../../../components/iconByIntegrationType/IconByIntegrationType"
import { useSendMessageFormStyles } from "./SendMessageForm.styles"

type SendMessageFormProps = {
  threadId: string;
  userId: string;
}

type SendMessageDateType = {
  newMessageValue: string;
}

const SendMessageForm: FC<SendMessageFormProps> = ({
  threadId,
  userId,
}) => {
  const { t } = useTranslation()
  const classes = useSendMessageFormStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  const form = useForm<SendMessageDateType>({
    defaultValues: {
      newMessageValue: "",
    }
  })

  const [sendChatMessageAction, { loading }] = useSendChatMessageMutation()

  const newMessageValue = form.watch("newMessageValue")

  const onSubmit = form.handleSubmit(async (values) => {
    const newMessage = values.newMessageValue.trim()

    if (!newMessage) {
      return
    }

    form.reset()
    sendChatMessageAction({
      variables: {
        message: newMessage,
        senderId: userId,
        threadId: threadId
      }
    })
  })

  const handleMessageChange = (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value.trimStart())
  }

  const handleKeyPress = async (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      await onSubmit()
    }
  }

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={onSubmit}
      className={classes.form}
    >
      <Controller
        name="newMessageValue"
        control={form.control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            autoFocus
            value={value}
            placeholder={t("chatConsultation:messageHere")}
            error={!!error}
            helperText={error?.message}
            fullWidth
            multiline
            minRows={3}
            maxRows={5}
            InputProps={{
              classes: {
                root: classes.textField,
              }
            }}
            onKeyPress={handleKeyPress}
            onChange={handleMessageChange(onChange)}
          />
        )}
      />

      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={loading}
        className={clsx(!newMessageValue && "pointer-events-none")}
      >
        { isMdUp
          ? t("defaultTranslations:send")
          : <IconByIntegrationType iconName={"icon-paperplane"}/>
        }
      </Button>
    </form>
  )
}

export default SendMessageForm
