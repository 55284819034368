import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, MenuItem, Select} from "@material-ui/core"

import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import { DocumentationTabItemConfigType } from "../Documentation.type"
import { getDocumentIconType } from "../Documentation.utils"

interface DocumentationGroupTypeSelectMobileProps {
  documentationTabItemsConfig: DocumentationTabItemConfigType[];
  selectedTab: number;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const DocumentationGroupTypeSelectMobile: FC<DocumentationGroupTypeSelectMobileProps> = (
  {
    documentationTabItemsConfig,
    selectedTab,
    handleChange,
  }
) => {
  const { t } = useTranslation()

  return (
    <Box px={2} my={4}>
      <Select
        variant="outlined"
        value={selectedTab}
        onChange={handleChange}
        fullWidth
      >
        { documentationTabItemsConfig.map((documentationTab, idx) => {
          const tabIcon = <IconByIntegrationType iconName={getDocumentIconType(documentationTab.value)} />

          return (
            <MenuItem
              key={documentationTab.value}
              value={idx}
            >
              { tabIcon }
              { t(`documents:${documentationTab.label}`) }
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  )
}

export default DocumentationGroupTypeSelectMobile
