import React, { FC, useState } from "react"
import {useTranslation} from "react-i18next"
import {
  Accordion, AccordionDetails,
  AccordionSummary,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@material-ui/core"
import clsx from "clsx"
import { CaretDown } from "phosphor-react"

import { useAppSelector } from "../../../hooks/storeHooks"
import { analytics, LogEventType } from "../../../services/analytics"
import { selectClinicSettings } from "../../../store/clinic/clinicSettings.selectors"
import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import LangsList from "./langsList/LangsList.component"
import { languageSelection } from "../langFlag/LangFlag.utils"
import { useLangDropdownStyles } from "./LangDropdown.styles"

interface LangDropdownProps {}

const LangDropdown: FC<LangDropdownProps> = () => {
  const {t, i18n} = useTranslation()
  const [expandedDropdown, setExpandedDropdown] = useState<boolean>(false)
  const classes = useLangDropdownStyles()
  const languageSelect = languageSelection(i18n?.services?.resourceStore?.options?.supportedLngs || [])
  const { clinicAllianzSettings: {
    ewkaVerificationEnabled,
    onyxVerificationEnabled
  } } = useAppSelector(selectClinicSettings)

  const currentLang = i18n.language

  if (!languageSelect) {
    return null
  }

  const handleCollapseLangMenu = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_NAVIGATION_BAR_CHANGE_LANGUAGE_CLICK)
    setExpandedDropdown(!expandedDropdown)
  }

  return (
    <MenuItem
      className={clsx(classes.listItemRoot, classes.customHover)}
      onClick={handleCollapseLangMenu}
    >
      <Accordion
        expanded={expandedDropdown}
        square
        classes={{
          root: classes.accordionRoot,
        }}
      >
        <AccordionSummary
          expandIcon={<CaretDown weight="bold"/>}
          classes={{
            root: classes.accordionSummary,
            content: classes.accordionContent,
          }}
        >
          <ListItemIcon classes={{
            root: !(ewkaVerificationEnabled || onyxVerificationEnabled) 
              ? classes.listItemIconRoot 
              : classes.listItemLanguageIconRoot
          }}>
            {
              <IconByIntegrationType iconName={"icon-translate"}/>
            }
          </ListItemIcon>

          <ListItemText
            classes={{root: classes.listItemTextRoot}}
            disableTypography
          >
            {t("language")}: {t(currentLang)}
          </ListItemText>
        </AccordionSummary>

        <AccordionDetails classes={{ root: classes.accordionDetails }}>
          <LangsList/>
        </AccordionDetails>
      </Accordion>
    </MenuItem>
  )
}

export default LangDropdown
