import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Grid, Typography} from "@material-ui/core"

import {ReactComponent as WarningCircle} from "../../../assets/svg/warningCircle.svg"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import {isDozErrorResponse} from "../../../store/doz/doz.utils"
import {ErrorStatusCode} from "../../../store/doz/doz.types"
import {useDozErrorParserStyles} from "./DozErrorParser.styles"

interface ErrorParserProps {
  error: unknown;
}

const DozErrorParser: FC<ErrorParserProps> = ({ error }) => {
  const classes = useDozErrorParserStyles()
  const {t} = useTranslation()

  if (isDozErrorResponse(error)) {
    switch (error.code) {
      case ErrorStatusCode.VALIDATION_ERROR:
      case ErrorStatusCode.INTERNAL_SERVER_ERROR:
        return <Typography variant="subtitle1" color="error">{t("errors:unknownError")}</Typography>
      case ErrorStatusCode.AVAILABILITY_PRODUCT_ERROR:
        return (
          <>
            <Typography variant="subtitle1" color="error">{error.message}</Typography>
            <Typography variant="subtitle1" color="error">{error.data}</Typography>
            <Grid
              className={classes.errorContainer}
              container
              wrap="nowrap"
            >
              <ImageByIntegrationType
                imageSrc={<WarningCircle className={classes.errorIcon} />}
                className={classes.errorIcon}
                imagePath={"warningCircle.svg"}
                returnSvg={true}
              />
              <Typography
                variant="subtitle1"
                color="error"
                className={classes.missingMedicinesLabel}
              >
                {t("orderMedicines:missingMedicines")}
              </Typography>
            </Grid>
          </>
        )
      case ErrorStatusCode.NO_PRODUCT_AVAILABLE_ERROR:
      case ErrorStatusCode.NO_PRODUCT_PROVIDED:
        return (
          <>
            <Typography variant="subtitle1" color="error">{error.message}</Typography>
            <Typography variant="subtitle1" color="error">{error.data}</Typography>
          </>
        )
    }
  }

  return <Typography variant="subtitle1" color="error">{t("errors:unknownError")}</Typography>
}

export default DozErrorParser
