import {useRef, useState} from "react"

import {
  CompletedQuestion,
  CompletedQuestionnaireTemplate,
  QuestionnaireTemplate,
} from "./GenericStepForm.types"

export const useGenericStepForm = (
  questionnaireTemplate: QuestionnaireTemplate,
  onSubmit: (questionnaireTemplate: CompletedQuestionnaireTemplate) => Promise<unknown>,
) => {
  const questionsLength = questionnaireTemplate.questions.length

  const [stepNumber, setStepNumber] = useState(0)
  const [completedQuestions, setCompletedQuestions] = useState<CompletedQuestion[]>([])

  const goToPreviousQuestion = () => {
    setStepNumber(previousState => previousState - 1)
  }

  const saveAnswer = (index: number) => (completedQuestion: CompletedQuestion) => {
    const completedQuestionsToSave = [...completedQuestions]
    completedQuestionsToSave[index] = completedQuestion
    setCompletedQuestions(completedQuestionsToSave)

    const isLastQuestionCompleted = questionsLength === index + 1

    if (isLastQuestionCompleted) {
      onSubmit({
        ...questionnaireTemplate,
        questions: completedQuestionsToSave
      })
    } else {
      setStepNumber(index + 1)
    }
  }

  return {
    stepNumber,
    completedQuestions,
    progress: (stepNumber+1)/(questionsLength) * 100,
    saveAnswer,
    goToPreviousQuestion
  }
}

export const useScrollHandler = () => {
  const containerRef = useRef<HTMLDivElement>()

  const handleScrollToQuestion = () => {
    containerRef.current?.scrollIntoView()
  }

  return {
    containerRef,
    handleScrollToQuestion
  }
}
