import {useState} from "react"
import {useMutation, useQuery} from "@apollo/client"

import {COMPLETE_ACTION} from "../../api/mutations/completeAction"
import {
  GET_MEASUREMENT_ACTION,
  GetMeasurementActionResponse,
  GetMeasurementActionVariables
} from "../../api/queries/getMeasurementAction"
import {ExaminationType} from "../../api/queries/getUserActionById"
import {useSuccessToast} from "../successToast/SuccessToast.utils"

export const useCheckIfUserFinishMeasurementAction = (examinationType: ExaminationType) => {
  const [isCompleted, setIsCompleted] = useState(false)
  const showSuccessToast = useSuccessToast()
  const { loading, data } = useQuery<GetMeasurementActionResponse, GetMeasurementActionVariables>(
    GET_MEASUREMENT_ACTION,
    {
      context: { clientName: "HealthPlanner" },
      variables: {
        examinationType
      },
      onCompleted: (data) => {
        const measurementAction = data?.usersActions[0]
        const isActionNotCompleted =  measurementAction?.completedAt === null

        setIsCompleted(!isActionNotCompleted)
      }
    }
  )

  const [handleComplete] = useMutation(
    COMPLETE_ACTION,
    {
      context: { clientName: "HealthPlanner" }
    }
  )
  
  const handleFinishMeasurementAction = () => {
    const measurementAction = data?.usersActions[0]

    if (!isCompleted && measurementAction) {
      const { id, action: { name, points } } = measurementAction

      setIsCompleted(true)
      handleComplete({
        variables: {
          actionId: id
        }
      })
        .then(() => {
          showSuccessToast(name, points)
        })
    }
  }
  
  return {
    isLoadingUserActions: loading,
    handleFinishMeasurementAction
  }
}