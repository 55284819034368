import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Button, Typography} from "@material-ui/core"

import breastImage from "../../../../../../assets/images/breast3.png"
import doctorWithCalendar from "../../../../../../assets/images/doctorWithCalendar.png"
import testiclesImage from "../../../../../../assets/images/testicles1.png"
import {CodeName} from "../../../../api/queries/getUserActionsQuery"
import ButtonLoader from "../../../../../../components/common/buttonLoader/ButtonLoader.component"
import ImageByIntegrationType from "../../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import ActionButtons from "../actionButtons/ActionButtons.component"
import PhysicalActivityMeasurementLog
  from "../measurementLog/physicalActivityMeasurementLog/PhysicalActivityMeasurementLog.component"
import PreventionActionWrapper from "../preventionActionWrapper/PreventionActionWrapper.component"
import BreastExaminationDescription from "./breastExaminationDescription/BreastExaminationDescription.component"
import ConfirmationButtons from "./confirmationButtons/ConfirmationButtons.component"
import TesticlesExaminationDescription from "./testiclesExaminationDescription/TesticlesExamination.component"
import {useFinishAction} from "../PreventionActionCard.utils"
import {useYourTaskManager} from "./YourTask.utils"
import {RoutePath} from "../../../../../../routes/Routes.types"
import {useYourTaskStyles} from "./YourTask.styles"

import {TESTICLES_EXAMINATION_ID} from "./YourTask.config"
import {YourTaskStep} from "./YourTask.enum"

type YourTaskProps = {
  title: string;
  actionId: string;
  codeName: CodeName;
}

const YourTask: FC<YourTaskProps> = ({ title, actionId, codeName }) => {
  const { t } = useTranslation()
  const classes = useYourTaskStyles()
  const { step, handleChangeStep } = useYourTaskManager()
  const { handleFinish, isLoading } = useFinishAction(RoutePath.PREVENTION)
  const isTesticlesExamination = actionId === TESTICLES_EXAMINATION_ID

  if (codeName === CodeName.ACTIVATE_GOOGLE_FIT) {
    return (
      <PhysicalActivityMeasurementLog
        title={title}
      />
    )
  }

  switch(step) {
    case YourTaskStep.DESCRIPTION:
      return (
        <PreventionActionWrapper
          title={title}
          isImageHidden
        >
          {
            isTesticlesExamination
              ? <TesticlesExaminationDescription/>
              : <BreastExaminationDescription/>
          }
          <ActionButtons
            onClick={handleChangeStep(YourTaskStep.CONFIRMATION)}
          />
        </PreventionActionWrapper>
      )
    case YourTaskStep.CONFIRMATION:
      return (
        <PreventionActionWrapper
          title={t(
            isTesticlesExamination
              ? "healthPlanner:testiclesExaminationTitle"
              : "healthPlanner:breastExaminationTitle"
          )}
          overrideImage={
            <ImageByIntegrationType
              imageSrc={isTesticlesExamination ? testiclesImage : breastImage}
              alt="Testicles"
              className={classes.image}
              imagePath={isTesticlesExamination ? "testicles1.png" : "breast3.png"}
            />
          }
        >
          <Typography
            className={classes.text}
          >
            {t(
              isTesticlesExamination
                ? "healthPlanner:testiclesExaminationQuestion"
                : "healthPlanner:breastExaminationQuestion"
            )}
          </Typography>
          <ConfirmationButtons
            isTesticlesExamination={isTesticlesExamination}
            onClickAbnormalityButton={handleChangeStep(YourTaskStep.SUMMARY)}
          />
        </PreventionActionWrapper>
      )
    case YourTaskStep.SUMMARY:
      return (
        <PreventionActionWrapper
          title={t("healthPlanner:immediatelyContactDoctor")}
          overrideImage={
            <ImageByIntegrationType
              imageSrc={doctorWithCalendar}
              alt="DoctorWithCalendar"
              className={classes.image}
              imagePath={"doctorWithCalendar.png"}
            />
          }
        >
          <Typography
            className={classes.text}
          >
            {t(
              isTesticlesExamination
                ? "healthPlanner:testiclesCancerInfo"
                : "healthPlanner:breastCancerInfo"
            )}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFinish}
            disabled={isLoading}
            startIcon={isLoading && <ButtonLoader position="prefix"/>}
          >
            {t("healthPlanner:backToProphylaxis")}
          </Button>
        </PreventionActionWrapper>
      )
  }
}

export default YourTask
