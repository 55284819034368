import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useLocation} from "react-router"
import {Checkbox, FormControlLabel} from "@material-ui/core"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"

import {useAppSelector} from "../../hooks/storeHooks"
import {selectClinicSettings} from "../../store/clinic/clinicSettings.selectors"
import AppDialog from "../common/appDialog/AppDialog.component"

interface LoginPagePopupComponentProps {

}

const DONT_SHOW_POPUP_AGAIN_KEY = "dontShowLoginPagePopupAgain"
const DONT_SHOW_POPUP_IN_THIS_SESSION_KEY = "dontShowLoginPagePopupInThisSession"
const translationPrefix = "loginPagePopup:"

// https://telemedico.atlassian.net/browse/LESS-8314
const LoginPagePopup: FC<LoginPagePopupComponentProps> = () => {
  const {t,i18n} = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [isDontShowAgainChecked, setIsDontShowAgainChecked] = useState(false)
  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  const {
    frontendSettings: { modules },
    clinicLoginPagePopupSettings: {
      loginPagePopupTitle,
      loginPagePopupContent,
      modifiedAt}
  } = useAppSelector(selectClinicSettings)
  const isEnabled = modules.includes("login_page_popup")

  useEffect(() => {
    if ( !isEnabled ) {
      return
    }
    handlePopupChanged()

    const openOnPageView = shouldOpenOnPageView()

    if (!openOnPageView) {
      return
    }

    parseContentAndTitle()
    setIsOpen(true)
  }, [isEnabled])

  //If popup content changed since user last saw it, remove the dontShowAgain flag
  const handlePopupChanged = (): void => {
    const savedDateModified = localStorage.getItem(DONT_SHOW_POPUP_AGAIN_KEY)
    if (!savedDateModified || savedDateModified === modifiedAt) {
      return
    }
    localStorage.removeItem(DONT_SHOW_POPUP_AGAIN_KEY)
    sessionStorage.removeItem(DONT_SHOW_POPUP_IN_THIS_SESSION_KEY)
  }

  const parseContentAndTitle = (): void => {
    const parsedTitle = replaceTranslationKeysWithText(loginPagePopupTitle)
    const parsedContent = replaceTranslationKeysWithText(loginPagePopupContent)

    setTitle(parsedTitle)
    setContent(parsedContent)
  }

  const replaceTranslationKeysWithText = (text: string | null): string => {
    if ( !text ) {
      return ""
    }

    const needTranslation = text.match(/{(\w+)}/g)

    let parsedText = text
    needTranslation?.forEach((key) => {
      const keyWithoutBrackets = key.replace(/{|}/g, "")
      parsedText = parsedText.replace(key, t(`${translationPrefix}${keyWithoutBrackets}`))
    })
    return parsedText
  }

  const shouldOpenOnPageView = (): boolean => {

    return !sessionStorage.getItem(DONT_SHOW_POPUP_IN_THIS_SESSION_KEY)
        && !localStorage.getItem(DONT_SHOW_POPUP_AGAIN_KEY)
  }

  const handleDontShowAgainToggle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if ( !modifiedAt ) {
      return
    }

    const isChecked = event.target.checked
    setIsDontShowAgainChecked(() => isChecked)

    isChecked
      ? localStorage.setItem(DONT_SHOW_POPUP_AGAIN_KEY, modifiedAt)
      : localStorage.removeItem(DONT_SHOW_POPUP_AGAIN_KEY)
  }

  const handleClose = (): void => {
    sessionStorage.setItem(DONT_SHOW_POPUP_IN_THIS_SESSION_KEY, "toggled")
    setIsOpen(false)
  }

  const dontShowAgainCheckbox = <Checkbox
    checked={isDontShowAgainChecked}
    onChange={(e) => handleDontShowAgainToggle(e)}
    checkedIcon={<CheckBoxIcon color="primary"/>}
    icon={<CheckBoxOutlineBlankIcon/>}
  />

  if (!isEnabled) {
    return null
  }

  if(
    useLocation().pathname.includes("sms")
      || useLocation().pathname.includes("login")
      || useLocation().pathname == "/"
      || useLocation().pathname == ("/"+i18n.language)
  ){
    return null
  }

  return (
    <AppDialog
      open={isOpen}
      title={title}
      disableBackdropClick={true}
      closeButton={false}
      onClose={handleClose}
      footerChildren={
        <FormControlLabel
          control={dontShowAgainCheckbox}
          label={t(`${translationPrefix}dontShowAgainCheckboxLabel`)}/>
      }
    >
      <span dangerouslySetInnerHTML={{__html: content}}/>
    </AppDialog>
  )
}

export default LoginPagePopup
