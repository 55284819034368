import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Paper, Tab, Tabs} from "@material-ui/core"

import {UserAction} from "../../../../api/queries/getUserActionsByIds"
import RecommendedActions from "../ recommendedActions/RecommendedActions.component"
import TabPanel from "../../../preventionDiseases/tabPanel/TabPanel.component"
import Description from "../description/Description.component"
import DiseaseRisk from "../diseaseRisk/DiseaseRisk.component"
import {useTabs} from "../../../preventionDiseases/PreventionDiseases.utils"
import {useDesktopDiseaseDetailsStyles} from "./DesktopDiseaseDetails.styles"

type DesktopDiseaseDetailsProps = {
  name: string;
  description: string;
  imageUrl: string;
  userActions: UserAction[];
  isUserDisease: boolean;
  probability?: number;
  questionnaireId?: string;
}

const DesktopDiseaseDetails: FC<DesktopDiseaseDetailsProps> = ({
  name,
  description,
  userActions,
  isUserDisease,
  probability,
  imageUrl,
  questionnaireId
}) => {
  const {t} = useTranslation()
  const { tabValue, handleChange } = useTabs()
  const classes = useDesktopDiseaseDetailsStyles()

  return (
    <Box>
      <Box
        className={classes.container}
        component={Paper}
      >
        <Tabs
          className={classes.tabs}
          color="primary"
          value={tabValue}
          variant="fullWidth"
          indicatorColor='primary'
          textColor='primary'
          onChange={handleChange}
        >
          <Tab
            label={t("healthPlanner:diseaseDescription")}
            value="1"
          />
          {
            !isUserDisease && questionnaireId && (
              <Tab
                label={t("healthPlanner:diseaseRisk")}
                value="2"
              />
            )
          }
          <Tab
            label={t("healthPlannerActions:recommendedActions")}
            value="3"
          />
        </Tabs>
        <TabPanel
          tabValue={tabValue}
          value="3"
        >
          <RecommendedActions
            userActions={userActions}
          />
        </TabPanel>
        <TabPanel
          tabValue={tabValue}
          value="2"
        >
          <DiseaseRisk
            diseaseName={name}
            probability={probability}
            imageUrl={imageUrl}
          />
        </TabPanel>
        <TabPanel
          tabValue={tabValue}
          value="1"
        >
          <Description
            title={name}
            description={description}
          />
        </TabPanel>
      </Box>
    </Box>
  )
}

export default DesktopDiseaseDetails
