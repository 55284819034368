import React, {FC} from "react"
import {Box, Hidden, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import clsx from "clsx"

import ConsultationDoctorDataSection from "../consultationDoctorDataSection/ConsultationDoctorDataSection.component"
import {removeOrphansHTML} from "../../../utils/removeOrphansHTML"
import {ConsultationType} from "../../consultation/Consultation.types"
import {DoctorDetailsType} from "../../doctor/Doctor.types"
import {ChatMessageType, ConsultationChatItem} from "../ConsultationChat.types"
import {useConsultationChatMessageItemStyles} from "./ConsultationChatMessageItem.styles"

import {parseMessageCreatedAt} from "./ConsultationChatMessageItem.config"

interface WelcomeChatMessageItemProps extends ConsultationChatItem, DoctorDetailsType {
  consultationType: ConsultationType;
  sender?: string | null;
  messageRawType?: number;
}

const WelcomeChatMessageItemComponent: FC<WelcomeChatMessageItemProps> = (
  {
    messageRaw,
    createdAt,
    doctorImage,
    doctorSpecialization,
    doctorTitle,
    doctorAvatarPseudorandomBoolean,
    consultationType,
    sender,
    messageRawType,
    isStationaryVisit
  }
) => {
  const classes = useConsultationChatMessageItemStyles()
  const time = parseMessageCreatedAt(createdAt)
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))

  return (
    <Box 
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
      className={clsx(
        classes.welcomeMessageContainer,
      )}
    >
      <Box 
        className={classes.container} 
        display="flex"
      >
        <Box
          mr={2}
          className={clsx(
            classes.cloudMessageWrapper,
            !sender ? classes.patientDetailsMessage : null
          )}
        >
          <Typography
            dangerouslySetInnerHTML={{ __html: removeOrphansHTML(messageRaw) }}
          />
        </Box>
        { time && (
          <Box alignSelf="center" pl={isLgUp ? 0 : 1}>
            <Hidden smDown>
              <Typography className={classes.messageTime}>
                { time }
              </Typography>
            </Hidden>
          </Box>
        )}
      </Box>
      {
        isLgUp && (!sender && messageRawType !== ChatMessageType.HELP || sender && messageRawType === ChatMessageType.WELCOME && consultationType === ConsultationType.SICK_LEAVE) && (
          <Box>
            <ConsultationDoctorDataSection
              doctorImage={doctorImage}
              doctorSpecialization={doctorSpecialization}
              doctorTitle={doctorTitle}
              doctorAvatarPseudorandomBoolean={doctorAvatarPseudorandomBoolean}
              consultationType={consultationType}
              isStationaryVisit={isStationaryVisit}
            />
          </Box>
        )
      }
    </Box>
  )
}

export default WelcomeChatMessageItemComponent
