import {createStyles, makeStyles} from "@material-ui/core"

export const useInfoLabelStyles = makeStyles(theme => createStyles({
  label: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(4)
  },
  icon: {
    marginRight: theme.spacing(1),
    minWidth: "14px",
    minHeight: "14px"
  }
}))