import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, ClickAwayListener, Tooltip,Typography, useMediaQuery, useTheme } from "@material-ui/core"
import copy from "copy-to-clipboard"

import { useAwardsListReceivedItemStyles } from "./AwardsListReceivedItem.styles"

interface AwardsListReceivedItemProps {
  name: string;
  desc: string;
  imageUrl: string;
  points: number;
  code: string;
}

const AwardsListReceivedItem: FC<AwardsListReceivedItemProps> = ({
  name,
  desc,
  imageUrl,
  points,
  code,
}) => {
  const { t } = useTranslation()
  const {
    imageWrapper,
    image,
    listItem,
    contentWrapper,
    infoTitle,
    label,
    description,
    pointsLabel,
    codeWrapper,
    codeLabel,
    buttonCopy,
  } = useAwardsListReceivedItemStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [openTooltip, setOpenTooltip] = useState(false)

  const handleTooltipClose = () => {
    setOpenTooltip(false)
  }

  const handleCopyCode = () => {
    setOpenTooltip(true)
    copy(code)
  }
  
  return (
    <Box className={listItem}>
      <Box
        display="flex"
        flexDirection="row"
      >
        <Box className={imageWrapper}>
          <img
            className={image}
            src={imageUrl || ""}
            alt=""
          />
        </Box>

        <Box className={contentWrapper}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            mb={1}
          >
            <Typography
              variant={isMdUp ? "body1" : "body2"}
              className={infoTitle}
            >
              {name}
            </Typography>
            {isMdUp && (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={openTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={<span>{t("healthPlanner:copied")}</span>}
                  >
                    <Button 
                      onClick={handleCopyCode}
                      className={label}
                    >
                      {t("healthPlanner:code")}: {code}
                    </Button>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            )}
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Typography
                variant={isMdUp ? "body2" : "subtitle2"}
                className={description}
              >
                {desc}
              </Typography>
            </Box>
            {isMdUp && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                alignSelf="flex-end"
              >
                <Typography
                  variant="body1"
                  className={pointsLabel}
                >
                  <span>{points}</span> {t("awards:points")}
                </Typography>
              </Box>
            )}
          </Box>

        </Box>
      </Box>

      {!isMdUp && (
        <Box className={codeWrapper}>
          <Typography
            variant="body2"
            component="span"
            className={codeLabel}
          >
            {t("healthPlanner:voucherLabel")}
          </Typography>
          <Box overflow="hidden">
            {code}
          </Box>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={openTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={<span>{t("healthPlanner:copied")}</span>}
              >
                <Button
                  onClick={handleCopyCode}
                  className={buttonCopy}
                >
                  {t("healthPlanner:copy")}
                </Button>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Box>
      )}
    </Box>
  )
}

export default AwardsListReceivedItem