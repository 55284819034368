import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useForgotPasswordStyles = makeStyles((theme: Theme) => createStyles({
  pageSection: {
    margin: "auto",
    width: "100%",
    height: "100%",
  },
  sectionBox: {
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6),
    },
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "stretch",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      flexDirection: "row",
    },
  },
  button: {
    marginTop: theme.spacing(2),
  },
  birthDateInput: {
    width: "100%",
  },
  birthDateCalendar: {
    "& .MuiPickersDay-day": {
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(4),
      }
    },
    "& .MuiPickersDay-daySelected": {
      color: "initial",
      backgroundColor: "initial",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPickersBasePicker-pickerView, & .MuiPickersYearSelection-container, & .MuiPickersMonthSelection-container": {
        minWidth: "260px",
        maxWidth: "260px"
      },
      "& .MuiTextField-root": {
        width: "100%"
      }
    },
  }
}))
