import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core"

import {isNotNullish} from "../../../utils/isNotNullish"
import {getCustomExamPriceFormat, getExamPriceFormat, getReferralExamPriceFormat} from "../Exam.utils"
import { ConsultationExamReferral } from "../Exam.types"
import {useOrderExamsListStyles} from "../orderedExamsList/OrderExamsList.styles"

interface ConsultationOrderedExamsListProps {
  examsToBuy: ConsultationExamReferral[];
  examsWithError: ConsultationExamReferral[];
}

const ConsultationOrderedExamsList: FC<ConsultationOrderedExamsListProps> = ({examsToBuy, examsWithError}) => {
  const {t} = useTranslation()
  const classes = useOrderExamsListStyles()

  const examsPrice = examsToBuy.reduce(
    (price, exam) => price + (exam.priceAfterDiscount ?? parseFloat(exam.lab_price)),
    0
  )

  return (
    <>
      {examsToBuy.length > 0 && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow hover>
                  <TableCell component="th"> {t("exam:index")} </TableCell>
                  <TableCell component="th"> {t("exam:labName")} </TableCell>
                  <TableCell component="th" align="right"> {t("exam:labPrice")} </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {examsToBuy.map((exam, index) => (
                  <TableRow hover key={`${exam.id}-${index}`}>
                    <TableCell>
                      { index + 1 }
                    </TableCell>

                    <TableCell>
                      { exam.lab_name }
                    </TableCell>

                    <TableCell align="right">
                      {
                        isNotNullish(exam.priceAfterDiscount)
                          ? (
                            <div>
                              <s>{ getCustomExamPriceFormat(exam.lab_price) }</s>
                              <p className={classes.discountPrice}>
                                { getExamPriceFormat(exam.priceAfterDiscount) + "\u00A0zł" }
                              </p>
                            </div>
                          )
                          : <>{ getCustomExamPriceFormat(exam.lab_price) }</>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box p={2} mb={2} display="flex" justifyContent="space-between">
            <Typography>{t("exam:summary")}</Typography>
            <Typography>{t("exam:price")}: {getReferralExamPriceFormat(examsPrice)}</Typography>
          </Box>
        </>
      )}

      { examsWithError.length > 0 && (
        <>
          <Typography>
            {t("exam:referralExamsWithErrorInfo")}
          </Typography>

          <Box component="ul" pb={2}>
            { examsWithError.map((exam, index) => (
              <Typography
                key={`${exam.id}-${index}`}
                component="li"
              >
                { exam.lab_name }
              </Typography>
            ))}
          </Box>
        </>
      )}
    </>
  )
}

export default ConsultationOrderedExamsList
