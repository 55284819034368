import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from "react"
import {Controller, useForm} from "react-hook-form"
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import Rating from "@material-ui/lab/Rating"

import api from "../../../api/api"
import {postConsultationChatRatingConfig} from "../../../api/routes"
import useConsultationLang from "../../../hooks/useConsultationLang"
import ButtonLoader from "../../common/buttonLoader/ButtonLoader.component"
import CloseButton from "../../common/closeButton/CloseButton.component"
import GlobalFormErrorMessage from "../../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import { redirectToError500Page } from "../../../utils/handleErrors"
import {ConsultationModel} from "../../consultation/Consultation.types"
import { RateConsultationDataType, Satisfaction } from "./ConsultationRatingModal.types"
import { Path } from "react-hook-form/dist/types"
import {useConsultationRatingModalStyles} from "./ConsultationRatingModal.styles"

enum CancelModalReason {
  ESCAPE_KEY_DOWN = "escapeKeyDown",
  BACKDROP_CLICK = "backdropClick",
}

interface ConsultationRatingModalProps {
  consultationId: string;
  doctorName: string;
  rate: ConsultationModel["rate"];
}

const ConsultationRatingModal: FC<ConsultationRatingModalProps> = ({consultationId, doctorName, rate}) => {
  const classes = useConsultationRatingModalStyles()

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const consultationLang = useConsultationLang()

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isSuccessBox, setIsSuccessBox] = useState<boolean>(false)
  const [globalError, setGlobalError] = useState<string>("")

  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<RateConsultationDataType>({
    defaultValues: {
      consultation_id: consultationId,
      satisfaction: null,
      message: "",
    }
  })

  const handleSatisfactionChange = (onChange: (value: Satisfaction|null) => void) => (event: ChangeEvent<unknown>, newValue: number|null) => {
    onChange(newValue)
  }

  const handleClosePopup = () => {
    form.reset()
    setOpenModal(false)
  }

  const onDialogClose = () => (event: MouseEvent, reason: CancelModalReason) => {
    if (reason === CancelModalReason.BACKDROP_CLICK) {
      return
    }
    handleClosePopup()
  }

  const handleMessageChange = (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value.trimStart())
  }

  const onSubmit = form.handleSubmit(async (values) => {
    setLoading(true)

    try {
      await api.request({
        ...postConsultationChatRatingConfig,
        data: values
      })

      setIsSuccessBox(true)
    } catch (e) {
      redirectToError500Page(e)
      if (e.response?.data?.errors) {
        for (const [fieldName, fieldValue] of Object.entries(e.response.data.errors)) {
          const errorMessage = (fieldValue as string[]).join("\u000A")
          form.setError(
            fieldName as Path<RateConsultationDataType>,
            {
              type: "manual",
              message: consultationLang.getLabel(`errors:${errorMessage}`)
            }
          )

          if (fieldName === "consultation_id") {
            setGlobalError(consultationLang.getLabel(`errors:${errorMessage}`))
          }
        }
      } else {
        console.error(e.response.data)
      }
    }

    setLoading(false)
  })

  useEffect(() => {
    if (!rate) {
      setOpenModal(true)
    }
  }, [])

  return (
    <Dialog
      open={openModal}
      maxWidth={"md"}
      classes={{
        paper: classes.popup
      }}
      onClose={onDialogClose}
      fullScreen={!isSmUp}
    >
      <CloseButton onClick={handleClosePopup}/>

      <DialogTitle>
        <Box mr={4} mb={1}>
          <Typography variant={"h3"}>
            {consultationLang.getLabel("chatConsultation:rating:consultationEnded")}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        { !isSuccessBox
          ? (
            <Box>
              <Box textAlign="center">
                { globalError && (
                  <GlobalFormErrorMessage
                    message={globalError}
                  />
                )}

                <Box mt={2} mb={1}>
                  <Typography variant={"h6"} component="span">
                    {consultationLang.getLabel("chatConsultation:rating:doctorAskingForRating", { doctorName: doctorName })}
                  </Typography>
                </Box>
              </Box>

              <form
                autoComplete="off"
                noValidate
                className={classes.form}
                onSubmit={onSubmit}
              >
                <Controller
                  name="satisfaction"
                  control={form.control}
                  render={({ field: { onChange, value }}) => (
                    <Rating
                      name="satisfaction"
                      value={value}
                      size="large"
                      disabled={loading}
                      onChange={handleSatisfactionChange(onChange)}
                    />
                  )}
                />

                <Box mt={4}>
                  <Typography variant={"h5"}>
                    {consultationLang.getLabel("chatConsultation:rating:doYouWantToSayMore")}
                  </Typography>
                </Box>

                <Controller
                  name="message"
                  control={form.control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      value={value}
                      label={consultationLang.getLabel("chatConsultation:rating:additionalMessage")}
                      placeholder={consultationLang.getLabel("chatConsultation:rating:additionalMessage")}
                      error={!!error}
                      helperText={error?.message}
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={5}
                      onChange={handleMessageChange(onChange)}
                    />
                  )}
                />

                <Button
                  disabled={loading}
                  variant={"contained"}
                  color={"primary"}
                  type="submit"
                  className={classes.submitButton}
                  endIcon={ loading && <ButtonLoader/>}
                >
                  { consultationLang.getLabel("send") }
                </Button>
              </form>
            </Box>
          )
          : (
            <Box className={classes.succesBox}>
              <Box className={classes.succesBox}>
                <Box mb={3}>
                  <Typography variant={"h6"} component="span">
                    {consultationLang.getLabel("chatConsultation:rating:successMessageTitle")}
                  </Typography>
                </Box>

                <Box mb={3} textAlign={"center"}>
                  <Typography className={classes.text}>
                    {consultationLang.getLabel("chatConsultation:rating:doctorThanks", { doctorName: doctorName })}
                  </Typography>
                </Box>
              </Box>

              <Button
                variant={"contained"}
                color={"primary"}
                className={classes.closeButton}
                onClick={handleClosePopup}
              >
                { consultationLang.getLabel("close") }
              </Button>
            </Box>
          )
        }
      </DialogContent>
    </Dialog>
  )
}

export default ConsultationRatingModal
