import React, {FC} from "react"
import { useState } from "react"
import {useTranslation} from "react-i18next"
import {Box, Link,Typography} from "@material-ui/core"
import {add, parseISO} from "date-fns"

import clinicImage from "../../../assets/images/clinic.png"
import useImageByIntegrationType from "../../imageByIntegrationType/useImageByIntegrationType"
import ComingServiceDetails from "../../services/comingServiceDetails/ComingServiceDetails.component"
import CancelExaminationPopup from "../cancelExaminationPopup/CancelExaminationPopup.component"
import ComingExamReferralsModal from "../comingExamReferrals/ComingExamReferralsModal.component"
import {AddToCalendarEvent} from "../../common/addToCalendar/AddToCalendar.types"
import {ExamModel} from "../Exam.types"
import {useComingExamDetailsStyles} from "./ComingExamDetails.styles"

const STANDARD_EXAM_DURATION_MIN = 30

interface ComingExamDetailsProps extends ExamModel {
  isOutdatedVariant?: boolean
}

const ComingExamDetails: FC<ComingExamDetailsProps> = ({
  id,
  exam_point_address: examPointAddress,
  exam_point_name: examPointName,
  exam_point_url: examPointUrl,
  date,
  name,
  referrals,
  isOutdatedVariant,
}) => {
  const {t} = useTranslation()
  const classes = useComingExamDetailsStyles()
  const {imageSrcState} = useImageByIntegrationType("clinic.png", clinicImage)
  const examDate = date ? parseISO(date) : null
  const examHour = examDate ? t("dates:time", { date: examDate }) : null
  const examDateWithDay = examDate ? t("dates:consultationDayWithDate", { date: examDate }) : null

  const calendarEvent: AddToCalendarEvent | null = examDate
    ? {
      title: t("exam:examTitle", {name}),
      description: t("exam:examDesc"),
      location: examPointAddress || undefined,
      startTime: examDate,
      endTime: add(examDate, {minutes: STANDARD_EXAM_DURATION_MIN})
    }
    : null

  const laboratoryAddress = examPointAddress && examPointAddress.split(": ")

  const addressString = laboratoryAddress
    ? `${laboratoryAddress} ${t("exam:orAnyFacility", { examPointName })}`
    : `${t("exam:anyFacility", { examPointName })}`

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false)
  const showExamDetails = () => {
    setIsDetailsModalOpen(true)
  }

  const [isCancelExaminationModalOpen, setIsCancelExaminationModalOpen] = useState<boolean>(false)
  const openCancelExaminationPopup = () => {
    setIsCancelExaminationModalOpen(true)
  }

  const datePlaceholderBox = isOutdatedVariant
    ? (
      <Typography
        className={classes.outdatedExamLabel}
        color="textPrimary"
      >
        { t("exam:examIsOutdated") }
      </Typography>
    )
    : (
      <>
        <Typography variant="inherit">
          { t("exam:anyTime") }
        </Typography>
        <Box>
          <Link
            color="inherit"
            underline="always"
            href={examPointUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {examPointName}
          </Link>
        </Box>
      </>
    )

  const getActionButtonData = () => {
    return {
      label: t("exam:details"),
      actionButtonOnClick: () => referrals.length > 0 ? showExamDetails() : undefined
    }
  }

  return (
    <>
      <ComingServiceDetails
        id={id}
        labelText={name}
        avatarSrc={imageSrcState}
        avatarAlt={examPointName}
        detailsTitle={t("exam:exam")}
        facilityName={examPointName.toUpperCase()}
        facilityAddress={addressString}
        dateWithDay={examDateWithDay}
        hour={examHour}
        datePlaceholder={datePlaceholderBox}
        actionButtonData={getActionButtonData()}
        cancelButtonOnClick={openCancelExaminationPopup}
        event={calendarEvent}
        isOutdatedVariant={isOutdatedVariant}
      />

      {referrals.length > 0 &&
        <ComingExamReferralsModal
          referrals={referrals}
          open={isDetailsModalOpen}
          onClose={() => {
            setIsDetailsModalOpen(false)
          }}
        />
      }

      <CancelExaminationPopup
        open={isCancelExaminationModalOpen}
        onClose={() => {
          setIsCancelExaminationModalOpen(false)
        }}
      />
    </>
  )
}

export default ComingExamDetails
