import React, {FC, ReactNode} from "react"
import { useTranslation } from "react-i18next"
import {Box, Grid, useMediaQuery, useTheme} from "@material-ui/core"
import clsx from "clsx"

import TextLabel from "../../common/textLabel/TextLabel.component"
import ConsultationTypeIcon from "../../consultation/consultationTypeIcon/ConsultationTypeIcon.component"
import AvatarSection from "../comingServiceSections/avatarSection/AvatarSection.component"
import ButtonsSection from "../comingServiceSections/buttonsSection/ButtonsSection.component"
import DetailsSection from "../comingServiceSections/detailsSection/DetailsSection.component"
import ConsultationDetails from "./consultationDetails/ConsultationDetails.component"
import ExamDetails from "./examDetails/ExamDetails.component"
import {
  getPrescriptionDrugNames,
  getSickLeaveEmploymentTypeLabel,
  isStationaryVisitConsultation
} from "../../consultation/Consultation.utils"
import {AddToCalendarEvent} from "../../common/addToCalendar/AddToCalendar.types"
import {ConsultationType,OrderedSickLeaveDetails,StationaryVisitAddress} from "../../consultation/Consultation.types"
import {ActionButtonData,StatusLabel} from "./ComingServiceDetails.types"
import {useComingServiceDetailsStyles} from "./ComingServiceDetails.styles"

interface ComingServiceDetailsProps {
  id: string;
  labelText?: string;
  statusLabel?: StatusLabel | null;
  avatarSrc: URL["href"] | null;
  avatarAlt: string;
  detailsTitle: string;
  consultationType?: ConsultationType;
  consultationLanguage?: string;
  facilityName?: string;
  facilityAddress?: string;
  dateWithDay: string | null;
  hour: string | null;
  cancelButtonOnClick?(): void;
  datePlaceholder?: string | ReactNode;
  actionButtonData?: ActionButtonData | null;
  event: AddToCalendarEvent | null;
  stationaryVisitAddress?: StationaryVisitAddress;
  showCancelButton?: boolean;
  isInterestedInPoz?: boolean;
  isOutdatedVariant?: boolean;
  drugNames?: string[];
  orderedSickLeave?: OrderedSickLeaveDetails;
  isSickLeave: boolean;
  statusNumber: number;
  showAdditionalGoToConsultationBtn?: boolean;
  sickLeaveQuestionnaireFilled?: boolean;
  prescriptionQuestionnaireFilled?: boolean;
  isPrescriptionOnly?: boolean;
  doctorId?: string | null;
}

const ComingServiceDetails: FC<ComingServiceDetailsProps> = ({
  id,
  labelText,
  statusLabel,
  avatarAlt,
  avatarSrc,
  detailsTitle,
  facilityAddress,
  consultationType,
  facilityName,
  dateWithDay,
  hour,
  actionButtonData,
  cancelButtonOnClick,
  datePlaceholder,
  event,
  stationaryVisitAddress,
  consultationLanguage,
  showCancelButton,
  isOutdatedVariant,
  isInterestedInPoz,
  drugNames,
  orderedSickLeave,
  isSickLeave,
  statusNumber,
  showAdditionalGoToConsultationBtn,
  sickLeaveQuestionnaireFilled,
  prescriptionQuestionnaireFilled,
  isPrescriptionOnly,
  doctorId
}) => {
  const classes = useComingServiceDetailsStyles({ isOutdatedVariant })
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const { t } = useTranslation()
  const showStatusBtn = isSickLeave && !sickLeaveQuestionnaireFilled || isPrescriptionOnly && !prescriptionQuestionnaireFilled

  const showDetails = consultationType
    ? (
      <ConsultationDetails
        id={id}
        classes={classes}
        dateWithDay={dateWithDay}
        hour={hour}
        event={event}
        consultationType={consultationType}
        stationaryVisitAddress={stationaryVisitAddress}
        isInterestedInPoz={isInterestedInPoz}
        orderedSickLeave={orderedSickLeave}
        detailsTitle={t("prescription:selectedDrugs")}
        detailsContent={getPrescriptionDrugNames(drugNames)}
      />
    ) :
    (
      <ExamDetails
        datePlaceholder={datePlaceholder}
        facilityName={facilityName}
        facilityAddress={facilityAddress}
        isOutdatedVariant={isOutdatedVariant}
      />
    )

  return (
    <Box
      className={clsx(classes.detailsContainer, classes.detailsBox)}
      color={theme.palette.secondary.contrastText}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.sectionsContainer}
        pt={(!isMdUp && statusLabel) ? 3 : (isMdUp ? 0 : 3)}
      >
        { (statusLabel && !isMdUp && !stationaryVisitAddress) && showStatusBtn && (
          <TextLabel
            bgcolor={statusLabel.color}
            text={statusLabel.text}
            className={classes.warningLabel}
          />
        )}
        <Grid
          className={classes.detailsContainer}
          container
          justifyContent={isSmUp ? "flex-start" : "space-between"}
          alignItems="stretch"
        >
          <Grid container item xs={12} md={4} justifyContent="space-between">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
              pl={1}
            >
              <Box
                display="flex"
                flexDirection="row"
                mt={!isSmUp && consultationType === ConsultationType.PRESCRIPTION_ONLY ? 1.5 : 0}
              >
                <AvatarSection
                  src={avatarSrc}
                  alt={avatarAlt}
                  className={
                    isStationaryVisitConsultation(
                      consultationType, 
                      stationaryVisitAddress
                    ) && !doctorId ? classes.hospitalImage : null
                  }
                />
                <Box mt={isMdUp ? 2 : 0}>
                  <DetailsSection
                    title={detailsTitle}
                    labelText={labelText}
                    classes={classes}
                    consultationType={consultationType}
                    consultationLanguage={consultationLanguage}
                    detailsTitle={isSickLeave ? t("consultation:employmentTypeLabel") : null}
                    detailsContent={isSickLeave ? getSickLeaveEmploymentTypeLabel(orderedSickLeave?.employment_type) : null}
                  />
                </Box>
              </Box>
              {(consultationType && !isMdUp) && (
                <Box>
                  <ConsultationTypeIcon
                    className={clsx(classes.textWhite, classes.consultationTypeIcon)}
                    consultationType={consultationType}
                    isStationaryVisit={isStationaryVisitConsultation(consultationType, stationaryVisitAddress)}
                  />
                </Box>
              )}
            </Box>
          </Grid>

          {isMdUp && (
            <>
              <Grid item md={4}>
                <Box mt={2}>
                  { showDetails }
                </Box>
              </Grid>

              <Grid item md={3} className={classes.changeOrderCol}>
                <Box mt={statusLabel ? 0 : 8}>
                  <ButtonsSection
                    id={id}
                    classes={classes}
                    statusLabel={statusLabel}
                    actionButtonData={actionButtonData}
                    cancelButtonOnClick={cancelButtonOnClick}
                    showCancelButton={showCancelButton}
                    isOutdatedVariant={isOutdatedVariant}
                    isSickLeave={isSickLeave}
                    statusNumber={statusNumber}
                    showAdditionalGoToConsultationBtn={showAdditionalGoToConsultationBtn}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      {!isMdUp && (
        <Grid
          className={classes.detailsServicesContainer}
          container
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
          >
            <Grid item xs={12}>
              <Box textAlign={isMdUp ? "initial" : "center"}>
                { showDetails }
              </Box>
            </Grid>

            <Grid item xs={12}>
              <ButtonsSection
                classes={classes}
                statusLabel={statusLabel}
                actionButtonData={actionButtonData}
                cancelButtonOnClick={cancelButtonOnClick}
                showCancelButton={showCancelButton}
                showAdditionalGoToConsultationBtn={showAdditionalGoToConsultationBtn}
              />
            </Grid>
          </Box>
        </Grid>
      )}
    </Box>
  )
}

export default ComingServiceDetails
