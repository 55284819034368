import React, {FC} from "react"
import {Controller, useFormContext} from "react-hook-form"

import PasswordInput, {PasswordInputProps} from "../passwordInput/PasswordInput.component"

type PasswordInputControllerProps = PasswordInputProps & {
  name: string;
}

const PasswordInputController: FC<PasswordInputControllerProps> = ({ name, ...props }) => {
  const form = useFormContext()

  return (
    <Controller
      name={name}
      control={form.control}
      render={
        (
          {
            field: {
              onChange,
              value
            },
            fieldState: {
              error,
              invalid,
            }
          }) => (
          <PasswordInput
            {...props}
            name={name}
            value={value}
            onChange={onChange}
            isErrorOccurred={invalid}
            errorText={error?.message}
          />
        )
      }
    />
  )
}

export default PasswordInputController
