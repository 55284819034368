import React, {FC} from "react"
import {MenuItem, Select} from "@material-ui/core"
import {SelectInputProps} from "@material-ui/core/Select/SelectInput"

import {dateTestIdMap} from "../../../GenericStepForm.config"
import {usePicker} from "./Picker.utils"
import {Answer, CompletedAnswer} from "../../../GenericStepForm.types"
import {usePickerStyles} from "./Picker.styles"

type PickerProps = {
  answers: Answer[];
  onChange: (completedAnswer: CompletedAnswer[]) => void;
  errors: string[];
  value?: CompletedAnswer[];
}

const Picker: FC<PickerProps> = ({ onChange, value, answers }) => {
  const classes = usePickerStyles()
  const { handleChange } = usePicker(onChange, answers)
  
  return (
    <Select
      data-testid={dateTestIdMap.pickerInput}
      className={classes.select}
      variant="outlined"
      value={value?.[0]?.id ?? ""}
      onChange={handleChange as SelectInputProps["onChange"]}
    >
      {
        answers.map(answer => (
          <MenuItem
            key={answer.id}
            value={answer.id}
          >
            {answer.name}
          </MenuItem>
        ))
      }
    </Select>
  )
}

export default Picker
