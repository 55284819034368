import React from "react"
import { RadioProps } from "@material-ui/core"

import { ReactComponent as RadioChecked } from "../../../assets/svg/radio-checked.svg"
import { ReactComponent as RadioUnchecked } from "../../../assets/svg/radio-unchecked.svg"
import ImageByIntegrationType from "../../../components/imageByIntegrationType/ImageByIntegrationType"

const MuiRadio = {
  color: "primary" as RadioProps["color"],
  icon: (
    <ImageByIntegrationType
      imageSrc={<RadioUnchecked/>}
      imagePath={"radio-unchecked.svg"}
      returnSvg={true}
    />
  ),
  checkedIcon: (
    <ImageByIntegrationType
      imageSrc={<RadioChecked/>}
      imagePath={"radio-checked.svg"}
      returnSvg={true}
    />
  ),
}

export const radio = {
  MuiRadio,
}
