import React, { ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { Tab, Tabs, useTheme } from "@material-ui/core"

import { useActionsListStyles } from "../ActionsList.styles"

export interface TabInfo {
  label: string;
}

interface ActionListTabsProps {
  tabs: TabInfo[];
  value: number;
  handleChange: (event: ChangeEvent<unknown>, newValue: number) => void;
}

const ActionListTabs: React.FC<ActionListTabsProps> = ({ tabs, value, handleChange}) => {
  const { t } = useTranslation()
  const classes = useActionsListStyles()
  const theme = useTheme()

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="fullWidth"
      TabIndicatorProps={{ style: { background: theme.palette.primary.main } }}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={t(tab.label)}
          className={classes.tab}
          id={`simple-tab-${index}`}
        />
      ))}
    </Tabs>
  )
}

export default ActionListTabs
