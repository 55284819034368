import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography,useMediaQuery, useTheme } from "@material-ui/core"

import ButtonTextUnderlined from "../../../../../components/common/buttonTextUnderlined/ButtonTextUnderlined.component"
import HealthPlannerInfoModal from "../healthPlannerInfoModal/HealthPlannerInfoModal.component"
import { useUserPointsTileStyles } from "./UserPointsTile.styles"

interface UserPointsTileProps {
  userPoints: number;
}

const UserPointsTile: FC<UserPointsTileProps> = ({ userPoints }) => {
  const { t } = useTranslation()
  const {
    pointsLabel,
    userPointsCircle,
  } = useUserPointsTileStyles()
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mb={3}
    >
      {isLgUp && (
        <>
          <Typography
            variant="h3"
            component="span"
          >
            {t("healthPlanner:yourPoints")}
          </Typography>
          <Typography
            variant="body1"
            component="span"
            className={pointsLabel}
          >
            {t("healthPlanner:pointsQuantity")}
          </Typography>
        </>
      )}
      <Box
        component="span"
        className={userPointsCircle}
      >
        {userPoints}
      </Box>
      <ButtonTextUnderlined
        color={"primary"}
        onClick={() => setIsOpen(true)}
      >
        {t("healthPlanner:howItWorks")}
      </ButtonTextUnderlined>
      <HealthPlannerInfoModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </Box>
  )
}

export default UserPointsTile