import React, { FC } from "react"
import {
  AccordionProps,
  AccordionSummaryProps,
  Grid,
} from "@material-ui/core"

import SickLeave from "../sickLeave/SickLeave.component"
import {SickLeaveModel} from "../SickLeave.types"
import {useSickLeaveSectionStyles} from "./SickLeaveSection.styles"

type ParentClassKey = "root"|"summaryRoot"|"detailsRoot"

interface SickLeaveSectionProps {
  sickLeave: SickLeaveModel;
  id: string;
  onChange: AccordionProps["onChange"];
  expanded: AccordionProps["expanded"];
  expandIcon: AccordionSummaryProps["expandIcon"];
  parentClasses: Record<ParentClassKey, string>;
  url: string;
}

const SickLeaveSection: FC<SickLeaveSectionProps> = ({sickLeave, id, onChange, expanded, expandIcon, parentClasses, url}) => {
  const classes = useSickLeaveSectionStyles()

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        className={classes.gridItem}
      >
        <SickLeave
          startDate={sickLeave.data.niezdolnoscOd || sickLeave.data.szpitalOd}
          endDate={sickLeave.data.niezdolnoscDo || sickLeave.data.szpitalDo}
          hospitalStartDate={sickLeave.data.szpitalOd}
          hospitalEndDate={sickLeave.data.szpitalDo}
          id={id}
          expanded={expanded}
          expandIcon={expandIcon}
          parentClasses={parentClasses}
          canWalk={sickLeave.data.mozeChodzic}
          onChange={onChange}
          url={url}
        />
      </Grid>
    </Grid>
  )
}

export default SickLeaveSection
