import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useAvatarSectionStyles = makeStyles((theme: Theme) => createStyles({
  image: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginTop: -theme.spacing(1),

    [theme.breakpoints.between("md", 1024)]: {
      marginTop: 0,
      width: theme.spacing(10),
      height: theme.spacing(10),
    },

    [theme.breakpoints.up(1024)]: {
      width: theme.spacing(13),
      height: theme.spacing(13),
    },
  },
}))
