import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { generatePath, Link, useHistory } from "react-router-dom"
import { Box, Button, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"
import { parseISO } from "date-fns"

import { ReactComponent as ActionFallbackIcon } from "../../../../../assets/svg/actionFallbackIcon.svg"
import { ActionType } from "../../../api/queries/getUserActionById"
import ImageByIntegrationType from "../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { useRewardInfo } from "./ActionsListItem.utils"
import { RoutePath } from "../../../../../routes/Routes.types"
import { WordpressTag } from "../../../../../store/wordpress/wordpress.types"
import { useActionsListItemStyles } from "./ActionsListItem.styles"

interface ActionsListItemProps {
  points: number;
  id: string;
  image_url: string | null;
  name: string;
  type: ActionType;
  deadline?: string | null;
  completed_at?: string | null;
  actionSource?: string;
  isDisabled?: boolean;
  tag?: string;
  description?: string;
  createdAt?: string;
  redirectUrl?: string;
  backRoute?: string;
  tags?: WordpressTag[];
}

const ActionsListItem: FC<ActionsListItemProps> = ({
  points = 0,
  id,
  image_url,
  name,
  deadline,
  completed_at,
  actionSource,
  isDisabled,
  type,
  tag,
  description,
  createdAt,
  redirectUrl,
  backRoute,
  tags
}) => {
  const { t, i18n } = useTranslation()
  const classes = useActionsListItemStyles({ isDisabled })
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const rewardInfo = useRewardInfo(type, points)
  const completedAction = completed_at
  const history = useHistory()
  const parsedCompletedDate = completed_at ? parseISO(completed_at) : null
  const parsedDeadline = deadline ? parseISO(deadline) : null
  const parsedCreatedAt = createdAt ? parseISO(createdAt) : null
  const completedDate = completedAction && t("dates:dayMonthYearShort", { date: parsedCompletedDate })
  const deadlineDate = parsedDeadline && t("dates:dayMonthYearShort", { date: parsedDeadline })
  const createdAtDate = parsedCreatedAt && t("dates:fullDate", { date: parsedCreatedAt })
  const isHealthPrevention = type === ActionType.HEALTH_PREVENTION_ARTICLES

  const getDetailsInfo = () => {
    if (createdAtDate) {
      return createdAtDate
    }

    if (isHealthPrevention) {
      return ""
    }

    return completedAction
      ? t("healthPlannerActions:pointsScored", { points })
      : deadlineDate ? t("healthPlannerActions:actionExpiresAt", { date: deadlineDate }) : ""
  }

  const handleRedirect = () => {
    if (isHealthPrevention && redirectUrl && backRoute) {
      history.push({
        pathname: `/${i18n.language}${RoutePath.HEALTH_PREVENTION_IFRAME_VIEW}`,
        state: {
          iframeSrc: redirectUrl,
          redirectRoute: backRoute,
        },
      })
    }
  }

  const getTags = () => {
    if (!tags || tags.length === 0) {
      return (
        <Typography variant="subtitle2" className={classes.label}>
          {tag ?? t(`healthPlanner:actionSources:${actionSource}`)}
        </Typography>
      )
    }

    return (
      <Box className={classes.tagsContainer}>
        {tags.map((item, index) => (
          <Typography key={index} variant="subtitle2" className={`${classes.label} ${classes.tagItem}`}>
            {item.taxonomy}
          </Typography>
        ))}
      </Box>
    )
  }
  
  return (
    <Button
      component={redirectUrl ? "a" : Link}
      to={redirectUrl ? null : generatePath(RoutePath.PREVENTION_ACTION, { actionId: id })}
      className={clsx(classes.listItem, isHealthPrevention && classes.articleListItem)}
      onClick={() => handleRedirect()}
    >
      <Box>
        <Box className={clsx(classes.iconBg, isHealthPrevention && classes.articleImgBg)}>
          {!image_url
            ? <ImageByIntegrationType
              imageSrc={<ActionFallbackIcon/>}
              imagePath={"actionFallbackIcon.svg"}
              returnSvg={true}
            />
            : <Box className={clsx(classes.imageWrapper, isHealthPrevention && classes.articleImageWrapper)}>
              <img
                src={image_url}
                alt="Image"
                className={clsx(classes.image, isHealthPrevention && classes.articleImage)}
              />
            </Box>
          }
          {!completedAction && !isHealthPrevention && (
            <Typography
              variant="subtitle1"
              component="span"
            >
              {points}
            </Typography>
          )}
        </Box>
      </Box>

      <Box className={clsx(classes.infoWrapper, isHealthPrevention ? classes.fullWidth : undefined )}>
        <Typography
          variant={isMdUp ? "body1" : "body2"}
          className={classes.infoTitle}
        >
          {name}
        </Typography>
        
        {isMdUp || isHealthPrevention
          ? (
            <>
              {completedAction ? (
                <>
                  {getTags()}
                  <Typography 
                    variant="body2"
                    className={classes.infoMessage}
                  >
                    {t("healthPlannerActions:actionCompletedAt", { date: completedDate })}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    variant="body2"
                    className={classes.infoSubtitle}
                  >
                    {description ?? rewardInfo}
                  </Typography>
                  {getTags()}
                </>
              )}
            </>
          ) : (
            <>
              {getTags()}
              <Typography variant="body2">
                {completedAction
                  ? t("healthPlannerActions:actionCompletedAt", { date: completedDate })
                  : deadlineDate ? t("healthPlannerActions:actionExpiresAt", { date: deadlineDate }) : ""
                }
              </Typography>
            </>
          )
        }
      </Box>
          
      {(isMdUp || (isMdUp && createdAtDate)) && (
        <Box className={clsx(classes.detailsWrapper, createdAtDate && classes.topDetails)}>
          <Typography variant="body2">
            {getDetailsInfo()}
          </Typography>
        </Box>
      )}
    </Button>
  )
}

export default ActionsListItem