import {getNearbyPharmacies, postOrderByEan} from "../../api/routes"
import {dozHashApi} from "../dozHash/dozHash.api"
import {dozService} from "../store.service"
import {isSuccessResponse} from "./doz.utils"
import {RootState} from "../store.types"
import {DozOrder, NearbyPharmaciesResponse, NearbyPharmacy, RequestedItem, SuccessResponseOrder} from "./doz.types"

import {TELEMEDI_CLIENT_ID} from "./doz.config"

const dozApi = dozService.injectEndpoints({
  endpoints: build => ({
    createOrderByEan: build.mutation<SuccessResponseOrder, DozOrder>({
      async queryFn(
        dozOrder,
        { getState },
        extraOptions,
        baseQuery
      ) {
        const state = (getState() as RootState)
        const dozHash = dozHashApi.endpoints.getDozHash.select()(state)?.data || {}

        const { data } = await baseQuery({
          ...postOrderByEan,
          data: {
            client_id: TELEMEDI_CLIENT_ID,
            ...dozHash,
            payload: {
              ...dozOrder,
            }
          }
        })

        if (isSuccessResponse<SuccessResponseOrder>(data)) {
          return { data }
        }

        return { error: data }
      }
    }),
    getNearbyPharmacies: build.query<
    NearbyPharmacy[],
    {
      requested_item_list: RequestedItem[];
      gps_latitude: number | string;
      gps_longitude: number | string;
    }
    >({
      async queryFn(
        { requested_item_list, ...restParams},
        { getState },
        extraOptions,
        baseQuery
      ) {
        const state = (getState() as RootState)
        const dozHash = dozHashApi.endpoints.getDozHash.select()(state)?.data || {}

        const { data } = await baseQuery({
          ...getNearbyPharmacies,
          data: {
            client_id: TELEMEDI_CLIENT_ID,
            ...dozHash,
            payload: {
              limit: 20,
              max_distance: 3,
              max_pharmacies_with_stock: 5,
              requested_item_list: requested_item_list.filter(requestedItem => requestedItem.quantity > 0),
              ...restParams,
            }
          }
        })

        if (isSuccessResponse<NearbyPharmaciesResponse>(data)) {
          const pharmacies = data.data.pharmacies

          return { data: pharmacies }
        }

        return { error: data }
      }
    })
  })
})

export const { useCreateOrderByEanMutation, useGetNearbyPharmaciesQuery } = dozApi
