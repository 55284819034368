import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"

import { ReactComponent as VoucherCodeToBuyImage } from "../../../assets/svg/voucherCodeToBuy.svg"
import Logotype from "../../common/logotype/Logotype"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import { VoucherTypes } from "../voucherBox/VoucherBox.utils"
import { voucherCodeToBuyTranslation } from "./VoucherCodeToBuy.utils"
import { useVoucherCodeToBuyStyles } from "./VoucherCodeToBuy.styles"

const VoucherCodeToBuyLogo = ({voucherCode, justifyContent, showDescription = true}: {voucherCode: string, justifyContent?: string, showDescription?: boolean}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.only("md"))
  const classes = useVoucherCodeToBuyStyles()
  
  if (voucherCode === VoucherTypes.TYPE_TELEMEDI_GO) {
    return (
      <Box
        display="flex"
        flexDirection={"column"}
        justifyContent={justifyContent ?? "center"}
        alignItems={ "flex-start"}
        width="100%"
        className={classes.telemediGoLogoContainer}
      >
        <Box
          display="flex"
          flexDirection={"row"}
          alignItems={"center"}
        >
          <Logotype
            logoFill={showDescription ? theme.palette.common.white : "#20A869"}
            textFill={showDescription ? theme.palette.common.white : "black"}
            width={"227"}
          />
          <span className={clsx(classes.telemediGoVoucherCode, showDescription ? null : classes.greenColor)}>GO</span>
        </Box>
        {showDescription && (
          <Box display="flex" alignItems="normal" flexDirection="column" className={classes.telemediGoDescriptionBox}>
            <Typography className={clsx(classes.medicalPackageTitle, classes.blueLogoDescription)}
              variant="body1"
              color="primary"
            >
              {t(`finances:voucherCodeToBuy:${voucherCode.toLowerCase()}:customInfo:logoDescription`)}
            </Typography>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection={!isSmUp ? "row": "column"}
      justifyContent={justifyContent ?? "center"}
      alignItems="center"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection={!isSmUp ? "row": "column"}
        alignItems="center"
      >
        <ImageByIntegrationType
          imageSrc={<VoucherCodeToBuyImage/>}
          imagePath={"voucherCodeToBuy.svg"}
          returnSvg={true}
        />
        <Box display="flex" flexDirection="column" ml={2}>
          <Typography className={classes.voucherCode}>
            {voucherCodeToBuyTranslation("title", voucherCode)}
          </Typography>
          <Typography className={classes.medicalPackageTitle}
            variant="body1"
            color="primary"
          >
            {t("finances:voucherCodeToBuy:subscription")}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default VoucherCodeToBuyLogo