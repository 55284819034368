import React, { FC, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { TextField, } from "@material-ui/core"
import { AxiosError } from "axios"

import api from "../../../../api/api"
import { putChangePasswordConfig } from "../../../../api/routes"
import { useAppSelector } from "../../../../hooks/storeHooks"
import useGetFormValidationRules from "../../../../hooks/useGetFormValidationRules"
import { selectUserId } from "../../../../store/session/session.selectors"
import AppDialog from "../../../common/appDialog/AppDialog.component"
import GlobalFormErrorMessage from "../../../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import { handleReactHookFormErrors, redirectToError500Page } from "../../../../utils/handleErrors"
import { UserPasswordChangeFormValues } from "./UserPasswordChange.types"

import { userPasswordChangeValidationSchema } from "./UserPasswordChange.validation"

interface GlobalErrorResponse {
  detail: string; // translated error message
  status: number;
  type: string;
  title: string;
}

interface UserPasswordChangeModalProps {
  open: boolean;
  onClose(): void;
  closeButton?: boolean
  closeIcon?: boolean
}

const UserPasswordChangeModal: FC<UserPasswordChangeModalProps> = ({open, onClose, closeButton, closeIcon}) => {
  const {t} = useTranslation()
  const userId = useAppSelector(selectUserId)
  const {i18n} = useTranslation()

  const form = useForm<UserPasswordChangeFormValues>({
    defaultValues: {
      oldPlainPassword: "",
      plainPassword: "",
      plainPasswordRepeat: "",
    },
    resolver: useGetFormValidationRules(userPasswordChangeValidationSchema),
    mode: "all",
  })

  const [loading, setLoading] = useState<boolean>(false)
  const [globalError, setGlobalError] = useState<AxiosError<GlobalErrorResponse> | null>(null)
  const globalErrorMessage = globalError?.response?.data.detail
    ? t(`errors:${globalError?.response?.data.detail}`)
    : t("errors:unknownError")

  const handleClose = () => {
    form.reset()
    setGlobalError(null)
    onClose()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    setGlobalError(null)
    setLoading(true)
    try {
      await api.request({
        ...putChangePasswordConfig(userId), data: {
          oldPlainPassword: values.oldPlainPassword,
          plainPassword: values.plainPassword,
          lang: i18n.language,
        },
      })
      setLoading(false)
      handleClose()
    } catch (e) {
      console.error(e)
      redirectToError500Page(e)

      // set errors associated with form fields
      if (e.response?.data?.errors) {
        for (const [fieldName, fieldValue] of Object.entries(e.response.data.errors)) {
          const message = (fieldValue as string[]).map(errorMessage => t(`errors:${errorMessage}`)).join("\u000A")
          form.setError(
            fieldName as keyof UserPasswordChangeFormValues,
            {
              type: "manual",
              message,
            })
        }
        handleReactHookFormErrors<UserPasswordChangeFormValues>(e.response.data.errors, form)
      }
      // e/o errors associated with form fields

      // Some errors are not associated with form fields, we call them "global"
      // They stay out of form scope, so we keep them in useState hook
      if (e.response?.data?.detail) {
        setGlobalError(e)
      }
      // e/o "global" errors

      setLoading(false)
    }
  })

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      closeButton={closeButton}
      closeIcon={closeIcon}
      title={t("user:passwordChange")}
      actionButtonText={t("save")}
      actionButtonProps={{
        onClick: handleSubmit,
      }}
    >
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        {globalError && (
          <GlobalFormErrorMessage
            message={globalErrorMessage}
          />
        )}
        <Controller
          name="oldPlainPassword"
          control={form.control}
          render={({
            field: {onChange, value},
            fieldState: {error},
          }) => (
            <TextField
              id="oldPlainPassword"
              type="password"
              value={value}
              label={t("user:oldPlainPassword")}
              placeholder={t("user:oldPlainPassword")}
              onChange={onChange}
              fullWidth
              disabled={loading}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="plainPassword"
          control={form.control}
          render={({
            field: {onChange, value},
            fieldState: {error},
          }) => (
            <TextField
              id="plainPassword"
              type="password"
              value={value}
              label={t("user:plainPassword")}
              placeholder={t("user:plainPassword")}
              onChange={onChange}
              fullWidth
              disabled={loading}
              error={!!error}
              helperText={error?.message || t("user:plainPasswordRequirements")}
            />
          )}
        />

        <Controller
          name="plainPasswordRepeat"
          control={form.control}
          render={({
            field: {onChange, value},
            fieldState: {error},
          }) => (
            <TextField
              id="plainPasswordRepeat"
              type="password"
              value={value}
              label={t("user:plainPasswordRepeat")}
              placeholder={t("user:plainPasswordRepeat")}
              onChange={onChange}
              fullWidth
              disabled={loading}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

      </form>
    </AppDialog>
  )
}

export default UserPasswordChangeModal
