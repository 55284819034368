import React, {FC, useState} from "react"
import { useTranslation } from "react-i18next"
import {Box,Collapse} from "@material-ui/core"

import ButtonText from "../buttonText/ButtonText.component"
import {useCollapseItemsWrapperStyles} from "./CollapseItemsWrapper.styles"

interface CollapseItemsWrapperProps {
  toggleButtonText?: string;
  children: JSX.Element | JSX.Element[];
  initialState?: boolean;
}

const CollapseItemsWrapper: FC<CollapseItemsWrapperProps> = (
  {
    children,
    toggleButtonText,
    initialState= false,
  }) => {
  const classes = useCollapseItemsWrapperStyles()
  const { t } = useTranslation()

  const [isCollapseOpen, setIsCollpaseOpen] = useState(initialState)

  const handleToggle = () => {
    setIsCollpaseOpen((prev) => !prev)
  }

  const seeMore = t("seeMore")

  return (
    <Box className={classes.root}>
      <Collapse
        in={isCollapseOpen}
      >
        { children }
      </Collapse>

      <Box className={classes.buttonSection}>
        <ButtonText
          color={"primary"}
          onClick={handleToggle}
          className={classes.label}
        >
          { isCollapseOpen
            ? t("hide")
            : toggleButtonText || seeMore
          }
        </ButtonText>
      </Box>
    </Box>
  )
}

export default CollapseItemsWrapper
