import React, {FC} from "react"

import {ExaminationType} from "../../../../api/queries/getUserActionById"
import {CodeName} from "../../../../api/queries/getUserActionsQuery"
import DefaultMeasurementLog from "./defaultMeasurementLog/DefaultMeasurementLog.component"
import PhysicalActivityMeasurementLog from "./physicalActivityMeasurementLog/PhysicalActivityMeasurementLog.component"

type MeasurementLogProps = {
  examinationType: ExaminationType;
  description: string;
  name: string;
  codeName: CodeName;
}

const MeasurementLog: FC<MeasurementLogProps> = ({ codeName, name, description, examinationType }) => {
  const isPhysicalActivityAction = codeName === CodeName.PHYSICAL_ACTIVITY_MEASUREMENT_LOG

  return isPhysicalActivityAction
    ? (
      <PhysicalActivityMeasurementLog
        title={name}
      />
    )
    : (
      <DefaultMeasurementLog
        title={name}
        description={description}
        measurementType={examinationType}
      />
    )
}

export default MeasurementLog