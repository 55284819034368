import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import AppDialog from "../../common/appDialog/AppDialog.component"

interface PaymentErrorProps {
  showPaymentErrorDialog: boolean;
  setShowPaymentErrorDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentError: FC<PaymentErrorProps> = ({showPaymentErrorDialog, setShowPaymentErrorDialog}) => {
  const {t} = useTranslation()

  return (
    <AppDialog
      title=""
      open={showPaymentErrorDialog}
      onClose={() => setShowPaymentErrorDialog(false)}
      fullScreen={false}
    >
      <Box
        my={3}
      >
        <Typography
          color="error"
        >
          {t("payments:card:paymentError")}
        </Typography>
      </Box>
    </AppDialog>
  )
}

export default PaymentError