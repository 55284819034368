import React, { FC } from "react"
import { Controller, useFormContext, } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, Grid } from "@material-ui/core"

import CountrySelect from "../../../../commonFormItems/countrySelect/CountrySelect.component"
import { PostalCodeInputController } from "../../../../commonFormItems/postalCodeInput/PostalCodeInput.component"
import TextFieldController from "../../../../commonFormItems/textFieldController/TextFieldController.component"
import {
  disabledFormFieldTooltipInputProps
} from "../../../../informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import { useEsInputPlaceholderClassName } from "../../../../../utils/useEsInputPlaceholderClass"
import { UserDataType } from "../../../../../store/user/user.types"

interface UserParentAddressDataEditFormProps {
  loading: boolean;
  hideForm?: boolean;
  disableFields?: boolean;
  userParentData?: UserDataType["parent"];
}

export const UserParentAddressDataEditForm: FC<UserParentAddressDataEditFormProps> = ({
  loading,
  hideForm = false,
  disableFields = false,
  userParentData
}) => {
  const {t} = useTranslation()
  const inputPlaceholderClassName = useEsInputPlaceholderClassName()
  const form = useFormContext()
  const countrySelected = form.watch("parentCountry")

  return (
    <Box mb={4}>
      {!hideForm && (
        <Grid container>
          <Grid item xs={12}>
            <TextFieldController
              name="parentStreet"
              label={t("user:street")}
              disabled={loading || disableFields}
              InputProps={disabledFormFieldTooltipInputProps(userParentData?.address?.street, loading)}
            />
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextFieldController
                name="parentHouseNumber"
                label={t("user:houseNumber")}
                disabled={loading || disableFields}
                InputProps={disabledFormFieldTooltipInputProps(userParentData?.address?.house_number, loading)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextFieldController
                className={inputPlaceholderClassName}
                name="parentApartmentNumber"
                label={t("user:apartmentNumber")}
                disabled={loading || disableFields}
                InputProps={disabledFormFieldTooltipInputProps(userParentData?.address?.apartment_number, loading)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <PostalCodeInputController
                country={countrySelected}
                name="parentPostalCode"
                label={t("user:postalCode")}
                loading={loading || disableFields}
                shrink={true}
                additionalProps={disabledFormFieldTooltipInputProps(userParentData?.address?.postal_code, loading)}
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <TextFieldController
                name="parentCity"
                label={t("user:city")}
                disabled={loading || disableFields}
                InputProps={disabledFormFieldTooltipInputProps(userParentData?.address?.city, loading)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parentCountry"
              control={form.control}
              render={({
                field: {onChange, value},
                fieldState: {error, invalid}
              }) => (
                <CountrySelect
                  id="parentCountry"
                  label={t("user:country")}
                  placeholder={t("user:country")}
                  fullWidth
                  disabled={loading || disableFields}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={error?.message}
                  InputProps={disabledFormFieldTooltipInputProps(userParentData?.address?.country, loading)}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  )
}