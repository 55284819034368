import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {AppBar, Tab, Tabs, Typography} from "@material-ui/core"

import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import { DocumentationTabItemConfigType } from "../Documentation.type"
import { getDocumentIconType } from "../Documentation.utils"
import {useDocumentationGroupTypeSelectDesktopStyles,} from "./DocumentationGroupTypeSelectDesktop.styles"

interface DocumentationGroupTypeSelectDesktopProps {
  documentationTabItemsConfig: DocumentationTabItemConfigType[];
  selectedTab: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

const DocumentationGroupTypeSelectDesktop: FC<DocumentationGroupTypeSelectDesktopProps> = (
  {
    documentationTabItemsConfig,
    selectedTab,
    handleChange
  }
) => {
  const { t } = useTranslation()
  const classes = useDocumentationGroupTypeSelectDesktopStyles()

  return (
    <AppBar
      position="static"
      color="transparent"
      classes={{
        root: classes.appBar
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        classes={{
          flexContainer: classes.tabsFlexContainer
        }}
      >
        { documentationTabItemsConfig.map(documentationTab => {
          const tabIcon = <IconByIntegrationType iconName={getDocumentIconType(documentationTab.value)} />
          const tabName = t(`documents:${documentationTab.label}`)
          const tabLabel = (
            <Typography variant="h5" component="span">
              { tabIcon } { tabName }
            </Typography>
          )

          return (
            <Tab
              key={documentationTab.value}
              label={tabLabel}
              className={classes.tab}
            />
          )
        })}
      </Tabs>
    </AppBar>
  )
}

export default DocumentationGroupTypeSelectDesktop
