import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useConsultationChangesStyles = makeStyles((theme) => createStyles({
  pageSection: {
    margin: "auto",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    padding: theme.spacing(3, 3),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      padding: theme.spacing(8, 8, 6, 8),
    },
  },
  pageSectionCol: {
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  cancelLabel: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(6),
    },
  },
  cancelBtn: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
  },
  btn: {
    width: "100%",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: "auto",
      marginBottom: 0,
    },
  },
  cancelledImage: {
    maxWidth: "100%",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(8, 0, 4),
    },
  },
  cancelPopupContent: {
    whiteSpace: "pre-line"
  }
}))
