import {RootState} from "../store.types"
import {ExamsState} from "./exams.types"

export const selectComingExamsItems: ((state: RootState) => ExamsState["comingExams"]["items"]) = ({exams}) => exams.comingExams.items

export const selectComingExamsLoading: ((state: RootState) => ExamsState["comingExams"]["loading"]) = ({exams}) => exams.comingExams.loading

export const selectComingExamsTotal: ((state: RootState) => ExamsState["comingExams"]["total"]) = ({exams}) => exams.comingExams.total

export const selectExamsWithResultsItems: ((state: RootState) => ExamsState["examsWithResults"]["items"]) = ({exams}) => exams.examsWithResults.items

export const selectExamsWithResultsLoading: ((state: RootState) => ExamsState["examsWithResults"]["loading"]) = ({exams}) => exams.examsWithResults.loading
