import React, {FC, useState} from "react"
import { useTranslation } from "react-i18next"
import {Box, Button, CircularProgress, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import { useAppSelector } from "../../../hooks/storeHooks"
import { selectPatientDocumentationError, } from "../../../store/patientDocumentation/patientDocumentation.selectors"
import { MedicalDocuments } from "../Documentation.type"
import GlobalFormErrorMessage from "../../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import DocumentationGroupTypeSelectDesktop
  from "../documentationGroupTypeSelectDesktop/DocumentationGroupTypeSelectDesktop.component"
import DocumentationGroupTypeSelectMobile
  from "../documentationGroupTypeSelectMobile/DocumentationGroupTypeSelectMobile.component"
import DocumentationsTable from "../documentationTable/DocumentationsTable.component"
import {ExamsState} from "../../../store/exams/exams.types"
import {useDocumentationPanelBoxStyles} from "./DocumentationPanelBox.styles"

import {getDocumentationItemsConfig} from "./DocumentationPanelBox.config"

interface DocumentationPanelBoxProps {
  documents: MedicalDocuments;
  examsResultsItems: ExamsState["examsWithResults"]["items"];
  loading: boolean;
  hideTabs?: boolean;
}

const DocumentationPanelBox: FC<DocumentationPanelBoxProps> = ({
  documents, 
  examsResultsItems, 
  loading, 
  hideTabs = false
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))
  const globalError = useAppSelector(selectPatientDocumentationError)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedTab, setSelectedTab] = useState(0)
  const classes = useDocumentationPanelBoxStyles()
  const recordsPerPage = 10

  const handleDesktopSelectChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setSelectedTab(newValue)
    setCurrentPage(1)
  }
  const handleMobileSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedTab(event.target.value as number)
    setCurrentPage(1)
  }

  const documentationGroupItemsConfig = getDocumentationItemsConfig(documents, examsResultsItems)
  const totalPages = Math.ceil(documentationGroupItemsConfig[selectedTab].items.length / recordsPerPage)
  const indexOfLastRecord = currentPage * recordsPerPage
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
  const currentRecords = documentationGroupItemsConfig[selectedTab].items.slice(indexOfFirstRecord, indexOfLastRecord)

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber)

  return (
    <Box>
      {globalError && ( // We do not know what is the response data model yet, "detail" is an assumption
        <GlobalFormErrorMessage
          message={t(`errors:${globalError.data.detail}`)}
        />
      )}
      { !hideTabs && (isSmUp
        ? (
          <DocumentationGroupTypeSelectDesktop
            documentationTabItemsConfig={documentationGroupItemsConfig}
            selectedTab={selectedTab}
            handleChange={handleDesktopSelectChange}
          />
        )
        : (
          <DocumentationGroupTypeSelectMobile
            documentationTabItemsConfig={documentationGroupItemsConfig}
            selectedTab={selectedTab}
            handleChange={handleMobileSelectChange}
          />
        ))
      }

      {loading && (
        <Box
          p={2}
          my={4}
          mx={isSmUp ? 0 : 2}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          borderRadius={theme.shape.borderRadius}
          bgcolor={theme.palette.info.light}
        >
          <Typography>
            {t("documents:loading")}
          </Typography>

          <Box ml={2}>
            <CircularProgress
              size={theme.spacing(1.5)}
              thickness={5}
              color="inherit"
            />
          </Box>
        </Box>
      )}
      {documentationGroupItemsConfig.map((documentationGroupItem, idx) => (
        <div
          key={`${documentationGroupItem.value}`}
          hidden={selectedTab !== idx}
        >
          {selectedTab === idx && (
            <Box px={isSmUp ? 0 : 2}>
              {currentRecords.length ? (
                <>
                  <DocumentationsTable files={currentRecords} />
                  <Box className={classes.paginationContainer}>
                    <ul className={classes.pagination}>
                      {Array.from({ length: totalPages }, (_, i) => (
                        <li key={i} className={`${currentPage === i + 1 ? "active" : ""}`}>
                          <Button
                            className={classes.pageButton}
                            variant={currentPage === i + 1 ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => paginate(i + 1)}
                          >
                            {i + 1}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </Box>
                </>
              ) : (
                <Box m={3}>
                  <i>{ t("documents:noFiles") }</i>
                </Box>
              )}
            </Box>
          )}
        </div>
      ))}
    </Box>
  )
}

export default DocumentationPanelBox
