import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import {Box, Button} from "@material-ui/core"
import i18next from "i18next"

import { analytics, LogEventType } from "../../services/analytics"
import ButtonLoader from "../common/buttonLoader/ButtonLoader.component"
import {RoutePath} from "../../routes/Routes.types"
import {useActionButtonsStyles} from "./ActionButtons.styles"

interface ActionButtonsProps {
  isLoading?: boolean;
  actionSource?: string;
}

const ActionButtons: FC<ActionButtonsProps> = ({ isLoading, actionSource }) => {
  const {t} = useTranslation()
  const classes = useActionButtonsStyles()

  const analyticsEventType = () => {
    switch(actionSource) {
      case "OrderMedicinesForm":
        return {
          submit: () => analytics.sendEventWithDefaultParams(LogEventType.DOZ_CONFIRM_ORDER_BUTTON_CLICK),
          cancel: () => analytics.sendEventWithDefaultParams(LogEventType.DOZ_CANCEL_ORDER_BUTTON_CLICK),
        }
      default:
        return {}
    }
  }

  return (
    <Box className={classes.root}>
      <Button
        color="primary"
        type="submit"
        variant="contained"
        className={classes.submitButton}
        startIcon={isLoading && (<ButtonLoader position="prefix"/>)}
        onClick={analyticsEventType()?.submit}
      >
        {t("orderMedicines:submitButton")}
      </Button>
      <Button
        component={Link}
        to={`/${i18next.language}${RoutePath.HOMEPAGE}`}
        color="secondary"
        variant="outlined"
        onClick={analyticsEventType()?.cancel}
      >
        {t("orderMedicines:cancelButton")}
      </Button>
    </Box>
  )
}

export default ActionButtons
