import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Grid, Typography} from "@material-ui/core"
import { format } from "date-fns"

import pharmacy from "../../../assets/images/pharmacy.png"
import visit from "../../../assets/images/visit.png"
import {useAppSelector} from "../../../hooks/storeHooks"
import {dateFnsTranslations} from "../../../i18n/dateFnsTranslations"
import {selectChosenLaboratory} from "../../../store/medicalExamsPoints/medicalExamsPoints.selectors"
import PickupSection from "../../orderMedicinesForm/thankYouPageView/pickupSection/PickupSection.component"
import ActionButtons from "./actionButtons/ActionButtons.component"
import {useSummaryViewStyles} from "./SummaryView.styles"

interface SummaryViewProps {
  executeDate: string | Date;
}

const SummaryView: FC<SummaryViewProps> = ({ executeDate }) => {
  const {t, i18n} = useTranslation()
  const classes = useSummaryViewStyles()
  const chosenLaboratory = useAppSelector(selectChosenLaboratory)
  const formattedDate = format(
    typeof executeDate === "string"
      ? new Date(executeDate)
      : executeDate,
    "eee d MMMM",
    { locale: dateFnsTranslations[i18n.language as keyof typeof dateFnsTranslations] }
  )

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
    >
      <Grid
        container
      >
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.borderRight}
        >
          <Typography
            variant="h2"
            gutterBottom
          >
            {t("referrals:summaryHeader")}
          </Typography>
          <Typography
            variant="body1"
          >
            {t("referrals:alabReminder",{point: chosenLaboratory?.pointType.toUpperCase()})}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.laboratoryColumn}
        >
          <PickupSection
            title={t("consultation:stationaryDetailsView:when")}
            labels={[formattedDate]}
            image={visit}
            imagePath={"visit.png"}
          />
          {
            chosenLaboratory && (
              <PickupSection
                title={t("referrals:nearestLaboratory")}
                labels={
                  [
                    `${chosenLaboratory?.pointType.toUpperCase()}`,
                    `${chosenLaboratory?.street}, ${chosenLaboratory?.postalCode} ${chosenLaboratory?.city}`
                  ]
                }
                image={pharmacy}
                imagePath={"pharmacy.png"}
              />
            )
          }
        </Grid>
      </Grid>
      <ActionButtons/>
    </Box>
  )
}

export default SummaryView
