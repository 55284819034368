import axios from "axios"

import { wordpressApiUrl } from "../../app.config"

const requestWordpress = axios.create({
  baseURL: wordpressApiUrl,
  headers: {
    common: {
      Accept: "application/json",
    },
    post: {
      "Content-Type": "application/json",
    },
  },
})

export default requestWordpress
