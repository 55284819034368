import {gql} from "@apollo/client"

export interface WeightMeasurementsModel {
  createdAt: string;
  id: string;
  value: number;
}
    
export interface WeightMeasurementsData {
  weightMeasurements: WeightMeasurementsModel[]
}

export const GET_USER_WEIGHT_MEASUREMENTS = gql`
  query getUserWeightMeasurements {
    weightMeasurements: examinations_weight_answers(order_by: {created_at: desc}) {
      createdAt: created_at
      id
      weight: value
    }
  }
`
