import {useState} from "react"
import {useTranslation} from "react-i18next"

import {analytics, LogEventType} from "../../../../../../services/analytics"
import {
  Answer,
  CompletedAnswer,
  CompletedQuestion,
  InputType,
  Question,
  Validation,
  ValidationType
} from "../GenericStepForm.types"

const multipleFieldType = [InputType.NUMBER, InputType.NUMBERS, InputType.TEXT_FIELD, InputType.TEXT_FIELDS]

export const useQuestionManager = (
  questionData: Question,
  initialCompletedQuestion: CompletedQuestion | undefined,
  onSaveAnswer: (completedQuestion: CompletedQuestion) => void,
  onScrollToQuestion: VoidFunction,
  goToPreviousQuestion: VoidFunction,
  sendAnalytics?: boolean
) => {
  const [answers, setAnswers] = useState<CompletedAnswer[]>(
    initialCompletedQuestion?.answers ?? []
  )
  const [relatedQuestions, setRelatedQuestions] = useState<Question[]>(
    getInitialRelatedQuestions(questionData, initialCompletedQuestion)
  )
  const [relatedQuestionIndex, setRelatedQuestionIndex] = useState<number>(
    (initialCompletedQuestion?.related_questions?.length || 1) - 1
  )
  const [completedRelatedQuestions, setCompletedRelatedQuestions] = useState<CompletedQuestion[]>(
    initialCompletedQuestion?.related_questions ?? []
  )
  const [errors, setErrors] = useState<string[]>([])
  const validateAnswer = useValidateAnswer()

  const handleBack = () => {
    onScrollToQuestion()
    goToPreviousQuestion()
  }

  const handleChange = (completedAnswer: CompletedAnswer[]) => {
    setAnswers(completedAnswer)
  }

  const handleSendAnalyticsEvent = (id: string, title: string, answers: CompletedAnswer[]|Answer[]) => {
    if (sendAnalytics) {
      analytics.sendEventWithDefaultParams(LogEventType.CONSULTATION_SURVEY_ANSWER, {
        question_id: id,
        question_title: title,
        answer: JSON.stringify(answers)
      })
    }
  }

  const handleSaveAnswer = () => {
    const fieldsTypesToValidate = [InputType.NUMBER, InputType.TEXT_FIELD, InputType.DATE, InputType.TEXTAREA]
    let isErrorOccurred = false

    if (
      (fieldsTypesToValidate.includes(questionData.type))
        && !questionData.answers.length
        && questionData.validation
    ) {
      const errorMessage = validateAnswer(questionData.validation, answers[0])
      isErrorOccurred = !!errorMessage

      if (errorMessage) {
        setErrors([errorMessage])
      }
    } else if (multipleFieldType.includes(questionData.type)) {
      const errorMessages: string[] = []

      answers.forEach((answer, index) => {
        const errorMessage = validateAnswer(
          questionData.answers[index]?.validation ?? [],
          answer
        )

        if (errorMessage) {
          isErrorOccurred = true
          errorMessages.push(errorMessage)
        }
      })

      setErrors(errorMessages)
    } else {
      setErrors([])
    }

    if (isErrorOccurred) {
      return
    }

    onScrollToQuestion()

    const relatedQuestionIdsSet = createRelatedQuestionsIdsSet(answers)

    if (relatedQuestionIdsSet.size > 0 && !(relatedQuestionIndex === relatedQuestions.length - 1)) {
      const relatedQuestions = questionData.related_questions?.filter(
        relatedQuestion => relatedQuestionIdsSet.has(relatedQuestion.id)
      )
      
      if (relatedQuestions) {
        handleSendAnalyticsEvent(questionData?.id, questionData?.title, questionData?.answers)
        setRelatedQuestions(relatedQuestions)
        setRelatedQuestionIndex(0)
        
        return
      }
    }

    const completedQuestion: CompletedQuestion = {
      ...questionData,
      answers,
      related_questions: []
    }

    if (completedRelatedQuestions.length > 0) {
      completedQuestion.related_questions = completedRelatedQuestions
    } else {
      delete completedQuestion.related_questions
    }

    handleSendAnalyticsEvent(completedQuestion?.id, completedQuestion?.title, completedQuestion?.answers)

    onSaveAnswer(completedQuestion)
  }

  const handleSaveRelatedAnswer = (completedQuestion: CompletedQuestion) => {
    onScrollToQuestion()
    const completedRelationQuestionsToAdd = [...completedRelatedQuestions]
    completedRelationQuestionsToAdd[relatedQuestionIndex] = completedQuestion
    const isLastRelatedQuestion = relatedQuestionIndex === relatedQuestions.length - 1

    if (isLastRelatedQuestion) {
      onSaveAnswer({
        ...questionData,
        answers,
        related_questions: completedRelationQuestionsToAdd
      })
    } else {
      setCompletedRelatedQuestions(completedRelationQuestionsToAdd)
      setRelatedQuestionIndex(previousIndex => previousIndex + 1)
    }
  }

  const handleBackToPreviousRelatedQuestion = () => {
    onScrollToQuestion()
    if (!relatedQuestionIndex) {
      setRelatedQuestions([])
    } else {
      setRelatedQuestionIndex(previousIndex => previousIndex - 1)
    }
  }

  const handleSkip = () => {
    onSaveAnswer({
      ...questionData,
      answers: [],
      related_questions: []
    })
  }

  const isNextButtonDisabled = (() => {
    if (!answers.length || (questionData.type === InputType.TEXT_FIELD || questionData.type === InputType.TEXTAREA) && answers?.[0]?.value === "") {
      return true
    }

    //TODO: https://telemedico.atlassian.net/browse/LESS-1615
    const isAllFieldFulfilled =
      questionData.type === InputType.TEXT_FIELD
      && answers.length > 1
      && answers.some(answer => !answer.value)
    if (isAllFieldFulfilled) {
      return true
    }

    return false
  })()

  return {
    errors,
    answers,
    relatedQuestion: relatedQuestions[relatedQuestionIndex],
    completedRelatedQuestion: completedRelatedQuestions[relatedQuestionIndex],
    isNextButtonDisabled,
    handleSaveRelatedAnswer,
    handleBackToPreviousRelatedQuestion,
    handleSaveAnswer,
    handleChange,
    handleBack,
    handleSkip,
  }
}

const useValidateAnswer = () => {
  const {t} = useTranslation()

  return (
    validations: Validation[],
    completedAnswer: CompletedAnswer
  ) => {
    for (const validation of validations) {
      switch (validation.type) {
        case ValidationType.MIN: {
          if (Number(completedAnswer.value) < Number(validation.value)) {
            return t("errors:minNumber", { number: validation.value })
          }
          break
        }
        case ValidationType.MAX: {
          if (Number(completedAnswer.value) > Number(validation.value)) {
            return t("errors:maxNumber", { number: validation.value })
          }
          break
        }
        case ValidationType.MIN_CHAR_COUNT: {
          if (completedAnswer.value.length < Number(validation.value)) {
            return t("errors:minChars", { minChars: validation.value })
          }
          break
        }
        case ValidationType.MIN_DATE: {
          if (new Date(completedAnswer.value) < new Date(validation.value)) {
            return t("errors:This value is not valid.")
          }
          break
        }
      }
    }
  }
}

const getInitialRelatedQuestions = (
  questionData: Question,
  initialCompletedQuestion?: CompletedQuestion
) => {
  const relatedQuestionsIds = createRelatedQuestionsIdsSet(initialCompletedQuestion?.answers ?? [])

  return questionData.related_questions?.filter(relatedQuestion => relatedQuestionsIds.has(relatedQuestion.id)) ?? []
}

const createRelatedQuestionsIdsSet = (answers: CompletedAnswer[]) =>
  answers.reduce(
    (previousRelatedQuestionsIdsSet, currentAnswer) => {
      const relatedQuestionIds = currentAnswer?.related_ids ?? []

      for (const relatedQuestionId of relatedQuestionIds) {
        previousRelatedQuestionsIdsSet.add(relatedQuestionId)
      }

      return previousRelatedQuestionsIdsSet
    },
    new Set<string>()
  )
