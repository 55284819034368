import { useMutation } from "@apollo/client"

import { SendChatMessageResponseData, SendChatMessageVariables } from "./sendChatMessage.types"
import { MutationHookOptions } from "@apollo/client/react/types/types"

import { SEND_CHAT_MESSAGE } from "./sendChatMessage.mutation"

export const useSendChatMessageMutation = (options?: MutationHookOptions<SendChatMessageResponseData, SendChatMessageVariables>) => {
  return useMutation<SendChatMessageResponseData, SendChatMessageVariables>(
    SEND_CHAT_MESSAGE,
    {
      context: { clientName: "HealthPlanner" },
      ...options
    }
  )
}