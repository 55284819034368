import React, { FC, useEffect, useState } from "react"
import {useTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import {Box, Button, Hidden, Typography} from "@material-ui/core"
import { AxiosError } from "axios"
import i18next from "i18next"

import api from "../../../api/api"
import { getMedicalDocumentFileConfig } from "../../../api/routes"
import useFileDownload from "../../../hooks/useFileDownload"
import { analytics, LogEventType } from "../../../services/analytics"
import GlobalErrorSnackbar from "../../commonFormItems/globalErrorSnackbar/GlobalErrorSnackbar"
import ButtonLoader from "../../common/buttonLoader/ButtonLoader.component"
import {RoutePath} from "../../../routes/Routes.types"
import { DozItem, PrescriptionCartErrorResponse } from "../Prescription.types"
import {usePrescriptionFooterStyles} from "./PrescriptionFooter.styles"

interface PrescriptionFooterProps {
  consultationId: string;
  id: string;
  date: string;
  validTo: string;
  expanded?: boolean;
  dozItems: DozItem[];
  isPrescriptionValid: boolean;
}

const PrescriptionFooter: FC<PrescriptionFooterProps> = (
  {
    consultationId,
    id,
    date,
    validTo,
    expanded = false,
    dozItems,
    isPrescriptionValid,
  }
) => {
  const {t} = useTranslation()
  const classes = usePrescriptionFooterStyles()

  const fileName = `${date} e-recepta`
  const getEPrescriptionPdf = () => api.request<Blob>({...getMedicalDocumentFileConfig(id)})
  const {download, downloading, downloadError} = useFileDownload(getEPrescriptionPdf)
  const [globalError, setGlobalError] = useState<AxiosError<PrescriptionCartErrorResponse> | null>(null)

  const price = dozItems.reduce((total, item) => {
    return total + (item.quantity * item.price)
  }, 0)

  const handleDownloadPdfClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    (event.target as HTMLInputElement).blur()
    analytics.sendEventWithDefaultParams(LogEventType.DOZ_DOWNLOAD_PRESCRIPTION_BUTTON_CLICK)
    download(fileName)
  }

  useEffect(()=> {
    return () => {
      setGlobalError(null)
    }
  }, [])

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="baseline"
      whiteSpace="nowrap"
    >
      {
        expanded && (
          <Hidden smDown>
            <Box ml={expanded ? 0 : 1.5} mr={4}>
              <Typography color="textSecondary">{`${t("prescription:validUntil")} ${validTo}`}</Typography>
            </Box>
          </Hidden>
        )
      }

      <Box className={classes.buttonsWrapper}>
        <Typography
          variant="h4"
          component="p"
          color={price === 0 ? "textSecondary" : "textPrimary"}
        >
          { `${price.toFixed(2)}`.replace(".", ",") } zł
        </Typography>
        <Button
          component={Link}
          to={
            `/${i18next.language}${RoutePath.ORDER_MEDICINES.replace(":consultationId", consultationId)}`
          }
          disabled={!dozItems.length || !isPrescriptionValid}
          variant="contained"
          color="primary"
          className={classes.actionButton}
          onClick={() => analytics.sendEventWithDefaultParams(LogEventType.DOZ_ORDER_BUTTON_CLICK)}
        >
          {t("prescription:orderAndPickUp")}
        </Button>

        <Button
          disabled={downloading}
          variant="outlined"
          color="secondary"
          className={classes.actionButton}
          onClick={handleDownloadPdfClick}
          endIcon={downloading && <ButtonLoader/>}
        >
          {t("prescription:download")}
        </Button>

        <GlobalErrorSnackbar isError={!!downloadError || !!globalError}/>
      </Box>
    </Box>
  )
}

export default PrescriptionFooter
