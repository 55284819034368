import React, { FC } from "react"
import {Box, Typography} from "@material-ui/core"

import {usePreventionDiseaseDetailsStyles} from "../../PreventionDiseaseDetails.styles"

type DescriptionProps = {
  title: string;
  description: string;
}

const Description: FC<DescriptionProps> = ({ title, description }) => {
  const classes = usePreventionDiseaseDetailsStyles()

  return (
    <Box className={classes.container}>
      <Typography 
        className={classes.title}
        variant="h3"
      >
        {title}
      </Typography>
      <Typography variant="body1">
        {description}
      </Typography>
    </Box>
  )
}

export default Description
