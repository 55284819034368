import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useSafeDataInfoLabel = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
  }
}))
