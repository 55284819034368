import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "@apollo/client"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"

import { ADD_PULSE,AddPulseResponse, AddPulseVariables } from "../../api/mutations/addPulse"
import {ExaminationType} from "../../api/queries/getUserActionById"
import { GET_USER_PULSE_MEASUREMENTS, PulseMeasurementsData } from "../../api/queries/getUserPulseMeasurements"
import DiaryMeasurement from "../../components/diaryMeasurement/DiaryMeasurement.component"
import { addPulseSchema } from "../../components/diaryMeasurement/DiaryMeasurement.validation"
import PageWrapper from "../../components/pageWrapper/PageWrapper.component"

const genericInputData = [
  {
    name: "pulse",
    unit: "bpm"
  },
]

const DiaryPulse: FC = () => {
  const { t } = useTranslation()

  const [getPulseMeasurements, {
    data: { pulseMeasurements = [] } = {},
    error: pulseMeasurementsError,
    loading: pulseMeasurementsLoading,
  }] = useLazyQuery<PulseMeasurementsData>(GET_USER_PULSE_MEASUREMENTS, {
    context: {clientName: "HealthPlanner"},
  })

  const [addPulse, {
    data: addPulseData,
    loading: addPulseLoading,
    error: addPulseError
  }] = useMutation<AddPulseResponse, AddPulseVariables>(ADD_PULSE, {
    context: { clientName: "HealthPlanner" }
  })

  const addMeasurement = (value: MaterialUiPickersDate, input: string[]) => {
    addPulse({
      variables: {
        createdAt: value,
        heartRate: input[0]
      }
    })
  }

  return (
    <PageWrapper
      title={t("healthPlanner:diaryTitles:pulse")}
    >
      <DiaryMeasurement
        examinationType={ExaminationType.PULSE}
        diaryTitle={t("healthPlanner:diaryTitles:pulse")}
        genericInputData={genericInputData}
        inputTitle={t("healthPlanner:addPulse")}
        validationSchema={addPulseSchema}
        getRecords={getPulseMeasurements}
        addMeasurement={addMeasurement}
        data={pulseMeasurements && pulseMeasurements}
        dataLoading={pulseMeasurementsLoading}
        dataFetchingError={pulseMeasurementsError}
        addMeasurementData={addPulseData}
        addMeasurementLoading={addPulseLoading}
        addMeasurementError={addPulseError}
      />
    </PageWrapper>
  )
}

export default DiaryPulse
