import {gql} from "@apollo/client"

export const GET_MEDICINES = gql`
  query getMedicinesItemsByMedicalProductId($productId: Int!) {
    medicine(where: { medical_product_id: { _eq: $productId } }) {
      id
      power
      productName: product_name
      size
      unit
      activeSubstances: active_substances
      ean
    }
  }
`
