import { createStyles, makeStyles } from "@material-ui/core"

import { Theme } from "@material-ui/core/styles"

export const useDiaryMeasurementTileStyles = makeStyles((theme: Theme) => createStyles({
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "382px",
      background: theme.palette.background.paper,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "382px",
      height: "auto",
      marginTop: 0,
      marginBottom: 0,
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  tilesWrapper: {
    display: "flex",
    gap: theme.spacing(1),
    overflowX: "scroll",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.background.appBar,
    },
    "&::-webkit-scrollbar": {
      width: "1px",
      height: "1px",
      borderRadius: "15px"
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("sm")]: {
      flexWrap: "wrap",
      overflow: "unset",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "auto",
      marginBottom: "auto"
    },
  },
}))