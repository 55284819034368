import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit"

import { PatientDocumentationState } from "./patientDocumentation.types"

const initialState: PatientDocumentationState = {
  items: null,
  error: null,
  loading: false,
}

export const patientDocumentationSlice = createSlice({
  name: "patientDocumentation",
  initialState,
  reducers: {
    getPatientDocumentation: (state: PatientDocumentationState) => {
      state.loading = true
      state.error = null
      return
    },
    setPatientDocumentation: (state: PatientDocumentationState, action: PayloadAction<PatientDocumentationState["items"]>) => {
      state.items = action.payload
      state.loading = false
      return
    },
    setPatientDocumentationError: (state: PatientDocumentationState, action: PayloadAction<PatientDocumentationState["error"]>) => {
      state.error = action.payload
      state.loading = false
      return
    },
    clearPatientDocumentation: () => {
      return {...initialState}
    }
  },
})

export const {
  getPatientDocumentation,
  setPatientDocumentation,
  setPatientDocumentationError,
  clearPatientDocumentation,
} = patientDocumentationSlice.actions

export default patientDocumentationSlice.reducer as Reducer<typeof initialState>