import {gql} from "@apollo/client"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"

export type AddPulseResponse = {
  addPulse: {
    id: string;
  }
}

export type AddPulseVariables = {
  heartRate: string;
  createdAt: MaterialUiPickersDate;
}

export const ADD_PULSE = gql`
  mutation AddPulse(
    $heartRate: numeric!,
    $createdAt: timestamptz!
  ) {
    addPulse: insert_examinations_pulse_answers_one(object: {
      pulse: $heartRate,
      created_at: $createdAt
    }
  ) {
      id
    }
  }
`
