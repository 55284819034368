import React, { FC, useMemo } from "react"

import doctorAvatarPlaceholder1 from "../../../../assets/images/doctorAvatarPlaceholder1.png"
import doctorAvatarPlaceholder2 from "../../../../assets/images/doctorAvatarPlaceholder2.png"
import useImageByIntegrationType from "../../../imageByIntegrationType/useImageByIntegrationType"
import ImageAvatar, { ImageAvatarProps } from "../ImageAvatar.component"

interface DoctorImageAvatarProps extends ImageAvatarProps {
  pseudorandomBoolean?: boolean
}

const DoctorImageAvatar: FC<DoctorImageAvatarProps> = (
  {
    src,
    alt,
    className,
    pseudorandomBoolean,
  },
) => {

  const placeHolderImageName =
        pseudorandomBoolean
          ? "doctorAvatarPlaceholder1.png"
          : "doctorAvatarPlaceholder2.png"

  const placeholderImage = useMemo(() => {
    return pseudorandomBoolean 
      ? doctorAvatarPlaceholder1
      : doctorAvatarPlaceholder2
  }, [pseudorandomBoolean])

  const {imageSrcState} = useImageByIntegrationType(placeHolderImageName)

  return (
    <ImageAvatar
      src={src || imageSrcState || placeholderImage}
      alt={alt}
      className={className}
    />
  )
}

export default DoctorImageAvatar
