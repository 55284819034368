import {videoShadows} from "../../../themes/shadows"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useConsultationVideoStyles = makeStyles((theme: Theme) => createStyles({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  mitigatedBackdrop: {
    pointerEvents: "none",
    background: "transparent",
  },
  videosBox: {
    position: "absolute",
    width: "100%",
    maxWidth: "960px",
    maxHeight: "720px",
    height: "100vh",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },
  mitigatedVideoBox: {
    position: "fixed",
    top: theme.spacing(2),
    left: theme.spacing(2),
    pointerEvents: "auto",
    // standard video 4/3 ratio
    width: "400px",
    height: "300px",
    maxWidth: `calc(100% - ${theme.spacing(4)}px)`, // minus margin-x
    [theme.breakpoints.up("md")]: {
      top: "unset",
      bottom: theme.spacing(4),
      left: theme.spacing(4),
    },
  },
  videosWrapper: {
    flex: 1,
    position: "relative",
    height: "100%",
  },
  doctorVideo: {
    width: "100%",
    height: "100%",
    "& > div": {
      borderRadius: theme.shape.borderRadius,
      boxShadow: videoShadows.shadow1,
    },
    "& video": {
      width: "100%",
      objectFit: "contain !important",
      [theme.breakpoints.up("md")]: {
        objectFit: "cover !important",
      },
    }
  },
  mitigatedDoctorVideo: {
    "& video": {
      objectFit: "cover !important",
    }
  },
  patientVideo: {
    maxWidth: "240px",
    maxHeight: "180px",
    width: `calc(100% - ${theme.spacing(4)}px)`, // minus margin-x
    height: "100%",
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    "& > div": {
      borderRadius: theme.shape.borderRadius,
      boxShadow: videoShadows.shadow1,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "unset",
      width: "20%",
      height: "20%",
      right: theme.spacing(8),
      top: theme.spacing(8),
    },
  },
  loaderBox: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    boxShadow: videoShadows.shadow1,
    background: theme.palette.common.black,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}))
