import {PublisherProperties, SubscriberProperties} from "@opentok/client"

export const publisherProperties: PublisherProperties = {
  audioFallbackEnabled: false,
  showControls: false,
  insertMode: "append",
  mirror: true,
  disableAudioProcessing: false,
  width: "100%",
  height: "100%",
}

export const subsriberProperties: SubscriberProperties = {
  subscribeToVideo: true,
  subscribeToAudio: true,
  showControls: false,
  width: "100%",
  height: "100%",
  insertMode: "append",
  // preferredResolution TODO,
  // preferredFrameRate TODO,
}