import { MouseEventHandler } from "react"
import {DatePickerProps} from "@material-ui/pickers/DatePicker/DatePicker"
import {add, sub} from "date-fns"

import {isAfterDate} from "../../../utils/dateFns/isAfterDate"
import {isBeforeDate} from "../../../utils/dateFns/isBeforeDate"

const useDatePicker = (
  value: string,
  onChange: DatePickerProps["onChange"],
  shouldDisableDate: DatePickerProps["shouldDisableDate"],
  disablePast?: boolean,
  disableFuture?: boolean
): {
  isPastDateDisabled: boolean,
  isFutureDateDisabled: boolean,
  handleIncrement: MouseEventHandler<HTMLButtonElement>,
  handleDecrement: MouseEventHandler<HTMLButtonElement>
} => {
  const today = new Date()
  const date = value ? new Date(value) : today
  const decrementedValue = sub(date, { days: 1 })
  const incrementedValue = add(date, { days: 1 })

  const isPastDateDisabled =
    shouldDisableDate?.(decrementedValue) 
    || (
      disablePast 
        ? isBeforeDate(decrementedValue, today)
        : false
    ) 
  const isFutureDateDisabled =
    shouldDisableDate?.(incrementedValue)
    || (
      disableFuture
        ? isAfterDate(incrementedValue, today)
        : false
    )

  const handleDecrement: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    onChange(decrementedValue)
  }
  const handleIncrement: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    onChange(incrementedValue)
  }

  return {
    isPastDateDisabled,
    isFutureDateDisabled,
    handleIncrement,
    handleDecrement
  }
}

export default useDatePicker
