import {createStyles, makeStyles} from "@material-ui/core"

export const useDoctorImageStyles = makeStyles(() => createStyles({
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minWidth: 250,
    maxWidth: 330,
  },
  image: {
    width: "100%",
  }
}))
