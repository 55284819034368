import React, {FC, useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import {Box, ClickAwayListener, Popover, Typography} from "@material-ui/core"

import {showVpozPopupOnStart, showVpozPopupOnStartDisabled} from "../../../app.config"
import {useAppSelector} from "../../../hooks/storeHooks"
import useIsB2BClinic from "../../../hooks/useIsB2BClinic"
import {selectClinicSettings} from "../../../store/clinic/clinicSettings.selectors"
import {
  selectComingConsultations,
  selectLatestConsultations
} from "../../../store/consultations/consultations.selectors"
import {selectLogInRedirectUrl} from "../../../store/logIn/logIn.selectors"
import {
  selectPozDeclarationActiveStatus,
  selectUserPozDeclarationActiveStatus
} from "../../../store/user/user.selectors"
import Button from "../../button/Button.component"
import CloseButton from "../closeButton/CloseButton.component"
import NfzStatusOffInfo from "./nfzStatusInfo/NfzStatusOffInfo.component"
import NfzStatusOnInfo from "./nfzStatusInfo/NfzStatusOnInfo.component"
import NfzStatusSwitch from "./nfzStatusSwitch/NfzStatusSwitch.component"
import { RoutePath } from "../../../routes/Routes.types"
import {useNfzStatusStyles} from "./NfzStatus.styles"

interface NfzStatusProps {}

const getLocalStorageItem = (itemName: string) => localStorage.getItem(itemName)

const NfzStatus: FC<NfzStatusProps> = () => {
  const {t,i18n} = useTranslation()
  const isPozDeclarationActiveAfterElectronicSignature = useSelector(selectPozDeclarationActiveStatus)
  const isPozDeclarationActive = useSelector(selectUserPozDeclarationActiveStatus) || isPozDeclarationActiveAfterElectronicSignature
  const { B2BClinic } = useIsB2BClinic()
  const clinicSettings = useSelector(selectClinicSettings)
  const redirectData = useSelector(selectLogInRedirectUrl)
  const comingConsultations = useAppSelector(selectComingConsultations)
  const latestConsultations = useAppSelector(selectLatestConsultations)
  const isHomePage = window.location.pathname === `/${i18n.language}`
  const classes = useNfzStatusStyles()
  const vPOZB2b = clinicSettings?.pozformB2BActive
  const showPopupOnStart =
      (!isPozDeclarationActive && B2BClinic && vPOZB2b)
      && !redirectData
      && getLocalStorageItem(showVpozPopupOnStart) === "true"
      && !getLocalStorageItem(showVpozPopupOnStartDisabled) // only for b2b clinics
  const disablePopupVisibilityOnStart = !redirectData && (!isHomePage || isHomePage && (!clinicSettings.hasBookConsultationAbility || latestConsultations.length || comingConsultations.length))

  const [open, setOpen] = useState<boolean>(showPopupOnStart)
  const [hidePopupBtn, setHidePopupBtn] = useState<boolean>(showPopupOnStart)
  const handleOpen = () => { setOpen(true) }
  const handleClose = () => {
    setOpen(false)
    setHidePopupBtn(false)
    localStorage.removeItem(showVpozPopupOnStart)
  }

  const title = isPozDeclarationActive
    ? t("insuranceActiveTitle")
    : t("insuranceInactiveTitle")

  useEffect(() => {
    setOpen(showPopupOnStart)

    if (disablePopupVisibilityOnStart) {
      localStorage.removeItem(showVpozPopupOnStart)
    }
  }, [])

  useEffect(() => {
    if (!open) {
      setOpen(showPopupOnStart)
    }

    if (disablePopupVisibilityOnStart) {
      localStorage.removeItem(showVpozPopupOnStart)
    }
  }, [redirectData, latestConsultations, comingConsultations])

  if (isPozDeclarationActive === null || (B2BClinic && !vPOZB2b)) {
    return null
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <NfzStatusSwitch
          isOn={isPozDeclarationActive!}
          clickable={true}
          onClick={handleOpen}
        />

        <Popover
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            paper: classes.popoverPaper
          }}
        >
          <CloseButton
            onClick={handleClose}
            topCSS={0}
            rightCSS={0}
          />

          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>

          { isPozDeclarationActive
            ? <NfzStatusOnInfo/>
            : <NfzStatusOffInfo 
              handleClosePopover={handleClose} 
              vPOZB2b={vPOZB2b}
              hidePopupBtn={hidePopupBtn}
            />
          }

          {isPozDeclarationActive && (
            <Box mt={2}>
              <Button
                component={NavLink}
                to={RoutePath.NFZ_RESIGN}
              >
                Zrezygnuj
              </Button>
            </Box>
          )}
        </Popover>
      </div>
    </ClickAwayListener>
  )
}

export default NfzStatus
