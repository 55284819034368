import { getHealthPreventionPatientMood, postHealthPreventionPatientMood } from "../../api/routes"
import { v4Service } from "../store.service"
import {
  HealthPreventionGetMood,
  HealthPreventionGetMoodPayload,
  HealthPreventionGetMoodResponse,
  HealthPreventionPostMood,
  HealthPreventionPostMoodPayload,
  HealthPreventionPostMoodResponse
} from "./healthPrevention.types"

export const healthPreventionApi = v4Service.injectEndpoints({
  endpoints: build => ({
    getHealthPreventionPatientMood: build.query<HealthPreventionGetMood, HealthPreventionGetMoodPayload>({
      query: ({patientId = "", type = 0}) => ({
        ...getHealthPreventionPatientMood(patientId, type),
      }),
      transformResponse: (response: HealthPreventionGetMoodResponse) => ({
        latestMood: response.latest_mood,
        averageMood: response.average_mood
      })
    }),
    postHealthPreventionPatientMood: build.mutation<HealthPreventionPostMood, HealthPreventionPostMoodPayload>({
      query: ({patientId = "", type = 0, mood = 1}) => ({
        ...postHealthPreventionPatientMood(patientId, type),
        data: {
          mood: mood,
          type: type
        }
      }),
      transformResponse: (response: HealthPreventionPostMoodResponse) => ({
        id: response.id,
      })
    })
  })
})

export const {
  useGetHealthPreventionPatientMoodQuery,
  usePostHealthPreventionPatientMoodMutation
} = healthPreventionApi
