import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"

import theme from "../../../themes/default/theme"
import { typography } from "../../../themes/default/typography"

export const useMedicalSurveyAccordionCheckboxStyles = makeStyles((theme: Theme) => createStyles({
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    background: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      height: "auto",
      padding: theme.spacing(5),
    },
  },
  textarea: {
    minHeight: 230,
    maxHeight: 230,
    borderRadius: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    ...typography.body1
  },
  inputText: {
    minHeight: 10,
    maxHeight: 10,
    padding: "5px",
    borderRadius: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    ...typography.body1
  },
}))

export const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing(3),
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      marginBottom: theme.spacing(3),
    },
  },
  expanded: {},
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    padding: 0,
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary)

export const AccordionDetails = withStyles({
  root: {
    padding: 0,
  },
})(MuiAccordionDetails)