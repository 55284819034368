import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import { useSelector } from "react-redux"
import { Button } from "@material-ui/core"
import Box from "@material-ui/core/Box"

import useGoToConsultationPage from "../../../../hooks/useGoToConsultationPage"
import { analytics, LogEventType } from "../../../../services/analytics"
import { selectUser } from "../../../../store/user/user.selectors"
import IconByIntegrationType from "../../../iconByIntegrationType/IconByIntegrationType"
import useMakeConsultationMenuListItems from "../common/nestedMenu/makeConsultationNestedMenu/MakeConsultationMenu.config"
import NestedMenu from "../common/nestedMenu/NestedMenu.component"
import MenuItemsComponent from "./MenuItems.component"
import {checkFeatureAvailability} from "../../../../store/clinic/clinicSettings.utils"

const MenuSidebarComponent: FC = () => {
  const {t} = useTranslation()
  const userData = useSelector(selectUser)
  const {goToMakeConsultationPage} = useGoToConsultationPage()
  const hideConsultationBooking = checkFeatureAvailability().hideConsultationBooking
  const { makeList } = useMakeConsultationMenuListItems()
  const renderMenuButton = (goToConsultationPage?: boolean) => {
    const handleClick = () => {
      analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_MENU_ORDER_CLICK)
      return goToConsultationPage ? goToMakeConsultationPage() : undefined
    }

    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<IconByIntegrationType iconName={"icon-calendar-blank"} />}
        fullWidth
        disabled={!userData}
        onClick={() => handleClick()}
      >
        { t("setDate") }
      </Button>
    )
  }

  return (
    <Box mx={4}>
      <Box mb={5}>
        <NestedMenu 
          trigger={renderMenuButton()}
          menuListItemsArray={makeList()}
          isEnabled={!hideConsultationBooking}
        />
      </Box>

      <MenuItemsComponent disabled={!userData}/>
    </Box>
  )
}

export default MenuSidebarComponent
