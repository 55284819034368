import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useCheckboxControllerStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    radioBtn: {
      "&.Mui-checked svg circle:last-child": {
        fill: theme.palette.primary.dark,
      }
    }
  })
)
