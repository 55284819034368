import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import OpenReplay from "@openreplay/tracker"
import trackerAssist from "@openreplay/tracker-assist"
import trackerGraphQL from "@openreplay/tracker-graphql"
import Cookies from "js-cookie"

import {openReplayProjectKey} from "../app.config"
import {selectClinicSettings} from "../store/clinic/clinicSettings.selectors"
import {selectUserId} from "../store/session/session.selectors"
import {selectRandomSessionId} from "../store/user/user.selectors"

import {useAppSelector} from "./storeHooks"

const OPEN_REPLAY_INGEST_POINT = "https://openreplay.telemedi.com/ingest"
export const OPEN_REPLAY_ANONYMOUS_KEY = "ANONYMOUS-"
const OPEN_REPLAY_SESSION_HASH_KEY = "sessionHash"

export const tracker = new OpenReplay({
  projectKey: openReplayProjectKey,
  ingestPoint: OPEN_REPLAY_INGEST_POINT,
  captureIFrames: false,
  network: {
    capturePayload: true,
    failuresOnly: false,
    ignoreHeaders: false,
    sessionTokenHeader: false,
    sanitizer: data => {
      const body = data?.request?.body ? JSON.parse(data.request.body.toString()) : null
      const hidePasswordString = "*****"

      if (data?.url?.includes("login-password-grant")) {
        body.password = hidePasswordString
        data.request.body = JSON.stringify(body)
      } else if (data?.url?.includes("change-password")) {
        body.oldPlainPassword = hidePasswordString
        body.plainPassword = hidePasswordString
        data.request.body = JSON.stringify(body)
      }

      return data
    },
  }
}) //openReplay plugin

export const recordGraphQL = tracker.use(trackerGraphQL())

const useOpenReplayTracker = (): {
  trackerStart(): void,
} => {
  const {t} = useTranslation()
  const [sessionHash] = useState(Cookies.get(OPEN_REPLAY_SESSION_HASH_KEY))
  const randomSessionId = useSelector(selectRandomSessionId)
  const userId = useSelector(selectUserId)
  const clinicSettings = useAppSelector(selectClinicSettings)

  useEffect(() => {
    if (userId) {
      tracker.setUserID(userId)
    }
  }, [userId])

  useEffect(() => {
    if (randomSessionId) {
      tracker.setUserID(`${OPEN_REPLAY_ANONYMOUS_KEY}${randomSessionId}`)
    }
  }, [randomSessionId])

  const trackerStart = () => {
    tracker.use(trackerAssist({
      controlConfirm: {
        text: t("defaultTranslations:remoteControlLabel"),
        confirmBtn: t("chatConsultation:accept"),
        declineBtn: t("chatConsultation:reject")
      },
      callConfirm: {
        text: t("defaultTranslations:remoteCallLabel"),
        confirmBtn: t("defaultTranslations:answer"),
        declineBtn: t("chatConsultation:reject"),
      }
    })) // openReplay tracker

    tracker.start({
      sessionHash: sessionHash ?? undefined,
      userID: userId || "",
      metadata: {
        clinicName: clinicSettings?.name,
        clinicUrl: window.location.origin,
        clinicId: clinicSettings?.frontendSettings?.clinicId
      }
    })

    Cookies.remove(OPEN_REPLAY_SESSION_HASH_KEY)
  }

  return {
    trackerStart
  }
}

export default useOpenReplayTracker
