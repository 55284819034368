import React, {FC, useMemo} from "react"
import { useTranslation } from "react-i18next"
import {NavLink} from "react-router-dom"
import {Link,List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core"
import clsx from "clsx"

import { useAppSelector } from "../../../../hooks/storeHooks"
import useIsB2BClinic from "../../../../hooks/useIsB2BClinic"
import { selectClinicSettings } from "../../../../store/clinic/clinicSettings.selectors"
import IconByIntegrationType from "../../../iconByIntegrationType/IconByIntegrationType"
import useMakeConsultationMenuListItems
  from "../common/nestedMenu/makeConsultationNestedMenu/MakeConsultationMenu.config"
import NestedMenu from "../common/nestedMenu/NestedMenu.component"
import {renderMenuElements, useMenuElements} from "../common/MainMenu.utils"
import {MenuItemType} from "../MenuItems.types"
import {useMenuItemsStyles} from "./MenuItems.styles"

interface MenuItemsProps {
  disabled: boolean;
}

const MenuItems: FC<MenuItemsProps> = ({disabled}) => {
  const { t, i18n } = useTranslation()
  const classes = useMenuItemsStyles()
  const { B2BClinic } = useIsB2BClinic()
  const {renderMenuElements} = useMenuElements(B2BClinic, i18n.language)

  const navItemContent = (iconClass: string, text: string, skipIconOverwrite?: boolean) => (
    <>
      <ListItemIcon className={classes.icon}>
        <IconByIntegrationType iconName={iconClass} skip={skipIconOverwrite}/>
      </ListItemIcon>

      <ListItemText
        primary={t(text)}
        primaryTypographyProps={{variant: "button", component: "span"}}
      />
    </>
  )

  return (
    <div>
      <List component="nav" aria-label={t("mainMenu")}>
        { renderMenuElements().map((listItem: MenuItemType) => {
          
          if (listItem.nestedItems) {
            const nestedMenuTrigger = <ListItem
              button
              disabled={disabled}
              className={clsx(classes.item, disabled && classes.disabled)}
              onClick={listItem.onClickHandler ?? undefined}
            >
              {navItemContent(listItem.iconClass, listItem.text, listItem.skipIconOverwrite)}
            </ListItem>  
              
            return (
              <NestedMenu
                key={listItem.text}
                trigger={nestedMenuTrigger}
                menuListItemsArray={listItem.nestedItems}
              />
            )
          }
            
          if (listItem.isExternalLink) {
            return (
              <ListItem
                key={listItem.text}
                component={Link}
                href={listItem.navigateTo}
                disabled={disabled}
                className={clsx(classes.item, disabled && classes.disabled)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={listItem.onClickHandler ?? undefined}
              >
                {navItemContent(listItem.iconClass, listItem.text, listItem.skipIconOverwrite)}
              </ListItem>
            )
          }
            
          return (
            <ListItem
              key={listItem.text}
              component={NavLink}
              to={listItem.navigateTo}
              disabled={disabled}
              exact
              className={clsx(classes.item, disabled && classes.disabled)}
              activeClassName="Mui-selected"
              onClick={listItem.onClickHandler ?? undefined}
            >
              {navItemContent(listItem.iconClass, listItem.text, listItem.skipIconOverwrite)}
            </ListItem>
          )
        })}
          
      </List>
    </div>
  )
}

export default MenuItems
