import { DocumentationTabItemConfigType } from "./Documentation.type"

export const getDocumentIconType = (medicalFileType: DocumentationTabItemConfigType["value"], fallbackIcon?: string): string => {
  switch(medicalFileType) {
    case "medicine_eprescriptions":
      return "icon-pill"
    case "prescriptions":
      return "icon-prescription"
    case "examsResults":
      return "icon-flask"
    case "user_files":
      return "icon-file"
    case "recommendations":
      return "icon-clipboard"
    case "e_referrals":
      return  "icon-sickLeave"
    case "trans_prescription":
      return "icon-pill"
    case "consultation_e_referrals":
    default:
      return fallbackIcon || ""
  }
}
