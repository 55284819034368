import {gql} from "@apollo/client"

export const SEND_CHAT_MESSAGE = gql`
  mutation sendMessage(
    $message: String!,
    $senderId: uuid!,
    $threadId: uuid!
  ) {
    insertChatMessagesOne: insert_chat_messages_one(
      object: {
        body: $message,
        sender_id: $senderId,
        thread_id: $threadId
      }) {
        id
      }
    }
`