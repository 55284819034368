import React, {FC} from "react"
import {useTranslation} from "react-i18next"
// import {useParams} from "react-router"
import {Link} from "react-router-dom"
import {Box, Button} from "@material-ui/core"
import i18next from "i18next"

// import api from "../../../../api/api"
// import {getMedicalDocumentFileConfig} from "../../../../api/routes"
// import useFileDownload from "../../../../hooks/useFileDownload"
// import {
//   useGetConsultationDocumentationQuery
// } from "../../../../store/consultationDocumentation/consultationDocumentation.api"
// import GlobalErrorSnackbar from "../../../commonFormItems/globalErrorSnackbar/GlobalErrorSnackbar"
// import ButtonLoader from "../../../common/buttonLoader/ButtonLoader.component"
import {RoutePath} from "../../../../routes/Routes.types"
import {useActionButtonsStyles} from "../../../actionButtons/ActionButtons.styles"

const ActionButtons: FC = () => {
  const classes = useActionButtonsStyles()
  const {t} = useTranslation()
  // LESS-2289 - temporary solution - [FE] Usunięcie przycisku "Pobierz" po umówieniu badań labo z poziomu pakietu
  // const {consultationId} = useParams<{ consultationId: string }>()
  // const { data } = useGetConsultationDocumentationQuery(consultationId)
  // const referralId = data?.prescriptions.items[0]?.id
  // const fileName = t("referrals:referral")
  // const getReferralPdf = () => api.request<Blob>({...getMedicalDocumentFileConfig(referralId || "")})
  // const {download, downloading, downloadError} = useFileDownload(getReferralPdf)
  // const handleDownloadPdfClick = () => {
  //   download(fileName)
  // }
  // e/o LESS-2289 - temporary solution - [FE] Usunięcie przycisku "Pobierz" po umówieniu badań labo z poziomu pakietu

  return (
    <Box
      className={classes.root}
    >
      <Button
        color="primary"
        variant="contained"
        className={classes.submitButton}
        component={Link}
        to={`/${i18next.language}${RoutePath.HOMEPAGE}`}
      >
        {t("referrals:backToDashboard")}
      </Button>
      {/* LESS-2289 - temporary solution - [FE] Usunięcie przycisku "Pobierz" po umówieniu badań labo z poziomu pakietu */}
      {/* <Button
        color="primary"
        variant="outlined"
        startIcon={downloading && (<ButtonLoader position="prefix"/>)}
        onClick={handleDownloadPdfClick}
      >
        {t("prescription:download")}
      </Button>
      <GlobalErrorSnackbar isError={!!downloadError}/> */}
      {/* e/o LESS-2289 - temporary solution - [FE] Usunięcie przycisku "Pobierz" po umówieniu badań labo z poziomu pakietu */}
    </Box>
  )
}

export default ActionButtons
