import {useHistory, useParams} from "react-router"
import {useQuery} from "@apollo/client"

import {
  Action,
  ActionType,
  GET_USER_ACTION_BY_ID,
  GetUserActionsVariables, UserAction,
  UserActionResponse
} from "../../api/queries/getUserActionById"
import {RoutePath} from "../../../../routes/Routes.types"

export const usePreventionAction = () => {
  const { actionId } = useParams<GetUserActionsVariables>()
  const { push } = useHistory()

  const { data, loading } = useQuery<UserActionResponse, GetUserActionsVariables>(
    GET_USER_ACTION_BY_ID,
    {
      onCompleted: (data) => {
        if (data?.userActions.length === 0) {
          push(RoutePath.PREVENTION)
        }
      },
      variables: { actionId },
      context: { clientName: "HealthPlanner" },
    })

  return {
    action: transformResponse(data?.userActions),
    isLoading: loading
  }
}

const transformResponse = (userActions?: [UserAction] | []): Action | undefined => {
  const userAction = userActions?.[0]

  if (!userAction) {
    return undefined
  }

  const action = { ...userAction.action }

  if (action.type === ActionType.E_PRESCRIPTION && userAction?.ePrescriptions) {
    action.ePrescriptions = userAction.ePrescriptions
  }

  return action
}