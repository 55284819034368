import React, {FC} from "react"
import {Box, BoxProps, Typography, useTheme} from "@material-ui/core"
import clsx from "clsx"

import {useAppSelector} from "../../../hooks/storeHooks"
import {selectClinicSettings} from "../../../store/clinic/clinicSettings.selectors"
import getColorByIntegration from "../../../utils/getColorByIntegration"
import {useTextLabelStyles} from "./TextLabel.styles"

interface TextLabelProps extends BoxProps {
  text?: string;
  textOverflow?: boolean
}

const TextLabel: FC<TextLabelProps> = ({text, textOverflow, ...boxProps}) => {
  const theme = useTheme()
  const classes = useTextLabelStyles()
  const isLgUp = theme.breakpoints.up("lg")
  const { clinicAllianzSettings: {
    ewkaVerificationEnabled,
    onyxVerificationEnabled
  } } = useAppSelector(selectClinicSettings)

  return (
    <Box
      bgcolor={ getColorByIntegration(ewkaVerificationEnabled || onyxVerificationEnabled, 1) ?? theme.palette.info.main}
      {...boxProps}
      className={clsx(classes.root, boxProps.className)}
    >
      <Typography
        variant={isLgUp ? "body2" : "subtitle2"}
        className={clsx(
          textOverflow && classes.overflow,
          classes.text
        )}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default TextLabel
