import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useCollapseItemsWrapperStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: "relative",
    "&::after": {
      content: "''",
      display: "block",
      position: "absolute",
      bottom: theme.spacing(2),
      left: 0,
      right: 0,
      height: theme.spacing(8),
      backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75.71%)",
      opacity: 0,
      transition: "opacity 300ms",
    },
  },
  buttonSection: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    }
  },
  label: {
    textDecoration: "underline",
  }
}))
