import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router"
import {Box, Typography} from "@material-ui/core"
import {CancelTokenSource} from "axios"
import {differenceInMilliseconds, formatISO, isBefore, parseISO, subHours, subMinutes} from "date-fns"

import api, {ApiInstance} from "../../../api/api"
import {getConsultationsConfig} from "../../../api/routes"
import {useAppSelector} from "../../../hooks/storeHooks"
import {selectUserRegulationsModalOpen} from "../../../store/user/user.selectors"
import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import AppDialog from "../../common/appDialog/AppDialog.component"
import {CLINIC_NAME} from "../../informationPanel/stationaryDetailsPanel/StationaryDetailsPanel.component"
import {
  COMING_CONSULTATION_START_VISIT_AT_HOURS_EARLIER,
  isStationaryVisitConsultation
} from "../Consultation.utils"
import {
  ConsultationListItemModel,
  ConsultationListParamsSort,
  ConsultationListParamsStatus, ConsultationType,
  GetConsultationsListParams,
} from "../Consultation.types"

interface ComingNearestConsultationPopupProps {}

const ComingNearestConsultationPopup: FC<ComingNearestConsultationPopupProps> = () => {
  const {t, i18n} = useTranslation()
  const {push} = useHistory()
  const startVisitAt = formatISO(subHours(new Date(), COMING_CONSULTATION_START_VISIT_AT_HOURS_EARLIER))

  const MAX_MINUTES_BEFORE_REMINDER_SHOW = 15
  const MAX_MINUTES_BEFORE_STATIONARY_CONSULTATION_REMINDER_SHOW = 30
  const [nearestComingConsultation, setNearestComingConsultation] = useState<ConsultationListItemModel>()
  const userRegulationsModalOpen = useAppSelector(selectUserRegulationsModalOpen)

  /* get nearest consultation to show notification-popup 15minutes before */
  const getNearestComingConsultation = async (cancelToken?: CancelTokenSource["token"]) => {
    try {
      const params: GetConsultationsListParams = {
        status: ConsultationListParamsStatus.PLANNED,
        sort: ConsultationListParamsSort.ASC,
        startVisitAt,
        lang: i18n.language
      }

      const {data} = await api.request<{items: ConsultationListItemModel[], total: number}>({
        ...getConsultationsConfig,
        params,
        cancelToken,
      })

      if (data.items.length) {
        const sortedConsultations = data.items
          .filter(consultation => isBefore(new Date(), parseISO(consultation.visit_at)))
          .sort((consultationA, consultationB) => {
            return isBefore(parseISO(consultationA.visit_at), parseISO(consultationB.visit_at))? -1 : 1
          })
        setNearestComingConsultation(sortedConsultations[0])
      }
    } catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
    }
  }
  /* e/o get nearest consultation to show notification-popup 15minutes before*/

  const consultationVisitDate = nearestComingConsultation ? parseISO(nearestComingConsultation.visit_at) : null
  const isStationaryConsultationType = nearestComingConsultation && isStationaryVisitConsultation(nearestComingConsultation.consultationType, nearestComingConsultation.stationaryVisitAddress)
  const [minutesBefore, setMinutesBefore] = useState<string>("")
  const [goToConsultationText, setGoToConsultationText] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  const disableReminder = (nearestComingConsultation?.consultationType === ConsultationType.PRESCRIPTION_ONLY || nearestComingConsultation?.consultationType === ConsultationType.SICK_LEAVE || userRegulationsModalOpen) || isStationaryConsultationType
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!nearestComingConsultation) return

    if (nearestComingConsultation.isPatientMedicalSurveyRequired && !nearestComingConsultation.isMedicalSurveyFilled){
      setGoToConsultationText("prescription:completeSurvey")
    } else {
      setGoToConsultationText("consultation:comingNearestConsultationPopup:goToConsultation")
    }

  }, [nearestComingConsultation])

  const getSurveyRedirectUrl = (consultationType: number, id: string): string => {
    switch(consultationType) {
      case ConsultationType.PRESCRIPTION_ONLY:
        return `${i18n.language}/medical-survey/${id}`
      case ConsultationType.SICK_LEAVE:
        return `${i18n.language}/sick-leave-survey/${id}`
      default:
        return `${i18n.language}/medical-survey/${id}}`
    }
  }

  const goToConsultation = () => {
    if (!nearestComingConsultation) return

    if (nearestComingConsultation.isPatientMedicalSurveyRequired && !nearestComingConsultation.isMedicalSurveyFilled) {
      push(getSurveyRedirectUrl(nearestComingConsultation.consultationType, nearestComingConsultation.id))
    } else {
      push(`/${i18n.language}/consultation/${nearestComingConsultation.id}`)
    }
  }

  const getMinutesBeforeConsultation = () => {
    const datesToCompare = [new Date(), consultationVisitDate]
    setMinutesBefore(t("dates:distanceDates", { date: datesToCompare }))
  }

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()

    if (!nearestComingConsultation) {
      getNearestComingConsultation(requestSource.token)

    } else {
      const nowDate = new Date()
      const showPopupMaxTime = subMinutes(
        consultationVisitDate!,
        isStationaryConsultationType ? MAX_MINUTES_BEFORE_STATIONARY_CONSULTATION_REMINDER_SHOW : MAX_MINUTES_BEFORE_REMINDER_SHOW
      )

      if (isBefore(showPopupMaxTime, nowDate)) { // is 15 or less minutes to consultation start
        getMinutesBeforeConsultation()
        setOpen(true)
      } else { // is more than 15 minutes to consultation start
        const milisecondsBeforePopupShow = differenceInMilliseconds(showPopupMaxTime, nowDate)
        setTimeout(() => {
          getMinutesBeforeConsultation()
          setOpen(true)
        }, milisecondsBeforePopupShow)
      }
    }

    return () => {
      requestSource.cancel("Request interrupted by page change")
    }
  }, [nearestComingConsultation])

  if (!nearestComingConsultation || disableReminder) {
    return null
  }

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t(`consultation:comingNearest${ isStationaryConsultationType ? "Stationary" : "" }ConsultationPopup:title`)}
      actionButtonText={t(isStationaryConsultationType ? "consultation:comingNearestStationaryConsultationPopup:details" : goToConsultationText)}
      actionButtonProps={{
        onClick: goToConsultation,
        startIcon: <IconByIntegrationType iconName={"icon-caret-right"}/>,
      }}
      closeButton={false}
    >
      <Box mb={1}>
        <Box mb={1}>
          <Typography className="break-spaces">
            { isStationaryConsultationType
              ? t("consultation:comingNearestStationaryConsultationPopup:description", {
                minutesBefore: minutesBefore,
                specialistName: nearestComingConsultation.doctorName,
                clinicWithAddress: CLINIC_NAME
              })
              : t("consultation:comingNearestConsultationPopup:description", {
                minutesBefore: minutesBefore,
                specialistName: nearestComingConsultation.doctorName
              })
            }
          </Typography>
        </Box>

        { !isStationaryConsultationType &&
              <Typography className="break-spaces">
                { t("consultation:comingNearestConsultationPopup:consultationPanelDescription")}
              </Typography>
        }
      </Box>
    </AppDialog>
  )
}

export default ComingNearestConsultationPopup
