import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useConsultationTermSelectionStyles = makeStyles<Theme>((theme) =>
  createStyles({
    item: {
      padding: theme.spacing(2, 2),
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      "&:last-child": {
        borderBottom: 0,
      }
    },
    hour: {
      alignSelf: "center",
    },
    channelOptions: {
      width: "100%"
    },
    daySelection: {
      paddingBottom: theme.spacing(4),
    },
  })
)
