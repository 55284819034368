import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

export const useCookieInfoStyles = makeStyles((theme: Theme) => {
  const THULIUM_ICON_WIDTH = theme.spacing(10)

  return createStyles({
    root: {
      display: "flex",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: 0,
      zIndex: theme.zIndex.appBar,
      padding: theme.spacing(2, 0),
      [theme.breakpoints.up("sm")]: {
        paddingRight: THULIUM_ICON_WIDTH
      },
      [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      [theme.breakpoints.up("xl")]: {
        paddingRight: 0,
      },
    },
    spacer: {
      ".defaultLayout &": {
        width: "268px"
      }
    },
    wrapper: {
      maxWidth: theme.breakpoints.values.lg,
      margin: theme.spacing(0, "auto"),
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row"
      }
    },
    descriptionWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(0, 2),
      width: `calc(100% - ${THULIUM_ICON_WIDTH}px)`,
      [theme.breakpoints.up("sm")]: {
        width: "100%"
      }
    },
    iconWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(4)
      }
    },
    buttonsWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0, 4)
      }
    },
    privacyPolicy: {
      marginRight: theme.spacing(2),
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        whiteSpace: "nowrap"
      }
    }
  })
})
