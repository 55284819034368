import React, { FC } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@material-ui/core"
import dayjs from "dayjs"

import { useTranslateOptions } from "../PatientsRequirementsForm/SickLeaveSection/SickLeaveSection.hooks"
import CheckboxController from "../../../../../components/CheckboxController/CheckboxController.component"
import NavigationButtons from "../../NavigationButtons/NavigationButtons.component"
import FormSection from "../PatientsRequirementsForm/FormSection/FormSection.component"
import AddressTemplate
  from "../PatientsRequirementsForm/SickLeaveSection/SickLeaveAddress/AddressTemplate/AddressTemplate.component"
import PrescriptionSummary from "./PrescriptionSummary/PrescriptionSummary.component"
import SickLeaveSummary from "./SickLeaveSummary/SickLeaveSummary.component"
import { PatientsRequirementsStepData, SummaryStepData } from "../../MedicalQuestionnaireStepperForm.types"

type Props = {
  patientsRequirements?: PatientsRequirementsStepData
  onSubmit: (data: SummaryStepData) => void
  isLoading: boolean
  visitReason?: {
    isPrescriptionNeeded: boolean;
    isSickLeaveNeeded: boolean;
    isReferralNeeded: boolean;
  }
}

const Summary: FC<Props> = ({ patientsRequirements, onSubmit, isLoading, visitReason }) => {
  const { t } = useTranslation()
  const formMethods = useForm<{ isAgreementAccepted: boolean; }>()
  const isAgreementAccepted = formMethods.watch("isAgreementAccepted")

  return (
    <FormProvider {...formMethods}>
      <Box
        component='form'
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <Box
          mb={2}
        >
          <Typography variant='h3'>
            {t("exam:summary")}
          </Typography>
        </Box>
        {
          visitReason?.isPrescriptionNeeded && patientsRequirements?.prescription &&
            <PrescriptionSummary patientsRequirements={patientsRequirements}/>
        }
        {
          visitReason?.isSickLeaveNeeded && patientsRequirements?.sickLeave &&
            <SickLeaveSummary patientsRequirements={patientsRequirements}/>
        }
        {
          visitReason?.isReferralNeeded && patientsRequirements?.referral && (
            <FormSection
              title={<Typography gutterBottom variant='h3' color='primary'>{t("consultationNestedMenu:referralHeader")}</Typography>}
            >
              {
                patientsRequirements.referral.isDoctorConsultationRequired ? (
                  <Typography
                    variant='body1'
                    color='textSecondary'
                  >
                    {t("medicalQuestionnaire:doctorWillChooseTheSickLeaveInfo")}
                  </Typography>
                ) : (
                  <Box
                    component='ol'
                    pl={3}
                  >
                    {
                      patientsRequirements.referral.exams.map((exam) => (
                        <li key={exam.id}>
                          <Typography
                            variant='body1'
                            color='textSecondary'
                          >
                            {exam.name}
                          </Typography>
                        </li>
                      ))
                    }
                  </Box>
                )
              }
            </FormSection>
          )
        }
        <CheckboxController
          name='isAgreementAccepted'
          label={t("medicalQuestionnaire:aggrementLabel")}
        />
        <NavigationButtons
          isLoading={isLoading}
          isNextButtonDisabled={!isAgreementAccepted}
          visitReason={visitReason}
        />
      </Box>
    </FormProvider>
  )
}

export default Summary
