const FONT_CONFIG_FILENAME = "config.json"

export const getFontFamilyByClinicId = async (clinicId:string)=>{
  if( !clinicId ){
    return null
  }
    
  try{
    // Fetch the font name from configuration file
    const configPath = `${clinicId}/${FONT_CONFIG_FILENAME}`
    const configFile = await import(
      /* webpackMode: "eager" */
      "../assets/fonts/"+configPath
    )
    const wasConfigFound = configFile && Object.keys(configFile).length >= 1
    if( !wasConfigFound ){
      return null
    }
    const fontName = configFile.name
    // // Dynamically import the font CSS using the font name from config
    const fontCssPath = `${clinicId}/css/${fontName}.css`
    const fontCssFile = await import(
      /* webpackMode: "eager" */
      "../assets/fonts/"+fontCssPath
    )
    const wasFontCssFound = fontCssFile && Object.keys(fontCssFile).length >= 1
    if( !wasFontCssFound ){
      return null
    }

    return fontName
  } catch (error) {
    //console.error(`Failed to load font for clinic with id ${clinicId}:`, error)
    return null
  }
}
