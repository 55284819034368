import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import {Box,Grid, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import SelfcareRecommendationsSection from "./generalRecommendation/GeneralRecommendation.component"
import MedicationsList from "./medicationsList/MedicationsList.component"
import RecommendationSummary from "./recommendationSummary/RecommendationSummary.component"
import { PrescriptionModel } from "../prescription/Prescription.types"
import {RecommendationModel} from "./Recommendation.types"
import {useRecommentationsStyles} from "./Recommendation.styles"

interface RecommendationProps {
  recommendations: RecommendationModel[];
  prescriptions: PrescriptionModel[];
}
const Recommendation: FC<RecommendationProps> = ({recommendations, prescriptions}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isSmUp = useMediaQuery((theme.breakpoints.up("sm")))

  const classes = useRecommentationsStyles()

  return (
    <Grid
      container
      spacing={3}
      className={classes.root}
    >
      <Grid item xs={12} sm={12}>
        <Typography
          variant={isSmUp ? "h3": "h5"}
          component="h3"
          style={{
            display: isSmUp ? "inline" : "block"
          }}
        >
          {t("recommendations:generalRecommendationTitle")}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {prescriptions.length > 0 && (
              <MedicationsList prescriptions={prescriptions}/>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            { recommendations.map(recommendation => (
              <Box key={recommendation.id}>
                <SelfcareRecommendationsSection
                  general={recommendation.general}
                />

                <RecommendationSummary
                  summary={recommendation.summary}
                />
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Recommendation
