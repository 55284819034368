import React, {ForwardedRef, forwardRef} from "react"
import {useTranslation} from "react-i18next"
import {FormControl, FormHelperText, IconButton} from "@material-ui/core"
import {DatePicker as MuiDatePicker} from "@material-ui/pickers"
import {DatePickerProps as MuiDatePickerProps} from "@material-ui/pickers/DatePicker/DatePicker"
import clsx from "clsx"

import {ReactComponent as LeftArrow} from "../../../assets/svg/leftArrow.svg"
import {ReactComponent as RightArrow} from "../../../assets/svg/rightArrow.svg"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import useDatePicker from "./DatePicker.utils"
import {useDatePickerStyles} from "./DatePicker.styles"

export interface DatePickerProps extends MuiDatePickerProps {
  value: string;
  errorMessage?: string;
}

const DatePicker = (
  {
    value,
    onChange,
    shouldDisableDate,
    disablePast,
    disableFuture,
    className,
    errorMessage,
    ...props
  }: DatePickerProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const classes = useDatePickerStyles({ isErrorOccurred: !!errorMessage })
  const {t} = useTranslation()
  const {
    isPastDateDisabled,
    isFutureDateDisabled,
    handleDecrement,
    handleIncrement
  } = useDatePicker(
    value,
    onChange,
    shouldDisableDate,
    disablePast,
    disableFuture
  )

  return (
    <FormControl
      error={!!errorMessage}
      fullWidth
      className={classes.formControl}
    >
      <MuiDatePicker
        inputRef={ref}
        fullWidth
        cancelLabel={t("cancel")}
        okLabel={t("login:choose")}
        format="eee d MMMM"
        InputProps={{
          startAdornment: (
            <IconButton
              className={classes.iconButton}
              size='small'
              disabled={isPastDateDisabled}
              onClick={handleDecrement}
            >
              <ImageByIntegrationType
                imageSrc={<LeftArrow/>}
                imagePath={"leftArrow.svg"}
                returnSvg={true}
              />
            </IconButton>
          ),
          endAdornment: (
            <IconButton
              className={classes.iconButton}
              size='small'
              disabled={isFutureDateDisabled}
              onClick={handleIncrement}
            >
              <ImageByIntegrationType
                imageSrc={<RightArrow/>}
                imagePath={"rightArrow.svg"}
                returnSvg={true}
              />
            </IconButton>
          ),
        }}
        value={value}
        onChange={onChange}
        shouldDisableDate={shouldDisableDate}
        disablePast={disablePast}
        disableFuture={disableFuture}
        className={clsx(className, classes.root)}
        {...props}
      />
      <FormHelperText error={!!errorMessage} className={classes.helperText}>
        {errorMessage}
      </FormHelperText>
    </FormControl>

  )
}

export default forwardRef(DatePicker)
