import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

interface Props {
  showFormErrors: boolean;
}

export const useConsultationPaymentPageStyles = makeStyles((theme) => createStyles({
  paymentProvider: {
    marginLeft: theme.spacing(1),
    position: "relative",
    top: -theme.spacing(2)
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "auto",
    },
  },
  doctorImage: {
    margin: theme.spacing(3, 0),
    maxWidth: "100%",
  },
  timeImage: {
    margin: theme.spacing(2, 0),
  },
  pageSection: {
    margin: "auto",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    padding: theme.spacing(3, 3),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      padding: theme.spacing(8, 8, 6, 8),
    },
  },
  content: {
    flex: "1",
  },
  userData: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      borderRight: `1px solid ${theme.palette.grey[100]}`,
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(10),
    },
  },
  cardFormInput: {
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2.5, 2),
    marginBottom: theme.spacing(1),
    fontWeight: 400,
    position: "relative",
    "&.StripeElement--focus + div": {
      color: `${theme.palette.primary.main}!important`,
    },
    "&.StripeElement--empty, &.StripeElement--invalid": {
      border: (props: Props) => props.showFormErrors ? `1px solid ${theme.palette.error.main}` : `1px solid ${theme.palette.grey[200]}`,
    },
    "&.StripeElement--empty + div, &.StripeElement--invalid + div": {
      color: (props: Props) => props.showFormErrors ? theme.palette.error.main : theme.palette.text.secondary,
    },
    "&.StripeElement--empty:hover, &.StripeElement--invalid:hover": {
      border: (props: Props) => props.showFormErrors ? `1px solid ${theme.palette.error.main}` : `1px solid ${theme.palette.text.primary}`,
    },
    "&.StripeElement--focus": {
      border: `1px solid ${theme.palette.primary.main}!important`,
    },
  },
  label: {
    position: "absolute",
    top: "-7px",
    left: "7px",
    padding: theme.spacing(0, .5),
    display: "inline-block",
    height: theme.spacing(2),
    zIndex: 1,
    background: theme.palette.common.white,
    color: theme.palette.text.secondary,
  },
  standardInput: {
    "& .MuiInputBase-formControl > input": {
      padding: theme.spacing(2.5, 2),
      height: theme.spacing(2.5),
    },
    "& > .MuiFormHelperText-contained.Mui-error": {
      display: "none",
    },
    "& > .MuiInputBase-formControl.Mui-focused > fieldset": {
      border: `1px solid ${theme.palette.primary.main}`,
      borderColor: theme.palette.primary.main,
    },
    "& > .MuiInputLabel-formControl.Mui-error.Mui-focused": {
      color: theme.palette.primary.main,
    }
  },
}))
