import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Box, Button, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"

import healthPreventionArticleImg from "../../../assets/images/healthPrevention/health-prevention-article-image.png"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import { sanitizeHTML } from "../../../utils/sanitizeHTML"
import { RoutePath } from "../../../routes/Routes.types"
import { useHealthPreventionLibraryStyles } from "./HealthPreventionLibrary.styles"

interface HealthPreventionLibraryProps {
  items: HealthPreventionLibraryItem[]
  redirectRoute: string
  btnLabel: string
}

export interface HealthPreventionLibraryItem {
  title: string
  content: string
  isBlue?: boolean
  availableAt?: string
  url?: string
  image?: string
}

const HealthPreventionLibrary:FC<HealthPreventionLibraryProps> = ({
  redirectRoute,
  items,
  btnLabel
}) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const { push } = useHistory()
  const history = useHistory()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const classes = useHealthPreventionLibraryStyles()
  
  const getImage = (url?: string) => {
    if (!url) {
      return <ImageByIntegrationType
        imageSrc={healthPreventionArticleImg}
        alt="healthPreventionArticleImg"
        className={classes.image}
        imagePath={"health-prevention-article-image.png"}
      />
    }

    return <img
      className={classes.image}
      src={url}
      alt={"WordpressImage"}
    />
  }

  const handleRedirect = (url: string) => {
    history.push({
      pathname: `/${i18n.language}${RoutePath.HEALTH_PREVENTION_IFRAME_VIEW}`,
      state: {
        iframeSrc: url,
        redirectRoute: RoutePath.MENTAL_HEALTH,
      },
    })
  }

  const itemsElements = items.map((item, index) => (
    <Box className={clsx(classes.pageSection, item.isBlue && classes.blueItem)} component="section" key={index}>
      <Box className={classes.content}>
        <Box className={classes.titleWrapper}>
          { !isMdUp && <Box className={classes.mobileImageWrapper}>{getImage(item.image)}</Box> }
          <Typography className={classes.title}>
            { item.title }
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography className={classes.bodyText}>
            {sanitizeHTML(item.content)}
          </Typography>
        </Box>
        <Box className={classes.buttonsWrapper}>
          {!item.availableAt && item.url && (
            <Button 
              variant="contained" 
              color="primary"
              onClick={() => handleRedirect(String(item.url))}
            >
              {t(btnLabel)}
            </Button>
          )}
          {item.availableAt && (
            <Typography className={classes.availableAt}>
              {t("availableSoon", {date: item.availableAt})}
            </Typography>
          )}
        </Box>
      </Box>
      { isMdUp && <Box className={classes.desktopImageWrapper}>{getImage(item.image)}</Box> }
    </Box>
  ))

  return <>
    {itemsElements}
    <Box className={classes.backBtnWrapper}>
      <Button 
        className={classes.backBtn} 
        variant="outlined" 
        color="primary" 
        onClick={() => push(redirectRoute)}
      >
        {t("goToHomepage")}
      </Button>
    </Box>
  </>
}

export default HealthPreventionLibrary
