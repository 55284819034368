import React, {FC, useEffect, useState} from "react"
import { useSelector } from "react-redux"
import {Box, Container, useMediaQuery,useTheme} from "@material-ui/core"

import { selectUserRegulationsModalOpen } from "../store/user/user.selectors"
import AcceptRegulationsModal from "../components/acceptRegulationsModal/AcceptRegulationsModal.component"
import MenuSidebarComponent from "../components/menu/mainMenu/desktopMenu/MenuSidebar.component"
import PageFooter from "../components/pageFooter/PageFooter.component"
import TopBar from "../components/topBar/TopBar.component"
import useLayoutStyles from "./Layout.styles"

import BaseLayout from "./BaseLayout"

interface ConsultationChatLayoutProps {}

const ConsultationChatLayout: FC<ConsultationChatLayoutProps> = ({ children }) => {
  const classes = useLayoutStyles()

  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))
  const userRegulationsModalOpen = useSelector(selectUserRegulationsModalOpen)

  const [menuSidebar, setMenuSidebar] = useState<HTMLDivElement | null>(null)
  const [sidebarWidth, setSidebarWidth] = useState<number>(0)

  const containerBoxMarginLeft = isSmUp ? sidebarWidth + "px" : 0

  useEffect(() => {
    if (menuSidebar) {
      setSidebarWidth(menuSidebar.offsetWidth)
    }
  }, [menuSidebar])

  return (
    <BaseLayout className="defaultLayout">
      {/*<NewNotificationPopup/>*/}

      <TopBar
        onlyLogo={!isMdUp}
      />

      <Box className={classes.pageContainerWithFixedTopBar}>
        { isMdUp && (
          <div
            ref={el => setMenuSidebar(el)}
            className={classes.fixedMenu}
          >
            <MenuSidebarComponent />
          </div>
        )}

        <Box
          style={{ marginLeft: containerBoxMarginLeft }}
          className={classes.containerWrapper}
        >
          <Container
            maxWidth="lg"
            className={classes.contentContainer}
          >
            <Box>
              { children }
            </Box>
          </Container>
        </Box>
      </Box>

      { isMdUp && (
        <PageFooter/>
      )}
      <AcceptRegulationsModal
        isOpen={userRegulationsModalOpen}
      />
    </BaseLayout>
  )
}

export default ConsultationChatLayout
