import { colors } from "../colors"

export const palette = {
  primary: {
    main: colors.telemediGreen,
    dark: colors.telemediGreenDark,
    darker: colors.telemediGreenDarker,
  },
  secondary: {
    main: colors.telemediBlue,
    dark: colors.telemediBlueDark,
    darker: colors.telemediBlueDarker,
    light: colors.telemediBlueLight,
    gradient: colors.telemediBlueGradient,
  },
  text: {
    primary: colors.telemediTextPrimary,
    secondary: colors.telemediGray60,
    footerBar: colors.telemediWhite,
    detailColor: colors.telemediDetailLightBlue
  },
  background: {
    default: colors.telemediPageBackground,
    paper: colors.telemediBackground,
    appBar: colors.telemediAppBarBg,
    map: colors.telemediGrayMapOverlay,
    footerBar: colors.telemediGreen
  },
  error: {
    main: colors.telemediRed,
  },
  warning: {
    main: colors.telemediWarning,
  },
  info: {
    lighter: colors.telemediViolet5,
    light: colors.telemediViolet10,
    main: colors.telemediViolet,
  },
  grey: {
    100: colors.telemediGray10,
    A100: colors.telemediGray15,
    200: colors.telemediGray20,
    400: colors.telemediGray40,
    600: colors.telemediGray60,
  },
}
