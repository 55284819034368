import {useParams} from "react-router"
import {useQuery} from "@apollo/client"

import {
  GET_USER_ACTION_BY_ID,
  GetUserActionsVariables,
  UserActionResponse
} from "../../api/queries/getUserActionById"

export const useGetAction = () => {
  const { actionId } = useParams<GetUserActionsVariables>()

  const { data, loading: isLoading } = useQuery<UserActionResponse, GetUserActionsVariables>(
    GET_USER_ACTION_BY_ID,
    {
      variables: { actionId },
      context: { clientName: "HealthPlanner" },
    }
  )

  return {
    actionData: data?.userActions[0]?.action,
    isLoading
  }
}
