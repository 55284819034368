const MuiListItemIcon = {
  root: {
    minWidth: "48px", // 56px in default is too large
    "& [class*='icon']": {
      fontSize: "24px", // icon default font-size
    }
  }
}

export const icons = {
  MuiListItemIcon,
}
