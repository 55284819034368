import React, {FC} from "react"
import {Trans, useTranslation} from "react-i18next"

import AppDialog from "../../../../../components/common/appDialog/AppDialog.component"

type InstructionModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
}

const InstructionModal: FC<InstructionModalProps> = ({ 
  isOpen,
  onClose,
}) => {
  const {t} = useTranslation()

  return (
    <AppDialog
      open={isOpen}
      onClose={onClose}
      title={t("awards:howItWorks")}
    >
      <Trans i18nKey="healthPlanner:awardInstruction">
        0
        <ul>
          <li>1</li>
          <li>2</li>
          <li>3</li>
        </ul>
        4
      </Trans>
    </AppDialog>
  )
}

export default InstructionModal