import { AxiosResponse } from "axios"

export const EXAM_POINTS_KEY = "examPoints"

export type MedicalExamsPointsState = {
  items: MedicalExamPoint[];
  itemsCoords: MedicalExamPointCoords[];
  loading: boolean;
  geocodeLoading: boolean;
  error: null | Pick<AxiosResponse, "data" | "status">; // TODO: add response data type
  geocodeError: null | google.maps.GeocoderStatus;
  chosenLaboratory?: MedicalExamPoint
}

export type MedicalExamPoint = {
  id: number;
  pointType: string; // "alab"
  postalCode: string; // "02-117"
  city: string; // "Warszawa"
  street: string; // "Racławicka 154"
  district: string; // "Stępińska"
  distance: string; // "1.12"
}

export const NEAREST_MEDICAL_EXAM_POINTS_LIMIT = 2

export type MedicalExamPointCoords = {
  coords: google.maps.LatLngLiteral;
}