import React, {FC, useEffect, useRef,useState} from "react"
import {useTranslation} from "react-i18next"
import {Backdrop, Box, CircularProgress} from "@material-ui/core"
import OT, {Publisher, Session} from "@opentok/client"
import clsx from "clsx"
import {Channel} from "pusher-js"

import {openTokApiKey, openTokApiKey2} from "../../../app.config"
import GlobalErrorSnackbar from "../../commonFormItems/globalErrorSnackbar/GlobalErrorSnackbar"
import ConsultationInvitationVideoModal from "./consultationInvitationVideoModal/ConsultationInvitationVideoModal.component"
import VideoControlButtons from "./videoControlButtons/VideoControlButtons.component"
import {disconnectOpenTok} from "./ConsultationVideo.utils"
import {useConsultationVideoStyles} from "./ConsultationVideo.styles"

import {publisherProperties, subsriberProperties} from "./ConsultationVideo.config"

interface ConsultationVideoProps {
  pusherChannel: Channel;
  consultationId: string;
  consultationOpenTokToken: string;
  consultationOpenTokToken2: string;
  consultationChatSession: string;
  consultationChatSession2: string;
  invitationVideoModalOpen: boolean;
  videoChatOpen: boolean;
  setInvitationVideoModalOpen: (invitationVideoModalOpen: boolean) => void;
  setVideoChatOpen: (videoChatOpen: boolean) => void;
}

const ConsultationVideo: FC<ConsultationVideoProps> = (
  {
    pusherChannel,
    consultationId,
    consultationChatSession,
    consultationChatSession2,
    consultationOpenTokToken,
    consultationOpenTokToken2,
    invitationVideoModalOpen,
    videoChatOpen,
    setInvitationVideoModalOpen,
    setVideoChatOpen,
  }
) => {
  const classes = useConsultationVideoStyles()
  const {t} = useTranslation()

  const [session, setSession] = useState<Session | undefined>()
  const [publisher, setPublisher] = useState<Publisher | undefined>()
  const doctorVideoBox = useRef<HTMLDivElement>(null)

  const [isVideoFullScreenOpen, setIsVideoFullScreenOpen] = useState<boolean>(true)
  const [loadingStream, setLoadingStream] = useState<boolean>(false)

  const [globalError, setGlobalError] = useState<string>("")

  const isAppleDevice = !!navigator.platform.match(/iPhone|iPod|iPad|MacIntel/)
  // to use openTokApiKey2, consultationChatSession2 and consultationOpenTokToken2
  // due to not supported codecs on safari

  const handleFinishVideoChat = () => {
    setInvitationVideoModalOpen(false)
    setVideoChatOpen(false)
    setIsVideoFullScreenOpen(true)
    setLoadingStream(false)
    setGlobalError("")

    disconnectOpenTok(setSession, setPublisher, session, publisher)
  }

  const initOpenTok = () => {
    setLoadingStream(true)
    setGlobalError("")

    const otSession = isAppleDevice
      ? OT.initSession(openTokApiKey2, consultationChatSession2)
      : OT.initSession(openTokApiKey, consultationChatSession)
    setSession(otSession)

    const otPublisher = OT.initPublisher("targetElementPatientVideo", publisherProperties)
    setPublisher(otPublisher)

    otSession.connect(isAppleDevice ? consultationOpenTokToken2 : consultationOpenTokToken, (err?: Error) => {
      otSession.publish(otPublisher)

      if (err) {
        console.error("connect error", err)
      }
    })

    otSession.on("streamCreated", event => {
      const currentSubscribers = otSession.getSubscribersForStream(event.stream)

      if (!currentSubscribers.length) {
        if (doctorVideoBox.current) {

          if (doctorVideoBox.current.childNodes.length <= 1) {
            doctorVideoBox.current.innerHTML = "" // prevent double subsriber video view
          }

          otSession.subscribe(event.stream, doctorVideoBox.current, subsriberProperties)
        }
      }

      setLoadingStream(false)
    })

    otSession.on("sessionDisconnected", event => {
      handleFinishVideoChat()
      if (event.reason == "networkDisconnected") {
        setGlobalError(t("errors:videoNetworkDisconnected"))
      }
    })

    otSession.on("streamDestroyed", () => {
      handleFinishVideoChat()
    })
  }

  useEffect(() => {
    if (videoChatOpen && !publisher) {
      initOpenTok()
    } else {
      handleFinishVideoChat()
    }
  }, [videoChatOpen])

  useEffect(() => {
    return () => {
      handleFinishVideoChat()
    }
  }, [])

  return (
    <>
      <Backdrop
        open={videoChatOpen}
        className={clsx(
          classes.backdrop,
          !isVideoFullScreenOpen && classes.mitigatedBackdrop
        )}
      >
        <Box className={clsx(isVideoFullScreenOpen ? classes.videosBox : classes.mitigatedVideoBox)}>
          <Box className={classes.videosWrapper}>
            <div
              ref={doctorVideoBox}
              className={clsx(
                classes.doctorVideo,
                !isVideoFullScreenOpen && classes.mitigatedDoctorVideo,
              )}
            />

            { loadingStream && (
              <Box className={classes.loaderBox}>
                <CircularProgress size={isVideoFullScreenOpen ? 100 : 50} thickness={2} color="inherit"/>
              </Box>
            )}

            <Box
              id="targetElementPatientVideo"
              className={clsx(
                classes.patientVideo,
                !isVideoFullScreenOpen && "visually-hidden",
              )}
            />
          </Box>

          { publisher && (
            <VideoControlButtons
              publisher={publisher}
              pusherChannel={pusherChannel}
              finishVideoChat={handleFinishVideoChat}
              consultationId={consultationId}
              toggleFullScreenVideo={() => setIsVideoFullScreenOpen(!isVideoFullScreenOpen)}
              isVideoFullScreenOpen={isVideoFullScreenOpen}
            />
          )}
        </Box>
      </Backdrop>

      <ConsultationInvitationVideoModal
        open={invitationVideoModalOpen}
        setClose={() => setInvitationVideoModalOpen(false)}
        setVideoChatOpen={() => setVideoChatOpen(true)}
      />

      <GlobalErrorSnackbar
        isError={!!globalError}
        errorMessage={globalError}
      />
    </>
  )
}

export default ConsultationVideo
