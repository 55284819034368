import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useSettingsPanelStyles = makeStyles((theme: Theme) => createStyles({
  settingsBox: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      position: "absolute",
    },
  },
  dialog: {
    width: "600px",
    maxWidth: "100%"
  }
}))
