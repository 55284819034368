import React, {FC, useState} from "react"
import {Box, Button, useMediaQuery, useTheme} from "@material-ui/core"

import api from "../../../../api/api"
import {postConsultationFileConfig} from "../../../../api/routes"
import useConsultationLang from "../../../../hooks/useConsultationLang"
import IconByIntegrationType from "../../../iconByIntegrationType/IconByIntegrationType"
import AppDialog from "../../../common/appDialog/AppDialog.component"
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader.component"
import FileDropzone from "../../../commonFormItems/fileDropzone/FileDropzone.component"
import {useSendNewMessageFormStyles} from "../SendNewMessageForm.styles"

interface AddConsultationFileProps {
  consultationId: string;
  disabled: boolean;
}

const AddConsultationFile: FC<AddConsultationFileProps> = ({consultationId, disabled}) => {
  const consultationLang = useConsultationLang()
  const classes = useSendNewMessageFormStyles()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))

  const [isSaving, setSaving] = useState(false)
  const [isAddFileModalOpen, setAddFileModalOpen] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [file, setFile] = useState<File | null>(null)

  const onModalClose = () => {
    setAddFileModalOpen(false)
  }

  const handleFileChange = (files: File[]) => {
    setFile(files.length ? files[0] : null)
  }

  const handleUploadFilesSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()
    setErrors([])
    setSaving(true)

    if (file) {
      const formData = new FormData()
      formData.append("file", file)

      try {
        await api.request({
          ...postConsultationFileConfig(consultationId),
          data: formData
        })

        setAddFileModalOpen(false)
        setFile(null)
      } catch (e) {
        if (e.response?.data?.errors) {
          setErrors(e.response.data.errors.map((error: string) => consultationLang.getLabel(`errors:${error}`)))
        }
      }
    }

    setSaving(false)
  }

  return (
    <Box>
      {
        isSmUp
          ? (
            <Button
              variant="outlined"
              disabled={disabled || isSaving}
              color="primary"
              onClick={() => setAddFileModalOpen(true)}
            >
              <IconByIntegrationType iconName={"icon-clip"}/>
              { consultationLang.getLabel("chatConsultation:addDocuments") }
            </Button>
          )
          : <IconByIntegrationType iconName={"icon-clip"}
            className={classes.icon} 
            onClick={() => setAddFileModalOpen(true)} 
          />
      }

      <AppDialog
        open={isAddFileModalOpen}
        onClose={onModalClose}
        disableBackdropClick={true}
        title={consultationLang.getLabel("chatConsultation:addFile")}
        actionButtonText={consultationLang.getLabel("add")}
        actionButtonProps={{
          disabled: !file || isSaving,
          onClick: handleUploadFilesSubmit,
          startIcon: isSaving && <ButtonLoader position="prefix"/>
        }}
      >
        <FileDropzone
          apiErrors={errors}
          resetApiErrors={() => setErrors([])}
          handleInputChange={handleFileChange}
        />
      </AppDialog>
    </Box>
  )
}

export default AddConsultationFile
