import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useInformationPanelStyles = makeStyles((theme: Theme) => createStyles({
  pageSection: {
    margin: "auto",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8),
    }
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
  },
  gridItem: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
  formSectionTitle: {
    marginBottom: theme.spacing(1.5),
    display: "block",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flexWrap: "wrap",
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row-reverse",
      alignItems: "center",
    }
  },
  button: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(3),
    }
  },
}))
