import React, { FC } from "react"
import {Box,Typography} from "@material-ui/core"

import useConsultationLang from "../../../../hooks/useConsultationLang"
import AppDialog from "../../../common/appDialog/AppDialog.component"

interface FinishVideoModalProps {
  open: boolean;
  setClose: () => void;
  finishVideoChat: () => void;
}

const FinishVideoModal: FC<FinishVideoModalProps> = (
  {
    open,
    setClose,
    finishVideoChat,
  }
) => {
  const consultationLang = useConsultationLang()

  const handleSubmitCloseVideoButtonClick = () => {
    setClose()
    finishVideoChat()
  }

  return (
    <AppDialog
      open={open}
      onClose={setClose}
      title={consultationLang.getLabel("videoChatConsultation:endVideoModalTitle")}
      actionButtonText={consultationLang.getLabel("videoChatConsultation:endVideoModalAgreeButton")}
      actionButtonProps={{
        onClick: handleSubmitCloseVideoButtonClick
      }}
      closeButtonText={consultationLang.getLabel("cancel")}
    >
      <Box mb={2}>
        <Typography>
          {consultationLang.getLabel("videoChatConsultation:endVideoModalDescription")}
        </Typography>
      </Box>
    </AppDialog>
  )
}

export default FinishVideoModal
