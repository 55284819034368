import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Paper, Typography} from "@material-ui/core"

import {UserAction} from "../../../../api/queries/getUserActionsByIds"
import RecommendedActions from "../ recommendedActions/RecommendedActions.component"
import Description from "../description/Description.component"
import DiseaseRisk from "../diseaseRisk/DiseaseRisk.component"
import {usePreventionDiseaseDetailsStyles} from "../../PreventionDiseaseDetails.styles"
import {useMobileDiseaseDetailsStyles} from "./MobileDiseaseDetails.styles"

type MobileDiseaseDetailsProps = {
  name: string;
  description: string;
  userActions: UserAction[];
  isUserDisease: boolean;
  imageUrl: string;
  probability?: number;
  questionnaireId?: string;
}

const MobileDiseaseDetails: FC<MobileDiseaseDetailsProps> = ({
  name,
  description,
  userActions,
  isUserDisease,
  probability,
  imageUrl,
  questionnaireId,
}) => {
  const mobileDiseaseClasses = useMobileDiseaseDetailsStyles()
  const classes = usePreventionDiseaseDetailsStyles()
  const {t} = useTranslation()

  return (
    <Box
      className={mobileDiseaseClasses.container}
      component={Paper}
    >
      <Description
        title={name}
        description={description}
      />
      <Typography
        className={classes.title}
        variant="h3"
      >
        {t("healthPlannerActions:recommendedActions")}
      </Typography>
      <RecommendedActions
        userActions={userActions}
      />
      {
        !isUserDisease && questionnaireId && (
          <>
            <Typography
              className={classes.title}
              variant="h3"
            >
              {t("healthPlanner:diseaseRisk")}
            </Typography>
            <DiseaseRisk
              diseaseName={name}
              probability={probability}
              imageUrl={imageUrl}
            />
          </>
        )
      }
    </Box>
  )
}

export default MobileDiseaseDetails