import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useConsultationTypeSelectStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    icon: {
      ...theme.typography.h5,
      color: theme.palette.info.main,
      marginTop: "1px",
    },
    text: {
      textTransform: "capitalize",
      fontWeight: "bold",
    }
  })
)
