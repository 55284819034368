import {useHistory, useLocation} from "react-router"

import {SearchParams} from "../enums/SearchParams"

export const useDeleteSearchParam = () => {
  const location = useLocation()
  const history = useHistory()

  return (searchParam: SearchParams) => {
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has(searchParam)) {
      queryParams.delete(searchParam)
      history.replace({
        search: queryParams.toString(),
      })
    }
  }
}
