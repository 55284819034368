import React, { FC } from "react"
import {Box, Typography, useTheme} from "@material-ui/core"

import ButtonTextUnderlined from "../../common/buttonTextUnderlined/ButtonTextUnderlined.component"

interface UserDataInfoSectionProps {
  title?: string;
  withButton?: boolean;
  onClick?(): void;
  buttonText?: string;
}

const UserDataInfoSection: FC<UserDataInfoSectionProps> = ({
  title,
  withButton = false,
  onClick,
  buttonText,
  children
}) => {
  const theme = useTheme()

  const handleClick = () => {
    if (withButton && onClick) {
      onClick()
    }
  }

  return (
    <Box
      mb={2}
      display="flex"
      flexDirection="column"
    >
      <Box
        mb={2}
        display="flex"
        alignItems="center"
      >
        <Box minHeight={theme.spacing(3)}>
          { title && (
            <Typography variant="h4" component="h3">
              { title }
            </Typography>
          )}
        </Box>

        {withButton && (
          <Box ml={1}>
            <ButtonTextUnderlined
              color="primary"
              onClick={handleClick}
            >
              {buttonText}
            </ButtonTextUnderlined>
          </Box>
        )}
      </Box>

      { children }
    </Box>
  )
}

export default UserDataInfoSection
