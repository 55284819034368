import React, {FC, MouseEvent, useState} from "react"
import {useTranslation} from "react-i18next"
import {ListItemIcon, ListItemText, Menu, MenuItem} from "@material-ui/core"
import { add, format } from "date-fns"
import isMobile from "is-mobile"

import { ReactComponent as AppleIcon } from "../../../assets/svg/apple.svg"
import { ReactComponent as GoogleIcon } from "../../../assets/svg/google.svg"
import { ReactComponent as OutlookIcon } from "../../../assets/svg/outlook.svg"
import { ReactComponent as OutlookComIcon } from "../../../assets/svg/outlook-com.svg"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import ButtonTextUnderlined from "../buttonTextUnderlined/ButtonTextUnderlined.component"
import { AddToCalendarEvent, AddToCalendarMenuItemType } from "./AddToCalendar.types"
import { useAddToCalendarStyles } from "./AddToCalendar.styles"

import { addToCalendarMenuItems } from "./addToCalendarMenuConfig"

const STANDARD_CONSULTATION_DURATION_MIN = 10

interface AddToCalendarProps {
  event: AddToCalendarEvent;
  eventId: string;
  className?: string;
}

const AddToCalendar: FC<AddToCalendarProps> = ({event, eventId, className}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const {t} = useTranslation()
  const classes = useAddToCalendarStyles()

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const formatTime = (date: Date): string => format(date, "yyyyMMdd'T'HHmmss")

  const getFormattedEndTime =(date: Date): string => {
    const endDate = add(date, {minutes: STANDARD_CONSULTATION_DURATION_MIN})
    return formatTime(endDate)
  }

  const buildUrl = (type: string, event: AddToCalendarEvent) => {
    const startTime =  formatTime(event.startTime)
    const endTime = event.endTime ? formatTime(event.endTime): getFormattedEndTime(event.startTime)
    const location = event.location ? encodeURIComponent(event.location) : ""
    const title = encodeURIComponent(event.title)
    const description = event.description ? encodeURIComponent(event.description) : ""

    let calendarUrl: string

    switch (type) {
      case "google":
        calendarUrl = "https://calendar.google.com/calendar/render"
        calendarUrl += "?action=TEMPLATE"
        calendarUrl += "&dates=" + startTime
        calendarUrl += "/" + endTime
        calendarUrl += "&location=" + location
        calendarUrl += "&text=" + title
        calendarUrl += "&details=" + description
        break

      case "outlookcom":
        calendarUrl = "https://outlook.live.com/owa/?rru=addevent"
        calendarUrl += "&startdt=" + startTime
        calendarUrl += "&enddt=" + endTime
        calendarUrl += "&subject=" + title
        calendarUrl += "&location=" + location
        calendarUrl += "&body=" + description
        calendarUrl += "&allday=false"
        calendarUrl += "&uid=" + eventId
        calendarUrl += "&path=/calendar/view/Month"
        break

      default:
        calendarUrl = [
          "BEGIN:VCALENDAR",
          "PRODID:-//Telemedi.co//patient dashboard//EN",
          "VERSION:2.0",
          "BEGIN:VEVENT",
          "DTSTAMP:" + formatTime(new Date()),
          "UID:" + eventId,
          "URL:" + document.URL,
          "CALSCALE:GREGORIAN",
          "METHOD:PUBLISH",
          "STATUS:CONFIRMED",
          "TRANSP:TRANSPARENT",
          "DTSTART:" + startTime,
          "DTEND:" + endTime,
          "SUMMARY:" + event.title,
          "DESCRIPTION:" + event.description?.replace(/\n/g, "\\n"),
          "LOCATION:" + event.location,
          "END:VEVENT",
          "END:VCALENDAR"
        ].join("\n")

        if (isMobile()) {
          calendarUrl = encodeURI("data:text/calendar;charset=utf8," + calendarUrl)
        }
    }

    return calendarUrl
  }

  const handleListItemClick = (url: string) => (e: MouseEvent) => {
    e.preventDefault()

    if (
      !isMobile() &&
      (url.startsWith("data") || url.startsWith("BEGIN"))
    ) {
      const filename = "download.ics"
      const blob = new Blob([url], { type: "text/calendar;charset=utf-8" })
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.setAttribute("download", filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

    } else {
      window.open(url, "_blank")
    }

    handleClose()
  }

  const calendarIcon = (type: AddToCalendarMenuItemType) => {
    switch(type) {
      case "google":
        return <ImageByIntegrationType
          imageSrc={<GoogleIcon/>}
          imagePath={"google.svg"}
          returnSvg={true}
        />
      case "apple":
        return <ImageByIntegrationType
          imageSrc={<AppleIcon/>}
          imagePath={"apple.svg"}
          returnSvg={true}
        />
      case "outlook":
        return <ImageByIntegrationType
          imageSrc={<OutlookIcon/>}
          imagePath={"outlook.svg"}
          returnSvg={true}
        />
      case "outlookCom":
        return <ImageByIntegrationType
          imageSrc={<OutlookComIcon/>}
          imagePath={"outlook-com.svg"}
          returnSvg={true}
        />
      default:
        return null
    }
  }

  return (
    <>
      <ButtonTextUnderlined
        color={"whiteOnBlue"}
        onClick={handleMenu}
        className={className}
      >
        {t("toCalendar")}
      </ButtonTextUnderlined>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        autoFocus={false}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        classes={{
          paper: classes.popoverPaper,
          list: classes.list,
        }}
      >
        {addToCalendarMenuItems.map((item, index) => (
          <MenuItem
            key={index}
            button
            className={classes.listItem}
            onClick={handleListItemClick(buildUrl(item.type, event))}
          >
            <ListItemIcon>
              {calendarIcon(item.type)}
            </ListItemIcon>
            <ListItemText>
              {t(`calendar:${item.type}`)}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default AddToCalendar
