export enum DozRefundType {
  REFOUNDATION_100 = "100",
  REFOUNDATION_50 = "50",
  REFOUNDATION_30 = "30",
  REFOUNDATION_FLAT_RATE = "R",
  REFOUNDATION_FREE = "B",
}

export interface RequestedItem {
  ean: number;
  quantity: number; //max 50
  quantity_description: string; //max 255 znakow
  refund_type: string;
}

export interface DozOrder {
  pharmacy_id: string;
  phone_number: string;
  terms_and_conditions: boolean;
  requested_item_list: RequestedItem[];
}

export enum OrderType {
  FROM_WHOLESALERS = "Z",
  FROM_PHARMACY = "R"
}

export enum ErrorStatusCode {
  VALIDATION_ERROR = 400,
  INTERNAL_SERVER_ERROR = 500,
  NO_PRODUCT_PROVIDED = 4400,
  AVAILABILITY_PRODUCT_ERROR = 4401,
  NO_PRODUCT_AVAILABLE_ERROR = 4402,
}

export type StatusCode = 200 | ErrorStatusCode

export interface SuccessResponseOrder {
  message: string | null;
  code: 200;
  status: "ok";
  data: {
    order_id: number;
    order_type: OrderType;
    order_url: string;
  }
}

export enum PharmacyType {
  "Day",
  "H24"
}

export interface NearbyPharmacy {
  id: number | null;
  name: string;
  street: string;
  city: string;
  postcode: string; //00695
  phone: string; // 800 110 110
  hours_week: string; // 07:30-20:30
  hours_saturday: string | null; //"08:00-15:00",
  hours_sunday: string | null;
  all_day: PharmacyType;
  delivery_time: string;
  gps_latitude: string;
  gps_longitude: string;
}

export interface NearbyPharmaciesResponse {
  code: 200;
  data: {
    pharmacies: NearbyPharmacy[];
  };
}

export interface DozErrorResponse {
  code: ErrorStatusCode;
  message: string;
  data: string;
}
