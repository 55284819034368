import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Typography} from "@material-ui/core"
import clsx from "clsx"

import {useNfzStatusInfoStyles} from "./NfzStatusInfo.styles"

interface NfzStatusOnInfoProps {}

const NfzStatusOnInfo: FC<NfzStatusOnInfoProps> = () => {
  const {t} = useTranslation()
  const classes = useNfzStatusInfoStyles()

  return (
    <Typography className={clsx(classes.description, "break-spaces")}>
      {t("insuranceActiveInfo")}
    </Typography>
  )
}

export default NfzStatusOnInfo
