import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useDocumentationGroupTypeSelectDesktopStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      boxShadow: "none",
      marginBottom: theme.spacing(6),
    },
    tabsFlexContainer: {
      flexWrap: "wrap",
    },
    tab: {
      color: theme.palette.grey[400],
      borderBottom: `2px solid ${theme.palette.grey[200]}`
    }
  })
)
