import {ChangeEvent, useState} from "react"

import {HttpStatusCode} from "../../../enums/HttpStatusCode"
import {SsoErrorCode} from "../../../enums/SsoErrorCode"
import {isBaseQueryError} from "../../../store/store.utils"

import {MAXIMUM_CHARACTER_EXCEEDED} from "./VerifyUserCode.config"

export const PASSWORDLESS_INVALID_CODE = "passwordless:invalidPasswordlessCode"

export const useAccessCodeInput = () => {
  const [inputValue, setInputValue] = useState("")
  const [isMaximumCharacterExceeded, setIsMaximumCharacterExceeded] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const numericValue = value.replace(/\D/g,"")
    setInputValue(numericValue)
    setIsMaximumCharacterExceeded(numericValue.length > MAXIMUM_CHARACTER_EXCEEDED)
  }

  return {
    inputValue,
    isMaximumCharacterExceeded,
    handleChange
  }
}

export const handleRequestError = (error: unknown) => {
  if (isBaseQueryError(error)) {
    if (error.data?.code === SsoErrorCode.INVALID_PASSWORDLESS_CODE) {
      return PASSWORDLESS_INVALID_CODE
    } else if (error.data?.code === SsoErrorCode.FUSION_AUTH_ERROR && error.data.status === HttpStatusCode.CONFLICT) {
      return "errors:login_prevented"
    }

    return "errors:unknownError"
  }
}
