import {gql} from "@apollo/client"

import {QuestionnaireTemplate} from "../../pages/preventionQuestionnaire/genericStepForm/GenericStepForm.types"

export const GET_QUESTIONNAIRE_TEMPLATE = gql`
  query QuestionnaireTemplates($questionnaireId: uuid!) {
    questionnaireTemplates: questionnaire_templates(where: {id: {_eq: $questionnaireId}}) {
      template
    }
  }
`

export type GetQuestionnaireTemplateResponse = {
  questionnaireTemplates: [{
    template: QuestionnaireTemplate
  }]
}

export type GetQuestionnaireTemplateVariables = {
  questionnaireId: string;
}