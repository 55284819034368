import { format as formatDate, formatDistance, formatDistanceStrict,formatRelative, Locale } from "date-fns"
import { bg, bs, cs, de, enUS, es, fr, hr, it, pl, pt, ru, srLatn,uk } from "date-fns/locale"
import i18next, { FormatFunction } from "i18next"

// date-fns resources
const locales: {[key: string]: Locale} = {
  pl,
  es,
  en: enUS,
  ua: uk,
  pt,
  ru,
  fr,
  rs: srLatn,
  cs,
  it,
  hr,
  de,
  ba: bs,
  bg
}

const interpolationDatesFormat: FormatFunction = (value, format, lng) => {
  if (null === value) {
    return ""
  }

  if (format && lng) {
    const locale = locales[lng]

    switch(format) {
      case "relative": {
        return formatRelative(value, new Date(), {locale})
      }
      case "ago": {
        return formatDistance(value, new Date(), {
          locale,
          addSuffix: true
        })
      }
      case "distanceDates": {
        return formatDistance(value[0], value[1], { locale })
      }
      case "distanceDatesStrict": {
        return formatDistanceStrict(value[0], value[1], { locale })
      }
      case "rangeTextDateWithYear": {
        const rangeTextDateWithYearFormat = "dd.MM.yyyy"
        const getRangeTextDateWithYearFormat = (date: Date) => formatDate(date, rangeTextDateWithYearFormat, { locale })
        return i18next.t("sickLeaveDateRange", {startDate: getRangeTextDateWithYearFormat(value[0]), endDate: getRangeTextDateWithYearFormat(value[1])})
      }
      case "rangeTextDateWithoutYear": {
        const startDateFormat = formatDate(value[0], "dd.MM", { locale })
        const endDateFormat = formatDate(value[1], "dd.MM.yyyy", { locale })
        return i18next.t("sickLeaveDateRange", {startDate: startDateFormat, endDate: endDateFormat})
      }
      case "rangeDateWithYearHeader": {
        const startDateFormat = formatDate(value[0], "dd.MM.yyyy", { locale })
        const endDateFormat = formatDate(value[1], "dd.MM.yyyy", { locale })
        return `${startDateFormat} - ${endDateFormat}`
      }
      case "rangeDateWithoutYearHeader": {
        const startDateFormat = formatDate(value[0], "dd.MM", { locale })
        const endDateFormat = formatDate(value[1], "dd.MM.yyyy", { locale })
        return `${startDateFormat} - ${endDateFormat}`
      }
      default: {
        return formatDate(value, format, { locale })
      }
    }
  }

  return value
}

export default interpolationDatesFormat
