import React, {FC} from "react"
import {useController} from "react-hook-form"
import { Box } from "@material-ui/core"

import SickLeaveDates from "../../../../../../../components/SickLeaveSurvey/SickLeaveDates.component"
import {PatientsRequirementsStepData} from "../../../../MedicalQuestionnaireStepperForm.types"

const SickLeaveDatesController: FC = () => {
  const { field: { value: startAt, onChange: onChangeStartAt } } = useController<PatientsRequirementsStepData, "sickLeave.startAt">({
    name: "sickLeave.startAt",
  })
  const { field: { value: endAt, onChange: onChangeEndAt } } = useController<PatientsRequirementsStepData, "sickLeave.endAt">({
    name: "sickLeave.endAt",
  })
  const handleChangeDates = (start: string, end: string) => {
    onChangeStartAt(start)
    onChangeEndAt(end)
  }

  return (
    <Box mb={1}>
      <SickLeaveDates
        startAt={startAt}
        endAt={endAt}
        handleChangeDates={handleChangeDates}
      />
    </Box>
  )
}

export default SickLeaveDatesController
