import React, {FC} from "react"
import { Typography } from "@material-ui/core"
import clsx from "clsx"

import { useAppSelector } from "../../hooks/storeHooks"
import { selectClinicSettings } from "../../store/clinic/clinicSettings.selectors"

interface IconByIntegrationTypeProps {
  iconName: string;
  skip?: boolean;
  returnTypography?: boolean;
  className?: string;
  onClick?: any;
}

const integrationMappings: Record<string, Record<string, string>> = {
  allianz: {
    "icon-user": "icon-allianz-user-o",
    "icon-umbrella": "icon-allianz-product-money-exchange",
    "icon-sign-out": "icon-allianz-product-door",
    "icon-translate": "icon-allianz-product-world-globe",
    "icon-stetoscope": "icon-allianz-cobrowsing-o",
    "icon-archive": "icon-allianz-text-o",
    "icon-calendar-blank": "icon-allianz-default-o",
    "icon-group":"icon-allianz-users-o",
    //documentation icons
    "icon-pill": "icon-allianz-pill-o",
    "icon-prescription": "icon-allianz-prescription-o",
    "icon-flask": "icon-allianz-flask-o",
    "icon-file": "icon-allianz-file-o",
    "icon-clipboard": "icon-allianz-clipboard-o",
    "icon-sickLeave": "icon-allianz-sickLeave-o",
    //Consultation type icons
    "icon-house" : "icon-allianz-house",
    "icon-video-camera" : "icon-allianz-video-camera",
    "icon-chat" : "icon-allianz-chat",
    "icon-phone" : "icon-allianz-phone",
    //Change consultation type icons
    "icon-video-camera-o" : "icon-allianz-video-camera-o",
    "icon-chat-o" : "icon-allianz-chat-o",
    "icon-phone-o" : "icon-allianz-phone-o",
  },
}

const IconByIntegrationType: FC<IconByIntegrationTypeProps> = ({
  iconName,
  skip,
  returnTypography,
  className,
  onClick,
  ...props
}) => {
  const {
    frontendSettings: { style },
    clinicAllianzSettings: { ewkaVerificationEnabled, onyxVerificationEnabled },
    id
  } = useAppSelector(selectClinicSettings)

  const clinicId = id.replace("/api/clinics/", "")

  const enabledIntegrations: Record<string, boolean> = {
    allianz: ewkaVerificationEnabled || onyxVerificationEnabled
  }

  let mappedIcon = iconName
  let mappedIconExist = false
  
  const allEnabledIntegrationMappings = Object.keys(enabledIntegrations)
    .filter(integration => enabledIntegrations[integration])
    .map(integration => integrationMappings[integration])
    .reduce((acc, val) => Object.assign(acc, val), {})
  
  if (!skip && allEnabledIntegrationMappings[iconName]) {
    mappedIcon = allEnabledIntegrationMappings[iconName]
    mappedIconExist = true
  }

  if (style.dynamicStylesEnabled && style.dynamicIconsEnabled && !mappedIconExist) {
    const clinicIconClass = `${mappedIcon.replace("icon-", `icon-${clinicId}-`)}`

    try {
      Array.from(document.styleSheets).some(styleSheet => {
        if (styleSheet.cssRules) {
          const cssRules = Array.from(styleSheet.cssRules)
          if (cssRules.some(rule => rule.cssText && rule.cssText.includes(`.${clinicIconClass}`))) {
            mappedIcon = clinicIconClass
            return true
          }
        }

        return false
      })
    } catch (e) {}
  }

  if (returnTypography) {
    return <Typography className={clsx(className, mappedIcon)} {...props} />
  }

  return <span className={clsx(mappedIcon, className)} onClick={onClick} />
}

export default IconByIntegrationType
