import React, { ChangeEvent, FC, useEffect,useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography,useMediaQuery, useTheme } from "@material-ui/core"
import _isEmpty from "lodash.isempty"

import Loader from "../../../../components/loader/Loader.component"
import DiaryMeasurementDataInput from "./diaryMeasurementDataInput/DiaryMeasurementDataInput.component"
import DiaryMeasurementTabPanels from "./diaryMeasurementTabs/diaryMeasurementTabPanels/DiaryMeasurementTabPanels.component"
import DiaryMeasurementTabs from "./diaryMeasurementTabs/DiaryMeasurementTabs.component"
import {useCheckIfUserFinishMeasurementAction} from "./DiaryMeasurement.utils"
import { DataOption, DataOptions, DiaryMeasurementProps, PreparedMeasurementsDataModel } from "./DiaryMeasurement.types"
import { useDiaryMeasurementStyles } from "./DiaryMeasurement.styles"

const DiaryMeasurement: FC<DiaryMeasurementProps> = ({
  examinationType,
  diaryTitle,
  getRecords,
  data,
  dataLoading,
  dataFetchingError,
  addMeasurement,
  addMeasurementData,
  addMeasurementLoading,
  addMeasurementError,
  genericInputData,
  inputTitle,
  validationSchema,
}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [currentTab, setCurrentTab] = useState(0)
  const [currentNestedTab, setCurrentNestedTab] = useState(0)
  const { 
    isLoadingUserActions,
    handleFinishMeasurementAction
  } = useCheckIfUserFinishMeasurementAction(examinationType)
  const {
    boxCard,
    title,
    measurementWrapper,
  } = useDiaryMeasurementStyles()

  const handleChangeTab = (event: ChangeEvent<unknown>, newValue: number) => {
    setCurrentTab(newValue)
  }

  const handleChangeNestedTab = (event: ChangeEvent<unknown>, newValue: number) => {
    setCurrentNestedTab(newValue)
  }

  useEffect(()=>{
    getRecords()
  },[])

  const mutateData = (data: DataOptions, unit: string): PreparedMeasurementsDataModel[] => {
    const excludedData = ["id", "createdAt", "__typename"]

    const formattedData = data.map( (item: DataOption) => {
      const filteredValues = Object.entries(item).filter( ([key, value]) => {
        const shouldExcludeProperty = excludedData.includes(key)

        if(!shouldExcludeProperty) {
          return { key, value }
        }
      })

      const values = filteredValues.map( filteredValue => {
        return ({
          name: filteredValue[0],
          value: filteredValue[1],
          unit
        })
      })

      return {
        id: item.id,
        createdAt: item.createdAt,
        values: values,
      }
    })

    return formattedData
  }

  const handleAddMeasurement: DiaryMeasurementProps["addMeasurement"] = (data, input) => {
    addMeasurement(data, input)
    handleFinishMeasurementAction()
  }
  
  return (
    <Box className={boxCard}>
      {!isMdUp && (
        <Typography
          variant={isMdUp ? "h3" : "h4"}
          component="h3"
          className={title}
        >
          {diaryTitle}
        </Typography>
      )}

      <Box className={measurementWrapper}>
        {
          false && (
            <DiaryMeasurementTabs
              currentTab={currentTab}
              currentNestedTab={currentNestedTab}
              handleChangeTab={handleChangeTab}
              handleChangeNestedTab={handleChangeNestedTab}
            />
          )
        }
        {dataLoading
          ? <Loader my={4} />
          : !dataFetchingError && !_isEmpty(data) && (
            <DiaryMeasurementTabPanels
              currentTab={currentTab}
              currentNestedTab={currentNestedTab}
              records={mutateData(data, genericInputData[0].unit)}
            />
          )
          
        }
        {dataFetchingError && (
          <Box my={4}>
            <Typography variant="body1">{t("errors:defaultError")}</Typography>
          </Box>
        )}

        {_isEmpty(data) && !dataFetchingError && !dataLoading && (
          <Box my={4}>
            <Typography variant="body1">Nie mamy jeszcze Twoich pomiarów.</Typography>
          </Box>
        )}
      </Box>
      {!dataLoading && !dataFetchingError && (
        <DiaryMeasurementDataInput
          getRecords={getRecords}
          validationSchema={validationSchema}
          addMeasurement={handleAddMeasurement}
          data={addMeasurementData}
          loading={isLoadingUserActions || addMeasurementLoading}
          error={addMeasurementError}
          genericInputData={genericInputData}
          inputTitle={inputTitle}
        />
      )}
    </Box>
  )
}

export default DiaryMeasurement