import {createSlice, PayloadAction, Reducer} from "@reduxjs/toolkit"

import {ConsultationDocumentationState} from "./consultationDocumentation.types"

const initialState: ConsultationDocumentationState = {
  items: {},
  loading: false,
  error: null,
}

export const consultationDocumentationSlice = createSlice({
  name: "consultationDocumentation",
  initialState,
  reducers: {
    getConsultationDocumentation: (state: ConsultationDocumentationState) => {
      state.loading = true
      state.error = null
      return
    },
    setConsultationDocumentation: (state: ConsultationDocumentationState, action: PayloadAction<ConsultationDocumentationState["items"]>) => {
      state.items = {...state.items, ...action.payload}
      return
    },
    resetConsultationDocumentationLoading: (state: ConsultationDocumentationState) => {
      state.loading = false
      return
    },
    setConsultationDocumentationError: (state: ConsultationDocumentationState, action: PayloadAction<ConsultationDocumentationState["error"]>) => {
      state.error = action.payload
      state.loading = false
      return
    },
    clearConsultationDocumentation: () => {
      return {...initialState}
    }
  },
})

export const {
  getConsultationDocumentation,
  setConsultationDocumentation,
  resetConsultationDocumentationLoading,
  setConsultationDocumentationError,
  clearConsultationDocumentation
} = consultationDocumentationSlice.actions

export default consultationDocumentationSlice.reducer as Reducer<typeof initialState>
