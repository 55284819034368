import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

export const useDocumentationPanelBoxStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      listStyleType: "none",
      padding: 0,
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    pageButton: {
      margin: theme.spacing(0.5),
    },
    paginationContainer: {
      marginTop: theme.spacing(2),
      maxWidth: "fit-content",
      margin: "auto",
    },
  })
)
