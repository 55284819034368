import { createTheme } from "@material-ui/core"

import { overrides } from "./overrides/overrides"
import { props } from "./props/props"
import { breakpoints } from "./breakpoints"
import { mixins } from "./mixins"
import { palette } from "./palette"
import { typography } from "./typography"
import { zIndex } from "./zIndex"

const theme = createTheme({
  palette,
  mixins,
  typography,
  shape: {
    borderRadius: 24,
    buttonBorderRadius:24,
  },
  breakpoints,
  props: props,
  overrides: overrides,
  zIndex
})

export default theme
