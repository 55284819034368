import {isBefore} from "date-fns"

import {startOfDay} from "./startOfDay"

export const isBeforeDate = (date: Date, compareDate: Date): boolean => {
  const startOfDayDate = startOfDay(date)
  const startOfDayCompareDate = startOfDay(compareDate)

  return isBefore(startOfDayDate, startOfDayCompareDate)
}
