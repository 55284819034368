import { ConsultationListItemModel } from "../../components/consultation/Consultation.types"
import { ConsultationExamReferral } from "../../components/exam/Exam.types"
import {RootState} from "../store.types"
import { ExamReferralsState } from "./examReferrals.types"

export const selectExamReferrals: ((state: RootState) => ExamReferralsState["items"]) = ({examReferrals}) => examReferrals.items

export const selectExamReferralsConsultationsIds: ((state: RootState) => ConsultationListItemModel["id"][]) = ({examReferrals}) => Object.keys(examReferrals.items)

export const selectConsultationExamReferralsItems: ((
  state: RootState,
  consultationId: ConsultationListItemModel["id"],
) => ConsultationExamReferral[]) = (
  {examReferrals},
  consultationId,
) => examReferrals.items[consultationId]

export const selectExamReferralsLoading: ((state: RootState) => ExamReferralsState["loading"]) = ({examReferrals}) => examReferrals.loading
