import { AxiosResponse } from "axios"
import i18n from "i18next"
import { SagaIterator } from "redux-saga"
import { all, AllEffect,CallEffect, select, takeLatest } from "redux-saga/effects"
import {call} from "redux-saga/effects"
import {put} from "redux-saga/effects"

import api from "../../api/api"
import { getMedicalDocumentsConfig } from "../../api/routes"
import { selectLatestConsultations } from "../consultations/consultations.selectors"
import { ConsultationListItemModel, FINISHED_WITH_SUCCESS_CONSULTATION_STATUS_NUMBERS, } from "../../components/consultation/Consultation.types"
import { MedicalDocuments } from "../../components/documentation/Documentation.type"

import { selectConsultationDocumentationConsultationsIds } from "./consultationDocumentation.selectors"
import {
  getConsultationDocumentation,
  resetConsultationDocumentationLoading,
  setConsultationDocumentation,
  setConsultationDocumentationError
} from "./consultationDocumentation.slice"

type ConsultationMedicalDocumentsResponse = {data: {items: MedicalDocuments[]}}

async function getConsultationMedicalDocuments(consultationId: ConsultationListItemModel["id"]) {
  return api.request<ConsultationMedicalDocumentsResponse>({...getMedicalDocumentsConfig, params: {
    consultation: consultationId,
    lang: i18n.language
  }})
}

function* getConsultationDocumentationSaga() {
  const consultations: ReturnType<typeof selectLatestConsultations> = yield select(selectLatestConsultations)
  const medicalDocumentsConsultationIds: ReturnType<typeof selectConsultationDocumentationConsultationsIds> = yield select(selectConsultationDocumentationConsultationsIds)
  const newConsultations = consultations.filter(consultation => !medicalDocumentsConsultationIds.includes(consultation.id))

  // since we filter out consultationIds that already have medical documents loaded (line 25)
  // we need to ensure to do not try to load documents that are already in loading state (not loaded yet)
  for (const consultation of newConsultations) {
    yield put(setConsultationDocumentation({
      [consultation.id]: {}
    }))
  }

  const requests = newConsultations.reduce((
    requestsObject: Record<ConsultationListItemModel["id"], CallEffect<AxiosResponse<ConsultationMedicalDocumentsResponse>>>,
    consultation: ConsultationListItemModel
  ) => {
    // make request only for finished with success consultations
    if (!FINISHED_WITH_SUCCESS_CONSULTATION_STATUS_NUMBERS.includes(consultation.status_number)) {
      return requestsObject
    }
    return {
      ...requestsObject,
      [consultation.id]: call(getConsultationMedicalDocuments, consultation.id),
    }
  }, {})
  try {
    const responses: AllEffect<Record<ConsultationListItemModel["id"], AxiosResponse<ConsultationMedicalDocumentsResponse>>> = yield all(requests)

    const medicalDocuments: Record<ConsultationListItemModel["id"], MedicalDocuments> = {}
    for (const [key, value] of Object.entries(responses)) {
      medicalDocuments[key] = value.data.items
    }

    yield put(setConsultationDocumentation(medicalDocuments))
    yield put(resetConsultationDocumentationLoading())
  } catch (e) {
    yield put(setConsultationDocumentationError({
      data: e.response.data,
      status: e.response.status,
    }))
  }
}

export default function* (): SagaIterator {
  yield takeLatest(getConsultationDocumentation, getConsultationDocumentationSaga)
}
