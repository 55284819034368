import React, {FC} from "react"
import {Box, Typography} from "@material-ui/core"
import clsx from "clsx"

import Breast1 from "../../../../../../../assets/images/breast1.png"
import Breast2 from "../../../../../../../assets/images/breast2.png"
import Breast3 from "../../../../../../../assets/images/breast3.png"
import Breast4 from "../../../../../../../assets/images/breast4.png"
import Breast5 from "../../../../../../../assets/images/breast5.png"
import Breast6 from "../../../../../../../assets/images/breast6.png"
import Breast7 from "../../../../../../../assets/images/breast7.png"
import ImageByIntegrationType from "../../../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import {useBreastExaminationDescriptionStyles} from "./BreastExaminationDescription.styles"

//brak tłumaczeń docelowo kontent będzie zwracany z BE
//todo: https://telemedico.atlassian.net/browse/LESS-1615
const BreastExaminationDescription: FC = () => {
  const classes = useBreastExaminationDescriptionStyles()

  return (
    <Box className={classes.container}>
      <Typography
        variant="h4"
        className={clsx(classes.header, classes.boldFont)}
      >
        Pamiętaj o tym, że możesz ograniczyć ryzyko zachorowania na raka piersi poprzez:
      </Typography>
      <Typography
        component="ul"
        className={classes.marginBottom}
      >
        <li>
          badania profilaktyczne:
          <ul>
            <li>
              samobadanie piersi raz w miesiącu u wszystkich kobiet od 20. roku życia,
            </li>
            <li>
              regularne wizyty i badanie u lekarza,
            </li>
            <li>
              mammografia co 2 lata u kobiet miedzy 50 a 69 rokiem życia oraz odpowiednia częstotliwość USG lub mammografii piersi u kobiet 50 i 70 roku życia,
            </li>
          </ul>
        </li>
        <li>
          zdrowy tryb życia:
          <ul>
            <li>
              utrzymywanie prawidłowej wagi,
            </li>
            <li>
              aktywność fizyczna,
            </li>
            <li>
              ograniczanie spożywania alkoholu.
            </li>
          </ul>
        </li>
      </Typography>
      <Typography
        variant="h4"
        className={classes.boldFont}
      >
        Regularnie wykonuj samobadanie piersi, najlepiej raz na miesiąc. Dzięki regularnemu samobadaniu piersi szybko wyczujesz ewentualną różnicę.
      </Typography>
      <Typography
        variant="h4"
        className={clsx(classes.header, classes.boldFont)}
      >
        Powinny cię zaniepokoić wszelkie zmiany w piersiach, których wcześniej nie wykryłaś, np.:
      </Typography>
      <Typography
        component="ul"
        className={classes.marginBottom}
      >
        <li>
          zmianę wielkości i/lub kształtu piersi, nieustępująca po miesiączce,
        </li>
        <li>
          guzki i/lub zgrubienia wyczuwalne w piersi lub pod pachą,
        </li>
        <li>
          zmianę koloru lub wciąganie skóry piersi,
        </li>
        <li>
          pomarszczenie lub łuszczenie skóry piersi,
        </li>
        <li>
          zmiany w obrębie brodawki piersi.
        </li>
      </Typography>
      <Typography
        variant="h4"
        className={classes.boldFont}
      >
        Jeśli cokolwiek Cię zaniepokoi - umów się na wizytę u lekarza.
      </Typography>
      <Typography
        variant="h4"
        className={clsx(classes.header, classes.boldFont)}
      >
        Badanie według poniższego schematu wykonuj najlepiej od trzeciego do piątego dnia po miesiączce.
      </Typography>
      <Typography
        variant="h4"
        className={clsx(classes.header, classes.boldFont)}
      >
        Jak poprawnie wykonać samobadanie piersi?
      </Typography>
      <Box
        className={classes.examinationStep}
      >
        <ImageByIntegrationType
          imageSrc={Breast1}
          alt="breast1"
          className={classes.image}
          imagePath={"breast1.png"}
        />
        <Typography
          className={classes.boldFont}
        >
          1. Stań przed lustrem. Unieś ręce do góry.
          Pierwsze dwa etapy to oglądanie własnych piersi.
          Czy widzisz w piersiach coś czego do tej pory nie zauważyłaś, jakiekolwiek zmiany?
        </Typography>
      </Box>
      <Box
        className={clsx(classes.reverseFlex, classes.examinationStep)}
      >
        <ImageByIntegrationType
          imageSrc={Breast2}
          alt="breast2"
          className={classes.image}
          imagePath={"breast2.png"}
        />
        <Typography
          className={classes.boldFont}
        >
          2. Połóż ręce na biodrach. Ponownie obejrzyj dokładnie piersi.
          Czy widzisz w piersiach coś czego do tej pory nie zauważyłaś, jakiekolwiek zmiany?
        </Typography>
      </Box>
      <Box
        className={classes.examinationStep}
      >
        <ImageByIntegrationType
          imageSrc={Breast3}
          alt="breast3"
          className={classes.image}
          imagePath={"breast3.png"}
        />
        <Typography>
          <Typography
            component="span"
            className={classes.boldFont}
          >
            3. Trzeci etap polega na palpacyjnym sprawdzaniu piersi.
            Unieś lewą rękę nad głowę, złączonymi trzema środkowymi palcami prawej dłoni sprawdź dokładnie lewą pierś.
            Zrób to w trzech etapach:
          </Typography>
          <ul>
            <li className={classes.listItem}>
              <ImageByIntegrationType
                imageSrc={Breast5}
                alt="Breast5"
                className={classes.smallImage}
                imagePath={"breast5.png"}
              />
              od środka piersi na zewnątrz ruchami okrężnymi,
            </li>
            <li className={classes.listItem}>
              <ImageByIntegrationType
                imageSrc={Breast6}
                alt="Breast6"
                className={classes.smallImage}
                imagePath={"breast6.png"}
              />
              od góry do dołu,
            </li>
            <li className={classes.listItem}>
              <ImageByIntegrationType
                imageSrc={Breast7}
                alt="Breast7"
                className={classes.smallImage}
                imagePath={"breast7.png"}
              />
              od środka piersi na zewnątrz promienistymi ruchami.
            </li>
          </ul>
          <Typography
            component="span"
            className={classes.boldFont}
          >
            To samo powtórz dla drugiej piersi.
          </Typography>
        </Typography>
      </Box>
      <Box
        className={clsx(classes.reverseFlex, classes.examinationStep)}
      >
        <ImageByIntegrationType
          imageSrc={Breast4}
          alt="breast4"
          className={classes.image}
          imagePath={"breast4.png"}
        />
        <Typography>
          <Typography
            component="span"
            className={classes.boldFont}
          >
            4. Połóż się na plecach.
          </Typography>
          <Typography
            component="span"
            className={classes.boldFont}
          >
            Trzema środkowymi palcami prawej dłoni sprawdź dokładnie lewą pierś.
          </Typography>
          {" "}
          <Typography
            component="span"
            className={classes.boldFont}
          >
            Zrób to w trzech etapach:
          </Typography>
          <ul>
            <li>od środka piersi na zewnątrz ruchami okrężnymi,</li>
            <li>od góry do dołu,</li>
            <li>od środka piersi na zewnątrz promienistymi ruchami.</li>
          </ul>
          <Typography
            component="span"
            className={classes.boldFont}
          >
            To samo powtórz dla drugiej piersi.
          </Typography>
        </Typography>
      </Box>
    </Box>
  )
}

export default BreastExaminationDescription
