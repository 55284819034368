import {RootState} from "../store.types"
import {MedicalExamsPointsState} from "./medicalExamsPoints.types"

export const selectMedicalExamsPointsItems: ((state: RootState) => MedicalExamsPointsState["items"]) = ({medicalExamsPoints}) => {
  return medicalExamsPoints.items
}

export const selectMedicalExamsPointsLoading: ((state: RootState) => MedicalExamsPointsState["loading"]) = ({medicalExamsPoints}) => {
  return medicalExamsPoints.loading
}

export const selectMedicalExamsPointsItemsCoords: ((state: RootState) => MedicalExamsPointsState["itemsCoords"]) = ({medicalExamsPoints}) => {
  return medicalExamsPoints.itemsCoords
}

export const selectMedicalExamsPointsGeocodeLoading: ((state: RootState) => MedicalExamsPointsState["geocodeLoading"]) = ({medicalExamsPoints}) => {
  return medicalExamsPoints.geocodeLoading
}

export const selectMedicalExamsPointsError: ((state: RootState) => boolean) = ({medicalExamsPoints}) => {
  return !!medicalExamsPoints.error || !!medicalExamsPoints.geocodeError
}

export const selectChosenLaboratory: ((state: RootState) => MedicalExamsPointsState["chosenLaboratory"]) = ({medicalExamsPoints}) => {
  return medicalExamsPoints.chosenLaboratory
}
