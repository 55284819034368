import { dates } from "./common/dates"
import awards from "./lokalise/pl/awards.json"
import b2bClinic from "./lokalise/pl/b2bClinic.json"
import calendar from "./lokalise/pl/calendar.json"
import chatConsultation from "./lokalise/pl/chatConsultation.json"
import consultation from "./lokalise/pl/consultation.json"
import consultationNestedMenu from "./lokalise/pl/consultationNestedMenu.json"
// files imported automaticaly from https://lokalise.com/
import defaultTranslations from "./lokalise/pl/defaultTranslations.json"
import diaryMeasurement from "./lokalise/pl/diaryMeasurement.json"
import diseaseManagement from "./lokalise/pl/diseaseManagement.json"
import documents from "./lokalise/pl/documents.json"
import emptyConsultationsBox from "./lokalise/pl/emptyConsultationsBox.json"
import errors from "./lokalise/pl/errors.json"
import exam from "./lokalise/pl/exam.json"
import finances from "./lokalise/pl/finances.json"
import forgotPassword from "./lokalise/pl/forgotPassword.json"
import healthPlanner from "./lokalise/pl/healthPlanner.json"
import healthPlannerActions from "./lokalise/pl/healthPlannerActions.json"
import laboratory from "./lokalise/pl/laboratory.json"
import login from "./lokalise/pl/login.json"
import loginPagePopup from "./lokalise/pl/loginPagePopup.json"
import medicalQuestionnaire from "./lokalise/pl/medicalQuestionnaire.json"
import mentalHealth from "./lokalise/pl/mentalHealth.json"
import notifications from "./lokalise/pl/notifications.json"
import orderMedicines from "./lokalise/pl/orderMedicines.json"
import pageFooter from "./lokalise/pl/pageFooter.json"
import passwordless from "./lokalise/pl/passwordless.json"
import payments from "./lokalise/pl/payments.json"
import prescription from "./lokalise/pl/prescription.json"
import preventionQuestionnaires from "./lokalise/pl/preventionQuestionnaires.json"
import recommendations from "./lokalise/pl/recommendations.json"
import referrals from "./lokalise/pl/referrals.json"
import sessionManager from "./lokalise/pl/sessionManager.json"
import stagingSettings from "./lokalise/pl/stagingSettings.json"
import user from "./lokalise/pl/user.json"
import videoChatConsultation from "./lokalise/pl/videoChatConsultation.json"
// e/o files imported automaticaly from https://lokalise.com/

const pl = {
  medicalQuestionnaire,
  defaultTranslations,
  dates,
  consultation,
  chatConsultation,
  videoChatConsultation,
  user,
  recommendations,
  exam,
  prescription,
  emptyConsultationsBox,
  login,
  loginPagePopup,
  forgotPassword,
  referrals,
  calendar,
  errors,
  sessionManager,
  notifications,
  documents,
  finances,
  pageFooter,
  laboratory,
  stagingSettings,
  payments,
  b2bClinic,
  orderMedicines,
  preventionQuestionnaires,
  awards,
  diaryMeasurement,
  healthPlannerActions,
  diseaseManagement,
  healthPlanner,
  consultationNestedMenu,
  passwordless,
  mentalHealth
}

export default pl
