import React, {FC} from "react"
import {useParams} from "react-router"
import {Box} from "@material-ui/core"

import {
  useGetConsultationDocumentationQuery
} from "../../store/consultationDocumentation/consultationDocumentation.api"
import {useGetDozHashQuery} from "../../store/dozHash/dozHash.api"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import OrderMedicinesForm from "../../components/orderMedicinesForm/OrderMedicinesForm.component"
import {useOrderMedicinesPageStyles} from "./OrderMedicinesPage.styles"

interface OrderMedicinesPageProps {}

const OrderMedicinesPage: FC<OrderMedicinesPageProps> = () => {
  const classes = useOrderMedicinesPageStyles()
  const {consultationId} = useParams<{ consultationId: string }>()
  const {data, isLoading} = useGetConsultationDocumentationQuery(consultationId)
  const {isLoading: isDozHashLoading} = useGetDozHashQuery()

  return (
    <Box className={classes.root}>
      {
        (isLoading || !data || isDozHashLoading) ? (
          <LoaderBox boxType="fullPage"/>
        ) : (
          <OrderMedicinesForm
            medicineEprescriptions={data.medicine_eprescriptions}
          />
        )
      }
    </Box>
  )
}

export default OrderMedicinesPage
