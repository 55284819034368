import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import AppDialog from "../../common/appDialog/AppDialog.component"

interface CreateRoomErrorProps {
  createRoomError: boolean;
  onClose(): void;
}

const CreateRoomError: FC<CreateRoomErrorProps> = ({createRoomError, onClose}) => {
  const {t} = useTranslation()

  return (
    <AppDialog // create room error info
      open={createRoomError}
      onClose={onClose}
      closeButtonText={t("close")}
      title={t("consultation:archiveTags:otherError")}
    >
      <Box mb={2}>
        <Typography>
          {t("errors:unknownError")}
        </Typography>
      </Box>
    </AppDialog>
  )
}

export default CreateRoomError
