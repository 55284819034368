import {gql} from "@apollo/client"

import {Disease} from "./getUserDiseasesFilteredByIds"

export const GET_DISEASES_BY_DISEASES_IDS = gql`
  query Diseases($diseasesIds: [uuid!]) {
    diseases(where: {id: {_nin: $diseasesIds }}) {
      name
      imageUrl: image_url
      onlyForGender: only_for_gender
      id
    }
}
`

export type GetDiseasesByDiseasesIdsResponse = {
  diseases: Disease[];
}

export type GetDiseasesByDiseasesIdsVariables = {
  diseasesIds: string[];
}