import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Box, Grid, Typography, useTheme } from "@material-ui/core"
import clsx from "clsx"

import allianzOnlineVisitImg from "../../assets/images/allianz/book-consultation.png"
import allianzPrescriptionVisitImg from "../../assets/images/allianz/book-prescription.png"
import allianzStationaryVisitImg from "../../assets/images/allianz/book-stationary.png"
import examinationImg from "../../assets/images/examination.png"
import onlineConsultationImg from "../../assets/images/onlineConsultation.png"
import onSiteConsultationImg from "../../assets/images/onSiteConsultation.png"
import { useAppSelector } from "../../hooks/storeHooks"
import useGoToConsultationPage from "../../hooks/useGoToConsultationPage"
import { selectClinicSettings } from "../../store/clinic/clinicSettings.selectors"
import { selectUser } from "../../store/user/user.selectors"
import LoaderBox from "../common/loaderBox/LoaderBox.component"
import SimpleCard from "../common/simpleCard/SimpleCard.component"
import NoMakeConsultationOptionEmptyBox from "./NoMakeConsultationOptionEmptyBox.component"
import { checkFeatureAvailability } from "../../store/clinic/clinicSettings.utils"
import {
  ALLIANZ_EWKA_INTEGRATION_TYPE,
  ALLIANZ_ONYX_INTEGRATION_TYPE,
  getIntegrationType
} from "../../utils/getIntegrationType"
import { isUserAdult } from "../../utils/isUserAdult"
import { RoutePath } from "../../routes/Routes.types"
import { ConsultationWidgetVisitDatePicker } from "../../store/makeConsultationWidget/makeConsultationWidget.types"
import { useEmptyConsultationsBoxStyles } from "./EmptyConsultationsBox.styles"

interface EmptyConsultationsBoxProps {
  B2BClinic: boolean;
}

const EmptyConsultationsBox: FC<EmptyConsultationsBoxProps> = ({B2BClinic}) => {
  const theme = useTheme()
  const classes = useEmptyConsultationsBoxStyles()
  const { t, i18n } = useTranslation()
  const { push } = useHistory()
  const goToConsultationPage = useGoToConsultationPage()
  const clinicSettings = useSelector(selectClinicSettings)
  const userData = useSelector(selectUser)
  const hideConsultationBooking = checkFeatureAvailability().hideConsultationBooking
  const goToStationaryPozConsultationPage = useGoToConsultationPage({
    visitDatePicker: ConsultationWidgetVisitDatePicker.STATIONARY_VISIT_DATA_PICKER
  })

  const { clinicAllianzSettings: {
    ewkaVerificationEnabled,
    onyxVerificationEnabled
  } } = useAppSelector(selectClinicSettings)
  
  const integrationType = getIntegrationType(
    false,
    false,
    ewkaVerificationEnabled,
    onyxVerificationEnabled
  )

  const isAllianz = integrationType === ALLIANZ_ONYX_INTEGRATION_TYPE
      || integrationType === ALLIANZ_EWKA_INTEGRATION_TYPE

  const isUserUnderAge = !isAllianz || !userData || !isUserAdult(userData.pesel, userData.birth_date)
  const [isAdult, setIsAdult] = useState<boolean>(!isUserUnderAge)

  useEffect(() => {
    if (userData && isAllianz) {
      setIsAdult(!isUserUnderAge)
    }
  }, [userData, isAllianz])

  if (!clinicSettings.hasBookConsultationAbility || hideConsultationBooking) {
    return <NoMakeConsultationOptionEmptyBox />
  }

  if (B2BClinic && !isAllianz) {
    return <LoaderBox />
  }

  const getIntro = () => {
    return isAllianz 
      ? t("emptyConsultationsBox:allianz:whatIsNeeded") 
      : t("emptyConsultationsBox:whatIsNeeded")
  }

  const redirectTPrescription = () => {
    const goToPrescriptionOnlyConsultationPage = useGoToConsultationPage({
      visitDatePicker: ConsultationWidgetVisitDatePicker.PRESCRIPTION_ONLY,
    })

    return goToPrescriptionOnlyConsultationPage.goToMakeConsultationPage
  }

  const getColContentClasses = () => {
    if (isAllianz && !isAdult) {
      return clsx(classes.colContent, classes.colContentFullWidth)
    }

    return classes.colContent
  }
  
  const getOnlineVisit = () => {
    if (isAllianz) {
      return <SimpleCard
        intro={t("emptyConsultationsBox:allianz:fast")}
        title={t("emptyConsultationsBox:onlineConsultation")}
        imageSrc={allianzOnlineVisitImg}
        buttonText={t("emptyConsultationsBox:allianz:bookConsultation").toUpperCase()}
        className={getColContentClasses()}
        onButtonClick={goToConsultationPage.goToMakeConsultationPage}
        imagePath={"book-consultation.png"}
        integrationType={integrationType}
      />
    }

    return <SimpleCard
      intro={t("emptyConsultationsBox:fast")}
      title={t("emptyConsultationsBox:onlineConsultation")}
      imageSrc={onlineConsultationImg}
      buttonText={t("setDate")}
      className={getColContentClasses()}
      onButtonClick={goToConsultationPage.goToMakeConsultationPage}
      imagePath={"onlineConsultation.png"}
      integrationType={integrationType}
    />
  }
  
  const getExamVisit = () => {
    if (isAllianz) {
      return <SimpleCard
        intro={t("emptyConsultationsBox:allianz:closeToStationaryVisit")}
        title={t("emptyConsultationsBox:allianz:closeToStationaryVisitTitle")}
        imageSrc={allianzStationaryVisitImg}
        buttonText={t("emptyConsultationsBox:allianz:bookConsultation").toUpperCase()}
        className={getColContentClasses()}
        onButtonClick={() => push(`/${i18n.language}${RoutePath.MAKE_STATIONARY_CONSULTATION}`)}
        imagePath={"book-stationary.png"}
        integrationType={integrationType}
      />
    }
    
    return <SimpleCard
      intro={t("emptyConsultationsBox:closeToHome")}
      title={t("emptyConsultationsBox:getExamined")}
      imageSrc={examinationImg}
      buttonText={t("setDate")}
      className={getColContentClasses()}
      onButtonClick={() => {push(`/${i18n.language}/order-exams`)}}
      imagePath={"examination.png"}
      integrationType={integrationType}
    />
  }
  
  const getPozVisit = () => {
    let content = <SimpleCard
      intro={t("emptyConsultationsBox:onSitePOZ")}
      title={t("emptyConsultationsBox:onSitePOZConsultation")}
      imageSrc={onSiteConsultationImg}
      buttonText={t("setDate")}
      className={getColContentClasses()}
      onButtonClick={() => goToStationaryPozConsultationPage.goToMakeConsultationPage()}
      imagePath={"onSiteConsultation.png"}
      integrationType={integrationType}
    />
    
    if (isAllianz) {
      if (!isAdult) {
        return null
      }

      content = <SimpleCard
        intro={t("emptyConsultationsBox:allianz:closeToPrescription")}
        title={t("emptyConsultationsBox:allianz:closeToPrescriptionTitle")}
        imageSrc={allianzPrescriptionVisitImg}
        buttonText={t("emptyConsultationsBox:allianz:bookPrescription").toUpperCase()}
        className={getColContentClasses()}
        onButtonClick={redirectTPrescription()}
        imagePath={"book-prescription.png"}
        integrationType={integrationType}
      />
    }

    return <Grid
      item
      className={classes.col}
      xs={12}
      lg={4}
    >
      {content}
    </Grid>
  }

  return (
    <Box
      p={4}
      mb={6}
      borderRadius={theme.shape.borderRadius}
      component="section"
      bgcolor={theme.palette.background.paper}
      className={classes.root}
    >
      <Typography
        variant="h3"
        component="p"
        className={classes.intro}
      >
        {getIntro()}
      </Typography>

      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          className={classes.col}
          xs={12}
          lg={6}
        >
          {getOnlineVisit()}
        </Grid>

        <Grid
          item
          className={classes.col}
          xs={12}
          lg={6}
        >
          {getExamVisit()}
        </Grid>
        {getPozVisit()}
      </Grid>
    </Box>
  )
}

export default EmptyConsultationsBox
