import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useForceResetPasswordModalStyles = makeStyles(theme => createStyles({
  modal: {
    "& .MuiPaper-root": {
      [theme.breakpoints.up("sm")]: {
        width: 600
      }
    }
  }
}))
