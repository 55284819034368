import React, { FC } from "react"
import { useParams } from "react-router"

import { Action, ActionType } from "../../../api/queries/getUserActionById"
import EPrescription from "./ePrescription/EPrescription.component"
import FillQuestionnaire from "./fillQuestionnaire/FillQuestionnaire.component"
import MeasurementLog from "./measurementLog/MeasurementLog.component"
import MedicalExamination from "./medicalExamination/MedicalExamination.component"
import RemoteConsultation from "./remoteConsultation/RemoteConsultation.component"
import SignDeclaration from "./signDeclaration/SignDeclaration.component"
import YourTask from "./yourTask/YourTask.component"
import { HealthPreventionPrograms } from "../../../../../store/clinic/clinicSettings.types"

type PreventionActionCardProps = {
  action: Action;
}

const PreventionActionCard: FC<PreventionActionCardProps> = ({
  action
}) => {
  const { actionId } = useParams<{ actionId?: string }>()
  
  const getHealthPreventionProgramType = (action: Action) => {
    if (action.codeName.includes("MH")) {
      return HealthPreventionPrograms.PROGRAM_MENTAL_HEALTH
    }

    return null
  }

  switch (action.type) {
    case ActionType.REMOTE_CONSULTATION:
      return (
        <RemoteConsultation
          title={action.name}
          description={action.description}
          specializationId={action.remoteConsultation.specializationId}
        />
      )
    case ActionType.FILL_QUESTIONNAIRE:
      return (
        <FillQuestionnaire
          title={action.name}
          description={action.description}
          questionnaireId={action.fillQuestionnaire.questionnaireId}
          programType={getHealthPreventionProgramType(action)}
          actionId={actionId}
        />
      )
    case ActionType.YOUR_TASK:
      return (
        <YourTask
          title={action.name}
          actionId={action.id}
          codeName={action.codeName}
        />
      )
    case ActionType.MEASUREMENT_LOG:
      return (
        <MeasurementLog
          name={action.name}
          description={action.description}
          examinationType={action.measurementLog.examinationType}
          codeName={action.codeName}
        />
      )
    case ActionType.E_PRESCRIPTION:
      return (
        <EPrescription
          title={action.name}
          description={action.description}
          ePrescriptions={action.ePrescriptions}
        />
      )
    case ActionType.SIGN_DECLARATION:
      return (
        <SignDeclaration
          title={action.name}
          description={action.description}
        />
      )
    case ActionType.MEDICAL_EXAMINATION:
      return (
        <MedicalExamination
          title={action.name}
          description={action.description}
        />
      )
    default:
      return (
        <></>
      )
  }
}

export default PreventionActionCard