import React, {FC} from "react"

import GlobalFormErrorMessage from "../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import {usePasswordlessMagicLinkHandler} from "./PasswordlessErrorMessage.utils"

const PasswordlessErrorMessage: FC = () => {
  const errorMessage = usePasswordlessMagicLinkHandler()

  return errorMessage
    ? (
      <GlobalFormErrorMessage
        message={errorMessage}
      />
    ) : null
}

export default PasswordlessErrorMessage
