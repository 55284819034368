import {createStyles, makeStyles, Theme} from "@material-ui/core"

export const useLoyalityProgramBadgeStyles = makeStyles((theme: Theme) =>
  createStyles({
    loyalityBadgeWrapper: {
      margin: theme.spacing(0, 2, 0, 1),
      textDecoration: "none",
      color: theme.palette.text.primary
    }
  })
)
