import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/react"
import { routerMiddleware } from "connected-react-router"
import { createBrowserHistory } from "history"
import createSagaMiddleware from "redux-saga"

import createRootReducer from "./rootReducer"
import rootSaga from "./rootSaga"
import { dozService, googleApisService, ssoService, v4Service, wordpressService } from "./store.service"

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const sentryEnhancer = Sentry.createReduxEnhancer({})
const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        "examReferrals/setExamReferralsError",
        "exams/setExamsError",
        "logIn/logInError",
        "apiError/catchApiError",
        "consultationDocumentation/setConsultationDocumentationError",
        "patientDocumentation/setPatientDocumentationError",
        "medicalExamsPoints/setMedicalExamsPointsError",
        "medicalExamsPoints/setMedicalExamsPointsGeocodeError",
        "user/setUserDataError",
        "user/setUserAddressCoordsError",
      ],
    }
  }).concat(
    v4Service.middleware, 
    dozService.middleware, 
    googleApisService.middleware, 
    ssoService.middleware, 
    wordpressService.middleware
  ),
  routerMiddleware(history),
  sagaMiddleware,
]

const store = configureStore({
  reducer: createRootReducer(history),
  middleware,
  enhancers: [sentryEnhancer],
})

sagaMiddleware.run(rootSaga)

export default store
