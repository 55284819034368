import {createStyles, makeStyles} from "@material-ui/core"

export const useCheckboxGroupSurveyStyles = makeStyles((theme) => createStyles({
  field: {
    position: "relative",
    padding: theme.spacing(1, 1.5),
    margin: 0,
    "& .MuiCheckbox-root .MuiIconButton-label": {
      display: "none"
    },
    "& .MuiCheckbox-root": {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      padding: 0,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.spacing(1),
    },
    "& .MuiCheckbox-root.Mui-checked": {
      background: theme.palette.primary.main,
    },
    "& .MuiCheckbox-root + .MuiTypography-root": {
      fontSize: theme.typography.subtitle2.fontSize,
      color: theme.palette.primary.main,
      zIndex: 1,
    },
    "& .MuiCheckbox-root.Mui-checked + .MuiTypography-root": {
      color: theme.palette.background.paper,
    }
  },
}))
