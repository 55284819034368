import {makeStyles} from "@material-ui/core"

import {boxShadows} from "../../../themes/shadows"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useNfzStatusStyles = makeStyles((theme: Theme) => createStyles({
  popoverPaper: {
    maxWidth: "408px",
    padding: theme.spacing(4),
    top: `${theme.spacing(11)}px !important`,
    boxShadow: boxShadows.shadow1,
  },
  title: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))
