import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useLoginPageStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    minHeight: "100vh",
    display: "flex",
  },
  divider: {
    height: 1,
    width: "100%",
    background: theme.palette.grey["100"],
    margin: theme.spacing(3, 0)
  },
  pageBox: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6),
    },
    marginBottom: theme.spacing(3)
  },
  buttonWrapperBox: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "baseline",
      flexWrap: "nowrap",
    },
  },
  textLinkSection: {
    order: 1,
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(0),
      order: 0,
    },
  },
  doctorLoginSection: {
    marginTop: theme.spacing(0),
  },
  goToResetPageText: {
    marginRight: theme.spacing(1),
  },
  link: {
    ...theme.typography.body2,
    textDecoration: "underline",
  },
  registerText1: {
    marginRight: theme.spacing(.5),
  },
  registerLink: {
    color: "inherit",
    textDecoration: "underline",
  },
  logoLoginWrapper: {
    textAlign: "center",
  },
  logoLogin: {
    maxWidth: "100%",
    marginBottom: theme.spacing(4),
  },
  resetPasswordMsg: {
    fontSize: theme.spacing(2),
  }
}))
