import React, {FC} from "react"
import {Box, LinearProgress, Paper, Typography} from "@material-ui/core"

import Question from "./question/Question.component"
import {useGenericStepForm, useScrollHandler} from "./GenericStepForm.utils"
import {CompletedQuestionnaireTemplate, QuestionnaireTemplate} from "./GenericStepForm.types"
import {useGenericStepFormStyles} from "./GenericStepForm.styles"

type GenericStepFormProps = {
  questionnaireTemplate: QuestionnaireTemplate;
  onSubmit: (questionnaireTemplate: CompletedQuestionnaireTemplate) => Promise<unknown>;
  isLoading: boolean;
}

const GenericStepForm: FC<GenericStepFormProps> = ({
  questionnaireTemplate,
  onSubmit,
  isLoading,
}) => {
  const classes = useGenericStepFormStyles()
  const { containerRef, handleScrollToQuestion } = useScrollHandler()
  const {
    stepNumber,
    progress,
    completedQuestions,
    saveAnswer,
    goToPreviousQuestion
  } = useGenericStepForm(questionnaireTemplate, onSubmit)

  const { questions, name } = questionnaireTemplate
  const currentQuestion = questions[stepNumber]

  return (
    <Paper
      ref={containerRef}
      className={classes.container}
    >
      <Box className={classes.headerWrapper}>
        <Typography
          variant="h4"
          className={classes.title}
        >
          {name}
        </Typography>
        <LinearProgress
          className={classes.progress}
          variant="determinate"
          value={progress}
        />
      </Box>
      {
        currentQuestion && (
          <Question
            key={currentQuestion.id}
            data={currentQuestion}
            isLastQuestion={questions.length === stepNumber + 1}
            isFirstQuestion={stepNumber === 0}
            isLoading={isLoading}
            onScrollToQuestion={handleScrollToQuestion}
            onSaveAnswer={saveAnswer(stepNumber)}
            goToPreviousQuestion={goToPreviousQuestion}
            completedQuestion={completedQuestions[stepNumber]}
          />
        )
      }
    </Paper>
  )  
}

export default GenericStepForm
