import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Box, Button } from "@material-ui/core"
import clsx from "clsx"

import { useQueryParam } from "../../../../../../../hooks/useSearchParams"
import { dateTestIdMap } from "../../GenericStepForm.config"
import ButtonLoader from "../../../../../../../components/common/buttonLoader/ButtonLoader.component"
import { RoutePath } from "../../../../../../../routes/Routes.types"
import { HealthPreventionPrograms } from "../../../../../../../store/clinic/clinicSettings.types"
import { useActionButtonsStyles } from "./ActionButtons.styles"

type ActionButtonsProps = {
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  isLoading: boolean;
  isNextButtonDisabled: boolean;
  isSkippable: boolean;
  onClickNextButton: VoidFunction;
  onClickSkipButton: VoidFunction;
  onClickBackButton: VoidFunction;
}

const ActionButtons: FC<ActionButtonsProps> = ({
  isNextButtonDisabled,
  isLastQuestion,
  isFirstQuestion,
  isSkippable,
  onClickNextButton,
  onClickSkipButton,
  onClickBackButton,
  isLoading
}) => {
  const classes = useActionButtonsStyles()
  const { t } = useTranslation()
  const { push } = useHistory()
  const type = useQueryParam("type")
  const isMentalHealthProgram = type === HealthPreventionPrograms.PROGRAM_MENTAL_HEALTH
    
  const getBackRoute = () => {
    return isMentalHealthProgram 
      ? RoutePath.MENTAL_HEALTH 
      : RoutePath.PREVENTION
  }  

  return (
    <Box className={classes.container}>
      <Button
        data-testid={dateTestIdMap.goBackButton}
        className={clsx(classes.button, classes.marginRight)}
        color="primary"
        variant="outlined"
        onClick={
          isFirstQuestion
            ? () => push(getBackRoute())
            : onClickBackButton
        }
        disabled={isLoading}
      >
        {t("preventionQuestionnaires:back")}
      </Button>
      <Button
        className={clsx(
          classes.button,
          classes.marginRight,
          classes.marginBottom,
          isSkippable ? "" : classes.hidden
        )}
        color="secondary"
        variant="contained"
        onClick={onClickSkipButton}
        disabled={isLoading}
      >
        {t("healthPlanner:skip")}
      </Button>
      <Button
        data-testid={dateTestIdMap.submitButton}
        className={clsx(classes.button, classes.marginRight, classes.marginBottom)}
        color="primary"
        variant="contained"
        disabled={isNextButtonDisabled || isLoading}
        onClick={onClickNextButton}
        startIcon={isLoading && (<ButtonLoader position="prefix"/>)}
      >
        {t(isLastQuestion ? "preventionQuestionnaires:send" : "preventionQuestionnaires:next")}
      </Button>
    </Box>
  )  
}

export default ActionButtons
