
import {v4Service} from "../store.service"
import {Prescription} from "./prescriptions.types"

const prescriptionsApi = v4Service.injectEndpoints({
  endpoints: build => ({
    getPrescriptions: build.query<Prescription[], string>({
      query: (name) => ({
        url: "/api/v2/eprescription/search",
        method: "POST",
        data: {
          name,
        }
      }),
    }),
  })
})

export const { useGetPrescriptionsQuery } = prescriptionsApi
