import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useAcceptRegulationsModalStyles = makeStyles((theme: Theme) => createStyles({
  checkbox: {
    alignSelf: "flex-start",
    paddingTop: 0,
  },
  confirmButton: {
    marginLeft: theme.spacing(1),
  },
}))
