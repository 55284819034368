import React, {FC, useEffect, useState} from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import { useHistory } from "react-router"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography
} from "@material-ui/core"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"

import api from "../../api/api"
import { patchUserPersonalDataConfig } from "../../api/routes"
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks"
import useGetFormValidationRules from "../../hooks/useGetFormValidationRules"
import { selectClinicSettings } from "../../store/clinic/clinicSettings.selectors"
import { selectUserId } from "../../store/session/session.selectors"
import {selectUser} from "../../store/user/user.selectors"
import { getUserData } from "../../store/user/user.slice"
import AppDialog from "../common/appDialog/AppDialog.component"
import ButtonLoader from "../common/buttonLoader/ButtonLoader.component"
import SafeDataInfoLabel from "../common/safeDataInfoLabel/SafeDataInfoLabel.component"
import PhoneCountryCodeSelect from "../commonFormItems/phoneCountryCodeSelect/PhoneCountryCodeSelect.component"
import UserRegulations from "../user/userProfile/userRegulations/UserRegulations.component"
import { handleReactHookFormErrors, redirectToError500Page } from "../../utils/handleErrors"
import { DefaultRegulationsLinks } from "../user/userProfile/userRegulations/UserRegulations.utils"
import { RoutePath } from "../../routes/Routes.types"
import { useAcceptRegulationsModalStyles } from "./AcceptRegulationsModal.styles"

import { AcceptRegulationsValidationSchema } from "./AcceptRegulationsModal.validation"

type AcceptRegulationsModalProps = {
  isOpen: boolean;
}

const AcceptRegulationsModal: FC<AcceptRegulationsModalProps> = ({ 
  isOpen,
}) => {
  const {t, i18n} = useTranslation()
  const userId = useAppSelector(selectUserId)
  const dispatch = useAppDispatch()
  const { checkbox, confirmButton } = useAcceptRegulationsModalStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState<boolean>(localStorage.getItem("UPDATE_PHONE_NUMBER") === "true")
  const { push } = useHistory()
  const {collectMedicalDocument, widgetRegisterSettings: { linkToRegulations }, frontendSettings: { modules }} = useAppSelector(selectClinicSettings)
  const isMarketingAgreeDisabled = modules.includes("remove_marketing_consent_sections_on_patient_registration")

  const handleChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setValue("phoneNumber", event.target.value)
  }

  const defaultValues = {
    termsAndConditions: false,
    mailingAgree: false,
    medicalDocumentAgree: false,
  }

  const userData = useAppSelector(selectUser)

  if (updatePhoneNumber) {
    defaultValues["phoneNumber"] = userData?.phone_national_number
    defaultValues["phoneCountryCode"] = userData?.phone_country
  }

  const form = useForm({
    defaultValues: defaultValues,
    mode: "onSubmit",
    resolver: useGetFormValidationRules(AcceptRegulationsValidationSchema),
  })

  const regulationsAreAccepted = collectMedicalDocument ? form.watch("medicalDocumentAgree") && form.watch("termsAndConditions") : form.watch("termsAndConditions")

  const handleSubmit = form.handleSubmit(async (values) => {
    setLoading(true)
    try {
      const patchValues = {
        termsAccepted: values.termsAndConditions,
        mailingAgree: values.mailingAgree,
        medicalDocumentAgree: collectMedicalDocument ? values.medicalDocumentAgree : undefined
      }

      if(updatePhoneNumber) {
        patchValues["phoneNumber"] = {
          country: values?.phoneCountryCode,
          number: values?.phoneNumber
        }
      }

      await api.request({
        ...patchUserPersonalDataConfig(userId), data: patchValues,
      })
      await dispatch(getUserData({id: userId}))
      localStorage.setItem("UPDATE_PHONE_NUMBER", null)
      setUpdatePhoneNumber(false)
    } catch (e) {
      console.error(e)

      redirectToError500Page(e)
      if (e.response?.data?.errors) {
        if(e.response?.data?.errors?.["phoneNumber"]) {
          localStorage.setItem("UPDATE_PHONE_NUMBER", "true")
          setUpdatePhoneNumber(true)
          setLoading(false)
        }
        handleReactHookFormErrors(e.response.data.errors, form)
      }
    }
  })

  useEffect(() => {
    if (userData) {
      form.setValue("phoneNumber",userData?.phone_national_number)
      form.setValue("phoneCountryCode",userData?.phone_country)
    }
  }, [updatePhoneNumber])

  const goToPossibilitiesPage = () => {
    push(`/${i18n.language}${RoutePath.POSSIBILITIES}`)
  }

  return (
    <AppDialog
      open={isOpen}
      onClose={goToPossibilitiesPage}
      title={t("user:regulationsModalTitle")}
      closeButton={false}
      maxWidth="xs"
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        <Typography
          variant="body1"
          color="textSecondary"
        >
          {t("user:regulationsModalSubtitle")}
        </Typography>

        <FormProvider {...form}>
          <Box
            component='form'
            onSubmit={handleSubmit}
            mt={4}
          >
            <Controller
              name="termsAndConditions"
              render={({
                field: {onChange},
                fieldState: {error},
              }) => (
                <Box mb={1.5}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={checkbox}
                        onChange={onChange}
                        checkedIcon={<CheckBoxIcon color="primary"/>}
                        icon={<CheckBoxOutlineBlankIcon />}
                        size="medium"
                        disabled={loading}
                      />
                    }
                    label={(
                      <Typography
                        variant="body2"
                        color="textPrimary"
                      >
                        <Trans
                          i18nKey="user:regulationsAndPrivacyPolicy"
                        >
                          Akceptuję&nbsp;
                          <Link 
                            color="textPrimary"
                            href={linkToRegulations || DefaultRegulationsLinks().regulations}
                            target="_blank"
                            underline="always"
                          >
                            Regulamin Serwisu&nbsp;
                          </Link>
                            oraz&nbsp;
                          <Link
                            color="textPrimary"
                            href={DefaultRegulationsLinks().privacyPolicy}
                            target="_blank"
                            underline="always"
                          >
                            Politykę Prywatności
                          </Link>
                          *
                        </Trans>
                      </Typography>
                    )}
                  />
                  <FormHelperText>
                    { error?.message }
                  </FormHelperText>
                </Box>
              )}
            />

            { !isMarketingAgreeDisabled && (
              <Controller
                name="mailingAgree"
                render={({
                  field: {onChange},
                }) => (
                  <Box mb={1.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={checkbox}
                          onChange={onChange}
                          checkedIcon={<CheckBoxIcon color="primary"/>}
                          icon={<CheckBoxOutlineBlankIcon />}
                          size="medium"
                          disabled={loading}
                        />
                      }
                      label={(
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          {t("user:mailingAgree")}
                        </Typography>
                      )}
                    />
                  </Box>
                )}
              />
            )}

            {
              collectMedicalDocument && (
                <Controller
                  name="medicalDocumentAgree"
                  render={({
                    field: {onChange},
                    fieldState: {error},
                  }) => (
                    <Box mb={1.5}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={checkbox}
                            onChange={onChange}
                            checkedIcon={<CheckBoxIcon color="primary"/>}
                            icon={<CheckBoxOutlineBlankIcon />}
                            size="medium"
                            disabled={loading}
                          />
                        }
                        label={(
                          <Typography
                            variant="body2"
                            color="textPrimary"
                          >
                            {t("user:regulationsAdditionalRegulation")}*
                          </Typography>
                        )}
                      />
                      <FormHelperText>
                        { error?.message }
                      </FormHelperText>
                    </Box>
                  )}
                />
              )}
            { updatePhoneNumber && (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant="h6"
                    color="textPrimary"
                  >
                    {t("user:phoneNumberUpdate")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="phoneCountryCode"
                    control={form.control}
                    render={({
                      field: {onChange, value},
                      fieldState: {invalid, error}
                    }) => (
                      <PhoneCountryCodeSelect
                        id="phoneCountryCode"
                        label={t("user:phoneCountryCode")}
                        placeholder={t("user:phoneCountryCode")}
                        fullWidth
                        disabled={loading}
                        value={value}
                        onChange={onChange}
                        error={invalid}
                        helperText={error?.message}
                        InputProps={{endAdornment: null}}
                        name="phoneNumber"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="phoneNumber"
                    control={form.control}
                    render={({
                      field: {onChange, value},
                      fieldState: {invalid}
                    }) => (
                      <TextField
                        id="phoneNumber"
                        name="phoneNumber"
                        label={t("user:phone")}
                        placeholder={t("user:phone")}
                        fullWidth
                        value={value}
                        disabled={loading}
                        error={invalid}
                        onChange={updatePhoneNumber ? handleChangePhoneNumber : onChange}
                        helperText={invalid ? t("user:wrongStationaryPhoneNumber") : ""}
                        InputProps={{endAdornment: null}}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) }
        
            <SafeDataInfoLabel />

            <Box
              display="flex"
              justifyContent="flex-end"
              my={4}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={goToPossibilitiesPage}
              >
                {t("user:cancelButton")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={confirmButton}
                startIcon={loading && (<ButtonLoader position="prefix"/>)}
                disabled={loading || !regulationsAreAccepted}
              >
                {t("user:confirmButton")}
              </Button>
            </Box>
          </Box>
        </FormProvider>
        <Box ml="auto" mr="auto">
          <UserRegulations />
        </Box>
      </Box>
    </AppDialog>
  )
}

export default AcceptRegulationsModal
