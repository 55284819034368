import {postPasswordlessConsultationMagiclink, postPasswordlessMagiclink} from "../../api/routes"
import {v4Service} from "../store.service"

export const passwordlessMagiclinkApi = v4Service.injectEndpoints({
  endpoints: build => ({
    postPasswordlessMagiclink: build.mutation<{ id: string }, string>({
      query: (userId) => ({
        ...postPasswordlessMagiclink(userId),
      }),
    }),
    postPasswordlessConsultationMagiclink: build.mutation<{ id: string }, { userId: string, consultationId: string }>({
      query: ({userId, consultationId}) => ({
        ...postPasswordlessConsultationMagiclink(userId, consultationId),
      }),
    })
  })
})

export const {
  usePostPasswordlessMagiclinkMutation,
  usePostPasswordlessConsultationMagiclinkMutation
} = passwordlessMagiclinkApi
