import React, { FC, useEffect, useMemo } from "react"
import {useTranslation} from "react-i18next"
import { Box, Typography } from "@material-ui/core"

import logoutAlert from "../../../assets/sounds/logoutAlert.wav"
import {useAppDispatch} from "../../../hooks/storeHooks"
import {clearSession} from "../../../store/session/session.slice"
import AppDialog from "../../common/appDialog/AppDialog.component"
import {useSessionManagerModalStyles} from "./SessionManagerModal.styles"

interface SessionManagerModalProps {
  open: boolean;
  onClose(): void;
  seconds: string;
  refresh(): void;
}

const SessionManagerModal: FC<SessionManagerModalProps> = ({open, onClose, seconds, refresh}) => {
  const {t} = useTranslation()
  const classes = useSessionManagerModalStyles()
  const dispatch = useAppDispatch()
  const showModalAlert = useMemo(() => (new Audio(logoutAlert)), [])
  const forYourSafetyTranslation = t("sessionManager:forYourSafety")
  const weWillLogYouOutTranslation = t("sessionManager:weWillLogYouOut")

  const handleShowModal = async () => {
    await showModalAlert.play()
  }

  const logOut = () => {
    onClose()
    dispatch(clearSession())
  }

  const handleCloseModal = () => {
    onClose()
    refresh()
  }

  useEffect(() => {
    if (open) {
      handleShowModal()
    } else {
      refresh()
    }
  }, [open])

  return (
    <>
      <AppDialog
        open={open}
        onClose={onClose}
        title={t("sessionManager:refreshSession")}
        actionButtonText={t("sessionManager:stayAndRefresh")}
        actionButtonProps={{
          onClick: handleCloseModal
        }}
        closeButtonText={t("sessionManager:cancelAndLogout")}
        closeButtonProps={{
          onClick: logOut
        }}
      >
        <Box mb={2}>
          {forYourSafetyTranslation}&nbsp;<Typography color="error" component="span" className={classes.secondsLeft}>
            {seconds} s</Typography> {weWillLogYouOutTranslation}
        </Box>
      </AppDialog>

      <audio>
        <source src={logoutAlert} type="audio/wav"/>
      </audio>
    </>
  )
}

export default SessionManagerModal
