import i18next from "i18next"
import * as yup from "yup"

import {SendAccessCodeSubmitData, SendNewPasswordFormValues, SendUsernameFormValues} from "./ForgotPassword.types"

export const sendUsernameValidationSchema = (): yup.SchemaOf<SendUsernameFormValues> => yup.object().shape({
  username: yup.string().required(i18next.t("errors:required")),
})

export const sendSmsCodeValidationSchema = (): yup.SchemaOf<SendAccessCodeSubmitData> => yup.object().shape({
  accessCode: yup.string().required(i18next.t("errors:required")),
})

export const userPasswordResetValidationSchema = (isBirthdateRequired?: boolean) => (): yup.SchemaOf<SendNewPasswordFormValues> => {
  const passwordRegExp = new RegExp("^(?=.*[a-zżźćńółęąś])(?=.*[A-ZŻŹĆĄŚĘŁÓŃ])(?=.*[\\d])(?=.*[~!@#$%\\^*\\(\\)\\-_=+\\{\\}\\[\\]\\|:;'\",./\\?€£¥₹])[a-zżźćńółęąśA-ZŻŹĆĄŚĘŁÓŃ\\d~!@#$%\\^*\\(\\)\\-_=+\\{\\}\\[\\]\\|:;'\",./\\?€£¥₹]{8,}$")

  return yup.object().shape({
    plainPassword: yup.string()
      .required(i18next.t("errors:required"))
      .matches(passwordRegExp, i18next.t("user:plainPasswordRequirements")),
    plainPasswordRepeat: yup.string()
      .required(i18next.t("errors:required"))
      .test("password match", i18next.t("errors:passwordMatchError"),
        (plainPasswordRepeat, testContext) => plainPasswordRepeat === testContext.parent.plainPassword
      ),
    birthdate: isBirthdateRequired ? yup.string().required(i18next.t("errors:required")) : yup.string(),
  })
}
