import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import {Typography, useMediaQuery, useTheme} from "@material-ui/core"

import RecommendationSectionWrapper from "../recommendationsSectionWrapper/RecommendationsSectionWrapper.component"
import {removeOrphansHTML} from "../../../utils/removeOrphansHTML"
import {useRecommentationsStyles} from "../Recommendation.styles"

interface RecommendationSummaryProps {
  summary?: string
}

const RecommendationSummary: FC<RecommendationSummaryProps> = ({ summary }) => {
  const { t } = useTranslation()
  const classes = useRecommentationsStyles()

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <RecommendationSectionWrapper>
      <Typography
        color="textSecondary"
        variant={isSmUp ? "body1" : "subtitle1"}
        className={classes.generalInformations}
        dangerouslySetInnerHTML={{
          __html: summary
            ? removeOrphansHTML(summary)
            : t("recommendations:noRecommendationDescription")
        }}
      />
    </RecommendationSectionWrapper>
  )
}

export default RecommendationSummary
