import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Grid, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import clsx from "clsx"

import {ReactComponent as CheckIcon} from "../../../assets/svg/check-fill.svg"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import { ConsultationExamReferral } from "../../exam/Exam.types"
import {useReferralSectionStyles} from "../ReferralSection.styles"
import {useReferralItemsListStyles} from "./ReferralItemsList.styles"

interface ReferralItemsListProps {
  referrals: ConsultationExamReferral[];
  borderBottom: boolean;
}

const ReferralItemsList: FC<ReferralItemsListProps> = ({referrals, borderBottom}) => {
  const classes = useReferralItemsListStyles()
  const sectionClasses = useReferralSectionStyles()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))
  const {t} = useTranslation()

  return (
    <Grid
      container
      spacing={3}
      component="ul"
      className={classes.list}
    >
      {referrals.map((referral, idx) => (
        <Grid
          key={`${referral.id}-${idx}`}
          item
          xs={12}
          component="li"
          classes={{item: clsx(classes.listItem, !borderBottom && classes.borderBottom) }}
        >
          <Box 
            display="flex"
            py={1.5} 
            px={0.5}
          >
            { 
              referral.is_bought && (
                <Box className={sectionClasses.checkIcon}>
                  <ImageByIntegrationType
                    imageSrc={<CheckIcon className={sectionClasses.checkIconImg}/>}
                    imagePath={"check-fill.svg"}
                    className={sectionClasses.checkIconImg}
                    returnSvg={true}
                  />
                </Box>
              )
            }
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              width="100%"
            >
              <Box>
                <Typography 
                  variant={isSmUp ? "body1" : "subtitle1"}
                  component="p"
                >
                  { referral.lab_name }
                </Typography>
              </Box>
              {
                referral.is_bought && (
                  <Box
                    mt={1}
                  >
                    <Typography 
                      variant="body2" 
                      component="span"
                    >
                      { t("referrals:examBought") }
                    </Typography>
                  </Box>
                )
              }
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

export default ReferralItemsList
