import i18next from "i18next"
import * as yup from "yup"

import {EmploymentType} from "../../MedicalQuestionnaireStepperForm.types"

export const patientsRequirementsFormValidation = (visitReason: {
  isPrescriptionNeeded: boolean;
  isSickLeaveNeeded: boolean;
  isReferralNeeded: boolean;
}, perDrugLimit: number, totalDrugsLimit: number, t: any) => () => {

  return yup.object().shape({
    referral: visitReason?.isReferralNeeded
      ? yup.object().shape({
        isDoctorConsultationRequired: yup.boolean(),
        exams: yup.array().when("isDoctorConsultationRequired", {
          is: true,
          then: yup.array().nullable(),
          otherwise: yup.array().min(1, t("medicalQuestionnaire:referralChooseAtLeastOne"))
        })
      })
      : yup.object().shape({}),
    sickLeave: visitReason?.isSickLeaveNeeded 
      ? yup.object().shape({
        address: yup.object().shape({
          street: yup.string().required(i18next.t("errors:required")),
          houseNumber: yup.string().required(i18next.t("errors:required")),
          postalCode: yup.string().required(i18next.t("errors:required")),
          city: yup.string().required(i18next.t("errors:required")),
        }),
        employmentType: yup.number()
        //@ts-ignore
          .oneOf(Object.values(EmploymentType))
          .required(t("medicalQuestionnaire:sickLeaveChooseEmploymentType")),
        isStudentConfirmation: yup.boolean().when("employmentType", {
          is: EmploymentType.STUDENT,
          then: yup.boolean().required(t("medicalQuestionnaire::sickLeaveStudentConfirmationRequired")),
          otherwise: yup.boolean().nullable().notRequired()
        })
      })
      : yup.object().shape({}),
    prescription: visitReason?.isPrescriptionNeeded
      ? yup.object().shape({
        isDoctorConsultationRequired: yup.boolean(),
        prescriptions: yup.array().when("isDoctorConsultationRequired", {
          is: true,
          then: yup.array().nullable(),
          otherwise: yup.array()
            .of(
              yup.object().shape({
                chosenAmount: yup.number()
                  .required( t("medicalQuestionnaire:prescriptionChooseAmount"))
                  .max(perDrugLimit, t("medicalQuestionnaire:prescriptionChoiceMaxAmount",{drugLimit: perDrugLimit})),
                chosenSize: yup.number().required(t("medicalQuestionnaire:prescriptionChooseSize")),
              })
            )
            .min(1, t("medicalQuestionnaire:prescriptionChooseAtLeastOne"))
            .max(totalDrugsLimit, t("medicalQuestionnaire:prescriptionChoiceMaxAmount",{drugLimit: totalDrugsLimit}))
        })
      })
      : yup.object().shape({}),
  })
}
