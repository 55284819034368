import { typography } from "../../../../../themes/default/typography"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useHealthPlannerInfoModalStyles = makeStyles((theme: Theme) => createStyles({
  popup: {
    width: "600px",
    maxWidth: "100%",
    maxHeight: "80%",
    padding: `${theme.spacing(2)}px !important`,
    marginTop: "auto",
    borderRadius: theme.spacing(2, 2, 0, 0),
    [theme.breakpoints.up("md")]: {
      maxHeight: "unset",
      borderRadius: theme.spacing(2),
      marginTop: "unset",
    },
  },
  list: {
    display: "list-item",
    margin: theme.spacing(1.5, 0),
    "& li": {
      display: "list-item",
      listStyle: "initial",
      padding: theme.spacing(0,0,0,1),
    },
    "& span": {
      ...typography.body2,
    },
  },
  closeButton: {
    marginTop: "auto",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(6),
      marginLeft: "auto",
    },
  },
}))
