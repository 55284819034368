import {useQuery} from "@apollo/client"

import {GetBmiUserActionResponse} from "./getBmiUserAction.types"
import {QueryHookOptions} from "@apollo/client/react/types/types"

import {GET_BMI_USER_ACTION} from "./getBmiUserAction.query"

export const useGetBmiUserActionQuery = (options?: QueryHookOptions<GetBmiUserActionResponse>) => {
  return useQuery<GetBmiUserActionResponse>(
    GET_BMI_USER_ACTION,
    {
      context: { clientName: "HealthPlanner" },
      ...options
    }
  )
}