import { createStyles, makeStyles } from "@material-ui/core"

import { Theme } from "@material-ui/core/styles"

export const useAwardsListStyles = makeStyles((theme: Theme) => createStyles({
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    background: theme.palette.background.paper,
  },
  boxCardUserPoints: {
    paddingTop: theme.spacing(4),
    maxWidth: 280,
  },
  tab: {
    borderBottom: `2px solid ${theme.palette.grey[400]}`,
    "&.Mui-selected": {
      color: theme.palette.primary.main
    }
  },
  tabPanel: {
    padding: theme.spacing(1.5),
    "& > div": {
      padding: 0,
    },
  },
}))