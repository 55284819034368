import { SessionState } from "../../store/session/session.types"
import {
  ChatMessageMessageType,
  ChatMessageType,
  ConsultationChatItem,
  ConsultationChatNewItem,
} from "./ConsultationChat.types"

export const findLastChatMessageIndexOfType = (
  messages: ConsultationChatNewItem[],
  messageType: ChatMessageMessageType
): number => messages.reduce((savedIndex: number, messageItem: ConsultationChatNewItem, currentIndex: number) => {
  return messageItem.message === messageType ? currentIndex : savedIndex
}, -1)

export const isAdditionalMessageSent = (messages: ConsultationChatItem[], userId: SessionState["id"]): boolean => {
  // check for one last additional patient message after first consultationClose
  // (we don't discriminate between patient's additional messages and messages after consultation reopen here)
  // in the future backend should send information whether patient can write or not
  // and whether there is additional question option still available or not
  const consultationCloseFirstMessageIndex = messages.findIndex((messageItem: ConsultationChatItem) => messageItem.message === ChatMessageMessageType.CHAT_CLOSED_MESSAGE)
  if (consultationCloseFirstMessageIndex === -1) {
    return false
  }
  const messagesAfterConsultationClose = messages.slice(consultationCloseFirstMessageIndex)
  const patientAdditionalMessageIndex = messagesAfterConsultationClose.findIndex((messageItem: ConsultationChatItem) => {
    const isUserMessage = [ChatMessageType.OWN_NORMAL, ChatMessageType.USER].includes(messageItem.messageRawType)
    return isUserMessage && messageItem.sender === userId
  })
  return patientAdditionalMessageIndex > -1
}