import React, { FC, ReactNode } from "react"
import {useMediaQuery, useTheme} from "@material-ui/core"

import PageSection from "../PageSection.component"

interface CenteredPageSectionProps {
  title?: string;
  titleNode?: ReactNode;
}

const CenteredPageSection: FC<CenteredPageSectionProps> = ({title, titleNode , children}) => {
  const centeringStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
    
  return (
    <PageSection
      title={title}
      titleNode={titleNode}
      customStyles={isMdUp ? centeringStyles : {}}
    >
      { children }
    </PageSection>
  )
}

export default CenteredPageSection
