import React, {FC} from "react"
import {useSelector} from "react-redux"
import {Route,Switch} from "react-router-dom"
import i18next from "i18next"

import withBaseLayout from "../hoc/withBaseLayout"
import withConsultationChatLayout from "../hoc/withConsultationChatLayout"
import withDefaultLayout from "../hoc/withDefaultLayout"
import withDefaultLayoutMakeConsultationPage from "../hoc/withDefaultLayoutMakeConusltationsPage"
import withFooterLessLayout from "../hoc/withFooterLessLayout"
import withSidebarLessLayout from "../hoc/withSidebarLessLayout"
import { useAppSelector } from "../hooks/storeHooks"
import CancelConsultation from "../pages/cancelConsultation/CancelConsultation"
import ClinicNotFoundPage from "../pages/clinicNotFound/ClinicNotFoundPage"
import ConsultationCancelledPage from "../pages/consultationChanges/ConsultationCancelledPage"
import ConsultationChangeTermPage from "../pages/consultationChanges/ConsultationChangeTermPage"
import ConsultationNewTermPage from "../pages/consultationChanges/ConsultationNewTermPage"
import ConsultationTermSelectionPage from "../pages/consultationChanges/ConsultationTermSelectionPage"
import ConsultationChatPage from "../pages/consultationChat/ConsultationChatPage"
import ConsultationPaymentDeclinedPage from "../pages/consultationPayment/ConsultationPaymentDeclinedPage"
import ConsultationPaymentPage from "../pages/consultationPayment/ConsultationPaymentPage"
import ConsultationPaymentStatusPage from "../pages/consultationPayment/ConsultationPaymentStatus.page"
import ConsultationSuccessfulPaymentPage from "../pages/consultationSuccessfulPayment/ConsultationSuccessfulPayment"
import ConsultationSurveyPage from "../pages/consultationSurvey/ConsultationSurveyPage"
import DeclarationSignStatusPage from "../pages/declarationSign/DeclarationSignStatusPage"
import DocumentationPage from "../pages/documentation/DocumentationPage"
import Error403Page from "../pages/errors/Error403Page"
import Error404Page from "../pages/errors/Error404Page"
import Error504Page from "../pages/errors/Error504Page"
import ServerErrorPage from "../pages/errors/ServerErrorPage"
import ExamPaymentStatusPage from "../pages/examPayment/ExamPaymentStatus.page"
import OrderExamsPage from "../pages/exams/OrderExamsPage"
import ExamSuccessfulPaymentPage from "../pages/examSuccessfulPayment/ExamSuccessfulPayment"
import ForgotPasswordPage from "../pages/forgotPassword/ForgotPasswordPage"
import HomePage from "../pages/HomePage"
// import NotificationsPage from "../pages/NotificationsPage"
import LoginPage from "../pages/login/LoginPage"
import LoginMagicLinkPage from "../pages/loginMagicLink/LoginMagicLinkPage"
import LoyalityProgramPage from "../pages/loyalityProgram/LoyalityProgramPage"
import MakeStationaryPage from "../pages/make-stationary/MakeStationaryPage"
import MakeConsultation from "../pages/makeConsultation/MakeConsultationPage"
import MakeStationaryConsultation from "../pages/makeStationaryConsultation/MakeStationaryConsultationPage"
import MedicalSurveyPage from "../pages/medicalSurvey/MedicalSurveyPage"
import NfzResignPage from "../pages/nfzResign/NfzResignPage"
import OrderExaminationsByAlab from "../pages/orderExaminationByAlab/OrderExaminationByAlab"
import OrderMedicinesPage from "../pages/orderMedicines/OrderMedicinesPage"
import PaymentStatusPage from "../pages/payment/PaymentStatus.page"
import PaymentSuccessPage from "../pages/payment/PaymentSuccessPage"
import PossibilitiesPage from "../pages/possibilitiesPage/PossibilitiesPage"
import SickLeavePage from "../pages/sickLeavePage/SickLeavePage"
import StationaryConsultationSuccessfulPaymentPage from "../pages/stationaryConsultationSuccessfulPayment/StationaryConsultationSuccessfulPaymentPage"
import SwitchAccountPage from "../pages/switchAccount/SwitchAccountPage"
import UserProfilePage from "../pages/user/UserProfilePage"
import {selectClinicSettings} from "../store/clinic/clinicSettings.selectors"
import { selectAuthenticated } from "../store/session/session.selectors"
import BuyVoucherSummary from "../components/buyVoucher/BuyVoucherSummary.component"
import BuyVoucherDeclinedPaymentPage
  from "../components/buyVoucher/paymentPage/BuyVoucherDeclinedPaymentPage.component"
import BuyVoucherSuccessPaymentPage from "../components/buyVoucher/paymentPage/BuyVoucherSuccessPaymentPage.component"
import LoaderBox from "../components/common/loaderBox/LoaderBox.component"
import HealthPreventionIframeViewPage from "../components/healthPrevention/iframeView/HealthPreventionIframeView.component"
import HealthPlanner from "../pages/healthPlanner/healthPlanner.component"
import MedicalQuestionnairePage from "../pages/medicalQuestionnaire/MedicalQuestionnairePage.component"
import MentalHealthLibraryPage from "../pages/mentalHealth/components/Library/MentalHealthLibrary.component"
import MentalHealthSelfImprovementPage
  from "../pages/mentalHealth/components/SelfImprovement/SelfImprovement.component"
import MentalHealthWebinarPage from "../pages/mentalHealth/components/Webinar/MentalHealthWebinar.component"
import MentalHealthPageRouter from "../pages/mentalHealth/MentalHealthRouter.component"
import PasswordlessAccessCode from "../pages/passwordlessAccessCode/PasswordlessAccessCode.component"
import PasswordlessBirthdayVerify from "../pages/passwordlessBirthdayVerify/PasswordlessBirthdayVerify.component"
import PasswordlessMagicLink from "../pages/passwordlessMagicLink/PasswordlessMagicLink.component"
import PrivateRoute from "./PrivateRoute.component"
import {isClinicNotFoundView} from "../store/clinic/clinicSettings.utils"
import {RoutePath} from "./Routes.types"

import RoutesController from "./RoutesController"

const Routes: FC = () => {
  const authenticated = useAppSelector(selectAuthenticated)
  const availableLanguages = Object.keys(i18next.services.resourceStore.data)
  const langsParam = availableLanguages.join("|")
  const localesString = `/:locale(${langsParam})` // e.g. /:locale(pl|es|en)
  const clinicSettings = useSelector(selectClinicSettings)

  if (!clinicSettings?.domain && !isClinicNotFoundView()) { // wait for API url
    return <LoaderBox boxType="fullPage" boxClassName="b2b-loader"/>
  }

  return (
    <RoutesController>
      <Switch>
        <PrivateRoute path={localesString} exact component={withDefaultLayout(HomePage)} authenticated={authenticated}/>
        <Route path={localesString + RoutePath.LOGIN} exact component={withBaseLayout(LoginPage)}/>
        <Route path={localesString + RoutePath.LOGIN_MAGIC_LINK} exact component={LoginMagicLinkPage}/>
        <Route path={localesString + RoutePath.FORGOT_PASSWORD} exact component={withBaseLayout(ForgotPasswordPage)}/>
        <Route path={localesString + RoutePath.CLINIC_NOT_FOUND} exact component={withBaseLayout(ClinicNotFoundPage)}/>
        <Route path={localesString + RoutePath.DECLARATION_SIGN_STATUS_PAGE} exact component={withDefaultLayout(DeclarationSignStatusPage)}/>
        <Route
          path={localesString + RoutePath.MEDICAL_QUESTIONNAIRE}
          exact
          component={withDefaultLayout(MedicalQuestionnairePage)}
        />
        <Route
          path={localesString + RoutePath.PASSWORDLESS_ACCESS_CODE}
          exact
          component={PasswordlessAccessCode}
        />
        <Route
          path={localesString + RoutePath.PASSWORDLESS_BIRTHDAY_VERIFY}
          exact
          component={PasswordlessBirthdayVerify}
        />
        <Route
          path={localesString + RoutePath.PASSWORDLESS_MAGIC_LINK}
          exact
          component={PasswordlessMagicLink}
        />
        <PrivateRoute path={localesString + RoutePath.DOCUMENTATION} exact component={withDefaultLayout(DocumentationPage)} authenticated={authenticated}/>
        {/*<PrivateRoute path={localesString + RoutePath.NOTIFICATIONS} exact component={withDefaultLayout(NotificationsPage)} authenticated={authenticated}/>*/}
        <PrivateRoute path={localesString + RoutePath.CONSULTATION_CHAT} exact component={withConsultationChatLayout(ConsultationChatPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.CONSULTATION_PAYMENT_SUCCESSFULL} exact component={withConsultationChatLayout(ConsultationSuccessfulPaymentPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.CONSULTATION_PAYMENT_DECLINED} exact component={withDefaultLayout(ConsultationPaymentDeclinedPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.PAYMENT_DECLINED} exact component={withDefaultLayout(ConsultationPaymentDeclinedPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.EXAM_PAYMENT_DECLINED} exact component={withDefaultLayout(ConsultationPaymentDeclinedPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.PAYMENT_SUCCESS} exact component={withDefaultLayout(PaymentSuccessPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.VOUCHER_PAYMENT_SUCCESS} exact component={withDefaultLayout(BuyVoucherSuccessPaymentPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.VOUCHER_PAYMENT_DECLINED} exact component={withDefaultLayout(BuyVoucherDeclinedPaymentPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.CONSULTATION_PAYMENT_STATUS} exact component={withDefaultLayout(ConsultationPaymentStatusPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.PAYMENT_STATUS} exact component={withDefaultLayout(PaymentStatusPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.CONSULTATION_NEW_TERM} exact component={withDefaultLayout(ConsultationNewTermPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.CONSULTATION_CHANGE_TERM} exact component={withDefaultLayout(ConsultationChangeTermPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.CONSULTATION_TERM_SELECTION} exact component={withDefaultLayout(ConsultationTermSelectionPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.CONSULTATION_CHOOSE_TERM} exact component={withDefaultLayout(ConsultationTermSelectionPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.CONSULTATION_CANCEL_SUCCESS} exact component={withDefaultLayout(ConsultationCancelledPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.CONSULTATION_PAYMENT} exact component={withDefaultLayout(ConsultationPaymentPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.EXAMS_PAYMENT_SUCCESSFULL} exact component={withConsultationChatLayout(ExamSuccessfulPaymentPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.STATIONARY_CONSULTATION_PAYMENT_SUCCESSFULL} exact component={withConsultationChatLayout(StationaryConsultationSuccessfulPaymentPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.USER_PROFILE} exact component={withDefaultLayout(UserProfilePage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.ORDER_EXAM} exact component={withDefaultLayout(OrderExamsPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.VOUCHERS_BUY} exact component={withDefaultLayout(BuyVoucherSummary)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.EXAM_PAYMENT_STATUS} exact component={withDefaultLayout(ExamPaymentStatusPage)} authenticated={authenticated}/>
        <PrivateRoute
          path={localesString + RoutePath.CONSULTATION_SURVEY}
          exact
          component={withDefaultLayout(ConsultationSurveyPage)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.SICK_LEAVE_SURVEY}
          exact
          component={withDefaultLayout(SickLeavePage)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.ORDER_EXAMINATIONS_BY_ALAB}
          exact
          component={withDefaultLayout(OrderExaminationsByAlab)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.CANCEL_CONSULTATION}
          exact
          component={withDefaultLayout(CancelConsultation)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.PREVENTION}
          component={withFooterLessLayout(HealthPlanner)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.MEDICAL_SURVEY}
          exact
          component={withDefaultLayout(MedicalSurveyPage)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.POSSIBILITIES}
          exact
          component={withSidebarLessLayout(PossibilitiesPage)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.LOYALITY_PROGRAM}
          exact
          component={withDefaultLayout(LoyalityProgramPage)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.NFZ_RESIGN}
          exact
          component={withDefaultLayout(NfzResignPage)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.EMAIL_STATIONARY_CONSULTATION}
          exact
          component={withDefaultLayout(MakeStationaryPage)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.MENTAL_HEALTH}
          exact
          component={withDefaultLayout(MentalHealthPageRouter)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.MENTAL_HEALTH_LIBRARY}
          exact
          component={withDefaultLayout(MentalHealthLibraryPage)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.HEALTH_PREVENTION_IFRAME_VIEW}
          exact
          component={withDefaultLayout(HealthPreventionIframeViewPage)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.MENTAL_HEALTH_WEBINAR}
          exact
          component={withDefaultLayout(MentalHealthWebinarPage)}
          authenticated={authenticated}
        />
        <PrivateRoute
          path={localesString + RoutePath.MENTAL_HEALTH_SELF_IMPROVEMENT}
          exact
          component={withDefaultLayout(MentalHealthSelfImprovementPage)}
          authenticated={authenticated}
        />
        <PrivateRoute path={localesString + RoutePath.MAKE_PRESCRIPTION_CONSULTATION} exact component={withDefaultLayoutMakeConsultationPage(MakeConsultation)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.MAKE_CONSULTATION} exact component={withDefaultLayoutMakeConsultationPage(MakeConsultation)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.MAKE_STATIONARY_CONSULTATION} exact component={withDefaultLayoutMakeConsultationPage(MakeStationaryConsultation)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.ORDER_MEDICINES} exact component={withDefaultLayout(OrderMedicinesPage)} authenticated={authenticated}/>
        <PrivateRoute path={localesString + RoutePath.SWITCH_ACCOUNT} exact component={withSidebarLessLayout(SwitchAccountPage)} authenticated={authenticated}/>
        <Route path={localesString + RoutePath.ERROR_404} component={withBaseLayout(Error404Page)}/>
        <Route path={localesString + RoutePath.ERROR_403} exact component={withBaseLayout(Error403Page)}/>
        <Route path={localesString + RoutePath.SERVER_ERROR} exact component={withBaseLayout(ServerErrorPage)}/>
        <Route path={localesString + RoutePath.ERROR_504} component={withBaseLayout(Error504Page)}/>
        <Route path={RoutePath.FALLBACK} component={withBaseLayout(Error404Page)}/>

      </Switch>
    </RoutesController>
  )
}

export default Routes
