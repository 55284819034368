import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {
  Box,
  Link,
  Typography,
} from "@material-ui/core"

import {useDozRegulationsStyles} from "./DozRegulations.styles"

interface DozRegulationsProps {}

const DOZ_ORDER_EXTERNAL_URL = {
  RULES_URL: "https://www.doz.pl/info/Doz.pl_-_regulamin",
  RODO_URL: "https://www.doz.pl/info/obowiazek_informacyjny",
}

const DozRegulations: FC<DozRegulationsProps> = () => {
  const {t} = useTranslation()
  const classes = useDozRegulationsStyles()

  return (
    <Box className={classes.root}>
      <Typography
        variant={"body2"}
      >
        {t("orderMedicines:dozOrderRulesLabel")}
        <Link
          className={classes.rulesUrl}
          href={DOZ_ORDER_EXTERNAL_URL.RULES_URL}
          target="_blank"
          rel="noopener noreferrer"
          underline="always"
        >
          { t("orderMedicines:regulations") }
        </Link>
      </Typography>
      <Link
        className={classes.rodoUrl}
        href={DOZ_ORDER_EXTERNAL_URL.RODO_URL}
        target="_blank"
        rel="noopener noreferrer"
        underline="always"
      >
        {t("orderMedicines:dozOrderRulesAccordionTitle")}
      </Link>
    </Box>
  )
}

export default DozRegulations
