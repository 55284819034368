import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const usePrescriptionFooterStyles = makeStyles((theme: Theme) => createStyles({
  buttonsWrapper: {
    marginLeft: "auto",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    alignItems: "baseline",
    justifyContent: "center",
    "& $actionButton:nth-of-type(1)": {
      flexGrow: 1,
    },
    "& $actionButton:nth-of-type(2)": {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
    [theme.breakpoints.up("md")]: {
      width: "auto",
      alignItems: "center",
      justifyContent: "flex-end",
      "& $actionButton:nth-of-type(1)": {
        flexGrow: 0,
      },
      "& $actionButton:nth-of-type(2)": {
        width: "auto",
      },
    },
    [theme.breakpoints.up("lg")]: {
      flexWrap: "nowrap",
    },
  },
  actionButton: {
    "&:nth-of-type(1)": {
      margin: theme.spacing(0, 0, 3, 2)
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 0, 0, 3),
      "&:nth-of-type(1)": {
        order: 1,
        margin: theme.spacing(0, 0, 0, 3),
      },
    },
  },
}))
