import {createStyles, makeStyles} from "@material-ui/core"

import {Theme} from "@material-ui/core/styles"

export const useLocationRadioButton = makeStyles<Theme, { isChecked: boolean }>((theme) => createStyles({
  root: {
    marginTop: theme.spacing(2)
  },
  radioButton: {
    "& svg": {
      width: 24,
      height: 24
    },
    "& circle": {
      stroke: props => props.isChecked
        ? theme.palette.primary.main
        : theme.palette.text.primary
    }
  }
}))