import axios from "axios"

import { dozApiUrl } from "../app.config"

const requestDoz = axios.create({
  baseURL: dozApiUrl,
  headers: {
    common: {
      Accept: "application/json",
    },
    post: {
      "Content-Type": "application/json",
    },
  },
})

export default requestDoz
