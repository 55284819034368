import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

type StylesProps = {
  isDeleteButtonDisabled: boolean;
}

export const useOrderMedicinesItemStyles = makeStyles<Theme, StylesProps>((theme) => createStyles({
  root: {
    padding: theme.spacing(2, 0, 2, 1),
    boxShadow: `inset 0px -1px 0px ${theme.palette.grey[200]}`,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 0),
    },
  },
  nameWrapper: {
    display: "flex",
    alignItems: "center"
  },
  counterWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  priceWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  price: {
    fontWeight: 700,
  },
  deleteButton: {
    "& path": {
      stroke: (props: StylesProps) =>
        props.isDeleteButtonDisabled
          ? theme.palette.text.disabled
          : theme.palette.common.black
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0)
    },
  }
}))
