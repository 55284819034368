import React, {FC} from "react"
import {get,useFormState} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import CheckboxController from "../../../../../../components/CheckboxController/CheckboxController.component"
import {BasicInformationStepData} from "../../../MedicalQuestionnaireStepperForm.types"

const ReasonForVisit: FC = () => {
  const {t} = useTranslation()
  const { errors } = useFormState<BasicInformationStepData>({
    name: "visitReason",
  })

  return (
    <Box>
      <Typography
        variant='h3'
        gutterBottom
      >
        {t("medicalQuestionnaire:reasonForVisitTitle")}
      </Typography>
      <Typography>{t("medicalQuestionnaire:markOptionsLabel")}</Typography>
      <Box
        display='flex'
        flexDirection='column'
        mt={2}
        pl={2}
      >
        <CheckboxController
          name='visitReason.isPrescriptionNeeded'
          label={t("medicalQuestionnaire:isPrescriptionNeededLabel")}
        />
        <CheckboxController
          name='visitReason.isSickLeaveNeeded'
          label={t("medicalQuestionnaire:isSickLeaveNeededLabel")}
        />
        <CheckboxController
          name='visitReason.isReferralNeeded'
          label={t("medicalQuestionnaire:isReferralNeededLabel")}
        />
        <CheckboxController
          name='visitReason.isTalkWithDoctorNeeded'
          label={t("medicalQuestionnaire:isTalkWithDoctorNeededLabel")}
        />
        {
          errors.visitReason && (
            <Typography
              variant='body2'
              color='error'
            >
              {get(errors, "visitReason.message")}
            </Typography>
          )
        }
      </Box>
    </Box>
  )
}

export default ReasonForVisit
