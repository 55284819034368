import {createStyles, makeStyles} from "@material-ui/core"

export const useMobileTable = makeStyles(theme => createStyles({
  recordName: {
    maxWidth: "110px",
    minWidth: "110px",
    marginRight: theme.spacing(1)
  },
  cellContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
}))
