import {gql} from "@apollo/client"

export const GET_BMI_USER_ACTION = gql`
  mutation usersActions {
    usersActions: users_actions(where: {action: {type: {_eq: "bmi"}}}) {
      action {
        points
      }
    }
  }
`