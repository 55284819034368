import { createStyles } from "@material-ui/core"

import { makeStyles, Theme } from "@material-ui/core/styles"

interface Props {
  topCSS?: number;
  rightCSS?: number;
}

export const useCloseButtonStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: "absolute",
    top: (props: Props) => theme.spacing(props.topCSS ?? 3),
    right: (props: Props) => theme.spacing(props.rightCSS ?? 3),
    overflow: "hidden",
    "&:hover, &:focus": {
      background: "transparent",
    },
  },
  wrapper: {
    transition: "transform .3s ease-in-out",
    "$root:hover &, $root:focus &": {
      transform: "rotate(180deg)",
    }
  },
}))