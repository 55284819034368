import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Button, Typography, useTheme} from "@material-ui/core"

import DeclarationStatusFailedImg from "../../assets/images/declaration-sign-fail.png"
import useGoToConsultationPage from "../../hooks/useGoToConsultationPage"
import {PozFormDetailedSource} from "../../services/analytics/PozFormDetailedSource.enum"
import ImageByIntegrationType from "../../components/imageByIntegrationType/ImageByIntegrationType"
import DeclarationSignSteps from "./DeclarationSignSteps.component"
import {useDeclarationSignStyles} from "./DeclarationSign.styles"

export const MAKE_DECLARATION_URL = "makeDeclarationUrl"

type DeclarationSignFailedPageProps = {
  errorCode?: number;
}

const DeclarationSignFailedPage: FC<DeclarationSignFailedPageProps> = ({errorCode}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const { goToMakeConsultationPage } = useGoToConsultationPage({visitDatePicker: false, detailedSource: PozFormDetailedSource.PP_SETTINGS})
  const classes = useDeclarationSignStyles()

  return (
    <Box className={classes.container}>
      <Box
        borderRadius={theme.shape.borderRadius}
        bgcolor={theme.palette.background.paper}
        className={classes.pageBox}
      >
        <Box className={classes.section}>
          <DeclarationSignSteps />
        </Box>
        <Box className={classes.statusInfoContainer}>
          <Box>
            <Box className={classes.statusInfo}>
              <Box>
                <Box>
                  <Typography variant="h2">
                    {t("consultation:generalError")}
                  </Typography>
                </Box>
                <Box className={classes.failMessage}>
                  <Typography className={classes.declarationSignFailed}>
                    {t("user:declarationSignFail")}
                  </Typography>
                  <Box className={classes.actionBtn}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.signAgain}
                      onClick={() => {
                        sessionStorage.getItem(MAKE_DECLARATION_URL) ? window.location.href = sessionStorage.getItem(MAKE_DECLARATION_URL) : goToMakeConsultationPage()
                      }}
                    >
                      {t("user:declarationSignTryAgain")}
                    </Button>
                  </Box>
                  {errorCode &&
                    <Box mt={6}>
                      {t("user:signError", { errorNumber: errorCode})}
                    </Box>
                  }
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <ImageByIntegrationType
              imageSrc={DeclarationStatusFailedImg}
              alt="DeclarationStatusFailedImg"
              className={classes.image}
              imagePath={"declaration-sign-fail.png"}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default DeclarationSignFailedPage
