import React, {FC} from "react"
import { useHistory } from "react-router"

import DefaultLayout from "../layout/DefaultLayout"

interface WithDefaultLayoutProps {}

// eslint-disable-next-line react/display-name
const withDefaultLayoutMakeConsultationPage = <T extends WithDefaultLayoutProps>(WrappedComponent: FC<T & WithDefaultLayoutProps>): FC<T> => (props: T) => {
  const { location } = useHistory()

  return (
    <DefaultLayout>
      <WrappedComponent {...props} key={location.key}/>
    </DefaultLayout>
  )
}

export default withDefaultLayoutMakeConsultationPage
