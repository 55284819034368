import React, {FC, useState} from "react"
import {useHistory} from "react-router"
import {Button, Typography} from "@material-ui/core"
import i18next from "i18next"

import api from "../../api/api"
import { postStationaryVisitRequestConfig} from "../../api/routes"
import PageSection from "../../components/common/pageSection/PageSection.component"
import {checkFeatureAvailability} from "../../store/clinic/clinicSettings.utils"

import "./MakeStationary.css"

const MakeStationaryPage: FC = () => {
  const { push } = useHistory()
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const [isFinishedRequest, setFinishedRequest] = useState(false)
  const hideConsultationBooking = checkFeatureAvailability().hideConsultationBooking

  if (hideConsultationBooking) {
    push(`/${i18next.language}`)

    return null
  }

  const disableButton = () => {
    setButtonDisabled(true)
  }

  const submitForm = async (e) => {
    disableButton()
    try {
      await api.request({
        ...postStationaryVisitRequestConfig,
        data: {
          voucher: e.target.voucher.value,
          service: e.target.service.value,
          location: e.target.location.value,
          preferences: e.target.preferences.value
        }
      })
      setFinishedRequest(true)
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <PageSection>
      <div className="stationary-form">
        <header>
          <h1>Formularz Zgłoszeniowy</h1>
        </header>
        <div className="container">
          <form onSubmit={(e) => {submitForm(e); e.preventDefault()}} method="POST">
            <h2>Zamów usługę stacjonarną</h2>
            <label htmlFor="voucher">Twój voucher (PESEL/inny numer ID)</label>
            <input type="text" id="voucher" name="voucher" required/>
            <label htmlFor="service">Rodzaj usługi stacjonarnej</label>
            <input type="text" id="service" name="service" required/>
            <label htmlFor="location">Miejscowość</label>
            <input type="text" id="location" name="location" required/>
            <label htmlFor="preferences">Preferencje (Dzień i godzina)</label>
            <input type="text" id="preferences" name="preferences" required/>
            <Button type="submit" disabled={isButtonDisabled} >Prześlij zgłoszenie</Button>
            {isFinishedRequest && <Typography>Zgłoszenie zostało przyjęte, dziękujemy!</Typography>}
          </form>
        </div>
      </div>
    </PageSection>
  )
}

export default MakeStationaryPage
