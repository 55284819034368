import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useOrderMedicinesPageStyles = makeStyles((theme) => createStyles({
  root: {
    background: theme.palette.background.paper,
    padding: theme.spacing(7, 7),
    borderRadius: theme.spacing(3),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3, 3),
    }
  },
}))
