import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Reducer } from "redux"

import { ApiErrorState } from "./apiError.types"

const initialState: ApiErrorState = {
  error: null,
}

const apiErrorSlice = createSlice({
  name: "apiError",
  initialState,
  reducers: {
    catchApiError: (state: ApiErrorState, action: PayloadAction<ApiErrorState["error"]>) => {
      state.error = action.payload
      return
    }
  }
})

export const {
  catchApiError,
} = apiErrorSlice.actions

export default apiErrorSlice.reducer as Reducer<typeof initialState>
