import { createStyles, makeStyles } from "@material-ui/core"

import { Theme } from "@material-ui/core/styles"

export const useDiaryMeasurementDataInputStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    whiteSpace: "nowrap",
    width: "100%",
  },
  closeBtn: {
    position: "static",
  },
  inputWrapper: {
    "& label": {
      maxWidth: "62%",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
  adornment: {
    paddingLeft: theme.spacing(1),
  },
  rootDialog: {
    "& .MuiDialog-paper": {
      margin: 0,
      minHeight: 550,
    }
  },
}))