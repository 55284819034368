import {v4Service} from "../store.service"
import {SendMedicalQuestionnairePayload, SendMedicalQuestionnaireResponse} from "./medicalQuestionnaire.types"

export const medicalQuestionnaireApi = v4Service.injectEndpoints({
  endpoints: build => ({
    sendMedicalQuestionnaire: build.mutation<SendMedicalQuestionnaireResponse, SendMedicalQuestionnairePayload>({
      query: (data) => ({
        method: "POST",
        url: `/api/v2/save/medical-survey/${data.consultationId}`,
        data
      })
    })
  })
})

export const { useSendMedicalQuestionnaireMutation } = medicalQuestionnaireApi
