import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"

import {SearchParams} from "../../enums/SearchParams"
import {SsoErrorCode} from "../../enums/SsoErrorCode"
import {useDeleteSearchParam} from "../../hooks/useDeleteSearchParam"
import {useQueryParam} from "../../hooks/useSearchParams"

export const usePasswordlessMagicLinkHandler = () => {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const deleteSearchParam = useDeleteSearchParam()
  const ssoErrorCode = useQueryParam(SearchParams.SSO_ERROR_CODE)

  useEffect(() => {
    if (!ssoErrorCode) {
      return
    }

    deleteSearchParam(SearchParams.SSO_ERROR_CODE)

    let errorMessage

    switch (ssoErrorCode) {
      case SsoErrorCode.PASSWORDLESS_LOGIN_INVALID_BODY:
      case SsoErrorCode.PASSWORDLESS_INVALID_MAGIC_LINK_ID:
        errorMessage = t("passwordless:invalidMagicLink")
        break
      case SsoErrorCode.PASSWORDLESS_LOGIN_MISSING_VERIFY_COOKIE:
      case SsoErrorCode.PASSWORDLESS_LOGIN_VERIFY_SESSION_EXPIRED:
        errorMessage = t("passwordless:magicLinkExpired")
        break
      default:
        errorMessage = t("passwordless:unknownError")
    }

    setErrorMessage(errorMessage)
  }, [ssoErrorCode])

  return errorMessage
}
