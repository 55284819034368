import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Box, Button, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"

import { analytics, LogEventType } from "../../../../services/analytics"
import AppDialog from "../../../common/appDialog/AppDialog.component"
import ButtonTextUnderlined from "../../../common/buttonTextUnderlined/ButtonTextUnderlined.component"
import TextLabel from "../../../common/textLabel/TextLabel.component"
import { ConsultationStatusNumber } from "../../../consultation/Consultation.types"
import { ActionButtonData, StatusLabel } from "../../comingServiceDetails/ComingServiceDetails.types"
import { useButtonsSectionStyles } from "./ButtonsSection.styles"

type ClassKey = "buttonCancel" | "warningLabel" | "statusLabel"

interface ButtonsSectionProps {
  id: string;
  isSickLeave: boolean;
  statusNumber: number;
  classes?: Record<ClassKey, string>;
  statusLabel?: StatusLabel | null;
  actionButtonData?: ActionButtonData | null;
  cancelButtonOnClick?(): void;
  showCancelButton?: boolean;
  isOutdatedVariant?: boolean;
  showAdditionalGoToConsultationBtn?: boolean;
}

const ButtonsSection: FC<ButtonsSectionProps> = (
  {
    id,
    classes,
    statusLabel,
    actionButtonData,
    cancelButtonOnClick,
    showCancelButton,
    isOutdatedVariant,
    isSickLeave,
    statusNumber,
    showAdditionalGoToConsultationBtn,
  }
) => {
  const sectionClasses = useButtonsSectionStyles()
  const {t, i18n} = useTranslation()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [cancelNotPossiblePopupOpen, setCancelNotPossiblePopupOpen] = useState(false)
  const consultationConfirmed = statusNumber === ConsultationStatusNumber.CONFIRMED
  const cancelUnavailable = isSickLeave && consultationConfirmed
  const classNames = clsx(sectionClasses.outlined, sectionClasses.actionButton, sectionClasses?.actionButtonPrescription)
  const {push} = useHistory()
  const goToConsultationBtn = (
    <ButtonTextUnderlined
      color="whiteOnBlue"
      className={sectionClasses.goToConsultationAdditionalBtn}
      onClick={() => push(`${i18n.language}/consultation/${id}`)}
    >
      {t("consultation:enter")}
    </ButtonTextUnderlined>
  )

  const handleCancelActionButton = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_CONSULTATION_CANCEL_BUTTON_CLICK)
    cancelUnavailable ? setCancelNotPossiblePopupOpen(true) : cancelButtonOnClick()
  }
  
  return (
    <Box>
      <Box
        height="100%"
        display="flex"
      >
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          { (statusLabel && isMdUp && statusLabel.text) && (
            <TextLabel
              bgcolor={statusLabel.color}
              text={statusLabel.text}
              className={clsx(classes?.warningLabel, classes?.statusLabel, sectionClasses?.actionButtonPrescription)}
            />
          )}
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
            width="100%"
            mt={isSmUp ? 0 : 4}
            mb={isSmUp ? 0 : 1}
          >
            {
              actionButtonData?.label && (
                <Button
                  disabled={!actionButtonData?.actionButtonOnClick}
                  className={isOutdatedVariant ? "" : classNames}
                  color={isOutdatedVariant ? "secondary" : undefined}
                  variant="outlined"
                  onClick={actionButtonData?.actionButtonOnClick}
                >
                  {actionButtonData?.label}
                </Button>
              )
            }

            {
              showCancelButton && (
                <ButtonTextUnderlined
                  className={classes?.buttonCancel}
                  color={"whiteOnBlue"}
                  onClick={handleCancelActionButton}
                >
                  {t("cancel")}
                </ButtonTextUnderlined>
              )
            }

            {showAdditionalGoToConsultationBtn && isMdUp && goToConsultationBtn}
          </Box>
        </Box>
        <AppDialog
          open={cancelNotPossiblePopupOpen}
          title={t("consultation:cancelConsultationPopup:title")}
          onClose={() => setCancelNotPossiblePopupOpen(false)}
          closeButtonText={t("close")}
        >
          {t("consultation:sickLeave:cancelNotPossible")}
        </AppDialog>
      </Box>
      <Box>
        {showAdditionalGoToConsultationBtn && !isMdUp && goToConsultationBtn}
      </Box>
    </Box>
  )
}

export default ButtonsSection
