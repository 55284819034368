export interface LogInPageValues {
  username: string;
  password: string;
}

export enum LogInValidationError {
  INVALID_USER_CREDENTIALS = "invalid_user_credentials",
  TWO_FACTOR_REQUIRED = "two_factor_required",
  CHANGE_PASSWORD_REQUIRED = "change_password_required",
}
