import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {NavLink} from "react-router-dom"
import {Box, Button, Container,Typography, useMediaQuery, useTheme} from "@material-ui/core"

import {useAppSelector} from "../../../hooks/storeHooks"
import {selectAuthenticated} from "../../../store/session/session.selectors"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import {useErrorPageStyles} from "./ErrorPageCard.styles"
// import {useHistory} from "react-router"

interface ErrorPageCardProps {
  errorCode: number;
  title: string;
  description: string;
  image: string;
  imagePath: string;
}

const ErrorPageCard: FC<ErrorPageCardProps> = (
  {
    errorCode,
    title,
    description,
    image,
    imagePath
  }
) => {
  const {t, i18n} = useTranslation()
  // const {goBack} = useHistory()
  const classes = useErrorPageStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  const authenticated = useAppSelector(selectAuthenticated)

  const goToHomePageButton = (
    <Button
      variant="contained"
      color="primary"
      component={NavLink}
      to={authenticated ? `/${i18n.language}` : `/${i18n.language}/login`}
      className={classes.button}
    >
      { t(authenticated ? "backToHomePage" : "goToLoginPage") }
    </Button>
  )

  // const goToPreviousPageButton = (
  //   <Button
  //     variant="contained"
  //     color="primary"
  //     onClick={() => goBack()}
  //     className={classes.button}
  //   >
  //     { t("backToLastPage") }
  //   </Button>
  // )

  return (
    <Container
      maxWidth="lg"
      className={classes.root}
    >
      <Box className={classes.boxCard}>
        <Box className={classes.textContentWrapper}>
          <Typography
            variant={isMdUp ? "h1" : "h3"}
            component="h2"
            className={classes.title}
          >
            <span className={classes.errorCode}>
              { errorCode }
            </span>

            { title }
          </Typography>

          <Typography
            className={classes.description}
            color="textSecondary"
            dangerouslySetInnerHTML={{ __html: description }}
          />

          { goToHomePageButton }
        </Box>

        <Box className={classes.imageWrapper}>
          <ImageByIntegrationType
            imageSrc={image!}
            alt="errorPage"
            className={classes.image}
            imagePath={imagePath}
          />
        </Box>
      </Box>
    </Container>
  )
}

export default ErrorPageCard
