import React, {FC} from "react"
import {Button as MuiButton, PropTypes} from "@material-ui/core"

import ButtonLoader from "../common/buttonLoader/ButtonLoader.component"

type ButtonProps = {
  isLoading?: boolean;
  isDisabled?: boolean;
  color?: PropTypes.Color;
  className?: string;
  type?: "button" | "submit" | "reset";
}

const Button: FC<ButtonProps> = ({
  color = "primary",
  type,
  className,
  isLoading,
  isDisabled,
  children,
  ...props
}) => {
  return (
    <MuiButton
      type={type}
      className={className}
      color={color}
      variant="contained"
      disabled={isDisabled || isLoading}
      startIcon={isLoading && <ButtonLoader position="prefix"/>}
      {...props}
    >
      {children}
    </MuiButton>
  )
}

export default Button
