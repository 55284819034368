import { createStyles, makeStyles } from "@material-ui/core"

import { colors } from "../../../../../themes/colors"
import { boxShadows } from "../../../../../themes/shadows"
import { Theme } from "@material-ui/core/styles"

export const useActionsListItemStyles = makeStyles((theme: Theme) => createStyles({
  listItem: {
    display: "flex",
    justifyContent: "flex-start",
    borderRadius: theme.shape.borderRadius,
    border: "none",
    width: "100%",
    boxShadow: boxShadows.shadow2,
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    pointerEvents: ({ isDisabled }: { isDisabled?: boolean }) => isDisabled ? "none" : "auto",
  },
  articleListItem: {
    boxShadow: "0px 1px 3px 0px #1D28392B",
  },
  tagsContainer: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  tagItem: {
    marginRight: theme.spacing(1),
  },
  imageWrapper: {
    flex: "1",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "55px",
    height: "55px",
  },
  articleImageWrapper: {
    padding: 0,
    width: "64px",
    height: "64px",
  },
  image: {
    width: "100%",
  },
  articleImage: {
    height: "100%",
    borderRadius: theme.spacing(1),
    objectFit: "fill"
  },
  iconBg: {
    position: "relative",
    background: theme.palette.background.paper,
    width: "55px",
    height: "55px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    boxShadow: boxShadows.shadow2,
    marginRight: theme.spacing(2),
    "& span": {
      content: "\"\"",
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "27px",
      height: "27px",
      top: "10%",
      left: "90%",
      transform: "translate(-50%,-50%)",
      background: theme.palette.primary.main,
      color: "#FFFFFF",
      borderRadius: "50%",
    },
  },
  articleImgBg: {
    background: "unset",
    width: "64px",
    height: "64px",
    borderRadius: "unset",
    marginLeft: theme.spacing(1),
    boxShadow: "unset"
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: theme.spacing(1),
  },
  fullWidth: {
    width: "100%"
  },
  infoTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    textAlign: "left",
  },
  infoSubtitle: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  infoMessage: {
    color: theme.palette.text.secondary,
  },
  detailsWrapper: {
    marginLeft: "auto",
    alignSelf: "flex-end",
    whiteSpace: "nowrap",
  },
  topDetails: {
    alignSelf: "flex-start",
    marginLeft: theme.spacing(4)
  },
  label: {
    color: colors.telemediBackground,
    background: theme.palette.primary.main,
    lineHeight: "16px",
    borderRadius: theme.spacing(.5),
    padding: theme.spacing(.5, 1),
    marginBottom: theme.spacing(1)
  },
}))