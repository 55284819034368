import {useMemo} from "react"
import {useTranslation} from "react-i18next"

import {isBaseQueryError} from "../../../store/store.utils"

export const useGlobalErrorHandler = (error: unknown) => {
  const {t} = useTranslation()

  return useMemo(
    () => {
      if (isBaseQueryError(error) && "detail" in error.data) {
        return error.data.detail
          ? t(`errors:${error.data.detail}`)
          : t("errors:unknownError")
      }
    },
    [error, t]
  )
}
