import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import { useRiskLevelStyles } from "./RiskLevel.styles"

type RiskLevelProps = {
  size?: "small" | "large",
  probability: number;
  className?: string;
}

const RiskLevel: FC<RiskLevelProps> = ({ size, probability, className }) => {
  const {t} = useTranslation()
  const classes = useRiskLevelStyles({ probability, size })

  return (
    <Box className={className}>
      <Box className={classes.container}>
        <Box className={classes.indicator}/>
      </Box>
      <Box className={classes.labelsWrapper}>
        <Typography
          variant="subtitle2"
        >
          {t("healthPlanner:lowRisk")}
        </Typography>
        <Typography
          variant="subtitle2"
        >
          {t("healthPlanner:highRisk")}
        </Typography>
      </Box>
    </Box>

  )
}

export default RiskLevel
