import i18next from "i18next"
import * as yup from "yup"

import {LoginMagicLinkFormValues} from "./LoginMagicLink.types"

export const loginMagicLinkValidationSchema = (): yup.SchemaOf<LoginMagicLinkFormValues> => yup.object().shape({
  phone: yup
    .string()
    .required(i18next.t("errors:required"))
    .test("phone number error",
      i18next.t("errors:phoneNumberError15Chars"),
      number => !number || /^\d{0,15}$/.test(number)
    ),
  birthdate: yup.string(),
})
