import i18next from "i18next"
import * as yup from "yup"

export interface AddWeightSchema {
  weight: string;
}

export interface AddBloodPressureSchema {
  diastolicPressure: string;
  systolicPressure: string;
}

export interface AddGlycemiaSchema {
  glucose: string;
}

export interface AddPulseSchema {
  pulse: string;
}

export interface AddWaistSchema {
  waist: string;
}

const numbersWithDotsPattern = /^(\d+(?:[\.]\d+)?)$/

const rangeValidation = (min: number, max: number) => (value?: string) => {
  const parsedValue = Number(value)

  if (isNaN(parsedValue)) {
    return false
  }

  return min <= parsedValue && parsedValue <= max
}

export const addWeightSchema = (): yup.SchemaOf<AddWeightSchema> => yup.object().shape({
  weight: yup.string()
    .required(i18next.t("errors:required"))
    .test("range", i18next.t("errors:range", { min: 0, max: 300 }), rangeValidation(0, 300)),
})

export const addBloodPressureSchema = (): yup.SchemaOf<AddBloodPressureSchema> => yup.object().shape({
  diastolicPressure: yup.string()
    .required(i18next.t("errors:required"))
    .test("range", i18next.t("errors:range", { min: 0, max: 300 }), rangeValidation(0, 300))
    .when("systolicPressure", (systolicPressure: string, schema) => {
      return schema.test({
        test: (diastolicPressure: string) => Number(diastolicPressure) <= Number(systolicPressure),
        message: i18next.t("healthPlanner:bloodPressureError")
      })
    }),

  systolicPressure: yup.string()
    .required(i18next.t("errors:required"))
    .test("range", i18next.t("errors:range", { min: 0, max: 300 }), rangeValidation(0, 300)),
})

export const addGlycemiaSchema = (): yup.SchemaOf<AddGlycemiaSchema> => yup.object().shape({
  glucose: yup.string()
    .required(i18next.t("errors:required"))
    .test("range", i18next.t("errors:range", { min: 0, max: 40 }), rangeValidation(0, 40)),
})

export const addPulseSchema = (): yup.SchemaOf<AddPulseSchema> => yup.object().shape({
  pulse: yup.string()
    .required(i18next.t("errors:required"))
    .test("range", i18next.t("errors:range", { min: 0, max: 300 }), rangeValidation(0, 300)),
})

export const addWaistSchema = (): yup.SchemaOf<AddWaistSchema> => yup.object().shape({
  waist: yup.string()
    .required(i18next.t("errors:required"))
    .matches(numbersWithDotsPattern, i18next.t("errors:onlyNumbers"))
})
