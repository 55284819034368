import {SwitchBaseProps} from "@material-ui/core/internal/SwitchBase"

import {Answer, CompletedAnswer} from "../../../GenericStepForm.types"

export const useCheckboxes = (
  answers: Answer[],
  onChange: (completedAnswer: CompletedAnswer[]) => void,
  values?: CompletedAnswer[],
) => {
  const handleChange = (id: string): SwitchBaseProps["onChange"] =>
    (event, checked) => {
      if (checked) {
        const currentAnswer = values?.find(value => value.id === id)
        const chosenAnswer = answers.find(answer => answer.id === id)

        if (currentAnswer) {
          return
        }

        if (chosenAnswer) {
          onChange([
            ...(values ?? []),
            {
              ...chosenAnswer,
              value: "",
            }
          ])
        }
      } else {
        const newAnswers = values?.filter(value => value.id !== id)

        if (newAnswers) {
          onChange(newAnswers)
        }
      }
    }
  
  const checkboxes = answers.map(answer => {
    
    return {
      ...answer,
      value: values?.find(value => value.id === answer.id),
      onCheck: handleChange(answer.id)
    }
  })

  return {
    checkboxes,
  }
}