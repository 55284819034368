import {createStyles, makeStyles} from "@material-ui/core"

import {colors} from "../../../themes/colors"

export const useVerifyUserFormStyles = makeStyles(theme => createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(6, 4),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 2),
    }
  },
  link: {
    textDecoration: "none",
    "&:visited": {
      color: "initial"
    }
  },
  title: {
    marginBottom: theme.spacing(4),
    fontSize: "18px",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    }
  },
  description: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    }
  },
  bold: {
    fontWeight: 600
  },
  input: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3),
    },
    maxWidth: "200px",
    alignSelf: "center"
  },
  cancelButton: {
    width: "100%"
  },
  submitButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0, 0, 2),
    }
  },
  errorMessage: {
    margin: theme.spacing(0, 0, 3)
  },
  successMessage: {
    color: colors.telemediGreen,
    border: `1px solid ${colors.telemediGreen}`,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    }
  }
}))
