import React, { FC } from "react"

import LoaderBox from "../../../../components/common/loaderBox/LoaderBox.component"
import PreventionActionCard from "./preventionActionCard/PreventionActionCard.component"
import { usePreventionAction } from "./PreventionAction.utils"

const PreventionAction: FC = () => {
  const { isLoading, action } = usePreventionAction()
  
  if (isLoading || !action) {
    return (
      <LoaderBox boxType="appDialog"/>
    )
  }

  return (
    <PreventionActionCard
      action={action}
    />
  )
}

export default PreventionAction