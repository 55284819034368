import {createStyles, makeStyles} from "@material-ui/core"

export const useBreastExaminationDescriptionStyles = makeStyles(theme => createStyles({
  container: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0
    }
  },
  header: {
    marginBottom: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing(4)
  },
  image: {
    width: 225,
    height: 225
  },
  stepLabel: {
    marginRight: theme.spacing(2)
  },
  smallImage: {
    width: 100,
    height: 100
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  examinationStep: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginBottom: theme.spacing(3)
    }
  },
  reverseFlex: {
    flexDirection: "row-reverse",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  boldFont: {
    fontWeight: 700
  }
}))