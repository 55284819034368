import {gql} from "@apollo/client"

import {Gender} from "../../../../store/healthPlanner/healthPlanner.types"

export const GET_USER_DISEASES_FILTERED_BY_IDS = gql`
  query UsersDiseases {
    usersDiseases: users_diseases {
      disease {
        name
        imageUrl: image_url
        onlyForGender: only_for_gender
        id
      }
    }
  }
`

export type Disease = {
  name: string;
  imageUrl: string;
  onlyForGender: Gender;
  id: string;
}

export type GetUserDiseasesFilteredByIdsResponse = {
  usersDiseases: {
    disease: Disease;
  }[]
}
