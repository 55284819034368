import {useQuery} from "@apollo/client"

import {GetUsersQuestionnaireValuesResponse} from "./getUserQuestionnaireValues.types"
import {QueryHookOptions} from "@apollo/client/react/types/types"

import {GET_USER_QUESTIONNAIRE_VALUES} from "./getUserQuestionnaireValues.query"

export const useGetUserQuestionnaireValues = (options?: QueryHookOptions<GetUsersQuestionnaireValuesResponse>) => {
  return useQuery<GetUsersQuestionnaireValuesResponse>(
    GET_USER_QUESTIONNAIRE_VALUES,
    {
      context: { clientName: "HealthPlanner" },
      ...options
    }
  )
}