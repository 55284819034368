import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Box, Button, CircularProgress, Divider, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import { internistSpecializationId, psychologistSpecializationId } from "../../../../app.config"
import HappyFaceIcon from "../../../../assets/svg/mentalHealth/happyFaceIcon.svg"
import NeutralFaceIcon from "../../../../assets/svg/mentalHealth/neutralFaceIcon.svg"
import SadFaceIcon from "../../../../assets/svg/mentalHealth/sadFaceIcon.svg"
import VeryHappyFaceIcon from "../../../../assets/svg/mentalHealth/veryHappyFaceIcon.svg"
import VerySadFaceIcon from "../../../../assets/svg/mentalHealth/verySadFaceIcon.svg"
import {
  useGetHealthPreventionPatientMoodQuery,
  usePostHealthPreventionPatientMoodMutation
} from "../../../../store/healthPrevention/healthPrevention.api"
import { selectUserId } from "../../../../store/session/session.selectors"
import ImageByIntegrationType from "../../../../components/imageByIntegrationType/ImageByIntegrationType"
import InfoLabel from "../../../../components/infoLabel/InfoLabel.component"
import Loader from "../../../../components/loader/Loader.component"
import { RoutePath } from "../../../../routes/Routes.types"
import { HealthPreventionPrograms } from "../../../../store/clinic/clinicSettings.types"
import { HealthPreventionPostMoodResponse } from "../../../../store/healthPrevention/healthPrevention.types"
import { mentalHealthColors } from "../../styles/mentalHealthColors"
import { useMoodTileStyles } from "./MoodTile.styles"

interface MoodTileProps {}

interface ColoredUpWrapperProps {
  color: string;
  isUp: boolean;
}

type MoodToColor = {
  [key: number]: string;
}

export enum MoodTypes {
  NONE = 0,
  VERY_SAD = 1,
  SAD = 2,
  NEUTRAL = 3,
  HAPPY = 4,
  VERY_HAPPY = 5
}

const MAX_MOOD = 5
const SCALE = 50  // constant value, defining half of circular progressbar

const MOOD_TO_COLOR: MoodToColor = {
  [MoodTypes.NONE]: mentalHealthColors.grey,
  [MoodTypes.VERY_SAD]: mentalHealthColors.verySadMood,
  [MoodTypes.SAD]: mentalHealthColors.sadMood,
  [MoodTypes.NEUTRAL]: mentalHealthColors.neutralMood,
  [MoodTypes.HAPPY]: mentalHealthColors.happyMood,
  [MoodTypes.VERY_HAPPY]: mentalHealthColors.veryHappyMood,
}

const MoodTile: FC<MoodTileProps> = () => {
  const { t, i18n } = useTranslation()
  const classes = useMoodTileStyles()
  const theme = useTheme()
  const { push } = useHistory()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [userMood, setUserMood] = useState<number>(0)
  const [isMoodForToday, setIsMoodForToday] = useState<boolean>(false)
  const userId = useSelector(selectUserId)

  const specializationId = process.env.NODE_ENV === "production"
    ? psychologistSpecializationId
    : internistSpecializationId

  const [handlePostPatientMood, { isLoading: isSaving }] = usePostHealthPreventionPatientMoodMutation()

  const { refetch, data: patientMood, isLoading } = useGetHealthPreventionPatientMoodQuery({
    patientId: String(userId),
    type: Number(HealthPreventionPrograms.PROGRAM_MENTAL_HEALTH)
  })

  const moodToProgressBarValue = (mood: number): number => {
    const percentOfMaxMood = mood * 100 / MAX_MOOD
    return SCALE * percentOfMaxMood / 100
  }

  useEffect(() => {
    if (!isLoading && patientMood) {
      setUserMood(patientMood.averageMood)

      if (patientMood.latestMood) {
        const moodDate = new Date(patientMood.latestMood)
        const today = new Date()

        const isToday = moodDate.getFullYear() === today.getFullYear() &&
            moodDate.getMonth() === today.getMonth() &&
            moodDate.getDate() === today.getDate()

        setIsMoodForToday(isToday)
      }
    }
  }, [patientMood, isLoading])

  const handleMoodClick = async (mood: MoodTypes) => {
    await handlePostPatientMood({
      patientId: String(userId),
      type: Number(HealthPreventionPrograms.PROGRAM_MENTAL_HEALTH),
      mood: mood
    })
      .unwrap()
      .then((data: HealthPreventionPostMoodResponse) => {
        data?.id && refetch()
      })
  }

  const getPatientMoodDescription = () => {
    switch (userMood) {
      case MoodTypes.VERY_SAD:
        return t("mentalHealth:moodMessages:verySad")
      case MoodTypes.SAD:
        return t("mentalHealth:moodMessages:sad")
      case MoodTypes.NEUTRAL:
        return t("mentalHealth:moodMessages:neutral")
      case MoodTypes.HAPPY:
        return t("mentalHealth:moodMessages:happy")
      case MoodTypes.VERY_HAPPY:
        return t("mentalHealth:moodMessages:veryHappy")
      default:
        return ""
    }
  }
  
  const ColoredArrowUp: React.FC<ColoredUpWrapperProps> = ({ color, isUp }) => {
    return <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
      transform: `rotate(${isUp ? 0 : 180}deg)`,
      transition: "transform 0.3s ease"
    }}>
      <path d="M8.70711 0.721604C8.31658 0.33108 7.68342 0.33108 7.29289 0.721604L0.928932 7.08556C0.538408 7.47609 0.538408 8.10925 0.928932 8.49978C1.31946 8.8903 1.95262 8.8903 2.34315 8.49978L8 2.84292L13.6569 8.49978C14.0474 8.8903 14.6805 8.8903 15.0711 8.49978C15.4616 8.10925 15.4616 7.47609 15.0711 7.08557L8.70711 0.721604ZM7 17.4287C7 17.981 7.44771 18.4287 8 18.4287C8.55228 18.4287 9 17.981 9 17.4287L7 17.4287ZM7 1.42871L7 17.4287L9 17.4287L9 1.42871L7 1.42871Z" fill={color} />
    </svg>
  }

  return (
    <Box
      px={2}
      py={3}
      className={classes.boxCard}
    >
      <Typography
        variant={isMdUp ? "h3" : "h4"}
        component="h3"
      >
        {t("mentalHealth:yourMoodTitle")}
      </Typography>
      {isLoading
        ? (
          <Loader
            mt={isMdUp ? "auto" : 3}
            mb={isMdUp ? "auto" : 2}
          />
        )
        : (
          <>
            <Box className={classes.arcProgressWrapper}>
              <Box className={classes.arcProgressWrapperRotated}>
                <CircularProgress
                  variant="determinate"
                  className={classes.bottomArc}
                  size="200px"
                  thickness={4}
                  value={50}
                />
                <CircularProgress
                  variant="determinate"
                  className={classes.topArc}
                  size="200px"
                  thickness={4}
                  value={moodToProgressBarValue(userMood)}
                  style={{color:MOOD_TO_COLOR[userMood]}}
                />
              </Box>
              <Box className={classes.averageMoodLabelContainer}>
                <Typography
                  component="span"
                  variant={"body2"}
                  align={"center"}
                  className={classes.averageMoodLabel}
                >
                  {t("mentalHealth:yourAverageMood")}
                </Typography>
                <ImageByIntegrationType
                  imageSrc={
                    <ColoredArrowUp 
                      color={MOOD_TO_COLOR[userMood]} 
                      isUp={userMood >= MoodTypes.NEUTRAL}
                    />
                  }
                  imagePath={"mentalHealth/arrowUp.svg"}
                  returnSvg={true}
                />
              </Box>
            </Box>

            <Divider/>

            <Box className={classes.ratingContainer}>
              { !isSaving && !isMoodForToday && (
                <>
                  <Typography
                    variant={isMdUp ? "h4" : "h5"}
                    component="span"
                  >
                    {t("mentalHealth:howAreYouToday")}
                  </Typography>

                  <Box className={classes.facesContainer}>
                    <ImageByIntegrationType
                      imageSrc={VerySadFaceIcon}
                      className={classes.moodIcon}
                      imagePath={"mentalHealth/verySadFaceIcon.svg"}
                      callback={() => handleMoodClick(MoodTypes.VERY_SAD)}
                    />
                    <ImageByIntegrationType
                      imageSrc={SadFaceIcon}
                      className={classes.moodIcon}
                      imagePath={"mentalHealth/sadFaceIcon.svg"}
                      callback={() => handleMoodClick(MoodTypes.SAD)}
                    />
                    <ImageByIntegrationType
                      imageSrc={NeutralFaceIcon}
                      className={classes.moodIcon}
                      imagePath={"mentalHealth/neutralFaceIcon.svg"}
                      callback={() => handleMoodClick(MoodTypes.NEUTRAL)}
                    />
                    <ImageByIntegrationType
                      imageSrc={HappyFaceIcon}
                      className={classes.moodIcon}
                      imagePath={"mentalHealth/happyFaceIcon.svg"}
                      callback={() => handleMoodClick(MoodTypes.HAPPY)}
                    />
                    <ImageByIntegrationType
                      imageSrc={VeryHappyFaceIcon}
                      className={classes.moodIcon}
                      imagePath={"mentalHealth/veryHappyFaceIcon.svg"}
                      callback={() => handleMoodClick(MoodTypes.VERY_HAPPY)}
                    />
                  </Box>
                </>
              )}
              { isSaving && (
                <Loader mt={`${theme.spacing(2.80)}px`} mb={`${theme.spacing(2.80)}px`}/>
              )}
              { isMoodForToday && userMood && (
                <>
                  <InfoLabel className={classes.infoLabel}>
                    { getPatientMoodDescription() }
                  </InfoLabel>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      push(`/${i18n.language}${RoutePath.MAKE_CONSULTATION}?specializationId=${specializationId}`)
                    }}
                  >
                    {t("mentalHealth:bookConsultation")}
                  </Button>
                </>
              )}
            </Box>
          </>
        )}
    </Box>
  )
}

export default MoodTile