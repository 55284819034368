import React, { FC, useEffect, useState } from "react"
import { Box } from "@material-ui/core"
import { CancelTokenSource } from "axios"
import Pusher, { AuthorizerCallback, Channel } from "pusher-js"

import api, { ApiInstance } from "../../../api/api"
import {
  getConsultationChatHistoryConfig,
  getPusherKeyConfig,
  postPusherAuthConsultationChannelConfig,
} from "../../../api/routes"
import { useAppSelector } from "../../../hooks/storeHooks"
import {useQueryParam} from "../../../hooks/useSearchParams"
import { selectSession } from "../../../store/session/session.selectors"
import ConsultationChatDocumentationModal
  from "../consultationChatDocumentationModal/ConsultationChatDocumentationModal"
import ChatLoader from "../../common/lottieAnimations/animations/ChatLoader.component"
import ConsultationChat from "../ConsultationChat.component"
import ConsultationTwilioVideo from "../consultationTwilioVideo/ConsultationTwilioVideo.component"
import ConsultationVideo from "../consultationVideo/ConsultationVideo.component"
import { redirectToError500Page } from "../../../utils/handleErrors"
import { scrollToBottom } from "../../../utils/scrollTo"
import { findLastChatMessageIndexOfType, isAdditionalMessageSent } from "../ConsultationChat.utils"
import { ConsultationModel } from "../../consultation/Consultation.types"
import {
  ChatEventType,
  ChatMessageMessageType,
  ChatMessageType,
  ChatTriggerEventType,
  ConsultationChatItem,
  InstantChatDoctorDataType,
  PusherKey,
  VideoChatTypes,
} from "../ConsultationChat.types"

interface ConsultationChatControllerProps {
  consultationData: ConsultationModel;
}

const ConsultationChatController: FC<ConsultationChatControllerProps> = ({consultationData}) => {
  const session = useAppSelector(selectSession)
  const userId = session.id
  const isTwilioVideoType = consultationData.video_server_type === VideoChatTypes.TWILIO_VIDEO

  const [loading, setLoading] = useState(true)
  const [chatMessages, setChatMessages] = useState<ConsultationChatItem[]>([])
  const [subtitleMessage, setSubtitleMessage] = useState<ConsultationChatItem>()
  const [consultationClosedMessage, setConsultationClosedMessage] = useState<ConsultationChatItem|null>(null)
  const [hasBeenAdditionalMessageSent, setHasBeenAdditionalMessageSent] = useState<boolean>(false)
  const [instantChatDoctorData, setInstantChatDoctorData] = useState<InstantChatDoctorDataType>({})

  const [invitationVideoModalOpen, setInvitationVideoModalOpen] = useState<boolean>(false)
  const [videoChatOpen, setVideoChatOpen] = useState<boolean>(false)
  const [pusherKey, setPusherKey] = useState<string>()
  const [pusherChannel, setPusherChannel] = useState<Channel>()
  const [isConsultationChatDocumentationModalOpen, setIsConsultationChatDocumentationModalOpen] = useState<boolean>(false)
  const shouldOpenConsultationChatDocumentationModal = useQueryParam("showDocs")
  const handleConsultationChatDocumentationModalClose = () => setIsConsultationChatDocumentationModalOpen(false)
  let pusher = null

  const initConsultationPusher = async (cancelToken?: CancelTokenSource["token"]) => {
    try {
      const [chatHistoryResponse, pusherKeyResponse] = await Promise.all([
        api.request<ConsultationChatItem[]>({
          ...getConsultationChatHistoryConfig(consultationData.id),
          cancelToken,
        }),
        api.request<PusherKey>({
          ...getPusherKeyConfig,
          cancelToken,
        }),
      ])
      setPusherKey(pusherKeyResponse.data.key)

      // ChatMessageMessageType.START_MESSAGE is in TopBox Component as subtitle
      const subtitleMessageItem = chatHistoryResponse.data.splice(chatHistoryResponse.data.findIndex((messageItem: ConsultationChatItem) => {
        return messageItem.message === ChatMessageMessageType.START_MESSAGE
      }), 1)
      setChatMessages(chatHistoryResponse.data)
      setSubtitleMessage(subtitleMessageItem[0])

      // check for last index of consultationClose message (there can be many messages of this kind because doctor can always reopen consultation)
      const consultationCloseLastMessageIndex = findLastChatMessageIndexOfType(chatHistoryResponse.data, ChatMessageMessageType.CHAT_CLOSED_MESSAGE)

      // check for last index of consultationReopened message (there can be as many messages of this kind as consultationClose messages)
      const consultationReopenedLastMessageIndex = findLastChatMessageIndexOfType(chatHistoryResponse.data, ChatMessageMessageType.CHAT_REOPENED_MESSAGE)
      const consultationChangeTermLastMessageIndex = findLastChatMessageIndexOfType(chatHistoryResponse.data, ChatMessageMessageType.CHANGE_TERM)

      const consultationAnyReopenedLastMessageIndex = Math.max(consultationReopenedLastMessageIndex, consultationChangeTermLastMessageIndex)
      // if consultation is reopened we allow user to write new messages and skip one last message after consultation close functionality
      if ( consultationCloseLastMessageIndex !== -1 && consultationCloseLastMessageIndex > consultationAnyReopenedLastMessageIndex) {
        setConsultationClosedMessage(chatHistoryResponse.data[consultationCloseLastMessageIndex])

        setHasBeenAdditionalMessageSent(isAdditionalMessageSent(chatHistoryResponse.data, userId))
      }
    } catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
      redirectToError500Page(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()

    initConsultationPusher(requestSource.token)

    return () => {
      setLoading(false)
      requestSource.cancel("Request interrupted by page change")
    }
  }, [consultationData.id])

  useEffect(() => {
    if(!loading) {
      setIsConsultationChatDocumentationModalOpen(typeof shouldOpenConsultationChatDocumentationModal === "string")
    }
  }, [loading])

  /* PUSHER EVENTS HANDLES */

  // manage chat new messages
  const setNewPusherMessage = (newPusherMessage: ConsultationChatItem) => {
    const isUserMessage = [ChatMessageType.OWN_NORMAL, ChatMessageType.USER].includes(newPusherMessage.messageRawType)
    const isPatientMessage = isUserMessage && newPusherMessage.sender === userId

    if (isPatientMessage) {
      setChatMessages(chatMessages => {
        // DIRTY HACK - we do not have access to chatMessages state, but we have access to setChatMessages state update function!
        const consultationCloseLastMessageIndex = findLastChatMessageIndexOfType(chatMessages, ChatMessageMessageType.CHAT_CLOSED_MESSAGE)
        const consultationReopenedLastMessageIndex = findLastChatMessageIndexOfType(chatMessages, ChatMessageMessageType.CHAT_REOPENED_MESSAGE)
        const consultationChangeTermLastMessageIndex = findLastChatMessageIndexOfType(chatMessages, ChatMessageMessageType.CHANGE_TERM)

        const consultationAnyReopenedLastMessageIndex = Math.max(consultationReopenedLastMessageIndex, consultationChangeTermLastMessageIndex)

        // if consultation has been closed, this newPusherMessage is the last one allowed to send
        if (consultationCloseLastMessageIndex !== -1 && consultationCloseLastMessageIndex > consultationAnyReopenedLastMessageIndex) {
          setHasBeenAdditionalMessageSent(true)
        }
        //e/o DIRTY HACK
        return chatMessages.map((currentMessage: ConsultationChatItem) => {
          return currentMessage.messageRaw === newPusherMessage.messageRaw
            ? newPusherMessage
            : currentMessage
        })
      })

      return
    }

    if (isUserMessage) { // doctor message
      setIsDoctorTyping(false)
    } else { // system message
      if (newPusherMessage.message === ChatMessageMessageType.CHAT_CLOSED_MESSAGE) {
        setConsultationClosedMessage(newPusherMessage)
        // DIRTY HACK - we do not have access to chatMessages state, but we have access to setChatMessages state update function!
        setChatMessages(chatMessages => {
          setHasBeenAdditionalMessageSent(isAdditionalMessageSent(chatMessages, userId))
          return chatMessages
        })
        // e/o DIRTY HACK
      }
      if (newPusherMessage.message === ChatMessageMessageType.CHAT_REOPENED_MESSAGE || newPusherMessage.message === ChatMessageMessageType.CHANGE_TERM) {
        setConsultationClosedMessage(null)
      }
    }

    setChatMessages(messages => [...messages, newPusherMessage])
  }

  const addNewPatientMessage = (newPatientMessage: ConsultationChatItem) => {
    setChatMessages(messages => {
      return [...messages, newPatientMessage]
    })
  }
  // e/o manage chat new messages

  // doctor-typing event
  const DOCTOR_TYPING_EVENT_TIMEOUT = 6000
  const [isDoctorTyping, setIsDoctorTyping] = useState<boolean>(false)
  let doctorTypingTimer = 0

  useEffect(() => {
    if (isDoctorTyping) {
      if (doctorTypingTimer) {
        clearTimeout(doctorTypingTimer)
      }
      const setDoctorTypingEnd = () => {
        setIsDoctorTyping(false)
      }

      doctorTypingTimer = window.setTimeout(setDoctorTypingEnd, DOCTOR_TYPING_EVENT_TIMEOUT)
    } else {
      clearTimeout(doctorTypingTimer)
    }
  }, [isDoctorTyping])
  // e/o doctor-typing event

  /* e/o PUSHER EVENTS HANDLES */

  // CHAT PUSHER DOCS: https://github.com/pusher/pusher-js
  const pusherAuthorizer = (channel: Channel) => {
    return {
      authorize: (socketId: string, callback: AuthorizerCallback) => {
        api.request({
          ...postPusherAuthConsultationChannelConfig,
          data: {
            socket_id: socketId,
            channel_name: channel.name,
            consultationId: consultationData.id,
          }
        })
          .then(response => {
            callback(null, {
              auth: response.data.auth,
              channel_data: response.data.channel_data
            })
          })
          .catch(err => {
            console.error(err)
          })
      }
    }
  }

  const triggerVideoInitEvent = (channel: Channel) => {
    // use multiple times just to be sure that pusher delivered event
    channel.trigger(ChatTriggerEventType.INIT_VIDEO, {})
    // event changes flag in v4 code which prevents video from disconnecting after 30s
    // https://github.com/telemedico/v4/blob/b8cb559f858caaaf6023ba5f0a70b7c8b74460d7/assets/js/videoCall.js#L418
  }

  useEffect(() => {
    if (pusherKey) {
      pusher = new Pusher(pusherKey, {
        cluster: "eu",
        authorizer: pusherAuthorizer,
      })

      const channelName = `presence-consultation-${consultationData.id}`
      pusher.connect()
      pusher.subscribe(channelName)
      const channel = pusher.channel(channelName)
      setPusherChannel(channel)

      /* bind events */
      channel.bind(ChatEventType.PUSHER_CHANNEL_SUBSCRIPTION_SUCCEEDED, () => {
        triggerVideoInitEvent(channel)
      })

      channel.bind(ChatEventType.NEW_MESSAGE, (data: ConsultationChatItem) => {
        triggerVideoInitEvent(channel)
        setNewPusherMessage(data)
        scrollToBottom()
      })

      channel.bind(ChatEventType.CLIENT_VIDEO_INVITE, () => {
        triggerVideoInitEvent(channel)
        setInvitationVideoModalOpen(true)
      })

      channel.bind(ChatEventType.CLIENT_VIDEO_CLOSE, () => {
        triggerVideoInitEvent(channel)
        // There may be a situation where the doctor comes in to the chat first and starts the video, and then the patient comes in.
        // The video will then disconnect after about 30 seconds.
        // This event trigger is used to ensure that the connection is not dropped during the next video call.
        setVideoChatOpen(false)
      })

      channel.bind(ChatEventType.DOCTOR_TYPING, () => {
        setIsDoctorTyping(true)
      })

      channel.bind(ChatEventType.DOCTOR_DATA, (data: InstantChatDoctorDataType) => {
        setInstantChatDoctorData(data)
      })
      /* e.o bind events */
    }

    return () => {
      if (pusher) {
        pusher.disconnect()
      }
    }
  }, [pusherKey])

  if (loading) {
    return (
      <Box className="page-loader-box">
        <ChatLoader/>
      </Box>
    )
  }

  return (
    <>
      <ConsultationChat
        consultationData={consultationData}
        chatMessages={chatMessages}
        isDoctorTyping={isDoctorTyping}
        subtitleMessage={subtitleMessage}
        consultationClosedMessage={consultationClosedMessage}
        hasBeenAdditionalMessageSent={hasBeenAdditionalMessageSent}
        addNewPatientMessage={addNewPatientMessage}
        instantChatDoctorData={instantChatDoctorData}
      />

      { pusherChannel && (
        isTwilioVideoType
          ? (
            <ConsultationTwilioVideo
              pusherChannel={pusherChannel}
              consultationId={consultationData.id}
              invitationVideoModalOpen={invitationVideoModalOpen}
              videoChatOpen={videoChatOpen}
              setInvitationVideoModalOpen={setInvitationVideoModalOpen}
              setVideoChatOpen={setVideoChatOpen}
            />
          )
          : (
            <ConsultationVideo
              pusherChannel={pusherChannel}
              consultationId={consultationData.id}
              consultationChatSession={consultationData.chatSession}
              consultationChatSession2={consultationData.chatSession2}
              consultationOpenTokToken={consultationData.openTokToken}
              consultationOpenTokToken2={consultationData.openTokToken2}
              invitationVideoModalOpen={invitationVideoModalOpen}
              videoChatOpen={videoChatOpen}
              setInvitationVideoModalOpen={setInvitationVideoModalOpen}
              setVideoChatOpen={setVideoChatOpen}
            />
          )
      )}
      <ConsultationChatDocumentationModal
        open={isConsultationChatDocumentationModalOpen}
        onClose={handleConsultationChatDocumentationModalClose}
      />
    </>
  )
}

export default ConsultationChatController
