import { alpha, createStyles, makeStyles, Theme } from "@material-ui/core"

export const useAwardDetailsStyles = makeStyles((theme: Theme) => createStyles({
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    background: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      height: "auto",
      padding: theme.spacing(5, 10),
    },
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    width: 160,
    height: 100,
    minWidth: 160,
    overflow: "hidden",
    borderRadius: theme.spacing(1.5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      width: 198,
      height: 120,
      minWidth: 198,
    },
  },
  image: {
    width: "100%",
  },
  awardTitle: {
    fontWeight: 700,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1.5),
  },
  submitButton: {
    width: "100%",
    marginTop: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      width: "unset",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(3),
    },
  },
  descWrapper: {
    width: "100%",
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up("sm")]: {
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      order: 1,
    },
  },
  desc: {
    lineHeight: "22px",
    letterSpacing: "0.01em",
    marginTop: theme.spacing(2)
  },
  awardPoints: {
    marginTop: theme.spacing(4),
    color: theme.palette.primary.main,
    "& span": {
      color: theme.palette.primary.dark,
    }
  },
  overlay: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background: alpha(theme.palette.background.paper, 0.3),
  }
}))