import React, {FC} from "react"
import {useController} from "react-hook-form"

interface HiddenInputProps {
  name: string;
}

const HiddenInput: FC<HiddenInputProps> = ({ name }) => {
  useController({ name })

  return (
    <input
      type="hidden"
    />
  )
}

export default HiddenInput