import React, { ChangeEvent,FC } from "react"
import { Box, Typography } from "@material-ui/core"

import CheckboxController from "../../commonFormItems/checkboxController/CheckboxController.component"
import { CheckboxControllerOption } from "../../commonFormItems/checkboxController/CheckboxController.types"

interface MedicalSurveyCheckboxProps {
  name: string;
  label: string;
  options: CheckboxControllerOption[];
  customOnChange?(): void;
}

const MedicalSurveyCheckbox: FC<MedicalSurveyCheckboxProps> = ({
  name,
  label,
  options,
  customOnChange,
}) => {
  const handleChange = (onChange: (value: CheckboxControllerOption["value"]) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
    customOnChange?.()
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      mb={3}
    >
      <Typography
        variant="body1"
      >
        {label}
      </Typography>
      <CheckboxController
        name={name}
        label=""
        disabled={false}
        options={options}
        handleChange={handleChange}
      />
    </Box>
  )
}

export default MedicalSurveyCheckbox