import { createApi } from "@reduxjs/toolkit/dist/query/react"

import api from "../api/api"
import requestDoz from "../api/requestDoz"
import requestGoogleApis from "../api/requestGoogleApis"
import requestWordpress from "../api/wordpress/requestWordpress"

import { baseQuery, ssoBaseQuery } from "./store.queries"

export const v4Service = createApi({
  baseQuery: baseQuery(api),
  reducerPath: "v4",
  endpoints: () => ({}),
})

export const ssoService = createApi({
  baseQuery: ssoBaseQuery(api),
  reducerPath: "sso",
  endpoints: () => ({}),
})

export const dozService = createApi({
  baseQuery: baseQuery(requestDoz),
  reducerPath: "doz",
  endpoints: () => ({}),
})

export const wordpressService = createApi({
  baseQuery: baseQuery(requestWordpress),
  reducerPath: "wordpress",
  endpoints: () => ({}),
})

export const googleApisService = createApi({
  baseQuery: baseQuery(requestGoogleApis),
  reducerPath: "googleApis",
  endpoints: () => ({}),
})
