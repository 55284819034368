import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box} from "@material-ui/core"

import DatePickerController from "../../commonFormItems/datePickerController/DatePickerController.component"
import InfoLabel from "../../infoLabel/InfoLabel.component"
import { checkDateValidity } from "./ExaminationDate.utils"
import {useExaminationDateStyles} from "./ExaminationDate.styles"

interface ExaminationDateProps {
  visitAtDate: string;
}

const ExaminationDate: FC<ExaminationDateProps> = ({ visitAtDate }) => {
  const {t} = useTranslation()
  const classes = useExaminationDateStyles()

  return (
    <Box>
      <DatePickerController
        className={classes.datePicker}
        disablePast
        shouldDisableDate={checkDateValidity(visitAtDate)}
        name="executeDate"
      />
      <InfoLabel>
        {t("referrals:validityDateLabel")}
      </InfoLabel>
    </Box>
  )
}

export default ExaminationDate
