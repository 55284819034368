import React, {FC} from "react"
import {useTranslation} from "react-i18next"

import {ActionType} from "../../../api/queries/getUserActionById"
import ActionThankYouPage from "../../../components/actionThankYouPage/ActionThankYouPage.component"
import {RoutePath} from "../../../../../routes/Routes.types"

//TODO: https://telemedico.atlassian.net/browse/LESS-1615
const OBESITY_TREATMENT_SPECIALIZATION_ID = "6d789b96-6811-4b6c-b04e-a8bf2b1e7e4d"

const BmiActionThankYouPage: FC = () => {
  const {t, i18n} = useTranslation()

  return (
    <ActionThankYouPage
      actionType={ActionType.FILL_QUESTIONNAIRE}
      buttonLabel={t("login:registerLinkText")}
      buttonLink={
        `/${i18n.language}${RoutePath.MAKE_CONSULTATION}?specializationId=${OBESITY_TREATMENT_SPECIALIZATION_ID}`
      }
    />
  )
}

export default BmiActionThankYouPage
