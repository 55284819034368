import {createTheme} from "@material-ui/core"

import {buttonShadows} from "../../shadows"
import {palette} from "../palette"

const theme = createTheme()

const MuiButton = {
  root: {
    padding: "10px 28px",
    maxWidth: "unset",
    "& *": {
      pointerEvents: "none" // prevent target button-label span on click event
    }
  },
  contained: {
    boxShadow: "none",
    "&:hover, &:focus": {
      boxShadow: buttonShadows.shadow1
    },
  },
  containedPrimary: {
    "&:hover, &:focus": {
      backgroundColor: palette.primary.dark,
    },
  },
  outlined: {
    borderWidth: "2px !important", // important for disable style override
    padding: "8px 26px",
    maxWidth: "unset",
    "&:hover, &:focus": {
      boxShadow: buttonShadows.shadow1
    },
  },
  outlinedPrimary: {
    borderColor: palette.primary.main,
    "&:hover, &:focus": {
      backgroundColor: palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  outlinedSecondary: {
    borderColor: palette.secondary.main,
    "&:hover, &:focus": {
      backgroundColor: palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
}

export const buttons = {
  MuiButton,
}
