export type PaymentCardDetailsForm = {
  nameOnCard: string;
  cardNumber: string;
  expiryDate: string;
  securityCode: string;
}

export enum PaymentMethod {
  CARD = "card"
}

export type CreateConsultationPaymentDetails = {
  payment_method_id?: string;
  consultation_id: string;
  currency: string;
}