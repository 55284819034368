import React, {FC} from "react"
import {Box, InputAdornment, TextField as MuiTextField} from "@material-ui/core"

import {dateTestIdMap} from "../../../GenericStepForm.config"
import useTextFields from "./TextFields.utils"
import {Answer, CompletedAnswer} from "../../../GenericStepForm.types"
import {useTextFieldStyles} from "../textField/TextField.styles"

type TextFieldsProps = {
  answers: Answer[];
  onChange: (completedAnswer: CompletedAnswer[]) => void;
  value: CompletedAnswer[];
  errors: string[];
  unit?: string;
  type?: "number" | "text";
}

const TextFields: FC<TextFieldsProps> = ({ 
  value,
  answers,
  unit,
  onChange,
  errors,
  type = "text"
}) => {
  const classes = useTextFieldStyles()
  const { handleChangeAnswer } = useTextFields(onChange, answers, value, unit)

  return (
    <Box className={classes.container}>
      {
        answers.map((answer, index) => (
          <MuiTextField
            key={answer.id}
            label={answer.name}
            inputProps={{
              "data-testid": dateTestIdMap.textFieldInput
            }}
            InputProps={{
              endAdornment: <InputAdornment position="start">{unit}</InputAdornment>,
            }}
            className={classes.textField}
            value={value?.[index]?.value ?? ""}
            onChange={handleChangeAnswer(index)}
            type={type}
            error={!!errors[index]}
            helperText={errors[index]}
          />
        ))
      }
    </Box>
  )
}

export default TextFields
