import React, {FC, Fragment,useEffect} from "react"
import TagManager from "react-gtm-module"
import { useTranslation } from "react-i18next"
import {useHistory, useLocation} from "react-router"
import i18next from "i18next"

import {useAppSelector} from "../hooks/storeHooks"
import usePrevious from "../hooks/usePrevious"
import {selectAuthenticated} from "../store/session/session.selectors"
import {RoutePath} from "./Routes.types"

interface RoutesControllerProps {}

const RoutesController: FC<RoutesControllerProps> = ({children}) => {
  const { i18n } = useTranslation()
  const defaultLang = i18n.language
  const availableLanguages = Object.keys(i18next.services.resourceStore.data)

  const { pathname, search } = useLocation()
  const { replace } = useHistory()
  const authenticated = useAppSelector(selectAuthenticated)
  const hasLangParam = pathname.split("/").some(param => availableLanguages.includes(param))

  /* set locale param as detected by LanguageDetector if there is none */
  const checkLangUrlParam = () => {
    if (!hasLangParam) {
      if (!search && pathname === RoutePath.HOMEPAGE) {
        replace(`/${defaultLang}`)
      } else {
        replace(`/${defaultLang}${pathname}${encodeURIComponent(search)}`)
      }
    }
  }
  /* e/o set locale param if there is none */

  /* manage go to PaymentSuccessPageUr - send consultation_paid event */
  const prevPathname = usePrevious({pathname})
  const loginPageUrl = RoutePath.LOGIN.split("/")[1]
  const consultationPaymentSuccessPageUrl = RoutePath.CONSULTATION_PAYMENT_SUCCESSFULL.split("/")[1]

  const sendConsultationPaidEvent = (consultaconsultationPaymentSuccessPageUrl: string) => {
    const consultationId = consultaconsultationPaymentSuccessPageUrl
      .split("/")
      .filter(x => x)
      .reverse()
      [0]

    // send consultation_paid event - workaround for non-postponing "transaction" events when patient will not log in
    TagManager.dataLayer({
      dataLayer: {
        event: "consultation_paid",
        consultationId: consultationId,
      }
    })
  }

  const manageSendConsultationPaidEvent = () => {
    if (authenticated) { // check if it is CONSULTATION_PAYMENT_SUCCESSFULL page and if prev page was NOT login page with CONSULTATION_PAYMENT_SUCCESSFULL ref to avoid double send event
      const isCurrentConsultationPaymentSuccessPageUrl = pathname.split("/").includes(consultationPaymentSuccessPageUrl)
      const isPrevPathnameLoginPageUrl = prevPathname?.pathname.split("/").includes(loginPageUrl)

      if (isCurrentConsultationPaymentSuccessPageUrl && !isPrevPathnameLoginPageUrl) {
        sendConsultationPaidEvent(pathname)
      }
    } else { // sens event from login page and get data from CONSULTATION_PAYMENT_SUCCESSFULL ref
      if (prevPathname?.pathname) {
        const isCurrentLoginPageUrl = pathname.split("/").includes(loginPageUrl)
        const isPrevPathnameConsultationPaymentSuccessUrl = prevPathname.pathname.split("/").includes(consultationPaymentSuccessPageUrl)

        if (isCurrentLoginPageUrl && isPrevPathnameConsultationPaymentSuccessUrl) {
          sendConsultationPaidEvent(prevPathname.pathname)
        }
      }
    }
  }
  /* e/o manage go to PaymentSuccessPageUr - send consultation_paid event */

  useEffect(() => {
    checkLangUrlParam()
    manageSendConsultationPaidEvent()

    // remove trailing slash from url - it causes bugs with push url method
    if (pathname !== RoutePath.HOMEPAGE && pathname.substring(pathname.length - 1) === "/") {
      replace(pathname.slice(0, -1))
    }
    // e/o remove trailing slash from url

    // scroll to top
    window.scrollTo(0, 0)
    // e/o scroll to top
  }, [pathname])

  return <Fragment>{children}</Fragment>
}

export default RoutesController
