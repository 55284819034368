import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { Box, Button, MenuItem,Typography, useTheme } from "@material-ui/core"
import clsx from "clsx"
import i18next from "i18next"
import { CheckCircle, Circle } from "phosphor-react"

import {localStorageAppLangKey} from "../../../../app.config"
import { setMakeConsultationWidgetConfig } from "../../../../store/makeConsultationWidget/makeConsultationWidget.slice"
import { setLanguage } from "../../../../store/settings/settings.slice"
import LangFlag from "../../langFlag/LangFlag.component"
import {useLangsListStyles} from "./LangsList.styles"

export enum Placement {
  USER_MENU = "userMenu",
  BASE_LAYOUT = "baseLayout"
}

interface LangsListProps {
  placement?: Placement
}

const LangsList: FC<LangsListProps> = ({ placement = Placement.USER_MENU}) => {
  const {t, i18n} = useTranslation()
  const dispatch = useDispatch()
  const classes = useLangsListStyles()
  const theme = useTheme()
  const {push, location, go} = useHistory()

  const currentLang = i18n.language
  const isOnBaseLayout = placement === Placement.BASE_LAYOUT
  const supportedLanguages = i18next?.services?.resourceStore?.options?.supportedLngs || []

  const availableLanguages = Object.keys(i18next.services.resourceStore.data)

  const changeLanguage = (lang: string) => {
    if (lang !== currentLang) {
      const newLocation = location.pathname.replace(currentLang, lang)
      dispatch(setLanguage(lang))
      dispatch(setMakeConsultationWidgetConfig({language: lang}))
      localStorage.setItem(localStorageAppLangKey, lang)
      document.title = i18next.t("appTitle")
      document.documentElement.setAttribute("lang", i18next.language)
      push(newLocation)
      go(0) // force rerender to send requests with new lang data param
    }
  }

  const langButtonItem = (lang: string) => {
    const isCurrentlySelectedLang = lang === currentLang

    return (
      <Button
        onClick={() => changeLanguage(lang)}
        className={clsx(
          classes.langButton,
          isCurrentlySelectedLang && classes.currentLangButton,
          isOnBaseLayout && classes.buttonOnBaseLayout
        )}
      >
        <Box className={classes.flagAndTextWrapper}>
          <LangFlag
            language={lang}
            className={classes.image}
          />

          <Typography variant="h5" component="span">
            { isOnBaseLayout
              ? t(`${lang}Shorthand`)
              : t(`${lang}`)
            }
          </Typography>
        </Box>

        { !isOnBaseLayout && (
          <Box display="flex" pb={.5}>
            { isCurrentlySelectedLang
              ? <CheckCircle
                color={theme.palette.primary.main}
                weight="fill"
                size={theme.spacing(3)}
              />
              : <Circle
                color={theme.palette.grey["200"]}
                weight="bold"
                size={theme.spacing(3)}
              />
            }
          </Box>
        )}
      </Button>
    )
  }

  return isOnBaseLayout
    ? (
      <>
        { availableLanguages
          .filter(lang => !(lang === currentLang) && supportedLanguages.includes(lang))
          .map((lang) => (
            <MenuItem key={lang} className={classes.menuItem}>
              {langButtonItem(lang)}
            </MenuItem>
          ))}
      </>
    )
    : (
      <>
        { availableLanguages
          .filter(lang => supportedLanguages.includes(lang))
          .map((lang) => (
            <React.Fragment key={lang}>
              {langButtonItem(lang)}
            </React.Fragment>
          ))
        }
      </>
    )
}

export default LangsList
