import { SagaIterator } from "redux-saga"
import { call, put, select,takeEvery } from "redux-saga/effects"

import api from "../../api/api"
import { getVouchersDataConfig } from "../../api/routes"
import { selectUserId } from "../session/session.selectors"
import { redirectToError500Page } from "../../utils/handleErrors"

import { getVouchers, setVouchers, setVouchersError } from "./vouchers.slice"

export function* getVouchersSaga(): SagaIterator {
  const userId: ReturnType<typeof selectUserId> = yield select(selectUserId)

  try {
    const {data} = yield call(api.request, {...getVouchersDataConfig(userId)})
    yield put(setVouchers(data))
  } catch (e) {
    yield put(setVouchersError({
      data: e.response.data,
      status: e.response.status,
    }))
    redirectToError500Page(e)
  }
}

export default function* (): SagaIterator {
  yield takeEvery(getVouchers, getVouchersSaga)
}
