import React, { FC } from "react"
import { Typography } from "@material-ui/core"

import workRelatedProblemsImg
  from "../../../../../assets/images/healthPrevention/mentalHealth/work-related-problems.png"
import HealthPreventionSelfImprovement, {
  HealthPreventionSelfImprovementContentBox,
  HealthPreventionSelfImprovementHeaderBox
} from "../../../../../components/healthPrevention/selfImprovement/HealthPreventionSelfImprovement.component"
import ImageByIntegrationType from "../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { RoutePath } from "../../../../../routes/Routes.types"

import { mentalHealthSelfImprovementBoxItems } from "./SelfImprovementLowMood"

const MentalHealthSelfImprovementWorkRelatedProblemsPage: FC = () => {
  const translationKeyPrefix = "mentalHealth:selfImprovement:items:workRelatedProblems"

  const getImage = (src: string, path: string) => {
    return <ImageByIntegrationType imageSrc={src} alt={path} imagePath={path}/>
  }

  const headerBox: HealthPreventionSelfImprovementHeaderBox = {
    title: `${translationKeyPrefix}:title`,
    content: `${translationKeyPrefix}:body`,
    redirectRoute: RoutePath.MENTAL_HEALTH_SELF_IMPROVEMENT,
    image: getImage(workRelatedProblemsImg, "work-related-problems.png"),
    backBtnLabel: "consultation:back",
  }

  const contentBox: HealthPreventionSelfImprovementContentBox = {
    content: <>
      <Typography>
        <strong>Stres i Przeciążenie Obowiązkami:</strong>
        <br /><br />
        Stres związany z pracą i przeciążenie obowiązkami mogą prowadzić do wypalenia zawodowego i pogorszenia zdrowia psychicznego i fizycznego. Warto szukać metod redukcji stresu, takich jak regularna praktyka relaksacyjna, delegowanie zadań, czy też ustalenie priorytetów w pracy.
        <br /><br />
        <strong>Konflikty w Zespole Pracowniczym:</strong>
        <br /><br />
        Konflikty między współpracownikami lub z przełożonymi mogą utrudniać efektywną pracę i obniżać morale. Ważne jest szukanie konstruktywnych sposobów rozwiązywania konfliktów, takich jak otwarta komunikacja, mediacje, czy też poszukiwanie kompromisów.
        <br /><br />
        <strong>Brak Perspektyw Rozwoju Zawodowego:</strong>
        <br /><br />
        Brak możliwości awansu lub brak satysfakcjonujących perspektyw rozwoju zawodowego może prowadzić do frustracji i zniechęcenia. W takich sytuacjach warto rozmawiać z przełożonymi o swoich aspiracjach zawodowych, szukać szkoleń i kursów doskonalących, czy też rozważyć zmianę ścieżki kariery.
        <br /><br />
        <strong>Niezadowolenie Zawodowe:</strong>
        <br /><br />
        Niezadowolenie z pracy może prowadzić do obniżenia motywacji i zaangażowania zawodowego. Warto zastanowić się nad przyczynami tego niezadowolenia i poszukać sposobów poprawy sytuacji, czy to poprzez zmianę środowiska pracy, czy też rozwój umiejętności zawodowych.
        <br /><br />
        <strong>Balans Praca - Życie Prywatne:</strong>
        <br /><br />
        Brak równowagi między pracą a życiem prywatnym może prowadzić do wypalenia zawodowego oraz problemów zdrowotnych i rodzinnych. Ważne jest dbanie o odpowiedni balans między pracą a życiem osobistym, planowanie czasu wolnego, oraz wyznaczanie granic między życiem zawodowym a prywatnym.
        <br /><br />
        W przypadku poważnych problemów zawodowych, ważne jest również poszukiwanie wsparcia profesjonalnego, takiego jak terapia psychologiczna czy konsultacje z doradcą zawodowym. Pamiętaj, że radzenie sobie z trudnościami zawodowymi wymaga czasu, determinacji i otwartości na zmiany. Warto szukać różnych rozwiązań i próbować różnych strategii, aby znaleźć te, które najlepiej odpowiadają naszym potrzebom i celom zawodowym.
      </Typography>
    </>
  }

  return <HealthPreventionSelfImprovement
    headerBox={headerBox}
    contentBox={contentBox}
    boxItems={mentalHealthSelfImprovementBoxItems}
  />
}

export default MentalHealthSelfImprovementWorkRelatedProblemsPage
