import {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {useHistory, useParams} from "react-router"

import {SearchParams} from "../../enums/SearchParams"
import { usePostPasswordlessLoginMutation } from "../../store/passwordless/passwordless.api"
import {isBaseQueryError} from "../../store/store.utils"
import {hasOwnProperty} from "../../utils/hasOwnProperty"

export const useHandlePasswordlessMagicLink = () => {
  const { code, magicLinkId } = useParams<{ code: string; magicLinkId: string; }>()
  const { push } = useHistory()
  const { i18n } = useTranslation()
  const [handleLogin, { error }] = usePostPasswordlessLoginMutation()

  useEffect(() => {
    handleLogin({ code, magicLinkId })
  }, [])

  useEffect(() => {
    if (!error) {
      return
    }

    const errorCode = (isBaseQueryError(error) && hasOwnProperty(error.data, "code"))
      ? error.data.code
      : "unknown"

    push(`/${i18n.language}/login?${SearchParams.SSO_ERROR_CODE}=${errorCode}`)
  }, [error])
}
