import React, {Dispatch, FC, useState} from "react"
import {useTranslation} from "react-i18next"
import {Box, CircularProgress, Typography} from "@material-ui/core"

import {useToggle} from "../../../../hooks/useToggle"
import { analytics, LogEventType } from "../../../../services/analytics"
import { useGetCoordinatesQuery } from "../../../../store/geolocation/geolocation.api"
import AppDialog from "../../../common/appDialog/AppDialog.component"
import AddressForm from "./addressForm/AddressForm.component"
import LocationRadioButton from "./locationRadioButton/LocationRadioButton.component"
import {useAddressManager, useInitializeCoordinates} from "./AddressSearchEngine.utils"
import {useAddressSearchEngineStyles} from "./AddressSearchEngine.styles"

interface AddressSearchEngineProps {
  setCoordinates: Dispatch<{
    gps_latitude: number;
    gps_longitude: number;
  }>;
}

const AddressSearchEngine: FC<AddressSearchEngineProps> = ({
  setCoordinates
}) => {
  const {t} = useTranslation()
  const [isDialogOpen, { handleToggle, handleOn, setState }] = useToggle(false)
  const { address, setAddress, fullAddress, isCoreAddressDataProvided } = useAddressManager()
  const { data: locations = [], isFetching, isLoading, isUninitialized } = useGetCoordinatesQuery(
    {
      city: address?.city,
      street: address?.street,
      houseNumber: address?.house_number,
      postalCode: address?.postal_code
    },
    { skip: !isCoreAddressDataProvided }
  )
  const classes = useAddressSearchEngineStyles({ isCoreAddressDataProvided })
  const [locationId, setLocationId] = useState<string>()
  useInitializeCoordinates(locations, isLoading, setCoordinates, setLocationId)

  const handleOpenAddressModal = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DOZ_FILL_ADRESS_DATA_POPUP_LOAD)
    handleOn()
  }

  return (
    <>
      <Typography
        className={classes.label}
        onClick={handleOpenAddressModal}
      >
        {
          isCoreAddressDataProvided
            ? fullAddress
            : t("orderMedicines:searchEnginePlaceholder")
        }
      </Typography>
      {
        !isCoreAddressDataProvided && (
          <Typography
            variant="caption"
            color="error"
          >
            {t("orderMedicines:missingCoreAddressDataError")}
          </Typography>
        )
      }
      <AppDialog
        open={isDialogOpen}
        onClose={handleToggle(false)}
        title={t("orderMedicines:chooseAddress")}
      >
        <AddressForm
          onSubmit={setAddress}
          defaultValues={address}
        />
        <Box className={classes.locationWrapper}>
          {
            isFetching
              ? <CircularProgress/>
              : !locations.length && !isUninitialized
                ? (
                  <Typography color="error">
                    {t("orderMedicines:notLocationFoundError")}
                  </Typography>
                )
                : locations.map((location) => (
                  <LocationRadioButton
                    key={location.place_id}
                    isChecked={location.place_id === locationId}
                    onChange={() => {
                      setLocationId(location.place_id)
                      setState(false)
                      setCoordinates({
                        gps_latitude: location.lat,
                        gps_longitude: location.lon
                      })
                    }}
                  >
                    {location.display_name}
                  </LocationRadioButton>
                ))
          }
        </Box>
      </AppDialog>
    </>
  )
}

export default AddressSearchEngine
