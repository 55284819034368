import React, {FC} from "react"

import DefaultLayout from "../layout/DefaultLayout"

interface WithDefaultLayoutProps {}

// eslint-disable-next-line react/display-name
const withFooterLessLayout = <T extends WithDefaultLayoutProps>(WrappedComponent: FC<T & WithDefaultLayoutProps>): FC<T> => (props: T) => {
  return (
    <DefaultLayout enableFooter={false}>
      <WrappedComponent {...props} />
    </DefaultLayout>
  )
}

export default withFooterLessLayout
