import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Box, useMediaQuery, useTheme } from "@material-ui/core"
import i18next from "i18next"

import HealthPreventionLibrary, { HealthPreventionLibraryItem } from "../../../../components/healthPrevention/library/HealthPreventionLibrary.component"
import { checkFeatureAvailability } from "../../../../store/clinic/clinicSettings.utils"

interface HealthPreventionLibraryItemsProps {
  items: HealthPreventionLibraryItem[]
  redirectRoute: string
  btnLabel: string
}

const HealthPreventionLibraryItems: FC<HealthPreventionLibraryItemsProps> = ({
  items,
  redirectRoute,
  btnLabel
}) => {
  const { i18n } = useTranslation()
  const theme = useTheme()
  const { push } = useHistory()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const showMentalHealthTab = checkFeatureAvailability().enabledMentalHealth && i18n.language === "pl"

  if (!showMentalHealthTab) {
    push(`/${i18next.language}`)

    return null
  }

  return (
    <Box
      display="flex"
      alignItems={isMdUp ? "flex-start" : "center"}
      flexDirection="column"
      marginLeft={!isMdUp ? theme.spacing(3) + "px" : undefined}
      marginRight={theme.spacing(3) + "px"}
    >
      <Box
        display="flex"
        flexDirection={isMdUp ? "row" : "column"}
        alignItems={isMdUp ? "unset" : "center"}
        flexWrap={"wrap"}
        gridGap={theme.spacing(2)}
        mb={2}
        width="100%"
      >
        <HealthPreventionLibrary
          redirectRoute={redirectRoute}
          items={items}
          btnLabel={btnLabel}
        />
      </Box>
    </Box>
  )
}

export default HealthPreventionLibraryItems
