import {getMedicalExamPointsByPostalCodeConfig} from "../../api/routes"
import {v4Service} from "../store.service"
import { RootState } from "../store.types"
import {MedicalExamPoint} from "./medicalExamsPoints.types"

const medicalExamsPointsApi = v4Service.injectEndpoints({
  endpoints: build => ({
    getExamPoints: build.query<MedicalExamPoint[], string>({
      async queryFn(
        postalCode,
        { getState },
        extraOptions,
        baseQuery
      ) {
        const state = (getState() as RootState)
        const laboratoryId = state.clinic.clinicSettings.patientLaboratories[0]

        const { data, error } = await baseQuery({
          ...getMedicalExamPointsByPostalCodeConfig,
          params: {
            laboratory: laboratoryId,
            postalCode
          }
        })

        if (error) {
          return { error }
        }

        return { data: data?.data as MedicalExamPoint[] }
      },
    })
  })
})

export const { useGetExamPointsQuery } = medicalExamsPointsApi
