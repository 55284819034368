import React, { ChangeEvent,FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { Box, Tab, Tabs, Typography,useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"

import { GET_USER_AWARDS, UserAwardsResponseData } from "../../api/queries/getUserAwardsQuery"
import Loader from "../../../../components/loader/Loader.component"
import AwardsListItem from "./awardsListItem/AwardsListItem.component"
import AwardsListReceivedItem from "./awardsListReceivedItem/AwardsListReceivedItem.component"
import AwardsListTabPanel from "./awardsListTabPanel/AwardsListTabPanel.component"
import UserPointsTile from "./userPointsTile/UserPointsTile.component"
import { useAwards } from "./AwardsList.utils"
import { RoutePath } from "../../../../routes/Routes.types"
import { useAwardsListStyles } from "./AwardsList.styles"

interface AwardsListProps {}

const AwardsList: FC<AwardsListProps> = () => {
  const { t, i18n } = useTranslation()
  const {
    boxCard,
    boxCardUserPoints,
    tab,
    tabPanel,
  } = useAwardsListStyles()
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))
  const [value, setValue] = useState(0)
  const { getNumberOfUserPoints } = useAwards()

  const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue)
  }

  const {
    data: { awards, userPoints, userAwards } = {},
    error: error,
    loading: loading,
  } = useQuery<UserAwardsResponseData>(GET_USER_AWARDS, {
    context: {clientName: "HealthPlanner"},
    fetchPolicy: "cache-and-network",
  })

  const renderActiveAwards = awards?.map( (award: any) => {
    if(!loading && !error) {
      return (
        <AwardsListItem
          key={award.id}
          name={award.name}
          desc={award.description}
          validTo={award.validTo}
          imageUrl={award.imageUrl}
          points={award.points}
          userPoints={getNumberOfUserPoints(userPoints?.aggregate?.sum?.amount)}
          path={`/${i18n.language}${RoutePath.PREVENTION_AWARDS_AWARD.replace(":awardId", award.id)}`}
        />
      )
    }
  })

  const renderReceivedAwards = userAwards?.map( (userAward: any) => {
    if(userAward.voucher && !loading && !error) {
      return (
        <AwardsListReceivedItem
          key={userAward.id}
          name={userAward.voucher.award.name}
          desc={userAward.voucher.award.description}
          imageUrl={userAward.voucher.award.imageUrl}
          points={userAward.voucher.award.points}
          code={userAward.voucher.code}
        />
      )
    }
  })

  return (
    <Box
      display="flex"
      gridGap={theme.spacing(4)}
    >
      {awards && !loading && isLgUp && (
        <Box className={clsx(
          boxCard,
          boxCardUserPoints,
        )}>
          <UserPointsTile
            userPoints={getNumberOfUserPoints(userPoints?.aggregate?.sum?.amount)}
          />
        </Box>
      )}
      <Box
        className={boxCard}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          TabIndicatorProps={{ style: { background: theme.palette.primary.main } }}
        >
          <Tab
            label={t("healthPlanner:awards")}
            className={tab}
            id={`simple-tab-${0}`}
          />
          <Tab
            label={t("healthPlanner:yourAwards")}
            className={tab}
            id={`simple-tab-${1}`}
          />
        </Tabs>
        <AwardsListTabPanel
          className={tabPanel}
          value={value}
          index={0}
        >
          {loading && <Loader />}
          {error && <Typography variant="body1">{t("errors:defaultError")}</Typography>}
          {awards && !loading && !isLgUp && <UserPointsTile userPoints={getNumberOfUserPoints(userPoints?.aggregate?.sum?.amount)} />}
          {awards && !loading && renderActiveAwards}
          {awards?.length === 0 && !loading && (
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{t("healthPlanner:noAvailableAwards")}</Typography>
            </Box>
          )}
        </AwardsListTabPanel>
        <AwardsListTabPanel
          className={tabPanel}
          value={value}
          index={1}
        >
          {loading && <Loader />}
          {error && <Typography variant="body1">{t("errors:defaultError")}</Typography>}
          {awards && !loading && !isLgUp && <UserPointsTile userPoints={getNumberOfUserPoints(userPoints?.aggregate?.sum?.amount)} />}
          {userAwards && !loading && renderReceivedAwards}
          {userAwards?.length === 0 && !loading && (
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{t("healthPlanner:noAvailableAwards")}</Typography>
            </Box>
          )}
        </AwardsListTabPanel>
      </Box>
    </Box>
  )
}

export default AwardsList