import i18next from "i18next"

export const voucherDescriptionTranslationText = (translationKey: string, voucherCode: string, params?: any) => {
  return i18next.t(`finances:voucherCodeToBuy:${voucherCode.toLowerCase()}:summary:${translationKey}`, params)
}

export const voucherExpirationInMonths = (voucherCode: string) => {
  return parseInt(i18next.t(`finances:voucherCodeToBuy:${voucherCode.toLowerCase()}:expirationInMonths`))
}

export const voucherCost = (voucherCode: string, returnFloat?: boolean) => {
  const cost = parseFloat(i18next.t(`finances:voucherCodeToBuy:${voucherCode.toLowerCase()}:discounted`))

  if (returnFloat) {
    return `${cost.toFixed(2)}`.replace(".", ",")
  }

  return cost
}
