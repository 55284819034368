import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import i18next from "i18next"

import { selectClinicSettings } from "../store/clinic/clinicSettings.selectors"
import { clearMakeConsultationConfig, setMakeConsultationWidgetConfig } from "../store/makeConsultationWidget/makeConsultationWidget.slice"
import { generateClinicStyleConfig } from "../store/clinic/clinicSettings.utils"
import { RoutePath } from "../routes/Routes.types"
import {
  MakeConsultationWidgetDataConfig,
} from "../store/makeConsultationWidget/makeConsultationWidget.types"

const useGoToConsultationPage = (
  config?: Partial<MakeConsultationWidgetDataConfig>|null,
  additionalUrlParams?: string
): {
    goToMakeConsultationPage(): void
  } => {
  const { push } = useHistory()
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const clinicSettings = useSelector(selectClinicSettings)

  const goToMakeConsultationPage = () => {
    dispatch(clearMakeConsultationConfig())

    const newConfig = {
      ...config,
      language: i18n.language,
      themeColors: clinicSettings.id ? generateClinicStyleConfig(clinicSettings?.frontendSettings?.style) : [],
      enabledS7HealthIntegration: clinicSettings.id ? clinicSettings?.frontendSettings?.modules.includes("s7health") : false,
      otherMessagesForB2bClinicEnabled: clinicSettings.id ? clinicSettings?.prescriptionsSettings?.otherMessagesForB2bClinicEnabled : false,
      enabledAllianzEwkaIntegration: clinicSettings.id ? clinicSettings?.clinicAllianzSettings?.ewkaVerificationEnabled : false,
      enabledAllianzOnyxIntegration: clinicSettings.id ? clinicSettings?.clinicAllianzSettings?.onyxVerificationEnabled : false,
    }

    dispatch(setMakeConsultationWidgetConfig(newConfig))

    push(`/${i18next.language}${RoutePath.MAKE_CONSULTATION}${additionalUrlParams || ""}`)
  }

  return { goToMakeConsultationPage }
}

export default useGoToConsultationPage
