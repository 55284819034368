import React, {FC} from "react"

import CollapseWrapper, {ButtonPlacement} from "../../common/collapseWrapper/CollapseWrapper.component"
import Recommendation from "../Recommendation.component"
import {PrescriptionModel} from "../../prescription/Prescription.types"
import {RecommendationModel} from "../Recommendation.types"

interface RecommendationsSectionProps {
  recommendations: RecommendationModel[];
  prescriptions: PrescriptionModel[];
  collapsedSize: number;
}

const RecommendationsSection: FC<RecommendationsSectionProps> = ({
  recommendations,
  prescriptions,
  collapsedSize
}) => {
  return (
    <CollapseWrapper
      collapsedSize={collapsedSize}
      toggleButtonText={"showDetails"}
      toggleButtonPlacement={ButtonPlacement.CENTER}
    >
      <Recommendation
        recommendations={recommendations}
        prescriptions={prescriptions}
      />
    </CollapseWrapper>
  )
}

export default RecommendationsSection
