import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { useQueryParam } from "../../../../hooks/useSearchParams"
import { ActionType } from "../../api/queries/getUserActionById"
import LoaderBox from "../../../../components/common/loaderBox/LoaderBox.component"
import ActionThankYouPage from "../../components/actionThankYouPage/ActionThankYouPage.component"
import PageWrapper from "../../components/pageWrapper/PageWrapper.component"
import ThankYouPageQuestionnaire from "../../components/thankYouPageQuestionnaire/ThankYouPageQuestionnaire.component"
import GenericStepForm from "./genericStepForm/GenericStepForm.component"
import { usePreventionQuestionnaireManager } from "./PreventionQuestionnaire.utils"
import { RoutePath } from "../../../../routes/Routes.types"
import { HealthPreventionPrograms } from "../../../../store/clinic/clinicSettings.types"

const PreventionQuestionnaire: FC = () => {
  const { t, i18n } = useTranslation()
  const type = useQueryParam("type")
  const isMentalHealthProgram = type === HealthPreventionPrograms.PROGRAM_MENTAL_HEALTH
  
  const {
    isSuccess,
    isBasicQuestionnaire,
    isLoadingTemplate,
    template,
    points,
    handleSubmit,
    isLoading
  } = usePreventionQuestionnaireManager()

  if (isLoadingTemplate || !template) {
    return <LoaderBox boxType="appDialog" />
  }

  if (!isLoading && isSuccess) {
    return isBasicQuestionnaire ? (
      <ActionThankYouPage
        actionType={ActionType.FILL_QUESTIONNAIRE}
        points={points}
        buttonLink={`/${i18n.language}${RoutePath.PREVENTION}`}
        buttonLabel={t("healthPlanner:backToProphylaxis")}
      />
    ) : (
      <ThankYouPageQuestionnaire
        points={points}
        template={template}
      />
    )
  }

  return (
    <PageWrapper
      promptMessage={t("healthPlanner:leaveQuestionnairePrompt")}
      navigateBackTo={
        isMentalHealthProgram 
          ? RoutePath.MENTAL_HEALTH 
          : RoutePath.PREVENTION}
    >
      <GenericStepForm
        questionnaireTemplate={template}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </PageWrapper>
  )
}

export default PreventionQuestionnaire
