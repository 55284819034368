import {createStyles, makeStyles} from "@material-ui/core"

export const useEPrescriptionStyles = makeStyles(theme => createStyles({
  boldFont: {
    fontWeight: 700
  },
  prescriptionWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  prescriptionTitle: {
    marginBottom: theme.spacing(1)
  },
  drugWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  drug: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1)
  },
  count: {
    minWidth: 50
  },
}))