import React, {FC} from "react"
import {Box, Typography} from "@material-ui/core"
import clsx from "clsx"

import { removeOrphansJS } from "../../../utils/removeOrphansHTML"
import { ChatMessageMessageType, ConsultationChatItem } from "../ConsultationChat.types"
import {useConsultationChatMessageItemStyles} from "./ConsultationChatMessageItem.styles"

interface SystemConsultationChatMessageItemProps extends ConsultationChatItem {}

const SystemConsultationChatMessageItemComponent: FC<SystemConsultationChatMessageItemProps> = ({messageRaw, messageText, message}) => {
  const classes = useConsultationChatMessageItemStyles()
  const displayMessage = (() => {
    switch (message) {
      case ChatMessageMessageType.E_ZLA_ISSUED:
        return messageText
      default:
        return removeOrphansJS(messageRaw)
    }
  })()

  return (
    <Box className={clsx(
      classes.messageItemBox,
      classes.centerBoxMessage
    )}>
      <Box className={classes.widthConditions}>
        <Typography
          className="break-spaces"
          color={"textSecondary"}
          variant={"body2"}
          align="center"
        >
          {displayMessage}
        </Typography>
      </Box>
    </Box>
  )
}

export default SystemConsultationChatMessageItemComponent
