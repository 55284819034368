import { makeStyles } from "@material-ui/core"

import { createStyles, Theme } from "@material-ui/core/styles"

export const buyVoucherSummaryStyles = makeStyles((theme: Theme) => createStyles({
  pageSection: {
    margin: "auto",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
      padding: theme.spacing(4),
      display: "flex",
      flexWrap: "wrap",
    }
  },
  pageTitle: {
    fontSize: 20,
    lineHeight: theme.spacing(3.50) + "px",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(4),
      color: "#3C3F3D",
      fontSize: 36,
      fontWeight: 700,
      lineHeight: theme.spacing(5.5) + "px",
      wordWrap: "break-word",
      width: "100%",
    },
  },
  infoSectionsWrapper: {
    [theme.breakpoints.up("md")]: {
      flex: "1 1 50%",
      borderLeft: `1px solid ${ theme.palette.grey["100"] }`,
      paddingLeft: theme.spacing(3)
    },
  },
  section: {
    display: "flex",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      marginBottom: theme.spacing(4),
    },
  },
  voucherLogoSection: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  },
  yourDataSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(0)
    },
  },
  sectionPatientDetails: {
    flex: "1 1 50%",
    paddingRight: theme.spacing(3),
  },
  promotionRulesSection: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(0),
    },
  },
  promotionRules: {
    color: "#0443A1",
    fontSize: 16,
    fontWeight: 400,
    textDecoration: "underline",
    lineHeight: theme.spacing(2.5) + "px",
    wordWrap: "break-word"
  },
  imageWrapper: {
    flex: 1,
    width: "70px",
    maxWidth: "70px",
    minWidth: "70px",
    marginRight: theme.spacing(3),
  },
  image: {
    maxWidth: "100%",
    width: "100%",
  },
  avatarImageWrapper: {
    [theme.breakpoints.up("md")]: {
      width: "80px",
      maxWidth: "80px",
      minWidth: "80px",
    },
  },
  iconImage :{
    width: theme.spacing(8),
    height: theme.spacing(8),
    top: theme.spacing(-1),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(13),
      height: theme.spacing(13),
    }
  },
  hospitalImage: {
    padding: "10px",
    backgroundColor: "white"
  },
  avatarImage: {
    height: "70px",
    background: "transparent",
    border: `1px solid ${ theme.palette.grey["200"] }`,
    [theme.breakpoints.up("md")]: {
      height: "80px",
    },
  },
  ulDescription: {
    paddingInlineStart: theme.spacing(3),
  },
  sectionTitle: {
    ...theme.typography.h5,
    [theme.breakpoints.up("md")]: {
      ...theme.typography.h4,
    }
  },
  sectionVoucherDetailsTitle: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: theme.spacing(3.25) + "px",
    wordWrap: "break-word",
    paddingBottom: theme.spacing(0.5),
  },
  sectionYourDataTitle: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: theme.spacing(2.75) + "px",
    wordWrap: "break-word",
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      lineHeight: theme.spacing(2) + "px",
    },
  },
  sectionYourDataText: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: theme.spacing(3) + "px",
    wordWrap: "break-word",
  },
  sectionYourData: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  doctorSectionText: {
    [theme.breakpoints.up("md")]: {
      ...theme.typography.h4,
      fontWeight: 400,
    },
  },
  whenSection: {},
  buttonsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      flexWrap: "wrap",
      flexDirection: "row-reverse",
      marginBottom: 0,
      flexBasis: "50%",
      marginLeft: "auto",
    },
  },
  button: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(2),
      width: "calc(50% - 16px)"
    }
  },
  link: {
    ...theme.typography.body2,
    display: "block",
    margin: theme.spacing(1, 0, 3),
    color: theme.palette.primary.dark,
    "&:hover, &:focus": {
      color: theme.palette.primary.darker,
    },
  },
  text: {
    display: "block",
    fontSize: 13,
    fontWeight: 500,
    lineHeight: theme.spacing(2.25) + "px",
    wordWrap: "break-word",
    paddingBottom: theme.spacing(0.5),
  }
}))
