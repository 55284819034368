import {createStyles, makeStyles, Theme} from "@material-ui/core"

export const useExaminationStyles = makeStyles<Theme, { isDisabledRemove: boolean; }>((theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.grey[100]}`,
    marginRight: theme.spacing(1)
  },
  bold: {
    fontWeight: 500
  },
  removeButton: {
    "& path": {
      stroke: props => props.isDisabledRemove ? theme.palette.grey[200] : theme.palette.text.primary
    }
  }
}))
