import React, { FC } from "react"
import { useWatch } from "react-hook-form"

import { CheckboxGroupController } from "../../../commonFormItems/CheckboxGroupController/CheckboxGroupController.component"
import { CheckboxGroupSurveyProps } from "./CheckboxGroupSurvey.types"
import { useCheckboxGroupSurveyStyles } from "./CheckboxGroupSurvey.styles"

export const CheckboxGroupSurvey: FC<CheckboxGroupSurveyProps> = ({
  label,
  name,
  parentName,
  options
}) => {
  const {field} = useCheckboxGroupSurveyStyles()
  const parentValue = useWatch({ name: parentName })

  if(parentName && parentValue !== name) {
    return null
  }

  return (
    <CheckboxGroupController
      name={name}
      label={label}
      options={options}
      optionStyles={field}
    />
  )
}
