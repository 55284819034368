import {ChangeEvent} from "react"

import {Answer, CompletedAnswer} from "../../../GenericStepForm.types"

const useTextFields = (
  onChange: (completedAnswer: CompletedAnswer[]) => void,
  answers: Answer[],
  values: CompletedAnswer[],
  unit?: string
) => {

  const handleChangeAnswer = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const completedAnswers: CompletedAnswer[] = [
      ...(
        values.length
          ? values
          : answers.map(answer => ({ ...answer, value: "" }))
      )
    ]

    completedAnswers[index] = {
      ...answers[index],
      unit,
      value
    }

    onChange(completedAnswers)
  }

  return {
    handleChangeAnswer
  }
}

export default useTextFields
