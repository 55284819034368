import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import { Box, Button, Typography, useTheme } from "@material-ui/core"

import store from "../../../store"
import PageSection from "../../common/pageSection/PageSection.component"

interface NoPOZdeclarationInformationPanelProps {
}

const NoPOZdeclarationInformationPanel: FC<NoPOZdeclarationInformationPanelProps> = () => {
  const {t, i18n} = useTranslation()
  const theme = useTheme()
  const contactPhoneNumber = store.getState().clinic?.clinicSettings?.frontendSettings?.contactPhoneNumber ?? t("serviceDeskPhone")
  const fillInYourDataInfo = t("user:fillInYourDataInfo", {contactPhoneNumber: contactPhoneNumber})

  return (
    <PageSection title={t("user:fillInYourData")}>
      <Box
        mb={7}
        p={5.5}
        borderRadius={theme.shape.borderRadius}
        component="section"
        bgcolor={theme.palette.background.paper}
        display="flex"
        textAlign="center"
      >
        <Box m="auto">
          <Box py={4}>
            <Typography className="break-spaces">
              {fillInYourDataInfo}
            </Typography>
          </Box>
          <Box py={4}>
            <Button
              component={NavLink}
              variant="contained"
              color="primary"
              to={`/${i18n.language}/user-profile#myProfile`}
            >
              {t("user:fillInData")}
            </Button>
          </Box>
        </Box>
      </Box>
    </PageSection>
  )
}

export default NoPOZdeclarationInformationPanel
