import React, {FC} from "react"
import {useFormContext, useWatch} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box, Button, Typography} from "@material-ui/core"

import {useToggle} from "../../../../../../../../hooks/useToggle"
import {useUpdateUserMutation} from "../../../../../../../../store/patients/patients.api"
import ButtonTextUnderlined
  from "../../../../../../../../components/common/buttonTextUnderlined/ButtonTextUnderlined.component"
import SickLeavePatientAddress
  from "../../../../../../../../components/SickLeaveSurvey/SickLeavePatientAddress.component"
import AddressTemplate from "../AddressTemplate/AddressTemplate.component"
import {PatientsRequirementsStepData} from "../../../../../MedicalQuestionnaireStepperForm.types"

const UserAddress: FC = () => {
  const {t} = useTranslation()
  const [isAddressFormDisabled, { handleOff, handleOn }] = useToggle(true)
  const [updateUser, { isLoading }] = useUpdateUserMutation()
  const address = useWatch<PatientsRequirementsStepData, "sickLeave.address">({
    name: "sickLeave.address"
  })
  const { getValues, setValue } = useFormContext<PatientsRequirementsStepData>()
  const form = useFormContext()

  const handleSubmit = async () => {
    const data = getValues("sickLeave.address")

    const isValid = await form.trigger()

    if (!isValid) {
      return
    }

    updateUser({
      address: { ...data }
    })
      .unwrap()
      .then(() => {
        handleOn()
      })
  }

  return (
    <Box>
      {
        isAddressFormDisabled ? (
          <AddressTemplate
            street={address?.street}
            houseNumber={address?.houseNumber}
            flatNumber={address?.apartmentNumber}
            postalCode={address?.postalCode}
            city={address?.city}
            country={address?.country ?? undefined}
          />
        ) : (
          <SickLeavePatientAddress
            fieldsNamePrefix='sickLeave.address.'
            addressFieldsDisabled={isLoading}
            setValue={setValue}
          />
        )
      }
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        {
          isAddressFormDisabled ? (
            <ButtonTextUnderlined
              color="primary"
              onClick={handleOff}
            >
              <Typography variant="body1">
                {t("defaultTranslations:edit")}
              </Typography>
            </ButtonTextUnderlined>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading || isAddressFormDisabled}
            >
              {t("defaultTranslations:save")}
            </Button>
          )
        }
      </Box>
    </Box>
  )
}

export default UserAddress
