import {useParams} from "react-router"

import useGoToConsultationPage from "../../../../../../hooks/useGoToConsultationPage"
import {useCompleteActionMutation} from "../../../../api/mutations/completeAction/completeAction.hook"

export const useHandleRemoteConsultationAction = (specializationID: string) => {
  const { actionId } = useParams<{ actionId: string; }>()
  const [handleCompleteAction] = useCompleteActionMutation()
  const { goToMakeConsultationPage } = useGoToConsultationPage({ specializationID })

  return () => {
    handleCompleteAction({
      variables: {
        actionId
      }
    })
    goToMakeConsultationPage()
  }
}