import {FC} from "react"
import { initReactI18next } from "react-i18next"
import {push} from "connected-react-router"
import i18n, { Resource } from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import useMakeQuery from "../api/graphql/hooks/useMakeQuery"
import {localStorageAppLangKey} from "../app.config"
import useGetSubdomainName from "../hooks/useGetSubdomainName"
import interpolationDatesFormat from "../plugins/date-fns"
import store from "../store"
import {setMakeConsultationWidgetConfig} from "../store/makeConsultationWidget/makeConsultationWidget.slice"
import { setLanguage } from "../store/settings/settings.slice"
import { ClinicType } from "../components/settingsPanel/clinicSelect/Clinic.types"
import {generateClinicStyleConfig} from "../store/clinic/clinicSettings.utils"

import ba from "./ba"
import bg from "./bg"
import cs from "./cs"
import de from "./de"
import en from "./en"
import es from "./es"
import fr from "./fr"
import hr from "./hr"
import it from "./it"
import lt from "./lt"
import pl from "./pl"
import pt from "./pt"
import rs from "./rs"
import ru from "./ru"
import ua from "./ua"

const I18Next: FC = () => {
  const {getClinicSettingsQuery} = useMakeQuery()
  const {subdomain} = useGetSubdomainName()
  const clinicSettingsData = getClinicSettingsQuery(subdomain)
  const settings = clinicSettingsData?.data?.edges?.[0]?.node
  const frontendSettings = settings?.frontendSettings
  const clinicStyles = frontendSettings?.style
  const images = frontendSettings?.images
  const title = frontendSettings?.seo?.title
  const B2BClinic = frontendSettings?.reactAppClinicType === ClinicType.B2B
  const clinicLanguages = settings?.enabledLanguages?.languages
  // i18n resources
  const resources: Resource = {
    pl,
    en,
    es,
    ua,
    pt,
    ru,
    lt,
    de,
    fr,
    cs,
    it,
    hr,
    bg,
    rs,
    ba
  }

  const languages = ["pl", "en", "es", "pt", "ru", "ua", "lt", "de", "fr", "rs", "cs", "it", "hr", "bg", "ba"]
  const availableLanguages = B2BClinic ? (clinicLanguages?.length ? clinicLanguages : languages) : languages
  const storedLng = localStorage.getItem(localStorageAppLangKey)

  const setData = (detectedLang: string) => {

    if (clinicStyles) {
      store.dispatch(setMakeConsultationWidgetConfig({language: detectedLang, themeColors: generateClinicStyleConfig(clinicStyles)}))
    }

    if (images?.favicon) {
      document.getElementById("favicon")?.setAttribute("href", images.favicon)
    }

    document.title = B2BClinic ? (title || i18n.t("appTitle")) : i18n.t("appTitle")
    store.dispatch(setLanguage(detectedLang))
    document.documentElement.setAttribute("lang", detectedLang)
  }

  const setB2BClinicData = async (detectedLang: string) => {
    if (clinicStyles) {
      generateClinicStyleConfig(clinicStyles)
    }
    setData(detectedLang)
  }

  const setDefaultLang = () => {
    const pathName = window.location.pathname
    const langParam = pathName.length > 1

    if (storedLng) {
      if (langParam && !pathName.includes(`/${storedLng}`) || typeof(clinicLanguages) === "undefined") {
        if (!availableLanguages.includes(pathName?.split("/")?.[1]) && availableLanguages.includes(storedLng)) {
          store.dispatch(push(`/${storedLng}`))
          return
        }

        return null
      }
      else if (langParam && !availableLanguages.includes(storedLng)) {
        store.dispatch(push("/"))
        return
      }
      else if (!langParam) {
        return availableLanguages.includes(storedLng) ? storedLng : null
      }

      return storedLng
    }

    return null
  }

  i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      defaultNS: "defaultTranslations",
      lng: setDefaultLang()?.toString(),
      fallbackLng: setDefaultLang() || clinicLanguages?.[0],
      whitelist: availableLanguages,
      supportedLngs: availableLanguages,
      keySeparator: false, // we do not use keys in form messages.welcome
      detection: {
        order: ["path", "navigator"],
      },
      interpolation: {
        format: interpolationDatesFormat, // dates format
        escapeValue: false, // react already safes from xss
      }
    })
    .then(() => {
      const detectedLang = setDefaultLang() || i18n.language

      if (settings?.domain) {
        if (B2BClinic) {
          setB2BClinicData(detectedLang)
        } else {
          setData(detectedLang)
          store.dispatch(setMakeConsultationWidgetConfig({language: detectedLang}))
        }
      }
    })

  return null
}

export default I18Next
