import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import {Link} from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Box, Button, CircularProgress,Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { isAfter } from "date-fns/esm"
import i18next from "i18next"

import {useToggle} from "../../../../hooks/useToggle"
import { selectUserId } from "../../../../store/session/session.selectors"
import { AwardsResponseModel,GET_USER_POINTS, UserPointsResponseModel } from "../../api/queries/getUserPointsQuery"
import ButtonTextUnderlined from "../../../../components/common/buttonTextUnderlined/ButtonTextUnderlined.component"
import Loader from "../../../../components/loader/Loader.component"
import InstructionModal from "./instructionModal/InstructionModal.component"
import { RoutePath } from "../../../../routes/Routes.types"
import { useAwardsTileStyles } from "./AwardsTile.styles"

interface AwardsTileProps {}

const AwardsTile: FC<AwardsTileProps> = () => {
  const { t } = useTranslation()
  const classes = useAwardsTileStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [nextAwardPoints, setNextAwardPoints] = useState<number | null>(null)
  const [userPointsSummary, setUserPointsSummary] = useState(0)
  const [isModalOpen, { handleToggle }] = useToggle()
  const scale = 50 // constant value, defining half of circular progressbar
  const userId = useSelector(selectUserId)

  const getProperPercent = (max: number, current: number): number => {
    const propsPercent = current * 100 / max
    const result = scale * propsPercent / 100

    return result
  }

  const {
    data: { awards, userPoints } = {},
    error: error,
    loading: loading,
  } = useQuery<UserPointsResponseModel>(GET_USER_POINTS, {
    context: {clientName: "HealthPlanner"},
    variables: { userId },
    fetchPolicy: "cache-and-network",
  })

  const getNextAwardPoints = (awards: AwardsResponseModel[], points: number) => {
    const activeAwards = awards && awards.filter((award: AwardsResponseModel) => {
      const validTo = new Date(award.validTo)
      const today = new Date()
        
      if(isAfter(today, validTo)) {
        return
      }
  
      return award
    })

    const nextAward = activeAwards && activeAwards.find((award: AwardsResponseModel) => award.points > points)

    nextAward?.points && setNextAwardPoints(nextAward.points)
  }

  const getNumberOfUserPoints = (points: string): number => {
    if(!points) {
      return 0
    }
    const numberPattern = /\d+/g
    const withoutDecimals = points.split(".").slice(0,1).join()
    const pts = parseInt(withoutDecimals.match( numberPattern )!.join("")) || 0

    return pts
  }

  useEffect(() => {
    if(awards && userPoints && !loading && !error) {
      const points = getNumberOfUserPoints(userPoints?.aggregate?.sum?.amount)
      setUserPointsSummary(points)
      getNextAwardPoints(awards, points)
    }
  },[awards, userPoints])

  return (
    <Box
      px={2}
      py={3}
      className={classes.boxCard}
    >
      <Typography
        variant={isMdUp ? "h3" : "h4"}
        component="h3"
      >
        {t("awards:tileTitle")}
      </Typography>
      {loading 
        ? (
          <Loader
            mt={isMdUp ? "auto" : 3}
            mb={isMdUp ? "auto" : 2}
          />
        )
        : (
          <>
            <Box className={classes.arcProgressWrapper}>
              <Box className={classes.arcProgressWrapperRotated}>
                <CircularProgress
                  variant="determinate"
                  className={classes.bottomArc}
                  size="200px"
                  thickness={4}
                  value={scale}
                />
                <CircularProgress
                  variant="determinate"
                  className={classes.topArc}
                  size="200px"
                  thickness={4}
                  value={
                    (nextAwardPoints && userPointsSummary) 
                      ? userPointsSummary < nextAwardPoints ? getProperPercent(nextAwardPoints, userPointsSummary) : userPointsSummary
                      : userPointsSummary > 0 ? scale : 0
                  }
                />
              </Box>
              <Typography
                variant={"body2"}
                component="span"
                className={classes.pointsLabel}
              >
                {t("awards:points")}
              </Typography>
              <Typography
                variant={isMdUp ? "h3" : "h4"}
                component="span"
                className={classes.points}
              >
                {userPointsSummary}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.nextAwardInfo}
              >
                {nextAwardPoints && t("awards:nextAwardFor", { diff: nextAwardPoints - userPointsSummary, awardAmount: nextAwardPoints})}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
            >
              <ButtonTextUnderlined
                color="primary"
                onClick={handleToggle(true)}
              >
                {t("awards:howItWorks")}
              </ButtonTextUnderlined>
              <InstructionModal
                isOpen={isModalOpen}
                onClose={handleToggle(false)}
              />
              <Button
                component={Link}
                to={`/${i18next.language}${RoutePath.PREVENTION_AWARDS}`}
                color="primary"
                variant="contained"
              >
                {t("awards:awards")}
              </Button>
            </Box>
          </>
        )}
    </Box>
  )
}

export default AwardsTile