import {createStyles, makeStyles} from "@material-ui/core"

import {Theme} from "@material-ui/core/styles"

export const useActiveInsuranceFormFieldsStyles = makeStyles((theme: Theme) => createStyles({
  dropzone: {
    width: "100%"
  },
  error: {
    color: theme.palette.error.main
  }
}))
