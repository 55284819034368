export interface SickLeavesModel {
  total: number;
  items: SickLeaveModel[];
}

export interface SickLeaveModel {
  id: string; //"d9f6f4ad-c2d6-496a-bdb2-c0031b17f07d"
  name: string;
  url: string;
  date: string; // "1900-01-01 00:00:00"
  data: {
    niezdolnoscOd: string; // "2021-06-28"
    niezdolnoscDo: string; // "2021-07-28"
    szpitalOd: string; //Date
    szpitalDo: string; //Date
    kody: string;
    mozeChodzic: YesOrNoPL;
    opieka: YesOrNoPL;
    lekarz: string; //"Lekarz Receptowy"
    data: string; // "2021-06-28"
  },
  consultation_id: string; // "a640d5f4-035f-46c0-bcd1-df07da2b885e"
  specialization_name: string; //"Lekarz ogólny / internista"
  specialization_id: string; // "acc96366-7c58-4c01-92fb-4e2cc0f8f102"
  visit_at: string; // "2021-04-21 16:59:01"

}

export enum YesOrNoPL {
  NO = "NIE",
  YES = "TAK",
}

export interface SickLeaveErrorResponse {
  status: number; //0
  type: string; //"not found"
  title: string; //"Not Found"
  errorType: number; //0
}
