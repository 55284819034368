import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {Reducer} from "redux"

import {NearbyPharmacy, PharmacyType} from "../doz/doz.types"
import {selectedPharmacyState} from "./dozPharmacy.types"

const initialState: selectedPharmacyState = {
  selectedPharmacyData: {
    id: null,
    name: "",
    street: "",
    city: "",
    postcode: "",
    phone: "",
    hours_week: "",
    hours_saturday: "",
    hours_sunday: "",
    all_day: PharmacyType["Day"],
    delivery_time: "",
    gps_latitude: "",
    gps_longitude: "",
  }
}

const dozPharmacySlice = createSlice({
  name: "dozPharmacy",
  initialState,
  reducers: {
    setSelectedPharmacyData: (state, action: PayloadAction<NearbyPharmacy>) => {
      state.selectedPharmacyData = action.payload
    }
  },
})

export const {
  setSelectedPharmacyData
} = dozPharmacySlice.actions

export default dozPharmacySlice.reducer as Reducer<typeof initialState>
