import React, {FC, useEffect} from "react"
import {useFieldArray, useWatch} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {Typography} from "@material-ui/core"

import useClearField from "../../useClearFields.hook"
import CheckboxController from "../../../../../../components/CheckboxController/CheckboxController.component"
import FormSection from "../FormSection/FormSection.component"
import ExamFields from "./ExamsFields/ExamFields.component"
import {PatientsRequirementsStepData} from "../../../MedicalQuestionnaireStepperForm.types"

const ReferralSection: FC = () => {
  const {t} = useTranslation()
  const isDoctorConsultationRequired = useWatch({
    name: "referral.isDoctorConsultationRequired",
  })

  const { fields, remove } = useFieldArray<PatientsRequirementsStepData, "referral.exams">({
    name: "referral.exams"
  })
  useClearField(fields, remove, isDoctorConsultationRequired)

  return (
    <FormSection
      title={
        <Typography
          variant='h3'
        >
          <Trans
            i18nKey="medicalQuestionnaire:referralSectionTitle"
          >
            0 <Typography variant='inherit' component='span' color='primary'>1</Typography>2
          </Trans>
        </Typography>
      }
      description={t("medicalQuestionnaire:referralSectionDescription")}
    >
      {
        !isDoctorConsultationRequired && (
          <ExamFields/>
        )
      }
      <CheckboxController
        name="referral.isDoctorConsultationRequired"
        label={t("medicalQuestionnaire:referralCheckboxLabel")}
      />
    </FormSection>
  )
}

export default ReferralSection
