import { createStyles, makeStyles, Theme } from "@material-ui/core"

import { boxShadows } from "../../../themes/shadows"

export const useErrorPageStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    maxHeight: "100%",
    paddingBottom: 0,
    [theme.breakpoints.up("md")]: {
      height: `calc(100vh - ${theme.spacing(10)}px)`,
      paddingTop: theme.spacing(10),
    }
  },
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    background: "transparent",
    [theme.breakpoints.up("md")]: {
      background: theme.palette.background.paper,
      boxShadow: boxShadows.shadow1,
      padding: theme.spacing(4),
      flexDirection: "row",
    }
  },
  imageWrapper: {
    flex: "1",
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      order: "-1"
    }
  },
  image: {
    width: "100%",
    maxWidth: "400px"
  },
  textContentWrapper: {
    flex: "1",
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
    }
  },
  errorCode: {
    display: "block",
  },
  title: {
    marginBottom: theme.spacing(4),
    marginRight: "auto",
  },
  description: {
    marginBottom: theme.spacing(4),
    marginRight: "auto",
    whiteSpace: "break-spaces",
    "& a": {
      color: theme.palette.primary.dark,
      "&:hover, &:focus": {
        color: theme.palette.primary.darker,
      },
    }
  },
  button: {
    marginBottom: theme.spacing(2),
  }
}))
