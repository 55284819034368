export enum ConsultationEntryPermissionStatus {
  ARCHIVED_WITHOUT_SUCCESS = "ARCHIVED_WITHOUT_SUCCESS", // zakończona nieprawidłowo lub anulowana
  SUCCESSFULLY_COMPLETED = "SUCCESSFULLY_COMPLETED", // zakończona prawidłowo
  NOT_PAYED = "NOT_PAYED", // nieopłacona
  FILL_USER_DATA = "FILL_USER_DATA", // niewypełnione wymagane dane osobowe
  CONFIRMED ="CONFIRMED", // opłacona, niezakończona
  NFZ_VERIFICATION_NEEDED = "VERIFICATION_NEEDED", //weryfikacja deklaracji NFZ, już nie używany mechanizm?
  PAYMENT_IN_PROGRESS = "PAYMENT_REFUNDED", // płatność w trakcie
  PAYMENT_REFUNDED = "PAYMENT_REFUNDED", // zwrot środków za wizytę dokonany
  STATIONARY_CONSULTATION = "STATIONARY_CONSULTATION", // konsultacja stacjonarna
  NONE = "NONE", // brak konsultacji o podanym id
}
