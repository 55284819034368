import { makeStyles } from "@material-ui/core"

import { createStyles, Theme } from "@material-ui/core/styles"

export const buyVoucherPaymentPageStyle = makeStyles((theme: Theme) => createStyles({
  pageSection: {
    margin: "auto",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    padding: theme.spacing(4, 4, 4, 4),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      flexDirection: "row",
      padding: theme.spacing(4, 4, 4, 4),
    }
  },
  content: {
    flex: "1",
  },
  thankYouText: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    wordWrap: "break-word",
    marginBottom: theme.spacing(3),
  },
  bodyText: {
    whiteSpace: "break-spaces",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    wordWrap: "break-word",
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "44px",
    wordWrap: "break-word"
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
      marginTop: "56px",
    },
  },
  spanText: {
    color: "#0443A1"
  },
  imageWrapper: {
    maxHeight: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
  },
  image: {
    width: "100%",
    maxWidth: "300px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "300px",
    }
  },
  stationaryVisitPlace: {
    color: theme.palette.primary.main,
  },
  iconContainer: {
    marginRight: theme.spacing(2),
  }
}))
