import {createStyles, makeStyles} from "@material-ui/core"

export const usePreventionDiseasesStyles = makeStyles(theme => createStyles({
  container: {
    padding: theme.spacing(5, 8),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 4, 4),
    }
  },
  wrapper: {
    marginTop: theme.spacing(5)
  },
  riskDiseases: {
    marginBottom: theme.spacing(5)
  },
  title: {
    margin: theme.spacing(0, 0, 2),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 3, 2)
    }
  },
  addButton: {
    marginTop: theme.spacing(5)
  }
}))