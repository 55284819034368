import {ChangeEvent, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router"

import {PASSWORDLESS_MAGIC_LINK_ID_KEY} from "../../../app.config"
import {useToggle} from "../../../hooks/useToggle"
import {useLazyGetAccountsQuery} from "../../../store/loginAccounts/loginAccounts.api"
import {usePostPasswordlessMagiclinkMutation} from "../../../store/passwordlessMagiclink/passwordlessMagiclink.api"
import {RoutePath} from "../../../routes/Routes.types"
import {LoginAccount} from "../../../store/loginAccounts/loginAccounts.types"

export const useSendAccessCodeInput = () => {
  const [
    getAccounts,
    {
      isLoading: isAccountLoading,
      data: users = [],
      error: getAccountsError
    }
  ] = useLazyGetAccountsQuery()
  const [
    handlePostUserId,
    {
      isLoading: isPasswordlessMagiclinkLoading,
      isSuccess,
      data,
      error: passwordlessMagicLinkError
    }
  ] = usePostPasswordlessMagiclinkMutation()
  const { push } = useHistory()
  const { i18n, t } = useTranslation()
  const [isModalOpen, { handleOff: handleClose, handleOn: handleOpen }] = useToggle()
  const [errorMessage, setErrorMessage] = useState("")
  const [inputValue, setInputValue] = useState("")
  const [inputError, setInputError] = useState("")

  const handleChooseAccount = (account: LoginAccount) => {
    handlePostUserId(account.id)
    handleClose()
  }

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value)
  }

  const handleClickSubmit = () => {
    if (!inputValue) {
      setInputError(t("errors:required"))
      return
    } else {
      setInputError("")
    }

    getAccounts(inputValue, true)
      .unwrap()
      .then((users) => {
        setErrorMessage("")
        if (users.length === 0) {
          setErrorMessage(t("passwordless:userNotFound"))
        } else if (users.length === 1) {
          handlePostUserId(users[0].id)
        } else {
          handleOpen()
        }
      })
  }

  useEffect(()=> {
    if (isSuccess) {
      push(`/${i18n.language}${RoutePath.PASSWORDLESS_ACCESS_CODE}`)
    }
  }, [isSuccess])

  useEffect(() => {
    if (data) {
      sessionStorage.setItem(PASSWORDLESS_MAGIC_LINK_ID_KEY, data.id)
    }
  }, [data])

  return {
    inputError,
    errorMessage,
    apiError: getAccountsError || passwordlessMagicLinkError,
    getAccountsError,
    passwordlessMagicLinkError,
    users,
    inputValue,
    isModalOpen,
    handleClose,
    handleChangeInput,
    handleClickSubmit,
    handleChooseAccount,
    isLoading: isAccountLoading || isPasswordlessMagiclinkLoading
  }
}
