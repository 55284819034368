import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {Link} from "react-router-dom"
import {Box, Button, Paper, Typography} from "@material-ui/core"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

import {SsoErrorCode} from "../../../enums/SsoErrorCode"
import {usePostPasswordlessLoginVerifyMutation} from "../../../store/passwordless/passwordless.api"
import {selectSession} from "../../../store/session/session.selectors"
import Alert from "../../../components/alert/Alert.component"
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader.component"
import DatePicker from "../../../components/commonFormItems/datePicker/DatePicker.component"
import {useLoginMagicLinkStyles} from "../../../components/loginMagicLink/LoginMagicLink.styles"
import {isBaseQueryError} from "../../../store/store.utils"
import {useDatePicker} from "./VerifyUserBirthday.utils"
import {useVerifyUserFormStyles} from "../../passwordlessAccessCode/verifyUserCode/VerifyUserCode.styles"

const VerifyUserBirthday: FC = () => {
  const {t} = useTranslation()
  const classes = useVerifyUserFormStyles()
  const datePickerClasses = useLoginMagicLinkStyles()
  const [handleVerify, { isLoading: isLoadingVerify, error }] = usePostPasswordlessLoginVerifyMutation()
  const { datePickerValue, handleChangePicker } = useDatePicker()
  const errorMessage =
    isBaseQueryError(error)
      ? error.data?.code === SsoErrorCode.PASSWORDLESS_BIRTH_DATE_NOT_MATCH
        ? "passwordless:birthDateNotMatch"
        : "errors:unknownError"
      : undefined
  const session = useSelector(selectSession)
  const isLoading = isLoadingVerify || (session && Object.keys(session).length > 0)

  return (
    <Paper className={classes.container}>
      <Typography
        className={classes.title}
      >
        {t("passwordless:birthDateVerifyTitle")}
      </Typography>
      <Typography
        className={classes.description}
      >
        {t("passwordless:birthDateVerifyDescription")}
      </Typography>
      <DatePicker
        className={classes.input}
        disableFuture
        autoOk
        value={datePickerValue}
        DialogProps={{
          className: datePickerClasses.birthDateCalendar,
        }}
        InputProps={{
          className: datePickerClasses.birthDateInput
        }}
        fullWidth={false}
        views={["year", "month", "date"]}
        okLabel={null}
        openTo="year"
        helperText=""
        error={false}
        onChange={handleChangePicker}
        format="yyyy-MM-dd"
      />
      {
        errorMessage && (
          <Alert
            message={t(errorMessage)}
            className={classes.errorMessage}
          />
        )
      }
      <Box
        className={classes.buttonsWrapper}
      >
        <Link
          className={classes.link}
          to="/"
        >
          <Button
            className={classes.cancelButton}
            variant="outlined"
            color="primary"
            disabled={isLoading}
            startIcon={<ArrowBackIosIcon/>}
          >
            {t("consultation:back")}
          </Button>
        </Link>
        <Button
          className={classes.submitButton}
          variant="contained"
          color="primary"
          startIcon={isLoading && <ButtonLoader/>}
          disabled={isLoading}
          onClick={
            () => handleVerify({
              birthDate: datePickerValue
            })
          }
        >
          {t("send")}
        </Button>
      </Box>
    </Paper>
  )
}

export default VerifyUserBirthday
