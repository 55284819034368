import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const usePreventionActionWrapperStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(8),
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(4)
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2)
    }
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginRight: theme.spacing(5),
    [theme.breakpoints.down("lg")]: {
      marginRight: 0
    }
  },
  typography: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(5)
    }
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  image: {
    maxWidth: 270,
    maxHeight: 270,
    alignSelf: "center",
    [theme.breakpoints.down("lg")]: {
      marginBottom: theme.spacing(2)
    }
  },
  overrideImage: {
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    }
  }
}))