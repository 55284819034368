import { ApolloClient, ApolloLink, from ,HttpLink, InMemoryCache, split } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { getMainDefinition } from "@apollo/client/utilities"
import fetch from "cross-fetch"
import { createClient } from "graphql-ws"

import { healthPlannerHasuraUrl, healthPlannerHasuraUrlWsLink, multiclinicHasuraUrl} from "../../app.config"
import {recordGraphQL} from "../../hooks/useOpenReplayTracker"
import store from "../../store"

const asyncAuthLink = setContext(async (operation, previousContext) => {
  const { headers } = previousContext
  const { session: { token } } = store.getState()

  return {
    ...previousContext,
    headers: {
      ...headers,
      "Authorization": `Bearer ${token}`
    }
  }
})

const Multiclinic = new HttpLink({
  uri: multiclinicHasuraUrl,
  fetch
})

const HealthPlanner = new HttpLink({
  uri: healthPlannerHasuraUrl,
  fetch
})

const wsLink = new GraphQLWsLink(createClient({
  url: healthPlannerHasuraUrlWsLink,
  connectionParams: () => {
    const { session: { token } } = store.getState()
    
    return {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }
  },
}))

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition"
            && definition.operation === "subscription"
    )
  },
  wsLink,
  HealthPlanner,
)

const trackerOpenReplay = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    const operationDefinition = operation.query.definitions[0]
    return recordGraphQL(
      operationDefinition.kind === "OperationDefinition" ? operationDefinition.operation :  "unknown",
      operation.operationName,
      operation.variables,
      result
    )
  })
})

// for react testing - currently unused, could be needed in the future for testing-library

// const MulticlinicNode = new HttpLink({
//   uri: "https://multiclinic-api.tmdi04.com/v1/graphql",
//   fetch
// })
//
// const HealthPlannerNode = new HttpLink({
//   uri: "https://health-planner-hasura-kdjrvjzeuq-ey.a.run.app/v1/graphql",
//   fetch
// })

const multiClinicOpenReplayLink = from([
  trackerOpenReplay,
  new HttpLink({uri: () => multiclinicHasuraUrl})
])

const healthPlannerOpenReplayLink = from([
  trackerOpenReplay,
  new HttpLink({uri: () => healthPlannerHasuraUrl})
])

export const client = new ApolloClient({
  link: ApolloLink.split(
    operation => operation.getContext().clientName === "HealthPlanner",
    ApolloLink.from([asyncAuthLink, link, healthPlannerOpenReplayLink]), //if above
    ApolloLink.from([Multiclinic, multiClinicOpenReplayLink])
  ),
  cache: new InMemoryCache(),
})

// export const clientNode = new ApolloClient({ // currently unused, could be needed in the future for testing-library
//   link: ApolloLink.split(
//     operation => operation.getContext().clientName === "HealthPlanner",
//     ApolloLink.from([asyncAuthLink, HealthPlannerNode]), //if above
//     MulticlinicNode
//   ),
//   cache: new InMemoryCache(),
// })

// e/o for react testing