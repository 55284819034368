import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { push } from "connected-react-router"

import { selectUserId } from "../../store/session/session.selectors"
import { selectUserPozDeclarationActiveStatus } from "../../store/user/user.selectors"
import PageSection from "../../components/common/pageSection/PageSection.component"
import { useNfzResignPageStyles } from "./NfzResign.styles"

const NfzResignPage: FC = () => {
  const { i18n } = useTranslation()
  const userId = useSelector(selectUserId)
  const iframeUrl = `https://resign.telemedi.co/?userId=${userId}`
  const classes = useNfzResignPageStyles()

  const isPozDeclarationActive = useSelector(selectUserPozDeclarationActiveStatus)
  const accessAllowed = isPozDeclarationActive
  const dispatch = useDispatch()

  if (!accessAllowed) {
    dispatch(push(`/${i18n.language}/404`))
  }

  return (
    <PageSection>
      <iframe className={classes.iframe} src={iframeUrl}></iframe>
    </PageSection>
  )
}

export default NfzResignPage
