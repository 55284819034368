import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import LangDropdowndOnBasePage from "../../common/langDropdown/LangDropdownOnBasePage.component"

type LoginMagicLinkTitleNodeProps = {
  title: string;
}

const LoginMagicLinkTitleNode: FC<LoginMagicLinkTitleNodeProps> = ({title}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
    >
      <Typography
        variant={isMdUp ? "h2" : "h4"}
        component="h2"
      >
        { t(`login:${title}`) }
      </Typography>

      <LangDropdowndOnBasePage/>
    </Box>
  )
}

export default LoginMagicLinkTitleNode
