import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useUserProfilePageStyles = makeStyles((theme: Theme) => createStyles({
  gridItem: {
    background: theme.palette.common.white,
    "&:not(:last-child)": {
      [theme.breakpoints.up("md")]: {
        marginRight: "1px",
        marginLeft: "-1px",
        borderRight: `1px solid ${theme.palette.grey[100]}`,
      }
    }
  },
  gridItemContentBox: {
    padding: theme.spacing(0, 5),
    height: "100%",
  },
  gridItemVoucherContentBox: {
    padding: theme.spacing(0, 5),
  }
}))
