import { RootState } from "../store.types"
import { ComingEventType, SettingsState, StagingSettings } from "./settings.types"

import { MAX_COMING_EVENTS_LIMIT } from "./settings.config"

export const selectLanguage: (((state: RootState) => SettingsState["lang"])) = ({settings}) => {
  return settings.lang
}

export const selectComingEventsType: (((state: RootState) => StagingSettings["comingEventsType"])) = ({settings}) => {
  return settings.stagingSettings.comingEventsType
}

export const selectStagingSettingsLoading: (((state: RootState) => SettingsState["stagingSettingsLoading"])) = ({settings}) => {
  return settings.stagingSettingsLoading
}

export const selectStagingSettings: (((state: RootState) => StagingSettings)) = ({settings}) => {
  return settings.stagingSettings
}

export const selectComingEventsLimit: (((state: RootState) => number)) = ({settings}) => {
  switch(settings.stagingSettings.comingEventsType) {
    case(ComingEventType.ALL): return MAX_COMING_EVENTS_LIMIT
    case(ComingEventType.PAGINATION): return 1
    default: return 1
  }
}
