import { createStyles, makeStyles } from "@material-ui/core"

import { colors } from "../../../../../themes/colors"
import { boxShadows } from "../../../../../themes/shadows"
import { Theme } from "@material-ui/core/styles"

export const useAwardsListReceivedItemStyles = makeStyles((theme: Theme) => createStyles({
  listItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.spacing(3),
    width: "100%",
    maxWidth: 500,
    background: theme.palette.background.paper,
    padding: theme.spacing(3, 4),
    margin: "16px auto",
    [theme.breakpoints.up("md")]: {
      border: "none",
      boxShadow: boxShadows.shadow2,
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(2,0),
      padding: theme.spacing(2,1),
      maxWidth: "unset",
      alignItems: "unset",
    },
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 76,
    height: 54,
    minWidth: 76,
    overflow: "hidden",
    borderRadius: theme.spacing(.5),
    [theme.breakpoints.up("md")]: {
      width: 126,
      height: 80,
      minWidth: 126,
      borderRadius: theme.spacing(1.5),
    },
  },
  image: {
    width: "100%",
    borderRadius: theme.spacing(.5),
    [theme.breakpoints.up("md")]: {
      borderRadius: theme.spacing(.5),
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingLeft: theme.spacing(1.5),
  },
  infoTitle: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    textAlign: "left",
    [theme.breakpoints.up("md")]: {
      maxWidth: "unset",
    },
  },
  label: {
    color: colors.telemediBackground,
    background: theme.palette.secondary.main,
    lineHeight: "16px",
    borderRadius: theme.spacing(.5),
    padding: theme.spacing(.5, 1),
    ...theme.typography.subtitle2,
    "&:hover": {
      background: theme.palette.secondary.dark,
    }
  },
  description: {
    textAlign: "left",
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(1),
  },
  pointsLabel: {
    whiteSpace: "nowrap",
    marginLeft: "40px",
    marginBottom: theme.spacing(1),
    fontWeight: 700,
    color: theme.palette.primary.main,
    "& span": {
      color: theme.palette.primary.dark,
    }
  },
  codeWrapper: {
    position: "relative",
    width: "100%",
    maxWidth: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 700,
  },
  codeLabel: {
    position: "absolute",
    top: 0,
    left: 10,
    transform: "translate(0%,-50%)",
    background: theme.palette.background.paper,
    color: theme.palette.primary.dark,
    padding: "0px 5px",
  },
  buttonCopy: {
    minWidth: "40%",
    height: 48,
    background: "rgba(14, 126, 74, .1)",
    color: theme.palette.primary.main,
    borderRadius: 0,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1,3),
  },
}))