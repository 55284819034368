import {createStyles, makeStyles} from "@material-ui/core"

export const useSendAccessCodeInputStyles = makeStyles(theme => createStyles({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  bold: {
    fontWeight: 500,
  },
  input: {
    margin: theme.spacing(3, 0)
  },
  submitButton: {
    alignSelf: "flex-end",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "initial"
    }
  }
}))
