import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import AppDialog from "../../common/appDialog/AppDialog.component"

interface PregnantConfirmationPopupProps {
  isOpen: boolean;
  onClose(): void;
  onChangeAnswer(): void;
}

const PregnantConfirmationPopup: FC<PregnantConfirmationPopupProps> = ({isOpen, onClose, onChangeAnswer}) => {
  const {t} = useTranslation()

  const dialogParams = {
    title: t("defaultTranslations:confirm"),
    actionButtonText: t("prescription:noLabel"),
    closeButtonText: t("prescription:yesLabel"),
    actionButtonProps: {
      onClick: onChangeAnswer
    },
    description: t("prescription:pregnantConfirmation")
  }

  return (
    <AppDialog
      open={isOpen}
      onClose={onClose}
      title={dialogParams.title}
      actionButtonText={dialogParams.actionButtonText}
      actionButtonProps={dialogParams.actionButtonProps}
      closeButtonText={dialogParams.closeButtonText}
    >
      <Box mb={1}>
        <Typography>
          {dialogParams.description}
        </Typography>
      </Box>

    </AppDialog>
  )
}

export default PregnantConfirmationPopup
