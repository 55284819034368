import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useTextLabelStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    maxWidth: "100%",
    display: "inline-block",
    padding: theme.spacing(.5, 1),
    borderRadius: theme.spacing(.5),
  },
  text: {
    color: theme.palette.common.white,
    fontWeight: 700,
  },
  overflow: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }
}))
