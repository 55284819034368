import {Dispatch, useEffect} from "react"

import {NearbyPharmacy} from "../../../store/doz/doz.types"

export const usePharmacyIdFormValueCleanUp = (
  pharmacies: NearbyPharmacy[],
  value: string | undefined,
  onChange: Dispatch<string | undefined>
): void => {
  useEffect(() => {
    const isAnyNewPharmaciesHasChosenId = pharmacies.some(pharmacy => pharmacy?.id?.toString() === value)
    if (!isAnyNewPharmaciesHasChosenId) {
      onChange(undefined)
    }
  }, [pharmacies])
}
