import { createStyles, makeStyles } from "@material-ui/core"

export const useActionThankYouPage = makeStyles(theme => createStyles({
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.paper,
    marginBottom: theme.spacing(4)
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    alignSelf: "flex-start",
    "& span": {
      color: theme.palette.primary.main,
    }
  },

  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}))