import React, { FC, useCallback,useEffect, useState } from "react"
import {FileError, FileRejection,useDropzone} from "react-dropzone"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"
import clsx from "clsx"

import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import {FileWithPreview} from "./FileDropzone.types"
import {useFileDropzoneStyles} from "./FileDropzone.styles"

import {acceptedFileUploadFormats, acceptedFileUploadSizeInBytes, acceptedFileUploadSizeInMB} from "./FileDropzone.config"

interface FileDropzoneProps {
  apiErrors: string[];
  resetApiErrors(): void;
  handleInputChange: (files: FileWithPreview[]) => void;
  customClassUploadDropzone?: string;
}

const IMAGE_TYPE = "image"

const FileDropzone: FC<FileDropzoneProps> = ({
  handleInputChange,
  apiErrors,
  resetApiErrors,
  customClassUploadDropzone,
}) => {
  const {t} = useTranslation()
  const classes = useFileDropzoneStyles()

  const [files, setFiles] = useState<FileWithPreview[]>([])
  const [errors, setErrors] = useState([])

  const acceptedFileFormats = acceptedFileUploadFormats.join(", ")
  const acceptedFileSize = acceptedFileUploadSizeInBytes

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    resetApiErrors()

    setErrors(
      rejectedFiles.reduce((errorList: string[], {file, errors}: FileRejection) => {
        const rejectedFilesErrors = errors.map((e: FileError) => {
          switch (e.code) {
            case "file-invalid-type": {
              return `${t("errors:wrongFileFormat")}: ${file.type}.\n` +
                `${t("chatConsultation:acceptedFileFormats")}: ${acceptedFileFormats}`
            }
            case "file-too-large": {
              const sizeInMB = file.size / 1024 / 1024
              // change file.size Bytes to MB => B / 1024 = kB, kB / 1024 = MB

              return `${t("errors:wrongFileSize")}: ${Math.round(sizeInMB * 100) / 100} MB.\n` +
                `${t("chatConsultation:maxFileSizeInfo")}: ${acceptedFileUploadSizeInMB} MB`
            }
            default: {
              return e.message
            }
          }
        })
        return [...errorList, ...rejectedFilesErrors]
      }, [])
    )

    setFiles(
      acceptedFiles.map((file: FileWithPreview) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    )

    handleInputChange(acceptedFiles)
  }, [handleInputChange])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileFormats,
    multiple: false,
    maxSize: acceptedFileSize,
  })

  const thumbs = files.map((file: FileWithPreview) => (
    <div className={classes.dropzoneThumb} key={file.name}>
      { file.preview && file.type.includes(IMAGE_TYPE)
        ? (
          <img src={file.preview} className={classes.dropzoneImage} alt={file.name} />
        )
        : (
          <Box>{file.name} ({t("chatConsultation:noPreviewFile")})</Box>
        )
      }
    </div>
  ))

  useEffect(() => () => {
    // Make sure to revoke the data urls to avoid memory leaks
    files.forEach(file => {
      if (file.preview) {
        URL.revokeObjectURL(file.preview)
      }
    })
  }, [files])

  const errorsBox = (errorsList: string[]) => (
    <Box pt={1}>
      { errorsList.map((errorMessage, idx) => (
        <Box key={idx}>
          <Typography className="break-spaces" color="error">
            { errorMessage }
          </Typography>
        </Box>)
      )}
    </Box>
  )

  return (
    <Box>
      <Box
        {...getRootProps()}
        className={clsx(
          classes.uploadDropzone,
          customClassUploadDropzone,
          (apiErrors.length || errors.length) && classes.invalidDropzone
        )}
      >
        <input {...getInputProps()} />

        <Box className={classes.contentWrapper}>
          <IconByIntegrationType {...{ component: "span", variant: "h1" }}
            iconName={"icon-file-add"}
            returnTypography={true}
          />

          <Box textAlign="center">
            <Typography variant="h5" component="p">
              { t("chatConsultation:dragFileOrAddHere") }
            </Typography>

            <em>({ t("chatConsultation:maxFileSizeInfo") }: {acceptedFileUploadSizeInMB} MB)</em>
          </Box>
        </Box>
      </Box>

      { !!errors.length && errorsBox(errors)}
      { !!apiErrors.length && errorsBox(apiErrors)}

      <Box mt={2}>
        { files.length > 0 && (
          <Box mb={1}>
            <Typography variant="h5" component="span">
              { t("chatConsultation:selectedFile") }:
            </Typography>
          </Box>
        )}

        {thumbs}
      </Box>
    </Box>
  )
}

export default FileDropzone
