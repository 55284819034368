import {useMutation} from "@apollo/client"

import {
  CreateBmiActionResponse, CreateBmiActionVariables
} from "./createBmiAction.types"

import {CREATE_BMI_ACTION} from "./createBmiAction.query"

export const useCreateBmiActionMutation = (
  variables?: CreateBmiActionVariables
) => {
  return useMutation<
  CreateBmiActionResponse,
  CreateBmiActionVariables
  >(
    CREATE_BMI_ACTION,
    {
      context: { clientName: "HealthPlanner" },
      variables
    }
  )
}