import {useTranslation} from "react-i18next"

import {PharmacyType} from "../../../../store/doz/doz.types"

export const useCreateFullPharmacyAddress = (street: string, postalCode: string, city: string): string => {
  const {t} = useTranslation()
  const correctedPostalCode = postalCode.slice(0, 2) + "-" + postalCode.slice(2)
  return `${t("orderMedicines:streetShortcut")} ${street}, ${correctedPostalCode} ${city}`
}

export const useCreateOpenHours = (
  all_day: PharmacyType,
  hours_week: string,
  hours_saturday: string | null,
  hours_sunday: string | null,
  multiLine?: boolean,
): string | string[]  => {
  const {t} = useTranslation()
  const hoursWeek = t("orderMedicines:openHoursWeek", {hours: hours_week})
  const hoursSaturday = hours_saturday ? t("orderMedicines:openHoursSaturday", {hours: hours_saturday}) : ""
  const hoursSunday = hours_sunday ? t("orderMedicines:openHoursSunday", {hours: hours_sunday}) : ""

  if (all_day === PharmacyType.H24) {
    if (multiLine) {
      return [t("orderMedicines:open24h")]
    }

    return t("orderMedicines:open24h")
  }

  if (multiLine) {
    return [
      hoursWeek,
      hoursSaturday,
      hoursSunday
    ]
  }

  return (
    `${hoursWeek}${hoursSaturday ? `, ${hoursSaturday}` : ""}${hoursSunday ? `, ${hoursSunday}` : ""}`
  )

}
