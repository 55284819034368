import React, {FC} from "react"
import {useParams} from "react-router"
import {Box} from "@material-ui/core"

import CancelConsultationPopup
  from "../../components/consultation/cancelConsultationPopup/CancelConsultationPopup.component"
import {useCancelConsultation} from "./CancelConsultation.utils"

const CancelConsultation: FC = () => {
  const { consultationId } = useParams<{ consultationId: string }>()
  const handleRedirect = useCancelConsultation()

  return (
    <Box>
      <CancelConsultationPopup
        onClose={handleRedirect}
        onSuccess={handleRedirect}
        open={true}
        consultationId={consultationId}
      />
    </Box>
  )
}

export default CancelConsultation