import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import healthPlannerIcon from "../../../../../../assets/svg/healthPlannerIcon.svg"
import mentalHealthIcon from "../../../../../../assets/svg/mentalHealthIcon.svg"
import { LogEventType } from "../../../../../../services/analytics"
import { checkFeatureAvailability } from "../../../../../../store/clinic/clinicSettings.utils"
import { RoutePath } from "../../../../../../routes/Routes.types"
import { PreventionNestedMenuItemLabel, PreventionNestedMenuItemValue } from "./PreventionNestedMenu.types"

const usePreventionNestedMenuListItems = () => {
  const { i18n } = useTranslation()
  const { push } = useHistory()

  const showMentalHealthTab =
      checkFeatureAvailability().enabledMentalHealth
      && i18n.language === "pl"

  const showHealthPlannerTab =
      checkFeatureAvailability().enabledHealthPlanner

  const makePreventionNestedMenuItemList = () => {
    if (!showHealthPlannerTab && !showMentalHealthTab) return []

    return [
      {
        text: PreventionNestedMenuItemValue.HEALTH_PLANNER,
        additionalText: PreventionNestedMenuItemLabel.HEALTH_PLANNER,
        value: PreventionNestedMenuItemValue.HEALTH_PLANNER,
        B2BClinicVisibility: true,
        featureEnabled: showHealthPlannerTab,
        image: healthPlannerIcon,
        imagePath: "healthPlannerIcon.svg",
        onClickHandler: () => {
          push(`/${i18n.language}${RoutePath.PREVENTION}`)
        },
        googleAnalyticsEvent: LogEventType.DASHBOARD_MENU_HEALTHPLANNER_CLICK
      },
      {
        text: PreventionNestedMenuItemValue.MENTAL_HEALTH,
        additionalText: PreventionNestedMenuItemLabel.MENTAL_HEALTH,
        value: PreventionNestedMenuItemValue.MENTAL_HEALTH,
        B2BClinicVisibility: true,
        featureEnabled: showMentalHealthTab ,
        image: mentalHealthIcon,
        imagePath: "mentalHealthIcon.svg",
        onClickHandler: () => {
          push(`/${i18n.language}${RoutePath.MENTAL_HEALTH}`)
        },
        googleAnalyticsEvent: LogEventType.DASHBOARD_MENU_MENTAL_HEALTH_CLICK,
      },
    ]
  }

  return {
    makePreventionNestedMenuItemList
  }
}

export default usePreventionNestedMenuListItems
