import axios from "axios"

import {GOOGLE_APIS_URL} from "../app.config"
import {env} from "../constants/env"

const requestGoogleApis = axios.create({
  baseURL: GOOGLE_APIS_URL,
  params: {
    key: env.GOOGLE_APIS_KEY
  },
  headers: {
    common: {
      Accept: "application/json",
    },
    post: {
      "Content-Type": "application/json",
    },
  },
})

export default requestGoogleApis
