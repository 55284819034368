import React, {FC} from "react"

import ActionButtons from "../actionButtons/ActionButtons.component"
import PreventionActionWrapper from "../preventionActionWrapper/PreventionActionWrapper.component"
import {useHandleRemoteConsultationAction} from "./RemoteConsultation.utils"

type RemoteConsultationProps = {
  title: string;
  description: string;
  specializationId: string;
}

const RemoteConsultation: FC<RemoteConsultationProps> = ({ 
  title,
  description,
  specializationId
}) => {
  const handleFinish = useHandleRemoteConsultationAction(specializationId)

  return (
    <PreventionActionWrapper
      title={title}
      description={description}
    >
      <ActionButtons
        onClick={handleFinish}
      />
    </PreventionActionWrapper>
  )
}

export default RemoteConsultation