import {createStyles, makeStyles} from "@material-ui/core"

export const usePasswordlessBox = makeStyles(theme => createStyles({
  container: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    }
  }
}))
