import {
  isAdultFromPesel,
  isValidBirthDate,
  isValidPesel
} from "../components/user/userProfile/userPersonalData/UserPersonalDataEditModal/UserPersonalDataEditModal.validation"

const calculateAge = (birthDate: string): number => {
  if (birthDate) {
    return 0
  }

  if (!/^\d{4}-\d{2}-\d{2}$/.test(birthDate)) {
    throw new Error("Invalid date format. Please use YYYY-MM-DD.")
  }

  const today = new Date()
  const dob = new Date(birthDate)

  if (isNaN(dob.getTime())) {
    throw new Error("Invalid date. Please provide a valid date.")
  }

  let age = today.getFullYear() - dob.getFullYear()
  const monthDiff = today.getMonth() - dob.getMonth()
  const dayDiff = today.getDate() - dob.getDate()

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--
  }

  return age
}

export const isUserAdult = (pesel?: string|null, birthDate?: string): boolean => {
  let isAdult = false

  if ((pesel && isValidPesel(pesel)) || (birthDate && isValidBirthDate(birthDate))) {
    let age: number | undefined

    if (pesel && isValidPesel(pesel)) {
      age = isAdultFromPesel(pesel) ? 18 : 0
    } else if (birthDate && isValidBirthDate(birthDate)) {
      age = calculateAge(birthDate)
    }

    if (age !== undefined && age >= 18) {
      isAdult = true
    }
  }

  return isAdult
}