import { createStyles, makeStyles } from "@material-ui/core"

import { Theme } from "@material-ui/core/styles"

export const useLatestConsultationsListStyles = makeStyles((theme: Theme) => createStyles({
  skeletonBox: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1, 0),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 0, 6),
    },
  },
  skeleton: {
    height: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up("md")]: {
      height: theme.spacing(16),
      borderRadius: theme.shape.borderRadius,
    },
  },
  perscriptionsPopupInfoTitleDescription: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  perscriptionsPopupInfoDescription: {
    marginBottom: theme.spacing(3),
  },
}))