import bgLocale from "date-fns/locale/bg"
import baLocale from "date-fns/locale/bs"
import csLocale from "date-fns/locale/cs"
import deLocale from "date-fns/locale/de"
import enLocale from "date-fns/locale/en-US"
import esLocale from "date-fns/locale/es"
import frLocale from "date-fns/locale/fr"
import hrLocale from "date-fns/locale/hr"
import itLocale from "date-fns/locale/it"
import ltLocale from "date-fns/locale/lt"
import plLocale from "date-fns/locale/pl"
import ptLocale from "date-fns/locale/pt"
import ruLocale from "date-fns/locale/ru"
import srLocale from "date-fns/locale/sr"
import uaLocale from "date-fns/locale/uk"

export const dateFnsTranslations = {
  en: enLocale,
  es: esLocale,
  pl: plLocale,
  ua: uaLocale,
  rs: srLocale,
  cs: csLocale, 
  it: itLocale, 
  hr: hrLocale, 
  ba: baLocale, 
  bg: bgLocale,
  fr: frLocale,
  lt: ltLocale,
  pt: ptLocale,
  ru: ruLocale,
  de: deLocale
}
