import axios, { AxiosInstance, AxiosResponse, AxiosStatic, CancelStatic, CancelTokenStatic } from "axios"
import { push } from "connected-react-router"
import i18next from "i18next"

import { isDevApiEnv, isStagingApiEnv, ssoMiddlewareHost } from "../app.config"
import store from "../store"
import { catchApiError } from "../store/apiError/apiError.slice"
import { logInError } from "../store/logIn/logIn.slice"
import { clearSession, setSession } from "../store/session/session.slice"
import { redirectToError500Page } from "../utils/handleErrors"

import {
  getExamsConfig,
  getMedicalDocumentsConfig,
  getMedicalExamPointsByPostalCodeConfig,
  logInConfig,
  refreshTokenConfig
} from "./routes"

const STAGING_DEFAULT_API_URL = "https://staging.tmdi04.com"
const PRODUCTION_DEFAULT_API_URL = "https://telemedi.co"

export interface ApiInstance extends AxiosInstance {
  Cancel: CancelStatic;
  CancelToken: CancelTokenStatic;
  isCancel: AxiosStatic["isCancel"];
}

const api: AxiosInstance = axios.create({
  baseURL: "",
  withCredentials: true,
  headers: {
    common: {
      Accept: "application/json",
    },
    post: {
      "Content-Type": "application/json",
    },
  },
})

const ssoMiddlewareExternalUrls: Array<string> = [
  refreshTokenConfig.url,
  logInConfig.url,
  `${ssoMiddlewareHost}/users?disableTokenRefresh=true`,
  `${ssoMiddlewareHost}/complete-two-factor`,
  `${ssoMiddlewareHost}/send-two-factor-code`,
  `${ssoMiddlewareHost}/magic-link/verify`,
  `${ssoMiddlewareHost}/magic-link/simple-verify`,
  `${ssoMiddlewareHost}/magic-link/refresh-token`,
  `${ssoMiddlewareHost}/magic-link/logs`,
  `${ssoMiddlewareHost}/passwordless/login`,
  `${ssoMiddlewareHost}/passwordless/login/verify`,
]

const preventRefreshTokenUrlList: Array<string> = [
  ...ssoMiddlewareExternalUrls,
  getMedicalExamPointsByPostalCodeConfig.url,
  getExamsConfig.url,
  getMedicalDocumentsConfig.url,
]

api.interceptors.request.use((config) => {
  const storeState = store.getState()
  const token = storeState.session.token
  const clinicId = storeState?.clinic?.clinicSettings?.frontendSettings?.clinicId
  const domain = storeState?.clinic?.clinicSettings?.domain

  config.baseURL = isDevApiEnv
    ?  "https://localhost:3000"
    : domain
      ? `//${storeState?.clinic?.clinicSettings?.domain}`
      : isStagingApiEnv ? STAGING_DEFAULT_API_URL : PRODUCTION_DEFAULT_API_URL

  if (token && !config?.url?.includes(ssoMiddlewareHost)) {
    config.headers["X-Authorization"] = `Bearer ${token}`
  }

  if (ssoMiddlewareExternalUrls.includes(config?.url || "") && clinicId) {
    config.headers["x-tenant"] = clinicId
  }

  return config
})

let refreshingToken = false
api.interceptors.response.use(
  async (response: AxiosResponse) => {
    const storeState = store.getState()

    if (refreshingToken || preventRefreshTokenUrlList.includes(response?.config?.url || "")) {
      return response
    }

    const refreshToken = storeState.session.refresh_token

    if (!!refreshToken) {
      refreshingToken = true

      try {
        const { data } = await api.request(
          {
            ...refreshTokenConfig,
            data: { refreshToken },
          }
        )

        store.dispatch(setSession({
          token: data?.token?.accessToken,
          refresh_token: data?.token?.refreshToken,
        }))
      } catch (error) {
        redirectToError500Page(error)
      }
    }
    // wait until response is returned and new refresh token is saved in store
    // setTimeout method without timeout argument pushes function execution to the bottom of browser call stack
    setTimeout(() => {
      refreshingToken = false
    }, 5000)

    return response
  },
  async (error) => {
    switch (error.response?.status) {
      case 401:
        if (store.getState()?.session?.id) {
          store.dispatch(clearSession())
        }
        break
      case 403:
        if (error.response?.data?.detail === "form.login.access_code_locked" || error.response?.data?.detail === "Access Denied.") {
          if (error.response?.data?.detail === "form.login.access_code_locked") {
            store.dispatch(logInError(error.response))
          }
          store.dispatch(push(`/${i18next.language}/login`))
        } else {
          store.dispatch(push(`/${i18next.language}/403`))
        }
        break
      case 422:
        if (error.response?.data?.type === "#user_password") {
          store.dispatch(logInError(error.response))
          store.dispatch(push(`/${i18next.language}/login`))
        }
        break
      case 412:
        store.dispatch(catchApiError(error.response))
        break
    }
    return Promise.reject(error)
  }
)

//add ability to cancel axios requests
Object.defineProperty(api, "Cancel", {
  value: axios.Cancel
})
Object.defineProperty(api, "CancelToken", {
  value: axios.CancelToken
})
Object.defineProperty(api, "isCancel", {
  value: axios.isCancel
})

export default api as ApiInstance
