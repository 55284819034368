import i18next from "i18next"
import * as yup from "yup"

interface OrderMedicinesFormValidationSchema {
  terms_and_conditions: boolean | undefined;
  pharmacy_id: string;
}

export const orderMedicinesFormValidationSchema = (): yup.SchemaOf<OrderMedicinesFormValidationSchema> => yup.object().shape({
  terms_and_conditions: yup.boolean().oneOf([true], i18next.t("orderMedicines:termsAndConditionsRequired")),
  pharmacy_id: yup.string().required(i18next.t("orderMedicines:pharmacyIdRequired"))
})
