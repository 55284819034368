import {createStyles, makeStyles} from "@material-ui/core"

export const useAddDiseaseModalStyles = makeStyles((theme) => createStyles({
  searchInput: {
    marginBottom: theme.spacing(4)
  },
  diseasesWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: theme.spacing(3),
    marginBottom: theme.spacing(4)
  }
}))