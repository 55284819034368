import React, {FC} from "react"
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@material-ui/core"
import clsx from "clsx"

import {TableDocumentationFileType} from "../Documentation.type"
import DocumentationItem from "./docuemntationItem/DocumentationItem.component"
import {useDocumentationsTableStyles} from "./DocumentationTable.styles"

interface DocumentationsTableProps {
  files: TableDocumentationFileType[];
}

const DocumentationsTable: FC<DocumentationsTableProps> =({files}) => {
  const classes = useDocumentationsTableStyles()

  return (
    <TableContainer
      component={Paper}
      className={classes.paper}
    >
      <Table className={classes.table}>
        <TableBody>
          {files.map((file: TableDocumentationFileType, index: number) => (
            <TableRow
              key={`${file.id}-${index}`}
              className={clsx(
                classes.tableRow,
                index === files.length - 1 && classes.noBorderRow
              )}
            >
              <DocumentationItem
                file={file as TableDocumentationFileType}
                documentType={file.documentType}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DocumentationsTable
