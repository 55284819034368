import React, {ChangeEvent, FC, useState} from "react"
import {Controller, useFormContext} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box, Grid} from "@material-ui/core"

import CountrySelect from "../commonFormItems/countrySelect/CountrySelect.component"
import {PostalCodeInputController} from "../commonFormItems/postalCodeInput/PostalCodeInput.component"
import TextFieldController from "../commonFormItems/textFieldController/TextFieldController.component"
import {Countries} from "../commonFormItems/countrySelect/Country.types"
import {useSickLeaveSectionStyles} from "./SickLeaveSurvey.styles"

interface SickLeavePatientAddressProps {
  countriesData?: Countries;
  fieldsNamePrefix?: string;
  addressFieldsDisabled?: boolean;
  nationality?: string;
  otherAddressEdit?: boolean;
  setValue(name: string, value: string): void;
}

const SickLeavePatientAddress: FC<SickLeavePatientAddressProps> = ({
  fieldsNamePrefix,
  countriesData,
  addressFieldsDisabled,
  nationality,
  otherAddressEdit,
  setValue
}) => {
  const {t} = useTranslation()
  const otherAddressPrefix = otherAddressEdit ? "_otherAddress" : ""
  const [selectedCountry, setSelectedCountry] = useState<string>("PL")
  const classes = useSickLeaveSectionStyles()
  const { register } = useFormContext()
  const form = useFormContext()

  const handleOnChangeCountry = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedCountry = event.target.value

    setSelectedCountry(selectedCountry)
    setValue?.(`${fieldsNamePrefix}country_otherAddress`, selectedCountry)
  }

  return (
    <Grid container item spacing={1}>
      <Grid item xs={12} sm={6} lg={8}>
        <TextFieldController
          {...register(`${fieldsNamePrefix}street${otherAddressPrefix}`)}
          name={`${fieldsNamePrefix}street${otherAddressPrefix}`}
          label={t("user:street")}
          disabled={addressFieldsDisabled}
          onChange={(e) => setValue(`${fieldsNamePrefix}street${otherAddressPrefix}`, e.target.value)}
          required
          InputProps={{
            classes:{
              disabled: classes.disabled
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={3} lg={2}>
        <TextFieldController
          {...register(`${fieldsNamePrefix}houseNumber${otherAddressPrefix}`)}
          name={`${fieldsNamePrefix}houseNumber${otherAddressPrefix}`}
          label={t("user:houseNumber")}
          required
          onChange={(e) => setValue(`${fieldsNamePrefix}houseNumber${otherAddressPrefix}`, e.target.value)}
          disabled={addressFieldsDisabled}
          InputProps={{
            classes:{
              disabled: classes.disabled
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={3} lg={2}>
        <TextFieldController
          {...register(`${fieldsNamePrefix}apartmentNumber${otherAddressPrefix}`)}
          name={`${fieldsNamePrefix}apartmentNumber${otherAddressPrefix}`}
          label={t("user:apartmentNumber")}
          disabled={addressFieldsDisabled}
          onChange={(e) => setValue(`${fieldsNamePrefix}apartmentNumber${otherAddressPrefix}`, e.target.value)}
          InputProps={{
            classes:{
              disabled: classes.disabled
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={4} lg={2}>
        <PostalCodeInputController
          {...register(`${fieldsNamePrefix}postalCode${otherAddressPrefix}`)}
          country={nationality ?? selectedCountry}
          name={`${fieldsNamePrefix}postalCode${otherAddressPrefix}`}
          label={t("user:postalCode")}
          required={true}
          loading={!!addressFieldsDisabled}
          additionalProps={{
            classes:{
              disabled: classes.disabled
            }
          }}
          shrink={true}
        />
      </Grid>

      <Grid item xs={12} sm={8} lg={10}>
        <TextFieldController
          {...register(`${fieldsNamePrefix}city${otherAddressPrefix}`)}
          name={`${fieldsNamePrefix}city${otherAddressPrefix}`}
          onChange={(e) => setValue(`${fieldsNamePrefix}city${otherAddressPrefix}`, e.target.value)}
          label={t("user:city")}
          disabled={addressFieldsDisabled}
          required
          InputProps={{
            classes:{
              disabled: classes.disabled
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Box width="100%">
        <Controller
          name={`${fieldsNamePrefix}country${otherAddressPrefix}`}
          control={form.control}
          render={({
            field: {value}
          }) => (
            <CountrySelect
              {...register(`${fieldsNamePrefix}country${otherAddressPrefix}`)}
              fullWidth
              required
              value={nationality ?? value ?? "PL"}
              className={classes.countrySelect}
              disabled={!otherAddressPrefix}
              name={`${fieldsNamePrefix}country${otherAddressPrefix}`}
              onChange={otherAddressEdit ? handleOnChangeCountry : null}
              InputProps={{
                classes:{
                  disabled: classes.disabled
                }
              }}
              countriesData={countriesData}
            />
          )}
        />
      </Box>
    </Grid>
  )
}
export default  React.forwardRef(SickLeavePatientAddress)
