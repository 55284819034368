import React, { FC, useState } from "react"

import ShowMoreButton from "../../common/showMoreButton/ShowMoreButton.component"
import ComingExamDetails from "../comingExamDetails/ComingExamDetails.component"
import {sliceExamsByPage} from "./CommingExams.utils"
import { ExamModel } from "../Exam.types"

interface ComingExamsProps {
  items: ExamModel[];
  isOutdatedVariant?: boolean;
}

const ComingExams: FC<ComingExamsProps> = ({items, isOutdatedVariant}) => {
  const [page, setPage] = useState<number>(1)

  const exams = sliceExamsByPage(items, page)
  const totalExams = items.length

  const getNextPage = () => {
    setPage(pageNumber => ++pageNumber)
  }

  return (
    exams.length
      ? (
        <>
          {
            exams.map(exam => (
              <ComingExamDetails
                key={exam.id}
                isOutdatedVariant={isOutdatedVariant}
                {...exam}
              />
            ))
          }
          {
            totalExams && totalExams > exams.length && (
              <ShowMoreButton
                onClick={getNextPage}
              />
            )
          }
        </>
      ) : null
  )
}

export default ComingExams
