import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "@apollo/client"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"

import { ADD_GLYCEMIA,AddGlycemiaResponse, AddGlycemiaVariables } from "../../api/mutations/addGlycemia"
import {ExaminationType} from "../../api/queries/getUserActionById"
import { GET_USER_GLYCEMIA_MEASUREMENTS, GlycemiaMeasurementsData } from "../../api/queries/getUserGlycemiaMeasurements"
import DiaryMeasurement from "../../components/diaryMeasurement/DiaryMeasurement.component"
import { addGlycemiaSchema } from "../../components/diaryMeasurement/DiaryMeasurement.validation"
import PageWrapper from "../../components/pageWrapper/PageWrapper.component"

const genericInputData = [
  {
    name: "glucose",
    unit: "mmol/l"
  },
]

const DiaryGlycemia: FC = () => {
  const { t } = useTranslation()

  const [getGlycemiaMeasurements, {
    data: { glycemiaMeasurements = [] } = {},
    error: glycemiaMeasurementsError,
    loading: glycemiaMeasurementsLoading,
  }] = useLazyQuery<GlycemiaMeasurementsData>(GET_USER_GLYCEMIA_MEASUREMENTS, {
    context: {clientName: "HealthPlanner"},
  })

  const [addGlycemia, {
    data: addGlycemiaData,
    loading: addGlycemiaLoading,
    error: addGlycemiaError
  }] = useMutation<AddGlycemiaResponse, AddGlycemiaVariables>(ADD_GLYCEMIA, {
    context: { clientName: "HealthPlanner" }
  })

  const addMeasurement = (value: MaterialUiPickersDate, input: string[]) => {
    addGlycemia({
      variables: {
        createdAt: value,
        glycemia: input[0]
      }
    })
  }

  return (
    <PageWrapper
      title={t("healthPlanner:diaryTitles:glycemia")}
    >
      <DiaryMeasurement
        examinationType={ExaminationType.GLYCEMIA}
        diaryTitle={t("healthPlanner:diaryTitles:glycemia")}
        genericInputData={genericInputData}
        inputTitle={t("healthPlanner:addGlycemia")}
        validationSchema={addGlycemiaSchema}
        getRecords={getGlycemiaMeasurements}
        addMeasurement={addMeasurement}
        data={glycemiaMeasurements && glycemiaMeasurements}
        dataLoading={glycemiaMeasurementsLoading}
        dataFetchingError={glycemiaMeasurementsError}
        addMeasurementData={addGlycemiaData}
        addMeasurementLoading={addGlycemiaLoading}
        addMeasurementError={addGlycemiaError}
      />
    </PageWrapper>
  )
}

export default DiaryGlycemia
