import { ConsultationListItemModel } from "../../components/consultation/Consultation.types"
import {RootState} from "../store.types"
import {ConsultationsState} from "./consultations.types"

export const selectComingConsultations: ((state: RootState) => ConsultationListItemModel[]) = ({consultations}) => consultations.coming

export const selectLatestConsultations: ((state: RootState) => ConsultationListItemModel[]) = ({consultations}) => consultations.latest

export const selectCancelledConsultationsVisible: ((state: RootState) => ConsultationsState["cancelledVisible"]) = ({consultations}) => consultations.cancelledVisible

export const selectComingConsultationsTotal: ((state: RootState) => ConsultationsState["comingTotal"]) = ({consultations}) => consultations.comingTotal

export const selectLatestConsultationsTotal: ((state: RootState) => ConsultationsState["latestTotal"]) = ({consultations}) => consultations.latestTotal

export const selectSelectedConsultationLang: ((state: RootState) => ConsultationsState["selectedConsultationLang"]) = ({consultations}) => consultations.selectedConsultationLang
