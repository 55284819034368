import i18next from "i18next"

export const voucherPaymentPageDescriptionTranslationText = (
  translationKey: string, 
  voucherCode: string, 
  page: string, 
  params?: any
) => {
  return i18next.t(`finances:voucherCodeToBuy:${voucherCode.toLowerCase()}:payment:${page}:${translationKey}`, params)
}
