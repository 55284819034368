import {createStyles, makeStyles} from "@material-ui/core"

export const useExaminationDateStyles = makeStyles((theme) => createStyles({
  datePicker: {
    maxWidth: theme.spacing(45.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    }
  },
  examinationValidityInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(4)
  },
  icon: {
    marginRight: theme.spacing(1),
    minWidth: "14px",
    minHeight: "14px"
  }
}))