import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography, useTheme } from "@material-ui/core"

import store from "../../../store"
import PageSection from "../../common/pageSection/PageSection.component"

interface PaymentInProgressInformationPanelProps {}

const PaymentInProgressInformationPanel: FC<PaymentInProgressInformationPanelProps> = () => {
  const {t} = useTranslation()
  const theme = useTheme()
  const contactPhoneNumber = store.getState().clinic?.clinicSettings?.frontendSettings?.contactPhoneNumber ?? t("serviceDeskPhone")
  const waitingForPaymentInfo = t("user:waitingForPaymentInfo", {contactPhoneNumber: contactPhoneNumber})

  return (
    <PageSection title={t("user:waitingForPayment")}>
      <Box
        mb={7}
        p={5.5}
        borderRadius={theme.shape.borderRadius}
        component="section"
        bgcolor={theme.palette.background.paper}
        display="flex"
        textAlign="center"
      >
        <Box m="auto">
          <Box py={4}>
            <Typography className="break-spaces">
              {waitingForPaymentInfo}
            </Typography>
          </Box>
        </Box>
      </Box>
    </PageSection>
  )
}

export default PaymentInProgressInformationPanel
