import React, {ChangeEvent,FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, FormControl, FormControlLabel, Radio, RadioGroup, useMediaQuery, useTheme} from "@material-ui/core"

import {ConsultationType} from "../Consultation.types"
import { useConsultationTermSelectionStyles } from "./ConsultationTermSelection.styles"

type ConsultationSelectChannelProps = {
  consultationType: number;
  getConsultationTerms(consultationType: number, language: string, specializationId: string, day: string): void;
  consultationLanguage: string;
  specializationId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedChannel: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  selectedDay: string;
  selectedChannel: number;
}

const ConsultationSelectChannel: FC<ConsultationSelectChannelProps> = ({
  consultationType, 
  getConsultationTerms, 
  consultationLanguage, 
  specializationId,
  setLoading,
  loading,
  selectedDay,
  setSelectedChannel,
  selectedChannel,
}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const classes = useConsultationTermSelectionStyles()
    
  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value)
    setSelectedChannel(value)

    setLoading(true)

    await getConsultationTerms(value, consultationLanguage, specializationId, selectedDay)

    setLoading(false)
  }
  
  const renderFormControlLabel = (value: number, label: string) => (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={t(label)}
      disabled={loading}
    />
  )

  return (
    <Box
      mt={2}
      px={isMdUp ? theme.spacing(1) : 0}
    >
      <FormControl
        className={classes.channelOptions}
      >
        <RadioGroup
          defaultValue={consultationType}
          value={selectedChannel}
          name="channel"
          onChange={handleChange}
        >
          <Box
            display="flex"
            flexDirection={isMdUp ? "row" : "column"}
            justifyContent="space-between"
          >
            {renderFormControlLabel(ConsultationType.PHONE, "consultation:phone")}
            {renderFormControlLabel(ConsultationType.CHAT, "consultation:chat")}
            {renderFormControlLabel(ConsultationType.VIDEO, "consultation:video")}
          </Box>
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

export default ConsultationSelectChannel
