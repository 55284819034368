import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit"

import { ExamReferralsState } from "./examReferrals.types"

const initialState: ExamReferralsState = {
  items: {},
  loading: false,
  error: null
}

export const examReferralsSlice = createSlice({
  name: "examReferralss",
  initialState,
  reducers: {
    getExamReferrals: (state: ExamReferralsState) => {
      state.loading = true
      state.error = null
      return
    },
    setExamReferrals: (state: ExamReferralsState, action: PayloadAction<ExamReferralsState["items"]>) => {
      state.items = {...state.items, ...action.payload}
      return
    },
    resetExamReferralsLoading: (state: ExamReferralsState) => {
      state.loading = false
      return
    },
    setExamReferralsError: (state: ExamReferralsState, action: PayloadAction<ExamReferralsState["error"]>) => {
      state.error = action.payload
      state.loading = false
      return
    },
    clearExamReferrals: () => {
      return {...initialState}
    }
  }
})

export const {
  getExamReferrals,
  setExamReferrals,
  resetExamReferralsLoading,
  setExamReferralsError,
  clearExamReferrals
} = examReferralsSlice.actions

export default examReferralsSlice.reducer as Reducer<typeof initialState>
