import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"
import dayjs from "dayjs"

import {useTranslateOptions} from "../../PatientsRequirementsForm/SickLeaveSection/SickLeaveSection.hooks"
import FormSection from "../../PatientsRequirementsForm/FormSection/FormSection.component"
import AddressTemplate
  from "../../PatientsRequirementsForm/SickLeaveSection/SickLeaveAddress/AddressTemplate/AddressTemplate.component"
import {EmploymentType, PatientsRequirementsStepData} from "../../../MedicalQuestionnaireStepperForm.types"

type Props = {
  patientsRequirements: PatientsRequirementsStepData
}
const SickLeaveSummary: FC<Props> = ({ patientsRequirements })=>{
  const { t } = useTranslation()
  const { employmentTypeOptions } = useTranslateOptions()
  const formattedStartAt = dayjs(patientsRequirements.sickLeave?.startAt).format("DD/MM/YYYY")
  const formattedEndAt = dayjs(patientsRequirements.sickLeave?.endAt).format("DD/MM/YYYY")

  const employmentTypeLabel = employmentTypeOptions
    .map(({ value, label }) => value === patientsRequirements.sickLeave?.employmentType && label)
  const shouldDisplayPolishNip =
      patientsRequirements.sickLeave?.employmentType == EmploymentType.CIVILIAN_PERSONNEL
      ||  patientsRequirements.sickLeave?.employmentType == EmploymentType.UNIFORMED_SERVICES
  const shouldDisplayForeignNip = patientsRequirements.sickLeave?.employmentType == EmploymentType.FOREIGN_COMPANY
  const moreThanOneNip = patientsRequirements.sickLeave?.nip?.length > 1

  return  (
    <FormSection
      title={<Typography gutterBottom variant='h3' color='primary'>{t("consultationNestedMenu:sickLeaveHeader")}</Typography>}
    >
      <Typography
        variant='body1'
        color='textSecondary'
      >
        {t("medicalQuestionnaire:startDateOfSickLeave")}: { formattedStartAt } - { formattedEndAt }
      </Typography>
      <Typography
        variant='body1'
        color='textSecondary'
      >
        {t("medicalQuestionnaire:employmentTypeLabel")}: { employmentTypeLabel }
      </Typography>
      { shouldDisplayPolishNip && patientsRequirements?.sickLeave?.nip?.map((nip, index) => {
        const nipHeader = moreThanOneNip 
          ? t("consultation:sickLeave:multipleNip", { index: index + 1 })
          : t("consultation:sickLeave:nip")

        return  <Typography
          key={nip.nipItem}
          variant='body1'
          color='textSecondary'
        >
          {nipHeader} {nip.nipItem}
        </Typography>
      })
      }
      { shouldDisplayForeignNip && (
        <Typography
          key={(patientsRequirements as any).nip_foreignWorker}
          variant='body1'
          color='textSecondary'
        >
          {t("consultation:sickLeave:nip")}  {(patientsRequirements as any).nip_foreignWorker}
        </Typography>
      )}
      
      <Box
        display='flex'
        flexDirection='row'
      >
        <Typography
          variant='body1'
          color='textSecondary'
        >
          {t("consultation:sickLeave:mainAddress")}
        </Typography>
        <Box ml={1}>
          <AddressTemplate
            street={patientsRequirements.sickLeave.address?.street}
            houseNumber={patientsRequirements.sickLeave.address?.houseNumber}
            flatNumber={patientsRequirements.sickLeave.address?.apartmentNumber}
            postalCode={patientsRequirements.sickLeave.address?.postalCode}
            city={patientsRequirements.sickLeave.address?.city}
            country={patientsRequirements.sickLeave.address?.country ?? undefined}
          />
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
      >
        <Typography
          variant='body1'
          color='textSecondary'
        >
          {t("medicalQuestionnaire:addressOfResidence")}:
        </Typography>

        <Box ml={1}>
          {
            patientsRequirements.sickLeave.isOtherAddressSameAsUserAddress ? (
              <Typography
                variant='body1'
                color='textSecondary'
              >
                {t("medicalQuestionnaire:sameAsRegisteredAddressOfResidence")}
              </Typography>
            ) : (
              <AddressTemplate
                street={patientsRequirements.sickLeave.addressOfResidence?.street_otherAddress}
                houseNumber={patientsRequirements.sickLeave.addressOfResidence?.houseNumber_otherAddress}
                flatNumber={patientsRequirements.sickLeave.addressOfResidence?.apartmentNumber_otherAddress}
                postalCode={patientsRequirements.sickLeave.addressOfResidence?.postalCode_otherAddress}
                city={patientsRequirements.sickLeave.addressOfResidence?.city_otherAddress}
                country={patientsRequirements.sickLeave.addressOfResidence?.country_otherAddress ?? "PL"}
              />
            )
          }
        </Box>
      </Box>
    </FormSection>)
}

export default SickLeaveSummary