import React, { FC } from "react"
import { Box, Typography, useMediaQuery,useTheme } from "@material-ui/core"

import { useAppSelector } from "../../../../hooks/storeHooks"
import useConsultationLang from "../../../../hooks/useConsultationLang"
import { selectClinicSettings } from "../../../../store/clinic/clinicSettings.selectors"
import ConsultationTypeIcon from "../../../consultation/consultationTypeIcon/ConsultationTypeIcon.component"
import { getComingConsultationTypeText } from "../../../consultation/Consultation.utils"
import { ConsultationType } from "../../../consultation/Consultation.types"
import { useConsultationTypeSelectStyles } from "./ConsultationTypeSelect.styles"

interface ConsultationTypeSelectProps {
  consultationType: ConsultationType;
  isStationaryVisit?: boolean;
}

const ConsultationTypeSelect: FC<ConsultationTypeSelectProps> = ({consultationType, isStationaryVisit}) => {
  const classes = useConsultationTypeSelectStyles()
  const consultationLang = useConsultationLang()
  const theme = useTheme()
  const consultationTypeTextName: string = getComingConsultationTypeText(consultationType, isStationaryVisit)
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))
  const { clinicAllianzSettings: {
    ewkaVerificationEnabled,
    onyxVerificationEnabled
  } } = useAppSelector(selectClinicSettings)
  const isAllianzVerificationEnabled = ewkaVerificationEnabled || onyxVerificationEnabled

  return (
    <Box className={classes.root}>
      <ConsultationTypeIcon
        consultationType={consultationType}
        className={classes.icon}
        isStationaryVisit={isStationaryVisit}
        style={{
          color: isAllianzVerificationEnabled 
            ? (theme?.palette?.text?.detailColor || theme?.palette?.info?.main)
            : theme?.palette?.info?.main
        }}
      />
      <Box pt={isLgUp ? 0 : .5}>
        <Typography
          variant={"body2"}
          component="span"
          className={classes.text}
        >
          { consultationLang.getLabel(`consultation:${consultationTypeTextName}`) }
        </Typography>
      </Box>
    </Box>
  )
}

export default ConsultationTypeSelect
