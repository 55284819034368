import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {Reducer} from "redux"

import { LoginAccountsState } from "./loginAccounts.types"

const initialState: LoginAccountsState = {
  items: [],
  selected: null,
}

const logInAccountsSlice = createSlice({
  name: "loginAccounts",
  initialState,
  reducers: {
    setLoginAccounts: (state: LoginAccountsState, action: PayloadAction<LoginAccountsState["items"]>) => {
      state.items = action.payload
      return
    },
    setSelectedLoginAccount: (state: LoginAccountsState, action: PayloadAction<LoginAccountsState["selected"]>) => {
      state.selected = action.payload
      return
    },
    clearLoginAccounts: (state: LoginAccountsState) => {
      state.items = []
      return
    },
    clearSelectedLoginAccount: (state: LoginAccountsState) => {
      state.selected = null
      return
    },
  }
})

export const {setLoginAccounts, setSelectedLoginAccount, clearLoginAccounts, clearSelectedLoginAccount} = logInAccountsSlice.actions

export default logInAccountsSlice.reducer as Reducer<typeof initialState>
