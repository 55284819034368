import React, { FC } from "react"
import { Link } from "react-router-dom"
import { Box, Button, Typography } from "@material-ui/core"

import { DiaryModel } from "../../../api/queries/getDiariesQuery"
import ImageByIntegrationType from "../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { useDiaryButtonStyles } from "./DiaryButton.styles"

interface DiaryButtonProps {
  title: DiaryModel["title"]
  imageUrl: DiaryModel["imageUrl"]
  path: string;
  imagePath: string|null;
}

const DiaryButton: FC<DiaryButtonProps> = ({ 
  title, 
  imageUrl, 
  path ,
  imagePath
}) => {
  const {
    buttonTile,
    imageWrapper,
    image,
    diaryTitle
  } = useDiaryButtonStyles()

  return (
    <Button
      component={Link}
      to={path}
      className={buttonTile}
    >
      <Box className={imageWrapper}>
        <ImageByIntegrationType
          imageSrc={imageUrl}
          alt="Image"
          className={image}
          imagePath={imagePath}
        />
      </Box>
      <Typography
        variant="subtitle2"
        className={diaryTitle}
      >
        {title}
      </Typography>
    </Button>
  )
}

export default DiaryButton