import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import doctorEmptyState from "../../assets/images/doctor-empty-state.png"
import ImageByIntegrationType from "../imageByIntegrationType/ImageByIntegrationType"
import {useEmptyConsultationsBoxStyles} from "./EmptyConsultationsBox.styles"

interface NoMakeConsultationOptionEmptyBoxProps {}

const NoMakeConsultationOptionEmptyBox: FC<NoMakeConsultationOptionEmptyBoxProps> = () => {
  const theme = useTheme()
  const classes = useEmptyConsultationsBoxStyles()
  const {t} = useTranslation()
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Box
      p={4}
      mb={6}
      borderRadius={theme.shape.borderRadius}
      component="section"
      bgcolor={theme.palette.background.paper}
      className={classes.root}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={isSmUp ? "row": "column"}
        className={classes.rootAdditionalStyle}
      >
        <Box>
          <Typography variant="h2">
            {t("emptyConsultationsBox:header")}
          </Typography>
          <Typography variant="body1" className={classes.emptyStateLabel}>
            {t("emptyConsultationsBox:label")}
          </Typography>
        </Box>
        <Box className={classes.imageContainer}>
          <ImageByIntegrationType
            imageSrc={doctorEmptyState}
            alt="doctorEmptyState"
            className={classes.image}
            imagePath={"doctor-empty-state.png"}
          />
        </Box>
      </Box>
    </Box>
  )
}
export default NoMakeConsultationOptionEmptyBox
