import React, {FC, ReactNode, useState} from "react"
import {useTranslation} from "react-i18next"
import {Box, Grid, Typography, useTheme} from "@material-ui/core"

import ButtonTextUnderlined from "../../common/buttonTextUnderlined/ButtonTextUnderlined.component"
import SafeDataInfoLabel from "../../common/safeDataInfoLabel/SafeDataInfoLabel.component"
import UserDataInfoSection from "../common/UserDataInfoSection.component"
import UserPasswordChangeModal from "./userPasswordChange/UserPasswordChangeModal.component"
import {checkFeatureAvailability} from "../../../store/clinic/clinicSettings.utils"
import {UserDataType} from "../../../store/user/user.types"

const PASSWORD_PLACEHOLDER_CHARACTERS_COUNT = 8

interface UserSecuritySettingsProps {
  user: UserDataType;
  hideChangePassword?: boolean;
  integrationType?: number|null
}

const UserSecuritySettings: FC<UserSecuritySettingsProps> = ({
  hideChangePassword = false,
  integrationType
}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const benefitSystemIntegrationEnabled = checkFeatureAvailability().enableBenefitSystemIntegration

  const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false)
  const handleEditModalOpen = () => {
    setChangePasswordModalOpen(true)
  }
  const handleChangePasswordModalClose = () => {
    setChangePasswordModalOpen(false)
  }

  const dataListItem = (label: string, description?: ReactNode, onClick?: () => void) => (
    <Grid
      container
      component="dl"
      alignItems="baseline"
      spacing={1}
    >
      <Grid
        item
        component="dt"
        xs={4}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          className="break-word"
        >
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        component="dd"
        xs={8}
      >
        <Typography noWrap>
          {description || t("user:noData")}
        </Typography>
        {
          onClick && (
            <ButtonTextUnderlined
              color="primary"
              onClick={onClick}
            >
              {t("change")}
            </ButtonTextUnderlined>
          )
        }
      </Grid>
    </Grid>
  )

  const passwordPlaceholder = Array(PASSWORD_PLACEHOLDER_CHARACTERS_COUNT)
    .fill(null)
    .map((value, index) => (
      <Box
        key={index}
        component="span"
        display="inline-block"
        mx={0.125}
        width={theme.spacing(1)}
        height={theme.spacing(1)}
        bgcolor={theme.palette.text.primary}
        borderRadius="100%"
      />
    ))

  return (
    <>
      <UserDataInfoSection
        title={t("user:securitySettings")}
      >
        {/*Disabled due to lack of functionality*/}
        {/*{dataListItem(*/}
        {/*  t("user:trustedDevices"),*/}
        {/*  user.trusted_devices*/}
        {/*)}*/}

        {/*Disabled due to lack of functionality*/}
        {/*{dataListItem(*/}
        {/*  t("user:twoFactorAuth"),*/}
        {/*  user.two_factor_auth,*/}
        {/*  true*/}
        {/*)}*/}
        {(!benefitSystemIntegrationEnabled && !checkFeatureAvailability().hideChangePassword) && !hideChangePassword ? dataListItem(
          t("user:password"),
          <>{passwordPlaceholder}</>,
          handleEditModalOpen
        ) : null}
        <Box mt={!hideChangePassword ? 5 : 1}>
          <SafeDataInfoLabel integrationType={integrationType}/>
        </Box>
      </UserDataInfoSection>
      <UserPasswordChangeModal
        open={isChangePasswordModalOpen}
        onClose={handleChangePasswordModalClose}
      />
    </>
  )
}

export default UserSecuritySettings
