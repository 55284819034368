import {FC, useEffect} from "react"
import {ReactHooksModule} from "@reduxjs/toolkit/dist/query/react/module"

type Props = {
  fieldsArray: any;
  remove: ()=>void
  condition: boolean;
}

/**
 *
 * @param fieldsArray
 */
const useClearField = ({fieldsArray,remove,condition}) =>{
  useEffect(() => {
    if ( !condition ) {
      return
    }

    fieldsArray.forEach((field, index) =>{
      field = {}
      remove(index)
    })
  }, [condition])

  return true
}

export default useClearField