import { useLocation } from "react-router"

export const useSearchParams = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search)
}

export const useQueryParam = (name: string): string|null => {
  const searchParams = useSearchParams()
  return searchParams.get(name)
}
