import React, { FC } from "react"
import { TypographyProps } from "@material-ui/core"
import clsx from "clsx"

import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import { getConsultationTypeIcon, isStationaryConsultation } from "../Consultation.utils"
import { ConsultationType } from "../Consultation.types"
import { useConsultationTypeIconStyles } from "./ConsultationTypeIcon.styles"

interface ConsultationTypeIconProps extends TypographyProps {
  consultationType: ConsultationType;
  className?: string;
  consultationFinished?: boolean;
  isStationaryVisit?: boolean;
  isOutlineVariant?: boolean;
}

const ConsultationTypeIcon: FC<ConsultationTypeIconProps> = (
  {
    consultationType,
    consultationFinished,
    className,
    isStationaryVisit,
    isOutlineVariant,
    ...props
  }
) => {
  const classes = useConsultationTypeIconStyles()

  const consultationTypeIcon: string = getConsultationTypeIcon(
    consultationType, 
    isStationaryVisit,
    isOutlineVariant
  )
  
  const isStationary = isStationaryConsultation(consultationType) || isStationaryVisit

  let stationaryConsultationClass = ""

  if (isStationary) {
    stationaryConsultationClass = consultationFinished
      ? classes.consultationFinished
      : classes.consultationUnfinished
  }

  return (
    <IconByIntegrationType
      className={clsx(classes.icon, stationaryConsultationClass, className)}
      iconName={consultationTypeIcon} 
      returnTypography={true} 
      {...props} 
    />
  )
}

export default ConsultationTypeIcon
