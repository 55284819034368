import { SurveyExtraQuestions } from "./ExtraQuestions.types"

export const questions: SurveyExtraQuestions[] = [
  {
    type: "radio",
    name: "pregnant",
    title: "Czy jesteś w ciąży?",
    answers: [
      {
        value: "Tak",
        label: "Tak"
      },
      {
        value: "Nie",
        label: "Nie"
      }
    ],
    correctAnswer: "Tak",
    nested: true,
    nestedItems: [
      {
        type: "radio",
        name: "pregnancySickLeave",
        title: "Czy lekarz ma wystawić zwolnienie ciążowe? (pracodawca dostanie informację o Twojej ciąży)",
        answers: [
          {
            value: "Tak",
            label: "Tak"
          },
          {
            value: "Nie",
            label: "Nie"
          }
        ],
        correctAnswer: "Tak",
        nested: true,
        nestedItems: [
          {
            type: "info",
            name: null,
            title: "Załącz skan karty ciąży (w innym przypadku zwolnienie nie może zostać wystawione).",
            answers: null,
            correctAnswer: null,
            nested: false,
            nestedItems: null,
          }
        ]
      }
    ]
  },
  {
    type: "radio",
    name: "chronicDiseases",
    title: "Czy chorujesz na jakieś choroby przewlekłe?",
    answers: [
      {
        value: "Tak",
        label: "Tak"
      },
      {
        value: "Nie",
        label: "Nie"
      }
    ],
    correctAnswer: "Tak",
    nested: true,
    nestedItems: [
      {
        type: "checkboxGroup",
        name: "chronicDiseasesGroup",
        title: "Jakie to choroby?",
        answers: [
          {
            value: "Choroba Autoimmunologiczna",
            label: "Choroba Autoimmunologiczna"
          },
          {
            value: "Choroba Układu Oddechowego",
            label: "Choroba Układu Oddechowego"
          },
          {
            value: "Cukrzyca",
            label: "Cukrzyca"
          },
          {
            value: "Choroba Układu Krążenia",
            label: "Choroba Układu Krążenia"
          },
          {
            value: "Nowotwór",
            label: "Nowotwór"
          },
          {
            value: "Osteoporoza",
            label: "Osteoporoza"
          },
          {
            value: "Padaczka",
            label: "Padaczka"
          },
          {
            value: "AIDS",
            label: "AIDS"
          },
          {
            value: "Otyłość",
            label: "Otyłość"
          }
        ],
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      }
    ]
  },
  {
    type: "radio",
    name: "allergy",
    title: "Czy masz alergię?",
    answers: [
      {
        value: "Tak",
        label: "Tak"
      },
      {
        value: "Nie",
        label: "Nie"
      }
    ],
    correctAnswer: "Tak",
    nested: true,
    nestedItems: [
      {
        type: "textarea",
        name: "allergyDetails",
        title: "Napisz nam na co jesteś uczulony. Pamiętaj również o wpisaniu leków, na które masz alergię. Wpisz po przecinkach.",
        answers: null,
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      }
    ]
  },
  {
    type: "radio",
    name: "regularDrugs",
    title: "Czy przyjmujesz jakieś leki stałe?",
    answers: [
      {
        value: "Tak",
        label: "Tak"
      },
      {
        value: "Nie",
        label: "Nie"
      }
    ],
    correctAnswer: "Tak",
    nested: true,
    nestedItems: [
      {
        type: "textarea",
        name: "regularDrugsDetails",
        title: "Napisz nam jakie leki przyjmujesz na stałe. Wpisz po przecinkach.",
        answers: null,
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      }
    ]
  },
  {
    type: "radio",
    name: "duringSickLeave",
    title: "Czy obecnie przebywasz na zwolnieniu dłużej niż 2 tygodnie ciągiem? Pamiętaj aby podać prawdziwe informacje - lekarz ma możliwość weryfikacji Twojej historii zwolnień.",
    answers: [
      {
        value: "Tak",
        label: "Tak"
      },
      {
        value: "Nie",
        label: "Nie"
      }
    ],
    correctAnswer: "Tak",
    nested: true,
    nestedItems: [
      {
        type: "info",
        name: null,
        title: "Pamiętaj aby załączyć dokumentację z poprzedniego leczenia (w innym przypadku zwolnienie nie zostanie wystawione)",
        answers: null,
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      }
    ]
  },
  {
    type: "radio",
    name: "ailments",
    title: "Co Ci dolega? - Wybierz główną kategorię Twojej dolegliwości",
    answers: [
      {
        value: "coldOrPain",
        label: "Przeziębienie lub bóle"
      },
      {
        value: "stomachIssues",
        label: "Zatrucie i problemy żołądkowe"
      },
      {
        value: "bladderIssues",
        label: "Problemy z pęcherzem"
      },
      {
        value: "injuries",
        label: "Urazy"
      },
      {
        value: "backPain",
        label: "Bóle pleców"
      },
      {
        value: "eyesIssues",
        label: "Problemy z oczami"
      },
      {
        value: "migraine",
        label: "Migrena"
      },
      {
        value: "stressReaction",
        label: "Ostre reakcje na stres / problemy psychologiczne"
      },
      {
        value: "menses",
        label: "Menstruacja / miesiączka"
      },
    ],
    correctAnswer: null,
    nested: true,
    nestedItems: [
      {
        type: "radio",
        name: "ailmentsTime",
        title: "Od ilu dni występują objawy?",
        answers: [
          {
            value: "1",
            label: "Od dzisiaj",
          },
          {
            value: "2",
            label: "Od wczoraj",
          },
          {
            value: "3",
            label: "Od 2-3 dni",
          },
          {
            value: "4",
            label: "Od 4-5 dni",
          },
          {
            value: "5",
            label: "Powyżej 5 dni",
          },
        ],
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      },
      {
        type: "checkboxGroup",
        name: "coldOrPain",
        parentName: "ailments",
        title: "Wybierz, które z poniższych objawów występują w Twoim przypadku",
        answers: [
          {
            value:  1743,
            label: "Gorączka Powyżej 38 Stopni"
          },
          {
            value: 30256,
            label: "Zmęczenie"
          },
          {
            value: 1765,
            label: "Zawroty Głowy"
          },
          {

            value: 13452,
            label: "Dreszcze"
          },
          {
            value: 13452,
            label: "Spuchnięte Migdałki"
          },
          {
            value: 1769,
            label: "Katar"
          },
          {
            value: 13525,
            label: "Zapalenie Zatok"
          },
          {
            value: 1767,
            label: "Kaszel"
          },
          {
            value: 2149,
            label: "Chrypka"
          },
          {
            value: 13525,
            label: "Podrażnione gardło"
          },
          {
            value: 1814,
            label: "Ból Gardła"
          },
          {
            value: 1724,
            label: "Ból Mięśni"
          },
          {
            value: 44398,
            label: "Uczucie Ciężkości W Klatce Piersiowej"
          },
          {
            value: 13447,
            label: "Ból Oczu"
          },
          {
            value: 115,
            label: "Migrenowy Ból Głowy"
          },
          {
            value: 1731,
            label: "Osłabienie Organizmu"
          },
        ],
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      },
      {
        type: "checkboxGroup",
        name: "stomachIssues",
        parentName: "ailments",
        title: "Wybierz, które z poniższych objawów występują w Twoim przypadku",
        answers: [
          {
            value: 1740,
            label: "Brak Apetytu"
          },
          {
            value: 1725,
            label: "Biegunka"
          },
          {
            value: 6649,
            label: "Zatrucie Pokarmowe"
          },
          {
            value: 1742,
            label: "Wymioty"
          },
          {
            value: 1729,
            label: "Ból Brzucha"
          },
        ],
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      },
      {
        type: "checkboxGroup",
        name: "bladderIssues",
        parentName: "ailments",
        title: "Wybierz, które z poniższych objawów występują w Twoim przypadku",
        answers: [
          {
            value: 13448,
            label: "Częste Oddawanie Moczu"
          },
          {
            value: 1794,
            label: "Bolesne Oddawanie Moczu"
          },
          {
            value: 4608,
            label: "Ból Pęcherza"
          },
          {
            value: 1904,
            label: "Parcie Na Mocz"
          },
          {
            value: 13512,
            label: "Skąpomocz"
          },
        ],
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      },
      {
        type: "checkboxGroup",
        name: "injuries",
        parentName: "ailments",
        title: "Wybierz, które z poniższych objawów występują w Twoim przypadku",
        answers: [
          {
            value: 4638,
            label: "Głowa i Czaszka"
          },
          {
            value: 8163,
            label: "Szyja"
          },
          {
            value: 25739,
            label: "Kręgosłup"
          },
          {
            value: 5425,
            label: "Klatka Piersiowa"
          },
          {
            value: 5965,
            label: "Brzuch"
          },
          {
            value: 6799,
            label: "Miednica"
          },
          {
            value: 5456,
            label: "Bark I Ramię"
          },
          {
            value: 25736,
            label: "Przedramię"
          },
          {
            value: 25485,
            label: "Łokieć"
          },
          {
            value: 25233,
            label: "Ręka / Nadgarstek"
          },
          {
            value: 13475,
            label: "Biodro"
          },
          {
            value: 13510,
            label: "Udo"
          },
          {
            value: 6820,
            label: "Kolano"
          },
          {
            value: 29733,
            label: "Podudzie"
          },
          {
            value: 25667,
            label: "Staw Skokowy / Stopa"
          },
        ],
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      },
      {
        type: "checkboxGroup",
        name: "backPain",
        parentName: "ailments",
        title: "Wybierz, które z poniższych objawów występują w Twoim przypadku",
        answers: [
          {
            value: 1761,
            label: "Ból Pleców"
          },
          {
            value: 1787,
            label: "Ból Kręgosłupa"
          },
          {
            value: 4794,
            label: "Rwa Kulszowa"
          },
          {
            value: 30782,
            label: "Rwa Barkowa"
          },
          {
            value: 1874,
            label: "Ból Pleców W Okolicy Szyjnej"
          },
          {
            value: 1750,
            label: "Ból Pleców W Okolicy Piersiowej"
          },
          {
            value: 34573,
            label: "Promieniowanie Do Kończyny Górnej"
          },
          {
            value: 63958,
            label: "Promieniowanie Do Kończyny Dolnej"
          },
          {
            value: 26863,
            label: "Uczucie Mrowienia W Kończynach"
          },
          {
            value: 63959,
            label: "Dolegliwości Bólowe W Pozycji Siedzącej"
          },
          {
            value: 1761,
            label: "Ból Pleców W Okolicy Lędźwiowo Krzyżowej"
          },
          {
            value: 42210,
            label: "Problemy Z Podnoszeniem Ciężkich Przedmiotów"
          },
          {
            value: 63960,
            label: "Dolegliwości Bólowe W Pozycji Stojącej"
          },
        ],
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      },
      {
        type: "checkboxGroup",
        name: "eyesIssues",
        parentName: "ailments",
        title: "Wybierz, które z poniższych objawów występują w Twoim przypadku",
        answers: [
          {
            value: 13468,
            label: "Pieczenie I Zaczerwienienie Oczu"
          },
          {
            value: 13496,
            label: "Łzawienie"
          },
          {
            value: 19100,
            label: "Uczucie Piasku Pod Powiekami"
          },
          {
            value: 27824,
            label: "Ropa W Oczach"
          },
        ],
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      },
      {
        type: "checkboxGroup",
        name: "migraine",
        parentName: "ailments",
        title: "Wybierz, które z poniższych objawów występują w Twoim przypadku",
        answers: [
          {
            value: 1778,
            label: "Światłowstręt"
          },
          {
            value: 23168,
            label: "Rozkojarzenie"
          },
          {
            value: 35002,
            label: "Migrena (Rozpoznana W Przeszłości)"
          },
        ],
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      },
      {
        type: "checkboxGroup",
        name: "stressReaction",
        parentName: "ailments",
        title: "Wybierz, które z poniższych objawów występują w Twoim przypadku",
        answers: [
          {
            value: 47579,
            label: "Problemy Rodzinne"
          },
          {
            value: 47580,
            label: "Stres W Związku Z Rozwodem"
          },
          {
            value: 47580,
            label: "Stres W Związku Z Problemami Rodzinnymi"
          },
          {
            value: 47580,
            label: "Stres W Związku Ze Śmiercią"
          },
          {
            value: 47580,
            label: "Stres Związany Z Pracą"
          },
          {
            value: 47580,
            label: "Stres Związany Z Utratą Pracy"
          },
        ],
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      },
      {
        type: "checkboxGroup",
        name: "menses",
        parentName: "ailments",
        title: "Wybierz, które z poniższych objawów występują w Twoim przypadku",
        answers: [
          {
            value: 52337,
            label: "Silne Bóle Miesiączkowe"
          },
          {
            value: 2008,
            label: "Obfite Krwawienie"
          },
          {
            value: 2336,
            label: "Rozdrażnienie"
          },
          {
            value: 1723,
            label: "Bolesny Początek Miesiączki"
          },
        ],
        correctAnswer: null,
        nested: false,
        nestedItems: null,
      },
    ]
  },
  {
    type: "textarea",
    name: "importantInformations",
    title: "Podaj istotne według Ciebie informacje na temat swojego stanu zdrowia, które powinien wiedzieć lekarz, a niewymienione w ankiecie:",
    answers: null,
    correctAnswer: null,
    nested: false,
    nestedItems: null
  },
]