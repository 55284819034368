import {gql} from "@apollo/client"

import {Gender} from "../../../../store/healthPlanner/healthPlanner.types"

import {Disease} from "./getUserDiseasesFilteredByIds"

export const GET_USER_DISEASES_PROBABILITY = gql`
  query UserDiseasesProbability($diseaseIds: [uuid!]!) {
    userDiseasesProbability: users_diseases_probability(where: {disease_id: {_nin: $diseaseIds}}) {
      probability
      disease {
        name
        imageUrl: image_url
        onlyForGender: only_for_gender
        id
      }
    }
  }
`

export type GetUserDiseasesProbabilityResponse = {
  userDiseasesProbability: {
    probability: number; //from 0 to 1
    disease: Disease;
    onlyForGender: Gender;
  }[]
}

export type GetUserDiseasesProbabilityVariables = {
  diseaseIds: string[]
}
