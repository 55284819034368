import * as Sentry from "@sentry/react"
import { SagaIterator } from "redux-saga"
import { call, select, takeEvery } from "redux-saga/effects"

import { selectUserId } from "../session/session.selectors"
import { SessionState } from "../session/session.types"
import { ErrorTypes } from "./error.types"

function* bubbleErrors(error: any) {
  if (process.env.NODE_ENV === "development") {
    yield call(console.error, error)
  }
  const id: SessionState["id"] = yield select(selectUserId)

  yield call(Sentry.setUser, {id})
  yield call(Sentry.captureException, error)
}

export default function* (): SagaIterator {
  yield takeEvery(ErrorTypes.GENERAL, bubbleErrors)
}
