import { boxShadows } from "../../../../../../../themes/shadows"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const MINIFY_IMAGE_HEIGHT = 180

export const usePatientChatMessageStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageItemBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(5),
      },
    },
    cloudMessageWrapper: {
      flex: "1",
      background: theme.palette.background.paper,
      height: "100%",
      padding: theme.spacing(3, 3.5),
      borderRadius: theme.shape.borderRadius,
      boxShadow: boxShadows.shadow1,
      wordBreak: "break-word",
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(4, 5),
        width: "unset",
      },
    },
    cloudPatientMessageWrapper: {
      background: theme.palette.info.lighter,
    },
    rightAlignBoxMessage: {
      justifyContent: "flex-end",
      marginLeft: "auto",
      textAlign: "right",
    },
    messageTime: {
      color: theme.palette.grey["200"],
    },
  })
)
