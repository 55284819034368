import { createStyles, makeStyles, Theme } from "@material-ui/core"

export const useMessengerChatControllerStyles = makeStyles((theme: Theme) => createStyles({
  threadsListWrapper: {
    maxHeight: "200px",
    overflow: "scroll",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      order: 1,
      maxHeight: "500px",
    }
  },
  chatMessagesWrapper: {
    maxHeight: "300px",
    overflow: "scroll",
    [theme.breakpoints.up("md")]: {
      maxHeight: "500px"
    }
  },
}))