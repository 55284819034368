import {boxShadows} from "../../themes/shadows"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const CHAT_ITEM_MAX_WIDTH = "60%"

export const useConsultationChatStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(8),
      },
    },
    chat: {
      fontSize: theme.spacing(2),
      lineHeight: `${theme.spacing(3)}px`,
    },
    doctorIsWritingWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: theme.spacing(6),
    },
    doctorIsWritingBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: theme.palette.background.paper,
      padding: theme.spacing(2, 4),
      borderRadius: "50px",
      boxShadow: boxShadows.shadow1,
    },
    doctorIsWritingText: {
      marginRight: theme.spacing(2),
    },
    consultationReason: {
      fontWeight: 700,
      margin: theme.spacing(2, 0, 0, 1),
    },
    checkbox: {
      "& svg": {
        fill: theme.palette.common.white,
      },
      "&.Mui-checked": {
        "& svg": {
          fill: theme.palette.primary.main,
          "& path[stroke-width]": {
            stroke: theme.palette.primary.main,
          },
          "& path[stroke-linecap]": {
            stroke: theme.palette.common.white,
          }
        },
      },
    },
    documentationInfoWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(3),
    },
    documentationInfo: {
      flex: "1",
      alignSelf: "flex-end",
      background: theme.palette.background.paper,
      height: "100%",
      padding: theme.spacing(3, 3.5),
      borderRadius: theme.shape.borderRadius,
      boxShadow: boxShadows.shadow1,
      wordBreak: "break-word",
      width: "100%",
      maxWidth: "100%",
      margin: theme.spacing(0, 2),
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(4, 5),
        maxWidth: "70%",
        margin: 0
      },  
    },
    documentationPopup: {
      maxWidth: "80%",
      padding: 0,
      "& .MuiDialogContent-root": {
        padding: 0
      },
      "& .MuiTable-root": {
        [theme.breakpoints.down("md")]: {
          minWidth: "100%",
          "& .download": {
            marginLeft: 0,
          }
        },
      },
    },
  })
)
