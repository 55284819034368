import React, { FC } from "react"
import { Box } from "@material-ui/core"

import DoctorImageSrc from "../../../../../assets/images/doctor-thumbs-up.png"
import ImageByIntegrationType from "../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { useDoctorImageStyles } from "./DoctorImage.styles"

const DoctorImage: FC = () => {
  const classes = useDoctorImageStyles()

  return (
    <Box className={classes.imageWrapper}>
      <ImageByIntegrationType
        imageSrc={DoctorImageSrc}
        alt="DoctorImageSrc"
        className={classes.image}
        imagePath={"doctor-thumbs-up.png"}
      />
    </Box>
  )
}

export default DoctorImage