import React, {FC} from "react"
import {generatePath} from "react-router-dom"

import {ExaminationType} from "../../../../../api/queries/getUserActionById"
import ActionButtons from "../../actionButtons/ActionButtons.component"
import PreventionActionWrapper from "../../preventionActionWrapper/PreventionActionWrapper.component"
import {useHandleRedirect} from "../../PreventionActionCard.utils"
import {RoutePath} from "../../../../../../../routes/Routes.types"

type DefaultMeasurementLogProps = {
  title: string;
  description: string;
  measurementType: ExaminationType;
}

const DefaultMeasurementLog: FC<DefaultMeasurementLogProps> = ({
  title,
  description,
  measurementType
}) => {
  const handleRedirect = useHandleRedirect(
    generatePath(RoutePath.PREVENTION_DIARY, { name: measurementType })
  )

  return (
    <PreventionActionWrapper 
      title={title}
      description={description}
    >
      <ActionButtons
        onClick={handleRedirect}
      />
    </PreventionActionWrapper>
  )
}

export default DefaultMeasurementLog