import i18next from "i18next"
import * as yup from "yup"

import {UserPhoneNumberFormType} from "./UserDetailsSection.types"

export const userPhoneNumberValidationSchema = (): yup.SchemaOf<UserPhoneNumberFormType> => yup.object().shape({
  phoneNumber: yup.string()
    .defined()
    .test("phone number error",
      i18next.t("errors:phoneNumberError"),
      number => !number || /^\d{9}$/.test(number)
    )
})
