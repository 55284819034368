import React, {Dispatch, FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import AppDialog from "../../../../components/common/appDialog/AppDialog.component"

interface AwardReceivingErrorProps {
  showAwardReceivingErrorDialog: boolean;
  setShowAwardReceivingErrorDialog: Dispatch<boolean>;
}

const AwardReceivingError: FC<AwardReceivingErrorProps> = ({showAwardReceivingErrorDialog, setShowAwardReceivingErrorDialog}) => {
  const {t} = useTranslation()

  return (
    <AppDialog
      title=""
      open={showAwardReceivingErrorDialog}
      onClose={() => setShowAwardReceivingErrorDialog(false)}
      fullScreen={false}
    >
      <Box
        my={3}
      >
        <Typography
          variant="h5"
          component="span"
          color="error"
        >
          {t("errors:defaultError")}
        </Typography>
      </Box>
    </AppDialog>
  )
}

export default AwardReceivingError