import {useEffect, useState} from "react"
import {useQuery} from "@apollo/client"
import {TabsTypeMap} from "@material-ui/core/Tabs/Tabs"

import {useAppSelector} from "../../../../hooks/storeHooks"
import {
  GET_DISEASES_BY_DISEASES_IDS,
  GetDiseasesByDiseasesIdsResponse,
  GetDiseasesByDiseasesIdsVariables,
} from "../../api/queries/getDiseasesByDiseasesIds"
import {
  GET_USER_DISEASES_FILTERED_BY_IDS,
  GetUserDiseasesFilteredByIdsResponse,
} from "../../api/queries/getUserDiseasesFilteredByIds"
import {
  GET_USER_DISEASES_PROBABILITY,
  GetUserDiseasesProbabilityResponse,
  GetUserDiseasesProbabilityVariables
} from "../../api/queries/getUserDiseasesProbability"
import {
  useGetUserQuestionnaireValues
} from "../../api/queries/getUserQuestionniareValues/getUserQuestionnaireValues.hook"
import {DiseaseProbability} from "./PreventionDiseases.types"

export const usePreventionDiseasesManager = () => {
  const gender = useAppSelector(state => state.healthPlanner.gender)
  const {
    data: userDiseasesResponse,
    loading: isUserDiseasesLoading,
    called: isUserDiseasesCalled,
    error: userDiseasesError,
    refetch: userDiseasesRefetch
  } = useQuery<
  GetUserDiseasesFilteredByIdsResponse
  >
  (
    GET_USER_DISEASES_FILTERED_BY_IDS,
    {
      context: { clientName: "HealthPlanner" },
    }
  )
  const userDiseases = userDiseasesResponse?.usersDiseases
    .map(userDisease => userDisease.disease)
    .filter(userDisease =>
      gender === null
        ? true
        : userDisease?.onlyForGender === null || userDisease?.onlyForGender === gender
    ) ?? []

  const {
    data: userDiseasesProbabilityResponse,
    loading: isUserDiseasesProbabilityLoading,
    called: isUserDiseasesProbabilityCalled,
    error: userDiseasesProbabilityError,
    refetch: userDiseasesProbabilityRefetch
  } = useQuery<GetUserDiseasesProbabilityResponse, GetUserDiseasesProbabilityVariables>(
    GET_USER_DISEASES_PROBABILITY,
    {
      context: { clientName: "HealthPlanner" },
      variables: {
        diseaseIds: userDiseases.map(userDisease => userDisease.id)
      },
      skip: isUserDiseasesCalled && isUserDiseasesLoading,
    }
  )

  const userDiseasesProbability = userDiseasesProbabilityResponse?.userDiseasesProbability
    .reduce<DiseaseProbability[]>(
    (currentDiseases, disease) => {
      const isDiseaseAlreadyAdded = currentDiseases.find(diseaseItem => diseaseItem.id === disease.disease.id)

      if (!isDiseaseAlreadyAdded) {
        currentDiseases.push({
          ...disease.disease,
          probability: disease.probability
        })
      }

      return currentDiseases
    },
    []
  ) ?? []

  const {
    data: diseasesResponse,
    loading: isDiseasesLoading,
    error: diseasesError,
    refetch: diseasesRefetch
  } = useQuery<
  GetDiseasesByDiseasesIdsResponse,
  GetDiseasesByDiseasesIdsVariables
  >(
    GET_DISEASES_BY_DISEASES_IDS,
    {
      variables: {
        diseasesIds: [
          ...userDiseasesProbability.map(userDiseaseProbability => userDiseaseProbability.id),
          ...userDiseases.map(userDisease => userDisease.id)
        ]
      },
      skip: isUserDiseasesProbabilityCalled && isUserDiseasesProbabilityLoading,
      context: { clientName: "HealthPlanner" },
    }
  )
  const diseases = diseasesResponse?.diseases
    .filter(disease =>
      gender === null
        ? true
        : disease?.onlyForGender === null || disease?.onlyForGender === gender
    ) ?? []
  const { refetch, loading: isUserQuestionnaireValuesLoading } = useGetUserQuestionnaireValues()

  useEffect(() => {
    diseasesRefetch()
    userDiseasesRefetch()
    userDiseasesProbabilityRefetch()
    refetch()
  }, [])

  return {
    isLoading: isDiseasesLoading || isUserQuestionnaireValuesLoading,
    isErrorOccurred: userDiseasesProbabilityError && userDiseasesError && diseasesError,
    userDiseasesProbability,
    userDiseases,
    diseases,
    userDiseasesToAdd: diseases.concat(userDiseasesProbability)
  }
}

export const useTabs = () => {
  const [tabValue, setTabValue] = useState("1")

  const handleChange: TabsTypeMap["props"]["onChange"] = (event, value) => {
    setTabValue(value)
  }

  return {
    tabValue,
    handleChange,
  }
}
