import React, {FC} from "react"
import {useWatch} from "react-hook-form"
import {Grid, IconButton, Typography} from "@material-ui/core"

import {ReactComponent as TrashIcon} from "../../../../assets/svg/trash.svg"
import ImageByIntegrationType from "../../../imageByIntegrationType/ImageByIntegrationType"
import HiddenInput from "../../../commonFormItems/hiddenInput/HiddenInput.component"
import ItemCounter from "./itemCounter/ItemCounter.component"
import {DozOrder} from "../../../../store/doz/doz.types"
import {DozItem} from "../../../prescription/Prescription.types"
import {useOrderMedicinesItemStyles} from "./OrderMedicinesItem.styles"

import {MINIMAL_ITEM_COUNT} from "./itemCounter/ItemCounter.config"

interface OrderMedicinesItemProps {
  ean: number;
  index: number;
  onRemove: VoidFunction;
  dozMedicinesMap: Map<number, DozItem>;
}

const OrderMedicinesItem: FC<OrderMedicinesItemProps> = ({
  index,
  onRemove,
  ean,
  dozMedicinesMap
}) => {
  const dozItem = dozMedicinesMap.get(ean)
  const orderItems = useWatch<DozOrder, "requested_item_list">({
    name: "requested_item_list"
  })
  const isDeleteButtonDisabled = orderItems.length === 1
  const classes = useOrderMedicinesItemStyles({ isDeleteButtonDisabled})

  return (
    <Grid
      className={classes.root}
      container
    >
      <Grid
        item
        xs={4}
        className={classes.nameWrapper}
      >
        <Typography variant="subtitle1">
          {dozItem?.name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        className={classes.counterWrapper}
      >
        <HiddenInput name={`requested_item_list.${index}.ean`}/>
        <HiddenInput name={`requested_item_list.${index}.refund_type`}/>
        <HiddenInput name={`requested_item_list.${index}.quantity_description`}/>
        <ItemCounter
          name={`requested_item_list.${index}.quantity`}
          maxCount={dozItem?.quantity ?? MINIMAL_ITEM_COUNT}
        />
      </Grid>
      <Grid
        item
        xs={4}
        className={classes.priceWrapper}
      >
        <Typography
          className={classes.price}
          variant="subtitle1"
        >
          {dozItem?.price} zł
        </Typography>
        <IconButton
          className={classes.deleteButton}
          onClick={onRemove}
          disabled={isDeleteButtonDisabled}
          size="small"
        >
          <ImageByIntegrationType
            imageSrc={<TrashIcon/>}
            imagePath={"trash.svg"}
            returnSvg={true}
          />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default OrderMedicinesItem
