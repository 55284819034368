import React, { FC } from "react"

import AwardsList from "../../components/awardsList/AwardsList.component"
import PageWrapper from "../../components/pageWrapper/PageWrapper.component"

const AwardsPage: FC = () => {
  return (
    <PageWrapper>
      <AwardsList />
    </PageWrapper>
  )
}

export default AwardsPage
