import React, {FC, useEffect, useState} from "react"
import { useSelector } from "react-redux"
import {CancelTokenSource} from "axios"

import api, {ApiInstance} from "../../api/api"
import useMakeQuery from "../../api/graphql/hooks/useMakeQuery"
import {getConsultationConfig} from "../../api/routes"
import { analytics, LogEventType } from "../../services/analytics"
import {selectToken} from "../../store/session/session.selectors"
import { selectHasUserCompletePersonalData } from "../../store/user/user.selectors"
import { selectLoading } from "../../store/user/user.selectors"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import PageSection from "../../components/common/pageSection/PageSection.component"
import {ConsultationModel} from "../../components/consultation/Consultation.types"
import FillUserDataInformationPanel from "../../components/informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import MedicalSurvey from "../../components/medicalSurvey/MedicalSurvey.component"
import {surveyType} from "../../components/medicalSurvey/MedicalSurvey.types" // medicinesFromCategoryType
import {useMedicalSurvey} from "../../components/medicalSurvey/MedicalSurvey.utils"

const CUSTOM_CATEGORY_ID = 1

const MedicalSurveyPage: FC = () => {
  const {
    getMedicinesItemsFromCategory
  } = useMakeQuery()
  const medicineCustomEan = getMedicinesItemsFromCategory(CUSTOM_CATEGORY_ID)
  const hasUserCompletePersonalData = useSelector(selectHasUserCompletePersonalData)
  const userDataLoading = useSelector(selectLoading)
  const tokenData = useSelector(selectToken)
  const [consultationData, setConsultationData] = useState<ConsultationModel>()
  const consultationMedicines = consultationData?.medicines?.[0]
  const [medicalSurveyType, setMedicalSurveyType] = useState<string>("")
  const [loading, setLoading] = useState(true)

  const {
    consultationId,
    parseAndGetSearchParameters
  } = useMedicalSurvey()

  const getConsultationData = async (cancelToken?: CancelTokenSource["token"]) => {
    try {
      const {data} = await api.request<ConsultationModel>({
        ...getConsultationConfig(consultationId),
        cancelToken,
      })

      setConsultationData(data)
    } catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
    }
    setLoading(false)
  }
  
  useEffect(() => {
    const { detailedSource, utmSource } = parseAndGetSearchParameters()
    analytics.sendEventWithDefaultParams(LogEventType.PRESCRIPTION_SURVEY_PAGE_LOADED, { detailedSource, utmSource })
  }, [])

  useEffect(() => {
    if (tokenData && !consultationData) {
      getConsultationData()
    }
  }, [consultationId, tokenData, consultationData])

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()

    if (medicineCustomEan?.medicine?.length) {
      // setMedicalSurveyType(medicineCustomEan?.medicine?.filter((item: medicinesFromCategoryType) => item.ean === consultationMedicines).length
      //   ? surveyType.CUSTOM
      //   : surveyType.BASIC) // temporary unused

      setMedicalSurveyType(surveyType.BASIC)
    }

    return () => {
      requestSource.cancel("Request interrupted by page change")
    }
  }, [medicineCustomEan])

  if (userDataLoading || loading || !consultationData || !consultationMedicines) {
    return <LoaderBox />
  }

  return (
    <PageSection>
      {hasUserCompletePersonalData
        ? <MedicalSurvey 
          consultationData={consultationData} 
          medicalSurveyType={medicalSurveyType}
          consultationMedicines={consultationMedicines}
        />
        : <FillUserDataInformationPanel />
      }
    </PageSection>
  )
}

export default MedicalSurveyPage
