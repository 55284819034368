import React, {FC} from "react"

import LoaderBox from "../../../../components/common/loaderBox/LoaderBox.component"
import PageWrapper from "../../components/pageWrapper/PageWrapper.component"
import DiseaseDetails from "./diseaseDetails/DiseaseDetails.component"
import {usePreventionDiseaseDetails} from "./PreventionDiseaseDetails.utils"
import {RoutePath} from "../../../../routes/Routes.types"

const PreventionDiseaseDetails: FC = () => {
  const { disease, userActions, isUserDisease, probability, isLoading } = usePreventionDiseaseDetails()

  if (isLoading || !disease || !userActions) {
    return (
      <LoaderBox
        boxType="appDialog"
      />
    )
  }

  return (
    <PageWrapper
      navigateBackTo={RoutePath.PREVENTION_DISEASES}
      title={disease.name}
    >
      <DiseaseDetails
        name={disease.name}
        description={disease.description}
        imageUrl={disease.imageUrl}
        questionnaireId={disease.questionnaireId ?? undefined}
        userActions={userActions}
        probability={probability}
        isUserDisease={isUserDisease}
      />
    </PageWrapper>
  )
}

export default PreventionDiseaseDetails
