import React, { FC, useState } from "react"
import {Box, IconButton, useTheme} from "@material-ui/core"
import {Publisher} from "@opentok/client"
import clsx from "clsx"
import { ChatCircleText, CornersOut, GearSix, IconProps,Microphone, MicrophoneSlash, VideoCamera, VideoCameraSlash } from "phosphor-react"
import {Channel} from "pusher-js"

import useConsultationLang from "../../../../hooks/useConsultationLang"
import IconByIntegrationType from "../../../iconByIntegrationType/IconByIntegrationType"
import FinishVideoModal from "../closeVideoModal/FinishVideoModal.component"
import VideoSettingsModal from "../videoSettingsModal/VideoSettingsModal.component"
import {ChatTriggerEventType} from "../../ConsultationChat.types"
import {useVideoControlButtonsStyles} from "./VideoControlButtons.styles"

interface VideoControlButtonsProps {
  publisher: Publisher;
  finishVideoChat(): void;
  pusherChannel: Channel;
  consultationId: string;
  toggleFullScreenVideo(): void;
  isVideoFullScreenOpen: boolean;
}

const VideoControlButtons: FC<VideoControlButtonsProps> = (
  {
    publisher,
    pusherChannel,
    finishVideoChat,
    consultationId,
    toggleFullScreenVideo,
    isVideoFullScreenOpen,
  }
) => {
  const consultationLang = useConsultationLang()
  const classes = useVideoControlButtonsStyles()
  const theme = useTheme()

  const [finishVideoModalOpen, setFinishVideoModalOpen] = useState<boolean>(false)
  const [videoSettingsModalOpen, setVideoSettingsModalOpen] = useState<boolean>(false)

  const [isMicrophoneOn, setIsMicrophoneOn] = useState<boolean>(true)
  const [isCameraOn, setIsCameraOn] = useState<boolean>(true)

  const iconProps: IconProps = {
    weight: "fill",
    color: theme.palette.common.white,
    size: 22,
  }

  const toggleCamera = () => {
    publisher.publishVideo(!isCameraOn)
    setIsCameraOn(val => !val)
  }

  const toggleMicrophone = () => {
    publisher.publishAudio(!isMicrophoneOn)
    setIsMicrophoneOn(val => !val)
  }

  const handleFinishVideoChat = () => {
    finishVideoChat()
    pusherChannel.trigger(ChatTriggerEventType.CLOSE_VIDEO_CHAT, {
      consultationId
    })
  }

  return (
    <>
      <Box
        className={clsx(
          classes.buttonsWrapper,
          !isVideoFullScreenOpen && classes.mitigatedViewButtonsWrapper
        )}
      >
        { isVideoFullScreenOpen &&
          <>
            <IconButton
              className={classes.button}
              title={consultationLang.getLabel("videoChatConsultation:turnFullScreenOff")}
              onClick={toggleFullScreenVideo}
            >
              <ChatCircleText {...iconProps}/>
            </IconButton>

            <IconButton
              className={clsx(classes.button, !isMicrophoneOn && classes.offDeviceButton)}
              title={consultationLang.getLabel(`videoChatConsultation:${isMicrophoneOn? "turnMicrophoneOff": "turnMicrophoneOn"}`)}
              onClick={toggleMicrophone}
            >
              { isMicrophoneOn
                ? <Microphone {...iconProps}/>
                : <MicrophoneSlash {...iconProps}/>
              }
            </IconButton>
          </>
        }

        <IconButton
          className={clsx(
            classes.button,
            classes.closeButton,
            !isVideoFullScreenOpen && classes.mitigatedViewButton,
          )}
          title={consultationLang.getLabel("videoChatConsultation:endVideoButton")}
          onClick={() => setFinishVideoModalOpen(true)}
        >
          <IconByIntegrationType
            iconName={"icon-x"}
            className={clsx(
              classes.buttonIcon,
              isVideoFullScreenOpen && classes.closeButtonIcon,
            )}
          />
        </IconButton>

        { isVideoFullScreenOpen
          ? (
            <>
              <IconButton
                className={clsx(classes.button, !isCameraOn && classes.offDeviceButton)}
                title={consultationLang.getLabel(`videoChatConsultation:${isCameraOn ? "turnCameraOff" : "turnCameraOn"}`)}
                onClick={toggleCamera}
              >
                { isCameraOn
                  ? <VideoCamera {...iconProps}/>
                  : <VideoCameraSlash {...iconProps}/>
                }
              </IconButton>

              <IconButton
                className={classes.button}
                title={consultationLang.getLabel("videoChatConsultation:settings")}
                onClick={() => setVideoSettingsModalOpen(true)}
              >
                <GearSix {...iconProps}/>
              </IconButton>
            </>
          )
          : ( // for not isVideoFullScreenOpen
            <IconButton
              className={clsx(classes.button, classes.mitigatedViewButton)}
              title={consultationLang.getLabel("videoChatConsultation:turnFullScreenOn")}
              onClick={toggleFullScreenVideo}
            >
              <CornersOut {...iconProps} size={20}/>
            </IconButton>
          )
        }
      </Box>

      <FinishVideoModal
        open={finishVideoModalOpen}
        setClose={() => {setFinishVideoModalOpen(false)}}
        finishVideoChat={handleFinishVideoChat}
      />

      { publisher && (
        <VideoSettingsModal
          open={videoSettingsModalOpen}
          onClose={() => {setVideoSettingsModalOpen(false)}}
          publisher={publisher}
        />
      )}
    </>
  )
}

export default VideoControlButtons
