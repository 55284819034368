import React, {FC} from "react"
import {Box, BoxProps} from "@material-ui/core"

import {useAppBoxStyle} from "./AppBox.styles"

interface AppBoxProps extends BoxProps {}

const AppBox: FC<AppBoxProps> = ({children, ...props}) => {
  const classes = useAppBoxStyle()

  return (
    <Box
      component="section"
      className={classes.root}
      {...props}
    >
      { children }
    </Box>
  )
}

export default AppBox
