import React, {FC, useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks"
import { analytics, LogEventType } from "../../services/analytics"
import {selectExamsWithResultsItems, selectExamsWithResultsLoading} from "../../store/exams/exams.selectors"
import {getExamsWithResults} from "../../store/exams/exams.slice"
import {
  selectPatientDocumentationItems,
  selectPatientDocumentationLoading,
} from "../../store/patientDocumentation/patientDocumentation.selectors"
import { getPatientDocumentation } from "../../store/patientDocumentation/patientDocumentation.slice"
import DocumentationLoader from "../../components/common/lottieAnimations/animations/DocumentationLoader.component"
import PageSection from "../../components/common/pageSection/PageSection.component"
import AddFileModal from "../../components/documentation/AddFileModal.component"
import DocumentationPanelBox from "../../components/documentation/documentationPanelBox/DocumentationPanelBox.component"
import IconByIntegrationType from "../../components/iconByIntegrationType/IconByIntegrationType"

type DocumentationPageProps = {
  displayInModal?: boolean;
}

const DocumentationPage: FC<DocumentationPageProps> = ({displayInModal = false}) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  const medicalDocumentsLoading = useAppSelector(selectPatientDocumentationLoading)
  const medicalDocuments = useAppSelector(selectPatientDocumentationItems)

  const examResultsLoading = useAppSelector(selectExamsWithResultsLoading)
  const examResults = useAppSelector(selectExamsWithResultsItems)
  const dispatch = useAppDispatch()

  const [addFileModalOpen, setAddFileModalOpen] = useState(false)
  const [fileSaving, setFileSaving] = useState(false)

  useEffect(() => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_DOCUMENTATION_PAGE_LOAD)
    dispatch(getPatientDocumentation())
    dispatch(getExamsWithResults())
  }, [])

  const handleOpenAddFileModal = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_DOCUMENTATION_ADD_FILE_BUTTON_CLICK)
    setAddFileModalOpen(true)
  }

  const titleNode = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
    >
      <Typography
        variant={isMdUp ? "h2" : "h4"}
        component="h2"
      >
        { t("documentation") }
      </Typography>
      <Button
        variant="contained"
        color="primary"
        disabled={medicalDocumentsLoading || examResultsLoading || fileSaving}
        onClick={handleOpenAddFileModal}
      >
        <IconByIntegrationType {...{ component: "span", variant: "h4" }}
          iconName={"icon-plus"}
          returnTypography={true}
        />
        <Typography>{ t("documents:addFile") }</Typography>
      </Button>
    </Box>
  )

  return (medicalDocumentsLoading || examResultsLoading) && (!medicalDocuments || !examResults)
    ? (
      <Box className="page-loader-box">
        <DocumentationLoader/>
      </Box>
    )
    : (
      <PageSection titleNode={displayInModal ? null : titleNode}>
        { medicalDocuments &&
          <DocumentationPanelBox
            documents={medicalDocuments}
            examsResultsItems={examResults}
            loading={medicalDocumentsLoading || examResultsLoading}
            hideTabs={displayInModal}
          />
        }

        <AddFileModal
          open={addFileModalOpen}
          onClose={() => {setAddFileModalOpen(false)}}
          saving={fileSaving}
          setSaving={setFileSaving}
          onSuccess={() => {dispatch(getPatientDocumentation())}}
        />
      </PageSection>
    )
}

export default DocumentationPage
