import {AxiosResponse} from "axios"

import {UserAddressModel,UserModel} from "../../components/user/User.types"

export const USER_STORAGE_KEY = "user"
export const USER_ADDRESS_COORDS_KEY = "userAddressCoords"

export type UserDataState = {
  userId: null|string;
  loading: boolean;
  userData: null|UserDataType;
  error: null|Pick<AxiosResponse, "data" | "status">; //TODO: add response data type
  userAddressCoords: null|google.maps.LatLngLiteral;
  userAddressCoordsLoading: boolean;
  userAddressCoordsError: null|google.maps.GeocoderStatus;
  userAddressEditModalOpen: boolean;
  userRegulationsModalOpen: boolean;
  userForcePasswordChangeModalOpen: boolean;
  randomSessionId: string|null;
  isActiveDeclaration: boolean;
}

export type UserDataType = UserModel & {
  gender: Gender; // 0 - FEMALE, 1 - MALE
  pesel: string | null;
  birth_date: string; // "2020-06-18"
  force_change_password: boolean;
  nationality: string; //"PL"
  phone_national_number: string; //"781147390"
  phone_country: string; //"PL"
  phone_country_code: string; //"48"
  terms_accepted: boolean;
  medical_document_agree: boolean;
  mailing_agree: boolean;
  phone_agree: boolean;
  passport_number: string | null;
  passport_country: string; //"PL"
  parent_first_name: string|null;
  parent_last_name: string | null;
  parent_address: UserAddressModel | null;
  parent_pesel: string|null,
  default_timezone: string; //"Europe\/Warsaw"
  identity_number: string | null;
  doctor_signature: string | null;
  weight: string; //"81
  height: string; //"175"
  pozInterested: boolean;
  doctor_penalty_notify:  boolean; //true,
  identity_document_type: null; //??
  identity_document_country_issuer: null; //??
  can_register_poz_declaration: boolean;
  has_poz_declaration: boolean;
  isPozDeclarationActive: boolean;
  is_active_poz_limited: boolean;
  photoIdentityCard: string;
  photoFace: string;
  diseases: DiseaseType[];
  hasCompletePersonalData: boolean,
  hasLastTermsAccepted: boolean,
  poz: UserPOZ;
  trusted_devices?: string; // assumption
  two_factor_auth?: string; //assumption
  user_parent: UserParentDataType | null;
  showPhoneNumber?: boolean;
  is_active_telemedi_go: boolean;
  is_active_medi_go: boolean;
  isEpuapReSignFlowRequired: boolean;
}

export interface UserParentAddressModel {
  id: string; // dda96f74-358b-47f8-913e-0fb1b6149de4
  street: string; // Testowa
  house_number: string // 69
  apartment_number: string; // 666
  postal_code: string; // 70-123
  city: string; // Szczecin
  country: string | null; // TODO: null - looks like bug, its always null
}

export type UserParentDataType = {
  id: string; // 0464f5eb-fa20-4437-a004-de8e4f6b4829
  first_name: string; // Matka
  last_name: string; // Polka
  nationality: string; // PL
  pesel: string | null; // 49102381525
  passport_country: string | null; // null - probably unused field (???)
  identity_number: string | null;
  birth_date: string; // 1949-10-23
  address: UserParentAddressModel;
  identity_document_type: UserDocumentType | null;
  identity_document_country_issuer: string | null;
  gender: Gender; // 0 - FEMALE, 1 - MALE
  default_timezone: string; //"Europe\/Warsaw"
}

type DiseaseType = {
  id: number; //8002
  name: string; //"R05 - Kaszel"
}

export enum Gender {
  "female",
  "male"
}

export interface UserPOZ {
  active_declaration_type: number; //2
  active_declaration_created_at: string; // "2023-05-09 10:38"
}

export enum UserDocumentType {
  IDENTITY_CARD_NUMBER = "1",
  DRIVERS_LICENSE_NUMBER = "2",
  SEAMANS_BOOK = "3",
  PASSPORT = "4",
  PESEL = "5",
  NO_DOCUMENT = "6",
  // ES Documents exceptions: id's have to be uniq - these documents are treated as IDENTITY_CARD_NUMBER
  DNI = "97",
  TIE = "98",
  NIE = "99",
  ES_IDENTITY_CARDS_TOGETHER = "100"
  // ES Documents exceptions
}

export type UserDocumentData = {
  label: string;
  value: string;
}

export enum MainCountriesTypes {
  PL = "PL",
  ES = "ES",
}

export enum UserSickLeaveEmploymentType {
  CIVILIAN_PERSON = 1,
  UNIFORMED_SERVICES = 2,
  FOREIGN_COMPANY = 3,
  STUDENT = 4
}
