import React, { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { generatePath, useHistory } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Box, Button, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import AddFriendsOnPhone from "../../../../assets/images/addFriendsOnPhone.png"
import LibraryIcon from "../../../../assets/svg/mentalHealth/libraryIcon.svg"
import QuestionnaireIcon from "../../../../assets/svg/mentalHealth/questionnaireIcon.svg"
import WebinarIcon from "../../../../assets/svg/mentalHealth/webinarIcon.svg"
import { GET_USER_ACTIONS, UserActionsResponseData } from "../../../healthPlanner/api/queries/getUserActionsQuery"
import ImageByIntegrationType from "../../../../components/imageByIntegrationType/ImageByIntegrationType"
import ResourceButton from "./ResourceButton/ResourceButton.component"
import { RoutePath } from "../../../../routes/Routes.types"
import { useResourcesTileStyles } from "./ResourcesTile.styles"

interface ResourcesTileProps {}

const ResourcesTile: FC<ResourcesTileProps> = () => {
  const { t, i18n } = useTranslation()
  const classes = useResourcesTileStyles()
  const theme = useTheme()
  const { push } = useHistory()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  const {
    data: { userActions } = {},
    error: userActionsError,
    loading: userActionsLoading,
    refetch
  } = useQuery<UserActionsResponseData>(GET_USER_ACTIONS, {
    context: {clientName: "HealthPlanner"},
  })

  useEffect(() => {
    refetch()
  }, [])

  const filteredUserAction = userActions?.find(
    userAction => userAction.action?.codeName?.includes("MH0")
  )

  const questionnairePath = filteredUserAction ? generatePath(RoutePath.PREVENTION_ACTION, {
    actionId: filteredUserAction.id
  }) : null
  
  return (
    <Box
      pt={3}
      pb={2}
      className={classes.boxCard}
    >
      <Typography
        variant={isMdUp ? "h3" : "h4"}
        component="h3"
        className={classes.title}
      >
        {t("mentalHealth:yourResourcesTitle")}
      </Typography>

      <Box className={classes.contentWrapper}>
        <Box className={classes.buttonsWrapper}>
          <ResourceButton
            title={t("mentalHealth:questionnaires")}
            imageUrl={QuestionnaireIcon}
            imagePath={"mentalHealth/questionnaireIcon.svg"}
            path={`/${i18n.language}${questionnairePath}`}
            disabled={!!userActionsError || userActionsLoading || !filteredUserAction}
          />
          <ResourceButton
            title={t("mentalHealth:yourLibrary")}
            imageUrl={LibraryIcon}
            imagePath={"mentalHealth/libraryIcon.svg"}
            path={`/${i18n.language}${RoutePath.MENTAL_HEALTH_LIBRARY}`}
          />
          <ResourceButton
            title={t("mentalHealth:webinars")}
            imageUrl={WebinarIcon}
            imagePath={"mentalHealth/webinarIcon.svg"}
            path={`/${i18n.language}${RoutePath.MENTAL_HEALTH_WEBINAR}`}
          />
        </Box>
        
        <Box className={classes.personalGrowthTile}>
          <Box className={classes.personalGrowthImageContainer}>
            <ImageByIntegrationType
              imageSrc={AddFriendsOnPhone}
              imagePath={"addFriendsOnPhone.png"}
              additionalStyles={{width: "80px", height: "auto"}}
            />
            <Typography
              variant={"subtitle2"}
              component="span"
            >
              {t("mentalHealth:personalGrowth")}
            </Typography>
          </Box>
          <Box className={classes.personalGrowthDescriptionContainer}>
            <Typography
              variant={"subtitle2"}
              component="span"
            >
              {t("mentalHealth:personalGrowthDescription")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => push(`/${i18n.language}${RoutePath.MENTAL_HEALTH_SELF_IMPROVEMENT}`)}
            >
              {t("mentalHealth:choose")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ResourcesTile
