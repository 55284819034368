import React, { FC, useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Typography } from "@material-ui/core"
import {Box} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { CancelTokenSource } from "axios"
import { useDebouncedCallback } from "use-debounce"

import api, { ApiInstance } from "../../../api/api"
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks"
import {
  selectCancelledConsultationsVisible,
  selectLatestConsultationsTotal,
} from "../../../store/consultations/consultations.selectors"
import {
  addLatestConsultations,
  setLatestConsultationsTotal,
} from "../../../store/consultations/consultations.slice"
import AppDialog from "../../common/appDialog/AppDialog.component"
import LatestConsultationDetails from "../latestConsultationDetails/LatestConsultationDetails.component"
import { redirectToError500Page } from "../../../utils/handleErrors"
import { getConsultations, LATEST_CONSULTATION_ITEMS_LIMIT } from "../Consultation.utils"
import {
  ConsultationListItemModel,
  ConsultationListParamsStatus,
  ConsultationStatusNumber,
  GetConsultationsListParams,
} from "../Consultation.types"
import { useLatestConsultationsListStyles } from "./LatestConsultationsList.styles"

interface LatestConsultationsListProps {
  items: ConsultationListItemModel[];
  lcpage: number | null;
}

const prescriptionInfoTotalItems = 4 // there is 5 elements, but the last one is temporary disabled - waiting for another text

const LatestConsultationsList: FC<LatestConsultationsListProps> = ({items, lcpage}) => {
  const {replace} = useHistory()
  const dispatch = useAppDispatch()
  const total = useAppSelector(selectLatestConsultationsTotal)
  const [page, setPage] = useState<number>(lcpage || 1)
  const [loading, setLoading] = useState<boolean>(false)
  const hasMore = total > items.length
  const cancelledVisible = useAppSelector(selectCancelledConsultationsVisible)
  const {i18n, t} = useTranslation()
  const [cancelToken, setCancelToken] = useState<CancelTokenSource["token"]>()
  const [showPerscriptionsPopup, setShowPrescriptionsPopup] = useState<boolean>(false)
  const classes = useLatestConsultationsListStyles()

  const getNextPage = async () => {
    setLoading(true)
    const nextPage = page + 1
    setPage(nextPage)
    replace({
      pathname: `/${i18n.language}`,
      search: `?lcpage=${nextPage}`,
    })
    try {
      const params: GetConsultationsListParams = {
        page: nextPage, //we get first 3 consultations in consultationsSaga, normally it would be `page: page`
        limit: LATEST_CONSULTATION_ITEMS_LIMIT,
        lang: i18n.language
      }

      if (cancelledVisible) {
        params.status = ConsultationListParamsStatus.ARCHIVED
      } else {
        params.statusNumber = [ConsultationStatusNumber.FINISHED]
      }

      const {data} = await getConsultations(params, cancelToken)
      dispatch(setLatestConsultationsTotal(data.total))
      dispatch(addLatestConsultations(data.items))
    } catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
      redirectToError500Page(e)
    }
    setLoading(false)
  }

  const observer = useRef<IntersectionObserver>()
  const debounceGetNextPage = useDebouncedCallback(async () => {
    await getNextPage()
  }, 1000)
  const lastConsultationItem = useCallback(
    (node: HTMLDivElement|null) => {
      if (loading || !node) {
        return
      }
      observer?.current?.disconnect()
      observer.current = new IntersectionObserver(async entries => {
        if (entries[0].isIntersecting && hasMore) {
          await debounceGetNextPage()
        }
      })
      if (node) {
        observer.current.observe(node)
      }
    },
    [loading, hasMore]
  )

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()
    setCancelToken(requestSource.token)
    return() => {
      setLoading(false)
      requestSource.cancel("Request interrupted by page change")
      setCancelToken(undefined)
    }
  }, [])

  const consultationBoxSkeletonBox = (
    <Skeleton variant="text"/>
  )

  const renderPrescriptionPopupContent = () => {
    const prescriptionInfoData = []
    let prescriptionInfoNumber

    for (prescriptionInfoNumber=1; prescriptionInfoNumber <= prescriptionInfoTotalItems; prescriptionInfoNumber++) {
      prescriptionInfoData.push(
        <Box
          key={prescriptionInfoNumber}
        >
          <Typography
            variant="h5"
            component="span"
          >
            { t(`prescription:popupInfoTitle${prescriptionInfoNumber}`) }
          </Typography>

          <Typography
            variant="body1"
            className={classes.perscriptionsPopupInfoDescription}
          >
            { t(`prescription:popupInfoDescription${prescriptionInfoNumber}`) }
          </Typography>
        </Box>
      )
    }

    return prescriptionInfoData
  }

  return (
    <>
      {items.map((consultation, index) => (
        <LatestConsultationDetails
          ref={items.length === index + 1 ? lastConsultationItem : undefined}
          key={consultation.id}
          index={index}
          consultation={consultation}
          setShowPrescriptionsPopup={setShowPrescriptionsPopup}
        />
      ))}

      {loading && (
        <Box mb={2}>
          { consultationBoxSkeletonBox }
          { consultationBoxSkeletonBox }
          { consultationBoxSkeletonBox }
        </Box>
      )}
      <AppDialog
        title=""
        open={showPerscriptionsPopup}
        onClose={() => setShowPrescriptionsPopup(false)}
      > 
        <Typography
          variant="h4"
          component="span"
        >
          { t("prescription:popupTitle") }
        </Typography>

        <Typography
          variant="body1"
          className={classes.perscriptionsPopupInfoTitleDescription}
        >
          { t("prescription:popupInfoTitleDescription") }
        </Typography>

        {
          renderPrescriptionPopupContent()
        }
      </AppDialog>
    </>
  )
}

export default LatestConsultationsList
