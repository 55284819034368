import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import {useHistory} from "react-router"
import {Box, Button, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import clsx from "clsx"

import VisitCancelled from "../../assets/images/visit-cancelled.png"
import ImageByIntegrationType from "../../components/imageByIntegrationType/ImageByIntegrationType"
import {useConsultationChangesStyles} from "./ConsultationChanges.styles"

const ConsultationCancelledPage: FC = () => {
  const classes = useConsultationChangesStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const {push} = useHistory()
  const {t, i18n} = useTranslation()

  return (
    <Box
      className={clsx(classes.pageSection, classes.pageSectionCol)}
      component="section"
      justifyContent="flex-start"
    >
      <Typography
        variant="h3"
      >
        {t("consultation:cancelSuccess")}
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
      >
        <ImageByIntegrationType
          imageSrc={VisitCancelled}
          alt="VisitCancelled"
          className={classes.cancelledImage}
          imagePath={"visit-cancelled.png"}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          variant="contained"
          color="primary"
          type="button"
          fullWidth={!isMdUp}
          onClick={() => push(`/${i18n.language}`)}
        >
          {t("consultation:consultationListBack")}
        </Button>
      </Box>
    </Box>
  )
}

export default ConsultationCancelledPage
