import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useConsultationRatingModalStyles = makeStyles((theme: Theme) => createStyles({
  popup: {
    width: "600px",
    maxWidth: "100%",
    padding: `${theme.spacing(2)}px !important`,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  submitButton: {
    alignSelf: "flex-end",
    marginBottom: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(3),
    whiteSpace: "break-spaces",
  },
  succesBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
  },
  closeButton: {
    alignSelf: "flex-end",
    marginBottom: theme.spacing(1),
  },
}))
