import { createStyles, makeStyles } from "@material-ui/core"

import { colors } from "../../../../../themes/colors"
import { Theme } from "@material-ui/core/styles"

export const useDiaryMeasurementTabsStyles = makeStyles((theme: Theme) => createStyles({
  tabsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "unset",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  tabs: {
    minHeight: "unset",
    marginBottom: theme.spacing(2)
  },
  indicator: {
    top: 0,
    left: 0,
    minWidth: 85,
    maxHeight: 25,
    height: "100%",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    background: "transparent",
  },
  tab: {
    minWidth: 85,
    minHeight: "unset",
    maxHeight: 25,
    background: colors.telemediGreen10,
    color: theme.palette.text.secondary,
    "&.Mui-selected": {
      color: theme.palette.primary.main
    }
  },
}))