export type SurveyFields = {
  nip: {
    [key:string]: {nipItem?: string|undefined;};
  }
  importantInformations: string;
  nipConfirmation: boolean;
  street: string;
  houseNumber: string;
  apartmentNumber: string;
  postalCode: string;
  city: string;
  country: string;
  street_otherAddress: string;
  houseNumber_otherAddress: string;
  apartmentNumber_otherAddress: string;
  postalCode_otherAddress: string;
  city_otherAddress: string;
  country_otherAddress: string;
  uniformedServiceType: string;
  companyName_foreignWorker: string;
  apartmentNumber_foreignWorker: string;
  nip_foreignWorker: string;
  street_foreignWorker: string;
  houseNumber_foreignWorker: string;
  postalCode_foreignWorker: string;
  city_foreignWorker: string;
  country_foreignWorker: string;

  pregnant: string;
  pregnancySickLeave: string;
  chronicDiseases: string;
  chronicDiseasesGroup: string[];
  allergy: string;
  allergyDetails: string;
  regularDrugs: string;
  regularDrugsDetails: string;
  duringSickLeave: string;
  ailments: string;
  ailmentsTime: string;
  coldOrPain: string[];
  stomachIssues: string[];
  bladderIssues: string[];
  injuries: string[];
  backPain: string[];
  eyesIssues: string[];
  migraine: string[];
  stressReaction: string[];
  menses: string[];
};

export enum SickLeaveSurveyStatus {
  START = 0,
  CONFIRM = 1,
  SENT = 2
}

export type Answers = {
  value: string|number
  label: string;
}[] | null;

export type PatientSymptompsArrayType = {
  name: string | undefined;
  id: string | number | undefined;
}
