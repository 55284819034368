import React, {FC} from "react"
import {Controller, useFormContext} from "react-hook-form"
import {FormControl, FormHelperText} from "@material-ui/core"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import {KeyboardTimePicker} from "@material-ui/pickers"

import {useControllerKeyboardTimePickerStyles} from "./ControllerKeyboardTimePicker.styles"

const ControllerKeyboardTimePicker: FC = () => {
  const form = useFormContext()
  const classes = useControllerKeyboardTimePickerStyles()

  return (
    <Controller
      name="time"
      control={form.control}
      render={
        ({
          field: {
            onChange,
            value
          },
          fieldState: {
            error
          }
        }) => (
          <FormControl>
            <KeyboardTimePicker
              className={error ? classes.errorInput : ""}
              value={value}
              onChange={onChange}
              ampm={false}
              invalidDateMessage=""
              keyboardIcon={<AccessTimeIcon/>}
              DialogProps={{
                className: classes.dialogRoot
              }}
            />
            {
              error && (
                <FormHelperText
                  className={classes.formHelperText}
                  error
                >
                  {error.message}
                </FormHelperText>
              )
            }
          </FormControl>
        )
      }
    />
  )  
}

export default ControllerKeyboardTimePicker
