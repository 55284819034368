import {alpha, makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

interface Props {
  isChecked: boolean;
}

export const useItemListRadioButton = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: (props: Props) => props.isChecked
      ? alpha(theme.palette.primary.main, 0.1)
      : "initial",
    border: (props: Props) => props.isChecked
      ? `2px solid ${alpha(theme.palette.primary.main, 0.2)}`
      : "2px solid transparent",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 1, 1.5),
    cursor: "pointer"
  },
  radioButton: {
    marginRight: theme.spacing(1),
    "& svg": {
      width: 24,
      height: 24,
    },
    "& circle": {
      stroke: (props: Props) => props.isChecked
        ? theme.palette.primary.main
        : theme.palette.text.primary,
      "&:last-child": {
        fill: (props: Props) => props.isChecked
          ? theme.palette.primary.main
          : theme.palette.common.white,
      }
    },
  },
  boldText: {
    fontWeight: 700,
  },
}))
