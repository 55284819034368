import {gql} from "@apollo/client"

import {ActionType} from "./getUserActionById"

export enum CodeName {
  PHYSICAL_ACTIVITY_MEASUREMENT_LOG = "CTA02",
  ACTIVATE_GOOGLE_FIT = "CTA47",
  CODE_LESS = "CTA"
}

export interface UserActionModel {
  id: string;
  completed_at: string;
  deadline: string;
  source: string;
  action: {
    type: ActionType;
    points: number;
    image_url: string;
    isVisibleOnB2bClinic: boolean;
    name: string;
    codeName: CodeName;
  }
}

export interface UserActionsResponseData {
  userActions: UserActionModel[]
}

export const GET_USER_ACTIONS = gql`
  query getUserActions {
    userActions: users_actions(limit: 100, order_by: {action: {priority: desc}}) {
      id
      completed_at
      deadline
      source
      action {
        type
        points
        image_url
        isVisibleOnB2bClinic: is_visible_on_b2b_clinic
        name
        codeName: code_name
      }
    }
  }
`
