import React, {FC} from "react"
import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import DoctorImageAvatar from "../../common/imageAvatar/doctorImageAvatar/DoctorImageAvatar.component"
import TextLabel from "../../common/textLabel/TextLabel.component"
import ConsultationTypeSelect from "../consultationChatTopBox/consultationTypeSelect/ConsultationTypeSelect.component"
import {DoctorDetailsType} from "../../doctor/Doctor.types"
import {useConsultationChatTopBoxStyles} from "../consultationChatTopBox/ConsultationChatTopBox.styles"

interface ConsultationDoctorDataSectionProps extends DoctorDetailsType {}

const ConsultationDoctorDataSection: FC<ConsultationDoctorDataSectionProps> = (
  {
    doctorImage, 
    doctorSpecialization, 
    doctorTitle, 
    doctorAvatarPseudorandomBoolean,
    consultationType,
    isStationaryVisit
  }
) => {
  const classes = useConsultationChatTopBoxStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Box className={classes.doctorDataWrapper}>
      <DoctorImageAvatar
        pseudorandomBoolean={doctorAvatarPseudorandomBoolean}
        className={classes.doctorImage}
        src={doctorImage.src || undefined}
        alt={doctorImage.alt || ""}
      />

      <Box>
        <Box mb={1}>
          <TextLabel
            text={doctorSpecialization.name}
            textOverflow
          />
        </Box>

        <Typography
          variant="h5"
          component="p"
          color={isMdUp ? "textPrimary" : "textSecondary"}
        >
          { doctorTitle }
        </Typography>
        <ConsultationTypeSelect 
          consultationType={consultationType}
          isStationaryVisit={isStationaryVisit}
        />
      </Box>
    </Box>
  )
}

export default ConsultationDoctorDataSection
