import React, {FC, useEffect} from "react"
import {useTranslation} from "react-i18next"
import {useParams} from "react-router"
import {Box, Button,LinearProgress, Paper, Typography} from "@material-ui/core"
import {push} from "connected-react-router"

import {useAppDispatch} from "../../../hooks/storeHooks"
import {analytics, LogEventType} from "../../../services/analytics"
import LoaderBox from "../../../components/common/loaderBox/LoaderBox.component"
import Question from "../../healthPlanner/pages/preventionQuestionnaire/genericStepForm/question/Question.component"
import {useGenericStepForm, useScrollHandler} from "./GenericStepForm.utils"
import {QuestionnaireTemplate} from "../../healthPlanner/pages/preventionQuestionnaire/genericStepForm/GenericStepForm.types"
import {useGenericStepFormStyles} from "../../healthPlanner/pages/preventionQuestionnaire/genericStepForm/GenericStepForm.styles"

type GenericStepFormProps = {
  questionnaireTemplate: QuestionnaireTemplate;
  isLoading: boolean;
}

const GenericStepForm: FC<GenericStepFormProps> = ({
  questionnaireTemplate,
  isLoading
}) => {
  const classes = useGenericStepFormStyles()
  const { containerRef, handleScrollToQuestion } = useScrollHandler()
  const {
    stepNumber,
    progress,
    completedQuestions,
    saveAnswer,
    goToPreviousQuestion,
    isCompleted,
    saving
  } = useGenericStepForm(questionnaireTemplate)
  const {t, i18n} = useTranslation()
  const { consultationId } = useParams<{ consultationId: string }>()
  const goToConsultationUrl = `/${i18n.language}/consultation/${consultationId}`
  const dispatch = useAppDispatch()

  const { questions, name } = questionnaireTemplate
  const currentQuestion = questions[stepNumber]

  useEffect(() => {
    analytics.sendEventWithDefaultParams(LogEventType.CONSULTATION_SURVEY_START)
  }, [])

  useEffect(() => {
    if (isCompleted) {
      analytics.sendEventWithDefaultParams(LogEventType.CONSULTATION_SURVEY_END)
    }
  }, [isCompleted])

  if (saving) {
    return (
      <Box data-testid="savingInProgress">
        <LoaderBox />
      </Box>
    )
  }

  if (isCompleted && !saving) {
    return (
      <Box
        data-testid="surveyCompleted"
        className={classes.container} 
        display="flex"
        flexDirection="column"
      >
        <Box
          justifyContent="center"
          display="flex"
        >
          <Typography
            variant="body1"
            className={classes.headerWrapper}
          >
            {t("consultation:surveyThankYouMessage")}
          </Typography>
        </Box>
        <Box
          justifyContent="center"
          display="flex"
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.title}
            onClick={() => dispatch(push(goToConsultationUrl))}
          >
            {t("payments:successPayment:goToConsultationPage")}
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Paper
      ref={containerRef}
      className={classes.container}
    >
      <Box className={classes.headerWrapper}>
        <Typography
          variant="h4"
          className={classes.title}
        >
          {name}
        </Typography>
        <LinearProgress
          className={classes.progress}
          variant="determinate"
          value={progress}
        />
      </Box>
      {
        currentQuestion && (
          <Question
            key={currentQuestion.id}
            data={currentQuestion}
            isLastQuestion={questions.length === stepNumber + 1}
            isFirstQuestion={stepNumber === 0}
            isLoading={isLoading}
            onScrollToQuestion={handleScrollToQuestion}
            onSaveAnswer={saveAnswer(stepNumber)}
            goToPreviousQuestion={goToPreviousQuestion}
            completedQuestion={completedQuestions[stepNumber]}
            sendAnalytics={true}
          />
        )
      }
    </Paper>
  )
}

export default GenericStepForm
