import React, {useEffect, useState} from "react"

import { useAppSelector } from "../../hooks/storeHooks"
import { selectClinicSettings } from "../../store/clinic/clinicSettings.selectors"
const useImageByIntegrationType = (imagePath: string,  fallback?: string) => {
  const [imageSrcState, setImageSrcState] = useState<string | null>(null)
  const {
    frontendSettings: { style },
    id
  } = useAppSelector(selectClinicSettings)
  const clinicId = id.replace("/api/clinics/", "")
  const dynamicImagesEnabled = style.dynamicStylesEnabled && style.dynamicImagesEnabled

  useEffect(() => {
    let isMounted = true
    isMounted && setImageSrcState(fallback ?? null)

    if ( dynamicImagesEnabled ) {
      const imageDirectory = imagePath.endsWith(".svg") ? "svg" : "images"
      const path = `${imageDirectory}/${clinicId}/${imagePath}`

      import(/* webpackMode: "eager" */ "../../assets/"+path)
        .then(dynamicImageModule => {
          if (!dynamicImageModule || Object.keys(dynamicImageModule).length < 1) {
            return
          }
          isMounted && setImageSrcState(dynamicImageModule.default || dynamicImageModule)
        }).catch(() => {
          //return console.error(error)
        })
    }

    return () => {
      isMounted = false
    }
  }, [dynamicImagesEnabled, clinicId, imagePath])

  return { imageSrcState }
}

export default useImageByIntegrationType