import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Grid, Typography, TypographyProps} from "@material-ui/core"

interface UserDataListItemProps {
  label: string,
  description?: string,
  descriptionProps?: Partial<TypographyProps>
}

const UserDataListItem: FC<UserDataListItemProps> = (
  {
    label,
    description,
    descriptionProps = {},
    children
  }
) => {
  const {t} = useTranslation()

  return (
    (
      <Grid
        container
        component="dl"
        alignItems="baseline"
        spacing={1}
      >
        <Grid
          item
          component="dt"
          xs={4}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            className="break-word"
          >
            { label }
          </Typography>
        </Grid>

        <Grid
          item
          component="dd"
          xs={8}
        >
          <Typography {...descriptionProps}> {/* noWrap ? */}
            { description || t("user:noData") }

            { children }
          </Typography>
        </Grid>
      </Grid>
    )
  )
}

export default UserDataListItem
