import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { useAppSelector } from "../../hooks/storeHooks"
import { selectApiError } from "../../store/apiError/apiError.selectors"
import { selectClinicSettings } from "../../store/clinic/clinicSettings.selectors"
import AppDialog from "../common/appDialog/AppDialog.component"

interface ApiErrorProps {}

const ApiErrorPopup: FC<ApiErrorProps> = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const errorResponse = useSelector(selectApiError)

  const {
    clinicAllianzSettings: { 
      ewkaVerificationEnabled,
      onyxVerificationEnabled
    }
  } = useAppSelector(selectClinicSettings)
    
  const isAllianz = ewkaVerificationEnabled || onyxVerificationEnabled

  useEffect(() => {
    if (isAllianz && errorResponse && errorResponse?.data?.error) {
      setIsOpen(true)
    }
  }, [isAllianz, errorResponse])
    
  if (!isAllianz || !errorResponse) {
    return null
  }

  return (
    <AppDialog
      open={isOpen}
      title={t("errors:errorOccurred")}
      disableBackdropClick={true}
      closeButton={false}
      onClose={() => setIsOpen(false)}
    >
      {errorResponse?.data?.error}
    </AppDialog>
  )
}

export default ApiErrorPopup
