import React, { FC } from "react"

import chatLoader from "../../../../assets/animations/chatLoader.json"
import LottieAnimation from "../LottieAnimation.component"

interface ChatLoaderProps {}

const ChatLoader: FC<ChatLoaderProps> = () => {
  return (
    <LottieAnimation
      animationData={chatLoader}
    />
  )
}

export default ChatLoader