import React, {FC, useState} from "react"
import {useTranslation} from "react-i18next"
import {Box, InputAdornment, useMediaQuery, useTheme} from "@material-ui/core"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import clsx from "clsx"
import dayjs from "dayjs"

import { ReactComponent as CalendarIcon } from "../../assets/svg/calendar.svg"
import ImageByIntegrationType from "../imageByIntegrationType/ImageByIntegrationType"
import DatePicker from "../commonFormItems/datePicker/DatePicker.component"
import {
  addDaysToCurrentDate,
  addDaysToSpecifiedDate,
  subtractDaysFromCurrentDate
} from "../../utils/dayJs/ManipulateDate"
import {useSickLeaveSectionStyles} from "./SickLeaveSurvey.styles"

interface SickLeaveDatesProps {
  startAt: string;
  endAt: string;
  handleChangeDates(start: string, end: string): void;
  disabled?: boolean;
}

const SickLeaveDates: FC<SickLeaveDatesProps> = ({
  startAt,
  endAt,
  handleChangeDates,
  disabled
}) => {
  const {t} = useTranslation()
  const dateFormat = "YYYY-MM-DD"
  const [newStartAt, setNewStartAt] = useState<string>(dayjs(startAt).format(dateFormat))
  const [newEndAt, setNewEndAt] = useState<string>(dayjs(endAt).format(dateFormat))
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const classes = useSickLeaveSectionStyles()
  const calendarIconBox = (
    <InputAdornment position="end">
      <ImageByIntegrationType
        imageSrc={<CalendarIcon className={disabled ? clsx(classes.calendarIcon, classes.disabled) : classes.calendarIcon}/>}
        className={disabled ? clsx(classes.calendarIcon, classes.disabled) : classes.calendarIcon}
        imagePath={"calendar.svg"}
        returnSvg={true}
      />
    </InputAdornment>
  )
  const inputProps = {
    className: classes.calendarInput,
    endAdornment: calendarIconBox,
    disabled
  }

  const handleSetSelectedDate = (val: MaterialUiPickersDate|Date, isStartDate?: boolean) => {
    const formattedDate = dayjs(val).format(dateFormat)

    if (isStartDate) {
      setNewStartAt(formattedDate)
      setNewEndAt(formattedDate)
      handleChangeDates(formattedDate, formattedDate)
    } else {
      setNewEndAt(formattedDate)
      handleChangeDates(newStartAt, formattedDate)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection={isMdUp ? "row" : "column"}
      mt={5}
    >
      <Box
        className={classes.datePicker}
        width={isMdUp ? "50%" : "100%"}
        mr={isMdUp ? 3 : 0}
      >
        <DatePicker
          autoOk
          value={newStartAt}
          DialogProps={{
            className: classes.calendar,
          }}
          InputProps={inputProps}
          InputLabelProps={{
            shrink: true
          }}
          okLabel={null}
          label={t("consultation:sickLeave:startDate")}
          helperText=""
          error={false}
          format="dd-MM-yyyy"
          onChange={(val) => handleSetSelectedDate(val, true)}
          minDate={subtractDaysFromCurrentDate(3)}
          maxDate={addDaysToCurrentDate(1)}
          fullWidth={false}
          disabled={disabled}
        />
      </Box>
      <Box
        className={classes.datePicker}
        width={isMdUp ? "50%" : "100%"}
      >
        <DatePicker
          autoOk
          value={newEndAt}
          DialogProps={{
            className: classes.calendar,
          }}
          InputProps={inputProps}
          InputLabelProps={{
            shrink: true
          }}
          okLabel={null}
          label={t("consultation:sickLeave:endDate")}
          helperText=""
          error={false}
          format="dd-MM-yyyy"
          onChange={(val) => handleSetSelectedDate(val)}
          minDate={newStartAt}
          maxDate={addDaysToSpecifiedDate(newStartAt, 7)}
          disabled={disabled}
        />
      </Box>
    </Box>
  )
}

export default SickLeaveDates
