import React, {FC, useEffect, useState} from "react"
import {useFormContext, useWatch} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useQuery} from "@apollo/client"
import {Box, IconButton, Typography} from "@material-ui/core"

import {GET_MEDICINES} from "../../../../../../../../api/graphql/queries/getMedicinesItemsByMedicalProductId"
import {ReactComponent as TrashIcon} from "../../../../../../../../assets/svg/trash.svg"
import SelectController
  from "../../../../../../../../components/commonFormItems/SelectController/SelectController.component"
import ImageByIntegrationType from "../../../../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import Loader from "../../../../../../../../components/loader/Loader.component"
import {PatientsRequirementsStepData} from "../../../../../MedicalQuestionnaireStepperForm.types"
import {useExamFieldsStyles} from "../../../ReferralSection/ExamsFields/ExamFields.styles"

type Props = {
  index: number;
  onDelete: VoidFunction;
  name: string;
  medicalProductId: string;
  commonName: string;
  power: string;
  packageType: string;
  maxAmount: number;
}
type Medicine = {
  id: string
  power: string;
  productName: string;
  size: number;
  unit: string;
  activeSubstances: string;
  ean: string;
}

const VALUE_WHEN_CHOSEN_PACKAGE_SIZE_IS_DEFAULT = 1

const PrescriptionDetails: FC<Props> = ({
  name,
  index,
  onDelete,
  medicalProductId,
  commonName,
  power,
  packageType,
  maxAmount,
}) => {
  const formContext = useFormContext()
  const {t} = useTranslation()
  const classes = useExamFieldsStyles()
  const {data = {medicine: []}, loading} = useQuery(GET_MEDICINES, {
    variables: {
      productId: Number(medicalProductId)
    }
  })
  const chosenPackageFormKey = `prescription.prescriptions[${index}].chosenSize`
  const chosenUnitFormKey = `prescription.prescriptions[${index}].unit`
  const chosenPackage = useWatch({name: chosenPackageFormKey,})

  const [chosenPackageType, setChosenPackageType] = useState<string>(packageType)
  const medicinePackageOptions = data?.medicine?.map((medicine: Medicine) => {
    const packageOption = `${medicine.size} ${medicine.unit}`
    return {
      label: packageOption,
      value: medicine.size,
      unit: medicine.unit
    }
  }).sort((a:any, b:any) => {a.value - b.value})
  const showChoosePackageSize = medicinePackageOptions?.length > 0

  const amountOptions = Array.from(
    {length: maxAmount}, (_, i) => ({
      label: String(i + 1),
      value: i + 1
    }))

  useEffect(() => {
    const isNoPackageSizeToChoose = !showChoosePackageSize && !loading
    if ( isNoPackageSizeToChoose ) {
      formContext.setValue(chosenPackageFormKey, VALUE_WHEN_CHOSEN_PACKAGE_SIZE_IS_DEFAULT)
    }
  }, [showChoosePackageSize, loading])

  useEffect(() => {
    let isMounted = true
    const updatePrescriptionHeaderAndUnit = () => {
      if (!chosenPackage || loading) {
        return
      }

      const selectedOption = medicinePackageOptions.find((option: any) => option.value == chosenPackage)
      if (!selectedOption) {
        return
      }

      formContext.setValue(chosenUnitFormKey, selectedOption.unit)
      const selectedOptionLabel = selectedOption.label
      isMounted && setChosenPackageType(selectedOptionLabel)
    }

    updatePrescriptionHeaderAndUnit()

    return () => {
      isMounted = false
    }
  }, [chosenPackage, loading])

  return (
    <Box
      display='flex'
      flexDirection='column'
      mb={3}
      ml={1}
    >
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
      >
        <Typography
          variant='h6'
          color='textSecondary'
        >
          {index + 1}. {name} - {commonName}, {power}, {chosenPackageType}
        </Typography>
        <IconButton
          onClick={onDelete}
        >
          <ImageByIntegrationType
            imageSrc={<TrashIcon className={classes.icon}/>}
            className={classes.icon}
            imagePath={"trash.svg"}
            returnSvg={true}
          />
        </IconButton>
      </Box>
      {
        loading ? (
          <Loader/>
        ) : (
          <Box
            maxWidth='300px'
            mt={2}
            ml={2}
          >
            <Box
              mb={2}
            >
              <SelectController
                name={`prescription.prescriptions[${index}].chosenAmount`}
                label={t("medicalQuestionnaire:prescriptionPackagesAmount")}
                options={amountOptions}
              />
            </Box>
            {showChoosePackageSize &&
              <SelectController
                name={`prescription.prescriptions[${index}].chosenSize`}
                label={t("medicalQuestionnaire:prescriptionPackagesSize")}
                options={medicinePackageOptions}
              />
            }
          </Box>
        )
      }
    </Box>
  )
}

export default PrescriptionDetails
