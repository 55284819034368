import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import {Drug} from "../../../../../api/queries/getUserActionById"
import {useEPrescriptionStyles} from "../EPrescription.styles"

type PrescriptionProps = {
  code: string;
  drugs: Drug[];
}

const Prescription: FC<PrescriptionProps> = ({ code, drugs }) => {
  const {t} = useTranslation()
  const classes = useEPrescriptionStyles()

  return (
    <Box>
      <Typography
        variant="body1"
        className={classes.prescriptionTitle}
      >
        {`${t("healthPlanner:prescriptionCode")}: `}
        <Typography
          className={classes.boldFont}
          component="span"
        >
          {code}
        </Typography>
      </Typography>
      <Box className={classes.drugWrapper}>
        {drugs.map(drug => (
          <Box
            key={drug.name}
            className={classes.drug}
          >
            <Typography
              className={classes.count}
              variant="body1"
            >
              {drug.quantity} {t("healthPlanner:piece")}
            </Typography>
            <Box>
              <Typography
                variant="body1"
              >
                {drug.name}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
              >
                {drug.package}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default Prescription