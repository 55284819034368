import {createStyles, makeStyles} from "@material-ui/core"

import {Theme} from "@material-ui/core/styles"

export const useOrderExamsListStyles = makeStyles((theme: Theme) => createStyles({
  discountPrice: {
    margin: 0,
    color: theme.palette.success.main
  }
}))
