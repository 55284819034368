import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useButtonTextStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "13px",
    lineHeight: "16px",
    textAlign: "left",
    "&:disabled": {
      color: theme.palette.grey[400],
    },
  },
  whiteOnBlue: {
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      color: theme.palette.info.main,
    },
  },
  primary: {
    color: theme.palette.primary.dark,
    "&:hover, &:focus": {
      color: theme.palette.primary.darker,
    },
  },
  secondary: {
    color: theme.palette.secondary.dark,
    "&:hover, &:focus": {
      color: theme.palette.secondary.darker,
    },
  },
  dark: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:focus": {
      color: theme.palette.primary.darker,
    },
  },
}))
