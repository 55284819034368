import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import {IconButton, useMediaQuery} from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

import {RoutePath} from "../../../../routes/Routes.types"
import {Theme} from "@material-ui/core/styles"

type NavigationArrowProps = {
  navigateTo: RoutePath;
}

const NavigationArrow: FC<NavigationArrowProps> = ({ navigateTo}) => {
  const {i18n} = useTranslation()
  const isSmUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"))

  return (
    <IconButton
      component={Link}
      to={`/${i18n.language}${navigateTo}`}
      size={isSmUp ? "medium" : "small"}
    >
      <ArrowBackIcon/>
    </IconButton>
  )
}

export default NavigationArrow