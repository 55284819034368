import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import DoctorImage from "../../../../assets/images/doctor_2.png"
import ButtonTextUnderlined from "../../../../components/common/buttonTextUnderlined/ButtonTextUnderlined.component"
import ImageByIntegrationType from "../../../../components/imageByIntegrationType/ImageByIntegrationType"
import {RoutePath} from "../../../../routes/Routes.types"
import { useDiseaseManagementTileStyles } from "./DiseaseManagementTile.styles"

interface DiseaseManagementTileProps {}

const DiseaseManagementTile: FC<DiseaseManagementTileProps> = () => {
  const { t } = useTranslation()
  const classes = useDiseaseManagementTileStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Box
      className={classes.boxCard}
    >
      <Typography
        variant={isMdUp ? "h3" : "h4"}
        component="h3"
      >
        {t("diseaseManagement:title")}
      </Typography>      
      <Box className={classes.imageWrapper}>
        <ImageByIntegrationType
          imageSrc={DoctorImage}
          alt="DoctorImage"
          className={classes.image}
          imagePath={"doctor_2.png"}
        />
      </Box>
      <Link
        to={RoutePath.PREVENTION_DISEASES}
      >
        <ButtonTextUnderlined
          color={"primary"}
        >
          {t("diseaseManagement:checkRisk")}
        </ButtonTextUnderlined>
      </Link>
    </Box>
  )
}

export default DiseaseManagementTile