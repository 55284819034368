import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@material-ui/core"

import DoctorCalendarImage from "../../../../../assets/images/doctor-calendar.png"
import ImageByIntegrationType from "../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { useActionsListStyles } from "../ActionsList.styles"

interface EmptyActionListTabPanelProps {
  showImage: boolean,
  label: string
}

const EmptyActionListTabPanel: FC<EmptyActionListTabPanelProps> = ({
  showImage,
  label
}) => {
  const { t } = useTranslation()
  const classes = useActionsListStyles()
  
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Typography>{t(label)}</Typography>
      { showImage && (
        <Box className={classes.imageWrapper}>
          <ImageByIntegrationType
            imageSrc={DoctorCalendarImage}
            alt="DoctorCalendarImage"
            className={classes.image}
            imagePath={"doctor-calendar.png"}
          />
        </Box>
      )}
    </Box>
  )
}

export default EmptyActionListTabPanel
