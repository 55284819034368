import React, {FC, useEffect, useState} from "react"
import {Trans, useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {Link} from "react-router-dom"
import {Box, Button, Paper, TextField, Typography} from "@material-ui/core"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

import {PASSWORDLESS_MAGIC_LINK_ID_KEY} from "../../../app.config"
import {usePostPasswordlessLoginMutation} from "../../../store/passwordless/passwordless.api"
import { usePostPasswordlessConsultationMagiclinkMutation } from "../../../store/passwordlessMagiclink/passwordlessMagiclink.api"
import {selectSession} from "../../../store/session/session.selectors"
import Alert from "../../../components/alert/Alert.component"
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader.component"
import {isBaseQueryError} from "../../../store/store.utils"
import {handleRequestError, PASSWORDLESS_INVALID_CODE, useAccessCodeInput} from "./VerifyUserCode.utils"
import {useVerifyUserFormStyles} from "./VerifyUserCode.styles"

import {MAXIMUM_CHARACTER_EXCEEDED} from "./VerifyUserCode.config"

type VerifyUserCodeProps = {
  magicLinkId: string;
}

const VerifyUserCode: FC<VerifyUserCodeProps> = ({ magicLinkId }) => {
  const classes = useVerifyUserFormStyles()
  const {t} = useTranslation()

  const [userId, setUserId] = useState<string|null>(null)
  const [passwordlessErrorMessage, setPasswordlessErrorMessage] = useState<string|null|undefined>(null)
  const [consultationId, setConsultationId] = useState<string|null>(null)
  const [handleSendCode, { isLoading: isCodeLoading, error }] = usePostPasswordlessLoginMutation()
  const { inputValue, isMaximumCharacterExceeded, handleChange } = useAccessCodeInput()
  const errorMessage = handleRequestError(error)

  const host = window.location.hostname
  const isCompensa = host.includes("compensa")

  useEffect(() => {
    setPasswordlessErrorMessage(errorMessage)

    if (
      errorMessage === PASSWORDLESS_INVALID_CODE
        && isBaseQueryError(error)
        && error?.data?.details?.userId
        && error?.data?.details?.consultationId
    ) {
      setUserId(error?.data?.details?.userId)
      setConsultationId(error?.data?.details?.consultationId)
    }
  }, [error, errorMessage])

  const [
    reSentPasswordlessAccessCode,
    {
      isLoading: isPasswordlessMagiclinkLoading,
      data,
      isSuccess,
      error: passwordlessMagicLinkError
    }
  ] = usePostPasswordlessConsultationMagiclinkMutation()

  useEffect(() => {
    if (passwordlessMagicLinkError) {
      setPasswordlessErrorMessage("errors:unknownError")
    }

    if (isSuccess && data) {
      sessionStorage.setItem(PASSWORDLESS_MAGIC_LINK_ID_KEY, data.id)
      magicLinkId = data.id
      setPasswordlessErrorMessage(errorMessage)
    }
  }, [data, isSuccess, passwordlessMagicLinkError])

  const isLoading = isCodeLoading
  return (
    <Paper className={classes.container}>
      <Typography
        className={classes.title}
      >
        {t("passwordless:accessCodeTitle")}
      </Typography>
      <Typography
        className={classes.description}
      >
        <Trans
          i18nKey={isCompensa ? "passwordless:accessCodeDescriptionCompensa" : "passwordless:accessCodeDescription"}
        >
          t1 <span className={classes.bold}>t2</span> t3 <span className={classes.bold}>t4</span>
        </Trans>
      </Typography>
      {isSuccess && (
        <Box className={classes.successMessage}>
          {t("passwordless:reSentPasswordlessCodeSuccess")}
        </Box>
      )}
      <TextField
        className={classes.input}
        fullWidth
        label={t("prescription:code")}
        value={inputValue}
        onChange={handleChange}
        error={isMaximumCharacterExceeded}
        helperText={isMaximumCharacterExceeded && t("passwordless:maximumCharacterExceeded")}
      />
      {
        passwordlessErrorMessage && (
          <Alert
            className={classes.errorMessage}
            message={t(passwordlessErrorMessage)}
          />
        )
      }
      <Box
        className={classes.buttonsWrapper}
      >
        <Link
          className={classes.link}
          to="/"
        >
          <Button
            className={classes.cancelButton}
            variant="outlined"
            color="primary"
            disabled={isLoading}
            startIcon={<ArrowBackIosIcon/>}
          >
            {t("consultation:back")}
          </Button>
        </Link>
        { errorMessage === PASSWORDLESS_INVALID_CODE && userId && consultationId && (
          <Button
            className={classes.submitButton}
            variant="contained"
            color="primary"
            startIcon={isPasswordlessMagiclinkLoading && <ButtonLoader/>}
            disabled={isPasswordlessMagiclinkLoading}
            onClick={() => reSentPasswordlessAccessCode({
              userId,
              consultationId
            })}
          >
            {t("passwordless:reSentPasswordlessCode")}
          </Button>
        )}
        <Button
          className={classes.submitButton}
          variant="contained"
          color="primary"
          startIcon={isLoading && <ButtonLoader/>}
          disabled={inputValue.length !== MAXIMUM_CHARACTER_EXCEEDED || isLoading}
          onClick={
            () => handleSendCode({
              code: inputValue,
              magicLinkId
            })
          }
        >
          {t("send")}
        </Button>
      </Box>
    </Paper>
  )
}

export default VerifyUserCode
