export const acceptedFileUploadSizeInMB = 100 // MB

export const acceptedFileUploadSizeInBytes = acceptedFileUploadSizeInMB * 1024 * 1024

export const acceptedFileUploadFormats = [
  "audio/mpeg", //.mp3
  "audio/ogg", //.oga .ogg .ogv .opus
  "audio/wav", //.wav

  "image/bmp", //.bmp
  "image/x-ms-bmp", //.bmp
  "image/x-windows-bmp", //.bmp
  "image/gif", //.gif
  "image/jpg", //.jpg
  "image/jpeg", //.jpeg
  "image/png", //.png
  "image/tif", //.tif
  "image/tiff", //.tiff

  "video/mp4", //.m4v .mp4
  "video/mpeg", //.mpeg .mpg
  "video/quicktime", //.mov
  "video/x-flv", //.flv
  "video/x-matroska", //.mkv
  "video/x-msvideo", //.avi
  "video/webm", //.webm

  "application/pdf", //.pdf
  "application/rtf", //.rtf
  "application/msword", //.doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //.docx
  "application/vnd.oasis.opendocument.text", //.odt
  "application/vnd.ms-excel",
  "text/csv",
  "text/plain"
]
