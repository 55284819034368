import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@material-ui/core"

import { useGetMessagesForThread } from "../../../../api/queries/getMessagesForThread/getMessagesForThread.hook"
import Loader from "../../../../../../components/loader/Loader.component"
import DoctorChatMessage from "./DoctorChatMessage/DoctorChatMessage.component"
import PatientChatMessage from "./PatientChatMessage/PatientChatMessage.component"
import { useMessengerChatStyles } from "./MessengerChat.styles"

interface MessengerChatProps {
  threadId: string;
  userId: string;
}

const MessengerChat: FC<MessengerChatProps> = ({ threadId, userId }) => {
  const { t } = useTranslation()
  const classes = useMessengerChatStyles()

  const { data: { chatMessagesAggregate } = {}, loading, error } = useGetMessagesForThread({ variables: { threadId }})

  if (loading) {
    return <Loader m="auto" />
  }

  if (error) {
    return (
      <Box
        m="auto"
        p={4}
      >
        <Typography variant="body1">{t("errors:defaultError")}</Typography>
      </Box>
    )
  }

  return (
    <Box
      className={classes.chat}
    >
      {chatMessagesAggregate?.nodes.map((message) => {
        if (userId === message.senderId) {
          return (
            <PatientChatMessage
              key={message.id}
              message={message.body}
              createdAt={message.createdAt}
            />
          )
        }
        
        return (
          <DoctorChatMessage
            key={message.id}
            message={message.body}
            createdAt={message.createdAt}
            doctorImage={{
              src: null,
              alt: null,
            }}
          />
        )
      })}
    </Box>
  )
}

export default MessengerChat
