import {gql} from "@apollo/client"

export const GET_MEDICINE_CATEGORY = gql`
  query getMedicineCategory($ean: String!) {
    medicine(where: {ean: {_eq: $ean}}) {
      id
      disease_category_id
    }
  }
`
