import {Dispatch} from "react"
import {useDebouncedCallback} from "use-debounce/esm"

import {useDeepEqualEffect} from "../../../../../hooks/useDeepEqualEffect"
import {Address} from "../AddressSearchEngine.types"
import {UseFormReturn} from "react-hook-form/dist/types"

export const useDebounceSubmit = (
  { watch, handleSubmit }: UseFormReturn<Address>,
  onSubmit: Dispatch<Address>
): void => {
  const formValues = watch()
  const handleDebounceSubmit = useDebouncedCallback(handleSubmit(onSubmit), 1000)
  useDeepEqualEffect(() => {
    const isAnyDataProvided = Object.values(formValues).some(value => !!value)
    if (isAnyDataProvided) {
      handleDebounceSubmit()
    }
  }, [formValues])
}