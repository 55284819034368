import React, { FC, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { Box, useMediaQuery, useTheme } from "@material-ui/core"

import useIsB2BClinic from "../../../../hooks/useIsB2BClinic"
import { GET_USER_ACTIONS, UserActionModel, UserActionsResponseData } from "../../api/queries/getUserActionsQuery"
import ActionsList from "../actionsList/ActionsList.component"
import ActionsListItem from "../actionsList/actionsListItem/ActionsListItem.component"
import { PanelData } from "../actionsList/dynamicActionListPanels/DynamicActionListPanels.component"
import AwardsTile from "../awardsTile/AwardsTile.component"
import DiaryMeasurementTile from "../diaryMeasurementTile/DiaryMeasurementTile.component"
import DiseaseManagementTile from "../diseaseManagementTile/DiseaseManagementTile.component"
import MessengerTile from "../messengerTile/MessengerTile.component"

const healthPlannerTabs = [
  { label: "healthPlannerActions:actionsUpcoming" },
  { label: "healthPlannerActions:actionsHistory" }
]

const HealthPlannerDashboard: FC = () => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))
  const { B2BClinic } = useIsB2BClinic()

  const {
    data: { userActions } = {},
    error: error,
    loading: loading,
    refetch
  } = useQuery<UserActionsResponseData>(GET_USER_ACTIONS, {
    context: {clientName: "HealthPlanner"},
  })

  useEffect(() => {
    refetch()
  }, [])

  const userActionsFilteredByB2bClinic = userActions?.filter(
    userAction => (B2BClinic ? userAction.action.isVisibleOnB2bClinic : true) && userAction.action
  )

  const isMentalHealthAction = (userAction: UserActionModel) => {
    return userAction?.action?.codeName.includes("MH")
  }

  const renderActiveActions = userActionsFilteredByB2bClinic?.map(userAction => {
    if(!userAction.completed_at && !loading && !error && !isMentalHealthAction(userAction)) {
      return (
        <ActionsListItem
          key={userAction.id}
          id={userAction.id}
          points={userAction?.action?.points}
          image_url={userAction?.action?.image_url}
          name={userAction?.action?.name}
          deadline={userAction.deadline}
          type={userAction.action.type}
          completed_at={userAction.completed_at}
          actionSource={userAction.source}
        />
      )
    }
  })

  const renderDoneActions = [...(userActionsFilteredByB2bClinic ?? [])]
    .sort((a,b) => new Date(a.completed_at).getTime() - new Date(b.completed_at).getTime())
    .reverse()
    .map(userAction => {
      if (userAction.completed_at && !loading && !error && !isMentalHealthAction(userAction)) {
        return (
          <ActionsListItem
            key={userAction.id}
            id={userAction.id}
            points={userAction?.action?.points}
            image_url={userAction?.action?.image_url}
            name={userAction?.action?.name}
            deadline={userAction.deadline}
            type={userAction.action.type}
            completed_at={userAction.completed_at}
            actionSource={userAction.source}
            isDisabled
          />
        )
      }
    })

  const panelsData:PanelData[] = [
    {
      isEmpty: userActionsFilteredByB2bClinic?.length === 0,
      renderContent: renderActiveActions,
      emptyMessageLabel: "healthPlannerActions:noCompletedActionsMessage",
      showImageOnEmptyList: true
    },
    {
      isEmpty: userActionsFilteredByB2bClinic?.length === 0,
      renderContent: renderDoneActions,
      emptyMessageLabel: "healthPlannerActions:noCompletedActionsMessage",
      showImageOnEmptyList: true
    }
  ]

  return (
    <Box
      display="flex"
      alignItems={isMdUp ? "flex-start" : "center"}
      flexDirection="column"
    >
      <Box
        display="flex"
        flexDirection={isMdUp ? "row" : "column"}
        alignItems={isMdUp ? "unset" : "center"}
        gridGap={isMdUp ? theme.spacing(2) : 0}
        mb={isMdUp ? 2 : 0}
        width="100%"
      >
        <AwardsTile />
        <DiaryMeasurementTile />
        {isLgUp && <DiseaseManagementTile />}
      </Box>
      <Box
        display="flex"
        flexDirection={isMdUp ? "row" : "column"}
        alignItems={isMdUp ? "unset" : "center"}
        gridGap={isMdUp ? theme.spacing(2) : 0}
        mb={2}
        width="100%"
      >
        <MessengerTile />
      </Box>
      <ActionsList 
        tabs={healthPlannerTabs}
        panelsData={panelsData}
        loading={loading}
        error={!!error}
      />
    </Box>
  )
}

export default HealthPlannerDashboard
