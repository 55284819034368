import React, {FC, ReactNode} from "react"
import {Box, Typography} from "@material-ui/core"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"

import trophy from "../../../../assets/svg/trophy.svg"
import ImageByIntegrationType from "../../../../components/imageByIntegrationType/ImageByIntegrationType"
import {ToastContentProps} from "react-toastify/dist/types"
import {ClassNameMap} from "@material-ui/styles/withStyles"

type SuccessToastProps = Required<ToastContentProps<{
  classes: ClassNameMap<"container" | "description" | "closeIcon" | "icon" | "closeIconWrapper">;
  text: ReactNode;
  actionName: string;
}>>

const SuccessToast: FC<SuccessToastProps> = ({ 
  closeToast,
  data: { classes, text },
}) => {

  return (
    <Box
      className={classes.container}
    >
      <ImageByIntegrationType
        imageSrc={trophy}
        alt="trophy"
        className={classes.icon}
        imagePath={"trophy.svg"}
      />
      <Typography
        className={classes.description}
        variant="body2"
      >
        {text}
      </Typography>
      <Box
        className={classes.closeIconWrapper}
      >
        <Box
          className={classes.closeIcon}
          onClick={closeToast}
        >
          <CloseRoundedIcon/>
        </Box>
      </Box>
    </Box>
  )
}

export default SuccessToast