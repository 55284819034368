export const boxShadows: Record<string, string> = {
  shadow1: "0px 0px 52px rgba(0, 0, 0, 0.05)",
  shadow2: "0px 0px 52px rgba(0, 0, 0, 0.11)",
  shadow3: "0px 0px 52px rgba(0, 0, 0, 0.2)",
  shadow4: "0px 0px 52px rgba(108, 108, 108, 0.09)",
  shadow5: "0px 0px 4px rgba(0, 0, 0, 0.3)",
}

export const buttonShadows: Record<string, string> = {
  shadow1: "0 4px 4px rgba(0, 0, 0, 0.3)",
}

export const menuShadows: Record<string, string> = {
  shadow1: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
  shadow2: "0px 40px 200px 0px rgba(0, 0, 0, 0.5)",
}

export const sidebarShadows: Record<string, string> = {
  shadow1: "-20px 0px 20px 0 rgba(0, 0, 0, 0.1)",
}

export const videoShadows: Record<string, string> = {
  shadow1: "0px -1px 1px rgba(29, 40, 57, 0.05), 0px 8px 15px rgba(29, 40, 57, 0.12), 0px 18px 36px rgba(29, 40, 57, 0.07)",
}

export const langDropdownShadows: Record<string, string> = {
  shadow1: "0px -1px 1px rgba(29, 40, 57, 0.05), 0px 8px 15px rgba(29, 40, 57, 0.12), 0px 18px 36px rgba(29, 40, 57, 0.07)",
}
