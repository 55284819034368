import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import { useSelector } from "react-redux"
import {NavLink} from "react-router-dom"
import { Avatar, Typography, useMediaQuery } from "@material-ui/core"

import { selectClinicSettings } from "../../../store/clinic/clinicSettings.selectors"
import theme from "../../../themes/default/theme"
import { RoutePath } from "../../../routes/Routes.types"
import { useUserAvatarStyles } from "../../user/userAvatar/UserAvatar.styles"
import { useLoyalityProgramBadgeStyles } from "./LoyalityProgramBadge.styles"
import { Variant } from "@material-ui/core/styles/createTypography"

interface LoyalityProgramBadgeProps {
  size?: number;
  fontVariant?: Variant;
}

const LoyalityProgramBadge: FC<LoyalityProgramBadgeProps> = ({ size, fontVariant }) => {
  const clinicSettings = useSelector(selectClinicSettings)
  const classes = useUserAvatarStyles({size, customBackgroundColor: clinicSettings?.frontendSettings?.style?.hoverColor})
  const { loyalityBadgeWrapper } = useLoyalityProgramBadgeStyles()
  const { i18n } = useTranslation()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  
  return (
    <>
      {isMdUp && <Typography variant='body2'>Brąz</Typography>}
      <NavLink 
        to={`/${i18n.language}${RoutePath.LOYALITY_PROGRAM}`}
        className={loyalityBadgeWrapper}
      >
        <Avatar classes={classes}>
          <Typography
            variant={fontVariant || "h4"}
            component="span"
          >
            1
          </Typography>
        </Avatar>
      </NavLink>
    </>
  )
}

export default LoyalityProgramBadge