export const mentalHealthColors = {
  grey: "#808080",
  lightRed: "#E87474",
  darkBlue:"#012760",
  lightBlue: "#0D408E",
  verySadMood:"#B93C3C",
  sadMood:"#E67337",
  neutralMood:"#D8D12A",
  happyMood:"#95A820",
  veryHappyMood: "#20A869"
}