import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core"

interface ReferralSectionHeaderProps {}

const ReferralSectionHeader: FC<ReferralSectionHeaderProps> = () => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isSmUp = useMediaQuery((theme.breakpoints.up("sm")))

  return (
    <Box display="flex" flexWrap={"wrap"}>
      <Typography
        variant={isSmUp ? "h3": "h5"}
        component="h3"
        style={{
          display: isSmUp ? "inline" : "block",
          marginRight: `${ theme.spacing(1)}px`,
        }}
      >
        { t("referrals:referral") }
      </Typography>
    </Box>
  )
}

export default ReferralSectionHeader
