import {AddToCalendarMenuItem} from "./AddToCalendar.types"

export const addToCalendarMenuItems: AddToCalendarMenuItem[] = [
  {
    type: "google",
  },
  {
    type: "outlook",
  },
  {
    type: "outlookCom",
  },
  {
    type: "apple",
  },
]
