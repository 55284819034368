import {alpha, makeStyles} from "@material-ui/core"

import {boxShadows} from "../../../../../themes/shadows"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useNestedMenuStyles = makeStyles((theme: Theme) => createStyles({
  backdrop: {
    backgroundColor: `${alpha(theme.palette.background.default, .8)} !important`,
    [theme.breakpoints.up("md")]: {
      backgroundColor: "transparent !important"
    },
  },
  popoverPaper: {
    minWidth: "100%",
    boxShadow: boxShadows.shadow3,
    marginLeft: theme.spacing(2),
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    [theme.breakpoints.up("md")]: {
      minWidth: "375px",
      width: "375px",
      marginLeft: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      overflow: "hidden",
    },
    // white space below open menu
    position: "relative",
    overflow: "visible",
    "&::after": {
      content: "''",
      position: "absolute",
      top: "100%",
      left: 0,
      right: 0,
      height: "200%",
      width: "100%",
      background: theme.palette.common.white,
      [theme.breakpoints.up("md")]: {
        display: "none",
      }
    },
    // e/o white space below open menu
  },
  listItemDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  listItemImg: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      height: "100%",
      width: "70px",
    }
  },
  listItem: {
    padding: theme.spacing(0, 1, 0),
    borderRadius: "0",
    transition: "background-color 0.3s ease-in-out",
    position: "relative",
    "& > .MuiButton-label": {
      borderBottom: `2px solid ${theme.palette.grey[100]}`,
    },
    "&:hover, &:focus": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    // left borderline animation
    "&:hover::before, &:focus::before": {
      transform: "scaleY(1)",
    },
    "&:last-child": {
      "& > .MuiButton-label": {
        borderBottom: 0,
      },
    },
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "4px",
      background: theme.palette.primary.main,
      transform: "scaleY(0)",
      transformOrigin: "50% 50%",
      transition: "transform 0.3s ease-in-out",
    }
    // e/o left borderline animation
  },
  listItemLabel: {
    color: theme.palette.grey[400],
    marginTop: 0,
  },
  listItemHeader: {
    marginBottom: 0,
  }
}))
