import {useEffect} from "react"

import {useQueryParam} from "../../../../hooks/useSearchParams"
import {useCreateBmiActionMutation} from "../../api/mutations/createBmiAction/createBmiAction.hook"
import {useGetBmiUserActionQuery} from "../../api/queries/getBmiUserAction/getBmiUserAction.hook"

export const useBmiAction = () => {
  const weight = useQueryParam("weight")
  const height = useQueryParam("height")
  const [handleCreate, { loading, called }] = useCreateBmiActionMutation({ weight, height })
  const { data, loading: isLoading } = useGetBmiUserActionQuery({ skip: called && loading })

  useEffect(() => {
    handleCreate()
  }, [])
  
  return {
    points: data?.usersActions[0]?.action.points,
    isLoading: loading || isLoading
  }
}