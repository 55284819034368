import {useEffect} from "react"

import {isBaseQueryError, isV4ValidationErrorResponse} from "../store/store.utils"
import {handleReactHookFormErrors} from "../utils/handleErrors"
import {UseFormReturn} from "react-hook-form/dist/types"

const useHandleReactHookFormErrors = <FormFields>(
  form: UseFormReturn<FormFields>,
  error: unknown,
) => {
  useEffect(() => {
    if (isBaseQueryError(error) && isV4ValidationErrorResponse<FormFields>(error.data)) {
      handleReactHookFormErrors(error.data.errors, form)
    }
  }, [error, form])
}

export {
  useHandleReactHookFormErrors
}
