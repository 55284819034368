import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Button, Dialog, Typography} from "@material-ui/core"

import ButtonLoader from "../../../../../../components/common/buttonLoader/ButtonLoader.component"
import {useConfirmationModalStyles} from "./ConfirmationModal.styles"

type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  isConfirmButtonLoading: boolean;
  diseasesNames: string[];
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({ 
  isOpen,
  onClose,
  onConfirm,
  isConfirmButtonLoading,
  diseasesNames
}) => {
  const {t} = useTranslation()
  const classes = useConfirmationModalStyles()

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <Typography variant="h3">
        {t("healthPlanner:addDiseasesConfirmationLabel")}
      </Typography>
      <ul>
        {
          diseasesNames.map(diseasesName => (
            <li key={diseasesName}>{diseasesName}</li>
          ))
        }
      </ul>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        marginTop={3}
      >
        <Button
          className={classes.button}
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={onConfirm}
          startIcon={isConfirmButtonLoading && (<ButtonLoader position="prefix"/>)}
        >
          {t("confirm")}
        </Button>
      </Box>
    </Dialog>
  )
}

export default ConfirmationModal
