import React, {FC, useEffect, useState} from "react"
import {Controller, FieldErrors,FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box, Button, TextField, Typography} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab"
import {push} from "connected-react-router"
import i18next from "i18next"

import api from "../../api/api"
import useMakeQuery from "../../api/graphql/hooks/useMakeQuery"
import {postConsultationFileConfig} from "../../api/routes"
import {useAppDispatch, useAppSelector} from "../../hooks/storeHooks"
import useConsultationLang from "../../hooks/useConsultationLang"
import useGetFormValidationRules from "../../hooks/useGetFormValidationRules"
import useGoToConsultationPage from "../../hooks/useGoToConsultationPage"
import store from "../../store"
import {selectUser} from "../../store/user/user.selectors"
import FileDropzone from "../commonFormItems/fileDropzone/FileDropzone.component"
import TextFieldController from "../commonFormItems/textFieldController/TextFieldController.component"
import MedicalSurveyCheckbox from "./medicalSurveyCheckbox/MedicalSurveyCheckbox.component"
import MedicalSurveyFields from "./MedicalSurveyFields.component"
import OrderBlockedPopup from "./orderBlockedPopup/OrderBlockedPopup.component"
import PregnantConfirmationPopup from "./pregnantConfirmationPopup/PregnantConfirmationPopup.component"
import {getPrescriptionConsultationIdParam} from "../consultation/Consultation.utils"
import {useMedicalSurvey} from "./MedicalSurvey.utils"
import {ConsultationWidgetVisitDatePicker} from "../../store/makeConsultationWidget/makeConsultationWidget.types"
import {Gender} from "../../store/user/user.types"
import {ConsultationModel, ConsultationStatusNumber, ConsultationType} from "../consultation/Consultation.types"
import {
  medicalSurveyData,
  medicalSurveyFields,
  surveyDefaults,
  surveyExtraQuestions,
  surveyType
} from "./MedicalSurvey.types"
import {useMedicalSurveyStyles} from "./MedicalSurvey.styles"

import {medicalSurveySchema} from "./MedicalSurvey.validation"

type MedicalSurveyProps = {
  consultationData: ConsultationModel;
  medicalSurveyType: string;
  consultationMedicines: string;
}

const MANUAL_PRESCRIPTION_VALUE = "1"
const FEMALE_CATEGORY = 1

const MedicalSurvey: FC<MedicalSurveyProps> = ({
  consultationData, 
  medicalSurveyType,
  consultationMedicines,
}) => {
  const { t, i18n } = useTranslation()
  const {
    boxCard,
    textarea,
    select,
    uploadDropzone,
  } = useMedicalSurveyStyles()
  const {
    createMessageTemplate,
    createCustomMessageTemplate,
    sendMessage,
    consultationId,
    getValuesInterval,
    getTemperatureValues,
  } = useMedicalSurvey()
  const user = useAppSelector(selectUser)
  const consultationLang = useConsultationLang()
  const {
    getMedicalQuestionsByMedicineIdQuery,
    getMedicineCategoryQuery,
  } = useMakeQuery()
  const { goToMakeConsultationPage } = useGoToConsultationPage({
    visitDatePicker: ConsultationWidgetVisitDatePicker.PRESCRIPTION_ONLY
  }, getPrescriptionConsultationIdParam(consultationData.id))

  const renderQuestionsForSelectedDrug = () => {
    const questionsList: JSX.Element[] = []

    medicineData?.data?.map((item: surveyExtraQuestions) => {
      questionsList.push(
        <MedicalSurveyCheckbox
          key={item.id}
          name={`extraQuestion_${item.id}`}
          label={item.question}
          options={checkboxOptions}
        />
      )}
    )

    return questionsList
  }

  const generateCheckboxOptions = (stringValues?: boolean) => [
    {
      value: stringValues ? "1" : 1,
      label: t("prescription:yesLabel")
    },
    {
      value: stringValues ? "0" : 0,
      label: t("prescription:noLabel")
    }
  ]
  const [errors, setErrors] = useState<string[]>([])
  const [file, setFile] = useState<File | null>(null)
  const [isSaving, setSaving] = useState(false)
  const checkboxAccordionOptions = generateCheckboxOptions()
  const checkboxOptions = generateCheckboxOptions(true)
  const [showPregnantConfirmationPopup, setShowPregnantConfirmationPopup] = useState(false)
  const medicineCategoryData = getMedicineCategoryQuery(consultationMedicines)
  const medicineData = getMedicalQuestionsByMedicineIdQuery(consultationMedicines)
  const medicineDiseaseCategory = medicineCategoryData?.medicine?.[0]?.disease_category_id
  const orderBlocked = medicineDiseaseCategory === FEMALE_CATEGORY && user?.gender === Gender["male"]
  const dispatch = useAppDispatch()

  const isBasicType = medicalSurveyType === surveyType.BASIC

  const form = useForm({
    // @ts-ignore
    defaultValues: surveyDefaults[medicalSurveyType],
    mode: "all",
    resolver: useGetFormValidationRules(medicalSurveySchema),
  })

  const handleFileChange = (files: File[]) => {
    setFile(files.length ? files[0] : null)
  }

  const validateAdditionalQuestions = (values: medicalSurveyFields) => {
    let isError = false

    medicineData?.data?.map((item: surveyExtraQuestions) => {
      const answer = values[`extraQuestion_${item.id}`]

      if (answer === undefined) {
        form.setError(`extraQuestion_${item.id}`, { message: t("errors:required") })
        isError = true
      }
    })

    if (isError) {
      return false
    }

    return true
  }

  const setSurveyData = (values: medicalSurveyFields) => {
    const medicalSurveyData: medicalSurveyData[] = []

    medicineData?.data?.map((item: surveyExtraQuestions) => {
      const answer = values[`extraQuestion_${item.id}`]

      if (answer !== undefined) {
        medicalSurveyData.push({
          id: item.id,
          question: item.question,
          answer: t(parseInt(answer) === 1 ? "prescription:yesLabel" : "prescription:noLabel")
        })
      }
    })

    return medicalSurveyData
  }

  const onSubmit = form.handleSubmit(async (values: medicalSurveyFields) => {
    const newMessage = isBasicType
      ? createMessageTemplate(values, medicineData?.data)
      : createCustomMessageTemplate(values, medicineData?.data)
    const questionData = setSurveyData(values)
    const medicineCategory = medicineCategoryData?.medicine?.[0]?.id
    const manualPrescription = [values?.pregnant, values?.breastFeed].includes(MANUAL_PRESCRIPTION_VALUE)

    if (orderBlocked) {
      return false
    }

    if (validateAdditionalQuestions(values)) {
      setSaving(true)
      sendMessage(
        newMessage,
        setSaving, 
        questionData,
        values,
        isBasicType,
        manualPrescription,
        medicineCategory,
      )
    }
  })

  const handleUploadFilesSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()
    onSubmit?.()

    setErrors([])

    if (file) {
      const formData = new FormData()
      formData.append("file", file)

      try {
        await api.request({
          ...postConsultationFileConfig(consultationId),
          data: formData
        })

        setFile(null)
      } catch (e) {
        if (e.response?.data?.errors) {
          setErrors(e.response.data.errors.map((error: string) => consultationLang.getLabel(`errors:${error}`)))
        }
      }
    }
  }

  const handleShowPregnantConfirmationPopup = (pregnantOptionValue: number) => {
    form.getValues("pregnant")
    setShowPregnantConfirmationPopup(pregnantOptionValue === 1)
  }

  const handleOnChangeAnswer = () => {
    form.setValue("pregnant", "0")
    setShowPregnantConfirmationPopup(false)
  }

  const checkScrollToError = (errors: FieldErrors<medicalSurveyFields>) => {
    const elements = Object.keys(errors).map(name => document.getElementById(name)).filter(el => !!el) || []

    elements.pop()?.scrollIntoView({behavior: "smooth", block: "center"})
  }

  if (consultationData && (consultationData?.status_number !== ConsultationStatusNumber.RESERVED || consultationData?.consultationType !== ConsultationType.PRESCRIPTION_ONLY || consultationData?.prescriptionQuestionnaireFilled)) {
    dispatch(push(`/${i18next.language}`))
  }

  useEffect(() => {
    checkScrollToError(form.formState.errors)
  }, [form.formState.errors])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!consultationMedicines) {
    goToMakeConsultationPage()
    return null
  }

  return (
    <Box
      className={boxCard}
    >
      <FormProvider {...form} >
        <Box
          component="form"
          onSubmit={onSubmit}
          width="100%"
        >
          <Typography
            variant="h3"
          >
            {t("prescription:fillMedicalSurvey")}
          </Typography>
          <Box
            mt={2}
          >
            <Typography
              variant="body1"
            >
              {t("prescription:surveyRequired")}
            </Typography>
          </Box>
          <Box
            mt={5}
          >
            <Controller
              name="temperature"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  className={select}
                  options={getTemperatureValues(35, 42)}
                  getOptionLabel={({ label }: { label: string }) => label}
                  getOptionSelected={(option, value) => option.label === value.label}
                  noOptionsText={t("prescription:noResults")}
                  clearOnEscape
                  id="temperature"
                  onChange={(_, data) => field.onChange(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="temperature"
                      variant="outlined"
                      placeholder={t("prescription:temperaturPlaceholder")}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
            <Controller 
              name="height"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  className={select}
                  options={getValuesInterval(100, 220, "cm")}
                  noOptionsText={t("prescription:noResults")}
                  getOptionLabel={({ label }: { label: string }) => label}
                  getOptionSelected={(option, value) => option.label === value.label}
                  clearOnEscape
                  id="height"
                  onChange={(_, data) => field.onChange(data)}
                  renderInput={(params) => (
                    <TextField 
                      {...params}
                      placeholder={t("prescription:heightPlaceholder")}
                      variant="outlined"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
            <Box
              mb={4}
            >
              <Controller
                name="weight"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    className={select}
                    disableClearable
                    noOptionsText={t("prescription:noResults")}
                    options={getValuesInterval(30, 200, "kg")}
                    getOptionLabel={({ label }: { label: string }) => label}
                    getOptionSelected={(option, value) => option.label === value.label}
                    clearOnEscape
                    id="weight"
                    onChange={(_, data) => field.onChange(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t("prescription:weightPlaceholder")}
                        variant="outlined"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Box>
            {
              isBasicType && (
                !user?.gender && (
                  <>
                    <MedicalSurveyCheckbox
                      name="pregnant"
                      label={t("prescription:surveyQuestions:pregnant")}
                      options={checkboxOptions}
                      customOnChange={() => handleShowPregnantConfirmationPopup(parseInt(form.getValues("pregnant")))}
                    />
                    <MedicalSurveyCheckbox
                      name="breastFeed"
                      label={t("prescription:surveyQuestions:breastFeed")}
                      options={checkboxOptions}
                    />
                  </>
                )
              )
            }
            <MedicalSurveyFields
              checkboxAccordionOptions={checkboxAccordionOptions}
              checkboxOptions={checkboxOptions}
              isBasicType={isBasicType}
              customSurveyFields={surveyDefaults[surveyType.CUSTOM]}
            />
            {renderQuestionsForSelectedDrug()}
            {
              isBasicType && (
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                  >
                    <Typography
                      variant="body1"
                    >
                      {t("prescription:surveyQuestions:importantInformations")}
                    </Typography>
                    <TextFieldController
                      name="importantInformations"
                      multiline
                      placeholder=""
                      label=""
                      InputProps={{ classes: { input: textarea } }}
                    />
                  </Box>
                </>
              )
            }
          </Box>
          <Box
            mt={4}
          >
            <Typography
              variant="body1"
            >
              {t("prescription:surveyQuestions:fileZoneLabel")}
            </Typography>
            <Box
              mt={2}
            >
              <FileDropzone
                apiErrors={errors}
                resetApiErrors={() => setErrors([])}
                handleInputChange={handleFileChange}
                customClassUploadDropzone={uploadDropzone}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            mt={4}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSaving || orderBlocked}
              onClick={handleUploadFilesSubmit}
            >
              {t("prescription:approveAndSend")}
            </Button>
          </Box>
        </Box>
      </FormProvider>
      <PregnantConfirmationPopup
        isOpen={showPregnantConfirmationPopup}
        onClose={() => setShowPregnantConfirmationPopup(false)}
        onChangeAnswer={() => handleOnChangeAnswer()}
      />
      <OrderBlockedPopup
        isOpen={orderBlocked}
        onClose={(() => store.dispatch(push(`/${i18n.language}`)))}
      />
    </Box>
  )
}

export default MedicalSurvey