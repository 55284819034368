import React, { FC } from "react"
import { Typography } from "@material-ui/core"

import { internistSpecializationId, psychologistSpecializationId } from "../../../../../app.config"
import lowMoodImg
  from "../../../../../assets/images/healthPrevention/mentalHealth/low-mood.png"
import HealthPreventionSelfImprovement, {
  HealthPreventionSelfImprovementBoxItem,
  HealthPreventionSelfImprovementContentBox,
  HealthPreventionSelfImprovementHeaderBox
} from "../../../../../components/healthPrevention/selfImprovement/HealthPreventionSelfImprovement.component"
import ImageByIntegrationType from "../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { RoutePath } from "../../../../../routes/Routes.types"

const translationKeyPrefix = "mentalHealth:selfImprovement"

export const mentalHealthSelfImprovementBoxItems: HealthPreventionSelfImprovementBoxItem[] = [
  {
    title: `${translationKeyPrefix}:infoBox:title`,
    content: `${translationKeyPrefix}:infoBox:content`,
    btnLabel: `${translationKeyPrefix}:infoBox:btnLabel`,
    btnRedirectRoute: RoutePath.MENTAL_HEALTH_LIBRARY,
  },
  {
    title: `${translationKeyPrefix}:specializationBox:title`,
    content: `${translationKeyPrefix}:specializationBox:content`,
    btnLabel: `${translationKeyPrefix}:specializationBox:btnLabel`,
    btnRedirectRoute: RoutePath.MENTAL_HEALTH_LIBRARY,
    specializationId: process.env.NODE_ENV === "production" 
      ? psychologistSpecializationId
      : internistSpecializationId,
  }
]

const MentalHealthSelfImprovementLowMoodPage: FC = () => {
  const getImage = (src: string, path: string) => {
    return <ImageByIntegrationType imageSrc={src} alt={path} imagePath={path}/>
  }

  const headerBox: HealthPreventionSelfImprovementHeaderBox = {
    title: `${translationKeyPrefix}:items:lowMood:title`,
    content: `${translationKeyPrefix}:items:lowMood:body`,
    redirectRoute: RoutePath.MENTAL_HEALTH_SELF_IMPROVEMENT,
    image: getImage(lowMoodImg, "low-mood.png"),
    backBtnLabel: "consultation:back",
  }

  const contentBox: HealthPreventionSelfImprovementContentBox = {
    content: <>
      <Typography>
        <strong>Co to jest obniżony nastrój?</strong>
        <br /><br />
        Obniżony nastrój może objawiać się nie tylko przelotne uczucie smutku. To stan, który może poważnie wpłynąć na codzienne funkcjonowanie, relacje z innymi oraz jakość życia. Objawia się on uczuciem przygnębienia, brakiem energii, utratą zainteresowania życiem codziennym, odczuwaniem złości, oraz trudnościami w koncentracji.
        <br /><br />
        <strong>Jak sobie z tym poradzić?</strong>
        <br /><br />
        Pierwszym krokiem jest zrozumienie, że obniżony nastrój to problem, z którym nie warto się samemu zmagać. Nie krępuj się szukać wsparcia i pomocy. Możesz skorzystać z terapii indywidualnej, grupowej lub poradnictwa psychologicznego.
        <br /><br />
        Pamiętaj również o zdrowym trybie życia. Regularna aktywność fizyczna, zdrowa dieta oraz odpowiednia ilość snu mogą znacząco wpłynąć na poprawę nastroju.
        <br /><br />
        <strong>Nie bagatelizuj swoich uczuć</strong>
        <br /><br />
        Twoje emocje są ważne, a negowanie ich może prowadzić do pogłębienia problemu. Zamiast tego, pozwól sobie na przeżycie swoich uczuć i szukaj zdrowych sposobów ich wyrażania.
        <br /><br />
        <strong>Znajdź wsparcie</strong>
        <br /><br />
        Nie musisz radzić sobie z obniżonym nastrojem samotnie. Znajdź osobę, której możesz zaufać, i podziel się swoimi uczuciami. Czasem rozmowa może być pierwszym krokiem do poprawy samopoczucia.
        <br /><br />
        <strong>Nie trać nadziei</strong>
        <br /><br />
        Pamiętaj, że obniżony nastrój jest stanem, który można pokonać. Nie trać wiary w siebie i swoje możliwości. Każdy dzień może być nową szansą na poprawę.
        <br /><br />
        <strong>Skorzystaj z pomocy</strong>
        <br /><br />
        Jeśli czujesz, że obniżony nastrój utrudnia Ci codzienne funkcjonowanie i nie potrafisz sobie sam poradzić, nie wahaj się skorzystać z pomocy specjalistów. Zaufaj doświadczonym terapeutom i psychologom, którzy pomogą Ci odzyskać równowagę psychiczną i wrócić do pełni życia.
        <br /><br />
        Zadbaj o siebie i swoje zdrowie psychiczne. Pokonaj obniżony nastrój, odzyskaj radość życia i ciesz się każdym dniem!
      </Typography>
    </>
  }

  return <HealthPreventionSelfImprovement
    headerBox={headerBox}
    contentBox={contentBox}
    boxItems={mentalHealthSelfImprovementBoxItems}
  />
}

export default MentalHealthSelfImprovementLowMoodPage
