import {useEffect} from "react"
import {useParams} from "react-router"
import {useQuery} from "@apollo/client"

import {
  GET_DISEASE_BY_ID,
  GetDiseaseByIdResponse,
  GetDiseaseByIdVariables
} from "../../api/queries/getDiseaseById"
import {
  GET_USER_ACTIONS_BY_ID,
  GetUserActionsVariables,
  UserActionsResponse
} from "../../api/queries/getUserActionsByIds"

export const usePreventionDiseaseDetails = () => {
  const { diseaseId } = useParams<GetDiseaseByIdVariables>()
  const {
    data: diseaseResponse,
    loading: isDiseaseLoading,
    called: isDiseaseCalled,
    refetch: diseaseRefetch
  } = useQuery<GetDiseaseByIdResponse, GetDiseaseByIdVariables>(
    GET_DISEASE_BY_ID,
    {
      context: {clientName: "HealthPlanner"},
      variables: {
        diseaseId
      }
    }
  )
  const {
    data: userActionsResponse,
    loading: isUserActionsLoading,
    refetch: userActionRefetch

  } = useQuery<UserActionsResponse, GetUserActionsVariables>(
    GET_USER_ACTIONS_BY_ID,
    {
      context: {clientName: "HealthPlanner"},
      variables: {
        actionIds: diseaseResponse?.diseasesActions.map(
          diseasesAction => diseasesAction.action.id
        ) ?? []
      },
      skip: isDiseaseLoading && isDiseaseCalled
    }
  )

  useEffect(() => {
    diseaseRefetch()
      .then(() => {
        userActionRefetch()
      })
  }, [])

  return {
    disease: diseaseResponse?.disease,
    probability: diseaseResponse?.usersDiseaseProbability[0]?.probability,
    userActions: userActionsResponse?.userActions.filter(userAction => !userAction.completedAt),
    isUserDisease: !!diseaseResponse?.usersDisease[0]?.id,
    isLoading: isUserActionsLoading || isDiseaseLoading
  }
}
