import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box} from "@material-ui/core"

import useMakeQuery from "../../api/graphql/hooks/useMakeQuery"
import {useAppSelector} from "../../hooks/storeHooks"
import {selectClinicSettings} from "../../store/clinic/clinicSettings.selectors"
import {selectUserPozDeclarationActiveStatus} from "../../store/user/user.selectors"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import GenericStepForm from "./genericStepForm/GenericStepForm.component"

const ConsultationSurveyPage: FC = () => {
  const {
    getConsultationSurveyTemplate
  } = useMakeQuery()
  const clinicSettings = useAppSelector(selectClinicSettings)
  const consultationSurveyTemplateData = getConsultationSurveyTemplate("AA") //todo change code_name
  const isPozDeclarationActive = useAppSelector(selectUserPozDeclarationActiveStatus)
  const {t} =  useTranslation()
  const { enableHealthPlannerQuestionnaire } = clinicSettings.frontendSettings

  if (!isPozDeclarationActive || !enableHealthPlannerQuestionnaire) {
    return (
      <Box data-testid="general-error">
        {t("consultation:generalError")}
      </Box>)
  }

  if (consultationSurveyTemplateData.dataLoading) {
    return (
      <Box data-testid="surveyLoading">
        <LoaderBox />
      </Box>
    )
  }

  return (
    <GenericStepForm
      questionnaireTemplate={consultationSurveyTemplateData?.questionnaireTemplates?.[0]?.template}
      isLoading={false}
    />
  )
}

export default ConsultationSurveyPage
