import {gql} from "@apollo/client"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"

export type AddBloodPressureResponse = {
  addBloodPressure: {
    id: string;
  }
}

export type AddBloodPressureVariables = {
  diastolicPressure: string;
  systolicPressure: string;
  createdAt: MaterialUiPickersDate;
}

export const ADD_BLOOD_PRESSURE = gql`
  mutation AddBloodPressure(
    $diastolicPressure: numeric!,
    $systolicPressure: numeric!,
    $createdAt: timestamptz!
  ) {
    addBloodPressure: insert_examinations_blood_pressure_answers_one(
      object: {
        diastolic_pressure: $diastolicPressure,
        systolic_pressure: $systolicPressure,
        created_at: $createdAt
      }
    ) {
      id
    }
  }
`
