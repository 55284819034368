import React, {FC} from "react"
import {Typography} from "@material-ui/core"

import useConsultationLang from "../../../../hooks/useConsultationLang"
import AppDialog from "../../../common/appDialog/AppDialog.component"

interface ConsultationAdditionalMessageProps {
  open: boolean;
  onClose(): void;
  onConfirm(): void;
}

const ConsultationAdditionalMessageConfirmPopup: FC<ConsultationAdditionalMessageProps> = ({open, onClose, onConfirm}) => {
  const consultationLang = useConsultationLang()

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={consultationLang.getLabel("chatConsultation:oneLastQuestionConfirmTitle")}
      closeButtonText={consultationLang.getLabel("correct")}
      actionButtonProps={{
        onClick: onConfirm,
      }}
      actionButtonText={consultationLang.getLabel("send")}
    >
      <Typography className="break-spaces">
        {consultationLang.getLabel("chatConsultation:oneLastQuestionConfirmContent")}
      </Typography>
    </AppDialog>
  )
}

export default ConsultationAdditionalMessageConfirmPopup
