import { useSubscription } from "@apollo/client"

import { LastMessageForThreadResponseData, LastMessageForThreadVariables } from "./getLastMessageForThread.types"
import { SubscriptionHookOptions } from "@apollo/client/react/types/types"

import { GET_LAST_MESSAGE_FOR_THREAD } from "./getLastMessageForThread.query"

export const useGetLastMessageForThread = (options?: SubscriptionHookOptions<LastMessageForThreadResponseData, LastMessageForThreadVariables>) => {
  return useSubscription<LastMessageForThreadResponseData, LastMessageForThreadVariables>(
    GET_LAST_MESSAGE_FOR_THREAD,
    {
      context: { clientName: "HealthPlanner" },
      ...options
    }
  )
}