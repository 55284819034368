import React, { forwardRef, PropsWithChildren } from "react"
import {useSelector} from "react-redux"
import {ButtonBase, ButtonProps} from "@material-ui/core"
import clsx from "clsx"

import {selectClinicSettings} from "../../../store/clinic/clinicSettings.selectors"
import {useButtonTextUnderlinedStyles} from "./ButtonTextUnderLined.styles"

interface ButtonTextUnderlinedProps {
  color: ButtonTextUnderlinedColor;
}

type ButtonTextUnderlinedColor = "whiteOnBlue" | "primary" | "secondary";

const ButtonTextUnderlined = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonTextUnderlinedProps & Omit<ButtonProps, "color">>>(({
  children,
  classes,
  className,
  color,
  ...props
}, ref) => {

  const clinicSettings = useSelector(selectClinicSettings)
  const buttonClasses = useButtonTextUnderlinedStyles({customMainColor: clinicSettings?.frontendSettings?.style?.mainColor, customHoverColor: clinicSettings?.frontendSettings?.style?.hoverColor})

  return (
    <ButtonBase
      ref={ref}
      classes={{
        ...classes,
        root: buttonClasses[color]
      }}
      className={clsx(
        buttonClasses.root,
        className,
      )}
      {...props}
    >
      {children}
    </ButtonBase>
  )
})

ButtonTextUnderlined.displayName = "ButtonTextUnderlined"

export default ButtonTextUnderlined
