import {createTheme} from "@material-ui/core"

const theme = createTheme()

const MuiDivider = {
  root: {
    background: theme.palette.grey[100]
  },
}

export const divider = {
  MuiDivider,
}
