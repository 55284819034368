import { createTheme } from "@material-ui/core"

const theme = createTheme()

const MuiInputBase = {
  root: {
    borderRadius: theme.spacing(1),
  },
}

const MuiOutlinedInput = {
  root: {
    borderRadius: theme.spacing(1),
    "&$error": {

    },
  }
}

const MuiInputAdornment = {
  root: {
    "&.errorAdornment": {
      display: "none",
      ".Mui-error &": {
        display: "flex",
      },
    },
  },
}

export const input = {
  MuiInputBase,
  MuiOutlinedInput,
  MuiInputAdornment,
}
