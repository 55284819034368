import React, { FC } from "react"

import documentationLoader from "../../../../assets/animations/documentationLoader.json"
import LottieAnimation from "../LottieAnimation.component"

interface DocumentationLoaderProps {}

const DocumentationLoader: FC<DocumentationLoaderProps> = () => {
  return (
    <LottieAnimation
      animationData={documentationLoader}
    />
  )
}

export default DocumentationLoader