import React, {FC} from "react"
import {useFieldArray} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import {MedicalDocuments} from "../../documentation/Documentation.type"
import OrderMedicinesItem from "./orderMedicinesItem/OrderMedicinesItem.component"
import OrderMedicinesSummary from "./orderMedicinesSummary/OrderMedicinesSummary.component"
import {useCreateDozMedicinesMap} from "./OrderMedicinesList.utils"
import {DozOrder} from "../../../store/doz/doz.types"
import {useOrderMedicinesListStyles} from "./OrderMedicinesList.styles"

interface OrderMedicinesListProps {
  medicineEprescriptions: MedicalDocuments["medicine_eprescriptions"]
}

const OrderMedicinesList: FC<OrderMedicinesListProps> = ({ medicineEprescriptions }) => {
  const { fields, remove } = useFieldArray<DozOrder>({
    name: "requested_item_list"
  })
  const {t} = useTranslation()
  const classes = useOrderMedicinesListStyles()
  const dozMedicinesMap = useCreateDozMedicinesMap(medicineEprescriptions)
  
  return (
    <Box>
      <Typography
        className={classes.header}  
        variant="h3"
      >
        {t("orderMedicines:listOfAssignedMedicines")}
      </Typography>
      {
        fields.map((field, index) => (
          <OrderMedicinesItem
            index={index}
            key={field.id}
            ean={field.ean}
            onRemove={() => remove(index)}
            dozMedicinesMap={dozMedicinesMap}
          />
        ))
      }
      <OrderMedicinesSummary
        dozMedicinesMap={dozMedicinesMap}
      />
    </Box>
  )
}

export default OrderMedicinesList
