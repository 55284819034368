import React, {FC} from "react"
import {useTranslation} from "react-i18next"

import { useAppSelector } from "../../../../hooks/storeHooks"
import { useToggle } from "../../../../hooks/useToggle"
import { selectLoading } from "../../../../store/user/user.selectors"
import UserDataListItem from "../../common/UserDataListItem"
import UserDataInfoSection from "../../common/UserDataInfoSection.component"
import UserParentAddressDataEditModal from "./UserParentAddressDataEditModal/UserParentAddressDataEditModal.component"
import {UserDataType} from "../../../../store/user/user.types"

interface UserParentAddressDataProps {
  userParentData: UserDataType["parent"];
}

const UserParentAddressData: FC<UserParentAddressDataProps> = ({ userParentData }) => {
  const {t} = useTranslation()
  const loading = useAppSelector(selectLoading)
  const [isModalOpen, { handleOff: handleClose, handleOn: handleOpen }] = useToggle()

  const userParentStreetAddress = (() => {
    if (!userParentData) return "—"
    let streetAddress = `${userParentData?.address?.street || ""} ${userParentData?.address?.house_number || ""}`
    if (userParentData?.address?.apartment_number) {
      streetAddress += ` / ${userParentData?.address?.apartment_number}`
    }
    return streetAddress
  })()

  const userParentCityAddress = (() => {
    if (userParentData?.address?.postal_code && userParentData?.address?.city) {
      return `${userParentData?.address?.postal_code || ""}, ${userParentData?.address?.city || ""}`
    }
    return userParentData?.address?.city || "—"
  })()

  if (loading) {
    return null
  }

  return (
    <>
      <UserDataInfoSection
        title={t("user:parentAddressInfo")}
        withButton
        onClick={handleOpen}
        buttonText={t("edit")}
      >
        <UserDataListItem
          label={t("user:street")}
          description={userParentStreetAddress}
          descriptionProps={{noWrap: true}}
        />

        <UserDataListItem
          label={t("user:city")}
          description={userParentCityAddress}
          descriptionProps={{noWrap: true}}
        />

        <UserDataListItem
          label={t("user:country")}
          description={userParentData?.address?.country || "—"}
          descriptionProps={{noWrap: true}}
        />
      </UserDataInfoSection>

      <UserParentAddressDataEditModal
        userParentData={userParentData}
        open={isModalOpen}
        onClose={handleClose}
      />
    </>
  )
}

export default UserParentAddressData
