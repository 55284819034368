import React, {FC} from "react"
import {Box, Typography} from "@material-ui/core"
import WarningRoundedIcon from "@material-ui/icons/WarningRounded"
import clsx from "clsx"

import {useAlertStyles} from "./Alert.styles"

type AlertProps = {
  message: string;
  className?: string;
}

const Alert: FC<AlertProps> = ({ message, className }) => {
  const classes = useAlertStyles()

  return (
    <Box className={clsx(className ?? "", classes.container)}>
      <WarningRoundedIcon
        className={classes.icon}
        color="error"
      />
      <Typography
        className={classes.message}
        variant="body2"
      >
        {message}
      </Typography>
    </Box>
  )
}

export default Alert
