import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useSuccessfulPaymentPageStyles = makeStyles((theme: Theme) => createStyles({
  pageSection: {
    margin: "auto",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    padding: theme.spacing(3, 3, 2, 3),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      flexDirection: "row",
      padding: theme.spacing(8, 8, 6, 8),
    }
  },
  content: {
    flex: "1",
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flexWrap: "wrap",
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    }
  },
  button: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  imageWrapper: {
    maxHeight: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  image: {
    width: "100%",
    maxWidth: "190px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "230px",
    }
  },
  stationaryVisitPlace: {
    color: theme.palette.primary.main,
  },
  visitTypeContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(5),
    }
  },
  iconContainer: {
    marginRight: theme.spacing(2),
  }
}))
