import {makeStyles} from "@material-ui/core"

import {boxShadows} from "../../../themes/shadows"
import {createStyles, Theme} from "@material-ui/core/styles"

export const CONSULTATION_CONTAINER_PADDING_X_SPACING = 1.5
export const CONSULTATION_CONTAINER_PADDING_X_UP_SM_SPACING = 4

export const useLatestConsultationDetailsStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2.5, CONSULTATION_CONTAINER_PADDING_X_SPACING),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: boxShadows.shadow1,
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(CONSULTATION_CONTAINER_PADDING_X_UP_SM_SPACING),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(8),
    },
  },
  buttonSection: {
    display: "flex",
    justifyContent: "flex-end",
  },
  accordionRoot: {
    marginTop: "0 !important",
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    "&::before": {
      opacity: 0,
      top: 0,
    },
  },
  accordionSummaryRoot: {
    margin: 0,
    padding: 0,
    alignItems: "center",
    "&.MuiButtonBase-root:hover": {
      cursor: "default",
    }
  },
  accordionSummaryIcon: {
    transition: "transform .25s ease-in-out",
    "&:hover, &:focus": {
      transform: "translate3d(0, 6px, 0)"
    }
  },
  accordionDetailsRoot: {
    padding: 0,
  },
  controlVisit: {
    marginBottom: theme.spacing(2)
  }
}))
