import React, {Dispatch, FC} from "react"
import { useTranslation } from "react-i18next"
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery
} from "@material-ui/core"

import MobileTable from "../mobileTable/MobileTable.component"
import { LoginAccount } from "../../store/loginAccounts/loginAccounts.types"
import {Theme} from "@material-ui/core/styles"

type LoginAccountsTableProps =  {
  users: LoginAccount[];
  chooseAccount: Dispatch<LoginAccount>;
}

const LoginAccountsTable: FC<LoginAccountsTableProps> = ({ users, chooseAccount }) => {
  const {t} = useTranslation()
  const isXs = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"))

  const userNameSurname = (user:LoginAccount) => {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`
    }
    return user.firstName || user.lastName || "\u2014"
  }

  return isXs ? (
    <MobileTable
      title={t("login:accounts")}
      records={
        users.map(
          user => ({
            id: user.id,
            rows: [
              {
                name: t("login:nameAndSurname"),
                element: userNameSurname(user),
              },
              {
                name: t("login:identity"),
                element: user.identity || "\u2014"
              },
              {
                name: t("login:createdAt"),
                element: user.createdAt
              },
              {
                name: "",
                element: (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => chooseAccount(user)}
                  >
                    {t("login:choose")}
                  </Button>
                )
              }
            ]
          })
        )
      }
    />
  ) : (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow hover>
            <TableCell component="th"> {t("login:nameAndSurname")} </TableCell>
            <TableCell component="th"> {t("login:identity")} </TableCell>
            <TableCell component="th"> {t("login:createdAt")} </TableCell>
            <TableCell component="th"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow
              hover
              key={user.username}
            >
              <TableCell>{userNameSurname(user)}</TableCell>
              <TableCell>{user.identity || "\u2014"}</TableCell>
              <TableCell>{user.createdAt}</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => chooseAccount(user)}
                >
                  {t("login:choose")}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default LoginAccountsTable
