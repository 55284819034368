import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {useLazyQuery, useMutation} from "@apollo/client"
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date"

import {ADD_WEIGHT, AddWeightResponse, AddWeightVariables} from "../../api/mutations/addWeight"
import {ExaminationType} from "../../api/queries/getUserActionById"
import {GET_USER_WEIGHT_MEASUREMENTS, WeightMeasurementsData} from "../../api/queries/getUserWeightMeasurements"
import DiaryMeasurement from "../../components/diaryMeasurement/DiaryMeasurement.component"
import {addWeightSchema} from "../../components/diaryMeasurement/DiaryMeasurement.validation"
import PageWrapper from "../../components/pageWrapper/PageWrapper.component"

const genericInputData = [
  {
    name: "weight",
    unit: "kg"
  },
]

const DiaryWeight: FC = () => {
  const { t } = useTranslation()

  const [getWeightMeasurements, {
    data: { weightMeasurements = [] } = {},
    error: weightMeasurementsError,
    loading: weightMeasurementsLoading,
  }] = useLazyQuery<WeightMeasurementsData>(GET_USER_WEIGHT_MEASUREMENTS, {
    context: {clientName: "HealthPlanner"},
  })

  const [addWeight, {
    data: addWeightData, 
    loading: addWeightLoading, 
    error: addWeightError
  }] = useMutation<AddWeightResponse, AddWeightVariables>(ADD_WEIGHT, {
    context: { clientName: "HealthPlanner" } 
  })

  const addMeasurement = (value: MaterialUiPickersDate | null, input: string[]) => {
    addWeight({
      variables: {
        createdAt: value,
        value: input[0]
      }
    })
  }

  return (
    <PageWrapper
      title={t("healthPlanner:diaryTitles:weight")}
    >
      <DiaryMeasurement
        examinationType={ExaminationType.WEIGHT}
        diaryTitle={t("healthPlanner:diaryTitles:weight")}
        getRecords={getWeightMeasurements}
        data={weightMeasurements && weightMeasurements}
        dataLoading={weightMeasurementsLoading}
        dataFetchingError={weightMeasurementsError}
        addMeasurement={addMeasurement}
        addMeasurementData={addWeightData}
        addMeasurementLoading={addWeightLoading}
        addMeasurementError={addWeightError}
        genericInputData={genericInputData}
        inputTitle={t("healthPlanner:addWeight")}
        validationSchema={addWeightSchema}
      />
    </PageWrapper>
  )
}

export default DiaryWeight
