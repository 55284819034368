import React, { FC } from "react"

import healthPreventionSelfImprovementImg
  from "../../../../assets/images/healthPrevention/health-prevention-self-improvement-image.png"
import addictionsImg from "../../../../assets/images/healthPrevention/mentalHealth/addictions.png"
import anxietyImg from "../../../../assets/images/healthPrevention/mentalHealth/anxiety.png"
import crisesImg from "../../../../assets/images/healthPrevention/mentalHealth/crises.png"
import difficultiesInRelationshipsImg from "../../../../assets/images/healthPrevention/mentalHealth/difficulties-in-relationships.png"
import lowMoodImg from "../../../../assets/images/healthPrevention/mentalHealth/low-mood.png"
import workRelatedProblemsImg from "../../../../assets/images/healthPrevention/mentalHealth/work-related-problems.png"
import HealthPreventionSelfImprovement
, {
  HealthPreventionSelfImprovementHeaderBox,
  HealthPreventionSelfImprovementItem
} from "../../../../components/healthPrevention/selfImprovement/HealthPreventionSelfImprovement.component"
import ImageByIntegrationType from "../../../../components/imageByIntegrationType/ImageByIntegrationType"
import MentalHealthSelfImprovementAddictionsPage from "./Items/SelfImprovementAddictions.component"
import MentalHealthSelfImprovementAnxietyPage from "./Items/SelfImprovementAnxiety.component"
import MentalHealthSelfImprovementCrisesPage from "./Items/SelfImprovementCrises.component"
import MentalHealthSelfImprovementDifficultiesInRelationshipsPage
  from "./Items/SelfImprovementDifficultiesInRelationships.component"
import MentalHealthSelfImprovementWorkRelatedProblemsPage from "./Items/SelfImprovementWorkRelatedProblems.component"
import { RoutePath } from "../../../../routes/Routes.types"

import MentalHealthSelfImprovementLowMoodPage from "./Items/SelfImprovementLowMood"

const MentalHealthSelfImprovementPage: FC = () => {
  const translationKeyPrefix = "mentalHealth:selfImprovement"
  
  const getImage = (src: string, path: string) => {
    return <ImageByIntegrationType imageSrc={src} alt={path} imagePath={path}/>
  }

  const headerBox: HealthPreventionSelfImprovementHeaderBox = {
    title: `${translationKeyPrefix}:title`,
    content: `${translationKeyPrefix}:content`,
    redirectRoute: RoutePath.MENTAL_HEALTH,
    backBtnLabel: "goToHomepage",
    image: getImage(healthPreventionSelfImprovementImg, "health-prevention-self-improvement-image.png")
  }
  
  const items:HealthPreventionSelfImprovementItem[] = [
    {
      title: `${translationKeyPrefix}:items:lowMood:title`,
      content: `${translationKeyPrefix}:items:lowMood:content`,
      image: getImage(lowMoodImg, "loow-mod.png"),
      onClickPage: <MentalHealthSelfImprovementLowMoodPage/>
    },
    {
      title: `${translationKeyPrefix}:items:difficultiesInRelationships:title`,
      content: `${translationKeyPrefix}:items:difficultiesInRelationships:content`,
      image: getImage(difficultiesInRelationshipsImg, "difficulties-in-relationships.png"),
      onClickPage: <MentalHealthSelfImprovementDifficultiesInRelationshipsPage/>
    },
    {
      title: `${translationKeyPrefix}:items:workRelatedProblems:title`,
      content: `${translationKeyPrefix}:items:workRelatedProblems:content`,
      image: getImage(workRelatedProblemsImg, "work-related-problems.png"),
      onClickPage: <MentalHealthSelfImprovementWorkRelatedProblemsPage/>
    },
    {
      title: `${translationKeyPrefix}:items:crises:title`,
      content: `${translationKeyPrefix}:items:crises:content`,
      image: getImage(crisesImg, "crises.png"),
      onClickPage: <MentalHealthSelfImprovementCrisesPage/>
    },
    {
      title: `${translationKeyPrefix}:items:anxiety:title`,
      content: `${translationKeyPrefix}:items:anxiety:content`,
      image: getImage(anxietyImg, "anxiety.png"),
      onClickPage: <MentalHealthSelfImprovementAnxietyPage/>
    },
    {
      title: `${translationKeyPrefix}:items:addictions:title`,
      content: `${translationKeyPrefix}:items:addictions:content`,
      image: getImage(addictionsImg, "addictions.png"),
      onClickPage: <MentalHealthSelfImprovementAddictionsPage/>
    },
  ]
  
  return <HealthPreventionSelfImprovement 
    headerBox={headerBox}
    items={items}
  />
}

export default MentalHealthSelfImprovementPage
