import {postCalculateServicePrices} from "../../api/routes"
import {v4Service} from "../store.service"
import {CalculateServicePricesPayload, CalculateServicePricesResponse} from "./vouchers.types"

const vouchersApi = v4Service.injectEndpoints({
  endpoints: build => ({
    calculateServicePrices: build.query<CalculateServicePricesResponse, CalculateServicePricesPayload>({
      query: (payload) => ({
        ...postCalculateServicePrices,
        data: {
          ...payload,
          clinic_codes: [],
        }
      }),
    }),
  })
})

export const { useCalculateServicePricesQuery } = vouchersApi
