import React, {FC} from "react"
import {Box, Typography} from "@material-ui/core"

import LangFlag from "../../../common/langFlag/LangFlag.component"
import TextLabel from "../../../common/textLabel/TextLabel.component"
import {getLangFlagImgTitle} from "../../../common/langFlag/LangFlag.utils"
import {ConsultationType} from "../../../consultation/Consultation.types"
import {useCommonStyles} from "../common/Common.styles"

type ClassKey = "textWhite"

interface DetailsSectionProps {
  labelText?: string;
  title: string;
  consultationType?: ConsultationType;
  classes?: Record<ClassKey, string>;
  consultationLanguage: string | undefined;
  detailsTitle?: string | null;
  detailsContent?: string | null;
}

const DetailsSection: FC<DetailsSectionProps> = (
  {
    labelText, 
    title, 
    classes, 
    consultationType, 
    consultationLanguage,
    detailsTitle,
    detailsContent,
  }) => {
  const commonStyles = useCommonStyles()
  const shouldShowDetails = detailsTitle && detailsContent && consultationType === ConsultationType.SICK_LEAVE

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      height="100%"
      width="100%"
      ml={2}
    >
      {labelText && (
        <TextLabel
          text={labelText}
        />
      )}
      <Box mt={consultationType ? 1 : 0}>
        <Typography
          variant="h5"
          component="p"
          className={classes?.textWhite}
        >
          {title}
        </Typography>
        {
          (consultationLanguage && !shouldShowDetails && consultationType !== ConsultationType.PRESCRIPTION_ONLY) && (
            <LangFlag
              language={consultationLanguage}
              title={getLangFlagImgTitle(consultationLanguage)}
              label={getLangFlagImgTitle(consultationLanguage)}
              className={commonStyles.langImg}
            />
          )
        }
      </Box>
      {
        shouldShowDetails && (
          <>
            <Typography variant="h5">
              {detailsTitle}
            </Typography>
            <Typography>
              {detailsContent}
            </Typography>
          </>
        )
      }
    </Box>
  )
}

export default DetailsSection
