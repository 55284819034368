import React, {FC, useState} from "react"
import {Controller, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router"
import {Box, Button, TextField, Typography} from "@material-ui/core"
import * as Sentry from "@sentry/react"
import {Severity} from "@sentry/react"
import axios, {AxiosError} from "axios"
import i18next from "i18next"

import api from "../../api/api"
import {postSmsPasswordCodeConfig} from "../../api/routes"
import {ApiError} from "../../enums/ApiErrorCode"
import {SearchParams} from "../../enums/SearchParams"
import useGetFormValidationRules from "../../hooks/useGetFormValidationRules"
import { analytics, LogEventType } from "../../services/analytics"
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader.component"
import PageSection from "../common/pageSection/PageSection.component"
import GlobalFormErrorMessage from "../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import {SessionState} from "../../store/session/session.types"
import {SendAccessCodeSubmitData} from "./ForgotPassword.types"
import {useForgotPasswordStyles} from "./ForgotPassword.styles"

import ForgotPasswordTitleNode from "./common/ForgotPasswordTitleNode"
import {sendSmsCodeValidationSchema} from "./ForgotPassword.validation"

interface SendSmsCodeFormProps {
  userId: string;
  setSession: (session: SessionState) => void;
}

const SendSmsCodeForm: FC<SendSmsCodeFormProps> = ({userId, setSession}) => {
  const { t } = useTranslation()
  const {push} = useHistory()
  const classes = useForgotPasswordStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const [globalError, setGlobalError] = useState<AxiosError | null>(null)
  const globalErrorMessage = globalError?.response?.data.detail
    ? t(`errors:${globalError?.response?.data.detail}`)
    : t("errors:unknownError")

  const form = useForm<SendAccessCodeSubmitData>({
    mode: "all",
    resolver: useGetFormValidationRules(sendSmsCodeValidationSchema),
    defaultValues: {
      accessCode: ""
    }
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    setLoading(true)

    try {
      const {data} = await api.request({
        ...postSmsPasswordCodeConfig(userId),
        data: {
          access_code: values.accessCode
        }
      })

      setSession(data)
      analytics.sendEventWithDefaultParams(LogEventType.PATIENTS_ACCESS_CODE_SUCCESS)
    } catch (e) {
      Sentry.captureException(e, { level: Severity.Debug })
      console.error(e)
      if (e.response?.data?.detail) {
        setGlobalError(e)
      }
      const errorLog = {
        "error_code": e.response.status,
        "error_name": e.response?.data?.detail,
        "login_name": userId,
      }
      analytics.sendEventWithDefaultParams(LogEventType.PATIENTS_ACCESS_CODE_ERROR, errorLog)

      if (axios.isAxiosError(e) && e.response?.data.errorType === ApiError.SIGNAL_IDUNA_FORGOT_PASSWORD_ACCESS_TOKEN_EXPIRED) {
        push(`/${i18next.language}/login?${SearchParams.V4_ERROR_TYPE}=${ApiError.SIGNAL_IDUNA_FORGOT_PASSWORD_ACCESS_TOKEN_EXPIRED}`)
      }
    }

    setLoading(false)
  })

  return (
    <PageSection titleNode={
      <ForgotPasswordTitleNode title="enterCode"/>
    }>
      <Box className={classes.sectionBox}>
        <Box mb={1}>
          <Typography variant={"body2"}>
            {t("forgotPassword:enterCodeDescription")}
          </Typography>
        </Box>

        {globalError && (
          <GlobalFormErrorMessage
            message={globalErrorMessage}
          />
        )}

        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <Controller
            name="accessCode"
            control={form.control}
            render={({
              field: {onChange, value},
              fieldState: {error, invalid}
            }) => (
              <TextField
                id="accessCode"
                label={t("forgotPassword:smsCode")}
                placeholder={t("forgotPassword:smsCode")}
                fullWidth
                disabled={loading}
                value={value}
                onChange={onChange}
                error={invalid}
                helperText={error?.message}
                required={true}
                autoFocus
              />
            )}
          />

          <Box className={classes.buttonsWrapper}>
            <Button
              disabled={loading}
              variant="outlined"
              color="primary"
              type="button"
              className={classes.button}
              onClick={() => push(`/${i18next.language}/login`)}
            >
              {t("forgotPassword:backToLoginPage")}
            </Button>

            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              startIcon={loading && <ButtonLoader position="prefix"/>}
            >
              {t("send")}
            </Button>
          </Box>
        </form>
      </Box>
    </PageSection>
  )
}

export default SendSmsCodeForm
