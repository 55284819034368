import React, {ChangeEvent, FC} from "react"
import {useTranslation} from "react-i18next"
import i18next from "i18next"

import CheckboxController from "../checkboxController/CheckboxController.component"
import {Gender} from "../../../store/user/user.types"
import {CheckboxControllerOption} from "../checkboxController/CheckboxController.types"

interface SexCheckboxProps {
  name?: string;
  disabled?: boolean;
}

const SexCheckbox: FC<SexCheckboxProps> = ({name = "gender", disabled}) => {
  const {t} = useTranslation()
  const sexCheckboxOptions: CheckboxControllerOption[] = [
    {
      value: Gender["female"],
      label: i18next.t("user:female")
    },
    {
      value: Gender["male"],
      label: i18next.t("user:male")
    }
  ]

  const handleGenderChange = (onChange: (value: Gender) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.target.value))
  }

  return (
    <CheckboxController
      name={name}
      label={t("user:gender")}
      disabled={disabled}
      options={sexCheckboxOptions}
      handleChange={handleGenderChange}
    />
  )
}

export default SexCheckbox
