import {boxShadows} from "../../../themes/shadows"
import {CHAT_ITEM_MAX_WIDTH} from "../ConsultationChat.styles"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const MINIFY_IMAGE_HEIGHT = 180

export const useConsultationChatMessageItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    welcomeMessageContainer: {
      whiteSpace: "pre-line",
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(5),
      },
    },
    messageItemBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(5),
      },
    },
    widthConditions: {
      maxWidth: "100%",
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up("md")]: {
        maxWidth: CHAT_ITEM_MAX_WIDTH,
        padding: theme.spacing(0),
      },
    },
    container: {
      [theme.breakpoints.up("lg")]: {
        maxWidth: CHAT_ITEM_MAX_WIDTH,
      }
    },
    patientDetailsMessage: {
      textAlign: "left"
    },
    cloudMessageWrapper: {
      flex: "1",
      background: theme.palette.background.paper,
      height: "100%",
      padding: theme.spacing(3, 3.5),
      borderRadius: theme.shape.borderRadius,
      boxShadow: boxShadows.shadow1,
      wordBreak: "break-word",
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(4, 5),
        width: "unset",
      },
    },
    patientMessageSending: {
      opacity: "0.5",
    },
    cloudPatientMessageWrapper: {
      background: theme.palette.info.lighter,
    },
    cloudConsultationReasonMessage: {
      border: `2px solid ${theme.palette.primary.main}`,
      textAlign: "left",
    },
    centerBoxMessage: {
      justifyContent: "center",
    },
    leftAlignBoxMessage: {
      justifyContent: "flex-start",
    },
    rightAlignBoxMessage: {
      justifyContent: "flex-end",
      marginLeft: "auto",
      textAlign: "right",
    },
    downloadItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "auto",
      maxWidth: "100%",
      height: theme.spacing(7),
      background: theme.palette.secondary.dark,
      borderRadius: "50px",
      color: theme.palette.common.white,
      padding: theme.spacing(0, 4),
      [theme.breakpoints.up("md")]: {
        minWidth: "440px",
      },
    },
    downloadButton: {
      color: theme.palette.common.white,
      textDecoration: "underline",
      "&:hover, &:focus, &:disabled": {
        textDecoration: "underline",
        color: theme.palette.common.white,
      }
    },
    downloadImageItem: {
      display: "flex",
    },
    downloadImageButton: {
      color: theme.palette.primary.dark,
      textDecoration: "underline",
      "&:hover, &:focus, &:disabled": {
        textDecoration: "underline",
        background: "none",
        color: theme.palette.primary.darker,
      }
    },
    patientDownloadImageButton: {
      right: theme.spacing(-1),
    },
    doctorDownloadImageButton: {
      left: theme.spacing(-1.5),
    },
    fileNameData: {
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(2),
    },
    fileIcon: {
      ...theme.typography.h3,
      marginRight: theme.spacing(0.5),
    },
    imageItem: {
      display: "flex",
      flexDirection: "column",
    },
    imageWrapper: {
      height: `${MINIFY_IMAGE_HEIGHT}px`,
      display: "flex",
      alignItems: "center",
    },
    image: {
      maxHeight: `${MINIFY_IMAGE_HEIGHT}px`,
      marginBottom: theme.spacing(1),
    },
    messageTime: {
      color: theme.palette.grey["200"],
    },
    doctorCloudMessageWrapper: {
      position: "relative",
      paddingRight: theme.spacing(5),
      marginRight: theme.spacing(5),
      [theme.breakpoints.up("md")]: {
        paddingRight: theme.spacing(9),
        marginRight: theme.spacing(9),
      }
    },
    doctorImage: {
      position: "absolute",
      width: theme.spacing(8),
      height: theme.spacing(8),
      top: theme.spacing(-1),
      right: theme.spacing(-4),
      [theme.breakpoints.up("md")]: {
        right: theme.spacing(-6.5),
        width: theme.spacing(13),
        height: theme.spacing(13),
      }
    },
  })
)
