import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Typography } from "@material-ui/core"

import Loader from "../../../../../components/loader/Loader.component"
import ActionListTabPanel from "../actionListTabPanel/ActionListTabPanel.component"
import EmptyActionListTabPanel from "../emptyActionListTabPanel/emptyActionListTabPanel.component"

export interface PanelData {
  isEmpty: boolean;
  renderContent: React.ReactNode;
  emptyMessageLabel: string;
  showImageOnEmptyList: boolean;
}

interface DynamicActionListPanelsProps {
  value: number
  panelsData: PanelData[]
  loading: boolean
  error: boolean
}

const DynamicActionListPanels: FC<DynamicActionListPanelsProps> = ({
  value,
  panelsData, 
  loading, 
  error 
}) => {
  const { t } = useTranslation()
    
  return (
    <>
      {panelsData.map((panelData, index: number) => (
        <ActionListTabPanel value={value} index={index} key={index}>
          {loading && <Loader />}
          {error && <Typography variant="body1">{t("errors:defaultError")}</Typography>}
          {!panelData.isEmpty && !loading && panelData.renderContent}
          {panelData.isEmpty && !loading && (
            <EmptyActionListTabPanel showImage={panelData.showImageOnEmptyList} label={t(panelData.emptyMessageLabel)} />
          )}
        </ActionListTabPanel>
      ))}
    </>
  )
}

export default DynamicActionListPanels
