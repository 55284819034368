import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useMessengerChatStyles = makeStyles((theme: Theme) =>
  createStyles({
    chat: {
      fontSize: theme.spacing(2),
      lineHeight: `${theme.spacing(3)}px`,
    },
  })
)
