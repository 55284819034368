import i18next from "i18next"
import * as yup from "yup"

interface SendSmsCodeValidationSchema {
  code: string;
}

export const sendSmsCodeValidationSchema = (): yup.SchemaOf<SendSmsCodeValidationSchema> => yup.object().shape({
  code: yup.string().required(i18next.t("errors:required")),
})
