import {gql} from "@apollo/client"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"

export type AddGlycemiaResponse = {
  addGlycemia: {
    id: string;
  }
}

export type AddGlycemiaVariables = {
  glycemia: string;
  createdAt: MaterialUiPickersDate;
}

export const ADD_GLYCEMIA = gql`
  mutation AddGlycemia(
    $glycemia: Int!,
    $createdAt: timestamptz!
  ) {
    addGlycemia: insert_examinations_glycemia_answers_one(object: {
      glucose: $glycemia,
      created_at: $createdAt
    }
  ) {
      id
    }
  }
`
