import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import {Typography, TypographyProps} from "@material-ui/core"

import {getComingConsultationTypeText, getConsultationTypeShortText} from "../Consultation.utils"
import {ConsultationType} from "../Consultation.types"

interface ConsultationTypeTextProps extends TypographyProps {
  consultationType: ConsultationType;
  className?: string;
  comingConsultation?: boolean;
  shortName?: boolean;
  isStationaryVisit?: boolean;
}

const ConsultationTypeText: FC<ConsultationTypeTextProps> = ({
  consultationType,
  className,
  comingConsultation,
  isStationaryVisit,
  ...props
}) => {
  const { t } = useTranslation()

  const consultationTypeTextName: string = comingConsultation
    ? getComingConsultationTypeText(consultationType, isStationaryVisit)
    : getConsultationTypeShortText(consultationType, isStationaryVisit)

  return (
    <Typography
      variant="h5"
      color="textSecondary"
      className={className}
      {...props}
    >
      { t(`consultation:${consultationTypeTextName}`) }
    </Typography>
  )
}

export default ConsultationTypeText
