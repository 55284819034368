import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import DoctorImage from "../../../../assets/images/doctor_2.png"
import { DiariesResponseData,GET_DIARIES } from "../../api/queries/getDiariesQuery"
import Loader from "../../../../components/loader/Loader.component"
import DiaryButton from "./diaryButton/DiaryButton.component"
import { RoutePath } from "../../../../routes/Routes.types"
import { useDiaryMeasurementTileStyles } from "./DiaryMeasurementTile.styles"

interface DiaryMeasurementTileProps {}

const DiaryMeasurementTile: FC<DiaryMeasurementTileProps> = () => {
  const { t, i18n } = useTranslation()
  const classes = useDiaryMeasurementTileStyles()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))

  const {
    data: { diaries } = {},
    error: error,
    loading: loading,
  } = useQuery<DiariesResponseData>(GET_DIARIES, {
    context: {clientName: "HealthPlanner"},
  })

  const diariesList = diaries?.map( diary => {
    return (
      <DiaryButton
        key={diary.name} 
        title={diary.title} 
        imageUrl={diary.imageUrl}
        imagePath={null}
        path={`/${i18n.language}${RoutePath.PREVENTION_DIARY.replace(":name", diary.name)}`}
      />
    )
  })

  return (
    <Box
      pt={3}
      pb={2}
      className={classes.boxCard}
    >
      <Typography
        variant={isMdUp ? "h3" : "h4"}
        component="h3"
        className={classes.title}
      >
        {isSmUp ? t("diaryMeasurement:title") : t("prevention")}
      </Typography>

      <Box className={classes.tilesWrapper}>
        {loading && <Loader mx="auto" />}
        {error && <Typography variant="body1">{t("errors:defaultError")}</Typography>}
        {diariesList && !loading && (
          <>
            {!isLgUp && (
              <DiaryButton
                title={t("diseaseManagement:title")}
                imageUrl={DoctorImage}
                path={`/${i18n.language}${RoutePath.PREVENTION_DISEASES}`}
                imagePath={"doctor_2.png"}
              />
            )}
            {diariesList}
          </>
        )}
      </Box>
    </Box>
  )
}

export default DiaryMeasurementTile