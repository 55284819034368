import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useLoginMagicLinkPageStyles = makeStyles(() => createStyles({
  root: {
    minHeight: "100vh",
    display: "flex",
  }
}))
