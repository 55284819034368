import {Publisher, Session} from "@opentok/client"

const disconnectOpenTok = (
  setSession: (value: Session | undefined) => void,
  setPublisher: (value: Publisher | undefined) => void,
  session: Session | undefined,
  publisher: Publisher | undefined,
): void => {
  if (session) {
    if (publisher) {
      const stream = publisher.stream

      if (stream) {
        const subscribers = session.getSubscribersForStream(stream)
        subscribers.forEach(subscirber => {
          session.unsubscribe(subscirber)
        })
      }
      setPublisher(undefined)
    }

    session.disconnect()
    setSession(undefined)
  }
}

export {
  disconnectOpenTok
}