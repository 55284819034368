import React, { FC, ReactNode } from "react"
import { Box } from "@material-ui/core"

interface DiaryMeasurementTabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  className?: string;
}

const DiaryMeasurementTabPanel: FC<DiaryMeasurementTabPanelProps> = (props) => {
  const { children, value, index, className, ...other } = props
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className={className}
      {...other}
    >
      {value === index && (
        <Box>{children}</Box>
      )}
    </div>
  )
    
}

export default DiaryMeasurementTabPanel