import React, {FC, useState} from "react"
import { FormProvider,useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { DialogContentText } from "@material-ui/core"

import api from "../../../../../api/api"
import { updateUserParentPersonalDataConfig } from "../../../../../api/routes"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks"
import useGetFormValidationRules from "../../../../../hooks/useGetFormValidationRules"
import { selectUserId } from "../../../../../store/session/session.selectors"
import { getUserData } from "../../../../../store/user/user.slice"
import { userParentEditPersonalDataSchema } from "../../userPersonalData/UserPersonalDataEditModal/UserPersonalDataEditModal.validation"
import AppDialog from "../../../../common/appDialog/AppDialog.component"
import ButtonLoader from "../../../../common/buttonLoader/ButtonLoader.component"
import UserParentPersonalDataEditForm from "../UserParentPersonalDataEditForm/UserParentPersonalDataEditForm.component"
import { getGenderFromPesel } from "../../../../../utils/getGenderFromPesel"
import { handleReactHookFormErrors, redirectToError500Page } from "../../../../../utils/handleErrors"
import { getDocumentTypesForCountry, setProperDefaultDocumentType,setProperDocumentType } from "../../userPersonalData/UserPersonalData.utils"
import {Gender, UserDataType, UserDocumentData, UserDocumentType} from "../../../../../store/user/user.types"
import { CountryOption } from "../../../../commonFormItems/countrySelect/Country.types"
import { TimeZoneOption } from "../../../../commonFormItems/timeZoneSelect/TimeZoneSelect.types"

interface PersonalDataValues {
  parentFirstName?: string | null;
  parentLastName?: string | null;
  parentNationality?: CountryOption["value"] | null;
  parentPesel?: string | null;
  parentIdentityNumber?: string | null;
  parentDocumentType?: UserDocumentData["value"] | null;
  parentBirthDate?: string;
  parentGender?: Gender | null;
  parentDefaultTimezone?: TimeZoneOption | null;
}

interface UserParentPersonalDataEditModalProps {
  userParentData: UserDataType["parent"];
  userParentDocumentData: UserDocumentData;
  open: boolean;
  onClose(): void;
}

const UserParentPersonalDataEditModal: FC<UserParentPersonalDataEditModalProps> = ({
  userParentData,
  userParentDocumentData,
  open,
  onClose,
}) => {
  const {t} = useTranslation()
  const userId = useAppSelector(selectUserId)
  const dispatch = useAppDispatch()
  const userParentHasNoPesel = userParentData?.identity_document_type !== null
  const [loading, setLoading] = useState<boolean>(false)

  let defaultValues: PersonalDataValues = {
    parentFirstName: userParentData?.first_name ?? "",
    parentLastName: userParentData?.last_name ?? "",
    parentNationality: userParentData?.nationality ?? "",
    parentDocumentType: setProperDefaultDocumentType(userParentData?.nationality, userParentData?.identity_document_type) 
      ?? setProperDefaultDocumentType(userParentData?.nationality, getDocumentTypesForCountry(userParentData?.nationality)[0].value)
      ?? "",
    parentIdentityNumber: userParentData?.identity_number ?? "",
    parentBirthDate: userParentData?.birth_date ?? "",
    parentGender: userParentData?.gender ?? null,
    parentDefaultTimezone: userParentData?.default_timezone ?? null
  }

  defaultValues =
    userParentHasNoPesel ? {
      ...defaultValues,
      parentIdentityNumber: userParentDocumentData?.value || ""
    } : {
      ...defaultValues,
      parentPesel: userParentDocumentData?.value || "",
    }

  const form = useForm<PersonalDataValues>({
    defaultValues,
    mode: "all",
    resolver: useGetFormValidationRules(() => userParentEditPersonalDataSchema(userParentData?.pesel ?? null)),
  })

  const handleClose = () => {
    setLoading(false)
    onClose()
    form.reset()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    const parentPersonalData = {
      firstName: userParentData?.first_name
        ? undefined
        : (values.parentFirstName || undefined),
      lastName: userParentData?.last_name
        ? undefined
        : (values.parentLastName || undefined),
      identityDocumentType: (userParentData?.pesel || (userParentData?.identity_document_type && userParentData?.identity_number) || values.parentDocumentType === UserDocumentType.PESEL || (!values.parentIdentityNumber && values.parentDocumentType !== UserDocumentType.PESEL) || !values.parentDocumentType)
        ? undefined
        : (setProperDocumentType(values.parentDocumentType) || undefined),
      pesel: (userParentData?.pesel || userParentData?.identity_document_type || values.parentDocumentType !== UserDocumentType.PESEL)
        ? undefined
        : (values.parentPesel || undefined),
      identityNumber: (userParentData?.pesel || userParentData?.identity_document_type || values.parentDocumentType === UserDocumentType.PESEL)
        ? undefined
        : (values.parentIdentityNumber || undefined),
      identityDocumentCountryIssuer: (userParentData?.pesel || userParentData?.identity_document_type || values.parentDocumentType === UserDocumentType.PESEL || !values.parentIdentityNumber)
        ? undefined
        : values.parentNationality,
      birthDate: (userParentData?.pesel || userParentData?.identity_document_type || values.parentDocumentType === UserDocumentType.PESEL || userParentData?.birth_date || !values.parentBirthDate)
        ? undefined
        : new Date(values.parentBirthDate),
      gender: (values.parentPesel && values.parentDocumentType === UserDocumentType.PESEL)
        ? (!userParentData?.pesel || values.parentPesel) ? (getGenderFromPesel(values.parentPesel) ?? undefined) : undefined
        : (!userParentData?.pesel && userParentData?.gender === null) ? (values.parentGender ?? undefined) : undefined,
      nationality: userParentData?.nationality === values.parentNationality
        ? undefined
        : (values.parentNationality || undefined),
      defaultTimezone: userParentData?.default_timezone === values.parentDefaultTimezone
        ? undefined
        : (values.parentDefaultTimezone || undefined)
    }

    const shouldUpdateParentData = Object.values(parentPersonalData).find(val => !!val) || userParentData.gender === null

    setLoading(true)
    try {
      if(shouldUpdateParentData) {
        await api.request({
          ...updateUserParentPersonalDataConfig,
          data: parentPersonalData,
        }) 
      }
      await dispatch(getUserData({id: userId}))
      handleClose()
    } catch (e) {
      console.error(e)
      redirectToError500Page(e)
      if (e.response?.data?.errors) {
        handleReactHookFormErrors<PersonalDataValues>(e.response.data.errors, form)
      }
    }
    setLoading(false)
  })

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t("user:parentPersonalInfo")}
      disabled={loading}
      actionButtonText={t("save")}
      actionButtonProps={{
        onClick: handleSubmit,
        startIcon: loading && <ButtonLoader position="prefix"/>,
      }}
    >
      <FormProvider {...form} >
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <DialogContentText>
            {t("user:editParentData")}
          </DialogContentText>

          <UserParentPersonalDataEditForm
            userParentData={userParentData}
            userParentDocumentData={userParentDocumentData}
            loading={loading}
          />
        </form>
      </FormProvider>
    </AppDialog>
  )
}

export default UserParentPersonalDataEditModal
