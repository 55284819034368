import {createStyles, makeStyles} from "@material-ui/core"

export const useActionButtonsStyles = makeStyles(theme => createStyles({
  container: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(4, 0),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4, 2),
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    }
  },
  button: {
    width: "100%",
    maxWidth: 250,
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "unset",
      width: `calc(100% - ${theme.spacing(6)}px)`,
    }
  },
  marginRight: {
    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(0)
    }
  },
  marginBottom: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2)
    }
  },
  hidden: {
    visibility: "hidden",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    }
  }
}))