import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Button} from "@material-ui/core"
import clsx from "clsx"

import wavyCheck from "../../../../../../../assets/svg/circleWavyCheck.svg"
import warning from "../../../../../../../assets/svg/warning.svg"
import ButtonLoader from "../../../../../../../components/common/buttonLoader/ButtonLoader.component"
import ImageByIntegrationType from "../../../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { useRedirectToSummaryActionView } from "../../PreventionActionCard.utils"
import {useConfirmationButtonsStyles} from "./ConfirmationButtons.styles"

type ConfirmationButtonsProps = {
  isTesticlesExamination: boolean;
  onClickAbnormalityButton: VoidFunction;
}

const ConfirmationButtons: FC<ConfirmationButtonsProps> = ({
  isTesticlesExamination,
  onClickAbnormalityButton
}) => {
  const classes = useConfirmationButtonsStyles()
  const { handleFinish, isLoading } = useRedirectToSummaryActionView()
  const {t} = useTranslation()

  return (
    <Box className={classes.wrapper}>
      <Button
        className={clsx(classes.marginButton, classes.button)}
        color="primary"
        variant="contained"
        onClick={handleFinish}
        disabled={isLoading}
        startIcon={
          isLoading
            ? <ButtonLoader position="suffix" />
            : <ImageByIntegrationType
              imageSrc={wavyCheck}
              alt="wavyCheck"
              imagePath={"circleWavyCheck.svg"}
            />
        }
      >
        {t("healthPlanner:examinationConfirm")}
      </Button>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={onClickAbnormalityButton}
        startIcon={
          <ImageByIntegrationType
            imageSrc={warning}
            alt="warning"
            imagePath={"warning.svg"}
          />
        }
      >
        {t(
          isTesticlesExamination
            ? "healthPlanner:maleAbnormality"
            : "healthPlanner:femaleAbnormality"
        )}
      </Button>
    </Box>
  )
}

export default ConfirmationButtons
