import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import { useTheme } from "@material-ui/core"

import { useAppSelector } from "../../../../hooks/storeHooks"
import { useToggle } from "../../../../hooks/useToggle"
import { selectLoading } from "../../../../store/user/user.selectors"
import UserDataListItem from "../../common/UserDataListItem"
import LoaderBox from "../../../common/loaderBox/LoaderBox.component"
import UserDataInfoSection from "../../common/UserDataInfoSection.component"
import UserPersonalDataEditModal from "./UserPersonalDataEditModal/UserPersonalDataEditModal.component"
import { ALLIANZ_EWKA_INTEGRATION_TYPE, ALLIANZ_ONYX_INTEGRATION_TYPE } from "../../../../utils/getIntegrationType"
import { getUserDocumentData } from "./UserPersonalData.utils"
import { Gender, UserDataType } from "../../../../store/user/user.types"

interface UserPersonalDataProps {
  user: UserDataType;
  userParentData: UserDataType["parent"];
  underageUserWithRegisteredParent: boolean;
  patientEditAllowed: boolean;
  integrationType?: number|null
}

const UserPersonalData: FC<UserPersonalDataProps> = ({
  user,
  userParentData,
  underageUserWithRegisteredParent,
  patientEditAllowed,
  integrationType
}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const loading = useAppSelector(selectLoading)
  const userHiddenPesel = user.pesel?.replace(/^\d{7}/, "*") || ""
  const userParentHiddenPesel = userParentData?.pesel?.replace(/^\d{7}/, "*") || ""
  const translationKeyPrefix = integrationType === ALLIANZ_ONYX_INTEGRATION_TYPE || integrationType === ALLIANZ_EWKA_INTEGRATION_TYPE ? "user:onyx" : "user"
  const userDocumentData = getUserDocumentData(user, userHiddenPesel, translationKeyPrefix)
  const userParentDocumentData = getUserDocumentData(userParentData, userParentHiddenPesel)
  const userGender = user.gender !== null ? t(`user:${Gender[user.gender]}`) : ""
  const [isModalOpen, { handleOff: handleClose, handleOn: handleOpen }] = useToggle()

  if (loading) {
    return (
      <UserDataInfoSection title={t("user:personalInfo")}>
        <LoaderBox size={theme.spacing(8)} thickness={2}/>
      </UserDataInfoSection>
    )
  }

  return (
    <>
      <UserDataInfoSection
        title={underageUserWithRegisteredParent ? t("user:personalInfoUnderage") : t("user:personalInfo")}
        withButton={patientEditAllowed}
        onClick={handleOpen}
        buttonText={t("edit")}
      >
        <UserDataListItem
          label={t("user:name")}
          description={user.first_name || ""}
          descriptionProps={{className: "break-word"}}
        />

        <UserDataListItem
          label={t("user:surname")}
          description={user.last_name || ""}
          descriptionProps={{className: "break-word"}}
        />

        <UserDataListItem
          label={t("user:nationality")}
          description={user.nationality || ""}
          descriptionProps={{noWrap: true}}
        />

        <UserDataListItem
          label={userDocumentData.label}
          description={userDocumentData.value}
          descriptionProps={{noWrap: true}}
        />

        <UserDataListItem
          label={t("user:gender")}
          description={userGender}
          descriptionProps={{noWrap: true}}
        />

        <UserDataListItem
          label={t(`${translationKeyPrefix}:defaultTimezone`)}
          description={user.default_timezone || ""}
          descriptionProps={{noWrap: true}}
        />

      </UserDataInfoSection>

      <UserPersonalDataEditModal
        open={isModalOpen}
        onClose={handleClose}
        user={user}
        userDocumentData={{
          label: userDocumentData.label,
          value: userDocumentData.value,
        }}
        userParentData={userParentData}
        userParentDocumentData={{
          label: userParentDocumentData.label,
          value: userParentDocumentData.value,
        }}
      />
    </>
  )
}

export default UserPersonalData
