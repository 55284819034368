import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Box, Button, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import visitConfirmed from "../../../assets/images/visit-confirmed.png"
import { useQueryParam } from "../../../hooks/useSearchParams"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import { voucherPaymentPageDescriptionTranslationText } from "./BuyVoucherPaymentPage.utils"

import { buyVoucherPaymentPageStyle } from "./BuyVoucherPaymentPage.style"

interface BuyVoucherSuccessPaymentPageParams {}

const BuyVoucherSuccessPaymentPage: FC<BuyVoucherSuccessPaymentPageParams> = () => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const classes = buyVoucherPaymentPageStyle()
  const { push } = useHistory()
  const voucherCode = useQueryParam("voucherCode")

  const goToHomepage = () => {
    push(`/${i18n.language}`)
  }

  if (!voucherCode) {
    goToHomepage()

    return null
  }

  return (
    <Box className={classes.pageSection} component="section">
      <Box className={classes.content}>
        <Box>
          <Typography className={classes.title}>
            { voucherPaymentPageDescriptionTranslationText("title1", voucherCode, "success") }
            <br></br>
            <span className={classes.spanText}>
              { voucherPaymentPageDescriptionTranslationText("title2", voucherCode, "success") }
            </span>
          </Typography>
        </Box>
        <Typography className={classes.thankYouText}>
          { voucherPaymentPageDescriptionTranslationText("description1", voucherCode, "success") }
        </Typography>
        <Box mb={3}>
          <Typography className={classes.bodyText}>
            { voucherPaymentPageDescriptionTranslationText("description2", voucherCode, "success", {voucherCode}) }
          </Typography>
        </Box>
        <Box className={classes.buttonsWrapper}>
          <Button variant="contained" color="primary" onClick={goToHomepage}>
            {t("consultation:goBackToMainPage")}
          </Button>
        </Box>
      </Box>
      { isMdUp && (
        <Box className={classes.imageWrapper} style={{ transform: "scaleX(-1)" }}>
          <ImageByIntegrationType
            alt="visitConfirmedImage"
            imageSrc={visitConfirmed}
            imagePath={"visit-confirmed.png"}
            className={classes.image}
          />
        </Box>
      ) }
    </Box>
  )
}

export default BuyVoucherSuccessPaymentPage
