import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useDocumentationItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    downloadButton: {
      color: theme.palette.primary.dark,
      "&:hover, &:focus": {
        background: "transparent",
        textDecoration: "underline",
      },
      "&:disabled": {
        color: theme.palette.primary.dark,
      }
    },
    fileIcon: {
      marginRight: theme.spacing(1)
    },
    mobileWrapper: {
      maxWidth: `calc(100vw - ${theme.spacing(8)}px)`, // minus page padding x
      [theme.breakpoints.up("md")]: {
        maxWidth: `calc(100vw - ${theme.spacing(12)}px)`, // minus page padding x
      }
    },
    item: {
      width: "100%"
    }
  })
)
