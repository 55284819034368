import {gql} from "@apollo/client"

export const COMPLETE_ACTION = gql`
  mutation CompleteAction($actionId: uuid!) {
    completeAction(user_action_id: $actionId) {
      status
    }
  }
`

export type CompleteActionResponse = {
  completeAction: {
    status: string
  }
}

export type CompleteActionVariables = {
  actionId: string
}
