import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useYourTaskStyles = makeStyles(theme => createStyles({
  image: {
    width: 200,
    height: 200,
    alignSelf: "center",
    [theme.breakpoints.down("xs")]: {
      width: 160,
      height: 160,
    }
  },
  text: {
    marginBottom: theme.spacing(2)
  }
}))
