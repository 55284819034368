import React, {FC} from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { Box } from "@material-ui/core"

import SickLeavePatientAddress from "../../../../../../../components/SickLeaveSurvey/SickLeavePatientAddress.component"
import AddressCheckbox from "./AddressCheckbox/AddressCheckbox.component"
import UserAddress from "./UserAddress/UserAddress.component"
import {PatientsRequirementsStepData} from "../../../../MedicalQuestionnaireStepperForm.types"

const SickLeaveAddress: FC = () => {
  const isOtherAddressSameAsUserAddress = useWatch<PatientsRequirementsStepData, "sickLeave.isOtherAddressSameAsUserAddress">({
    name: "sickLeave.isOtherAddressSameAsUserAddress",
  })

  const { setValue } = useFormContext<PatientsRequirementsStepData>()

  return (
    <Box>
      <UserAddress/>
      <AddressCheckbox/>
      {
        !isOtherAddressSameAsUserAddress && (
          <SickLeavePatientAddress
            fieldsNamePrefix="sickLeave.addressOfResidence."
            otherAddressEdit
            setValue={setValue}
          />
        )
      }
    </Box>
  )
}

export default SickLeaveAddress
