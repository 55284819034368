import i18n from "i18next"

import api from "../../api/api"
import {patchUserPersonalDataConfig} from "../../api/routes"
import {redirectToError500Page} from "../../utils/handleErrors"
import {SetState} from "../../types/SetState"
import { YesNoAnswer } from "./ExtraQuestions/ExtraQuestions.types"
import {Answers, PatientSymptompsArrayType ,SurveyFields} from "./SickLeaveSurvey.types"

import { questions } from "./ExtraQuestions/ExtraQuestions.survey"

export const getOtherAddressData = (data: SurveyFields) => (
  data?.street_otherAddress ?
    `Adres przebywania (w okresie objętym zwolnieniem):\n\n
     Ulica: ${data?.street_otherAddress}\n
     Numer domu: ${data?.houseNumber_otherAddress}\n
     Numer mieszkania: ${data?.apartmentNumber_otherAddress ?? "Nie podano"}\n
     Kod pocztowy: ${data?.postalCode_otherAddress}\n
     Miejscowość: ${data?.city_otherAddress}\n
     Kraj: ${data?.country_otherAddress}\n` : ""
)

export const getForeignWorkerData = (data: SurveyFields, isForeignWorker: boolean) => (
  isForeignWorker ? `Dane firmy (pracownik zagraniczny):\n
     Nazwa firmy: ${data?.companyName_foreignWorker}\n
     NIP (identyfikator pracodawcy): ${data?.nip_foreignWorker}\n
     Ulica: ${data?.street_foreignWorker}\n
     Numer domu: ${data?.houseNumber_foreignWorker}\n
     Numer mieszkania: ${data?.apartmentNumber_foreignWorker ?? "Nie podano"}\n
     Kod pocztowy: ${data?.postalCode_foreignWorker}\n
     Miejscowość: ${data?.city_foreignWorker}\n
     Kraj: ${data?.country_foreignWorker}\n` : ""
)

export const handleSavePersonalData = async (
  setAddressFieldsDisabled: SetState<boolean>,
  setIsPersonalDataSaving: SetState<boolean>,
  userAddressData?: unknown[],
  userId?: string
) => {

  if (userAddressData?.[0] && userAddressData?.[1] && userAddressData?.[3] && userAddressData?.[4]) {
    setIsPersonalDataSaving(true)

    try {
      await api.request({
        ...patchUserPersonalDataConfig(userId), data: {
          address: {
            street: userAddressData?.[0],
            houseNumber: userAddressData?.[1],
            apartmentNumber: userAddressData?.[2],
            postalCode: userAddressData?.[3],
            city: userAddressData?.[4],
          },
        },
      })
    } catch (e) {
      redirectToError500Page(e)
    }

    setAddressFieldsDisabled(true)
    setIsPersonalDataSaving(false)
  }
}

export const getNipData = (data: SurveyFields) => {
  const nipData = data?.nip
  
  return (
    `${nipData?.[0]?.nipItem ? `\nNIP 1: ${nipData?.[0]?.nipItem}` : ""}
    ${nipData?.[1]?.nipItem ? `\nNIP 2: ${nipData?.[1]?.nipItem}` : ""}
    ${nipData?.[2]?.nipItem ? `\nNIP 3: ${nipData?.[2]?.nipItem}\n`: ""}`
  )
}

export const getNipDataParams = (data: SurveyFields) => {
  const nipData = data?.nip
  const nipDataArray = []

  for (let i = 0; i < nipData?.length; i++){
    const item = nipData?.[i]?.nipItem

    if (item) {
      nipDataArray.push(item)
    }
  }

  return nipDataArray
}

export const getSickLeavePeriod = (startAt: string, endAt: string) => {
  const dateStart = startAt.split("-")
  const dateEnd= endAt.split("-")

  return `${dateStart[2]}/${dateStart[1]}/${dateStart[0]} - ${dateEnd[2]}/${dateEnd[1]}/${dateEnd[0]}`
}

export const generatePatientAddress = (formData: SurveyFields, isMainAddress = false) => {
  const addressData = {
    street: isMainAddress ? formData?.street : formData?.street_otherAddress,
    houseNumber: isMainAddress ? formData?.houseNumber : formData?.houseNumber_otherAddress,
    apartmentNumber: isMainAddress ? formData?.apartmentNumber : formData?.apartmentNumber_otherAddress,
    postalCode: isMainAddress ? formData?.postalCode : formData?.postalCode_otherAddress,
    city: isMainAddress ? formData?.city : formData?.city_otherAddress
  }

  const {street, houseNumber, apartmentNumber, postalCode, city} = addressData

  return `${i18n.t("user:street")} ${street} ${houseNumber}${apartmentNumber ? `/${apartmentNumber}` : ""}\n${postalCode} ${city}`
}

export const generateForeignWorkerCompanyAddress = (formData: SurveyFields) => {
  const addressData = {
    street: formData?.street_foreignWorker,
    houseNumber: formData?.houseNumber_foreignWorker,
    apartmentNumber: formData?.apartmentNumber_foreignWorker,
    postalCode: formData?.postalCode_foreignWorker,
    city: formData?.city_foreignWorker,
    country: formData?.country_foreignWorker,
    companyName: formData?.companyName_foreignWorker,
    nip: formData?.nip_foreignWorker
  }

  const {street, houseNumber, apartmentNumber, postalCode, city, country, companyName, nip} = addressData

  return `${companyName}
    ${i18n.t("consultation:sickLeave:nip")} ${nip}
    ${i18n.t("user:street")} ${street} ${houseNumber}${apartmentNumber ? `/${apartmentNumber}` : ""}
    ${postalCode} ${city}
    ${country}`
}

const parseAilmentsGroupTitle = (value) => {
  switch(value) {
    case "coldOrPain":
      return "Przeziębienie i bóle" 
    case "stomachIssues":
      return "Zatrucie i problemy żołądkowe" 
    case "bladderIssues":
      return "Problemy z pęcherzem"
    case "injuries":
      return "Urazy"
    case "backPain":
      return "Bóle pleców"
    case "eyesIssues":
      return "Problemy z oczami"
    case "migraine":
      return "Migrena"
    case "stressReaction":
      return "Ostre reakcje na stres / problemy psychologiczne"
    case "menses":
      return "Menstruacja / miesiączka"
  }
}
export const getExtraQuestionsMessage = (values: SurveyFields) => {
  const pregnantMsg = values.pregnant === YesNoAnswer.YES
    ? "Jest w ciąży" 
    : "Nie jest w ciąży"
  const diseasesMsg = values.chronicDiseases === YesNoAnswer.YES
    ? `Choruje na choroby przewlekłe: ${values.chronicDiseasesGroup.join(", ")}`
    : "Nie choruje na choroby przewlekłe"
  const allergyMsg = values.allergy === YesNoAnswer.YES
    ? `Ma alergie na: ${values.allergyDetails}` 
    : "Brak alergii"
  const regularDrugsMsg = values.regularDrugs === YesNoAnswer.YES
    ? `Przyjmuje na stałe leki: ${values.regularDrugsDetails}`
    : "Nie przyjmuje żadnych leków na stałe"
  const duringSickLeaveMsg = values.duringSickLeave === YesNoAnswer.YES
    ? "Przebywa na zwolnieniu dłużej niż 2 tygodnie. Załączył dokumentację z poprzedniego leczenia."
    : "Neguje przebywanie na zwolnieniu dłużej niż 2 tygodnie"

  const ailmentsMsg = `${parseAilmentsGroupTitle(values.ailments)}: ${values[values.ailments].join(", ")}`
  const ailmentsTimeMsg = `Objawy występują: Od ${values.ailmentsTime} dni`
  const importantInfoMsg = `Dodatkowe informacje: ${values.importantInformations}`
  
  const message = `Pacjent zgłasza:\n
    ${pregnantMsg ? `- ${pregnantMsg}\n` : ""}
    ${diseasesMsg ? `- ${diseasesMsg}\n` : ""}
    ${allergyMsg ? `- ${allergyMsg}\n` : ""}
    ${regularDrugsMsg ? `- ${regularDrugsMsg}\n` : ""}
    ${duringSickLeaveMsg ? `- ${duringSickLeaveMsg}\n` : ""}
    ${ailmentsMsg ? `- ${ailmentsMsg}\n` : ""}
    ${ailmentsTimeMsg ? `- ${ailmentsTimeMsg}\n` : ""}
    ${importantInfoMsg ? `- ${importantInfoMsg}\n` : ""}
  `

  return message
}

const getPatientSymptomps = (ailments: string[], answers: Answers|undefined) => {
  const patientSymptompsArray: PatientSymptompsArrayType[] = []

  ailments.forEach(ailment => {
    const currentItem = answers?.filter((item) => item.label === ailment)?.[0]

    patientSymptompsArray.push({
      name: currentItem?.label,
      id: currentItem?.value
    })
  })

  return patientSymptompsArray
}

enum AilmentType {
  COLD_OR_PAIN = 1,
  STOMACH_ISSUES = 2,
  BLADDER_ISSUES = 3,
  INJURIES = 4,
  BACK_PAIN = 5,
  EYES_ISSUES = 6,
  MIGRAINE = 7,
  STRESS_REACTION = 8,
  MENSES = 9,
}

const parseAilmentToId = (ailmentType) => {
  switch(ailmentType) {
    case "coldOrPain":
      return AilmentType.COLD_OR_PAIN
    case "stomachIssues":
      return AilmentType.STOMACH_ISSUES
    case "bladderIssues":
      return AilmentType.BLADDER_ISSUES
    case "injuries":
      return AilmentType.INJURIES
    case "backPain":
      return AilmentType.BACK_PAIN
    case "eyesIssues":
      return AilmentType.EYES_ISSUES
    case "migraine":
      return AilmentType.MIGRAINE
    case "stressReaction":
      return AilmentType.STRESS_REACTION
    case "menses":
      return AilmentType.MENSES
  }
}

const parseDiseases = (diseases) => {
  return diseases.map((item) => {
    return { name: item }
  })
}

export const getExtraQuestionsAnswersPayload = (values: SurveyFields, consultationId: string) => {
  const ailmentsData = questions.filter(item => item.name === "ailments")
  const currentAliments = ailmentsData?.[0].nestedItems?.filter(item => item.name === values.ailments)
  const symptoms = getPatientSymptomps(values[values.ailments], currentAliments?.[0]?.answers)

  const isPregnant = values.pregnant === YesNoAnswer.YES
  const isRequiredPregnancySickLeave = values.pregnancySickLeave === YesNoAnswer.YES
  const hasChronicDiseases = values.chronicDiseases === YesNoAnswer.YES
  const chronicDiseases = values.chronicDiseases === YesNoAnswer.YES ? parseDiseases(values.chronicDiseasesGroup) : undefined
  const hasAllergy = values.allergy === YesNoAnswer.YES
  const allergyDetails = values.allergy === YesNoAnswer.YES ? values.allergyDetails : undefined
  const hasRegularDrugs = values.regularDrugs === YesNoAnswer.YES
  const regularDrugsDetails = values.regularDrugs === YesNoAnswer.YES ? values.regularDrugsDetails : undefined
  const isDuringSickLeave = values.duringSickLeave === YesNoAnswer.YES
  const ailmentCategory = parseAilmentToId(values.ailments)
  const ailmentsTime = parseInt(values.ailmentsTime)

  // ailments
  const coldOrPain = parseAilmentToId(values.ailments) === AilmentType.COLD_OR_PAIN ? symptoms : []
  const poisoningAndStomachIssues = parseAilmentToId(values.ailments) === AilmentType.STOMACH_ISSUES ? symptoms : []
  const bladderIssues = parseAilmentToId(values.ailments) === AilmentType.BLADDER_ISSUES ? symptoms : []
  const injuries = parseAilmentToId(values.ailments) === AilmentType.INJURIES ? symptoms : []
  const backPain = parseAilmentToId(values.ailments) === AilmentType.BACK_PAIN ? symptoms : []
  const eyesIssues = parseAilmentToId(values.ailments) === AilmentType.EYES_ISSUES ? symptoms : []
  const migraine = parseAilmentToId(values.ailments) === AilmentType.MIGRAINE ? symptoms : []
  const stressReaction = parseAilmentToId(values.ailments) === AilmentType.STRESS_REACTION ? symptoms : []
  const menstruation = parseAilmentToId(values.ailments) === AilmentType.MENSES ? symptoms : []

  return {
    consultationId,
    isPregnant,
    isRequiredPregnancySickLeave,
    hasChronicDiseases,
    chronicDiseases,
    hasAllergy,
    allergyDetails,
    hasRegularDrugs,
    regularDrugsDetails,
    isDuringSickLeave,
    ailmentCategory,
    ailmentsTime,
    coldOrPain,
    poisoningAndStomachIssues,
    bladderIssues,
    injuries,
    backPain,
    eyesIssues,
    migraine,
    stressReaction,
    menstruation
  }
}

export const NIP_FIELDS_LIMIT = 3