import { AxiosError } from "axios"
import { push } from "connected-react-router"
import i18next from "i18next"

import store from "../store"
import { Path, UseFormReturn } from "react-hook-form/dist/types"

export const redirectToError500Page = (error: AxiosError): void => {
  const status = error?.response?.status || 0
  if (status >= 500) {
    store.dispatch(push(`/${i18next.language}/server-error/${status}`))
  }
}

export const redirectToError404Page = (error: AxiosError): void => {
  const status = error?.response?.status || 0
  if (status === 404) {
    store.dispatch(push(`/${i18next.language}/404`))
  }
}

export const handleReactHookFormErrors = <T>(errors: Record<keyof T, string[]> , form: UseFormReturn<T>): void => {
  for (const [fieldName, fieldValue] of Object.entries(errors)) {
    const errorMessage = (fieldValue as string[]).join("\u000A")
    form.setError(
      fieldName as Path<T>,
      {
        type: "manual",
        message: i18next.t(`errors:${errorMessage}`)
      }
    )
  }
}
