import { isAfter, isBefore, isValid } from "date-fns"
import i18next from "i18next"
import * as yup from "yup"

import { isAdult } from "../../../../../utils/isAdult"
import { Gender, UserDataType, UserDocumentData, UserParentDataType } from "../../../../../store/user/user.types"
import { CountryOption } from "../../../../commonFormItems/countrySelect/Country.types"

export const nameRegexp = /^[a-zA-Z\-_ ’'‘ÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ\s]+$/

interface SendUserDocumentsFormValues {
  // Patient values
  firstName: string;
  lastName: string;
  nationality: CountryOption["value"];
  pesel?: string;
  documentType?: UserDocumentData["value"];
  identityNumber?: string;
  birthDate?: string;
  gender: Gender | null;
  defaultTimezone: string;
  // Parent values
  parentFirstName: string;
  parentLastName: string;
  parentNationality: CountryOption["value"];
  parentPesel?: string;
  parentDocumentType?: UserDocumentData["value"];
  parentIdentityNumber?: string;
  parentBirthDate?: string;
  parentGender: Gender | null;
  parentDefaultTimezone: string;
}

interface SendUserParentDocumentsFormValues {
  parentFirstName: string;
  parentLastName: string;
  parentNationality: CountryOption["value"];
  parentPesel?: string;
  parentDocumentType?: UserDocumentData["value"];
  parentIdentityNumber?: string;
  parentBirthDate?: string;
  parentGender: Gender | null;
  parentDefaultTimezone: string;
}

export const isValidBirthDate = (birthDate?: string) => {
  if(!birthDate) {
    return false
  }

  const earliestDate = new Date("1899-12-31")
  const oldestDate = new Date()
  const formattedBirthDate = new Date(birthDate)

  if(!isValid(formattedBirthDate) || !isAfter(formattedBirthDate, earliestDate) || !isBefore(formattedBirthDate, oldestDate)) {
    return false
  }

  return true
}

export const isValidPesel = (pesel?: string | null) => {
  // https://www.modestprogrammer.pl/walidacja-pesel-w-javascript
  if (!pesel || !/^[0-9]{11}$/.test(pesel)) {
    return false
  }
  const times = [1, 3, 7, 9]
  const digits = `${pesel}`.split("").map((digit) => parseInt(digit, 10))
  const dig11 = digits.splice(-1)[0]
  const control = digits.reduce((previousValue, currentValue, index) => previousValue + currentValue * times[index % 4]) % 10
  return 10 - (control === 0 ? 10 : control) === dig11
}

export const isAdultFromPesel = (pesel?: string | null) => {
  if (!pesel || !/^[0-9]{11}$/.test(pesel)) {
    return false
  }

  // first, we need to extract date of birth from pesel number
  let year = parseInt(pesel.substring(0,2),10)
  let month = parseInt(pesel.substring(2,4),10)
  const day = parseInt(pesel.substring(4,6),10)

  // then we have to apply a special rule for date depending on which year user were born
  // more details below:
  // https://www.modestprogrammer.pl/walidacja-pesel-w-javascript
  if (month>80) {
    year = year + 1800
    month = month - 80
  } else if(month > 60) {
    year = year + 2200
    month = month - 60
  } else if (month > 40) {
    year = year + 2100
    month = month - 40
  } else if (month > 20) {
    year = year + 2000
    month = month - 20
  } else {
    year += 1900
  }

  const birthDate = new Date()
  birthDate.setFullYear(year, month, day)

  return isAdult(birthDate)
}

type schemaType = {
  fields: {
    [fieldKey: string]: string
  }
}

export const userEditPersonalDataSchema = (
  userPesel: UserDataType["pesel"],
  userParentPesel: UserParentDataType["pesel"]
): yup.SchemaOf<SendUserDocumentsFormValues> => {
  const requiredMessage = i18next.t("errors:required")
  const wrongPeselMessage = i18next.t("errors:form.wrong_pesel")
  const wrongParentAgeFromPeselMessage = i18next.t("errors:wrongParentAgeFromPeselMessage")
  const wrongParentAgeFromBirthDateMessage = i18next.t("errors:wrongParentAgeFromBirthDateMessage")
  const birthDateInvalidMessage = i18next.t("errors:birthDateInvalid")
  const unknownErrorMessage = i18next.t("errors:unknownError")
  const incorrectFirstNameMessage = i18next.t("errors:incorrectFirstNameMessage")
  const incorrectLastNameMessage = i18next.t("errors:incorrectLastNameMessage")

  return yup.object().shape({
    // -------------- Patient Fields --------------
    firstName: yup.string()
      .required(requiredMessage)
      .matches(nameRegexp, incorrectFirstNameMessage),
    lastName: yup.string()
      .required(requiredMessage)
      .matches(nameRegexp, incorrectLastNameMessage),
    nationality: yup.string()
      .required(requiredMessage),
    pesel: yup.string()
      .required(requiredMessage)
      .when("pesel", {
        is: () => !userPesel,
        then: yup.string()
          .when("underageUserCheckbox", {
            is: (underageUserCheckbox: boolean) => underageUserCheckbox,
            then: yup.string()
              .test("is-adult", unknownErrorMessage, (value?: string) =>
                !isAdultFromPesel(value),
              )
              .test("is-valid-pesel", wrongPeselMessage, (value?: string) =>
                isValidPesel(value)
              ),
            otherwise: yup.string()
              .test("is-valid-pesel", wrongPeselMessage, (value?: string) =>
                isValidPesel(value)
              )
          })
      }),
    documentType: yup.string()
      .required(requiredMessage),
    identityNumber: yup.string()
      .required(requiredMessage),
    birthDate: yup.string()
      .required(requiredMessage)
      .test("is-valid-date", birthDateInvalidMessage, (value?: string) => 
        isValidBirthDate(value),
      )
      .when("underageUserCheckbox", {
        is: (underageUserCheckbox: boolean) => underageUserCheckbox,
        then: yup.string()
          .test("is-adult", unknownErrorMessage, (value?: string) =>
            !isAdult(value),
          ),
      }),
    gender: yup.mixed()
      .oneOf(Object.values(Gender), requiredMessage)
      .required(requiredMessage),
    defaultTimezone: yup.string()
      .required(requiredMessage),

    // -------------- Parent Fields --------------
    parentFirstName: yup.string()
      .required(requiredMessage)
      .matches(nameRegexp, incorrectFirstNameMessage),
    parentLastName: yup.string()
      .required(requiredMessage)
      .matches(nameRegexp, incorrectLastNameMessage),
    parentNationality: yup.string()
      .required(requiredMessage),
    parentPesel: yup.string()
      .required(requiredMessage)
      .when("parentPesel", {
        is: () => !userParentPesel,
        then: yup.string()
          .test("is-valid-pesel", wrongPeselMessage, (value?: string) =>
            isValidPesel(value)
          )
          .test("is-adult", wrongParentAgeFromPeselMessage, (value?: string) =>
            isAdultFromPesel(value) ?? false,
          )
      }),
    parentDocumentType: yup.string()
      .required(requiredMessage),
    parentIdentityNumber: yup.string()
      .required(requiredMessage),
    parentBirthDate: yup.string()
      .required(requiredMessage)
      .test("is-valid-date", birthDateInvalidMessage, (value?: string) => 
        isValidBirthDate(value),
      )
      .test("is-adult", wrongParentAgeFromBirthDateMessage, (value?: string) =>
        isAdult(value) ?? false,
      ),
    parentGender: yup.mixed()
      .oneOf(Object.values(Gender), requiredMessage)
      .required(requiredMessage),
    parentDefaultTimezone: yup.string()
      .required(requiredMessage),
  }, [
    ["pesel", "pesel"],
    ["parentPesel", "parentPesel"]
  ]).required().when((values: string, schema: schemaType) => {
    for (const key in schema.fields) {
      const currentField = document.getElementById(key)

      if (currentField === null) {
        delete schema.fields[key]
      }
    }
  })
}

export const userParentEditPersonalDataSchema = (userPesel: UserParentDataType["pesel"]): yup.SchemaOf<SendUserParentDocumentsFormValues> => {
  const requiredMessage = i18next.t("errors:required")
  const wrongPeselMessage = i18next.t("errors:form.wrong_pesel")
  const wrongParentAgeFromPeselMessage = i18next.t("errors:wrongParentAgeFromPeselMessage")
  const wrongParentAgeFromBirthDateMessage = i18next.t("errors:wrongParentAgeFromBirthDateMessage")
  const birthDateInvalidMessage = i18next.t("errors:birthDateInvalid")
  const incorrectFirstNameMessage = i18next.t("errors:incorrectFirstNameMessage")
  const incorrectLastNameMessage = i18next.t("errors:incorrectLastNameMessage")

  return yup.object().shape({
    parentFirstName: yup.string()
      .required(requiredMessage)
      .matches(nameRegexp, incorrectFirstNameMessage),
    parentLastName: yup.string()
      .required(requiredMessage)
      .matches(nameRegexp, incorrectLastNameMessage),
    parentNationality: yup.string()
      .required(requiredMessage),
    parentPesel: yup.string()
      .required(requiredMessage)
      .when("parentPesel", {
        is: () => !userPesel,
        then: yup.string()
          .test("is-valid-pesel", wrongPeselMessage, (value?: string) =>
            isValidPesel(value)
          )
          .test("is-adult", wrongParentAgeFromPeselMessage, (value?: string) =>
            isAdultFromPesel(value) ?? false,
          )
      }),
    parentDocumentType: yup.string()
      .required(requiredMessage),
    parentIdentityNumber: yup.string()
      .required(requiredMessage),
    parentBirthDate: yup.string()
      .required(requiredMessage)
      .test("is-valid-date", birthDateInvalidMessage, (value?: string) => 
        isValidBirthDate(value),
      )
      .test("is-adult", wrongParentAgeFromBirthDateMessage, (value?: string) =>
        isAdult(value) ?? false,
      ),
    parentGender: yup.mixed()
      .oneOf(Object.values(Gender), requiredMessage)
      .required(requiredMessage),
    parentDefaultTimezone: yup.string()
      .required(requiredMessage),
  },[
    ["parentPesel", "parentPesel"]
  ]).required().when((values: string, schema: schemaType) => {
    for (const key in schema.fields) {
      const currentField = document.getElementById(key)

      if (currentField === null) {
        delete schema.fields[key]
      }
    }
  })
}