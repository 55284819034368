import { makeStyles } from "@material-ui/core"

import { createStyles, Theme } from "@material-ui/core/styles"

export const usePageFooterStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.background.footerBar,
    color: theme.palette.text.footerBar,
    padding: theme.spacing(4, 4, 14, 4),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.up("lg")]: {
      flexWrap: "nowrap",
    },
  },
  logotypeLink: {
    padding: theme.spacing(0, 0, 3, 0),
    [theme.breakpoints.up("lg")]: {
      flex: "1 0 20%",
      padding: theme.spacing(0, 4, 0, 0),
    },
  },
  rootB2b: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    }
  },
  logoB2b: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(3),
    }
  },
  writeToUsB2b: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 0, 2, 4)
    }
  }
}))
