export interface ReferralsModel {
  total: number;
  items: ReferralModel[];
}

export interface ReferralModel {
  id: string; //"8c3dc9c5-dfe4-11eb-a02b-25563e344bde"
  name: string; // "Skierowanie - Lekarz Receptowy - 2021-07-08 14:04"
  url: URL["href"]; //"http://staging.tmdi04.com/api/v2/medical-documents/8c3dc9c5-dfe4-11eb-a02b-25563e344bde?_locale=pl"
  date: string; // "2021-07-08 14:05:49"
  consultation_id: string; //"fd4e8f13-7f5b-42b8-8d97-7e1a9f2ab7d8"
  specialization_id: string; //"164b5f7f-b72e-4038-a67c-5308082734a9"
  specialization_name: string; //"Lekarz ogólny / internista"
  visit_at: string; //"2021-07-08 14:04:34"
}

export enum EXAMS_PURCHASE_STATUSES {
  ALL = "ALL",
  PARTIALLY = "PARTIALLY",
  NONE = "NONE",
}
