import React, { FC } from "react"
import {useParams} from "react-router"
import { Box } from "@material-ui/core"

import {
  useGetConsultationDocumentationQuery
} from "../../store/consultationDocumentation/consultationDocumentation.api"
import {useGetExaminationReferralsQuery} from "../../store/examReferrals/examReferrals.api"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import OrderExaminationsByAlabForm
  from "../../components/orderExaminationsByAlabForm/OrderExaminationsByAlabForm.component"
import {useOrderExaminationsByAlabStyles} from "./OrderExaminationsByAlab.styles"

interface OrderExaminationsByAlabProps {}

const OrderExaminationsByAlab: FC<OrderExaminationsByAlabProps> = () => {
  const classes = useOrderExaminationsByAlabStyles()
  const {consultationId} = useParams<{ consultationId: string }>()
  const {data = [], isLoading} = useGetExaminationReferralsQuery(consultationId)
  const {data: medicalDocuments, isLoading: isLoadingMedicalDocuments} = useGetConsultationDocumentationQuery(consultationId)
  const visitAtDate = medicalDocuments?.prescriptions.items[0]?.visit_at //all items have same visit at

  return (
    <Box className={classes.root}>
      {
        (isLoading || !data || isLoadingMedicalDocuments)
          ? <LoaderBox boxType="appDialog"/>
          : (
            <OrderExaminationsByAlabForm
              referrals={data}
              consultationId={consultationId}
              visitAtDate={visitAtDate ?? ""}
            />
          )
      }
    </Box>
  )
}

export default OrderExaminationsByAlab
