
import {ExamListItem} from "../../../components/exam/examsSelect/ExamsSelect.types"
import {ForeignCompanyAddress, UserAddress} from "../../../store/patients/patients.types"
import {Prescription} from "../../../store/prescriptions/prescriptions.types"

import {StepNumber} from "./MedicalQuestionnaireStepperForm.enums"

export enum QuestionAnswer {
  YES = "1",
  NO = "0",
}

export enum Sex {
  MALE  = "male",
  FEMALE = "female"
}

export type BasicInformationStepData = {
  weight?: number;
  height?: number;
  sex?: Sex;
  sexChangeIsLocked?: boolean;
  isPregnant?: boolean;
  isBreastFeeding?: boolean;
  hasChronicDiseases?: QuestionAnswer;
  hasChronicDiseasesInput: string;
  isTakingAnyMedicines?: QuestionAnswer;
  isTakingAnyMedicinesInput: string;
  hasAnyAllergies?: QuestionAnswer;
  hasAnyAllergiesInput: string;
  visitReason?: {
    isPrescriptionNeeded: boolean;
    isSickLeaveNeeded: boolean;
    isReferralNeeded: boolean;
    isTalkWithDoctorNeeded: boolean;
  }
}

export enum EmploymentType {
  CIVILIAN_PERSONNEL = 1,
  UNIFORMED_SERVICES = 2,
  FOREIGN_COMPANY = 3,
  STUDENT = 4,
}

export type OtherUserAddress = {
  street_otherAddress: string;
  houseNumber_otherAddress: string
  apartmentNumber_otherAddress: string;
  postalCode_otherAddress: string;
  city_otherAddress: string;
  country_otherAddress: string | null;
}

export type PatientsRequirementsStepData = {
  sickLeave: {
    employmentType: EmploymentType;
    startAt: string;
    endAt: string;
    isOtherAddressSameAsUserAddress: boolean;
    address: UserAddress;
    addressOfResidence: OtherUserAddress;
    foreignCompanyAddress: ForeignCompanyAddress | null;
    isStudentConfirmation: boolean;
    uniformedServiceType: string | null;
    nip: {
      nipItem: string;
    }[]
  }
  referral: {
    exams: ExamListItem[]
    isDoctorConsultationRequired: boolean;
  }

  prescription: {
    prescriptions: (Prescription & {
      chosenAmount: number;
      chosenSize: number;
      unit?: string;
    })[];
    isDoctorConsultationRequired: boolean;
  }
}

export type SummaryStepData = {
  isAgreementAccepted: boolean;
}

export type StepsData = {
  [StepNumber.BASIC_INFORMATION]: BasicInformationStepData;
  [StepNumber.PATIENTS_REQUIREMENTS]: PatientsRequirementsStepData;
  [StepNumber.SUMMARY]: SummaryStepData;
}
