import {createStyles, makeStyles} from "@material-ui/core"

export const useAlertStyles = makeStyles(theme => createStyles({
  container: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  message: {
    fontWeight: 500,
    color: theme.palette.error.main,
    paddingTop: theme.spacing(0.5)
  }
}))
