import {createStyles, makeStyles} from "@material-ui/core"

export const useFormFieldStyles = makeStyles((theme) => createStyles({
  root: {
    borderBottom: `2px solid ${theme.palette.grey[100]}`,
    paddingBottom: theme.spacing(5),
    marginBottom: theme.spacing(4)
  },
  label: {
    marginBottom: theme.spacing(4)
  },
  inputWrapper: {
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 2),
    }
  }
}))