import React, {FC} from "react"

import DefaultLayout from "../layout/DefaultLayout"

interface WithDefaultLayoutProps {}

// eslint-disable-next-line react/display-name
const withDefaultLayout = <T extends WithDefaultLayoutProps>(WrappedComponent: FC<T & WithDefaultLayoutProps>): FC<T> => (props: T) => {
  return (
    <DefaultLayout>
      <WrappedComponent {...props} />
    </DefaultLayout>
  )
}

export default withDefaultLayout
