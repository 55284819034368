import React, { FC } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useWizard } from "react-use-wizard"
import { Box, Divider } from "@material-ui/core"

import useGetFormValidationRules from "../../../../../hooks/useGetFormValidationRules"
import NavigationButtons from "../../NavigationButtons/NavigationButtons.component"
import BasicMedicalQuestionnaire from "./BasicMedicalQuestionnaire/BasicMedicalQuestionnaire.component"
import ReasonForVisit from "./ReasonForVisit/ReasonForVisit.component"
import { BasicInformationStepData } from "../../MedicalQuestionnaireStepperForm.types"
import { useBasicInformationFormStyles } from "./BasicInformationForm.styles"

import { basicMedicalQuestionnaireSchema } from "./BasicInformationForm.validation"

type Props = {
  initialValues?: BasicInformationStepData
  onSubmit: (values: BasicInformationStepData) => void
  isDataFetchedFromPrevSurvey?: boolean
  setIsDataFetchedFromPrevSurvey : (value:boolean)=>void
}

const BasicInformationForm: FC<Props> = ({ initialValues, onSubmit, isDataFetchedFromPrevSurvey,setIsDataFetchedFromPrevSurvey }) => {
  const classes = useBasicInformationFormStyles()
  const formMethods = useForm<BasicInformationStepData>({
    defaultValues: initialValues,
    resolver: useGetFormValidationRules(basicMedicalQuestionnaireSchema),
  })
  const { nextStep, goToStep } = useWizard()

  return (
    <FormProvider {...formMethods}>
      <Box
        component='form'
        onSubmit={formMethods.handleSubmit((data) => {
          onSubmit(data)
          if (data.visitReason?.isTalkWithDoctorNeeded
              && !data.visitReason?.isPrescriptionNeeded
              && !data.visitReason?.isSickLeaveNeeded
              && !data.visitReason?.isReferralNeeded) {
            goToStep(2)
          } else {
            setIsDataFetchedFromPrevSurvey(false)
            nextStep()
          }
        })}
      >
        <BasicMedicalQuestionnaire
          isDataFetchedFromPrevSurvey={isDataFetchedFromPrevSurvey}
        />
        <Divider
          className={classes.divider}
        />
        <ReasonForVisit />
        <NavigationButtons />
      </Box>
    </FormProvider>
  )
}

export default BasicInformationForm
