import React, { FC } from "react"
import Carousel from "react-multi-carousel"

import useGoToConsultationPage from "../../hooks/useGoToConsultationPage"
import ComingConsultationDetails from "../consultation/comingConsultationDetails/ComingConsultationDetails.component"
import ComingExamDetails from "../exam/comingExamDetails/ComingExamDetails.component"
import {
  refreshComingConsultationsData,
} from "../consultation/Consultation.utils"
import {
  ConsultationListItemModel,
} from "../consultation/Consultation.types"
import { ExamModel } from "../exam/Exam.types"
import "react-multi-carousel/lib/styles.css"

import { carouselExtraSettings,responsiveCarouselSettings } from "./ComingConsAndExamsCarousel.config"

interface ComingConsAndExamsCarouselProps {
  consultations: ConsultationListItemModel[]
  exams: ExamModel[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  B2BClinic: boolean;
}

const ComingConsAndExamsCarousel: FC<ComingConsAndExamsCarouselProps> = ({consultations, exams, setLoading, B2BClinic}) => {
  const { goToMakeConsultationPage } = useGoToConsultationPage()

  return (
    <Carousel
      responsive={responsiveCarouselSettings}
      showDots
      arrows={false}
      partialVisible
      renderButtonGroupOutside
      dotListClass={carouselExtraSettings.dotListClass}
      customTransition={carouselExtraSettings.customTransition}
      transitionDuration={carouselExtraSettings.transitionDuration}
      infinite={true}
    >
      {
        consultations.map(consultation => (
          <ComingConsultationDetails
            key={consultation.id}
            id={consultation.id}
            patientId={consultation.patientId}
            doctor={consultation.doctor}
            doctorId={consultation.doctorId}
            doctorNameWithDegree={consultation.doctorNameWithDegree}
            doctorSpecialization={consultation.specialization}
            visitAt={consultation.visit_at}
            consultationType={consultation.consultationType}
            isPayed={consultation.is_payed}
            stationaryVisitAddress={consultation.stationaryVisitAddress}
            paymentLink={consultation.paymentLink}
            refreshConsultations={() => refreshComingConsultationsData(setLoading, 1, goToMakeConsultationPage, B2BClinic)}
            statusNumber={consultation.status_number}
            consultationLanguage={consultation.language}
            isInterestedInPoz={consultation.is_interested_in_poz}
            drugNames={consultation?.drugNames}
            orderedSickLeave={consultation?.orderedSickLeave}
            healthPlannerSurveyFilled={consultation?.healthPlannerSurveyFilled}
            isInterestedInTelemediGo={consultation?.is_interested_in_telemedi_go}
            sickLeaveQuestionnaireFilled={consultation?.sickLeaveQuestionnaireFilled}
            prescriptionQuestionnaireFilled={consultation?.prescriptionQuestionnaireFilled}
            isMedicalSurveyFilled={consultation?.isMedicalSurveyFilled}
            isPatientMedicalSurveyRequired={consultation?.isPatientMedicalSurveyRequired}
          />
        ))
      }
      {
        exams.map(exam => (
          <ComingExamDetails
            key={exam.id}
            {...exam}
          />
        ))
      }
    </Carousel>
  )
}

export default ComingConsAndExamsCarousel
