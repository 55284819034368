import { createStyles, makeStyles, Theme } from "@material-ui/core"

export const useUserProfileStyles = makeStyles((theme: Theme) => createStyles({
  coloredSubtitle: {
    color: theme.palette.primary.main
  },
}))

export const useFillUserParentDataForm = makeStyles<Theme, { isFillDataView?: boolean }>((theme) => createStyles({
  //todo: usunąć logikę po podbicu materiala do wersji 5 https://github.com/telemedico/patient-dashboard/pull/783
  esInputPlaceholder: {
    "& .MuiFormLabel-root": {
      maxWidth: "calc(100% - 24px)",
      top: (props) => props.isFillDataView ? 0 : -7,
      [theme.breakpoints.down("xs")]: {
        top: () => 0
      },
      [theme.breakpoints.up("lg")]: {
        top: () => -7
      },
      [theme.breakpoints.down(401)]: {
        top: (props) => props.isFillDataView ? 0 : -7
      },
      [theme.breakpoints.down(353)]: {
        top: () => -7
      },
      [theme.breakpoints.between("md", 1040)]: {
        top: () => -7
      },
    },
  }
}))
