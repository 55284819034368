import {gql} from "@apollo/client"

export const GET_CLINIC_SETTINGS = gql`
  query getClinicSettings($patientDashboardUrl: String!) {
    clinics(dashboardUrl: $patientDashboardUrl) {
      edges {
        node {
          id
          name
          domain
          collectMedicalDocument
          allowRegistration
          patientEditAllowed
          hasBookConsultationAbility
          showPrices
          stationaryVisitPatient
          buyExamButtonEnabled
          pozformB2BActive
          stripePublicKey
          patientLaboratories
          clinicForeign
          widgetConsbookSettings {
            enableMedicalDocumentationReminderInfo
            enablePaymentProviderInfo
            enableAddVoucherInSummaryFeature
            enableSmallSlotsFeature
            enableInstantChatFeature
            defaultChannelType
          }
          consultationListSettings {
            enableReferralPriceInfo
          }
          examCartSettings {
            enableReferralBasketFeature
          }
          frontendSettings {
            vPozMedicalSurveyEnabled
            clinicId
            style
            images
            seo
            reactAppClinicType
            enableHealthPlannerFeature
            enableHealthPlannerQuestionnaire
            disableReferralsInDashboard
            enableBenefitSystemIntegration
            enabledTelemediGo
            enabledTelemediGoInEprescription
            enabledTelemediGoInSickLeave
            enabledTelemediGoForInternist
            showPhoneNumberOnCompleteDataView
            hideChangePassword
            modules
            contactPhoneNumber
            hideConsultationBooking
          }
          notificationSettings {
            magicLinks
          }
          prescriptionsSettings {
            medicalDrugLimit
            medicalDrugsTotalLimit
            modules
            enableCheckMedicineExposed
            pozGlobalMedicalDrugLimit
            pozGlobalMedicalDrugsTotalLimit
            otherMessagesForB2bClinicEnabled
          }
          enabledLanguages {
            languages
          }
          widgetRegisterSettings {
            linkToRegulations
            linkToPrivacyPolicy
            showAreaCodeForLogin
          }
          clinicSignalIdunaSettings {
            insuranceVerificationEnabled
            dashboardLaboratoryModuleEnabled
          }
          sickLeaveSettings {
            modules
          }
          clinicAllianzSettings {
            ewkaVerificationEnabled
            onyxVerificationEnabled
          }
          clinicVoucherSettings {
            voucherToBuy
          }
          clinicLoginPagePopupSettings {
            loginPagePopupTitle
            loginPagePopupContent
            modifiedAt
          }
          clinicHealthPreventionSettings {
            programs
          }
        }
      }
    }
  }
`
