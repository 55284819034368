import React, {FC} from "react"

import ConsultationChatLayout from "../layout/ConsultationChatLayout"

interface WithConsultationChatLayoutProps {}

// eslint-disable-next-line react/display-name
const withConsultationChatLayout = <T extends WithConsultationChatLayoutProps>(WrappedComponent: FC<T & WithConsultationChatLayoutProps>): FC<T> => (props: T) => {
  return (
    <ConsultationChatLayout>
      <WrappedComponent {...props} />
    </ConsultationChatLayout>
  )
}

export default withConsultationChatLayout
