import React, {FC} from "react"
import {useController} from "react-hook-form"
import {Box, IconButton} from "@material-ui/core"

import {ReactComponent as MinusCircleIcon} from "../../../../../assets/svg/minusCircle.svg"
import {ReactComponent as PlusCircleIcon} from "../../../../../assets/svg/plusCircle.svg"
import ImageByIntegrationType from "../../../../imageByIntegrationType/ImageByIntegrationType"
import {useItemCounterStyles} from "./ItemCounter.styles"

import { MINIMAL_ITEM_COUNT } from "./ItemCounter.config"

interface ItemCounterProps {
  name: string;
  maxCount: number;
}

const ItemCounter: FC<ItemCounterProps> = ({ name, maxCount }) => {
  const classes = useItemCounterStyles()
  const { field: { value, onChange } } = useController({
    name
  })

  return (
    <Box className={classes.root}>
      <IconButton
        size="small"
        disabled={value === MINIMAL_ITEM_COUNT}
        onClick={() => onChange(value - 1)}
      >
        <ImageByIntegrationType
          imageSrc={<MinusCircleIcon className={value === MINIMAL_ITEM_COUNT ? classes.disabledIcon : classes.icon}/>}
          imagePath={"minusCircle.svg"}
          className={value === MINIMAL_ITEM_COUNT ? classes.disabledIcon : classes.icon}
          returnSvg={true}
        />
      </IconButton>
      <Box className={classes.valueContainer}>
        {value}
      </Box>
      <IconButton
        size="small"
        disabled={value === maxCount}
        onClick={() => onChange(value + 1)}
      >
        <ImageByIntegrationType
          imageSrc={<PlusCircleIcon className={value === maxCount ? classes.disabledIcon : classes.icon}/>}
          imagePath={"plusCircle.svg"}
          className={value === maxCount ? classes.disabledIcon : classes.icon}
          returnSvg={true}
        />
      </IconButton>
    </Box>
  )
}

export default ItemCounter
