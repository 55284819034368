import React, { FC } from "react"

import doctorIsWritingDotsAnimationDark from "../../../../assets/animations/doctorIsWritingDotsDark.json"
import LottieAnimation from "../LottieAnimation.component"

import {LOADER_WIDTH} from "./DoctorIsWritingDotsAnimation.config"

interface DoctorIsWritingDotsAnimationDarkProps {}

const DoctorIsWritingDotsAnimationDark: FC<DoctorIsWritingDotsAnimationDarkProps> = () => {
  return (
    <LottieAnimation
      animationData={doctorIsWritingDotsAnimationDark}
      boxWidth={LOADER_WIDTH}
    />
  )
}

export default DoctorIsWritingDotsAnimationDark