import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { ApolloProvider } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import {ConnectedRouter} from "connected-react-router"

import "./styles/index.css"

import { client } from "./api/graphql/apolloClient"
import I18Next from "./i18n/i18n"
import App from "./App"
import store, {history} from "./store"

import "./services/analytics/Analytics.service"
import "./i18n/i18n"

const isDevelopment = process.env.NODE_ENV === "development"
const dsn = process.env.REACT_APP_SENTRY_DSN

if (dsn && !isDevelopment) {
  Sentry.init({
    dsn,
    integrations: [new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    })],
    release: process.env.REACT_APP_VERSION,
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0, //default
    normalizeDepth: 3, //default
  })
}

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <I18Next/>
        <ConnectedRouter history={history}>
          <App/>
        </ConnectedRouter>
      </Provider>
    </ApolloProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
)
