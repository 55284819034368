import {createStyles, makeStyles} from "@material-ui/core"

import {Theme} from "@material-ui/core/styles"

export const useVoucherBoxStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: "relative",
    background: theme.palette.background.paper,
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    }
  },
  expired: {
    "&::after": {
      content: "''",
      background: "rgba(255, 255, 255, 0.6)",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
    }
  },
  expiredText: {
    marginLeft: theme.spacing(1)
  },
  code: {
    wordBreak: "break-all",
  }
}))
