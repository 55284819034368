import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useSickLeaveStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0.5,1.5),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    }
  },
  detailsRoot: {
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  visible: {
    opacity: 1,
    transition: "opacity 300ms",
  },
  hidden: {
    opacity: 0,
    transition: "opacity 300ms",
    pointerEvents: "none",
  },
  calendarSection: {
    paddingTop: theme.spacing(1),
    paddingRight: 0,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(10),
    },
  },
  sickLeaveInfoSection: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(7),
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      marginLeft: theme.spacing(11),
      marginBottom: theme.spacing(7),
    },
    [theme.breakpoints.up("lg")]: {
      paddingRight: theme.spacing(10),
    },
  },
  moreInfoHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  sickLeaveDateRange: {
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      marginTop: "6px",
    }
  }
}))
