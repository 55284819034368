import {useState} from "react"

import {YourTaskStep} from "./YourTask.enum"

export const useYourTaskManager = () => {
  const [step, setStep] = useState(YourTaskStep.DESCRIPTION)

  const handleChangeStep = (stepNumber: YourTaskStep) => () => {
    setStep(stepNumber)
  }

  return {
    step,
    handleChangeStep
  }
}