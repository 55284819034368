import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import {UserAction} from "../../../../api/queries/getUserActionsByIds"
import ActionsListItem from "../../../../components/actionsList/actionsListItem/ActionsListItem.component"
import {useRecommendedActionsStyles} from "./RecommendedActions.styles"

type RecommendedActionsProps = {
  userActions: UserAction[];
}

const RecommendedActions: FC<RecommendedActionsProps> = ({ userActions }) => {
  const {t} = useTranslation()
  const classes = useRecommendedActionsStyles()

  return (
    <Box className={classes.container}>
      {
        userActions.length === 0 && (
          <Typography>
            {t("healthPlanner:noRecommendedActions")}
          </Typography>
        )
      }
      {
        userActions.map(userAction => (
          <ActionsListItem
            key={userAction.id}
            id={userAction.id}
            deadline={userAction.deadline}
            type={userAction.action.type}
            name={userAction.action.name}
            actionSource={userAction.source}
            points={userAction.action.points}
            image_url={userAction.action.imageUrl}
          />
        ))
      }
    </Box>
  )
}

export default RecommendedActions