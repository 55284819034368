import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import {add, isSameYear, parseISO} from "date-fns"

import {useSickLeaveStyles} from "../../sickLeave/SickLeave.styles"

interface SickLeaveSectionHeaderProps {
  startDate: string;
  endDate: string;
}

const SickLeaveSectionHeader: FC<SickLeaveSectionHeaderProps> = ({startDate, endDate}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery((theme.breakpoints.up("md")))
  const isSmUp = useMediaQuery((theme.breakpoints.up("sm")))
  const classes = useSickLeaveStyles()
  const datesToCompare = [
    parseISO(startDate),
    add(parseISO(endDate), {
      hours: 23,
      minutes: 59,
      seconds: 59,
    })
  ]
  const rangeTextDates = t(
    isSameYear(datesToCompare[0], datesToCompare[1])
      ? "dates:rangeDateWithoutYearHeader"
      : "dates:rangeDateWithYearHeader",
    { date: datesToCompare })

  return (
    <Box display="flex" flexWrap={isMdUp ? "nowrap" : "wrap"}>
      <Typography
        variant={isSmUp ? "h3": "h5"}
        component="h3"
        style={{
          display: isSmUp ? "inline" : "block",
          marginRight: `${ theme.spacing(1)}px`,
        }}
      >
        { t("recommendations:sickLeave") }
      </Typography>
      <Typography
        variant={isSmUp ? "body1" : "body1"}
        component="span"
        color="textSecondary"
        className={classes.sickLeaveDateRange}
      >
        { rangeTextDates }
      </Typography>
    </Box>
  )
}

export default SickLeaveSectionHeader
