import { SagaIterator } from "redux-saga"
import { call, put, select,takeLatest } from "redux-saga/effects"

import api from "../../api/api"
import { getMedicalExamPointsByPostalCodeConfig } from "../../api/routes"
import { selectClinicSettings } from "../clinic/clinicSettings.selectors"
import {selectUserAddress} from "../user/user.selectors"
import {UserAddressModel} from "../../components/user/User.types"
import {USER_STORAGE_KEY} from "../user/user.types"
import {EXAM_POINTS_KEY, NEAREST_MEDICAL_EXAM_POINTS_LIMIT} from "./medicalExamsPoints.types"

import {
  clearMedicalExamsPoints,
  getMedicalExamsPoints,
  setMedicalExamsPoints, setMedicalExamsPointsCoords,
  setMedicalExamsPointsError
} from "./medicalExamsPoints.slice"

function* getMedicalExamsPointsSaga() {
  try {
    const userAddress: (UserAddressModel | null) = yield select(selectUserAddress)
    const { patientLaboratories } = yield select(selectClinicSettings)
    if (!userAddress || !userAddress.postal_code) return

    const {data} = yield call(api.request, {
      ...getMedicalExamPointsByPostalCodeConfig!,
      params: {
        laboratory: patientLaboratories[0],
        postalCode: userAddress.postal_code
      }
    })

    if (data.data) {
      const nearestMedicalExamsPoints = data.data.slice(0, NEAREST_MEDICAL_EXAM_POINTS_LIMIT)
      yield put(setMedicalExamsPoints(nearestMedicalExamsPoints))
    } else {
      yield put(setMedicalExamsPoints([]))
    }
  } catch (e) {
    yield put(setMedicalExamsPointsError({
      data: e.response?.data,
      status: e.response?.status,
    }))
  }
}

function* clearMedicalExamsPointsSaga() {
  yield call(() => localStorage.removeItem(EXAM_POINTS_KEY))
}

export default function* (): SagaIterator {
  const medicalExamPointsCoordsJSON: string = yield call(() => localStorage.getItem(EXAM_POINTS_KEY))
  const userJSON: string = yield call(() => localStorage.getItem(USER_STORAGE_KEY))
  const medicalExamPointsCoords = JSON.parse(medicalExamPointsCoordsJSON)
  if (medicalExamPointsCoords) {
    yield put(setMedicalExamsPointsCoords(medicalExamPointsCoords))
  } else if (userJSON) {
    yield put(getMedicalExamsPoints())
  }

  yield takeLatest(getMedicalExamsPoints, getMedicalExamsPointsSaga)
  yield takeLatest(clearMedicalExamsPoints, clearMedicalExamsPointsSaga)
}
