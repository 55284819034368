import {useMemo, useState} from "react"

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState)

  return useMemo(
    () => [
      state,
      {
        toggle: () => {
          setState(previousState => !previousState)
        },
        handleToggle: (newState: boolean) => () => {
          setState(newState)
        },
        handleOff: () => {
          setState(false)
        },
        handleOn: () => {
          setState(true)
        },
        setState
      }
    ] as const,
    [state, setState]
  )
}
