import React, {FC} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box} from "@material-ui/core"

import useGetFormValidationRules from "../../../hooks/useGetFormValidationRules"
import {useHandleReactHookFormErrors} from "../../../hooks/useHandleReactHookFormErrors"
import {
  userPasswordChangeValidationSchema
} from "../../user/userProfile/userPasswordChange/UserPasswordChange.validation"
import Button from "../../button/Button.component"
import GlobalFormErrorMessageHandlers
  from "../../commonFormItems/globalFormErrorMessageHandlers/GlobalFormErrorMessageHandlers.component"
import PasswordInputController
  from "../../commonFormItems/passwordInputController/PasswordInputController.component"
import {ChangePasswordPayload} from "../../../store/patients/patients.types"
import {useResetPasswordFormStyles} from "./ResetPasswordForm.styles"

import {dataTestIdMap} from "./ResetPasswordForm.config"

export type ResetPasswordFormProps = {
  onSubmit: (changePasswordPayload: ChangePasswordPayload) => Promise<unknown>
  isLoading: boolean;
  error?: unknown
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ onSubmit, isLoading, error }) => {
  const {t} = useTranslation()
  const classes = useResetPasswordFormStyles()
  const form = useForm<ChangePasswordPayload & { plainPasswordRepeat: string; }>({
    resolver: useGetFormValidationRules(userPasswordChangeValidationSchema),
  })
  useHandleReactHookFormErrors(form, error)

  return (
    <FormProvider {...form}>
      <Box
        display="flex"
        flexDirection="column"
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <GlobalFormErrorMessageHandlers error={error}/>
        <PasswordInputController
          name="oldPlainPassword"
          label={t("user:oldPlainPassword")}
          placeholder={t("user:oldPlainPassword")}
          data-testid={dataTestIdMap.oldPlainPasswordInput}
        />
        <PasswordInputController
          name="plainPassword"
          label={t("user:plainPassword")}
          placeholder={t("user:plainPassword")}
          helperText={t("user:plainPasswordRequirements")}
          data-testid={dataTestIdMap.plainPasswordInput}
        />
        <PasswordInputController
          name="plainPasswordRepeat"
          label={t("user:plainPasswordRepeat")}
          placeholder={t("user:plainPasswordRepeat")}
          data-testid={dataTestIdMap.plainPasswordRepeatInput}
        />
        <Button
          type="submit"
          isLoading={isLoading}
          className={classes.button}
          data-testid={dataTestIdMap.submitButton}
        >
          {t("save")}
        </Button>
      </Box>
    </FormProvider>
  )
}

export default ResetPasswordForm
