import {  createStyles, makeStyles,Theme } from "@material-ui/core"

import { colors } from "../../../themes/colors"

export const useVoucherCodeToBuyStyles = makeStyles((theme: Theme) => createStyles({
  boldText: {
    fontWeight: 700
  },
  selfCentered: {
    alignSelf: "center"
  },
  voucherCode: {
    fontSize: 44,
    fontWeight: 700
  },
  telemediGoVoucherCode: {
    fontSize: 44,
    fontWeight: 700,
    marginTop: 10,
  },
  greenColor: {
    color: "#96DCBB"
  },
  medicalPackageTitle: {
    marginTop: theme.spacing(1),
  },
  blueLogoDescription: {
    color: "#96DCBB",
    fontWeight: 400,
    fontSize: 16,
  },
  discounted: {
    color: colors.telemediGreen30,
    fontWeight: 700,
    fontSize: 94,
    [theme.breakpoints.only("md")]: {
      fontSize: 53,
      lineHeight: "14px",
    },
  },
  telemediGoDiscounted: {
    [theme.breakpoints.down(960)]: {
      fontSize: 53
    },
  },
  discountedCurrency: {
    fontSize: theme.spacing(4)
  },
  telemediGoDescriptionBox: {
    [theme.breakpoints.down(960)]: {
      textAlign: "center"
    },
  },
  buyButton: {
    color: colors.telemediBlue,
    background: theme.palette.common.white,
    border: theme.palette.common.white,
    maxWidth: 380,
  },
  stroke: {
    textDecoration: "line-through",
  },
  currency: {
    display: "inline-block",
    marginLeft: theme.spacing(0.2),
  },
  costText: {
    marginLeft: theme.spacing(2.5),
    [theme.breakpoints.only("md")]: {
      marginLeft: theme.spacing(0.5),
    },
  },
  checkIcon: {
    width: "22px",
    minWidth: "22px",
    height: "20px",
    borderRadius: "50%",
    position: "relative",
    marginRight: theme.spacing(1.5),

    "&.whiteVariant": {
      marginTop: "1px",
      backgroundColor: theme.palette.common.white,
    },
  },
  checkIconImg: {
    width: theme.spacing(2.5),
    fill: colors.telemediGreen30,
    position: "absolute",
    left: "2px",
    top: "-2px",

    "&.whiteVariant": {
      minWidth: theme.spacing(2),
      width: theme.spacing(2),
      top: "2px",
      left: "3px",
      fill: colors.telemediGreen30,
    },
  },
  sectionsContainer: {
    alignItems: "center",
    borderBottom: `1px solid rgba(${theme.palette.common.white}, .3)`,
    width: "100%",
    position: "relative",
  },
  borderBox: {
    border: `2px dashed ${theme.palette.common.white}`,
    borderRadius: theme.shape.borderRadius,
    boxSizing: "border-box",
    padding: theme.spacing(2.5),
  },
  detailsBox: {
    background: `linear-gradient(90deg, ${theme.palette.secondary.darker}, ${theme.palette.secondary.gradient})`,
    borderRadius: theme.shape.borderRadius,
    minHeight: "325px",
    padding: "2px",
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up("sm")]: {
      minHeight: "100%",
    },
  },
  leftSpacing: {
    marginLeft: "16px",
  },
  detailsContainer: {
    marginRight: "16px",
    height: "100%",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
      flexDirection: "row",
    },
  },
  telemediGoGridContainer: {
    flexDirection: "row",
    display: "flex",
    width: "auto",
    maxWidth: "100%",
    flexBasis: "fit-content",
    [theme.breakpoints.down(1360)]: {
      width: "auto"
    },
  },
  telemediGoDetailsContainer: {
    [theme.breakpoints.down(1360)]: {
      alignItems: "center",
      flexDirection: "column",
    },
    [theme.breakpoints.down(960)]: {
      marginRight: -12
    },
  },
  featureDescription: {
    [theme.breakpoints.down(1360)]: {
      display: "none"
    },
  },
  telemediGoLogoContainer: {
    [theme.breakpoints.down(960)]: {
      alignItems: "center"
    },
  },
  featureDescriptionMobile: {
    display: "none",
    [theme.breakpoints.down(1360)]: {
      display: "block",
      marginTop: 20,
      marginBottom: 20,
    },
    [theme.breakpoints.down(960)]: {
      marginTop: 0
    },
  },
  telemediGoCheckboxBox: {
    textAlign: "right",
    [theme.breakpoints.down(1360)]: {
      textAlign: "center"
    },
    [theme.breakpoints.down(960)]: {
      marginTop: 0
    },
  },
  telemediGoDiscountContainer: {
    display: "flex",
    flexDirection: "row",
    flexBasis: "unset",
    maxWidth: "unset",
    alignItems: "center",
    fontSize: 13,
    [theme.breakpoints.down(1360)]: {
      marginTop: 20
    },
    [theme.breakpoints.down(960)]: {
      marginTop: 0
    },
  },
  telemediGoCheckboxContainer: {
    maxWidth: "unset",
    flexBasis: "unset",
    marginTop: "auto",
    [theme.breakpoints.down(1360)]: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      width: "100%"
    },
  },
  greenTextColor: {
    color: "#96DCBB"
  },
  checkboxDescription: {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: "18px",
    maxWidth: 245,
    marginBottom: 20
  },
  fullWidthContainer: {
    width: "100%"
  },
  telemediGoBuyButton: {
    width: "100%"
  },
  telemediGoCheckboxDescription: {
    [theme.breakpoints.down(1360)]: {
      width: "100%",
      color: theme.palette.common.white,
      maxWidth: "unset"
    },
  },
}))