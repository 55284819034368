import React, {FC} from "react"
import {Box, Paper} from "@material-ui/core"

import MedicalQuestionnaireStepperForm
  from "./MedicalQuestionnaireStepperForm/MedicalQuestionnaireStepperForm.component"

const MedicalQuestionnairePage: FC = () => (
  <Box
    component={Paper}
    p={4}
  >
    <MedicalQuestionnaireStepperForm/>
  </Box>
)

export default MedicalQuestionnairePage
