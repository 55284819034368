import React from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"
import clsx from "clsx"

import {useDeclarationSignStyles} from "./DeclarationSign.styles"

const DeclarationSignSteps = () => {
  const {t} = useTranslation()
  const classes = useDeclarationSignStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.stepperWrapper}>
        <div>
          <div className={classes.header}>
            <Typography
              variant="h4"
            >
              {t("user:signUpTo")}
            </Typography>
            <Typography
              variant="h4"
              className={classes.ourClinic}
            >
              {t("user:ourClinic")}
            </Typography>
          </div>
        </div>
        <div className={classes.steps}>
          <Box className={classes.stepNumber}>1</Box>
          <Box className={classes.label}>{t("user:account")}</Box>
          <Box className={classes.stepNumber}>2</Box>
          <Box className={classes.label}>{t("user:data")}</Box>
          <Box className={classes.stepNumber}>3</Box>
          <Box className={classes.label}>{t("user:sign")}</Box>
          <Box className={clsx(classes.stepNumber, classes.stepActiveNumber)}>4</Box>
          <Box className={clsx(classes.label, classes.activeLabel)}>{t("user:confirmation")}</Box>
        </div>
      </Box>
    </Box>
  )
}

export default DeclarationSignSteps