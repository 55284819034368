import React, {FC} from "react"
import {useFormContext} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import { Box } from "@material-ui/core"

import {selectUser} from "../../../../../../../store/user/user.selectors"
import CheckboxController from "../../../../../../../components/CheckboxController/CheckboxController.component"
import ForeignWorker from "../../../../../../../components/SickLeaveSurvey/ForeignWorker.component"

const ForeignCompanyAddress: FC = () => {
  const {t} = useTranslation()
  const { setValue } = useFormContext()
  const userData = useSelector(selectUser)

  return (
    <Box
      mb={2}
    >
      <ForeignWorker
        setValue={setValue}
        nationality={userData?.nationality}
        countriesData={{}}
        checkboxes={
          <>
            <CheckboxController
              name='companyAddressConfirmation'
              label={t("consultation:foreignCompanyCheckbox1")}
              required={true}
            />
            <CheckboxController
              name='electronicSickLeaveAggreement'
              label={t("consultation:foreignCompanyCheckbox2")}
              required={true}
            />
          </>
        }
      />
    </Box>
  )
}

export default ForeignCompanyAddress
