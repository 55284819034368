import { gql } from "@apollo/client"

export const REGISTER_TO_HEALTH_PLANNER = gql`
  mutation Register($hasPozDeclaration: Boolean!) {
    register(has_poz_declaration: $hasPozDeclaration, platform: "web") {
      status
    }
  }  
`

export type RegisterResponse = {
  status: string;
}

export type RegisterVariables = {
  hasPozDeclaration: boolean;
}