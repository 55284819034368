import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import {Box, Typography, useMediaQuery,useTheme} from "@material-ui/core"

import {useRecommendationsSectionStyles} from "../recommendationsSection/RecommendationsSection.styles"

interface RecommendationsSectionProps {
  titleName?: string;
}

const RecommendationsSection: FC<RecommendationsSectionProps> = ({ titleName, children }) => {
  const { t } = useTranslation()
  const classes = useRecommendationsSectionStyles()

  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  return (
    <Box component="section" mb={isXs ? 0 : 3} className={classes.root}>
      { titleName && (
        <Box mb={2} className={classes.header}>
          <Typography
            variant="h5"
            component="h3"
          >
            { t(`recommendations:${ titleName }`) }
          </Typography>
        </Box>
      )}

      { children }
    </Box>
  )
}

export default RecommendationsSection
