import {useEffect, useState} from "react"

import api from "../api/api"
import {getDependentAccounts} from "../api/routes"
import {checkFeatureAvailability} from "../store/clinic/clinicSettings.utils"

export interface DependentAccount{
  first_name: string;
  last_name: string;
  email: string;
  pesel: string;
  birth_date: string;
  passport: string;
  policy_uid: string;
}

export const useDependentAccounts = () => {
  const [dependentAccounts, setDependentAccounts] = useState<DependentAccount[]>([])
  const [isLoading,setIsLoading] = useState<boolean>(true)
  const dependentAccountsEnabled = checkFeatureAvailability().dependentAccountsEnabled
  const hasDependentAccounts = dependentAccounts?.length > 0
    
  useEffect(() => {
    let isMounted = true

    if (!dependentAccountsEnabled){
      setIsLoading(false)
      return
    }

    fetchDependentAccounts()
      .then((data) => {
        isMounted && setDependentAccounts(data)
      })
      .finally(()=>{
        isMounted && setIsLoading(false)
      })
      
    return ()=>{isMounted = false}
  }, [dependentAccountsEnabled])

  const fetchDependentAccounts = async()=>{
    if (!dependentAccountsEnabled) return
      
    try {
      const resp = await api.request({
        ...getDependentAccounts,
      })
      return await resp.data
    } catch (e) {
      //console.error(e)
    }
  }
  
  return{
    dependentAccountsEnabled,
    isLoading,
    dependentAccounts,
    hasDependentAccounts,
  }
}