import { createStyles, makeStyles, Theme } from "@material-ui/core"

export const useMessengerTileStyles = makeStyles((theme: Theme) => createStyles({
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "382px",
    width: "100%",
    height: "100%",
    background: theme.palette.background.paper,
    overflow: "scroll",
    maxHeight: "320px",
    [theme.breakpoints.up("md")]: {
      height: "320px",
    },
  },
}))