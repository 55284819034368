import React, {forwardRef, PropsWithChildren, ReactNode} from "react"
import {Box, useTheme} from "@material-ui/core"

import PageSection from "../../common/pageSection/PageSection.component"

interface UserDataCardProps {
  title?: string;
  titleNode?: ReactNode;
}

const UserDataCard = forwardRef<HTMLDivElement,PropsWithChildren<UserDataCardProps>>(({
  title,
  titleNode,
  children,
}, ref) => {
  const theme = useTheme()

  return (
    <div ref={ref}>
      <PageSection title={title} titleNode={titleNode}>
        <Box
          mb={7}
          py={5.5}
          borderRadius={theme.shape.borderRadius}
          component="section"
          bgcolor={theme.palette.background.paper}
        >
          {children}
        </Box>
      </PageSection>
    </div>
  )
})

UserDataCard.displayName = "UserDataCard"

export default UserDataCard