import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router"
import {Box} from "@material-ui/core"

import AppDialog from "../../common/appDialog/AppDialog.component"
import {SetState} from "../../../types/SetState"

interface ConsultationChangeTermPopupProps {
  open: boolean;
  onClose(): void;
  setPopupOpen: SetState<boolean>;
  consultationId: string;
  termChangeSuccess: boolean;
  handleClosePopupOnSuccess(): void;
}

const ConsultationChangeTermPopup: FC<ConsultationChangeTermPopupProps> = ({
  open, 
  onClose, 
  setPopupOpen,
  consultationId,
  termChangeSuccess,
  handleClosePopupOnSuccess
}) => {
  const {t, i18n} = useTranslation()
  const { push } = useHistory()

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      closeButton={!termChangeSuccess}
      title={t(termChangeSuccess ? "consultation:changeTermReady" : "consultation:generalError")}
      closeButtonText={termChangeSuccess ? undefined : t("consultation:cancelVisit")}
      closeButtonProps={termChangeSuccess 
        ? undefined 
        : {
          onClick: () => push(`/${i18n.language}/consultation-change-term/${consultationId}?cancel=true`)
        }}
      actionButtonText={t(termChangeSuccess ? "consultation:changeTermOkButton" : "consultation:tryAgain")}
      actionButtonProps={{
        onClick: () => termChangeSuccess ? handleClosePopupOnSuccess() : setPopupOpen(false),
      }}
    >
      <Box
        mb={2}
      >
        {t(termChangeSuccess ? "consultation:changeTermSuccess" : "consultation:changeTermError")}
      </Box>
    </AppDialog>
  )
}

export default ConsultationChangeTermPopup
