import {makeStyles, Theme} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

interface Props {
  isOutdatedVariant?: boolean
}

export const useComingServiceDetailsStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    borderTopLeftRadius: 0,
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    paddingLeft: 0,
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(6),
      paddingTop: theme.spacing(4.5),
      paddingBottom: theme.spacing(4.5),
    },
  },
  hospitalImage: {
    padding: "10px",
  },
  flagIcon: {
    marginRight: theme.spacing(1),
  },
  sectionsContainer: {
    alignItems: "flex-start",
    borderBottom: (props: Props) => props.isOutdatedVariant
      ? `1px solid ${theme.palette.grey[300]}`
      : `1px solid rgba(${theme.palette.common.white}, .3)`,
    width: "100%",
    position: "relative",
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      paddingBottom: 0,
      borderBottom: () => 0,
      marginTop: theme.spacing(1),
      minHeight: theme.spacing(13),
      alignItems: "center",
    },
  },
  detailsBox: {
    background: (props: Props) => props.isOutdatedVariant
      ? theme.palette.common.white
      : `linear-gradient(90deg, ${theme.palette.secondary.darker}, ${theme.palette.secondary.gradient})`,
    borderRadius: theme.shape.borderRadius,
    minHeight: "325px",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up("sm")]: {
      minHeight: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: 188,
    },
  },
  detailsContent: {
    padding: 0,
  },
  detailsContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
      flexDirection: "row",
    },
  },
  detailsServicesContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
  textViolet: {
    color: `${theme.palette.info.main}!important;`,
  },
  consultationTypeIcon: {
    position: "relative",
    top: "-35px",
    left: "10px",
  },
  textWhite: {
    color: (props: Props) => props.isOutdatedVariant
      ? `${theme.palette.text.primary}!important`
      : `${theme.palette.common.white}!important`,
  },
  stationaryVisitAddress: {
    fontWeight: "bold",
  },
  consultationTypeName: {
    ...theme.typography.h5,
    lineHeight: `${theme.spacing(3)}px`, // equal to icon height
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1),
    },
  },
  buttonCancel: {
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2.5),
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center",
    },
  },
  changeOrderCol: {
    [theme.breakpoints.up("md")]: {
      minWidth: 85,
      order: 2,
    },
  },
  warningLabel: {
    minWidth: 105,
    position: "absolute",
    left: -24,
    top: -24,
    [theme.breakpoints.up("sm")]: {
      left: -24,
    },
    [theme.breakpoints.up("md")]: {
      position: "initial",
      marginBottom: theme.spacing(6.5),
    },
  },
  statusLabel:  {
    padding: `${theme.spacing(.5)}px ${theme.spacing(1)}px!important;`,
    textAlign: "center",
  },
  allianzIconColor: {
    color: "#13a0d3 !important"
  },
}))
