import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Button, Paper, Tab, Tabs, Typography} from "@material-ui/core"

import {useToggle} from "../../../../hooks/useToggle"
import LoaderBox from "../../../../components/common/loaderBox/LoaderBox.component"
import IconByIntegrationType from "../../../../components/iconByIntegrationType/IconByIntegrationType"
import PageWrapper from "../../components/pageWrapper/PageWrapper.component"
import AddDiseaseModalComponent from "./addDiseaseModal/AddDiseaseModal.component"
import DiseasesLists from "./diseasesLists/DiseasesLists.component"
import TabPanel from "./tabPanel/TabPanel.component"
import {usePreventionDiseasesManager, useTabs} from "./PreventionDiseases.utils"
import { usePreventionDiseasesStyles } from "./PreventionDiseases.styles"

const PreventionDiseases: FC = () => {
  const {t} = useTranslation()
  const classes = usePreventionDiseasesStyles()
  const { tabValue, handleChange } = useTabs()
  const [isOpen, { handleToggle }] = useToggle()
  const {
    isLoading,
    isErrorOccurred,
    diseases,
    userDiseases,
    userDiseasesProbability,
    userDiseasesToAdd,
  } = usePreventionDiseasesManager()

  if (isLoading) {
    return (
      <LoaderBox
        boxType="appDialog"
      />
    )
  }

  if (isErrorOccurred) {
    return (
      <Typography variant="body1">
        {t("errors:defaultError")}
      </Typography>
    )
  }

  return (
    <PageWrapper>
      <Box
        className={classes.container}
        component={Paper}
      >
        <Tabs
          color="primary"
          value={tabValue}
          variant="fullWidth"
          indicatorColor='primary'
          textColor='primary'
          onChange={handleChange}
        >
          <Tab
            label={t("healthPlanner:diseasesRisk")}
            value="1"
          />
          <Tab
            label={t("healthPlanner:myDiseases")}
            value="2"
          />
        </Tabs>
        <TabPanel
          tabValue={tabValue}
          value="1"
        >
          <Box className={classes.wrapper}>
            {
              userDiseasesProbability.length !== 0 && (
                <>
                  <Typography
                    className={classes.title}
                    variant="h4"
                  >
                    {t("healthPlanner:knownRisk")}
                  </Typography>
                  <DiseasesLists
                    className={classes.riskDiseases}
                    diseases={userDiseasesProbability}
                  />
                </>
              )
            }
            <Typography
              className={classes.title}
              variant="h4"
            >
              {t("healthPlanner:discoverDiseases")}
            </Typography>
            <DiseasesLists
              diseases={diseases}
            />
          </Box>
        </TabPanel>
        <TabPanel
          tabValue={tabValue}
          value="2"
        >
          <Box>
            <DiseasesLists
              className={classes.wrapper}
              diseases={userDiseases}
            />
            <Button
              className={classes.addButton}
              variant="outlined"
              color="primary"
              onClick={handleToggle(true)}
              startIcon={<IconByIntegrationType iconName={"icon-plus"}/>}
            >
              {t("healthPlanner:addDiseaseDialogTitle")}
            </Button>
            <AddDiseaseModalComponent
              isOpen={isOpen}
              diseases={userDiseasesToAdd}
              onClose={handleToggle(false)}
            />
          </Box>
        </TabPanel>
      </Box>
    </PageWrapper>
  )
}

export default PreventionDiseases