import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useAwardSuccessfullyReceivedModalStyles = makeStyles((theme: Theme) => createStyles({
  popup: {
    width: "520px",
    maxWidth: "100%",
    height: "unset",
    padding: `${theme.spacing(2)}px !important`,
    borderRadius: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      maxHeight: "unset",
      borderRadius: theme.spacing(2),
      marginTop: "unset",
    },
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 180,
    height: 130,
    minWidth: 180,
    overflow: "hidden",
    borderRadius: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      order: 1,
      width: 300,
      height: 220,
      minWidth: 300,
    },
  },
  image: {
    width: "100%",
    borderRadius: theme.spacing(1.5),
  },
  codeWrapper: {
    color: theme.palette.text.primary,
    margin: theme.spacing(2, 0),
    "& span": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(6, 0),
    },
  },
  button: {
    marginBottom: theme.spacing(2),
  },
}))
