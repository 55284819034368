import React, { FC, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MenuItem, StandardTextFieldProps, TextField} from "@material-ui/core"
import { CancelTokenSource } from "axios"

import api, { ApiInstance } from "../../../api/api"
import {getCountriesConfig, getPhoneCountryCodesConfig} from "../../../api/routes"
import {Countries} from "../countrySelect/Country.types"
import { PhoneCountryCodeOption, PhoneCountryCodes } from "./PhoneCountryCode.types"

interface PhoneCountryCodeSelectProps extends Partial<StandardTextFieldProps> {
  value: PhoneCountryCodeOption["value"] |null;
}

const PhoneCountryCodeSelect: FC<PhoneCountryCodeSelectProps> = ({ value, disabled, ...props}) => {
  const phoneCountryCodeInitialState: PhoneCountryCodeOption[] = value
    ? [{
      value,
      label: value,
    }]
    : []
  const [options, setOptions] = useState<PhoneCountryCodeOption[]>(phoneCountryCodeInitialState)
  const [loading, setLoading] = useState<boolean>(false)
  const {i18n} = useTranslation()

  const getPhoneCodeCountryName = (codeWithCountry: PhoneCountryCodes[keyof PhoneCountryCodes]) => {
    return codeWithCountry.replace(/\(.*\)/, "").trim()
  }

  const findCountryAbbreviation = (
    countries: Countries,
    codeWithCountry: PhoneCountryCodes[keyof PhoneCountryCodes]) => {
    const [countryAbbreviation] = Object.entries(countries).find(([, name]) => name === getPhoneCodeCountryName(codeWithCountry)) || [""]
    return countryAbbreviation
  }

  const convertToOptions = (countryCodes: PhoneCountryCodes, countries: Countries) => Object.entries(countryCodes).map(([, codeWithCountry]) => ({
    value: findCountryAbbreviation(countries, codeWithCountry),
    label: codeWithCountry,
  }))

  const getPhoneCountryCodes = async (cancelToken?: CancelTokenSource["token"]) => {
    const {data: {codes: countryCodes}} = await api.request<{codes: PhoneCountryCodes}>({
      ...getPhoneCountryCodesConfig(i18n.language),
      cancelToken,
    })
    return countryCodes
  }

  const getCountries = async (cancelToken?: CancelTokenSource["token"]) => {
    const {data: {countries}} = await api.request<{countries: Countries}>({
      ...getCountriesConfig(i18n.language),
      cancelToken,
    })
    return countries
  }

  const getPhoneCountryCodeOptions = useCallback(async (cancelToken?: CancelTokenSource["token"]) => {
    setLoading(true)
    try {
      const [countryCodes, countries] = await Promise.all([
        getPhoneCountryCodes(cancelToken),
        getCountries(cancelToken),
      ])
      setOptions(convertToOptions(countryCodes, countries))
      setLoading(false)
    } catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
    }
  }, [getPhoneCountryCodesConfig])

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()

    getPhoneCountryCodeOptions(requestSource.token)

    return () => {
      setOptions(phoneCountryCodeInitialState)
      requestSource.cancel("Request interrupted by page change")
    }
  }, [])

  return (
    <TextField
      value={value}
      select
      disabled={disabled || loading}
      {...props}
    >
      {options.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default PhoneCountryCodeSelect
