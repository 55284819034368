import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useWizard } from "react-use-wizard"
import { Button } from "@material-ui/core"

import ButtonLoader from "../../../../components/common/buttonLoader/ButtonLoader.component"
import { useNavigationButtonStyles } from "./NavigationButtons.styles"

type Props = {
  isNextButtonDisabled?: boolean;
  isLoading?: boolean;
  visitReason?: any;
}

const NavigationButtons: FC<Props> = ({ isNextButtonDisabled, isLoading, visitReason }) => {
  const { t } = useTranslation()
  const classes = useNavigationButtonStyles()
  const { isFirstStep, previousStep, goToStep } = useWizard()

  return (
    <div className={classes.container}>
      {
        !isFirstStep && (
          <Button
            type='button'
            variant='outlined'
            color='secondary'
            onClick={() => {
              if (visitReason == undefined) {
                previousStep()
              } else if (visitReason.isTalkWithDoctorNeeded
                && !visitReason.isPrescriptionNeeded
                && !visitReason.isSickLeaveNeeded
                && !visitReason.isReferralNeeded) {
                goToStep(0)
              } else {
                previousStep()
              }
            }
            }
          >
            {t("medicalQuestionnaire:previousStep")}
          </Button>
        )
      }
      <Button
        className={classes.nextButton}
        disabled={isLoading || isNextButtonDisabled}
        variant='contained'
        type='submit'
        color='primary'
        startIcon={isLoading && <ButtonLoader position="prefix" />}
      >
        {t("medicalQuestionnaire:nextStep")}
      </Button>
    </div>
  )
}

export default NavigationButtons
