export enum VoucherTypes {
  TYPE_TELEMEDI_GO = "TELEMEDI-GO",
  TYPE_MEDI_GO = "MEDI-GO",
}

export const isTelemediGO = (voucherCode: string): boolean => voucherCode === VoucherTypes.TYPE_TELEMEDI_GO

export const isMediGO = (voucherCode: string): boolean => voucherCode === VoucherTypes.TYPE_MEDI_GO

export const isSubscriptionVoucher = (voucherCode: string): boolean => Object.values(VoucherTypes).includes(voucherCode as VoucherTypes)

export const subscriptionTitleTranslationKey = (voucherCode: string): string => isTelemediGO(voucherCode) ? "finances:telemediSubscription" : isMediGO(voucherCode) ? "finances:mediGoSubscription" : ""

export const subscriptionDescriptionTranslationKey = (voucherCode: string): string => isTelemediGO(voucherCode) ? "finances:telemediSubscriptionInfo" : isMediGO(voucherCode) ? "finances:mediGoSubscriptionInfo" : ""
