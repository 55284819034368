import {DozRefundType, RequestedItem} from "../../store/doz/doz.types"
import {DozItem, RefoundationType} from "../prescription/Prescription.types"

const parseRefundType = (refundType: string) => {
  switch (refundType) {
    case RefoundationType.REFOUNDATION_100:
      return DozRefundType.REFOUNDATION_100
    case RefoundationType.REFOUNDATION_50:
      return DozRefundType.REFOUNDATION_50
    case RefoundationType.REFOUNDATION_30:
      return DozRefundType.REFOUNDATION_30
    case RefoundationType.REFOUNDATION_FLAT_RATE:
      return DozRefundType.REFOUNDATION_FLAT_RATE
    case RefoundationType.REFOUNDATION_FREE:
      return DozRefundType.REFOUNDATION_FREE
    default:
      return ""
  }
}

export const mapDozItemsToRequestedItemList = (dozItems: DozItem[]): RequestedItem[] => 
  dozItems.map(dozItem => ({
    ean: dozItem.ean,
    quantity: dozItem.quantity,
    quantity_description: dozItem.quantityDescription,
    refund_type: parseRefundType(dozItem.refundType)
  }))
