import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {Reducer} from "redux"

import {SessionState} from "../session/session.types"
import {UserDataState, UserDataType} from "./user.types"

const initialState: UserDataState = {
  userId: null,
  userData: null,
  loading: false,
  error: null,
  userAddressCoords: null,
  userAddressCoordsLoading: false,
  userAddressCoordsError: null,
  userAddressEditModalOpen: false,
  userRegulationsModalOpen: false,
  userForcePasswordChangeModalOpen: false,
  randomSessionId: null,
  isActiveDeclaration: false
}

const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    getUserData: (state: UserDataState, action: PayloadAction<Partial<SessionState>>) => {
      state.userId = action.payload.id!
      state.loading = true
      state.error = null
      return
    },
    setUserData: (state: UserDataState, action: PayloadAction<UserDataType>) => {
      return {
        ...state,
        userData: action.payload,
        loading: false,
        error: null,
      }
    },
    setUserDataError: (state: UserDataState, action: PayloadAction<UserDataState["error"]>) => {
      state.error = action.payload
      state.loading = false
      return
    },
    setPozDeclarationActiveStatus: (state: UserDataState) => {
      state.isActiveDeclaration = true
      return
    },
    getUserAddressCoords: (state: UserDataState) => {
      state.userAddressCoordsLoading = true
      return
    },
    setUserAddressCoords: (state: UserDataState, action: PayloadAction<google.maps.LatLngLiteral>) => {
      state.userAddressCoords = action.payload
      state.userAddressCoordsLoading = false
      state.userAddressCoordsError = null
    },
    setUserAddressCoordsError: (state: UserDataState, action: PayloadAction<UserDataState["userAddressCoordsError"]>) => {
      state.userAddressCoordsError = action.payload
      state.userAddressCoordsLoading = false
      return
    },
    setUserAddressEditModalOpen: (state: UserDataState, action: PayloadAction<boolean>) => {
      state.userAddressEditModalOpen = action.payload
    },
    setUserRegulationsModalOpen: (state: UserDataState, action: PayloadAction<boolean>) => {
      state.userRegulationsModalOpen = action.payload
    },
    setForcePasswordChange: (state, action: PayloadAction<boolean>) => {
      state.userForcePasswordChangeModalOpen = action.payload
    },
    clearUserData: (state: UserDataState) => {
      return { ...initialState, randomSessionId: state.randomSessionId }
    },
    setRandomSessionId: (state: UserDataState, action: PayloadAction<string>) => {
      state.randomSessionId = action.payload
    },
    clearRandomSessionId: (state: UserDataState) => {
      state.randomSessionId = null
    },
  }
})

export const {
  getUserData,
  setUserData,
  setUserDataError,
  setUserAddressEditModalOpen,
  clearUserData,
  setForcePasswordChange,
  getUserAddressCoords,
  setUserAddressCoords,
  setUserAddressCoordsError,
  setUserRegulationsModalOpen,
  setRandomSessionId,
  setPozDeclarationActiveStatus
} = userSlice.actions

export default userSlice.reducer as Reducer<typeof initialState>
