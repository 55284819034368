export const colors = {
  telemediGreen: "#20A869", // tele-green
  telemediGreenDark: "#0E7E4A", // tele-green-40
  telemediGreenDarker: "#085431", // tele-green-60
  telemediBlue: "#004EC3", // tele-blue
  telemediBlueLight: "#72A2EA",
  telemediBlueDark: "#164386", // tele-blue-40
  telemediBlueDarker: "#012760", // tele-blue-60
  telemediBlueGradient: "#0C408E",
  telemediViolet5: "#F5F6FF",
  telemediViolet10: "#C7CDFB",
  telemediViolet: "#7282F4",
  telemediTextPrimary: "#3C3F3D", // tele-black
  telemediGray60: "#787878",
  telemediGray40: "#A8A8A8",
  telemediGray20: "#C4C4C4",
  telemediGray15: "#EBE9E4",
  telemediGray12: "#EEEBE6",
  telemediGray10: "#EBEBEB",
  telemediGrayMapOverlay: "#F7F7F7",
  telemediBackground: "#ffffff",
  telemediPageBackground: "#F6F5F2", // tele-beige
  telemediAppBarBg: "#EFEDE9", // tele-beige-20
  telemediRed: "#B93C3C",
  telemediWarning: "#E67337",
  telemediGreen10: "#EEF9F4", // tele-green-10
  telemediGreen30: "#96DCBB", // tele-green-30
  telemediWhite: "#FFFFFF",
  telemediDetailLightBlue: "#007AB3"
}
