import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import {Box, Button, Typography} from "@material-ui/core"
import i18next from "i18next"

import {useAppSelector} from "../../../../hooks/storeHooks"
import useIsB2BClinic from "../../../../hooks/useIsB2BClinic"
import {selectClinicSettings} from "../../../../store/clinic/clinicSettings.selectors"
import {
  useOrderExaminationButton,
  useOrderMedicinesButton
} from "./ServicesToBuy.utils"
import {RoutePath} from "../../../../routes/Routes.types"
import {ConsultationExamReferral} from "../../../exam/Exam.types"
import {
  Doz,
  PrescriptionModel
} from "../../../prescription/Prescription.types"
import {useServicesToBuyStyles} from "./ServicesToBuy.styles"

interface Props {
  consultationId: string;
  prescriptionItems: PrescriptionModel[];
  examReferrals?: ConsultationExamReferral[];
  doz: Doz;
}

const ServicesToBuy: FC<Props> = ({
  consultationId,
  prescriptionItems,
  doz, 
  examReferrals = []
}) => {
  const classes = useServicesToBuyStyles()
  const {t, i18n} = useTranslation()
  const { price, shouldShowOrderExaminationButton } = useOrderExaminationButton(examReferrals)
  const { dozItemPrice, shouldShowOrderMedicinesButton } = useOrderMedicinesButton(prescriptionItems, doz)
  const {
    examCartSettings:{
      enableReferralBasketFeature
    },
    clinicSignalIdunaSettings: {
      dashboardLaboratoryModuleEnabled
    }
  } = useAppSelector(selectClinicSettings)

  const showExaminationButton =
      shouldShowOrderExaminationButton
      && dashboardLaboratoryModuleEnabled

  if ( !(showExaminationButton || shouldShowOrderMedicinesButton) )
  {
    return null
  }

  return (
    <Box className={classes.container}>
      {
        shouldShowOrderMedicinesButton && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              size="large"
              component={Link}
              to={
                `/${i18next.language}${RoutePath.ORDER_MEDICINES.replace(":consultationId", consultationId)}`
              }
            >
              {t("prescription:orderAndPickUp")}
            </Button>

            <Typography
              className={classes.text}
              variant="h4"
            >
              {t("consultation:buyFor")} {dozItemPrice} zł
            </Typography>
          </Box>
        )
      }
      {
        showExaminationButton && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              size="large"
              component={Link}
              to={
                enableReferralBasketFeature
                  ? `/${i18n.language}/order-examinations/${consultationId}`
                  : `/${i18n.language}/order-exams/${consultationId}`
              }
            >
              {t("exam:orderExams")}
            </Button>
            <Typography
              className={classes.text}
              variant="h4"
            >
              {t("consultation:buyFor")} {price} zł
            </Typography>
          </Box>
        )
      }
    </Box>
  )
}

export default ServicesToBuy
