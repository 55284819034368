import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useSessionManagerModalStyles = makeStyles((theme: Theme) => createStyles({
  secondsLeft: {
    display: "inline-block",
    minWidth: theme.spacing(4),
  }
}))
