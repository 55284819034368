import React, {FC} from "react"

type TabPanelProps = {
  tabValue: string;
  value: string;
}

const TabPanel: FC<TabPanelProps> = ({ 
  children,
  value, 
  tabValue
}) => {
  return (
    <div hidden={value !== tabValue}>
      {children}
    </div>
  )
}

export default TabPanel
