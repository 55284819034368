export enum MakeConsultationMenuItemValue {
  REMOTE_CONSULTATION = "consultationNestedMenu:remoteConsultationHeader",
  MEDICAL_EXAMINATION = "medicalExamination",
  ON_SITE_POZ_CONSULTATION = "onSitePozConsultation",
  STATIONARY_CONSULTATION = "consultationNestedMenu:stationaryVisitHeader",
  PRESCRIPTION = "consultationNestedMenu:prescriptionHeader",
  // PRESCRIPTION_EXTENSION = "consultationNestedMenu:prescriptionExtensionHeader", // LESS-2115 - Ukrycie w menu bocznym przedłużenie recepty
  SICK_LEAVE = "consultationNestedMenu:sickLeaveHeader",
  REFERRAL = "consultationNestedMenu:referralHeader",
  EMAIL_STATIONARY_CONSULTATION = "consultationNestedMenu:emailStationaryVisitHeader",
}

export enum MakeConsultationMenuItemLabel {
  REMOTE_CONSULTATION_LABEL = "consultationNestedMenu:remoteConsultationLabel",
  MEDICAL_EXAMINATION_LABEL = "consultationNestedMenu:examsLabel",
  ON_SITE_POZ_CONSULTATION_LABEL = "onSitePozConsultation",
  STATIONARY_CONSULTATION_LABEL = "consultationNestedMenu:stationaryVisitLabel",
  PRESCRIPTION_LABEL = "consultationNestedMenu:prescriptionLabel",
  // PRESCRIPTION_EXTENSION_LABEL = "consultationNestedMenu:prescriptionExtensionLabel", // LESS-2115 - Ukrycie w menu bocznym przedłużenie recepty
  SICK_LEAVE_LABEL = "consultationNestedMenu:sickLeaveLabel",
  REFERRAL_LABEL = "consultationNestedMenu:referralLabel",
  EMAIL_STATIONARY_CONSULTATION_LABEL = "consultationNestedMenu:emailStationaryVisitLabel",
}
