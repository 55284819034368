import {SelectInputProps} from "@material-ui/core/Select/SelectInput"

import {Answer, CompletedAnswer} from "../../../GenericStepForm.types"

export const usePicker = (
  onChange: (completedAnswer: CompletedAnswer[]) => void,
  answers: Answer[],
) => {
  const handleChange: SelectInputProps["onChange"] = (event) => {
    //Mui Select doesn't infer value type properly
    const id = event.target.value as string
    const chosenAnswer = answers.find(answer => answer.id === id)

    if (chosenAnswer) {
      onChange([{
        ...chosenAnswer,
        value: ""
      }])
    }
  }
  
  return {
    handleChange
  }
}
