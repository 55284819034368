import React, {FC} from "react"
import {Box, Typography} from "@material-ui/core"

import {useSickLeaveSectionStyles} from "./SickLeaveSurvey.styles"

interface SickLeaveSummaryItemProps {
  label: string;
  value: string|null
}

const SickLeaveSummaryItem: FC<SickLeaveSummaryItemProps> = ({label, value}) => {
  const classes = useSickLeaveSectionStyles()

  return (
    <Box>
      <Typography 
        variant="h5"
        component="span"
        className={classes.summaryLabel}
      >
        {label}
      </Typography>
      <Typography 
        className={classes.summaryValue}
      >
        {value}
      </Typography>
    </Box>
  )
}

export default SickLeaveSummaryItem
