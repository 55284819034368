import i18next from "i18next"
import * as yup from "yup"

import {QuestionAnswer, Sex} from "../../MedicalQuestionnaireStepperForm.types"

export const basicMedicalQuestionnaireSchema = () => yup.object().shape({
  weight: yup
    .number()
    .min(0, i18next.t("errors:minNumber", { number: 0 }))
    .max(1000, i18next.t("errors:maxNumber", { number: 1000 }))
    .required(i18next.t("errors:required")),
  height: yup
    .number()
    .min(0, i18next.t("errors:minNumber", { number: 0 }))
    .max(1000, i18next.t("errors:maxNumber", { number: 1000 }))
    .required(i18next.t("errors:required")),
  sex: yup
    .mixed<Sex>()
    .oneOf(Object.values(Sex))
    .required(i18next.t("errors:required")),
  isPregnant: yup
    .mixed<QuestionAnswer>()
    .when("sex", {
      is: Sex.FEMALE,
      then: yup
        .string()
        .required(i18next.t("errors:required")),
    }),
  isBreastFeeding: yup
    .mixed<QuestionAnswer>()
    .when("sex", {
      is: Sex.FEMALE,
      then: yup
        .string()
        .required(i18next.t("errors:required")),
    }),
  hasChronicDiseases: yup
    .mixed<QuestionAnswer>()
    .required(i18next.t("errors:required")),
  isTakingAnyMedicines: yup
    .mixed<QuestionAnswer>()
    .required(i18next.t("errors:required")),
  hasAnyAllergies: yup
    .mixed<QuestionAnswer>()
    .required(i18next.t("errors:required")),
  visitReason: yup
    .object()
    .test(
      "isAnyReasonSelected",
      i18next.t("errors:min1OptionSelected"),
      (value) => {
        return Object.values(value).some((reason) => reason)
      }
    )
})
