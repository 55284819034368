import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

export const useConsultationChannelChangeStyles = makeStyles((theme: Theme) =>
  createStyles({
    consultationIconBox: {
      borderRadius: "50%",
      border: `2px solid ${theme.palette.primary.main}`,
      padding: "12px 12px",
      marginRight: theme.spacing(2),
      cursor: "pointer",
    },
    allianzIconBorder: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    consultationIcon: {
      color: theme.palette.primary.main,
      marginRight: 0,
    },
    consultationIconSelected: {
      background: "#CBF1E0",
    },
    iconsContainer: {
      alignSelf: "flex-start",
    }
  })
)
