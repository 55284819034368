import React, {FC} from "react"
import {Box, CircularProgress, CircularProgressProps, useTheme} from "@material-ui/core"
import clsx from "clsx"

import {useButtonLoaderStyles} from "./ButtonLoader.styles"

interface ButtonLoaderProps {
  boxClassName?: string;
  position?: "prefix" | "suffix"
}

const ButtonLoader: FC<ButtonLoaderProps & CircularProgressProps> = (
  {
    boxClassName,
    position = "suffix",
    ...props
  }
) => {
  const theme = useTheme()
  const classes = useButtonLoaderStyles()

  const defaultProps = {
    size: theme.spacing(1.5),
    thickness: 5
  }

  return (
    <Box
      mr={position === "prefix" ? 1 : 0}
      ml={position === "suffix" ? 1 : 0}
      className={clsx(
        classes.root,
        boxClassName,
      )}
    >
      <CircularProgress
        {...defaultProps}
        {...props}
        color="inherit"
      />
    </Box>
  )
}

export default ButtonLoader
