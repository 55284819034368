import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Box, Button } from "@material-ui/core"

import ButtonLoader from "../../../../../../components/common/buttonLoader/ButtonLoader.component"
import { RoutePath } from "../../../../../../routes/Routes.types"
import { HealthPreventionPrograms } from "../../../../../../store/clinic/clinicSettings.types"
import { useActionButtonStyles } from "./ActionButtons.styles"

type ActionButtonsProps = {
  onClick: VoidFunction;
  isLoading?: boolean;
  actionButtonLabel?: string|null;
  programType?: HealthPreventionPrograms|null;
}

const ActionButtons: FC<ActionButtonsProps> = ({
  onClick,
  isLoading,
  actionButtonLabel,
  programType
}) => {
  const classes = useActionButtonStyles()
  const { t } = useTranslation()
  const isMentalHealthProgram = programType === HealthPreventionPrograms.PROGRAM_MENTAL_HEALTH

  const getBackBtnLabel = () => {
    return isMentalHealthProgram 
      ? "goToHomepage" 
      : "healthPlanner:backToProphylaxis"
  }

  const getRedirectRoute = () => {
    return isMentalHealthProgram 
      ? RoutePath.MENTAL_HEALTH 
      : RoutePath.PREVENTION
  }

  return (
    <Box className={classes.buttonWrapper}>
      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={isLoading}
        startIcon={isLoading && <ButtonLoader position="prefix"/>}
      >
        {actionButtonLabel ?? t("healthPlanner:takeAction")}
      </Button>
      <Button
        component={Link}
        to={getRedirectRoute()}
        variant="outlined"
        color="secondary"
        disabled={isLoading}
      >
        {t(getBackBtnLabel())}
      </Button>
    </Box>
  )
}

export default ActionButtons