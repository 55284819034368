import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

interface Props {
  customMainColor?: string;
  customHoverColor?: string;
}

export const useButtonTextUnderlinedStyles = makeStyles<Theme>((theme) => createStyles({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "13px",
    lineHeight: "16px",
    textDecoration: "underline",
    textAlign: "left",
    "&:disabled": {
      color: theme.palette.grey[400],
    },
  },
  noUnderline: {
    textDecoration: "none",
  },
  whiteOnBlue: {
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      color: theme.palette.info.main,
    },
  },
  primary: {
    color: (props: Props) => props.customMainColor || theme.palette.primary.dark,
    "&:hover, &:focus": {
      color: (props: Props) => props.customHoverColor || theme.palette.primary.darker,
    },
  },
  secondary: {
    color: theme.palette.secondary.dark,
    "&:hover, &:focus": {
      color: theme.palette.secondary.darker,
    },
  },
}))
