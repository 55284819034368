import React, {FC, MouseEvent} from "react"
import {Box, Typography} from "@material-ui/core"
import clsx from "clsx"

import {ReactComponent as CheckIcon} from "../../../../assets/svg/check-fill.svg"
import ImageByIntegrationType from "../../../imageByIntegrationType/ImageByIntegrationType"
import {useNfzStatusSwitchStyles} from "./NfzStatusSwitch.styles"

interface NfzStatusSwitchProps {
  isOn: boolean;
  clickable?: boolean;
  onClick(event: MouseEvent<HTMLElement>): void;
}

const NfzStatusSwitch: FC<NfzStatusSwitchProps> = ({
  isOn,
  clickable = false,
  onClick,
}) => {
  const classes = useNfzStatusSwitchStyles()

  return (
    <label
      className={clsx(
        classes.root,
        isOn ? classes.isOn : classes.isOff,
        clickable && classes.clickable,
      )}
      onClick={onClick}
    >
      <input
        defaultChecked={isOn}
        className={classes.switchCheckbox}
        type="checkbox"
      />

      <Box
        position="absolute"
        top="0"
        bottom="0"
        height="100%"
        display="flex"
        alignItems="center"
        className={classes.switchText}
      >
        <Typography variant={"body2"}>NFZ</Typography>
      </Box>

      <Box
        position="absolute"
        top="0"
        bottom="0"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.switchIndicator}
      >
        <ImageByIntegrationType
          imageSrc={<CheckIcon className={classes.switchIcon}/>}
          imagePath={"check-fill.svg"}
          className={classes.switchIcon}
          returnSvg={true}
        />
      </Box>
    </label>
  )
}

export default NfzStatusSwitch
