import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import { Typography } from "@material-ui/core"
import { Lock } from "phosphor-react"

import { ALLIANZ_EWKA_INTEGRATION_TYPE, ALLIANZ_ONYX_INTEGRATION_TYPE } from "../../../utils/getIntegrationType"
import { useSafeDataInfoLabel } from "./SafeDataInfoLabel.styles"

interface SafeDataInfoLabelProps {
  integrationType?: number|null
}

const SafeDataInfoLabel: FC<SafeDataInfoLabelProps> = ({ integrationType }) => {
  const { t } = useTranslation()
  const classes = useSafeDataInfoLabel()
  let translationKeyPrefix = "defaultTranslations:"
  
  if (
    integrationType === ALLIANZ_ONYX_INTEGRATION_TYPE 
      || integrationType === ALLIANZ_EWKA_INTEGRATION_TYPE
  ) {
    translationKeyPrefix = `${translationKeyPrefix}onyx:`
  }

  return (
    <Typography
      variant={"body2"}
      color={"textSecondary"}
      className={classes.root}
    >
      <Lock
        weight="fill"
        className={classes.icon}
      />

      { t(`${translationKeyPrefix}safeDataInfoLabel`) }
    </Typography>
  )
}

export default SafeDataInfoLabel
