import {createSlice, PayloadAction, Reducer} from "@reduxjs/toolkit"

import { getUniqueItemsByKey } from "../../utils/getUniqueItems"
import {ConsultationsState} from "./consultations.types"

const initialState: ConsultationsState = {
  coming: [],
  latest: [],
  comingTotal: 0,
  latestTotal: 0,
  cancelledVisible: false,
  selectedConsultationLang: null,
}

export const consultationsSlice = createSlice({
  name: "consultations",
  initialState,
  reducers: {
    setSelectedConsultationLang: (state: ConsultationsState, action: PayloadAction<ConsultationsState["selectedConsultationLang"]>) => {
      state.selectedConsultationLang = action.payload
    },
    setComingConsultations: (state: ConsultationsState, action: PayloadAction<ConsultationsState["coming"]>) => {
      state.coming = action.payload
      return
    },
    setLatestConsultations: (state: ConsultationsState, action: PayloadAction<ConsultationsState["latest"]>) => {
      state.latest = action.payload
      return
    },
    addComingConsultations: (state: ConsultationsState, action: PayloadAction<ConsultationsState["coming"]>) => {
      state.coming = getUniqueItemsByKey([...state.coming, ...action.payload], "id")
      return
    },
    addLatestConsultations: (state: ConsultationsState, action: PayloadAction<ConsultationsState["latest"]>) => {
      state.latest = getUniqueItemsByKey([...state.latest, ...action.payload], "id")
      return
    },
    setComingConsultationsTotal: (state: ConsultationsState, action: PayloadAction<ConsultationsState["comingTotal"]>) => {
      state.comingTotal = action.payload
    },
    setLatestConsultationsTotal: (state: ConsultationsState, action: PayloadAction<ConsultationsState["latestTotal"]>) => {
      state.latestTotal = action.payload
    },
    setCancelledVisible: (state: ConsultationsState, action: PayloadAction<ConsultationsState["cancelledVisible"]>) => {
      state.cancelledVisible = action.payload
      return
    },
    clearComingConsultations: (state: ConsultationsState) => {
      state.coming = initialState.coming
      state.comingTotal = initialState.comingTotal
      return
    },
    clearLatestConsultations: (state: ConsultationsState) => {
      state.latest = initialState.latest
      state.latestTotal = initialState.latestTotal
      return
    },
    clearSelectedConsultationLang: (state: ConsultationsState) => {
      state.selectedConsultationLang = null
    }
  },
})

export const {
  setSelectedConsultationLang,
  setComingConsultations,
  setLatestConsultations,
  addComingConsultations,
  addLatestConsultations,
  setComingConsultationsTotal,
  setLatestConsultationsTotal,
  setCancelledVisible,
  clearComingConsultations,
  clearLatestConsultations,
  clearSelectedConsultationLang,
} = consultationsSlice.actions

export default consultationsSlice.reducer as Reducer<typeof initialState>
