import React, {FC, useState} from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {useHistory} from "react-router"
import {add, parseISO} from "date-fns"

import useGoToConsultationPage from "../../../hooks/useGoToConsultationPage"
import { analytics, LogEventType } from "../../../services/analytics"
import {selectClinicSettings} from "../../../store/clinic/clinicSettings.selectors"
import {selectHasUserCompletePersonalData} from "../../../store/user/user.selectors"
import useImageByIntegrationType from "../../imageByIntegrationType/useImageByIntegrationType"
import AppDialog from "../../common/appDialog/AppDialog.component"
import ComingServiceDetails from "../../services/comingServiceDetails/ComingServiceDetails.component"
import CancelConsultationPopup from "../cancelConsultationPopup/CancelConsultationPopup.component"
import {
  getAvatarUrl,
  getComingConsultationEventDescription,
  getComingConsultationEventTitle,
  getComingConsultationStatusLabel,
  getPrescriptionConsultationIdParam,
  getStationaryVisitAddress,
  isAfterConsultationStartDate,
  isStationaryConsultation,
  isStationaryNonIntegrated, isStationaryVisitConsultation,
} from "../Consultation.utils"
import {ConsultationWidgetVisitDatePicker} from "../../../store/makeConsultationWidget/makeConsultationWidget.types"
import {AddToCalendarEvent} from "../../common/addToCalendar/AddToCalendar.types"
import {DoctorAdditionalData, DoctorSpecializationModel} from "../../doctor/Doctor.types"
import {
  ConsultationStatusNumber,
  ConsultationType,
  OrderedSickLeaveDetails,
  StationaryVisitAddress
} from "../Consultation.types"
import {useConsultationChangesStyles} from "../../../pages/consultationChanges/ConsultationChanges.styles"

const NOT_DEFAULT_TIME_CONSULTATION_SPECIALIZATION_IDS = [
  "a63ca073-6642-4197-b72b-0e4bf44cd0f5", // psychiatra
  "7d616ba7-322e-4d43-85f5-732d26ea6c30", // psycholog
  "6ac838d9-84b8-46dc-a8a7-edf6090f882a", // psycholog dziecięcy
  "297eb31f-da37-410e-96e9-7e4b8a06566a", // psychoterapia
  "9e6d720e-bd3b-4c45-840a-9cca5476e202", // psycholog 60 min
  // future backlog: psycholog dziecięcy 60 min
]

interface ComingConsultationDetailsProps {
  //from ConsultationProps
  id: string;
  consultationType: ConsultationType;
  consultationLanguage: string;
  doctorId: string;
  doctorNameWithDegree: string;
  patientId: string;
  doctorSpecialization: DoctorSpecializationModel;
  visitAt: string;
  doctor?: DoctorAdditionalData; // TODO: remove "?" when model is updated on production
  isPayed: boolean;
  paymentLink?: URL["href"];
  refreshConsultations(): void;
  statusNumber: ConsultationStatusNumber;
  stationaryVisitAddress?: StationaryVisitAddress;
  isInterestedInPoz?: boolean;
  drugNames?: string[];
  orderedSickLeave?: OrderedSickLeaveDetails;
  healthPlannerSurveyFilled?: boolean;
  prescriptionQuestionnaireFilled?: boolean;
  sickLeaveQuestionnaireFilled?: boolean;
  isInterestedInTelemediGo?: boolean;
  isMedicalSurveyFilled?: boolean;
  isPatientMedicalSurveyRequired?: boolean;
}

const ComingConsultationDetails: FC<ComingConsultationDetailsProps> = ({
  id,
  doctor,
  doctorNameWithDegree: doctorTitle,
  doctorSpecialization,
  visitAt,
  consultationType,
  isPayed,
  paymentLink,
  refreshConsultations,
  statusNumber,
  stationaryVisitAddress,
  consultationLanguage,
  isInterestedInPoz,
  drugNames,
  orderedSickLeave,
  healthPlannerSurveyFilled,
  isInterestedInTelemediGo,
  sickLeaveQuestionnaireFilled,
  prescriptionQuestionnaireFilled,
  isMedicalSurveyFilled,
  isPatientMedicalSurveyRequired,
  doctorId
}) => {
  const { t, i18n } = useTranslation()
  const {push} = useHistory()
  const hasUserCompletePersonalData = useSelector(selectHasUserCompletePersonalData)
  const clinicSettings = useSelector(selectClinicSettings)
  const { goToMakeConsultationPage } = useGoToConsultationPage({
    visitDatePicker: ConsultationWidgetVisitDatePicker.PRESCRIPTION_ONLY
  }, getPrescriptionConsultationIdParam(id))
  const {imageSrcState} = useImageByIntegrationType("stationaryVisit.png")

  const [cancelSickLeaveConsultationPopupOpen, setCancelSickLeaveConsultationPopupOpen] = useState(false)
  const [isCancelConsultationModalOpen, setIsCancelConsultationModalOpen] = useState<boolean>(false)
  const cancelConsultation = () => {
    setIsCancelConsultationModalOpen(true)
  }

  const enableHealthPlannerQuestionnaire = clinicSettings?.frontendSettings?.enableHealthPlannerQuestionnaire
  const isPrescriptionOnly = consultationType === ConsultationType.PRESCRIPTION_ONLY
  const isSickLeave = consultationType === ConsultationType.SICK_LEAVE
  const consultationDate = null !== visitAt ? parseISO(visitAt) : null
  const consultationHour = t("dates:time", { date: consultationDate })
  const showGoToPrescriptionSurveyButton = statusNumber === ConsultationStatusNumber.RESERVED && isPrescriptionOnly
  const showGoToSickLeaveSurveyButton = statusNumber === ConsultationStatusNumber.RESERVED && isSickLeave
  const showGoToHealthPlannerSurvey = isInterestedInPoz && !healthPlannerSurveyFilled && statusNumber < ConsultationStatusNumber.STARTED && consultationType < ConsultationType.QUESTION && enableHealthPlannerQuestionnaire
  const canCancelConsultation = statusNumber !== ConsultationStatusNumber.STARTED && !isAfterConsultationStartDate(consultationDate) && !isSickLeave || isSickLeave && (statusNumber < ConsultationStatusNumber.CONFIRMED)
  const showCancelButton = (canCancelConsultation && (consultationType !== ConsultationType.ON_SITE && !isPrescriptionOnly || consultationType === ConsultationType.ON_SITE && isInterestedInPoz || isPrescriptionOnly && statusNumber === ConsultationStatusNumber.RESERVED)) && !isStationaryVisitConsultation(consultationType, stationaryVisitAddress)
  const classes = useConsultationChangesStyles()
  const isInterestedInTelemediGoCancelPopupParams = {
    title: t("consultation:isInterestedInTelemediGoCancelPopupConfirm"),
    label: t("consultation:isInterestedInTelemediGoCancelPopupLabel"),
    hideButtonIcon: true,
    confirmLabel: t("consultation:isInterestedInTelemediGoCancelPopupCancel"),
    cancelLabel: t("defaultTranslations:close")
  }

  const getConsultationDateLabel = (): string => {
    let translationKey = "dates:consultationDayWithDate"

    if (isStationaryVisitConsultation(consultationType, stationaryVisitAddress)) {
      translationKey = "dates:consultationDayWithFullYearDate"
    }

    return t(translationKey, { date: consultationDate })
  }

  const getConsultationDurationMin = (specializationId: string): number => {
    switch (specializationId) {
      case "a63ca073-6642-4197-b72b-0e4bf44cd0f5": // psychiatra
      case "7d616ba7-322e-4d43-85f5-732d26ea6c30": // psycholog
      case  "6ac838d9-84b8-46dc-a8a7-edf6090f882a": // psycholog dziecięcy
        return 30
      case"297eb31f-da37-410e-96e9-7e4b8a06566a": // psychoterapia
      case "9e6d720e-bd3b-4c45-840a-9cca5476e202": // psycholog 60 min
        // future backlog: psycholog dziecięcy 60 min
        return 60
      default:
        return 10
    }
  }

  const handlePrescriptionActionButtonClick = (drugNames?: string[]) => {
    if (drugNames) {
      push(`${i18n.language}/medical-survey/${id}`)
    } else {
      goToMakeConsultationPage()
    }
  }

  const handlePayActionButtonClick = (paymentLink: string) => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_CONSULTATION_PAY_BUTTON_CLICK)
    window.location.href = paymentLink
  }

  const handleFillUserDataActionButtonClick = (consultationUrl: string) => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_CONSULTATION_FILL_DATA_BUTTON_CLICK);
    (isPrescriptionOnly || isSickLeave) ? push(getRedirectUrl(consultationType)) : push(consultationUrl)
  }

  const handleEnterActionButtonClick = (consultationUrl: string) => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_CONSULTATION_ENTER_BUTTON_CLICK)
    push(consultationUrl)
  }

  const isStationaryVisit = isStationaryVisitConsultation(consultationType, stationaryVisitAddress)

  const consultationDuration = getConsultationDurationMin(doctorSpecialization.id)
  const consultationEndTime = NOT_DEFAULT_TIME_CONSULTATION_SPECIALIZATION_IDS.includes(doctorSpecialization.id)
    ? add(consultationDate, { minutes: consultationDuration })
    : undefined

  const calendarEvent: AddToCalendarEvent = {
    title: t(`consultation:${getComingConsultationEventTitle(consultationType, isStationaryVisit)}`, { specializationName: doctorSpecialization.name }),
    description: t(`consultation:${getComingConsultationEventDescription(consultationType, isStationaryVisit)}`, {
      doctorTitle,
      consultationDuration,
    }),
    location: isStationaryVisit ? getStationaryVisitAddress(stationaryVisitAddress) : "",
    startTime: consultationDate,
    endTime: consultationEndTime,
  }

  const getRedirectUrl = (consultationType: number): string => {
    switch(consultationType) {
      case ConsultationType.PRESCRIPTION_ONLY:
        return `${i18n.language}/medical-survey/${id}`
      case ConsultationType.SICK_LEAVE:
        return `${i18n.language}/sick-leave-survey/${id}`
      default:
        return `${i18n.language}/consultation-payment-successful/${id}`
    }
  }

  const getActionButtonData = () => {
    const goToConsultationUrl = `/${i18n.language}/consultation/${id}`
    const redirectUrl = getRedirectUrl(consultationType)
    const paymentLinkWithRedirect = paymentLink
      ? `${paymentLink}?redirect-url=${window.location.origin}/${redirectUrl}`
      : `/${i18n.language}`

    if (!isPayed) {
      return {
        label: t("consultation:pay"),
        actionButtonOnClick: () => handlePayActionButtonClick(paymentLinkWithRedirect),
      }
    }
    else if (!hasUserCompletePersonalData) {
      return {
        label: t("consultation:noPersonalDataBtn"),
        actionButtonOnClick: () => handleFillUserDataActionButtonClick(goToConsultationUrl)
      }
    }
    else if (!isMedicalSurveyFilled && isPatientMedicalSurveyRequired) {
      return {
        label: t("prescription:completeSurvey"),
        actionButtonOnClick: () => push(redirectUrl)
      }
    }
    else if (showGoToHealthPlannerSurvey) {
      return {
        label: t("prescription:completeSurvey"),
        actionButtonOnClick: () => push(`${i18n.language}/consultation-survey/${id}`)
      }
    }
    else if (showGoToPrescriptionSurveyButton && !prescriptionQuestionnaireFilled) {
      return {
        label: t("prescription:completeSurvey"),
        actionButtonOnClick: () => handlePrescriptionActionButtonClick(drugNames)
      }
    }
    else if (showGoToSickLeaveSurveyButton && !sickLeaveQuestionnaireFilled) {
      return {
        label: t("prescription:completeSurvey"),
        actionButtonOnClick: () => push(`${i18n.language}/sick-leave-survey/${id}`)
      }
    }
    else if (isStationaryConsultation(consultationType) || isStationaryNonIntegrated(consultationType)) {
      return {
        label: t("consultation:details"),
        actionButtonOnClick: () => push(goToConsultationUrl)
      }
    }
    else if (isPrescriptionOnly || isSickLeave) {
      return {
        label: t("consultation:enter"),
        actionButtonOnClick: () => handleEnterActionButtonClick(goToConsultationUrl)
      }
    }

    return {
      label: t("consultation:enter"),
      actionButtonOnClick: () => handleEnterActionButtonClick(goToConsultationUrl)
    }
  }

  const getDetailsTitle = (): string => doctorTitle

  const cancelConsultationOnSuccess = () => {
    if (!isSickLeave) {
      refreshConsultations()

      return
    }

    setCancelSickLeaveConsultationPopupOpen(true)
  }

  const getLabelText = () => {
    if (isPrescriptionOnly) {
      return t("consultation:prescription")
    } else if (isSickLeave) {
      return t("consultation:sickLeaveSpec")
    }

    return doctorSpecialization?.name
  }

  return (
    <>
      <ComingServiceDetails
        id={id}
        labelText={getLabelText()}
        statusLabel={
          getComingConsultationStatusLabel(
            statusNumber,
            isPayed,
            hasUserCompletePersonalData,
            isPrescriptionOnly,
            isAfterConsultationStartDate(consultationDate),
            isSickLeave,
            showGoToHealthPlannerSurvey,
          )}
        doctorId={doctorId}
        avatarSrc={getAvatarUrl(doctor?.avatar, consultationType, stationaryVisitAddress, doctorId, imageSrcState)}
        avatarAlt={doctorTitle}
        detailsTitle={getDetailsTitle()}
        consultationType={consultationType}
        dateWithDay={getConsultationDateLabel()}
        hour={consultationHour}
        event={calendarEvent}
        actionButtonData={getActionButtonData()}
        cancelButtonOnClick={cancelConsultation}
        showAdditionalGoToConsultationBtn={showGoToHealthPlannerSurvey}
        stationaryVisitAddress={stationaryVisitAddress}
        consultationLanguage={consultationLanguage}
        showCancelButton={showCancelButton}
        isInterestedInPoz={isInterestedInPoz}
        drugNames={drugNames}
        orderedSickLeave={orderedSickLeave}
        statusNumber={statusNumber}
        isSickLeave={isSickLeave}
        isPrescriptionOnly={isPrescriptionOnly}
      />

      <AppDialog
        open={cancelSickLeaveConsultationPopupOpen}
        title={t("consultation:cancelConsultationPopup:title")}
        onClose={() => {
          setCancelSickLeaveConsultationPopupOpen(false)
          refreshConsultations()
        }}
        closeButtonText={t("close")}
      >
        {t("consultation:sickLeave:cancelSuccess")}
      </AppDialog>

      {
        showCancelButton && (
          <CancelConsultationPopup
            open={isCancelConsultationModalOpen}
            consultationId={id}
            onClose={() => {setIsCancelConsultationModalOpen(false)}}
            onSuccess={cancelConsultationOnSuccess}
            dialogCustomParams={isInterestedInTelemediGo ? isInterestedInTelemediGoCancelPopupParams : null}
            containerCustomClass={isInterestedInTelemediGo ? classes.cancelPopupContent : null}
          />
        )
      }
    </>
  )
}

export default ComingConsultationDetails
