import React, {Dispatch, FC, SetStateAction} from "react"
import {useTranslation} from "react-i18next"
import {Box, ListItemIcon,Tooltip, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import clsx from "clsx"

import { analytics, LogEventType } from "../../../services/analytics"
import ButtonTextUnderlined from "../../common/buttonTextUnderlined/ButtonTextUnderlined.component"
import { usePrescriptionStyles } from "../Prescription.styles"

interface PrescriptionHeaderProps {
  packageCode: string;
  setShowPrescriptionsPopup: Dispatch<SetStateAction<boolean>>;
}

const PrescriptionHeader: FC<PrescriptionHeaderProps> = ({packageCode,setShowPrescriptionsPopup}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isSmUp = useMediaQuery((theme.breakpoints.up("sm")))
  const classes = usePrescriptionStyles()

  const handleShowPrescriptionPopup = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_CONSULTATION_HOW_IT_WORKS_LINK_CLICK)
    setShowPrescriptionsPopup(true)
  }

  return (
    <Box 
      display="flex" 
      flexWrap="nowrap"
      width="100%"
      justifyContent="space-between"
    >
      <Box
        display="flex"
      >
        <Typography
          variant={isSmUp ? "h3": "h5"}
          component="h3"
          style={{
            display: isSmUp ? "inline" : "block"
          }}
        >
          {t("prescription:prescription")}
        </Typography>
        <Typography
          variant={isSmUp ? "body1" : "body1"}
          component="span"
          color="textSecondary"
          className={classes.codeLabel}
        >
          { packageCode &&
            `${t("prescription:code")}: ${packageCode}`
          }
        </Typography>
        {
          isSmUp && (
            <Box flexWrap="nowrap">
              <Tooltip
                title={
                  <Typography
                    variant="body2"
                    className={classes.tooltipText}
                  >
                    {t("prescription:tooltipInfo")}
                  </Typography>
                }
                classes={{
                  tooltip: classes.tooltip,
                }}
              >
                <ListItemIcon>
                  <span className={clsx(classes.icon, "icon-info")}/>
                </ListItemIcon>
              </Tooltip>
            </Box>
          )
        }
      </Box>
      {
        isSmUp && (
          <Box
            className={classes.popupOpenBtn}
          >
            <ButtonTextUnderlined
              color="primary"
              onClick={handleShowPrescriptionPopup}
            >
              {t("prescription:howItWorks")}
            </ButtonTextUnderlined>
          </Box>
        )
      }
    </Box>
  )
}

export default PrescriptionHeader
