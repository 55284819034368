import React, { FC, useCallback, useEffect, useState } from "react"
import { MenuItem, StandardTextFieldProps, TextField} from "@material-ui/core"
import { CancelTokenSource } from "axios"

import api, { ApiInstance } from "../../../api/api"
import { getTimeZoneOptionsConfig } from "../../../api/routes"
import { TimeZoneOption, TimeZones } from "./TimeZoneSelect.types"

interface TimeZoneSelectProps extends Partial<StandardTextFieldProps> {
  value: TimeZoneOption|null;
}

const TimeZoneSelect: FC<TimeZoneSelectProps> = ({ value, disabled,  ...props}) => {
  const timeZoneInitialState = value ? [value] : []
  const [options, setOptions] = useState<TimeZoneOption[]>(timeZoneInitialState)
  const [loading, setLoading] = useState<boolean>(false)

  const convertToOptions = (timeZones: TimeZones) => Object.values(timeZones).reduce<TimeZoneOption[]>((options, capitalCities) => {
    const continentOptions = Object.keys(capitalCities)
    options = [...options, ...continentOptions]
    return options
  }, [])

  const getTimeZoneOptions = useCallback(async (cancelToken?: CancelTokenSource["token"]) => {
    setLoading(true)
    try {
      const {data: timeZones} = await api.request<TimeZones>({
        ...getTimeZoneOptionsConfig,
        cancelToken,
      })
      setOptions(convertToOptions(timeZones))
      setLoading(false)
    } catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
    }
  }, [getTimeZoneOptionsConfig])

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()

    getTimeZoneOptions(requestSource.token)

    return () => {
      setOptions(timeZoneInitialState)
      requestSource.cancel("Request interrupted by page change")
    }
  }, [])

  return (
    <TextField
      value={value}
      select
      disabled={disabled || loading}
      {...props}
    >
      {options.map((option, index) => (
        <MenuItem
          key={index}
          value={option}
        >
          {option}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default TimeZoneSelect