import React, {FC, useEffect} from "react"
import {push} from "connected-react-router"
import i18next from "i18next"

import {showVpozPopupOnStart, wasEpuapReSignFlowTriedOnLogin} from "../../app.config"
import useGoToConsultationPage from "../../hooks/useGoToConsultationPage"
import {PozFormDetailedSource} from "../../services/analytics/PozFormDetailedSource.enum"
import store from "../../store"
import {RoutePath} from "../../routes/Routes.types"
import {
  ConsultationWidgetVisitDatePicker,
  DetailedSourceWidgetType
} from "../../store/makeConsultationWidget/makeConsultationWidget.types"

interface StartEpuapReSignFlowProps {}

const StartEpuapReSignFlow: FC<StartEpuapReSignFlowProps> = () => {
  const constBookWidgetConfig = {
    visitDatePicker: ConsultationWidgetVisitDatePicker.RESIGN_POZ,
    detailedSource: DetailedSourceWidgetType.RESIGN_POZ,
  }
  const { goToMakeConsultationPage } = useGoToConsultationPage(constBookWidgetConfig)
  const wasEpupaResignTriedOnLogin = sessionStorage.getItem(wasEpuapReSignFlowTriedOnLogin)

  useEffect(() => {
    if( wasEpupaResignTriedOnLogin === "true" ){
      return
    }
    sessionStorage.setItem(wasEpuapReSignFlowTriedOnLogin,"true")
    goToMakeConsultationPage()
  }, [wasEpupaResignTriedOnLogin])

  return null
}

export default StartEpuapReSignFlow