import {ChangeEvent, useState} from "react"

import {useAppSelector} from "../../../hooks/storeHooks"
import {selectUser} from "../../../store/user/user.selectors"

export const usePostalCodeManager = (): {
  postalCode: string
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
} => {
  const user = useAppSelector(selectUser)
  const [postalCode, setPostalCode] = useState(user?.address.postal_code ?? "")

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim()
    setPostalCode(value)
  }

  return {
    postalCode,
    handleChange
  }
}