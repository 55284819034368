import React, {FC, useState} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import AddressSearchEngine from "./addressSearchEngine/AddressSearchEngine.component"
import PharmaciesList from "./pharmaciesList/PharmaciesList.component"
import {Coordinates} from "./PharmacySearchEngine.types"
import {usePharmacySearchEngineStyles} from "./PharmacySearchEngine.styles"

export const PharmacySearchEngine: FC = () => {
  const classes = usePharmacySearchEngineStyles()
  const {t} = useTranslation()
  const [coordinates, setCoordinates] = useState<Coordinates>({
    gps_latitude: 0,
    gps_longitude: 0
  })

  return (
    <Box className={classes.root}>
      <Typography variant="h4">
        {t("orderMedicines:searchEngineLabel")}
      </Typography>
      <AddressSearchEngine
        setCoordinates={setCoordinates}
      />
      <PharmaciesList
        coordinates={coordinates}
      />
    </Box>
  )
}
