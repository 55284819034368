import {gql} from "@apollo/client"

import {QuestionnaireTemplate} from "../../pages/preventionQuestionnaire/genericStepForm/GenericStepForm.types"

export const GET_USER_ACTIONS_BY_QUESTIONNAIRE_ID = gql`
  query UserActionsByQuestionnaireId($questionnaireId: uuid!) {
    usersActions: users_actions(where: {
      action: { 
        fill_questionnaire: { 
          questionnaire_id: { _eq: $questionnaireId }
        }
      }
    }) {
      id
      action {
        points
        fillQuestionnaire: fill_questionnaire {
          questionnaireTemplate: questionnaire_template {
            template
          }
        }
      }
    }
  }
`

export type UserActionsByQuestionnaireIdResponse = {
  usersActions: [{
    id: string;
    action: {
      points: number
      fillQuestionnaire: {
        questionnaireTemplate: {
          template: QuestionnaireTemplate
        }
      }
    }
  }] | []
}

export type UserActionsByQuestionnaireIdVariables = {
  questionnaireId: string
}