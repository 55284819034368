import React, { forwardRef, PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { Button, ButtonProps,Typography } from "@material-ui/core"

import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import { useCloseButtonStyles } from "./CloseButton.styles"

interface CloseButtonProps extends ButtonProps {
  onClick?(): void;
  topCSS?: number;
  rightCSS?: number;
}

// eslint-disable-next-line react/display-name
const CloseButton = forwardRef<HTMLButtonElement, PropsWithChildren<CloseButtonProps>>(({
  onClick,
  topCSS,
  rightCSS,
  ...props
}, ref) => {
  const {t} = useTranslation()
  const classes = useCloseButtonStyles({
    topCSS,
    rightCSS,
  })

  return (
    <Button
      ref={ref}
      title={t("close")}
      className={classes.root}
      onClick={onClick}
      {...props}
    >
      <Typography variant="h2" component="span" className={classes.wrapper}>
        <IconByIntegrationType iconName={"icon-x"} />
      </Typography>
    </Button>
  )
})

export default CloseButton
