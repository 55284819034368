import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import {Box, Grid, Hidden, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import CollapseItemsWrapper from "../../common/collapseItemsWrapper/CollapseItemsWrapper.component"
import MedicalExamsPoints from "../medicalExamsPoints/MedicalExamsPoints.component"
import ReferralItemsList from "../referralItemsList/ReferralItemsList.component"
import ReferralSectionFooter from "../referralSectionFooter/ReferralSectionFooter.component"
import ReferralPurchaseStatus from "./RefferalPurchaseStatus.component"
import { ConsultationExamReferral } from "../../exam/Exam.types"
import {useReferralSectionStyles} from "../ReferralSection.styles"

interface ReferralContentProps {
  items: ConsultationExamReferral[];
  price: number;
  isBought: boolean;
  consultationId: string;
  isPoz: boolean;
  referralId?: string;
  date?: string;
  expanded?: boolean;
}

const ReferralContent: FC<ReferralContentProps> = ({
  items: referrals,
  price,
  isBought,
  consultationId,
  referralId,
  date,
  isPoz
}) => {
  const {t} = useTranslation()
  const classes = useReferralSectionStyles()

  const VISIBLE_ITEMS_COUNT = 4
  const visibleExamItems = referrals.slice(0, VISIBLE_ITEMS_COUNT)
  const collapsibleExamItems = referrals.slice(VISIBLE_ITEMS_COUNT)
  const collapsedItemsCount = collapsibleExamItems.length
  const boughtExams = referrals.filter((item) => item.is_bought === true).length
  const totalExams = referrals.length
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))

  /* components */
  /* HIDDEN DUE TO LACK OF FUNCTIONALITY
  const examGroupsListLabels = examGroupsList.map(examGroup => (
    <TextLabel
      text={examGroup}
      key={examGroup}
      mr={2}
    />
  ))*/

  /* HIDDEN DUE TO LACK OF FUNCTIONALITY
  const howToPrepareButton = (
    <ButtonTextUnderlined color={"secondary"}>
      { t("referrals:howToPrepare") }
    </ButtonTextUnderlined>
  )*/

  /* e/o components */

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box>
        <ReferralPurchaseStatus totalExams={totalExams} boughtExams={boughtExams} />
      </Box>
      <Box>
        <Grid container spacing={8}>
          <Grid
            item
            xs={12}
            md={6}
            className={classes.content}
          >
            <Box mb={3}>
              <Typography
                variant={isSmUp ? "h4" : "h5"}
              >
                { `${t("exam:examReferrals")}:` }
              </Typography>
            </Box>
            {/* HIDDEN DUE TO LACK OF FUNCTIONALITY
            <Hidden smDown>
              <Box mb={2}>
                { examGroupsListLabels }
              </Box>
            </Hidden>
            */}

            <ReferralItemsList
              referrals={visibleExamItems}
              borderBottom={collapsedItemsCount > 0}
            />

            {collapsedItemsCount > 0 && (
              <Box mt={1} mb={3}>
                <CollapseItemsWrapper toggleButtonText={t("showMoreReferrals", {count: collapsedItemsCount})}>
                  <ReferralItemsList
                    referrals={collapsibleExamItems}
                    borderBottom={false}
                  />
                </CollapseItemsWrapper>
              </Box>
            )}

            {/* HIDDEN DUE TO LACK OF FUNCTIONALITY
            <Hidden smDown>
              <Box mt={collapsedItemsCount > 0 ? -5 : 2}>
                { howToPrepareButton }
              </Box>
            </Hidden>
            */}
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <Box>
              <MedicalExamsPoints />
            </Box>
          </Grid>

          <Box
            mt={3} 
            display="flex" 
            justifyContent="flex-end"
            width="100%"
          >
            <ReferralSectionFooter
              price={price}
              expanded={true}
              isBought={isBought}
              consultationId={consultationId}
              isPoz={isPoz}
              referralId={referralId}
              date={date}
            />
            <Hidden smDown>
              <Box p={1.5} color="transparent">
                <IconByIntegrationType {...{ color: "inherit" }}
                  iconName={"icon-caret-down"}
                  returnTypography={true}
                />
              </Box>
            </Hidden>
          </Box>

          <Hidden mdDown>
            <Grid item md={3}/>
          </Hidden>
        </Grid>
      </Box>
    </Box>
  )
}

export default ReferralContent
