import React, {FC} from "react"
import {Checkbox, FormControlLabel, FormGroup} from "@material-ui/core"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"

import {dateTestIdMap} from "../../../GenericStepForm.config"
import {useCheckboxes} from "./Checkboxes.utils"
import {Answer, CompletedAnswer} from "../../../GenericStepForm.types"
import {useCheckboxesStyles} from "./Checkboxes.styles"

type CheckboxesProps = {
  answers: Answer[];
  onChange: (completedAnswer: CompletedAnswer[]) => void;
  errors: string[];
  value?: CompletedAnswer[];
}

const Checkboxes: FC<CheckboxesProps> = ({
  answers,
  onChange,
  value
}) => {
  const classes = useCheckboxesStyles()
  const { checkboxes } = useCheckboxes(answers, onChange, value)
  
  return (
    <FormGroup
      className={classes.formGroup}
      data-testid={dateTestIdMap.checkBoxesInput}
    >
      {
        checkboxes.map(checkbox => (
          <FormControlLabel
            key={checkbox.id}
            control={(
              <Checkbox
                checked={!!checkbox.value}
                onChange={checkbox.onCheck}
                checkedIcon={<CheckBoxIcon color="primary"/>}
                icon={<CheckBoxOutlineBlankIcon/>}
                value={checkbox.id}
              />
            )}
            label={checkbox.name}
          />
        ))
      }
    </FormGroup>
  )
}

export default Checkboxes
