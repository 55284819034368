import React, {FC} from "react"
import {useWatch} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box, Grid, Typography} from "@material-ui/core"

import {useAppSelector} from "../../../../../../hooks/storeHooks"
import {selectUser} from "../../../../../../store/user/user.selectors"
import Alert from "../../../../../../components/alert/Alert.component"
import SelectController from "../../../../../../components/commonFormItems/SelectController/SelectController.component"
import TextFieldController
  from "../../../../../../components/commonFormItems/textFieldController/TextFieldController.component"
import InfoLabel from "../../../../../../components/infoLabel/InfoLabel.component"
import MedicalSurveyAccordionCheckbox
  from "../../../../../../components/medicalSurvey/medicalSurveyAccordionCheckbox/MedicalSurveyAccordionCheckbox.component"
import MedicalSurveyCheckbox
  from "../../../../../../components/medicalSurvey/medicalSurveyCheckbox/MedicalSurveyCheckbox.component"
import {BasicInformationStepData, Sex} from "../../../MedicalQuestionnaireStepperForm.types"

import {useTranslateOptions} from "./BasicMedicalQuestionnaire.hooks"

type Props = {
  isDataFetchedFromPrevSurvey?: boolean
}

const BasicMedicalQuestionnaire: FC<Props> = ({isDataFetchedFromPrevSurvey}) => {
  const { t } = useTranslation()
  const { questionAnswerOptions, sexOptions } = useTranslateOptions()
  const sexValue = useWatch<BasicInformationStepData>({
    name: "sex",
  })
  const sexChangeIsLocked: boolean = useWatch<BasicInformationStepData>({
    name: "sexChangeIsLocked",
  })

  return (
    <Box>
      <Typography variant='h3'>{t("medicalQuestionnaire:basicMedicalQuestionnaireTitle")}</Typography>

      { isDataFetchedFromPrevSurvey &&
          <InfoLabel>
            { t("prescription:dataFetchedFromPrevSurvey") }
          </InfoLabel>
      }
      
      <Box
        marginBottom={2}
      >
        <Grid
          container
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs={6}
              >
                <TextFieldController
                  type='number'
                  name='weight'
                  label={t("prescription:weightPlaceholder")}
                  inputProps= {{
                    inputProps: { 
                      max: 500, min: 0 
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={6}
              >
                <TextFieldController
                  type='number'
                  name='height'
                  label={t("prescription:heightPlaceholder")}
                  inputProps= {{
                    inputProps: { 
                      max: 300, min: 0 
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <SelectController
                  name='sex'
                  label={t("user:gender")}
                  disabled={sexChangeIsLocked}
                  options={sexOptions}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {
        sexValue === Sex.FEMALE && (
          <>
            <MedicalSurveyCheckbox
              name='isPregnant'
              label={t("prescription:surveyQuestions:pregnant")}
              options={questionAnswerOptions}
            />
            <MedicalSurveyCheckbox
              name='isBreastFeeding'
              label={t("prescription:surveyQuestions:breastFeed")}
              options={questionAnswerOptions}
            />
          </>
        )
      }
      <MedicalSurveyAccordionCheckbox
        name="hasChronicDiseases"
        checkboxLabel={t("prescription:surveyQuestions:chronicDiseases")}
        inputLabel={t("medicalQuestionnaire:chronicDiseasesTextAreaLabel")}
        options={questionAnswerOptions}
      />
      <MedicalSurveyAccordionCheckbox
        name="isTakingAnyMedicines"
        checkboxLabel={t("prescription:surveyQuestions:chemicals")}
        inputLabel={t("medicalQuestionnaire:medicinesTextAreaLabel")}
        options={questionAnswerOptions}
      />
      <MedicalSurveyAccordionCheckbox
        name="hasAnyAllergies"
        checkboxLabel={t("prescription:surveyQuestions:alergy")}
        inputLabel={t("medicalQuestionnaire:allergiesTextAreaLabel")}
        options={questionAnswerOptions}
      />
    </Box>
  )
}

export default BasicMedicalQuestionnaire
