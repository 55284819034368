import {useTranslation} from "react-i18next"
import {useHistory} from "react-router"

export const useCancelConsultation = (): VoidFunction => {
  const { i18n } = useTranslation()
  const { push } = useHistory()

  const handleRedirect = () => {
    push(`/${i18n.language}`)
  }

  return handleRedirect
}