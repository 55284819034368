import { AxiosResponse } from "axios"
import { SagaIterator } from "redux-saga"
import { all, AllEffect, call, CallEffect, put, select, takeLatest } from "redux-saga/effects"

import api from "../../api/api"
import { getConsultationExamReferralsConfig } from "../../api/routes"
import { selectLatestConsultations } from "../consultations/consultations.selectors"
import { ConsultationListItemModel, FINISHED_WITH_SUCCESS_CONSULTATION_STATUS_NUMBERS, } from "../../components/consultation/Consultation.types"
import { ConsultationExamReferral } from "../../components/exam/Exam.types"
import { ConsultationExamReferralsResponse } from "./examReferrals.types"

import { selectExamReferralsConsultationsIds } from "./examReferrals.selectors"
import {
  getExamReferrals,
  resetExamReferralsLoading,
  setExamReferrals,
  setExamReferralsError
} from "./examReferrals.slice"

async function getConsultationExamReferrals(consultationId: ConsultationListItemModel["id"]) {
  return api.request<ConsultationExamReferralsResponse>({
    ...getConsultationExamReferralsConfig(consultationId)
  })
}

function* getExamReferralsSaga() {
  const consultations: ReturnType<typeof selectLatestConsultations> = yield select(selectLatestConsultations)
  const examReferralsConsultationIds: ReturnType<typeof selectExamReferralsConsultationsIds> = yield select(selectExamReferralsConsultationsIds)
  const newConsultations = consultations.filter(consultation => !examReferralsConsultationIds.includes(consultation.id))

  // since we filter out consultationIds that already have exam referrals loaded (line 23)
  // we need to ensure to do not try to load referrals that are already in loading state (not loaded yet)
  for (const consultation of newConsultations) {
    yield put(setExamReferrals({
      [consultation.id]: []
    }))
  }

  const requests = newConsultations.reduce((
    requestsObject: Record<ConsultationListItemModel["id"], CallEffect<AxiosResponse<ConsultationExamReferralsResponse>>>,
    consultation: ConsultationListItemModel
  ) => {
    // make request only for finished with success consultations
    if (!FINISHED_WITH_SUCCESS_CONSULTATION_STATUS_NUMBERS.includes(consultation.status_number)) {
      return requestsObject
    }
    return {...requestsObject, [consultation.id]: call(getConsultationExamReferrals, consultation.id)}
  }, {})

  try {
    const responses: AllEffect<Record<ConsultationListItemModel["id"], AxiosResponse<ConsultationExamReferralsResponse>>> = yield all(requests)

    const examReferrals: Record<ConsultationListItemModel["id"], ConsultationExamReferral[]> = {}
    for (const [key, value] of Object.entries(responses)) {
      examReferrals[key] = value.data.referrals
    }

    yield put(setExamReferrals(examReferrals))
    yield put(resetExamReferralsLoading())
  } catch (e) {
    yield put(setExamReferralsError({
      data: e.response.data,
      status: e.response.status,
    }))
  }
}

export default function* (): SagaIterator {
  yield takeLatest(getExamReferrals, getExamReferralsSaga)
}
