import {createStyles, makeStyles} from "@material-ui/core"

export const useControllerKeyboardTimePickerStyles = makeStyles(theme => createStyles({
  formHelperText: {
    margin: "0 0 0 14px",
  },
  errorInput: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.light
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.light,
      borderWidth: 2
    }
  },
  dialogRoot: {
    "& .MuiPaper-root": {
      padding: "0 !important"
    }
  }
}))