export const isAdult = (birthDate: string | Date | undefined) => {
  if(!birthDate) {
    return null
  }

  const age = 18
  const userDate = new Date(birthDate)
  const currentDate = new Date()

  currentDate.setFullYear(currentDate.getFullYear() - age)

  if((currentDate.getTime() - userDate.getTime()) < 0) {
    return false
  }
  return true
}