import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Hidden, Typography} from "@material-ui/core"

import {GOOGLE_STORE_TELEMEDI_APP_LINK} from "../../../../../../../app.config"
import downloadApp from "../../../../../../../assets/images/downloadApp.png"
import downloadAppQR from "../../../../../../../assets/svg/downloadAppQR.svg"
import googlePlayLogo from "../../../../../../../assets/svg/googlePlayLogo.svg"
import ImageByIntegrationType from "../../../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import ActionButtons from "../../actionButtons/ActionButtons.component"
import PreventionActionWrapper from "../../preventionActionWrapper/PreventionActionWrapper.component"
import {usePhysicalActivityMeasurementLogStyles} from "./PhysicalActivityMeasurementLog.styles"

type MedicalExaminationProps = {
  title: string;
}

const PhysicalActivityMeasurementLog: FC<MedicalExaminationProps> = ({
  title,
}) => {
  const classes = usePhysicalActivityMeasurementLogStyles()
  const {t} = useTranslation()

  return (
    <PreventionActionWrapper
      title={title}
      isImageHidden
    >
      <Hidden mdUp>
        <ImageByIntegrationType
          imageSrc={downloadApp}
          alt="downloadApp"
          className={classes.downloadAppImage}
          imagePath={"downloadApp.png"}
        />
      </Hidden>
      <Box
        display="flex"
        flexDirection="row"
      >
        <Typography className={classes.description}>
          {t("healthPlanner:downloadAppText")}
        </Typography>
        <Hidden smDown>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className={classes.imageContainer}
          >
            <ImageByIntegrationType
              imageSrc={downloadAppQR}
              alt="downloadAppQR"
              imagePath={"downloadAppQR.svg"}
            />
            <ImageByIntegrationType
              imageSrc={googlePlayLogo}
              alt="googlePlayLogo"
              imagePath={"googlePlayLogo.svg"}
            />
          </Box>
        </Hidden>
      </Box>
      <ActionButtons
        onClick={() => window.location.href = GOOGLE_STORE_TELEMEDI_APP_LINK}
        actionButtonLabel={t("healthPlanner:downloadApp")}
      />
    </PreventionActionWrapper>
  )
}

export default PhysicalActivityMeasurementLog