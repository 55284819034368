import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import DocumentationPage from "../../../pages/documentation/DocumentationPage"
import AppDialog from "../../common/appDialog/AppDialog.component"
import {useConsultationChatStyles} from "../../consultationChat/ConsultationChat.styles"

type ConsultationChatDocumentationModalProps = {
  open: boolean;
  onClose(): void;
}

const ConsultationChatDocumentationModal: FC<ConsultationChatDocumentationModalProps> = ({open, onClose}) => {
  const {t} = useTranslation()
  const classes = useConsultationChatStyles()

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={t("consultation:consultationDocumentation")}
      classes={{
        paper: classes.documentationPopup,
      }}
    >
      <DocumentationPage
        displayInModal
      />
    </AppDialog>
  )
}

export default ConsultationChatDocumentationModal
