import { makeStyles } from "@material-ui/core"

import { createStyles } from "@material-ui/core/styles"

export const useMedicationsListStyles = makeStyles(() => createStyles({
  list: {
    padding: 0,
    listStyle: "none",
  },
}))
