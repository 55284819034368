import {gql} from "@apollo/client"

export const GET_CONSULTATION_SURVEY_TEMPLATE = gql`
    query getQuestionnaireTemplates($codeName: String!) {
        questionnaireTemplates: questionnaire_templates(where: {code_name: {_eq: $codeName}}) {
            priority
            template
            codeName: code_name
            id
        }
}
`
