import React, { FC, ReactNode, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { generatePath, Link } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Box, Button, Typography, useMediaQuery } from "@material-ui/core"
import clsx from "clsx"

import ScoreImage from "../../../../assets/images/healthPrevention/score.png"
import { useAppSelector } from "../../../../hooks/storeHooks"
import { useQueryParam } from "../../../../hooks/useSearchParams"
import { selectUserId } from "../../../../store/session/session.selectors"
import {
  GET_QUESTIONNAIRE_ANSWERS,
  GetQuestionnaireAnswersResponse,
  GetQuestionnaireAnswersVariables, QuestionnaireAnswers
} from "../../api/queries/getQuestionnaireAnswers"
import { ActionType } from "../../api/queries/getUserActionById"
import ImageByIntegrationType from "../../../../components/imageByIntegrationType/ImageByIntegrationType"
import PreventionActionWrapper
  from "../../pages/preventionAction/preventionActionCard/preventionActionWrapper/PreventionActionWrapper.component"
import DoctorImage from "./doctorImage/DoctorImage.component"
import { useParseActionTypeToTile } from "./ActionThankYouPage.utils"
import { RoutePath } from "../../../../routes/Routes.types"
import { HealthPreventionPrograms } from "../../../../store/clinic/clinicSettings.types"
import { QuestionnaireTemplate } from "../../pages/preventionQuestionnaire/genericStepForm/GenericStepForm.types"
import { usePreventionActionWrapperStyles } from "../../pages/preventionAction/preventionActionCard/preventionActionWrapper/PreventionActionWrapper.styles"
import { useActionThankYouPage } from "./ActionThankYouPage.styles"
import { Theme } from "@material-ui/core/styles"

type ActionThankYouPageProps = {
  actionType: ActionType;
  buttonLink: string;
  buttonLabel: string;
  points?: number;
  children?: ReactNode;
  template?: QuestionnaireTemplate;
}

const ActionThankYouPage: FC<ActionThankYouPageProps> = ({
  actionType,
  points = 0,
  buttonLink,
  buttonLabel,
  children,
  template
}) => {
  const { t, i18n } = useTranslation()
  const classes = useActionThankYouPage()
  const actionClasses = usePreventionActionWrapperStyles()
  const isMdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"))
  const [scoreContent, setScoreContent] = useState<string>("")
  const { questionnaireId } = useParams<{ questionnaireId: string }>()
  const actionId = useQueryParam("id")
  const type = useQueryParam("type")
  const userId = useAppSelector(selectUserId)
  const isMentalHealthProgram = type === HealthPreventionPrograms.PROGRAM_MENTAL_HEALTH
  const title = template && template.completedQuestionnaireTitle 
    ? template.completedQuestionnaireTitle 
    : useParseActionTypeToTile(actionType)

  const { refetch, loading } = useQuery<GetQuestionnaireAnswersResponse, GetQuestionnaireAnswersVariables>(
    GET_QUESTIONNAIRE_ANSWERS, {
      context: { clientName: "HealthPlanner" },
      skip: true,
      onCompleted: (data) => {
        if (data) {
          let totalScore = 0
          let lowPointExists = false
          let veryLowPointExists = false

          const qa = data.questionnaire_answers[0]
          qa.answer.questions.forEach((question) => {
            question.answers.forEach((answer) => {
              totalScore += answer.points
              if (answer.points === 1) {
                veryLowPointExists = true
              }
              if (answer.points <= 2) {
                lowPointExists = true
              }
            })
          })

          if (!isMentalHealthProgram) {
            return
          }

          let message = ""
          if (totalScore < 13 || veryLowPointExists) {
            message = "mentalHealth:calculatedQuestionnaire:bad"
          } else if (totalScore > 13 && lowPointExists) {
            message = "mentalHealth:calculatedQuestionnaire:ok"
          } else if (totalScore > 13 && !lowPointExists) {
            message = "mentalHealth:calculatedQuestionnaire:good"
          }

          setScoreContent(t(message))
        }
      }
    }
  )

  useEffect(() => {
    if (!userId || !questionnaireId || !template?.calculateAnswersPoints || loading) {
      return
    }
    
    refetch({
      user_id: userId,
      template_id: questionnaireId
    })
  }, [])
  
  if (isMentalHealthProgram) {
    return <PreventionActionWrapper
      title={title}
      description={scoreContent}
      overrideImage={
        <ImageByIntegrationType
          imageSrc={ScoreImage}
          alt="ScoreImage"
          className={clsx(actionClasses.image, actionClasses.overrideImage)}
          imagePath={"score.png"}
        />
      }
    >
      <Box
        display="flex"
        flexDirection={isMdUp ? "row" : "column"}
      >
        { template?.specializationId && (
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={`/${i18n.language}${RoutePath.MAKE_CONSULTATION}?specializationId=${template.specializationId}`}
            className={classes.button}
          >
            {t("mentalHealth:bookConsultation")}
          </Button>
        )}
        { template?.canFilledAgain && actionId && (
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={generatePath(RoutePath.PREVENTION_ACTION, { actionId })}
            className={classes.button}
          >
            {t("mentalHealth:fillQuestionnaireAgainBtnLabel")}
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={buttonLink}
          className={classes.button}
        >
          {buttonLabel}
        </Button>
      </Box>
    </PreventionActionWrapper>
  }
  
  return (
    <Box
      className={classes.boxCard}
      p={isMdUp ? 6 : 2}
    >
      <Typography
        variant={isMdUp ? "h2" : "h3"}
        component="h2"
      >
        {title}
      </Typography>
      <Box
        display="flex"
        flexDirection={isMdUp ? "row" : "column"}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          order={isMdUp ? 0 : 1}
          maxWidth={isMdUp ? "600px" : "unset"}
        >
          {
            points > 0 && (
              <Typography
                variant={isMdUp ? "h3" : "h4"}
                component="h3"
                className={classes.subtitle}
              >
                <Trans i18nKey="healthPlannerActions:pointsReceived">
                  Otrzymujesz <span>{{ points }} punktów!</span>
                </Trans>
              </Typography>
            )
          }
          {children}
        </Box>
        <DoctorImage/>
      </Box>
      <Box
        display="flex"
        flexDirection={isMdUp ? "row" : "column"}
      >
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={buttonLink}
          className={classes.button}
        >
          {buttonLabel}
        </Button>
      </Box>
    </Box>
  )
}

export default ActionThankYouPage
