import React, { FC } from "react"

import { DiaryMeasurementDataTable } from "../../diaryMeasurementDataTable/DiaryMeasurementDataTable.component"
import DiaryMeasurementTabPanel from "../../diaryMeasurementTabPanel/DiaryMeasurementTabPanel.component"
import { PreparedMeasurementsDataModel } from "../../DiaryMeasurement.types"
import { useDiaryMeasurementTabPanelsStyles } from "./DiaryMeasurementTabPanels.styles"

interface DiaryMeasurementTabPanelsProps {
  currentTab: number;
  currentNestedTab: number;
  records: PreparedMeasurementsDataModel[];
}

const DiaryMeasurementTabPanels: FC<DiaryMeasurementTabPanelsProps> = ({
  currentTab,
  currentNestedTab,
  records
}) => {
  const { tabPanel } = useDiaryMeasurementTabPanelsStyles()
  
  // indexes of nested tab panels should be reorganized while adding new tables / charts
  return (
    <>
      <DiaryMeasurementTabPanel className={tabPanel} value={currentTab} index={0}>
        <DiaryMeasurementTabPanel value={currentNestedTab} index={2}>
          {/* data table per week */}
        </DiaryMeasurementTabPanel>
        <DiaryMeasurementTabPanel value={currentNestedTab} index={1}>
          {/* data table per month */}
        </DiaryMeasurementTabPanel>
        <DiaryMeasurementTabPanel value={currentNestedTab} index={0}>
          {/* data table per year */}
          <DiaryMeasurementDataTable
            records={records}
          />
        </DiaryMeasurementTabPanel>
      </DiaryMeasurementTabPanel>

      <DiaryMeasurementTabPanel className={tabPanel} value={currentTab} index={1}>
        <DiaryMeasurementTabPanel value={currentNestedTab} index={0}>
          {/* data chart per week */}
        </DiaryMeasurementTabPanel>
        <DiaryMeasurementTabPanel value={currentNestedTab} index={1}>
          {/* data chart per month */}
        </DiaryMeasurementTabPanel>
        <DiaryMeasurementTabPanel value={currentNestedTab} index={2}>
          {/* data chart per year */}
        </DiaryMeasurementTabPanel>
      </DiaryMeasurementTabPanel>
    </>
  )
}

export default DiaryMeasurementTabPanels