import React, {FC, useState} from "react"
import {useTranslation} from "react-i18next"
import { useTheme } from "@material-ui/core"

import { useAppSelector } from "../../../../hooks/storeHooks"
import { selectLoading } from "../../../../store/user/user.selectors"
import UserDataListItem from "../../common/UserDataListItem"
import LoaderBox from "../../../common/loaderBox/LoaderBox.component"
import UserDataInfoSection from "../../common/UserDataInfoSection.component"
import UserContactDataEditModal from "./UserContactDataEditModal.component"
import {checkFeatureAvailability} from "../../../../store/clinic/clinicSettings.utils"
import {UserDataType} from "../../../../store/user/user.types"

interface UserContactDataProps{
  user: UserDataType;
  patientEditAllowed: boolean
}

const UserContactData: FC<UserContactDataProps> = ({user,patientEditAllowed}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const loading = useAppSelector(selectLoading)
  const benefitSystemIntegrationEnabled = checkFeatureAvailability().enableBenefitSystemIntegration

  const userPhoneCode = user.phone_country_code ? `+${user.phone_country_code}`: ""
  const userPhone = `${userPhoneCode} ${user.phone_national_number || ""}`

  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false)
  const handleEditModalOpen = () => {
    setEditModalOpen(true)
  }
  const handleEditModalClose = () => {
    setEditModalOpen(false)
  }

  if (loading) {
    return (
      <UserDataInfoSection
        title={t("user:contactInfo")}
      >
        <LoaderBox size={theme.spacing(8)} thickness={2}/>
      </UserDataInfoSection>
    )}

  return (
    <>
      <UserDataInfoSection
        title={t("user:contactInfo")}
        withButton={patientEditAllowed && (user.nationality !== "PL " || !!user.pesel)}
        onClick={handleEditModalOpen}
        buttonText={t("edit")}
      >
        <UserDataListItem
          label={t("user:email")}
          description={user.email || ""}
          descriptionProps={{noWrap: true}}
        />

        <UserDataListItem
          label={t("user:phone")}
          description={userPhone}
          descriptionProps={{noWrap: true}}
        />
      </UserDataInfoSection>

      <UserContactDataEditModal
        user={user}
        open={isEditModalOpen}
        onClose={handleEditModalClose}
        benefitSystemIntegrationEnabled={benefitSystemIntegrationEnabled}
      />
    </>
  )
}

export default UserContactData
