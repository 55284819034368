import React, { FC } from "react"
import Lottie, {LottieProps} from "react-lottie"
import { Box } from "@material-ui/core"

interface LottieAnimationProps {
  animationData: LottieProps["options"]["animationData"];
  boxWidth?: string;
}

const LottieAnimation: FC<LottieAnimationProps> = (
  {
    animationData,
    boxWidth
  }
) => {
  const lottieDefaultOptions: LottieProps["options"] = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
    animationData: animationData,
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        width={boxWidth || "400px"}
        maxWidth="400px"
        height="auto"
      >
        <Lottie
          options={lottieDefaultOptions}
          height="100%"
          width="100%"
        />
      </Box>
    </Box>
  )
}

export default LottieAnimation