import {gql} from "@apollo/client"

export interface ReceivedAwardModel {
  code: string;
}
    
export interface ReceivedAwardResponseData {
  receivedAward: ReceivedAwardModel[]
}

export const GET_RECEIVED_AWARD = gql`
  query getReceivedAward {
    receivedAward: awards_vouchers(order_by: {created_at: desc}, limit: 1) {
      code
    }
  }
`
