import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Link, Typography} from "@material-ui/core"

import AppDialog from "../../common/appDialog/AppDialog.component"

interface CancelExaminationPopupProps {
  open: boolean;
  onClose(): void;
}

const CancelExaminationPopup: FC<CancelExaminationPopupProps> = ({open, onClose}) => {
  const {t} = useTranslation()

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={t("exam:cancelExaminationPopup:title")}
    >
      <Box mb={1}>
        <Typography>
          {t("exam:cancelExaminationPopup:description")}
        </Typography>
      </Box>

      <Link
        underline="always"
        href={`tel:${t("serviceDeskPhone")}`}
      >
        <Typography>
          {t("serviceDeskPhone")}
        </Typography>
      </Link>

      <Typography className="break-spaces">
        {t("exam:cancelExaminationPopup:workTime")}
      </Typography>
    </AppDialog>
  )
}

export default CancelExaminationPopup
