import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"
import clsx from "clsx"

import {
  isSubscriptionVoucher,
  subscriptionDescriptionTranslationKey,
  subscriptionTitleTranslationKey
} from "./VoucherBox.utils"
import { VoucherModel } from "../../../store/vouchers/vouchers.types"
import {useVoucherBoxStyles} from "./VoucherBox.styles"

interface VoucherBoxProps {
  voucher: VoucherModel;
}

const VoucherBox: FC<VoucherBoxProps> = ({voucher}) => {
  const { t } = useTranslation()
  const classes = useVoucherBoxStyles()

  return (
    <Box
      height="100%"
      py={2}
      px={0}
      component="section"
      className={clsx(
        classes.root,
        !voucher.is_valid && classes.expired
      )}
    >
      {isSubscriptionVoucher(voucher.code) &&
          <Box mb={1}>
            <Typography
              variant="h5"
              color="primary"
            >
              {t(subscriptionTitleTranslationKey(voucher.code))}
            </Typography>
          </Box>
      }
      <Box mb={1} display="flex" justifyContent="space-between">
        <Typography variant="h5" className={classes.code}>
          {voucher.code}
        </Typography>

        { !voucher.is_valid &&
          <Typography variant="h5" className={classes.expiredText}>
            { t("finances:vouchers:expired") }
          </Typography>
        }
      </Box>
      {
        voucher.valid_to && (
          <Box mb={1}>
            <Typography>
              {t("finances:vouchers:validTo")} <strong>{voucher.valid_to.split("T")[0]}</strong>
            </Typography>
          </Box>
        )
      }
      {
        isSubscriptionVoucher(voucher.code) &&
          <Box mb={2}>
            <Typography color="textSecondary">
              {t(subscriptionDescriptionTranslationKey(voucher.code))}
            </Typography>
          </Box>
      }
    </Box>
  )
}

export default VoucherBox
