import { buttonBase } from "./buttonBase"
import { checkbox } from "./checkbox"
import { dialog } from "./dialog"
import { radio } from "./radio"
import { textField } from "./textField"
import { typography } from "./typography"

export const props = {
  ...buttonBase,
  ...checkbox,
  ...radio,
  ...textField,
  ...typography,
  ...dialog,
}
