import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import {Link} from "react-router-dom"
import { Box, Button, LinearProgress,Typography, useMediaQuery, useTheme } from "@material-ui/core"

import { useAwards } from "../AwardsList.utils"
import { useAwardsListItemStyles } from "./AwardsListItem.styles"

interface AwardsListItemProps {
  name: string;
  desc: string;
  validTo: string;
  points: number;
  userPoints: number;
  imageUrl: string;
  path: string;
}

const AwardsListItem: FC<AwardsListItemProps> = ({
  name,
  desc,
  validTo,
  points,
  userPoints,
  imageUrl,
  path,
}) => {
  const { t } = useTranslation()
  const {
    imageWrapper,
    image,
    listItem,
    contentWrapper,
    infoTitle,
    label,
    description,
    progressbarLabel,
    progressbar,
  } = useAwardsListItemStyles({ awardReached: userPoints >= points })
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const { getProperPercent } = useAwards()
  
  return (
    <Button
      component={Link}
      to={path}
      className={listItem}
    >
      <Box className={imageWrapper}>
        <img
          className={image}
          src={imageUrl || ""}
          alt=""
        />
      </Box>

      <Box className={contentWrapper}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={1}
        >
          <Typography
            variant={isMdUp ? "body1" : "body2"}
            className={infoTitle}
          >
            {name}
          </Typography>
          <Typography 
            variant="subtitle2"
            className={label}
          >
            {userPoints >= points 
              ? t("healthPlanner:readyToReceive") 
              : t("healthPlanner:gainMorePoints")
            }
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Typography
              variant={isMdUp ? "body2" : "subtitle2"}
              className={description}
            >
              {desc}
            </Typography>
            {isMdUp && (
              <Typography
                variant={"body2"}
              >
                {t("healthPlanner:validToLabel")}: {t("dates:dayMonthYearShort", { date: new Date(validTo) })}
              </Typography>
            )}
          </Box>
          {isMdUp && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              alignSelf="flex-end"
            >
              <Typography
                variant="body1"
                className={progressbarLabel}
              >
                <span>{points}</span> {t("awards:points")}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={ userPoints >= points ? 100 : getProperPercent(points, userPoints, 100)}
                className={progressbar}
              />
            </Box>
          )}
        </Box>

      </Box>
    </Button>
  )
}

export default AwardsListItem