import React, {FC, useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Button,
  Grid,
  Hidden,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import {AxiosError} from "axios"
import clsx from "clsx"
import { add, isSameYear, parseISO } from "date-fns"

import api from "../../../api/api"
import {getSickLeaveFileConfig} from "../../../api/routes"
import useFileDownload from "../../../hooks/useFileDownload"
import GlobalErrorSnackbar from "../../commonFormItems/globalErrorSnackbar/GlobalErrorSnackbar"
import CalendarBox from "../../common/calendarBox/CalendarBox.component"
import SickLeaveSectionHeader from "../sickLeaveSection/sickLeaveSectionHeader/SickLeaveSectionHeader.component"
import {SickLeaveErrorResponse, YesOrNoPL} from "../SickLeave.types"
import {useSickLeaveStyles} from "./SickLeave.styles"

type ParentClassKey = "root" | "summaryRoot" | "detailsRoot"

interface SickLeaveProps {
  startDate: string;
  endDate: string;
  hospitalStartDate: string;
  hospitalEndDate: string;
  onChange: AccordionProps["onChange"];
  expanded: AccordionProps["expanded"];
  expandIcon: AccordionSummaryProps["expandIcon"];
  parentClasses: Record<ParentClassKey, string>;
  id: string;
  canWalk: string;
  url: string;
}

const PATIENT_DATA_EXTERNAL_URL = "https://pacjent.gov.pl"

const SickLeave: FC<SickLeaveProps> = (
  {
    startDate,
    endDate,
    hospitalStartDate,
    hospitalEndDate,
    expanded,
    expandIcon,
    parentClasses,
    onChange,
    id,
    canWalk,
    url
  }
) => {
  const { t } = useTranslation()
  const classes = useSickLeaveStyles()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))
  const fontVariant = isSmUp ? "body1" : "subtitle1"

  const fileName = `${startDate} - ${endDate} e-zwolnienie.pdf`
  const getEPrescriptionPdf = () => api.request<Blob>({...getSickLeaveFileConfig(url.replace("http://", "https://"))}) // temporary replace, to be fixed by backend
  const {download, downloading, downloadError} = useFileDownload(getEPrescriptionPdf)
  const [globalError, setGlobalError] = useState<AxiosError<SickLeaveErrorResponse> | null>(null)

  const getDatesToCompare = (startDate: string, endDate: string) => [
    parseISO(startDate),
    add(parseISO(endDate), {
      hours: 23,
      minutes: 59,
      seconds: 59,
    })
  ]

  const datesToCompare = useMemo((): Date[] => {
    return getDatesToCompare(startDate, endDate)
  }, [])

  const hospitalDatesToCompare = useMemo((): Date[] => {
    return getDatesToCompare(hospitalStartDate, hospitalEndDate)
  }, [])

  const distanceBetweenSickLeaveDates = t("dates:distanceDatesStrict", { date: datesToCompare })

  const getRangeTextDates = (dateRange: Array<Date>) => t(
    isSameYear(dateRange[0], dateRange[1])
      ? "dates:rangeTextDateWithoutYear"
      : "dates:rangeTextDateWithYear",
    { date: dateRange })

  const generateAdditionalInfoLabels = useMemo((): string => {
    if (hospitalStartDate) {
      return `${t("sickLeaveHospital")} ${getRangeTextDates(hospitalDatesToCompare)}`
    } else if (canWalk === YesOrNoPL.YES) {
      return t("sickLeaveCanWalk")
    }

    return t("sickLeaveCannotWalk")
  }, [])

  const handleDownloadPdfClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    (event.target as HTMLInputElement).blur()
    download(fileName)
  }
  
  const sickLeaveDownloadBtn = (
    <Button
      disabled={downloading}
      variant="outlined"
      color="secondary"
      onClick={handleDownloadPdfClick}
    >
      { t(expanded ? "sickLeaveDownloadConfirmation" : "defaultTranslations:download") }
    </Button>
  )

  useEffect(()=> {
    return () => {
      setGlobalError(null)
    }
  }, [])

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      square
      elevation={0}
      classes={{
        root: parentClasses.root
      }}
    >
      <AccordionSummary
        id={`${id}-header`}
        aria-controls={`${id}-content`}
        expandIcon={expandIcon}
        classes={{
          root: parentClasses.summaryRoot
        }}
      >
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
            >
              <Box>
                <SickLeaveSectionHeader
                  startDate={startDate}
                  endDate={endDate}
                />
              </Box>
              <Box className={expanded ? classes.hidden : classes.visible}>
                <Hidden smDown>
                  {sickLeaveDownloadBtn}
                </Hidden>
              </Box>
            </Box>
          </Box>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        classes={{ root: clsx(parentClasses.detailsRoot, classes.detailsRoot) }}
      >
        <Box
          className={clsx(classes.root, classes.calendarSection)}
        >
          <CalendarBox
            startDate={startDate}
            endDate={endDate}
          />
        </Box>

        <Box className={classes.sickLeaveInfoSection}>
          <Typography variant={isSmUp ? "h4" : "h5"}>
            { t("sickLeaveDateHeader") }
          </Typography>

          <Box my={1}>
            <Typography variant={fontVariant}>
              { distanceBetweenSickLeaveDates }: { getRangeTextDates(getDatesToCompare(startDate, endDate)) }
            </Typography>
          </Box>

          <Typography variant={isSmUp ? "h4" : "h5"} className={classes.moreInfoHeader}>
            { t("sickLeaveMoreInfoHeader") }
          </Typography>

          <Box mb={3}>
            <Typography variant={fontVariant}>
              { generateAdditionalInfoLabels }
            </Typography>
          </Box>

          <Typography variant={fontVariant}>
            { `${t("sickLeaveStatus")} - ` }
            <Link
              href={PATIENT_DATA_EXTERNAL_URL}
              target="_blank"
              rel="noopener noreferrer"
              underline="always"
            >
              { PATIENT_DATA_EXTERNAL_URL.replace("https://", "") }
            </Link>
          </Typography>

          <Box
            mt={isSmUp ? 8 : 4}
            mb={isSmUp ? 0 : 2}
          >
            {sickLeaveDownloadBtn}
          </Box>
        </Box>
        <GlobalErrorSnackbar isError={!!downloadError || !!globalError}/>
      </AccordionDetails>
    </Accordion>
  )
}

export default SickLeave
