import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useOrderMedicinesSummaryStyles = makeStyles((theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: theme.spacing(2)
  },
  label: {
    fontWeight: 700
  }
}))
