import React, { ChangeEvent, forwardRef, PropsWithChildren } from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {Box, Button, Grid, Hidden, Paper, useMediaQuery, useTheme} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import {internistSpecializationId} from "../../../app.config"
import { useAppSelector } from "../../../hooks/storeHooks"
import useGoToConsultationPage from "../../../hooks/useGoToConsultationPage"
import {analytics, LogEventType} from "../../../services/analytics"
import store from "../../../store"
import {selectClinicSettings} from "../../../store/clinic/clinicSettings.selectors"
import {
  selectConsultationDocumentationByConsultationId,
  selectConsultationDocumentationLoading
} from "../../../store/consultationDocumentation/consultationDocumentation.selectors"
import { selectConsultationExamReferralsItems } from "../../../store/examReferrals/examReferrals.selectors"
import { selectUserPozDeclarationActiveStatus} from "../../../store/user/user.selectors"
import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import DoctorDetails from "../../doctor/doctorDetails/DoctorDetails.component"
import Prescription
  from "../../prescription/Prescription.component"
import RecommendationsSections from "../../recommendation/recommendationsSection/RecommendationsSection.component"
import ReferralSection from "../../referralSection/ReferralSection.component"
import SickLeaveSection from "../../sickLeave/sickLeaveSection/SickLeaveSection.component"
import ServicesToBuy from "./servicesToBuy/ServicesToBuy.component"
import {checkFeatureAvailability} from "../../../store/clinic/clinicSettings.utils"
import {
  getLatestConsultationId,
  isPrescriptionOnlyConsultation,
  isSickLeaveConsultation,
  isStationaryVisitConsultation
} from "../Consultation.utils"
import { PrescriptionModel } from "../../prescription/Prescription.types"
import {
  ConsultationListItemModel,
  ConsultationType,
  FINISHED_WITH_SUCCESS_CONSULTATION_STATUS_NUMBERS
} from "../Consultation.types"
import {useLatestConsultationDetailsStyles} from "./LatestConsultationDetails.styles"

interface LatestConsultationDetailsProps {
  consultation: ConsultationListItemModel;
  index: number;
  setShowPrescriptionsPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const RECOMMENDATIONS_XS_DOWN_TOGGLE_HEIGHT = 230
const RECOMMENDATIONS_SM_UP_TOGGLE_HEIGHT = 360

// eslint-disable-next-line react/display-name
const LatestConsultationDetails = forwardRef<unknown, PropsWithChildren<LatestConsultationDetailsProps>>(({
  consultation,
  index,
  setShowPrescriptionsPopup,
}, ref) => {
  const classes = useLatestConsultationDetailsStyles()
  const medicalDocuments = useAppSelector((state) => selectConsultationDocumentationByConsultationId(state, consultation.id))
  const examReferrals = useAppSelector((state) => selectConsultationExamReferralsItems(state, consultation.id))

  const prescriptions = medicalDocuments?.medicine_eprescriptions
  const prescriptionItems = prescriptions?.total && prescriptions?.items || []
  const doz = prescriptions?.doz || {}
  const recommendations = medicalDocuments?.recommendations
  const recommendationItems = recommendations?.total && recommendations?.items || []
  const sickLeaves = medicalDocuments?.e_referrals
  const sickLeaveItems = sickLeaves?.total && sickLeaves?.items || []
  const referrals = medicalDocuments?.prescriptions
  const referralItems = referrals?.total && referrals?.items || []
  const isPrescriptionOnly = isPrescriptionOnlyConsultation(consultation.consultationType)
  const isSickLeave = isSickLeaveConsultation(consultation.consultationType)
  const {t} = useTranslation()
  const isPozDeclarationActive = useAppSelector(selectUserPozDeclarationActiveStatus)
  const consultationType= consultation.consultationType
  const consultationLanguage= consultation.language
  const doctorSpecialization = consultation.specialization
  const doctorId = consultation.doctorId
  const { hasBookConsultationAbility } = useSelector(selectClinicSettings)
  const isFinishedWithSuccessConsultation = FINISHED_WITH_SUCCESS_CONSULTATION_STATUS_NUMBERS.includes(consultation.status_number)
  const medicalDocumentsLoading = useAppSelector(selectConsultationDocumentationLoading)
  const controlVisitAvailable = !isPozDeclarationActive
  const standardConsultation = !isPrescriptionOnly && !isSickLeave
  const isStationaryVisitPatientCheckboxEnabled = store.getState().clinic?.clinicSettings?.stationaryVisitPatient
  const showControlVisit = (controlVisitAvailable || (hasBookConsultationAbility && consultationType !== ConsultationType.SICK_LEAVE && consultationType !== ConsultationType.PRESCRIPTION_ONLY && internistSpecializationId !== doctorSpecialization?.id)) && (isStationaryVisitPatientCheckboxEnabled || !isStationaryVisitConsultation(consultationType, consultation?.stationaryVisitAddress))
  const hideConsultationBooking = checkFeatureAvailability().hideConsultationBooking
  
  const getConsultationType = (consultationType: number) => {
    switch(consultationType) {
      case ConsultationType.PRESCRIPTION_ONLY:
        return "prescription"
      case ConsultationType.SICK_LEAVE:
        return "sickleave"
    }
  }

  const { goToMakeConsultationPage } = isSickLeave || isPrescriptionOnly
    ? useGoToConsultationPage({
      visitDatePicker: getConsultationType(consultationType),
      controlVisit: true
    })
    : useGoToConsultationPage(
      null,
      `?consultationLanguage=${consultationLanguage}&doctorId=${doctorId}&specializationId=${doctorSpecialization?.id}&consultationChannel=${consultation}`)

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const recommendationsSectionsToggleHeight = isSmUp
    ? RECOMMENDATIONS_SM_UP_TOGGLE_HEIGHT
    : RECOMMENDATIONS_XS_DOWN_TOGGLE_HEIGHT

  const [accordionExpanded, setAccordionExpanded] = React.useState<string | false>(false)
  const handleAccordionChange = (accordion: string) => (event: ChangeEvent<unknown>, expanded: boolean) => {
    const target = event.target as Element

    if (!target.className.includes("MuiButton-root")) {
      setAccordionExpanded(expanded ? accordion : false)
    }
  }

  const handleBookControlVisit = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_CONSULTATION_MAKE_APPOINTMENT_AGAIN_BUTTON_CLICK)
    goToMakeConsultationPage()
  }

  const accordionExpandIcon = (
    <IconByIntegrationType {...{ component: "span", variant: "h3" }}
      iconName={"icon-caret-down"}
      className={classes.accordionSummaryIcon}
      returnTypography={true}
    />
  )

  return (
    <Paper
      ref={ref}
      id={getLatestConsultationId(index)}
      elevation={0}
      className={classes.root}
    >
      <DoctorDetails
        consultationId={consultation.id}
        consultationStartISODate={consultation.visit_at}
        consultationType={consultationType}
        consultationLanguage={consultationLanguage}
        stationaryVisitAddress={consultation.stationaryVisitAddress}
        doctorImage={{
          src: consultation.doctor?.avatar,
          alt: ""
        }}
        doctorSpecialization={doctorSpecialization}
        doctorTitle={consultation.doctorNameWithDegree}
        consultationStatusNumber={consultation.status_number}
        doctorId={doctorId}
        maxSectionHeight={standardConsultation ? recommendationsSectionsToggleHeight : {}}
        isInterestedInPoz={consultation.is_interested_in_poz}
        isPrescriptionOnly={isPrescriptionOnly}
        isSickLeave={isSickLeave}
        orderedSickLeave={consultation?.orderedSickLeave}
        isPaid={consultation.is_payed}
      />
      <Hidden
        smUp
      >
        { showControlVisit && !hideConsultationBooking && (
          <Button
            variant="contained"
            color="primary"
            className={classes.controlVisit}
            onClick={handleBookControlVisit}
          >
            { t("consultation:controlVisit") }
          </Button>
        )}
        <ServicesToBuy
          consultationId={consultation.id}
          doz={doz}
          prescriptionItems={prescriptionItems}
          examReferrals={examReferrals}
        />
      </Hidden>
      { isFinishedWithSuccessConsultation && (
        <>
          {medicalDocumentsLoading && medicalDocuments && Object.keys(medicalDocuments).length === 0 && (
            <>
              <Box my={2}>
                <Skeleton
                  variant="rect"
                  height={theme.spacing(4)}
                />
              </Box>
              <Box my={2}>
                <Skeleton
                  variant="rect"
                  height={theme.spacing(4)}
                />
              </Box>
            </>
          )}
          { (prescriptionItems.length > 0 || recommendationItems.length > 0) && (
            <Grid item xs={12}>
              <RecommendationsSections
                collapsedSize={recommendationsSectionsToggleHeight}
                recommendations={recommendationItems}
                prescriptions={prescriptionItems}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            { sickLeaveItems.map((sickLeave, index) => {
              return (sickLeave.data.niezdolnoscOd || sickLeave.data.szpitalOd)
                ? (
                  <Box my={index === 0 ? 1 : 0}>
                    <SickLeaveSection
                      sickLeave={sickLeave}
                      id="sickLeave"
                      key={`sickLeave-${sickLeave.id}`}
                      url={sickLeave.url}
                      expanded={accordionExpanded === `sickLeave-${sickLeave.id}`}
                      expandIcon={accordionExpandIcon}
                      onChange={handleAccordionChange(`sickLeave-${sickLeave.id}`)}
                      parentClasses={{
                        root: classes.accordionRoot,
                        summaryRoot: classes.accordionSummaryRoot,
                        detailsRoot: classes.accordionDetailsRoot,
                      }}
                    />
                  </Box>
                ) : null
            })}
          </Grid>

          {examReferrals?.length > 0 && (
            <ReferralSection
              id="referrals"
              expanded={accordionExpanded === "referrals"}
              expandIcon={accordionExpandIcon}
              onChange={handleAccordionChange("referrals")}
              parentClasses={{
                root: classes.accordionRoot,
                summaryRoot: classes.accordionSummaryRoot,
                detailsRoot: classes.accordionDetailsRoot,
              }}
              items={examReferrals}
              consultationId={consultation.id}
              isPoz={consultation.isPoz}
              referralId={referrals?.total ? referralItems[0].id : undefined}
              date={referrals?.total ? referralItems[0].date : undefined}
            />
          )}

          {prescriptionItems.length > 0 && doz &&
            (prescriptionItems).map((prescription: PrescriptionModel) => (
              <Prescription
                key={prescription.id}
                doz={doz}
                expanded={accordionExpanded === prescription.id}
                expandIcon={accordionExpandIcon}
                onChange={handleAccordionChange(prescription.id)}
                parentClasses={{
                  root: classes.accordionRoot,
                  summaryRoot: classes.accordionSummaryRoot,
                  detailsRoot: classes.accordionDetailsRoot,
                }}
                consultationId={consultation.id}
                setShowPrescriptionsPopup={setShowPrescriptionsPopup}
                {...prescription}
              />
            ))
          }
        </>
      )}
    </Paper>
  )
})

export default LatestConsultationDetails
