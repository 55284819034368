import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import AppDialog from "../../common/appDialog/AppDialog.component"

interface OrderBlockedPopupProps {
  isOpen: boolean;
  onClose(): void;
}

const OrderBlockedPopup: FC<OrderBlockedPopupProps> = ({isOpen, onClose}) => {
  const {t} = useTranslation()

  return (
    <AppDialog
      open={isOpen}
      onClose={onClose}
      title={t("consultation:archiveTags:otherError")}
    >
      <Box mb={1}>
        <Typography>
          {t("prescription:orderBlockedMessage")}
        </Typography>
      </Box>

    </AppDialog>
  )
}

export default OrderBlockedPopup
