export const useAwards = () => {
  const getProperPercent = (max: number, current: number, scale: number): number => {
    const propsPercent = current * 100 / max
    const result = scale * propsPercent / 100

    return result
  }

  const getNumberOfUserPoints = (points: string | undefined): number => {
    if(!points) {
      return 0
    }
    const numberPattern = /\d+/g
    const withoutDecimals = points.split(".").slice(0,1).join()
    const pts = parseInt(withoutDecimals.match( numberPattern )!.join("")) || 0

    return pts
  }

  return {
    getProperPercent,
    getNumberOfUserPoints
  }
}