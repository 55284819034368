import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { generatePath } from "react-router-dom"
import clsx from "clsx"

import PsychologyImage from "../../../../../../assets/images/healthPrevention/psychology.png"
import ImageByIntegrationType from "../../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import ActionButtons from "../actionButtons/ActionButtons.component"
import PreventionActionWrapper from "../preventionActionWrapper/PreventionActionWrapper.component"
import { useHandleRedirect } from "../PreventionActionCard.utils"
import { RoutePath } from "../../../../../../routes/Routes.types"
import { HealthPreventionPrograms } from "../../../../../../store/clinic/clinicSettings.types"
import { usePreventionActionWrapperStyles } from "../preventionActionWrapper/PreventionActionWrapper.styles"

type FillQuestionnaireProps = {
  title: string;
  description: string;
  questionnaireId: string;
  programType?: HealthPreventionPrograms|null;
  actionId?: string;
}

const FillQuestionnaire: FC<FillQuestionnaireProps> = ({ 
  title, 
  description, 
  questionnaireId ,
  programType,
  actionId
}) => {
  const { t } = useTranslation()
  const classes = usePreventionActionWrapperStyles()

  const path = generatePath(RoutePath.PREVENTION_QUESTIONNAIRE, { questionnaireId })
  const query = actionId && programType ? `?id=${actionId}&type=${programType}` : ""
  const handleFinish = useHandleRedirect(`${path}${query}`)
  const isMentalHealthProgram = programType === HealthPreventionPrograms.PROGRAM_MENTAL_HEALTH
  
  const getActionButtonLabel = () => {
    return isMentalHealthProgram 
      ? t("mentalHealth:fillQuestionnaireBtnLabel") 
      : null
  }
  
  const getImage = () => {
    if (isMentalHealthProgram) {
      return <ImageByIntegrationType
        imageSrc={PsychologyImage}
        alt="PsychologyImage"
        className={clsx(classes.image, classes.overrideImage)}
        imagePath={"psychology.png"}
      />
    }
    
    return null
  }

  return (
    <PreventionActionWrapper
      title={title}
      description={description}
      overrideImage={getImage()}
    >
      <ActionButtons
        onClick={handleFinish}
        programType={programType}
        actionButtonLabel={getActionButtonLabel()}
      />
    </PreventionActionWrapper>
  )
}

export default FillQuestionnaire
