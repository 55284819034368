import { createStyles, makeStyles } from "@material-ui/core"

import { colors } from "../../../../themes/colors"
import { mentalHealthColors } from "../../styles/mentalHealthColors"
import { Theme } from "@material-ui/core/styles"

export const useCrisisTileStyles = makeStyles((theme: Theme) => createStyles({
  boxCard: {
    color: colors.telemediWhite,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "338px",
    width: "100%",
    height: "100%",
    background: `linear-gradient(90deg, ${mentalHealthColors.darkBlue} 0%, ${mentalHealthColors.lightBlue} 100%)`,
    [theme.breakpoints.up("md")]: {
      height: "auto",
    },
  },
  title: {
    marginBottom: "unset",
    color: mentalHealthColors.lightRed,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
    marginTop: theme.spacing(1),
    flexWrap: "wrap",
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    width:"100%",
  },
  crisisHelplineWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
  },
  crisisHelplineItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    columnGap: theme.spacing(1.5),
  },
  crisisHelplineText: {
    marginTop: "3px",
  }
}))
