import React, {FC} from "react"
import {Box, Button, Typography} from "@material-ui/core"
import clsx from "clsx"

import api from "../../../api/api"
import {getMedicalDocumentFileConfig, getPrescriptionMedicalDocumentFileConfig} from "../../../api/routes"
import useConsultationLang from "../../../hooks/useConsultationLang"
import useFileDownload from "../../../hooks/useFileDownload"
import GlobalErrorSnackbar from "../../commonFormItems/globalErrorSnackbar/GlobalErrorSnackbar"
import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import ButtonLoader from "../../common/buttonLoader/ButtonLoader.component"
import {ChatMessageType, ConsultationChatItem} from "../ConsultationChat.types"
import {useConsultationChatMessageItemStyles} from "./ConsultationChatMessageItem.styles"

import {getFileIconType, getFileName, parseMessageCreatedAt} from "./ConsultationChatMessageItem.config"

interface FileChatMessageItemProps extends ConsultationChatItem {
  language: string;
}

const FileChatMessageItemComponent: FC<FileChatMessageItemProps> = (
  {
    messageRaw,
    createdAt,
    messageRawType,
    language,
  }
) => {
  const classes = useConsultationChatMessageItemStyles()
  const consultationLang = useConsultationLang()
  const time = parseMessageCreatedAt(createdAt)

  const name = getFileName(messageRawType)
  const iconName = getFileIconType(messageRawType)
  const messageRawFormatted = messageRawType === ChatMessageType.EZLA
    ? messageRaw.replace(`/${language}/consultation/ezla/`, "").replace("/download", "")
    : (messageRawType === ChatMessageType.EREFERRAL
      ? messageRaw.replace(`/${language}/referral/download/`, "").replace("/download", "") : messageRaw)

  const fileName = consultationLang.getLabel(`documents:${name}`)
  /* download file */
  const getDocumentPdf = () => api.request<Blob>(
    messageRawType === ChatMessageType.TRANS_PRESCRIPTION
      ? {...getPrescriptionMedicalDocumentFileConfig(messageRawFormatted || "")}
      : {...getMedicalDocumentFileConfig(messageRawFormatted || "")}
  )
  const {download, downloading, downloadError} = useFileDownload(getDocumentPdf)

  const handleDownloadPdfClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    (event.target as HTMLInputElement).blur()
    download(`${time} ${fileName}`)
  }
  /* e/o download file */

  return (
    <Box className={clsx(
      classes.messageItemBox,
      classes.centerBoxMessage
    )}>
      <Box className={classes.widthConditions}>
        <Box className={classes.downloadItem}>
          <Typography className={classes.fileNameData}>
            { iconName &&
              <IconByIntegrationType iconName={iconName} className={classes.fileIcon} />
            }

            <span>{ fileName }</span>
          </Typography>

          <Button
            disabled={downloading}
            className={classes.downloadButton}
            onClick={handleDownloadPdfClick}
            startIcon={ downloading
              ? <ButtonLoader position="prefix"/>
              : (
                <IconByIntegrationType {...{ component: "span", variant: "h4" }}
                  iconName={"icon-download"}
                  returnTypography={true}
                />
              )
            }
          >
            <Typography variant="body2">
              { consultationLang.getLabel("download") }
            </Typography>
          </Button>

          <GlobalErrorSnackbar isError={!!downloadError}/>
        </Box>
      </Box>
    </Box>
  )
}

export default FileChatMessageItemComponent
