import React, {FC, useEffect} from "react"
import {useTranslation} from "react-i18next"
import {Box} from "@material-ui/core"

import {useAppDispatch, useAppSelector} from "../../../hooks/storeHooks"
import { selectUserId } from "../../../store/session/session.selectors"
import {selectRandomSessionId} from "../../../store/user/user.selectors"
import {setRandomSessionId} from "../../../store/user/user.slice"
import {useSessionManagerStyles} from "../SessionManager.styles"

const generateRandomSessionId = () => Math.round((Math.random() * (900000) + 100000)).toString()

const SessionCode: FC = () => {
  const {t} = useTranslation()
  const classes = useSessionManagerStyles()
  const dispatch = useAppDispatch()
  const userId = useAppSelector(selectUserId)
  const randomSessionId = useAppSelector(selectRandomSessionId)

  useEffect(() => {
    if (!userId) {
      dispatch(setRandomSessionId(generateRandomSessionId()))
    }
  }, [])

  if (userId || !randomSessionId) {
    return null
  }

  return (
    <Box position="relative">
      <Box className={classes.sessionCodeBox}>
        {`${t("user:session")} ${randomSessionId}`}
      </Box>
    </Box>
  )
}

export default SessionCode
