import React, {FC, useState} from "react"
import {useTranslation} from "react-i18next"
import {Box, Button,TextFieldProps,useMediaQuery, useTheme} from "@material-ui/core"
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date"
import {add, format} from "date-fns"

import DatePicker from "../../commonFormItems/datePicker/DatePicker.component"

type ConsultationTermDaySelectionProps = {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
  getConsultationTerms(consultationType: number, language: string, specializationId: string, day: string): void;
  consultationLanguage: string;
  specializationId: string;
  selectedChannel: number;
  selectedDay: string;
}

const ConsultationTermDaySelection: FC<ConsultationTermDaySelectionProps> = ({
  loading, 
  setSelectedDay, 
  getConsultationTerms,
  consultationLanguage,
  specializationId,
  setLoading,
  selectedChannel,
  selectedDay,
}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [customDate, setCustomDate] = useState<string|null>(null)
  const currentDate = new Date()
  const today = format(currentDate, "yyyy-MM-dd")
  const tomorrow = format(add(currentDate, { days: 1 }), "yyyy-MM-dd")

  const handleOnChange = async (selectedDay: string, customDate?: boolean) => {
    setLoading(true)
    setSelectedDay(selectedDay)

    if (!customDate) {
      setCustomDate(null)
    }

    await getConsultationTerms(selectedChannel, consultationLanguage, specializationId, selectedDay)

    setLoading(false)
  }

  const handleSetCustomDate = (val: MaterialUiPickersDate) => {
    const formattedSelectedDate = new Date(val || "").toISOString().slice(0, 10)

    setCustomDate(formattedSelectedDate)

    return formattedSelectedDate
  }

  const isSelected = (date: string) => !customDate && date === selectedDay

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      mt={4}
      px={isMdUp ? theme.spacing(1) : 0}
      flexDirection={isMdUp ? "row" : "column"}
    >
      <Box
        mb={isMdUp ? 0 : 2}
      >
        <Button
          fullWidth
          variant={!loading && isSelected(today) ? "contained" : "outlined"}
          color="primary"
          disabled={loading}
          onClick={() => handleOnChange(today)} // today
        >
          {t("consultation:today")}
        </Button>
      </Box>
      <Box
        mb={isMdUp ? 0 : 2}
      >
        <Button
          fullWidth
          variant={!loading && isSelected(tomorrow) ? "contained" : "outlined"}
          color="primary"
          disabled={loading}
          onClick={() => handleOnChange(tomorrow)} // tomorrow
        >
          {t("consultation:tomorrow")}
        </Button>
      </Box>
      <Box
        mb={isMdUp ? 0 : 2}
      >
        <DatePicker
          disablePast
          value={selectedDay}
          fullWidth={false}
          onChange={(val) => handleOnChange(handleSetCustomDate(val), true)}
          format="yyyy-MM-dd"
          name="selectedDay"
          TextFieldComponent={(props: TextFieldProps) => {
            return (
              <div onClick={props.onClick}>
                <Button
                  fullWidth
                  variant={!loading && customDate ? "contained" : "outlined"}
                  color="primary"
                  disabled={loading}
                >
                  {customDate ?? t("consultation:anotherDay")}
                </Button>
              </div>
            )
          }}
        />
      </Box>
    </Box>
  )
}

export default ConsultationTermDaySelection
