import React, {FC, useEffect, useState} from "react"
import { useSelector } from "react-redux"
import { Box, Container, useMediaQuery, useTheme } from "@material-ui/core"

import {selectUserForcePasswordChangeModalOpen, selectUserRegulationsModalOpen} from "../store/user/user.selectors"
import AcceptRegulationsModal from "../components/acceptRegulationsModal/AcceptRegulationsModal.component"
import ForceResetPasswordModal from "../components/forceResetPasswordModal/ForceResetPasswordModal.component"
import MenuSidebarComponent from "../components/menu/mainMenu/desktopMenu/MenuSidebar.component"
import MobileMenu from "../components/menu/mainMenu/mobileMenu/MobileMenu.component"
import PageFooter from "../components/pageFooter/PageFooter.component"
import TopBar from "../components/topBar/TopBar.component"
import useLayoutStyles from "./Layout.styles"

import BaseLayout from "./BaseLayout"

interface DefaultLayoutProps {
  enableFooter?: boolean;
}

const DefaultLayout: FC<DefaultLayoutProps> = ({
  children,
  enableFooter = true
}) => {
  const classes = useLayoutStyles()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))
  const userRegulationsModalOpen = useSelector(selectUserRegulationsModalOpen)
  const userForcePasswordChangeModalOpen = useSelector(selectUserForcePasswordChangeModalOpen)

  const [menuSidebar, setMenuSidebar] = useState<HTMLDivElement | null>(null)
  const [sidebarWidth, setSidebarWidth] = useState<number>(0)

  const containerBoxMarginLeft = isSmUp ? sidebarWidth + "px" : 0

  useEffect(() => {
    if (menuSidebar) {
      setSidebarWidth(menuSidebar.offsetWidth)
    }
  }, [menuSidebar])

  return (
    <BaseLayout className="defaultLayout">
      {/*<NewNotificationPopup/>*/}

      <TopBar />

      <Box className={classes.pageContainerWithFixedTopBar}>
        { isSmUp
          ? (
            <div
              ref={el => setMenuSidebar(el)}
              className={classes.fixedMenu}
            >
              <MenuSidebarComponent />
            </div>
          )
          : (
            <MobileMenu/>
          )
        }

        <Box
          style={{ marginLeft: containerBoxMarginLeft }}
          className={classes.containerWrapper}
        >
          <Container
            maxWidth="lg"
            className={classes.contentContainer}
          >
            <Box>
              { children }
            </Box>
          </Container>
        </Box>
      </Box>
      {
        enableFooter && (
          <PageFooter/>
        )
      }
      {
        userForcePasswordChangeModalOpen && (
          <ForceResetPasswordModal/>
        )
      }
      <AcceptRegulationsModal
        isOpen={userRegulationsModalOpen/* && !userForcePasswordChangeModalOpen*/}
      />
    </BaseLayout>
  )
}

export default DefaultLayout
