import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Link, Typography} from "@material-ui/core"

import ImageByIntegrationType from "../../../imageByIntegrationType/ImageByIntegrationType"
import ButtonTextUnderlined from "../../../common/buttonTextUnderlined/ButtonTextUnderlined.component"
import {Coordinates} from "../../pharmacySearchEngine/PharmacySearchEngine.types"
import {useThankYouPageViewStyles} from "../ThankYouPageView.styles"

interface PickupSectionProps {
  title: string;
  labels: string[];
  image: string;
  imagePath: string;
  seeOnMapButton?: boolean;
  coords?: Coordinates;
}

const PickupSection: FC<PickupSectionProps> = ({
  title, 
  labels, 
  image, 
  seeOnMapButton, 
  coords,
  imagePath
}) => {
  const {t} = useTranslation()
  const classes = useThankYouPageViewStyles()

  return (
    <Box
      display="flex"
      className={classes.container}
    >
      <Box className={classes.icon}>
        <ImageByIntegrationType
          imageSrc={image}
          alt="image"
          imagePath={imagePath}
        />
      </Box>
      <Box
        className={classes.pickupInfo}
      >
        <Typography
          variant="button"
        >
          {title}
        </Typography>
        {
          labels.map((label, index) => {
            return (
              <Typography
                variant="body2"
                key={index}
                className={classes.pickupInfoItem}
              >
                {label}
              </Typography>
            )
          })
        }
        {
          seeOnMapButton && (
            <Link
              href={`https://maps.google.com/?q=${coords?.gps_latitude},${coords?.gps_longitude}`}
              target="_blank"
              rel="noopener noreferrer"
              underline="always"
            >
              <ButtonTextUnderlined
                color="primary"
                className={classes.seeOnMap}
              >
                { t("referrals:seeExamPointOnMap") }
              </ButtonTextUnderlined>
            </Link>
          )
        }
      </Box>
    </Box>
  )
}

export default PickupSection
