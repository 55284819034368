import {createStyles, makeStyles} from "@material-ui/core"

export const useDiseasesList = makeStyles(theme => createStyles({
  diseasesList: {
    display: "flex",
    gap: theme.spacing(6),
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(4),
    }
  },
}))