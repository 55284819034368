import React, { FC } from "react"
import {Container} from "@material-ui/core"

import withBaseLayout from "../../hoc/withBaseLayout"
import {useRemoveIsFirstSessionInvoked} from "../../hooks/useRemoveIsFirstSessionInvoked"
import LoginMagicLinkForm from "../../components/loginMagicLink/LoginMagicLinkForm.component"
import { useLoginMagicLinkPageStyles } from "./LoginMagicLinkPage.styles"

const LoginMagicLinkPage: FC = () => {
  const classes = useLoginMagicLinkPageStyles()
  useRemoveIsFirstSessionInvoked()

  return (
    <Container
      maxWidth={"sm"}
      className={classes.root}
    >
      <LoginMagicLinkForm />

    </Container>
  )
}

export default withBaseLayout(LoginMagicLinkPage)
