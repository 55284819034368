import React, { FC } from "react"
import { Typography } from "@material-ui/core"

import RecommendationsSection from "../recommendationsSectionWrapper/RecommendationsSectionWrapper.component"
import { PrescriptionModel } from "../../prescription/Prescription.types"
import { useMedicationsListStyles } from "./MedicationsList.styles"

interface MedicationsListProps {
  prescriptions: PrescriptionModel[];
}

const MedicationsList: FC<MedicationsListProps> = ({prescriptions}) => {
  const classes = useMedicationsListStyles()

  return (
    <RecommendationsSection titleName={"medicationsList"}>
      {prescriptions.map(prescription => (
        <ul
          key={prescription.id}
          className={classes.list}
        >
          {prescription.positions.map((position, index) => (
            <li key={index}>
              <Typography
                variant="body1"
                component="span"
              >
                {position.name}
              </Typography>
              {position.text && (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  component="span"
                >
                  {"\u2014"} {position.text}
                </Typography>
              )}
            </li>
          ))}
        </ul>
      ))}
    </RecommendationsSection>
  )
}

export default MedicationsList
