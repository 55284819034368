import React, {FC} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {useWizard} from "react-use-wizard"
import {Box} from "@material-ui/core"

import useGetFormValidationRules from "../../../../../hooks/useGetFormValidationRules"
import {usePrescriptionDrugLimits} from "../../../../../hooks/usePrescriptionDrugLimits"
import {selectUser} from "../../../../../store/user/user.selectors"
import NavigationButtons from "../../NavigationButtons/NavigationButtons.component"
import PrescriptionSection from "./PrescriptionSection/PrescriptionSection.component"
import ReferralSection from "./ReferralSection/ReferralSection.component"
import SickLeaveSection from "./SickLeaveSection/SickLeaveSection.component"
import {EmploymentType, PatientsRequirementsStepData} from "../../MedicalQuestionnaireStepperForm.types"

import {patientsRequirementsFormValidation} from "./PatientsRequirementsForm.validation"

type Props = {
  initialValues?: PatientsRequirementsStepData
  onSubmit: (values: PatientsRequirementsStepData) => void
  visitReason?: {
    isPrescriptionNeeded: boolean;
    isSickLeaveNeeded: boolean;
    isReferralNeeded: boolean;
  }
}

const PatientsRequirementsForm: FC<Props> = ({ initialValues, visitReason, onSubmit }) => {
  const {t} = useTranslation()
  const userData = useSelector(selectUser)
  const { perDrugLimit, totalDrugsLimit } = usePrescriptionDrugLimits()
  const defaultSickLeaveValues = (!initialValues?.sickLeave && visitReason?.isSickLeaveNeeded) ? {
    sickLeave: {
      address: {
        street: userData?.address.street,
        houseNumber: userData?.address.house_number,
        apartmentNumber: userData?.address.apartment_number,
        postalCode: userData?.address.postal_code,
        city: userData?.address.city,
        country: userData?.address.country,
      },
      employmentType: EmploymentType.CIVILIAN_PERSONNEL,
    }
  } : undefined
  const formMethods = useForm<PatientsRequirementsStepData>({
    defaultValues: {
      ...initialValues,
      ...defaultSickLeaveValues
    },
    resolver: useGetFormValidationRules(patientsRequirementsFormValidation(visitReason,perDrugLimit,totalDrugsLimit,t)),
  })
  const { nextStep } = useWizard()

  return (
    <FormProvider {...formMethods}>
      <Box
        component='form'
        onSubmit={formMethods.handleSubmit((data) => {
          onSubmit(data)
          nextStep()
        })}
      >
        {
          visitReason?.isSickLeaveNeeded && userData && (
            <SickLeaveSection/>
          )
        }
        {
          visitReason?.isReferralNeeded && (
            <ReferralSection/>
          )
        }
        {
          visitReason?.isPrescriptionNeeded && (
            <PrescriptionSection
              perDrugLimit={ perDrugLimit }
              totalDrugsLimit={ totalDrugsLimit }
            />
          )
        }
        <NavigationButtons/>
      </Box>
    </FormProvider>
  )
}

export default PatientsRequirementsForm
