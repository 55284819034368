import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useVideoSettingsModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaperWidth: {
      [theme.breakpoints.up("sm")]: {
        width: theme.breakpoints.values.sm,
      }
    },
    deviceBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    }
  })
)
