import React, {FC, useState} from "react"
import {useController} from "react-hook-form"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"
import clsx from "clsx"

import useGetFormValidationRules from "../../../hooks/useGetFormValidationRules"
import AppDialog from "../../common/appDialog/AppDialog.component"
import ButtonTextUnderlined from "../../common/buttonTextUnderlined/ButtonTextUnderlined.component"
import TextFieldController from "../../commonFormItems/textFieldController/TextFieldController.component"
import {UserDataType} from "../../../store/user/user.types"
import {UserPhoneNumberFormType} from "./UserDetailsSection.types"
import {useUserDetailsSectionStyles} from "./UserDetailsSection.styles"

import { userPhoneNumberValidationSchema } from "./UserDetailsSection.validation"

interface UserDetailsSectionProps {
  userData: UserDataType | null;
}

const UserDetailsSection: FC<UserDetailsSectionProps> = ({userData}) => {
  const classes = useUserDetailsSectionStyles()
  const userAddress = userData?.address
  const name = "phone_number"
  const [editPhoneDialogVisible, setEditPhoneDialogVisible] = useState<boolean>(false)
  const {t} = useTranslation()
  const userAddressSection = `${t("user:street")} ${userAddress?.street} ${userAddress?.house_number}${userAddress?.apartment_number
    ? `/${userAddress?.apartment_number}` : ""}, ${userAddress?.postal_code} ${userAddress?.city}`
  const { field: { value, onChange } } = useController({
    name
  })
  const form = useForm<UserPhoneNumberFormType>({
    defaultValues: {
      phoneNumber: userData?.phone_national_number
    },
    resolver: useGetFormValidationRules(userPhoneNumberValidationSchema),
    mode: "all",
  })

  const handleCloseEditPhoneDialog = () => {
    setEditPhoneDialogVisible(false)
  }

  const handleSubmit = form.handleSubmit(async(data) => {
    onChange(data.phoneNumber)
    setEditPhoneDialogVisible(false)
  })

  return (
    <Box>
      <Typography
        variant="h3"
        className={classes.header}
      >
        {t("orderMedicines:pharmaciesNearYou")}
      </Typography>
      <Box className={classes.userDetailsSection}>
        <Typography
          variant="h4"
          component="span"
        >
          {t("orderMedicines:userDetails")}
        </Typography>
        <ButtonTextUnderlined
          color="primary"
          className={classes.editBtn}
          onClick={() => setEditPhoneDialogVisible(true)}
        >
          {t("edit")}
        </ButtonTextUnderlined>
      </Box>
      <Typography
        className={clsx(classes.userData, classes.addressItem)}
        variant="body2"
      >
        {userData?.first_name} {userData?.last_name}
      </Typography>
      <Typography
        variant="body2"
        className={classes.addressItem}
      >
        { userAddressSection }
      </Typography>
      <Typography
        variant="body2"
        className={classes.addressItem}
      >
        { `+${userData?.phone_country_code} ${value}` }
      </Typography>
      <Typography
        variant="body2"
        className={classes.addressItem}
      >
        { userData?.email }
      </Typography>
      <AppDialog
        open={editPhoneDialogVisible}
        onClose={handleCloseEditPhoneDialog}
        actionButtonText={t("save")}
        actionButtonProps={{
          onClick: handleSubmit,
        }}
        title={t("user:editYourData")}
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <TextFieldController
              name="phoneNumber"
              label={t("user:phone")}
              className={classes.phoneNumberField}
              isRequired
            />
          </form>
        </FormProvider>
      </AppDialog>
    </Box>
  )
}

export default UserDetailsSection
