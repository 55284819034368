import {alpha, createStyles, makeStyles} from "@material-ui/core"

import {Theme} from "@material-ui/core/styles"

export const useDatePickerStyles = makeStyles<Theme, { isErrorOccurred: boolean; }>((theme) => createStyles({
  formControl: {
    marginBottom: theme.spacing(1)
  },
  root: {
    marginBottom: 0,
    boxShadow: "0px 0px 32px rgba(0, 0, 0, 0.05)",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: props => props.isErrorOccurred ? "1px" : "2px",
      borderColor: props => props.isErrorOccurred
        ? theme.palette.error.main
        : alpha(theme.palette.primary.main, 0.5)
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: props => props.isErrorOccurred ? "1px" : "2px",
      borderColor: props => props.isErrorOccurred
        ? theme.palette.error.main
        : theme.palette.primary.main
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
      fontWeight: 700,
      fontSize: `${theme.spacing(2)}px`,
      lineHeight: `${theme.spacing(3)}px`,
      textTransform: "capitalize"
    }
  },
  helperText: {
    marginLeft: "14px"
  },
  iconButton: {
    color: theme.palette.text.primary
  }
}))
