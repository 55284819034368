import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import CheckboxController from "../../../../../../../../components/CheckboxController/CheckboxController.component"

const AddressCheckbox: FC = () => {
  const {t} = useTranslation()

  return (
    <Box>
      <Box mt={3}>
        <Typography variant="body1">
          {t("consultation:sickLeaveAddressOfStay")}
        </Typography>
      </Box>
      <Box
        display="flex"
        mt={1}
        mb={2.5}
      >
        <CheckboxController
          name='sickLeave.isOtherAddressSameAsUserAddress'
          label={t("consultation:sickLeaveSameAddress")}
        />
      </Box>
    </Box>
  )
}

export default AddressCheckbox
