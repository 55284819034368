import React, {FC} from "react"
import { useParams } from "react-router"
import { Grid, useMediaQuery } from "@material-ui/core"

import { useAppSelector } from "../../../../../hooks/storeHooks"
import { selectUserId } from "../../../../../store/session/session.selectors"
import MessengerChat from "./MessengerChat/MessengerChat.component"
import MessengerThreadsList from "./MessengerThreadsList/MessengerThreadsList.component"
import SendMessageForm from "./SendMessageForm/SendMessageForm.component"
import { useMessengerChatControllerStyles } from "./MessengerChatController.styles"

const MessengerChatController: FC = () => {
  const classes = useMessengerChatControllerStyles()
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"))
  const { threadId } = useParams<{ threadId: string }>()
  const userId = useAppSelector(selectUserId)

  return (
    <Grid container>
      <Grid
        item
        container
        xs={12}
        spacing={isMdUp ? 4 : 0}
      >
        <Grid
          item
          xs={12}
          md={4}
          className={classes.threadsListWrapper}
        >
          <MessengerThreadsList
            threadId={threadId}
          />
        </Grid>
        <Grid
          item 
          xs={12}
          md={8}
          className={classes.chatMessagesWrapper}
        >
          <MessengerChat
            threadId={threadId}
            userId={userId}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <SendMessageForm
          threadId={threadId}
          userId={userId}
        />
      </Grid>
    </Grid>
  )
}

export default MessengerChatController