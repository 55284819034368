import {gql} from "@apollo/client"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"

export type AddWeightResponse = {
  addWeight: {
    id: string;
  }
}

export type AddWeightVariables = {
  value: string;
  createdAt: MaterialUiPickersDate;
}

export const ADD_WEIGHT = gql`
  mutation AddWeight(
    $value: numeric!,
    $createdAt: timestamptz!
  ) {
    addWeight: insert_examinations_weight_answers_one(object: {
      value: $value,
      created_at: $createdAt}
    ) {
      id
    }
  }
`
