import React, {ComponentType, FC} from "react"
import {Redirect, Route, RouteProps} from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../hooks/storeHooks"
import {selectUserId} from "../store/session/session.selectors"
import { initSessionByFusionAuth } from "../store/session/session.slice"
import { getUserData } from "../store/user/user.slice"
import {loginLogoutRedirectUrl} from "../store/session/session.utils"

interface PrivateRouteProps extends RouteProps {
  // eslint-disable-next-line
  component: ComponentType<any>;
  authenticated: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  authenticated,
  ...rest
}) => {
  const dispatch = useAppDispatch()
  const id = useAppSelector(selectUserId)

  return (
    <Route
      {...rest}
      render={(props) => {
        const redirectLink = loginLogoutRedirectUrl()

        //temporary test
        if ((window.location.href.includes("consultation") || window.location.href.includes("consultation-payment") || window.location.href.includes("survey") || window.location.href.includes("check-declaration")) && !authenticated) {
          dispatch(initSessionByFusionAuth())
          return <Component {...props} />
        }

        if (!authenticated) {
          dispatch(initSessionByFusionAuth())
          return
        }

        if (authenticated) { // refresh user data on every private route change
          dispatch(getUserData({id}))
        }

        return !authenticated
          ? <Redirect to={redirectLink}/>
          : <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute
