import {useHistory} from "react-router-dom"

import {RtkRequest} from "../types/RtkRequest"

export const useRequestHandler = <Data, Response>(
  request: RtkRequest<Data, Response>,
  { redirectTo, successCallback }: {
    redirectTo?: string,
    successCallback?: () => void
  },
) => {
  const { push } = useHistory()

  return (data: Data) =>
    request(data)
      .unwrap()
      .then(() => {
        if (redirectTo) {
          push(redirectTo)
        }
        successCallback?.()
      })

}
