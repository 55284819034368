import React, {FC, useState} from "react"
import {useTranslation} from "react-i18next"

import api from "../../api/api"
import { postUserFileConfig } from "../../api/routes"
import AppDialog from "../common/appDialog/AppDialog.component"
import ButtonLoader from "../common/buttonLoader/ButtonLoader.component"
import FileDropzone from "../commonFormItems/fileDropzone/FileDropzone.component"

interface AddFileModalProps {
  open: boolean;
  onClose(): void;
  saving: boolean;
  setSaving(saving: boolean): void;
  onSuccess(): void;
}

const AddFileModal: FC<AddFileModalProps> = ({
  open,
  onClose,
  saving,
  setSaving,
  onSuccess,
}) => {
  const { t } = useTranslation()

  const [errors, setErrors] = useState<string[]>([])
  const [file, setFile] = useState<File | null>(null)

  const handleFileChange = (files: File[]) => {
    setFile(files.length ? files[0] : null)
  }

  const handleUploadFilesSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()
    setErrors([])
    setSaving(true)

    if (file) {
      const formData = new FormData()
      formData.append("file", file)

      try {
        await api.request({
          ...postUserFileConfig,
          data: formData
        })
        onSuccess()
        onClose()
        setFile(null)
      } catch (e) {
        if (e.response?.data?.errors) {
          setErrors(e.response.data.errors.map((error: string) => t(`errors:${error}`)))
        }
      }
    }

    setSaving(false)
  }

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
      title={t("chatConsultation:addFile")}
      actionButtonText={t("add")}
      actionButtonProps={{
        disabled: !file || saving,
        onClick: handleUploadFilesSubmit,
        startIcon: saving && <ButtonLoader position="prefix"/>
      }}
    >
      <FileDropzone
        apiErrors={errors}
        resetApiErrors={() => setErrors([])}
        handleInputChange={handleFileChange}
      />
    </AppDialog>
  )
}

export default AddFileModal