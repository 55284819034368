import {AxiosResponse} from "axios"

import {isObject} from "../utils/isObject"
import {V4ValidationErrorResponse} from "../types/V4ErrorResponse"

export const isBaseQueryError = (error: unknown): error is {
  status?: AxiosResponse["status"];
  data?: AxiosResponse["data"];
} => {
  return Boolean(
    error
    && isObject(error)
    && ("data" in error || "status" in error)
  )
}

export const isV4ValidationErrorResponse =
  <Fields>(error: unknown): error is V4ValidationErrorResponse<keyof Fields> => {
    return isObject(error) && "errors" in error
  }
