import React, {FC} from "react"

import GlobalFormErrorMessage from "../globalFormErrorMessage/GlobalFormErrorMessage.component"
import {useGlobalErrorHandler} from "./GlobalFormErrorMessageHandlers.utils"

type GlobalFormErrorMessageHandlersProps = {
  error?: unknown;
}

const GlobalFormErrorMessageHandlers: FC<GlobalFormErrorMessageHandlersProps> = ({ error }) => {
  const message = useGlobalErrorHandler(error)

  if (!message) {
    return null
  }

  return (
    <GlobalFormErrorMessage message={message} />
  )
}

export default GlobalFormErrorMessageHandlers
