import React, {ChangeEvent, FC, ReactNode, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Box, Grid} from "@material-ui/core"

import CountrySelect from "../commonFormItems/countrySelect/CountrySelect.component"
import TextFieldController from "../commonFormItems/textFieldController/TextFieldController.component"
import {Countries} from "../commonFormItems/countrySelect/Country.types"
import {useSickLeaveSectionStyles} from "./SickLeaveSurvey.styles"

interface ForeignWorkerProps {
  countriesData: Countries;
  nationality?: string;
  checkboxes: ReactNode;
  setValue(name: string, value: string): void;
}

const ForeignWorker: FC<ForeignWorkerProps> = ({
  countriesData,
  nationality,
  setValue,
  checkboxes
}) => {
  const {t} = useTranslation()
  const fieldPrefix = "_foreignWorker"
  const [selectedCountry, setSelectedCountry] = useState<string>(nationality || "PL")
  const classes = useSickLeaveSectionStyles()

  const handleOnChangeCountry = (event: ChangeEvent<HTMLInputElement>) => {
    const country = event.target.value

    setSelectedCountry(country)
  }

  useEffect(() => {
    if (selectedCountry) {
      setValue("country_foreignWorker", selectedCountry)
    }
  }, [selectedCountry])

  return (
    <Grid container item spacing={1}>
      <Grid item xs={12} sm={6} lg={8}>
        <TextFieldController
          name={`companyName${fieldPrefix}`}
          label={t("user:companyName")}
          required
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <TextFieldController
          name={`nip${fieldPrefix}`}
          label={t("user:companyNip")}
          required
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={8}>
        <TextFieldController
          name={`street${fieldPrefix}`}
          label={t("user:street")}
          required
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={3} lg={2}>
        <TextFieldController
          name={`houseNumber${fieldPrefix}`}
          label={t("user:houseNumber")}
          required
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={3} lg={2}>
        <TextFieldController
          name={`apartmentNumber${fieldPrefix}`}
          label={t("user:apartmentNumber")}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={4} spacing={0}>
        <TextFieldController
          name={`postalCode${fieldPrefix}`}
          label={t("user:postalCode")}
          required
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={8}>
        <TextFieldController
          name={`city${fieldPrefix}`}
          label={t("user:city")}
          required
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Box width="100%">
        <CountrySelect
          fullWidth
          className={classes.countrySelect}
          value={selectedCountry}
          required
          name={`country${fieldPrefix}`}
          onChange={handleOnChangeCountry}
          countriesData={undefined}
        />
      </Box>
      <Box width="100%" mb={4}>
        {checkboxes}
      </Box>
    </Grid>
  )
}

export default ForeignWorker
