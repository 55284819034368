import {alpha, makeStyles} from "@material-ui/core"

import {boxShadows} from "../../../themes/shadows"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useUserMenuStyles = makeStyles((theme: Theme) => createStyles({
  menuPaper: {
    marginTop: theme.spacing(1.5),
    boxShadow: boxShadows.shadow4,
  },
  listRoot: {
    position: "relative",
    width: "100%",
    maxWidth: "400px",
    paddingBottom: theme.spacing(0),
  },
  listSubheader: {
    padding: theme.spacing(4, 4, 3),
    marginRight: theme.spacing(7), // for logout text
  },
  listItemRoot: {
    padding: theme.spacing(2, 3),

    "&:last-child": {
      marginBottom: theme.spacing(2.5),
      "& > .MuiListItemIcon-root": {
        paddingLeft: "3px",
      }
    }
  },
  listItemIconRoot: {
    color: theme.palette.text.primary,
    ...theme.typography.h4,
    marginRight: theme.spacing(2),
  },
  listItemTextRoot: {
    color: theme.palette.text.primary,
    ...theme.typography.h4,
  },
  dividerRoot: {
    color: theme.palette.grey[100],
    margin: theme.spacing(0, 4),
  },
  childListItemWrapper: {
    "&:last-of-type $childListItemRoot": {
      paddingBottom: theme.spacing(5),
    },
  },
  childListItemRoot: {
    padding: theme.spacing(3, 4),
  },
  listItemAvatarRoot: {
    paddingRight: theme.spacing(2),
  },
  customHover: {
    transition: "background-color 0.3s ease-in-out",
    "&:hover, &:focus": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
}))
