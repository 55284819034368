import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useMedialExamsPointsStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    background: theme.palette.background.default,
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(1.5),
  },
  address: {
    marginBottom: theme.spacing(1)
  },
  label: {
    color: theme.palette.grey[600]
  },
  buttonEditAddress: {
    marginTop: theme.spacing(1.5)
  }
}))
