import React, {FC} from "react"
import {Box, Typography} from "@material-ui/core"

import {useFormFieldStyles} from "./FormField.styles"

interface FormFieldProps {
  title: string;
}

const FormField: FC<FormFieldProps> = ({ title, children }) => {
  const classes = useFormFieldStyles()

  return (
    <Box className={classes.root}>
      <Typography
        variant="h5"
        className={classes.label}
      >
        {title}
      </Typography>
      <Box
        className={classes.inputWrapper}
      >
        {children}
      </Box>
    </Box>
  )
}

export default FormField
