import { dates } from "./common/dates"
import awards from "./lokalise/it/awards.json"
import b2bClinic from "./lokalise/it/b2bClinic.json"
import calendar from "./lokalise/it/calendar.json"
import chatConsultation from "./lokalise/it/chatConsultation.json"
import consultation from "./lokalise/it/consultation.json"
import consultationNestedMenu from "./lokalise/it/consultationNestedMenu.json"
import defaultTranslations from "./lokalise/it/defaultTranslations.json"
import diaryMeasurement from "./lokalise/it/diaryMeasurement.json"
import diseaseManagement from "./lokalise/it/diseaseManagement.json"
import documents from "./lokalise/it/documents.json"
import emptyConsultationsBox from "./lokalise/it/emptyConsultationsBox.json"
import errors from "./lokalise/it/errors.json"
import exam from "./lokalise/it/exam.json"
import finances from "./lokalise/it/finances.json"
import forgotPassword from "./lokalise/it/forgotPassword.json"
import healthPlanner from "./lokalise/it/healthPlanner.json"
import healthPlannerActions from "./lokalise/it/healthPlannerActions.json"
import laboratory from "./lokalise/it/laboratory.json"
import login from "./lokalise/it/login.json"
import loginPagePopup from "./lokalise/it/loginPagePopup.json"
import medicalQuestionnaire from "./lokalise/it/medicalQuestionnaire.json"
import notifications from "./lokalise/it/notifications.json"
import orderMedicines from "./lokalise/it/orderMedicines.json"
import pageFooter from "./lokalise/it/pageFooter.json"
import passwordless from "./lokalise/it/passwordless.json"
import payments from "./lokalise/it/payments.json"
import prescription from "./lokalise/it/prescription.json"
import preventionQuestionnaires from "./lokalise/it/preventionQuestionnaires.json"
import recommendations from "./lokalise/it/recommendations.json"
import referrals from "./lokalise/it/referrals.json"
import sessionManager from "./lokalise/it/sessionManager.json"
import stagingSettings from "./lokalise/it/stagingSettings.json"
import user from "./lokalise/it/user.json"
import videoChatConsultation from "./lokalise/it/videoChatConsultation.json"

const it = {
  medicalQuestionnaire,
  defaultTranslations,
  dates,
  consultation,
  chatConsultation,
  videoChatConsultation,
  user,
  recommendations,
  exam,
  prescription,
  emptyConsultationsBox,
  login,
  forgotPassword,
  referrals,
  calendar,
  errors,
  sessionManager,
  notifications,
  documents,
  finances,
  pageFooter,
  laboratory,
  stagingSettings,
  payments,
  b2bClinic,
  orderMedicines,
  preventionQuestionnaires,
  awards,
  diaryMeasurement,
  healthPlannerActions,
  diseaseManagement,
  healthPlanner,
  consultationNestedMenu,
  passwordless,
  loginPagePopup
}

export default it
