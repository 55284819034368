import React, { FC, useEffect,useState } from "react"
import {Box, MenuItem, TextField, useTheme} from "@material-ui/core"
import OT, {Device, Publisher} from "@opentok/client"

import useConsultationLang from "../../../../hooks/useConsultationLang"
import AppDialog from "../../../common/appDialog/AppDialog.component"
import LoaderBox from "../../../common/loaderBox/LoaderBox.component"
import {CameraSource} from "../ConsultationVideo.types"
import {useVideoSettingsModalStyles} from "./VideoSettingsModal.styles"

interface VideoSettingsModalProps {
  open: boolean;
  onClose: () => void;
  publisher: Publisher;
}

const VideoSettingsModal: FC<VideoSettingsModalProps> = (
  {
    open,
    onClose,
    publisher,
  }
) => {
  const consultationLang = useConsultationLang()
  const theme = useTheme()
  const classes = useVideoSettingsModalStyles()

  const [videoInputs, setVideoInputs] = useState<Device[]>([])
  const [audioInputs, setAudioInputs] = useState<Device[]>([])

  const [selectedCamera, setSelectedCamera] = useState<string | null>()
  const [selectedAudio, setSelectedAudio] = useState<string>()

  const [loading, setLoading] = useState<boolean>(false)
  const [cameraChanging, setCameraChanging] = useState<boolean>(false)
  const [audioChanging, setAudioChanging] = useState<boolean>(false)
  const isAnyDeviceChanging = cameraChanging || audioChanging

  const handleCameraSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCamera(event.target.value as string)
  }
  const handleAudioSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedAudio(event.target.value as string)
  }

  const handleSubmitCameraChange = () => {
    setCameraChanging(true)
    if (selectedCamera) {
      publisher.setVideoSource(selectedCamera).finally(() => {
        setCameraChanging(false)
      })
    }
  }

  const handleSubmitAudioChange = () => {
    setAudioChanging(true)
    if (selectedAudio) {
      publisher.setAudioSource(selectedAudio).finally(() => {
        setAudioChanging(false)
      })
    }
  }

  const handleSubmitDevicesChange = () => {
    handleSubmitCameraChange()
    handleSubmitAudioChange()
  }

  const getDevices = async () => {
    await OT.getDevices((err, devices: Device[] | undefined) => {
      if (devices) {
        const audioDevicesInputs = devices.filter((device) => device.kind === "audioInput")
        const videoDevicesInputs = devices.filter((device) => device.kind === "videoInput")
        setAudioInputs(audioDevicesInputs)
        setVideoInputs(videoDevicesInputs)

        const currentVideoSource: CameraSource = publisher.getVideoSource()
        setSelectedCamera(currentVideoSource?.deviceId)

        const currentAudioSource: MediaStreamTrack = publisher.getAudioSource()
        audioDevicesInputs.find((device) => {
          if (currentAudioSource && device.label === currentAudioSource.label) {
            setSelectedAudio(device.deviceId)
          }
        })
      }
    })
  }

  useEffect(() => {
    if (open) {
      setLoading(true)
      getDevices().finally(() => setLoading(false))
    }

    return () => {
      setLoading(false)
    }
  }, [open])

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={consultationLang.getLabel("videoChatConsultation:settings")}
      classes={{
        paperWidthSm: classes.dialogPaperWidth
      }}
      actionButtonText={consultationLang.getLabel("videoChatConsultation:confirm")}
      actionButtonProps={{
        onClick: handleSubmitDevicesChange,
        disabled: isAnyDeviceChanging,
      }}
      closeButtonText={consultationLang.getLabel("cancel")}
    >
      { (loading || isAnyDeviceChanging)
        ? (
          <LoaderBox
            size={theme.spacing(8)}
            thickness={2}
            boxType="appDialog"
          />
        )
        : (
          <Box mb={2} component="form">
            <Box className={classes.deviceBox}>
              <TextField
                label={consultationLang.getLabel("videoChatConsultation:camera")}
                placeholder={consultationLang.getLabel("videoChatConsultation:camera")}
                select
                value={selectedCamera || ""}
                onChange={handleCameraSelectChange}
                fullWidth
              >
                { videoInputs.map((videoInput) =>
                  <MenuItem
                    key={videoInput.deviceId}
                    value={videoInput.deviceId}
                  >
                    { videoInput.label }
                  </MenuItem>
                )}
              </TextField>
            </Box>

            <Box className={classes.deviceBox}>
              <TextField
                label={consultationLang.getLabel("videoChatConsultation:audioAndMicrophone")}
                placeholder={consultationLang.getLabel("videoChatConsultation:audioAndMicrophone")}
                select
                value={selectedAudio || ""}
                onChange={handleAudioSelectChange}
                fullWidth
              >
                { audioInputs.map((audioInput) =>
                  <MenuItem
                    key={audioInput.deviceId}
                    value={audioInput.deviceId}
                  >
                    { audioInput.label }
                  </MenuItem>
                )}
              </TextField>
            </Box>
          </Box>
        )
      }
    </AppDialog>
  )
}

export default VideoSettingsModal
