import {gql} from "@apollo/client"

export const GET_USER_QUESTIONNAIRE_VALUES = gql`
  query GetUsersQuestionnaireValues {
    usersQuestionnaireValues: users_questionnaire_values {
      globalQuestionId: global_question_id
      type
      pickerId: picker_id
      textFieldValue: text_field_value
      checkboxIds: checkbox_ids
    }
  }
`