import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import { useSelector } from "react-redux"
import {useHistory} from "react-router"
import {NavLink} from "react-router-dom"
import {AppBar, Box, Button, Toolbar, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import useIsB2BClinic from "../../hooks/useIsB2BClinic"
import { isDeclarationSignedAfterSpecificDate } from "../../pages/loyalityProgram/LoyalityProgramPage"
import {selectClinicSettings} from "../../store/clinic/clinicSettings.selectors"
import { selectUser, selectUserFirstName, selectUserInitialLoading, selectUserLastName, selectUserPozDeclarationActiveStatus } from "../../store/user/user.selectors"
import Logotype from "../common/logotype/Logotype"
import IconByIntegrationType from "../iconByIntegrationType/IconByIntegrationType"
import NfzStatus from "../common/nfzStatus/NfzStatus.component"
import UserMenu from "../menu/userMenu/UserMenu.component"
import UserMenuMobile from "../menu/userMenuMobile/UserMenuMobile.component"
import SessionManager from "../sessionManager/SessionManager.component"
import { checkIfLastPeselCharIsEven } from "./LoyalityProgramBadge/LoyalityProgramBadge.utils"
import {useTopBarStyles} from "./TopBar.styles"

import LoyalityProgramBadge from "./LoyalityProgramBadge/LoyalityProgramBadge"

interface TopBarProps {
  onlyLogo?: boolean;
}

const TopBar: FC<TopBarProps> = ({onlyLogo = false}) => {
  const classes = useTopBarStyles()
  const {t, i18n} = useTranslation()
  const { goBack } = useHistory()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const clinicSettings = useSelector(selectClinicSettings)
  const userData = useSelector(selectUser)

  const userDataInitialLoading = useSelector(selectUserInitialLoading)
  const userFirstName = useSelector(selectUserFirstName) || ""
  const userLastName = useSelector(selectUserLastName) || ""
  const customLogoType = clinicSettings?.frontendSettings?.images?.logoWatermark

  const { B2BClinic } = useIsB2BClinic()
  const signedAfterDate = isDeclarationSignedAfterSpecificDate()
  const isPozDeclarationActive = useSelector(selectUserPozDeclarationActiveStatus)
  const shouldShowLoyalityProgramBadge = checkIfLastPeselCharIsEven(userData?.pesel) && isPozDeclarationActive && !B2BClinic && signedAfterDate
  
  return (
    <AppBar
      classes={{
        root: classes.root
      }}
      color={"default"}
      elevation={0}
    >
      <Toolbar
        classes={{
          root: classes.toolbarRoot
        }}
      >
        { onlyLogo &&
          <Button
            className={classes.backButton}
            onClick={goBack}
          >
            <IconByIntegrationType iconName={"icon-caret-left"} className={classes.backButtonIcon}/>
          </Button>
        }
        <Box m={onlyLogo ? "auto" : "0"}>
          <Typography variant="h1">
            <NavLink to={`/${i18n.language}`}>
              {
                customLogoType
                  ? <img className={classes.customLogoType} src={customLogoType} alt={window.location.origin} />
                  : (
                    <Logotype
                      title={t("mainPage")}
                      logoFill={theme.palette.primary.main}
                      textFill={theme.palette.text.primary}
                    />
                  )
              }
            </NavLink>
          </Typography>
        </Box>

        { !onlyLogo && (
          <Box
            display="flex"
            alignItems="center"
            ml="auto"
          >
            { !!userFirstName && (
              <SessionManager/>
            )}

            { isMdUp
              ? (
                <>
                  {/*<Box ml={10}>*/}
                  {/*  <Notifications/>*/}
                  {/*</Box>*/}

                  { !userDataInitialLoading && (
                    <Box mx={10}>
                      <NfzStatus/>
                    </Box>
                  )}
                  
                  {shouldShowLoyalityProgramBadge && <LoyalityProgramBadge />}
                
                  <Typography variant={"body2"}>
                    { userDataInitialLoading
                      ? <Skeleton width={theme.spacing(16)}/>
                      : `${userFirstName} ${userLastName}`
                    }
                  </Typography>

                  <Box ml={1}>
                    <UserMenu
                      disabled={userDataInitialLoading}
                    />
                  </Box>
                </>
              ) : (
                <>
                  {shouldShowLoyalityProgramBadge && <LoyalityProgramBadge size={5.5} fontVariant='h4' />}
                  <Box ml={1}>
                    <UserMenuMobile
                      disabled={userDataInitialLoading}
                    />
                  </Box>
                </>
              )
            }
          </Box>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
