import * as Sentry from "@sentry/react"
import {Severity} from "@sentry/react"
import i18n from "i18next"

import {getManyUserAccountsByLogin} from "../../api/routes"
import {v4Service} from "../store.service"
import {GetAccountsResponse, LoginAccount} from "./loginAccounts.types"

export const loginAccountsApi = v4Service.injectEndpoints({
  endpoints: build => ({
    getAccounts: build.query<LoginAccount[], string>({
      query: (login) => ({
        ...getManyUserAccountsByLogin(i18n.language),
        params: {
          login
        }
      }),
      transformResponse: (response: GetAccountsResponse) => response.result,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          await queryFulfilled
        } catch (error) {
          Sentry.captureException(error, { level: Severity.Debug})
        }
      }
    })
  })
})

export const { useLazyGetAccountsQuery } = loginAccountsApi
