import React, { FC } from "react"

import consultationsLoader from "../../../../assets/animations/consultationsLoader.json"
import LottieAnimation from "../LottieAnimation.component"

interface ConsultationsLoaderProps {}

const ConsultationsLoader: FC<ConsultationsLoaderProps> = () => {
  return (
    <LottieAnimation
      animationData={consultationsLoader}
    />
  )
}

export default ConsultationsLoader