import React, {FC} from "react"
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@material-ui/core"

import CheckboxController from "../../../../commonFormItems/checkboxController/CheckboxController.component"
import { CollapseCheckboxControllerProps } from "./CollapseCheckboxController.types"
import { useCollapseCheckboxControllerStyles } from "./CollapseCheckboxController.styles"

export const CollapseCheckboxController: FC<CollapseCheckboxControllerProps> = ({
  name,
  label,
  options,
  nestedItems,
  expanded,
  row,
}) => {
  const { accordion, accordionSummary, accordionDetails } = useCollapseCheckboxControllerStyles()
  return  (
    <Accordion
      elevation={0}
      classes={{ root: accordion }}
      expanded={expanded || !nestedItems}
    >
      <AccordionSummary
        classes={{ root: accordionSummary }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <Typography
            variant="body1"
          >
            {label}
          </Typography>
          <CheckboxController
            name={name}
            label=""
            disabled={false}
            options={options}
            row={row}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails classes={{ root: accordionDetails }}>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
        >
          {nestedItems}
        </Box>

      </AccordionDetails>
    </Accordion>
  )
}