import {gql} from "@apollo/client"

export interface BloodPressureMeasurementsModel {
  createdAt: string;
  id: string;
  diastolicPressure: number;
  systolicPressure: number;
}
    
export interface BloodPressureMeasurementsData {
  bloodPressureMeasurements: BloodPressureMeasurementsModel[]
}

export const GET_USER_BLOOD_PRESSURE_MEASUREMENTS = gql`
  query getUserBloodPressureMeasurements {
    bloodPressureMeasurements: examinations_blood_pressure_answers(order_by: {created_at: desc}) {
      systolicPressure: systolic_pressure
      diastolicPressure: diastolic_pressure
      createdAt: created_at
      id
    }
  }
`
