import { createStyles, makeStyles, Theme } from "@material-ui/core"

import { typography } from "../../themes/default/typography"

export const useMedicalSurveyStyles = makeStyles((theme: Theme) => createStyles({
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    padding: theme.spacing(3),
    background: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      height: "auto",
      padding: theme.spacing(5),
    },
    "& .MuiFormControl-root": {
      marginLeft: 0,
    }
  },
  textarea: {
    minHeight: 230,
    maxHeight: 230,
    borderRadius: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    ...typography.body1
  },
  select: {
    maxWidth: 240,
    "&:placeholder": {
      background: "blue",
      width: 20,
    },
  },
  uploadDropzone: {
    borderRadius: theme.spacing(1),
    width: "100%",
  },
}))