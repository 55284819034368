import {useState} from "react"
import {useTranslation} from "react-i18next"
import {useHistory, useParams} from "react-router"
import {useMutation, useQuery} from "@apollo/client"

import {ADD_ANSWER, AddAnswerResponse, AddAnswerVariables} from "../../api/mutations/addAnswer"
import {
  GET_DISEASE_BY_ID,
  GetDiseaseByIdResponse,
  GetDiseaseByIdVariables
} from "../../api/queries/getDiseaseById"
import {
  GET_QUESTIONNAIRE_TEMPLATE,
  GetQuestionnaireTemplateResponse, GetQuestionnaireTemplateVariables
} from "../../api/queries/getQuestionnaireTemplate"
import {RoutePath} from "../../../../routes/Routes.types"
import {CompletedQuestionnaireTemplate} from "../preventionQuestionnaire/genericStepForm/GenericStepForm.types"

export const usePreventionDiseasesQuestionnaire = () => {
  const { diseaseId } = useParams<{ diseaseId: string; }>()
  const { push } = useHistory()
  const { i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false) 
  
  const {
    data: diseaseResponse,
    loading: isDiseaseLoading,
    called: isDiseaseCalled
  } = useQuery<GetDiseaseByIdResponse, GetDiseaseByIdVariables>(
    GET_DISEASE_BY_ID,
    {
      variables: {
        diseaseId
      },
      context: { clientName: "HealthPlanner" },
      onCompleted: (data) => {
        if (data.usersDiseaseProbability?.[0]?.probability || data.usersDisease[0]?.id) {
          push(`/${i18n.language}${RoutePath.PREVENTION_DISEASE_DETAILS.replace(":diseaseId", diseaseId)}`)
        }
      }
    }
  )
  const {
    data: questionnaireTemplateResponse,
    loading: isQuestionnaireLoading
  } = useQuery<GetQuestionnaireTemplateResponse, GetQuestionnaireTemplateVariables>(
    GET_QUESTIONNAIRE_TEMPLATE,
    {
      variables: {
        questionnaireId: diseaseResponse?.disease.questionnaireId ?? ""
      },
      context: { clientName: "HealthPlanner" },
      skip: isDiseaseLoading && isDiseaseCalled
    }
  )

  const [
    handleSendAnswer,
    {
      data: addAnswerResponse
    }
  ] = useMutation<
  AddAnswerResponse,
  AddAnswerVariables
  >(
    ADD_ANSWER,
    {
      context: { clientName: "HealthPlanner" }
    }
  )

  const handleSubmit = async (data: CompletedQuestionnaireTemplate) => {
    setIsLoading(true)

    await handleSendAnswer({
      variables: {
        templateId: questionnaireTemplateResponse?.questionnaireTemplates[0].template.id ?? "",
        answer: data
      }
    })
    await new Promise(resolve => setTimeout(resolve, 2000))//temporary solution TODO: set subscription for disease probability

    setIsLoading(false)
  }

  return {
    isSuccess: addAnswerResponse?.addAnswer.id,
    questionnaireId: diseaseResponse?.disease.questionnaireId,
    template: questionnaireTemplateResponse?.questionnaireTemplates[0].template,
    isLoading: isQuestionnaireLoading || isDiseaseLoading,
    handleSubmit,
    isSubmittingAnswer: isLoading
  }
}