import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"

import {ApiError} from "../../../enums/ApiErrorCode"
import {SearchParams} from "../../../enums/SearchParams"
import {useDeleteSearchParam} from "../../../hooks/useDeleteSearchParam"
import {useQueryParam} from "../../../hooks/useSearchParams"

export const useParseV4ErrorTypeSearchParamToErrorMessage = () => {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const deleteSearchParam = useDeleteSearchParam()
  const v4ErrorType = useQueryParam(SearchParams.V4_ERROR_TYPE)

  useEffect(() => {
    if (Number(v4ErrorType) === ApiError.SIGNAL_IDUNA_FORGOT_PASSWORD_ACCESS_TOKEN_EXPIRED) {
      setErrorMessage(t("login:resetPasswordSessionExpiredMessage"))
    }
  }, [v4ErrorType])

  useEffect(
    () => {
      if (errorMessage) {
        deleteSearchParam(SearchParams.V4_ERROR_TYPE)
      }
    },
    [errorMessage]
  )

  return errorMessage
}
