import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Skeleton } from "@material-ui/lab"
import { CancelTokenSource } from "axios"

import api, { ApiInstance } from "../../../api/api"
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks"
import useGoToConsultationPage from "../../../hooks/useGoToConsultationPage"
import { selectComingConsultationsTotal } from "../../../store/consultations/consultations.selectors"
import {
  addComingConsultations,
  setComingConsultationsTotal,
} from "../../../store/consultations/consultations.slice"
import ShowMoreButton from "../../common/showMoreButton/ShowMoreButton.component"
import ComingConsultationDetails from "../comingConsultationDetails/ComingConsultationDetails.component"
import { redirectToError500Page } from "../../../utils/handleErrors"
import {
  COMING_CONSULTATION_ITEMS_LIMIT,
  getConsultations,
  refreshComingConsultationsData,
} from "../Consultation.utils"
import {
  ConsultationListItemModel,
  ConsultationListParamsSort,
  ConsultationListParamsStatus,
  GetConsultationsListParams,
} from "../Consultation.types"
import "react-multi-carousel/lib/styles.css"

interface ComingConsultationsListProps {
  items: ConsultationListItemModel[]
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  B2BClinic: boolean;
}

const ComingConsultationsList: FC<ComingConsultationsListProps> = ({items, setLoading, loading, B2BClinic}) => {
  const {i18n} = useTranslation()
  const dispatch = useAppDispatch()
  const total = useAppSelector(selectComingConsultationsTotal)
  const [page, setPage] = useState<number>(1)
  const [cancelToken, setCancelToken] = useState<CancelTokenSource["token"]>()
  const { goToMakeConsultationPage } = useGoToConsultationPage()

  const getNextPage = async () => {
    setLoading(true)
    try {
      const params: GetConsultationsListParams = {
        page,
        limit: COMING_CONSULTATION_ITEMS_LIMIT,
        status: ConsultationListParamsStatus.PLANNED,
        sort: ConsultationListParamsSort.ASC,
        lang: i18n.language,
      }
      const {data} = await getConsultations(params, cancelToken)
      dispatch(setComingConsultationsTotal(data.total))
      dispatch(addComingConsultations(data.items))
      setPage(prevState => prevState + 1)
    } catch (e) {
      console.error(e)
      redirectToError500Page(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()
    setCancelToken(requestSource.token)
    return() => {
      setLoading(false)
      requestSource.cancel("Request interrupted by page change")
      setCancelToken(undefined)
    }
  }, [])

  return (
    items.length
      ? (
        <>
          {
            items.map(consultation => (
              <ComingConsultationDetails
                key={consultation.id}
                id={consultation.id}
                patientId={consultation.patientId}
                doctor={consultation.doctor}
                doctorId={consultation.doctorId}
                doctorNameWithDegree={consultation.doctorNameWithDegree}
                doctorSpecialization={consultation.specialization}
                visitAt={consultation.visit_at}
                consultationType={consultation.consultationType}
                isPayed={consultation.is_payed}
                stationaryVisitAddress={consultation.stationaryVisitAddress}
                paymentLink={consultation.paymentLink}
                refreshConsultations={() => refreshComingConsultationsData(setLoading, page, goToMakeConsultationPage, B2BClinic)}
                statusNumber={consultation.status_number}
                consultationLanguage={consultation.language}
                isInterestedInPoz={consultation.is_interested_in_poz}
                drugNames={consultation?.drugNames}
                orderedSickLeave={consultation?.orderedSickLeave}
                healthPlannerSurveyFilled={consultation?.healthPlannerSurveyFilled}
                isInterestedInTelemediGo={consultation?.is_interested_in_telemedi_go}
                sickLeaveQuestionnaireFilled={consultation?.sickLeaveQuestionnaireFilled}
                prescriptionQuestionnaireFilled={consultation?.prescriptionQuestionnaireFilled}
                isMedicalSurveyFilled={consultation?.isMedicalSurveyFilled}
                isPatientMedicalSurveyRequired={consultation?.isPatientMedicalSurveyRequired}
              />
            ))
          }
          {loading && (
            <>
              <Skeleton variant="text"/>
              <Skeleton variant="text"/>
              <Skeleton variant="text"/>
            </>
          )}

          {total > items.length && (
            <ShowMoreButton
              disabled={loading}
              onClick={getNextPage}
            />
          )}
        </>
      ) : null
  )
}

export default ComingConsultationsList
