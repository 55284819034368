import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useActionButtonsStyles = makeStyles((theme) => createStyles({
  root: {
    margin: theme.spacing(5, 0, 2),
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  submitButton: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
}))
