import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useClinicNotFoundStyles = makeStyles((theme) => createStyles({
  container: {
    width: "100%",
    maxWidth: "1090px",
    margin: "auto"
  },
  root: {
    width: "100%",
    height: "100%",
    margin: "auto"
  },
  pageBox: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(7),
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& *": {
      fontWeight: 400,
    }
  },
  button: {
    "& .MuiButton-label": {
      fontWeight: 700,
    }
  },
  section: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%"
    }
  },
  label: {
    color: theme.palette.grey[600]
  },
  image: {
    maxWidth: "100%",
  }
}))
