import jwtDecode from "jwt-decode"

import {RootState} from "../store.types"
import {DecodedTokenType, SessionState} from "./session.types"

export const selectSession: ((state: RootState) => SessionState) = ({session}) => session

export const selectToken: ((state: RootState) => SessionState["token"]) = ({session}) => session.token

export const selectUserId: ((state: RootState) => SessionState["id"]) = ({session}) => session.id

export const selectUserName: ((state: RootState) => SessionState["username"]) = ({session}) => session.username

export const selectTokenRefresh: ((state: RootState) => SessionState["refresh_token"]) = ({session}) => session.refresh_token

export const selectAuthenticated: ((state: RootState) => boolean) = ({session}) => !!session.id

export const selectSessionExpirationDate: ((state: RootState) => Date|null) = ({session}) => {
  if (!session.token) {
    return null
  }
  const decodedToken: DecodedTokenType = jwtDecode(session.token)
  return decodedToken.exp
}
