import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Box, Button, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"

import { RoutePath } from "../../../routes/Routes.types"
import { useHealthPreventionLibraryStyles } from "../library/HealthPreventionLibrary.styles"
import { useHealthPreventionSelfImprovementStyles } from "./HealthPreventionSelfImprovement.styles"

export interface HealthPreventionSelfImprovementHeaderBox {
  title: string
  content: string
  backBtnLabel: string
  redirectRoute: string
  image: React.ReactElement
}

export interface HealthPreventionSelfImprovementItem {
  title: string
  content: string
  image: React.ReactElement
  onClickPage?: React.ReactElement
}

export interface HealthPreventionSelfImprovementBoxItem {
  title: string
  content: string
  btnLabel: string
  btnRedirectRoute: string
  specializationId?: string
  useClinicColor?: boolean
}

export interface HealthPreventionSelfImprovementContentBox {
  content: React.ReactElement
}

interface HealthPreventionSelfImprovementProps {
  headerBox: HealthPreventionSelfImprovementHeaderBox
  contentBox?: HealthPreventionSelfImprovementContentBox
  items?: HealthPreventionSelfImprovementItem[]
  boxItems?: HealthPreventionSelfImprovementBoxItem[]
}

const HealthPreventionSelfImprovement:FC<HealthPreventionSelfImprovementProps> = ({
  headerBox,
  contentBox,
  items,
  boxItems
}) => {
  const { i18n, t } = useTranslation()
  const theme = useTheme()
  const { push } = useHistory()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const classes = useHealthPreventionLibraryStyles()
  const selfImprovementClasses = useHealthPreventionSelfImprovementStyles()
  const [currentPage, setCurrentPage] = useState<JSX.Element | null>(null)

  useEffect(() => {
    currentPage && window.scrollTo(0, 0)
  }, [currentPage])

  const changePage = (page?: JSX.Element) => {
    page && setCurrentPage(page)
  }

  const handleRedirect = (item: HealthPreventionSelfImprovementBoxItem) => {
    if (item.specializationId) {
      push(`/${i18n.language}${RoutePath.MAKE_CONSULTATION}?specializationId=${item.specializationId}`)

      return
    }

    push(item.btnRedirectRoute)
  }

  const image = (
    <Box className={clsx(
      selfImprovementClasses.imageWrapper, 
      contentBox && selfImprovementClasses.contentBoxImage
    )}>
      {React.cloneElement(headerBox.image, { className: clsx(
        selfImprovementClasses.image, 
        contentBox && selfImprovementClasses.contentImage
      )})}
    </Box>
  )

  if (currentPage) {
    return currentPage
  }

  return <>
    <Box className={selfImprovementClasses.pageWrapper} >
      <Box className={clsx(classes.pageSection)} component="section">
        <Box className={classes.content}>
          <Box className={classes.titleWrapper}>
            <Typography className={selfImprovementClasses.title}>
              {t(headerBox.title)}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.bodyText}>
              {t(headerBox.content)}
            </Typography>
          </Box>
          { !isMdUp && image }
          <Box className={clsx(
            selfImprovementClasses.buttonsWrapper, 
            contentBox && selfImprovementClasses.contentButtonsWrapper
          )}>
            <Button variant="outlined" color="primary" onClick={() => push(headerBox.redirectRoute)}>
              {t(headerBox.backBtnLabel)}
            </Button>
          </Box>
        </Box>
        { isMdUp && image }
      </Box>
      {items && (
        <Box className={selfImprovementClasses.gridContainer}>
          {
            items && items.map((item, index) => (
              <Box
                key={`item-${index}`}
                pt={3} pb={2}
                className={selfImprovementClasses.boxCard}
                onClick={() => changePage(item.onClickPage)}
              >
                <Typography variant={isMdUp ? "h4" : "h3"} component="h3" className={selfImprovementClasses.boxTitle}>
                  {t(item.title)}
                </Typography>
                <div className={selfImprovementClasses.boxContent}>
                  <div className={selfImprovementClasses.boxImage}>
                    {React.cloneElement(item.image, { className: selfImprovementClasses.img })}
                  </div>
                  <div className={selfImprovementClasses.description}>
                    <Typography className={selfImprovementClasses.descriptionContent}>
                      {t(item.content)}
                    </Typography>
                  </div>
                </div>
              </Box>
            ))
          }
        </Box>
      )}
      {contentBox && (
        <>
          <Box className={clsx(classes.pageSection)} component="section">
            <Box className={classes.content}>
              <Typography className={classes.bodyText}>
                {contentBox.content}
              </Typography>
            </Box>
          </Box>
          {
            boxItems && boxItems.map((item, index) => (
              <Box className={clsx(classes.pageSection)} component="section" key={`box-${index}`}>
                <Box className={classes.content}>
                  <Box className={classes.titleWrapper}>
                    <Typography className={clsx(
                      selfImprovementClasses.boxItemTitle,
                      item.useClinicColor && selfImprovementClasses.clinicColor
                    )}>
                      {t(item.title)}
                    </Typography>
                  </Box>
                  <Typography className={selfImprovementClasses.boxItemContent}>
                    {t(item.content)}
                  </Typography>
                  <Box className={clsx(classes.backBtnWrapper, selfImprovementClasses.boxBtnWrapper)}>
                    <Button
                      className={clsx(classes.backBtn)}
                      variant="contained"
                      color="primary"
                      onClick={() => handleRedirect(item)}
                    >
                      {t(item.btnLabel)}
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))
          }
        </>
      )}
    </Box>
  </>
}

export default HealthPreventionSelfImprovement
