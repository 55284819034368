import React, {FC} from "react"
import {Box} from "@material-ui/core"

import {EPrescription as EPrescriptionType} from "../../../../api/queries/getUserActionById"
import ActionButtons from "../actionButtons/ActionButtons.component"
import PreventionActionWrapper from "../preventionActionWrapper/PreventionActionWrapper.component"
import Prescription from "./prescription/Prescription.component"
import {useRedirectToSummaryActionView} from "../PreventionActionCard.utils"
import {useEPrescriptionStyles} from "./EPrescription.styles"

type EPrescriptionProps = {
  title: string;
  description: string;
  ePrescriptions: EPrescriptionType[];
}

const EPrescription: FC<EPrescriptionProps> = ({ 
  title,
  description,
  ePrescriptions
}) => {
  const classes = useEPrescriptionStyles()
  const { handleFinish, isLoading } = useRedirectToSummaryActionView()

  return (
    <PreventionActionWrapper
      title={title}
      description={description}
    >
      <Box className={classes.prescriptionWrapper}>
        {
          ePrescriptions.map(ePrescription => (
            <Prescription
              key={ePrescription.id}
              code={ePrescription.code}
              drugs={ePrescription.drugs}
            />
          ))
        }
      </Box>
      <ActionButtons
        isLoading={isLoading}
        onClick={handleFinish}
      />
    </PreventionActionWrapper>
  )
}

export default EPrescription