import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {Box, Button, Typography} from "@material-ui/core"
import clsx from "clsx"

import useGoToConsultationPage from "../../../../hooks/useGoToConsultationPage"
import {PozFormDetailedSource} from "../../../../services/analytics/PozFormDetailedSource.enum"
import {selectUserPozDeclarationActiveStatus} from "../../../../store/user/user.selectors"
import IconByIntegrationType from "../../../iconByIntegrationType/IconByIntegrationType"
import UserDataListItem from "../../common/UserDataListItem"
import {useUserNfzConsultationsStyles} from "./UserNfzConsultations.styles"

interface UserNfzConsultationsProps {}

const UserNfzConsultations: FC<UserNfzConsultationsProps> = () => {
  const {t} = useTranslation()
  const classes = useUserNfzConsultationsStyles()
  const { goToMakeConsultationPage } = useGoToConsultationPage({visitDatePicker: false, detailedSource: PozFormDetailedSource.PP_SETTINGS})

  const isPozDeclarationActive = useSelector(selectUserPozDeclarationActiveStatus)

  return (
    <Box mb={2}>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" component="h3">
          { t("finances:freeNfzConsultations:title") }
        </Typography>
      </Box>

      <UserDataListItem
        label={t("finances:freeNfzConsultations:clinicAtNFZ")}
        description={t(`finances:freeNfzConsultations:${
          isPozDeclarationActive
            ? "activeClinicAtNFZ"
            : "inactiveClinicAtNFZ"
        }`)}
      />

      <Box mt={2}>
        <Box className={classes.descriptionWrapper}>
          <IconByIntegrationType iconName={"icon-info"}/>

          <Typography
            variant="body2"
            className={clsx(classes.description, "break-spaces")}
          >
            { isPozDeclarationActive
              ? t("insuranceActiveInfo")
              : `${t("insuranceInactiveInfoLong")}`
            }
          </Typography>
        </Box>

        { !isPozDeclarationActive && (
          <Box className={classes.linkWrapper}>
            <Button
              variant="outlined"
              color="primary"
              onClick={goToMakeConsultationPage}
            >
              { t("signUp") }
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default UserNfzConsultations
