import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useFormSectionStyles = makeStyles((theme) => createStyles({
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(3),
  }
}))
