import { dates } from "./common/dates"
import awards from "./lokalise/en/awards.json"
import b2bClinic from "./lokalise/en/b2bClinic.json"
import calendar from "./lokalise/en/calendar.json"
import chatConsultation from "./lokalise/en/chatConsultation.json"
import consultation from "./lokalise/en/consultation.json"
import consultationNestedMenu from "./lokalise/en/consultationNestedMenu.json"
// files imported automaticaly from https://lokalise.com/
import defaultTranslations from "./lokalise/en/defaultTranslations.json"
import diaryMeasurement from "./lokalise/en/diaryMeasurement.json"
import diseaseManagement from "./lokalise/en/diseaseManagement.json"
import documents from "./lokalise/en/documents.json"
import emptyConsultationsBox from "./lokalise/en/emptyConsultationsBox.json"
import errors from "./lokalise/en/errors.json"
import exam from "./lokalise/en/exam.json"
import finances from "./lokalise/en/finances.json"
import forgotPassword from "./lokalise/en/forgotPassword.json"
import healthPlanner from "./lokalise/en/healthPlanner.json"
import healthPlannerActions from "./lokalise/en/healthPlannerActions.json"
import laboratory from "./lokalise/en/laboratory.json"
import login from "./lokalise/en/login.json"
import loginPagePopup from "./lokalise/en/loginPagePopup.json"
import medicalQuestionnaire from "./lokalise/en/medicalQuestionnaire.json"
import notifications from "./lokalise/en/notifications.json"
import orderMedicines from "./lokalise/en/orderMedicines.json"
import pageFooter from "./lokalise/en/pageFooter.json"
import passwordless from "./lokalise/en/passwordless.json"
import payments from "./lokalise/en/payments.json"
import prescription from "./lokalise/en/prescription.json"
import preventionQuestionnaires from "./lokalise/en/preventionQuestionnaires.json"
import recommendations from "./lokalise/en/recommendations.json"
import referrals from "./lokalise/en/referrals.json"
import sessionManager from "./lokalise/en/sessionManager.json"
import stagingSettings from "./lokalise/en/stagingSettings.json"
import user from "./lokalise/en/user.json"
import videoChatConsultation from "./lokalise/en/videoChatConsultation.json"
// e/o files imported automaticaly from https://lokalise.com/

const en = {
  medicalQuestionnaire,
  defaultTranslations,
  dates,
  consultation,
  chatConsultation,
  videoChatConsultation,
  user,
  recommendations,
  exam,
  prescription,
  emptyConsultationsBox,
  login,
  forgotPassword,
  referrals,
  calendar,
  errors,
  sessionManager,
  notifications,
  documents,
  finances,
  pageFooter,
  laboratory,
  stagingSettings,
  payments,
  b2bClinic,
  orderMedicines,
  preventionQuestionnaires,
  awards,
  diaryMeasurement,
  healthPlannerActions,
  diseaseManagement,
  healthPlanner,
  consultationNestedMenu,
  passwordless,
  loginPagePopup
}

export default en
