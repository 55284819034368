import i18n from "i18next"

import {patchUserPersonalDataConfig, postForgotPasswordConfig, putChangePasswordConfig} from "../../api/routes"
import { analytics, LogEventType } from "../../services/analytics"
import {setLoginAccounts} from "../loginAccounts/logInAccounts.slice"
import {selectUserId} from "../session/session.selectors"
import {v4Service} from "../store.service"
import {RootState} from "../store.types"
import {
  ChangePasswordPayload,
  ChangePasswordResponse,
  ForgotPasswordBody,
  ForgotPasswordResponse, UpdateUserPayload
} from "./patients.types"

export const patientsApi = v4Service.injectEndpoints({
  endpoints: build => ({
    postForgotPasswordConfig: build.mutation<ForgotPasswordResponse, ForgotPasswordBody>({
      query: (data) => ({
        ...postForgotPasswordConfig,
        data
      }),
      async onQueryStarted(
        arg,
        { dispatch, queryFulfilled }
      ) {
        try {
          const response = await queryFulfilled

          if ("users" in response.data) {
            dispatch(setLoginAccounts(response.data.users))
          }
          analytics.sendEventWithDefaultParams(LogEventType.FORGOT_PASSWORD_SUCCESS)
        } catch (error) {
          const errorLog = {
            "error_code": error.error.status,
            "error_name": error.error.data,
            "login_name": arg.username
          }
          analytics.sendEventWithDefaultParams(LogEventType.FORGOT_PASSWORD_ERROR, errorLog)
          console.error(error)
        }
      }
    }),
    putChangePassword: build.mutation<ChangePasswordResponse, ChangePasswordPayload>({
      async queryFn(
        { oldPlainPassword, plainPassword },
        { getState },
        extraOptions,
        baseQuery
      ) {
        const state = (getState() as RootState)
        const userId = selectUserId(state)

        const { data, error } = await baseQuery({
          ...putChangePasswordConfig(userId),
          data: {
            oldPlainPassword,
            plainPassword,
            lang: i18n.language
          }
        })

        if (error) {
          return { error: error }
        }

        return { data: data as ChangePasswordResponse }
      }
    }),
    updateUser: build.mutation<void, UpdateUserPayload>({
      async queryFn(userPayload, { getState }, extraOptions, baseQuery) {
        const state = (getState() as RootState)
        const userId = selectUserId(state)

        const { data, error } = await baseQuery({
          ...patchUserPersonalDataConfig(userId),
          data: {
            address: userPayload.address,
          }
        })

        if (error) {
          return { error }
        }

        return { data: data as void }
      }
    }),
  })
})

export const {
  usePostForgotPasswordConfigMutation,
  usePutChangePasswordMutation,
  useUpdateUserMutation,
} = patientsApi
