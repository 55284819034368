import React from "react"
import { InputAdornment, OutlinedTextFieldProps, PropTypes } from "@material-ui/core"

import IconByIntegrationType from "../../../components/iconByIntegrationType/IconByIntegrationType"

const MuiTextField = {
  margin: "normal" as PropTypes.Margin,
  variant: "outlined" as OutlinedTextFieldProps["variant"],
  InputProps: {
    endAdornment: (
      <InputAdornment position="end" className="errorAdornment">
        <IconByIntegrationType {...{ component: "span", variant: "h3", color: "error" }}
          iconName={"icon-alert"}
          returnTypography={true}
        />
      </InputAdornment>
    ),
  },
}

export const textField = {
  MuiTextField,
}