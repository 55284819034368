import { useEffect } from "react"
import { useQuery } from "@apollo/client"

import { analytics, LogEventType } from "../../../services/analytics"
import {GET_CLINIC_ID} from "../queries/getClinicIdQuery"
import {GET_CLINIC_SETTINGS} from "../queries/getClinicSettingsQuery"
import {GET_CONSULTATION_SURVEY_TEMPLATE} from "../queries/getConsultationSurveyTemplate"
import {GET_MEDICAL_QUESTIONS_BY_MEDICINE_ID} from "../queries/getMedicalQuestionsByMedicineIdQuery"
import {GET_MEDICINE_CATEGORY} from "../queries/getMedicineCategory"
import {GET_MEDICINES_ITEMS_FROM_CATEGORY} from "../queries/getMedicinesItemsFromCategoryQuery"

const useMakeQuery = (): any => {
  const getClinicSettingsQuery = (patientDashboardUrl: string) => {
    const {
      data: { clinics: data } = {},
      loading: dataLoading,
      error,
    } = useQuery(GET_CLINIC_SETTINGS, {
      variables: {patientDashboardUrl}
    })

    useEffect(() => {
      if(!dataLoading && error) {
        const errorLog = {
          "error_name": error?.graphQLErrors[0]?.extensions?.code,
          "error_message": error?.graphQLErrors[0]?.message,
        }
        analytics.sendEventWithDefaultParams(LogEventType.CLINIC_SETTINGS_ERROR, errorLog)
      }
    },[error])

    useEffect(() => {
      if(!dataLoading && data) {
        analytics.sendEventWithDefaultParams(LogEventType.CLINIC_SETTINGS_SUCCESS)
      }
    },[data])

    return {
      data,
      dataLoading,
    }
  }

  const getClinicIdQuery = (patientDashboardUrl: string) => {
    const {
      data: { clinics: data } = {},
      loading: dataLoading,
    } = useQuery(GET_CLINIC_ID, {
      variables: {patientDashboardUrl}
    })

    return {
      data,
      dataLoading,
    }
  }

  const getConsultationSurveyTemplate = (codeName: string) => {
    const {
      data: {questionnaireTemplates} = {},
      loading: dataLoading,
    } = useQuery(GET_CONSULTATION_SURVEY_TEMPLATE, {
      variables: {codeName},
      context: {clientName: "HealthPlanner"},
    })

    return {
      questionnaireTemplates,
      dataLoading,
    }
  }

  const getMedicalQuestionsByMedicineIdQuery = (medicineId: string) => {
    const {
      data: { medical_question: data } = {},
      loading: dataLoading,
    } = useQuery(GET_MEDICAL_QUESTIONS_BY_MEDICINE_ID, {
      variables: {medicineId}
    })

    return {
      data,
      dataLoading,
    }
  }

  const getMedicineCategoryQuery = (ean: string) => {
    const {
      data: { medicine } = {},
      loading: dataLoading,
    } = useQuery(GET_MEDICINE_CATEGORY, {
      variables: {ean}
    })

    return {
      medicine,
      dataLoading,
    }
  }

  const getMedicinesItemsFromCategory = (categoryId: string) => {
    const {
      data: { medicine } = {},
      loading: dataLoading,
    } = useQuery(GET_MEDICINES_ITEMS_FROM_CATEGORY, {
      variables: { categoryId }
    })

    return {
      medicine,
      dataLoading,
    }
  }

  return {
    getClinicSettingsQuery,
    getClinicIdQuery,
    getMedicalQuestionsByMedicineIdQuery,
    getMedicineCategoryQuery,
    getMedicinesItemsFromCategory,
    getConsultationSurveyTemplate,
  }
}

export default useMakeQuery
