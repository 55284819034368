import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {Reducer} from "redux"

import { VoucherModel, VouchersState } from "./vouchers.types"

const initialState: VouchersState = {
  vouchersList: null,
  loading: false,
  error: null,
  isAddNewVoucherModalOpen: false,
}

const vouchersSlice = createSlice({
  name: "vouchers",
  initialState,
  reducers: {
    getVouchers: (state: VouchersState) => {
      state.loading = true
      state.error = null
      return
    },
    setVouchers: (state: VouchersState, action: PayloadAction<VoucherModel[]>) => {
      return {
        ...state,
        vouchersList: action.payload,
        loading: false,
        error: null,
      }
    },
    setVouchersError: (state: VouchersState, action: PayloadAction<VouchersState["error"]>) => {
      state.error = action.payload
      state.loading = false
      return
    },
    setAddNewVoucherModalOpen: (state: VouchersState, action: PayloadAction<VouchersState["isAddNewVoucherModalOpen"]>) => {
      state.isAddNewVoucherModalOpen = action.payload
    },
    clearVouchers: () => {
      return { ...initialState }
    },
  }
})

export const {
  getVouchers,
  setVouchers,
  setVouchersError,
  setAddNewVoucherModalOpen,
  clearVouchers,
} = vouchersSlice.actions

export default vouchersSlice.reducer as Reducer<typeof initialState>
