import i18next from "i18next"
import * as yup from "yup"

type schemaType = {
  fields: {
    [fieldKey: string]: string
  }
}

export const medicalSurveySchema = () => yup.object().shape({
  alergy: yup.string().required(i18next.t("errors:required")),
  alergyInput: yup.string()
    .when("alergy", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  smoking: yup.string().required(i18next.t("errors:required")),
  smokingInput: yup.string()
    .when("smoking", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  drugsField: yup.string().required(i18next.t("errors:required")),
  drugsFieldInput: yup.string()
    .when("drugsField", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  chemicals: yup.string().required(i18next.t("errors:required")),
  chemicalsInput: yup.string()
    .when("chemicals", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  chronicDiseases: yup.string().required(i18next.t("errors:required")),
  chronicDiseasesInput: yup.string()
    .when("chronicDiseases", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  pancreaticDiseases: yup.string().required(i18next.t("errors:required")),
  pancreaticDiseasesInput: yup.string()
    .when("pancreaticDiseases", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  tumors: yup.string().required(i18next.t("errors:required")),
  tumorsInput: yup.string()
    .when("tumors", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  venousDisease: yup.string().required(i18next.t("errors:required")),
  venousDiseaseInput: yup.string()
    .when("venousDisease", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  venousDiseaseInFamily: yup.string().required(i18next.t("errors:required")),
  venousDiseaseInFamilyInput: yup.string()
    .when("venousDiseaseInFamily", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  thromboembolism: yup.string().required(i18next.t("errors:required")),
  thromboembolismInput: yup.string()
    .when("thromboembolism", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  immobilization: yup.string().required(i18next.t("errors:required")),
  immobilizationInput: yup.string()
    .when("immobilization", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  heartDisease: yup.string().required(i18next.t("errors:required")),
  myocardialInfarction: yup.string().required(i18next.t("errors:required")),
  myocardialInfarctionInput: yup.string()
    .when("myocardialInfarction", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  stroke: yup.string().required(i18next.t("errors:required")),
  strokeInput: yup.string()
    .when("stroke", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  cerebralIschemicAttack: yup.string().required(i18next.t("errors:required")),
  cerebralIschemicAttackInput: yup.string()
    .when("cerebralIschemicAttack", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  chestPains: yup.string().required(i18next.t("errors:required")),
  chestPainsInput: yup.string()
    .when("chestPains", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  metabolicDisorders: yup.string().required(i18next.t("errors:required")),
  metabolicDisordersInput: yup.string()
    .when("metabolicDisorders", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  migraine: yup.string().required(i18next.t("errors:required")),
  migraineInput: yup.string()
    .when("migraine", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  hypertension: yup.string().required(i18next.t("errors:required")),
  heartValveDisease: yup.string().required(i18next.t("errors:required")),
  heartValveDiseaseInput: yup.string()
    .when("heartValveDisease", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  diabetes: yup.string().required(i18next.t("errors:required")),
  complicatedDiabetes: yup.string().required(i18next.t("errors:required")),
  malabsorptionSyndrome: yup.string().required(i18next.t("errors:required")),
  systemicLupusErythematosus: yup.string().required(i18next.t("errors:required")),
  inflammationOfTheIntestines: yup.string().required(i18next.t("errors:required")),
  inflammationOfTheIntestinesInput: yup.string()
    .when("inflammationOfTheIntestines", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  epilepsy: yup.string().required(i18next.t("errors:required")),
  prohibitionOfHormonalContraception: yup.string().required(i18next.t("errors:required")),
  prohibitionOfHormonalContraceptionInput: yup.string()
    .when("prohibitionOfHormonalContraception", {
      is: "1",
      then: yup.string().required(i18next.t("errors:required"))
    }),
  height: yup.object().typeError(i18next.t("errors:required")).shape({
    value: yup.number(),
    label: yup.string()
  }),
  weight: yup.object().typeError(i18next.t("errors:required")).shape({
    value: yup.number(),
    label: yup.string()
  }),
  temperature: yup.object().typeError(i18next.t("errors:required")).shape({
    value: yup.number(),
    label: yup.string()
  }),
  pregnant: yup.string(),
  breastFeed: yup.string(),
  drugs: yup.string().required(i18next.t("errors:required")),
  drugsInput:
        yup.string()
          .when("drugs", {
            is: "1",
            then: yup.string().required(i18next.t("errors:required"))
          }),
  treatmentContinuation: yup.string().required(i18next.t("errors:required")),
  importantInformations: yup.string().min(50, i18next.t("consultation:sickLeave:min50Chars")),
}).required().when((values: string, schema: schemaType) => {
  for (const key in schema.fields) {
    const currentField = document.getElementById(key)

    if (currentField === null) {
      delete schema.fields[key]
    }
  }
})
