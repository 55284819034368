import { createStyles, makeStyles } from "@material-ui/core"

import { boxShadows } from "../../../../../themes/shadows"
import { Theme } from "@material-ui/core/styles"

export const useResourceButtonStyles = makeStyles((theme: Theme) => createStyles({
  buttonTile: {
    borderRadius: theme.shape.buttonBorderRadius,
    border: "none",
    minWidth: "92px",
    height: "92px",
    background: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: "center",
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    boxShadow: boxShadows.shadow5,
  },
  imageWrapper: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "70%",
  },
  image: {
    width: "100%",
  },
  buttonTitle: {
    color: theme.palette.text.secondary,
  },
}))
