export const dates = {
  // CUSTOM FORMATS
  // dataName: "{{ value format }}"
  // format: https://date-fns.org/v2.21.3/docs/format
  weekDay: "{{ date, eeee }}",
  dayMonthYearShort: "{{ date, dd.MM.yy }}",
  dayMonthYearShortSlashes: "{{ date, dd/MM/yy }}",
  fullDate: "{{ date, dd.MM.yyyy }}",
  time: "{{ date, HH:mm }}",
  consultationFullDate: "{{ date, eeee, dd.MM.yy, HH:mm }}", // Wtorek, 02.11.21 - 12:17
  consultationDayWithDate: "{{ date, eeee', 'dd.MM.yy }}",
  consultationDayWithFullYearDate: "{{ date, eeee', 'dd.MM.yyyy }}",
  consultationDayWithFullYearDateAndHour: "{{ date, eeee', 'dd.MM.yyyy - HH:mm }}",

  // FORMATS DEFINED IN @/plugins/date-fns.ts: relative, ago, range
  relativeDate: "{{ date, relative }}",
  agoDate: "{{ date, ago }}",
  distanceDates: "{{ date, distanceDates }}",
  distanceDatesStrict: "{{ date, distanceDatesStrict }}",
  rangeTextDateWithYear: "{{ date, rangeTextDateWithYear }}",
  rangeTextDateWithoutYear: "{{ date, rangeTextDateWithoutYear }}",
  rangeDateWithYearHeader: "{{ date, rangeDateWithYearHeader }}",
  rangeDateWithoutYearHeader: "{{ date, rangeDateWithoutYearHeader }}",
}
