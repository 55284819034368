import {createStyles, makeStyles} from "@material-ui/core"

export const useTextFieldStyles = makeStyles((theme) => createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
    width: "100%"
  },
  textField: {
    margin: 0,
    minWidth: 240,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
}))