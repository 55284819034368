import React, {FC, useEffect, useState} from "react"
import {Box, Button, Typography} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { CancelTokenSource } from "axios"
import clsx from "clsx"

import api, { ApiInstance } from "../../../api/api"
import {getMedicalDocumentFileConfig} from "../../../api/routes"
import useConsultationLang from "../../../hooks/useConsultationLang"
import useFileDownload from "../../../hooks/useFileDownload"
import GlobalErrorSnackbar from "../../commonFormItems/globalErrorSnackbar/GlobalErrorSnackbar"
import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import ButtonLoader from "../../common/buttonLoader/ButtonLoader.component"
import {ConsultationChatItem} from "../ConsultationChat.types"
import {MINIFY_IMAGE_HEIGHT, useConsultationChatMessageItemStyles} from "./ConsultationChatMessageItem.styles"

import {parseMessageCreatedAt} from "./ConsultationChatMessageItem.config"

interface ImageChatMessageItemProps extends ConsultationChatItem {
  isPatientMessage: boolean;
}

const ImageChatMessageItemComponent: FC<ImageChatMessageItemProps> = (
  {
    messageRaw,
    createdAt,
    isPatientMessage
  }
) => {
  const consultationLang = useConsultationLang()
  const classes = useConsultationChatMessageItemStyles()
  const time = parseMessageCreatedAt(createdAt)

  const [imageSrc, setImageSrc] = useState<string>()

  const getImage = (cancelToken?: CancelTokenSource["token"]) => api.request<Blob>({
    ...getMedicalDocumentFileConfig(messageRaw),
    cancelToken,
  })

  const getImageSrc = async (cancelToken?: CancelTokenSource["token"]) => {
    try {
      const {data} = await getImage(cancelToken)
      const blob = new Blob([data], {
        type: "blob"
      })

      const urlCreator = window.URL || window.webkitURL
      const imageUrl = urlCreator.createObjectURL(blob)
      setImageSrc(imageUrl)
    }
    catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
    }
  }

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()

    getImageSrc(requestSource.token)

    return () => {
      requestSource.cancel("Request interrupted by page change")
    }
  }, [])

  /* download file */
  const {download, downloading, downloadError} = useFileDownload(getImage)

  const handleDownloadPdfClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    (event.target as HTMLInputElement).blur()
    download(`${time} ${consultationLang.getLabel("documents:image")}`)
  }
  /* e/o download file */

  return (
    <Box className={clsx(
      classes.messageItemBox,
      isPatientMessage ? classes.rightAlignBoxMessage : classes.leftAlignBoxMessage,
    )}>
      <Box className={clsx(
        classes.widthConditions,
        classes.imageItem,
      )}>
        { imageSrc
          ? (
            <Box className={classes.imageWrapper}>
              <img
                src={imageSrc}
                alt=""
                className={classes.image}
              />
            </Box>
          )
          : (
            <Skeleton
              variant="rect"
              width={200}
              height={MINIFY_IMAGE_HEIGHT}
              className={classes.image}
              animation="pulse"
            />
          )
        }

        <Box
          className={clsx(
            classes.downloadImageItem,
            isPatientMessage ? classes.rightAlignBoxMessage : classes.leftAlignBoxMessage,
          )}
        >
          <Button
            disabled={downloading}
            className={clsx(
              classes.downloadImageButton,
              isPatientMessage ? classes.patientDownloadImageButton : classes.doctorDownloadImageButton,
            )}
            onClick={handleDownloadPdfClick}
            startIcon={ downloading
              ? <ButtonLoader position="prefix"/>
              : (
                <IconByIntegrationType {...{ component: "span", variant: "h4" }}
                  iconName={"icon-download"}
                  returnTypography={true}
                />
              )
            }
          >
            <Typography variant="body2">
              { consultationLang.getLabel("download") }
            </Typography>
          </Button>

          <GlobalErrorSnackbar isError={!!downloadError}/>
        </Box>
      </Box>
    </Box>
  )
}

export default ImageChatMessageItemComponent
