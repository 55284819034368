import React, {FC} from "react"

import withBaseLayout from "../../hoc/withBaseLayout"
import {useRemoveIsFirstSessionInvoked} from "../../hooks/useRemoveIsFirstSessionInvoked"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import {useHandlePasswordlessMagicLink} from "./PasswordlessMagicLink.utils"

const PasswordlessMagicLink: FC = () => {
  useHandlePasswordlessMagicLink()
  useRemoveIsFirstSessionInvoked()

  return (
    <LoaderBox
      boxType="fullPage"
    />
  )
}

export default withBaseLayout(PasswordlessMagicLink)
