import React, { FC } from "react"
import {useTranslation} from "react-i18next"

import serverErrorImage from "../../assets/images/page-server-error.png"
import store from "../../store"
import ErrorPageCard from "../../components/common/errorPageCard/ErrorPageCard.component"

const Error504Page: FC = () => {
  const {t} = useTranslation()
  const contactPhoneNumber = store.getState().clinic?.clinicSettings?.frontendSettings?.contactPhoneNumber ?? t("serviceDeskPhone")

  return (
    <ErrorPageCard
      errorCode={502}
      title={t("errors:serverErrorPage:title")}
      description={t("errors:serverErrorPage:description", {contactPhoneNumber: contactPhoneNumber})}
      image={serverErrorImage}
      imagePath={"page-server-error.png"}
    />
  )
}

export default Error504Page
