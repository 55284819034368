import {createStyles, makeStyles} from "@material-ui/core"

export const useDozErrorParserStyles = makeStyles((theme) => createStyles({
  errorContainer: {
    marginTop: theme.spacing(1)
  },
  errorIcon: {
    minWidth: theme.spacing(2),
    minHeight: theme.spacing(2)
  },
  missingMedicinesLabel: {
    fontWeight: 500,
    lineHeight: "18px",
    marginLeft: theme.spacing(1)
  }
}))