export interface ClinicStyles {
  mainColor: string;
  hoverColor: string;
  lightBgColor: string;
  gradientColor: string;
  chatBgColor: string;
  textDetailColor: string;
  dashboardBackgroundColor: string;
  dashboardHeaderColor: string;
  dashboardFooterColor: string;
  dashboardUserDotColor: string;
  dashboardFooterTextColor: string;
  buttonBorderRadius: number;
  dynamicStylesEnabled: boolean;
  dynamicImagesEnabled: boolean;
  dynamicIconsEnabled: boolean;
  dynamicFontsEnabled: boolean;
  mainClinicLogoInFooterEnabled: boolean;
}

interface ClinicSeo {
  title: string | null;
  description: string | null;
}

interface ClinicImages {
  logo: string | null;
  logoLogin: string | null;
  logoWatermark: string | null;
  favicon: string | null;
}

export enum ChannelType {
  TYPE_VIDEO = 1,
  TYPE_PHONE = 2,
  TYPE_CHAT = 3
}

export enum MagicLink {
  TYPE_PATIENT_PASSWORDLESS_SMS = "11",
  TYPE_PATIENT_PASSWORDLESS_EMAIL = "12",
}

export enum HealthPreventionPrograms {
  PROGRAM_MENTAL_HEALTH = "1",
}

export const BENEFIT_SYSTEM_NO_BOOKING_PERMISSION = 63

export interface ClinicSettingsState {
  clinicSettings: {
    domain: string;
    collectMedicalDocument: boolean;
    frontendSettings: {
      vPozMedicalSurveyEnabled: boolean;
      clinicId: string;
      __typename: string;
      edges: unknown[];
      style: ClinicStyles;
      reactAppClinicType: string;
      images: ClinicImages;
      enableHealthPlannerFeature: boolean;
      disableReferralsInDashboard: boolean;
      enableHealthPlannerQuestionnaire: boolean;
      enableBenefitSystemIntegration: boolean;
      enabledTelemediGo: boolean;
      enabledTelemediGoInEprescription: boolean;
      enabledTelemediGoInSickLeave: boolean;
      enabledTelemediGoForInternist: boolean;
      prescriptionForPozGlobal: boolean;
      showPhoneNumberOnCompleteDataView: boolean;
      hideChangePassword: boolean;
      modules: string[];
      contactPhoneNumber: string;
      hideConsultationBooking: boolean;
    }
    prescriptionsSettings: {
      medicalDrugLimit: number;
      medicalDrugsTotalLimit: number;
      modules: string[];
      enableCheckMedicineExposed: boolean;
      pozGlobalMedicalDrugLimit: number;
      pozGlobalMedicalDrugsTotalLimit: number;
      otherMessagesForB2bClinicEnabled: boolean;
    }
    notificationSettings: {
      magicLinks: MagicLink[];
    }
    widgetConsbookSettings: {
      enableMedicalDocumentationReminderInfo: boolean;
      enablePaymentProviderInfo: boolean;
      enableAddVoucherInSummaryFeature: boolean;
      enableSmallSlotsFeature: boolean;
      enableInstantChatFeature: boolean;
      defaultChannelType: ChannelType;
    }
    consultationListSettings: {
      enableReferralPriceInfo: boolean;
    },
    examCartSettings: {
      enableReferralBasketFeature: boolean;
    },
    clinicSignalIdunaSettings: {
      insuranceVerificationEnabled: boolean;
      dashboardLaboratoryModuleEnabled: boolean;
    },
    isB2b: false;
    __typename: string;
    id: string;
    stripePublicKey: string;
    apiUrl: string;
    allowRegistration: boolean;
    hasBookConsultationAbility: boolean;
    clinicForeign: boolean
    showPrices: boolean;
    stationaryVisitPatient: boolean;
    buyExamButtonEnabled: boolean;
    pozformB2BActive: boolean;
    seo: ClinicSeo;
    name: string;
    enabledLanguages: {
      languages: string[];
    }
    widgetRegisterSettings: {
      linkToRegulations: string;
      linkToPrivacyPolicy: string;
      showAreaCodeForLogin: boolean;
    }
    sickLeaveSettings: {
      modules: string[];
    }
    patientLaboratories: string[];
    clinicAllianzSettings: {
      ewkaVerificationEnabled: boolean;
      onyxVerificationEnabled: boolean;
    }
    clinicHealthPreventionSettings: {
      programs: HealthPreventionPrograms[];
    }
    clinicVoucherSettings: {
      voucherToBuy: string | null;
    }
    clinicLoginPagePopupSettings: {
      loginPagePopupTitle: string | null;
      loginPagePopupContent: string | null;
      modifiedAt: string | null;
    }
    patientEditAllowed: boolean
  },
}
