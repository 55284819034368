import React, {FC} from "react"
import { Box, Hidden, Typography } from "@material-ui/core"
import clsx from "clsx"
import dayjs from "dayjs"

import { usePatientChatMessageStyles } from "./PatientChatMessage.styles"

type PatientChatMessageProps = {
  message: string;
  createdAt: string;
}

const PatientChatMessage: FC<PatientChatMessageProps> = ({
  message,
  createdAt,
}) => {
  const classes = usePatientChatMessageStyles()
  const time = dayjs(createdAt).format("HH:mm")

  return (
    <Box className={clsx(
      classes.messageItemBox,
      classes.rightAlignBoxMessage,
    )}>
      { time && (
        <Hidden smDown>
          <Typography className={classes.messageTime}>
            { time }
          </Typography>
        </Hidden>
      )}

      <Box
        ml={2}
        className={clsx(
          classes.cloudMessageWrapper,
          classes.cloudPatientMessageWrapper,
        )}
      >
        <Typography className="break-spaces">
          { message }
        </Typography>
      </Box>
    </Box>
  )
}

export default PatientChatMessage
