import React, {FC, useEffect, useRef, useState} from "react"
import {useSelector} from "react-redux"
import { useLocation } from "react-router"
import { Wizard } from "react-use-wizard"

import api from "../../../api/api"
import {getUserLatestBasicMedicalQuestionnaireAnswers} from "../../../api/routes"
import {useSendMedicalQuestionnaireMutation} from "../../../store/medicalQuestionnaire/medicalQuestionnaire.api"
import {selectUserId} from "../../../store/session/session.selectors"
import LoaderBox from "../../../components/common/loaderBox/LoaderBox.component"
import ErrorAlert
  from "../../../components/passwordlessBox/sendAccessCodeInput/errorAlert/ErrorAlert.component"
import BasicInformationForm from "./steps/BasicInformationForm/BasicInformationForm.component"
import PatientsRequirementsForm from "./steps/PatientsRequirementsForm/PatientsRequirementsForm.component"
import Summary from "./steps/Summary/Summary.component"
import {BasicInformationStepData, QuestionAnswer, Sex} from "./MedicalQuestionnaireStepperForm.types"

import {StepNumber} from "./MedicalQuestionnaireStepperForm.enums"
import {useMedicalStepperFormManager} from "./MedicalQuestionnaireStepperForm.hooks"

const MedicalQuestionnaireStepperForm: FC = () => {
  const { stepsData, handleStepData, redirectToConsultation } = useMedicalStepperFormManager()
  const userId = useSelector(selectUserId)
  const { pathname } = useLocation()
  const defaultBasicInformationFormValues = stepsData[StepNumber.BASIC_INFORMATION]
  const [initialBasicInformationFormValues, setInitialBasicInformationFormValues] = useState(defaultBasicInformationFormValues)
  const [isLoadingPreviousBasicInfo,setIsLoadingPreviousBasicInfo] = useState<boolean>(true)
  const [isBasicInfoFetchedFromPrevSurvey,setIsBasicInfoFetchedFromPrevSurvey] = useState<boolean>(false)
  const [,{ isLoading, error }] = useSendMedicalQuestionnaireMutation({
    fixedCacheKey: "sendMedicalQuestionnaire",
  })

  useEffect(() => {
    let isMounted = true

    const fetchUserLatestMedicalQuestionaire = async()=>{
      isMounted && setIsLoadingPreviousBasicInfo(true)
      try {
        const { data : questionerData } = await api.request({
          ...getUserLatestBasicMedicalQuestionnaireAnswers(userId)
        })

        if ( !questionerData
            || (Array.isArray(questionerData) &&questionerData.length ===0)
        ){
          isMounted && setIsLoadingPreviousBasicInfo(false)
          return
        }

        if (questionerData.consultation_id === pathname.split("/").pop()) {
          redirectToConsultation()
          return
        }

        const hasChronicDiseases = questionerData.diseases && questionerData.diseases !==""
        const isTakingAnyMedicines =   questionerData.medicines && questionerData.medicines !==""
        const hasAnyAllergies = questionerData.allergies && questionerData.allergies !==""
        const patientPreviousAnswers = {
          weight:questionerData.weight,
          height:questionerData.height,
          sex: questionerData.sex ? Sex.MALE : Sex.FEMALE,
          sexChangeIsLocked: questionerData.sex != undefined,
          isBreastFeeding: questionerData.is_breast_feeding,
          hasChronicDiseases: hasChronicDiseases ? QuestionAnswer.YES : QuestionAnswer.NO,
          hasChronicDiseasesInput: questionerData.diseases,
          isTakingAnyMedicines: isTakingAnyMedicines ? QuestionAnswer.YES : QuestionAnswer.NO,
          isTakingAnyMedicinesInput: questionerData.medicines,
          hasAnyAllergies: hasAnyAllergies ? QuestionAnswer.YES : QuestionAnswer.NO,
          hasAnyAllergiesInput: questionerData.allergies,
        }

        isMounted && setInitialBasicInformationFormValues({
          ...stepsData[StepNumber.BASIC_INFORMATION],
          ...patientPreviousAnswers,
        })
        isMounted && setIsBasicInfoFetchedFromPrevSurvey(true)
      } catch (e) {
        //console.error(e)
      } finally {
        isMounted && setIsLoadingPreviousBasicInfo(false)
      }
    }
    fetchUserLatestMedicalQuestionaire()

    return ()=>{
      isMounted = false
    }
  }, [userId])

  if (error) {
    return <ErrorAlert/>
  }

  if ( isLoadingPreviousBasicInfo ){
    return <LoaderBox/>
  }

  return (
    <Wizard>
      <BasicInformationForm
        initialValues={initialBasicInformationFormValues}
        onSubmit={handleStepData(StepNumber.BASIC_INFORMATION)}
        isDataFetchedFromPrevSurvey={isBasicInfoFetchedFromPrevSurvey}
        setIsDataFetchedFromPrevSurvey={setIsBasicInfoFetchedFromPrevSurvey}
      />
      <PatientsRequirementsForm
        initialValues={stepsData[StepNumber.PATIENTS_REQUIREMENTS]}
        onSubmit={handleStepData(StepNumber.PATIENTS_REQUIREMENTS)}
        visitReason={stepsData[StepNumber.BASIC_INFORMATION]?.visitReason}
      />
      <Summary
        visitReason={stepsData[StepNumber.BASIC_INFORMATION]?.visitReason}
        patientsRequirements={stepsData[StepNumber.PATIENTS_REQUIREMENTS]}
        onSubmit={handleStepData(StepNumber.SUMMARY)}
        isLoading={isLoading}
      />
    </Wizard>
  )
}

export default MedicalQuestionnaireStepperForm
