import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useDozRegulationsStyles = makeStyles((theme) => createStyles({
  rulesUrl: {
    marginLeft: theme.spacing(.5),
  },
  root: {
    marginTop: theme.spacing(5),
  },
  rodoUrl: {
    display: "block",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(8),
  }
}))
