import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import { ButtonBase, Menu, Typography } from "@material-ui/core"
import { CaretDown } from "phosphor-react"

import { useAppSelector } from "../../../hooks/storeHooks"
import { selectLogInLoading } from "../../../store/logIn/logIn.selectors"
import LangFlag from "../langFlag/LangFlag.component"
import LangsList, { Placement } from "./langsList/LangsList.component"
import {languageSelection} from "../langFlag/LangFlag.utils"
import { useLangDropdownOnBasePageStyles } from "./LangDropdownOnBasePage.styles"

interface LangDropdowndOnBasePageProps {}

const LangDropdowndOnBasePage: FC<LangDropdowndOnBasePageProps> = () => {
  const {t, i18n} = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const classes = useLangDropdownOnBasePageStyles()
  const loginLoading = useAppSelector(selectLogInLoading)
  const languageSelect = languageSelection(i18n?.services?.resourceStore?.options?.supportedLngs || [])

  const currentLang = i18n.language

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!languageSelect) {
    return null
  }

  return (
    <>
      <ButtonBase
        aria-controls="lang-dropdown-on-base-page"
        aria-label={t("user:userSettings")}
        aria-haspopup="true"
        className={classes.currentLangButton}
        disabled={loginLoading}
        onClick={handleMenu}
      >
        <LangFlag
          language={currentLang}
          className={classes.flagImage}
        />

        <Typography variant="h5" component="span">
          { t(`${currentLang}Shorthand`) }
        </Typography>

        <CaretDown
          weight="bold"
          className={classes.arrowIcon}
        />
      </ButtonBase>

      <Menu
        id="lang-dropdown-on-base-page"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoFocus={false}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.menuPaper,
          list: classes.listRoot,
        }}
      >
        <LangsList placement={Placement.BASE_LAYOUT}/>
      </Menu>
    </>
  )
}

export default LangDropdowndOnBasePage
