import React, { FC } from "react"
import {Box,Typography} from "@material-ui/core"

import useConsultationLang from "../../../../hooks/useConsultationLang"
import AppDialog from "../../../common/appDialog/AppDialog.component"

interface ConsultationInvitationVideoModalProps {
  open: boolean;
  setClose: () => void;
  setVideoChatOpen: () => void;
}

const ConsultationInvitationVideoModal: FC<ConsultationInvitationVideoModalProps> = (
  {
    open,
    setClose,
    setVideoChatOpen,
  }
) => {
  const consultationLang = useConsultationLang()

  const handleSubmitInvitationVideoButtonClick = () => {
    setVideoChatOpen()
    setClose()
  }

  return (
    <AppDialog
      open={open}
      onClose={setClose}
      title={consultationLang.getLabel("videoChatConsultation:inivitationModalTitle")}
      actionButtonText={consultationLang.getLabel("videoChatConsultation:inivitationModalAgreeButton")}
      actionButtonProps={{
        onClick: handleSubmitInvitationVideoButtonClick
      }}
      closeButtonText={consultationLang.getLabel("close")}
    >
      <Box mb={2}>
        <Typography>
          {consultationLang.getLabel("videoChatConsultation:inivitationModalDescription")}
        </Typography>
      </Box>
    </AppDialog>
  )
}

export default ConsultationInvitationVideoModal
