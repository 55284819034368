import {DependencyList, EffectCallback, useEffect} from "react"

export const useDeepEqualEffect = (effect: EffectCallback, dependencies: DependencyList): void => {
  useEffect(
    effect,
    dependencies.map(
      dependency => typeof dependency === "object" 
        ? JSON.stringify(dependency) 
        : dependency
    )
  )
}