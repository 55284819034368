import React, {FC, useEffect, useRef,useState} from "react"
import { useTranslation } from "react-i18next"
import { Box,Collapse } from "@material-ui/core"
import clsx from "clsx"
import { useDebouncedCallback } from "use-debounce"

import ButtonText from "../buttonText/ButtonText.component"
import {scrollToRefElement} from "../../../utils/scrollTo"
import { useCollapseWrapperStyles } from "./CollapseWrapper.styles"

export enum ButtonPlacement {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  CENTER = "CENTER",
}

interface CollapseWrapperProps {
  toggleButtonText?: string;
  collapsedSize: number;
  onToggleButtonClick?: () => void;
  toggleButtonPlacement?: ButtonPlacement;
  isDefaultOpen?: boolean;
}

const CollapseWrapper: FC<CollapseWrapperProps> = (
  {
    children,
    collapsedSize,
    onToggleButtonClick,
    toggleButtonPlacement = ButtonPlacement.RIGHT,
    isDefaultOpen = false,
    toggleButtonText = "seeMore",
  }) => {
  const classes = useCollapseWrapperStyles()
  const { t } = useTranslation()

  const [isCollapseOpen, setIsCollpaseOpen] = useState(isDefaultOpen)
  const [collapseMaxHeight, setCollapseMaxHeight] = useState(`${ collapsedSize }px`)

  const COLLAPSE_TRANSITION_DURATION = 300 // ms (300 is a default collapse transition-duration)

  const handleToggle = () => {
    setIsCollpaseOpen((prev) => !prev)
  }

  const handleToggleButtonClick = () => {
    return onToggleButtonClick ? onToggleButtonClick() : handleToggle()
  }

  /* check if content is higher than collapse wrapper */
  const collapseContentRef = useRef<HTMLDivElement>(null)
  const [showCollapseButton, setShowCollapseButton] = useState<boolean>(!!isDefaultOpen)

  const handleToggleButtonVisibility = useDebouncedCallback(() => {
    if (collapseContentRef.current === null) {
      return
    }

    if (isDefaultOpen) {
      setShowCollapseButton(false)
    } else {
      const elementBoundingClientRect: DOMRect = collapseContentRef.current.getBoundingClientRect()
      setShowCollapseButton(elementBoundingClientRect.height > collapsedSize)
    }
  }, 100)
  handleToggleButtonVisibility()

  useEffect(() => {
    window.addEventListener("resize", handleToggleButtonVisibility)
    return () => {
      window.removeEventListener("resize", handleToggleButtonVisibility)
    }
  }, [collapseContentRef])
  /* e/o check if content is higher than collapse wrapper */

  /* max height fixes for too short items */
  useEffect(() => {
    if (isCollapseOpen) {
      setCollapseMaxHeight("100%")
    } else {
      setTimeout(() => {
        setCollapseMaxHeight(`${ collapsedSize }px`)
      }, COLLAPSE_TRANSITION_DURATION)
    }
  }, [isCollapseOpen])
  /* e/o max height fixes for too short items */

  /* initial scroll to selected item */
  const BOX_TOP_PADDING_SPACING = 3
  const GUTTERS_SPACING = 1

  useEffect(() => {
    if (isDefaultOpen) {
      setTimeout(() => {
        const scrollOffset = 8 * (BOX_TOP_PADDING_SPACING + 2 * GUTTERS_SPACING)
        scrollToRefElement(collapseContentRef, -scrollOffset)
      }, 0)
    }
  }, [])
  /* e/o initial scroll to selected item */

  return (
    <Box className={classes.root}>
      <Collapse
        in={isCollapseOpen}
        collapsedSize={collapsedSize}
        className={clsx(
          classes.collapse,
          (!isCollapseOpen && showCollapseButton) && classes.isClosed
        )}
        style={{
          height: showCollapseButton ? `${ collapsedSize }px` : "100%",
          maxHeight: collapseMaxHeight,
          minHeight:  showCollapseButton ? `${ collapsedSize }px` : "unset",
        }}
      >
        <div ref={collapseContentRef}>
          { children }
        </div>
      </Collapse>

      { showCollapseButton &&
        <Box
          className={clsx(
            classes.buttonSection,
            toggleButtonPlacement === ButtonPlacement.LEFT && classes.buttonSectionLeft,
            toggleButtonPlacement === ButtonPlacement.RIGHT && classes.buttonSectionRight,
          )}
        >
          <ButtonText
            color="primary"
            onClick={handleToggleButtonClick}
          >
            { isCollapseOpen ? t("hide") : t(toggleButtonText) }
          </ButtonText>
        </Box>
      }
    </Box>
  )
}

export default CollapseWrapper
