import React, { FC, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { RouteComponentProps, useParams } from "react-router"
import { Redirect } from "react-router-dom"
import { Box } from "@material-ui/core"
import { CancelTokenSource } from "axios"
import i18n from "i18next"

import api, { ApiInstance } from "../../api/api"
import { getConsultationConfig } from "../../api/routes"
import { useAppSelector } from "../../hooks/storeHooks"
import { clearSelectedConsultationLang,setSelectedConsultationLang } from "../../store/consultations/consultations.slice"
import { selectUser } from "../../store/user/user.selectors"
import ConsultationsLoader from "../../components/common/lottieAnimations/animations/ConsultationsLoader.component"
import {ConsultationModel } from "../../components/consultation/Consultation.types"
import ConsultationChatController
  from "../../components/consultationChat/consultationChatController/ConsultationChatController.component"
import NotCompleteUserDataInformationPanel from "../../components/informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import NoPOZdeclarationInformationPanel from "../../components/informationPanel/noPozDeclarationInformationPanel/NoPOZdeclarationInformationPanel.component"
import PaymentInProgressInformationPanel from "../../components/informationPanel/paymentInProgressInformationPanel/PaymentInProgressInformationPanel.component"
import StationaryDetailsPanel from "../../components/informationPanel/stationaryDetailsPanel/StationaryDetailsPanel.component"
import { ConsultationEntryPermissionStatus } from "./ConsultationChatPage.types"

import { getConsultationPermissionStatus } from "./ConsultationChatPage.utlis"

interface ConsultationChatPageParams {
  consultationId: string
}

const ConsultationChatPage: FC<RouteComponentProps<ConsultationChatPageParams>> = () => {
  const dispatch = useDispatch()
  const {consultationId} = useParams<ConsultationChatPageParams>()
  const user = useAppSelector(selectUser)

  const [loading, setLoading] = useState(true)
  const [consultationData, setConsultationData] = useState<ConsultationModel>()

  const getConsultationData = async (cancelToken?: CancelTokenSource["token"]) => {
    try {
      const {data} = await api.request<ConsultationModel>({
        ...getConsultationConfig(consultationId),
        cancelToken,
      })

      const { language, services: { resourceStore } } = i18n
      const isConsultationLanguageAvailable = Object.keys(resourceStore.data).includes(data.language)

      dispatch(setSelectedConsultationLang(isConsultationLanguageAvailable ? data.language : language))
      setConsultationData(data)
    } catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
    }
    setLoading(false)
  }

  const paymentLink = consultationData?.paymentLink
    ? `${consultationData!.paymentLink}?redirect-url=${window.location.origin}/${i18n.language}/consultation-payment-successful/${consultationId}`
    : ""

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()

    getConsultationData(requestSource.token)

    return () => {
      setLoading(false)
      requestSource.cancel("Request interrupted by page change")
    }
  }, [consultationId])

  useEffect(() => () => {
    dispatch(clearSelectedConsultationLang())
  }, [])

  if (loading || !consultationData || !user) {
    return (
      <Box className="page-loader-box">
        <ConsultationsLoader/>
      </Box>
    )
  }

  switch (getConsultationPermissionStatus(consultationData, user)) {
    case ConsultationEntryPermissionStatus.NONE:
      return <Redirect to={`/${i18n.language}/404`}/>
    case ConsultationEntryPermissionStatus.SUCCESSFULLY_COMPLETED:
    case ConsultationEntryPermissionStatus.CONFIRMED:
      return <ConsultationChatController consultationData={consultationData!}/>
    case ConsultationEntryPermissionStatus.STATIONARY_CONSULTATION:
      return <StationaryDetailsPanel consultationData={consultationData!}/>
    case ConsultationEntryPermissionStatus.FILL_USER_DATA:
      return <NotCompleteUserDataInformationPanel />
    case ConsultationEntryPermissionStatus.NOT_PAYED:
    case ConsultationEntryPermissionStatus.PAYMENT_REFUNDED:
      if (paymentLink) {
        window.location.href = paymentLink
      }
      return null
    case ConsultationEntryPermissionStatus.PAYMENT_IN_PROGRESS:
      return <PaymentInProgressInformationPanel/>
    case ConsultationEntryPermissionStatus.NFZ_VERIFICATION_NEEDED:
      return <NoPOZdeclarationInformationPanel/>
    case ConsultationEntryPermissionStatus.ARCHIVED_WITHOUT_SUCCESS:
    default:
      return <Redirect to={`/${i18n.language}`}/>
  }
}

export default ConsultationChatPage
