import {createStyles, makeStyles} from "@material-ui/core"

export const useSuccessToastStyles = makeStyles(theme => createStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    minHeight: 70,
    padding: theme.spacing(2, 2.5)
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  description: {
    color: theme.palette.common.white,
    fontWeight: 500
  },
  closeIconWrapper: {
    paddingLeft: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    borderLeft: `1px solid ${theme.palette.common.white}`
  },
  closeIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
    cursor: "pointer"
  },
  bold: {
    fontWeight: 700
  }
}))
