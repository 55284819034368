import {RootState} from "../store.types"
import {MakeConsultationWidgetDataState} from "./makeConsultationWidget.types"

export const selectMakeConsultationWidgetLoading: ((state: RootState) => MakeConsultationWidgetDataState["loading"]) = ({makeConsultationWidget}) => {
  return makeConsultationWidget.loading
}

export const selectIsMakeConsultationWidgetModalOpen: ((state: RootState) => MakeConsultationWidgetDataState["isModalOpen"]) = ({makeConsultationWidget}) => {
  return makeConsultationWidget.isModalOpen
}

export const selectMakeConsultationWidgetConfig: ((state: RootState) => MakeConsultationWidgetDataState["config"]) = ({makeConsultationWidget}) => {
  return makeConsultationWidget.config
}