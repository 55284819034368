import {gql} from "@apollo/client"

export interface PulseMeasurementsModel {
  createdAt: string;
  id: string;
  pulse: number;
}
    
export interface PulseMeasurementsData {
  pulseMeasurements: PulseMeasurementsModel[]
}

export const GET_USER_PULSE_MEASUREMENTS = gql`
  query getUserPulseMeasurements {
    pulseMeasurements: examinations_pulse_answers(order_by: {created_at: desc}) {
      createdAt: created_at
      id
      pulse
    }
  }
`
