import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useRecommendationsSectionStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginBottom: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    }
  },
  header: {
    width: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }
}))
