import React from "react"

import {ReactComponent as CheckedIcon} from "../../../assets/svg/checkbox-checked.svg"
import {ReactComponent as UncheckedIcon} from "../../../assets/svg/checkbox-unchecked.svg"
import ImageByIntegrationType from "../../../components/imageByIntegrationType/ImageByIntegrationType"

const MuiCheckbox = {
  checkedIcon: (
    <ImageByIntegrationType
      imageSrc={<CheckedIcon/>}
      imagePath={"checkbox-checked.svg"}
      returnSvg={true}
    />
  ),
  icon: (
    <ImageByIntegrationType
      imageSrc={<UncheckedIcon/>}
      imagePath={"checkbox-unchecked.svg"}
      returnSvg={true}
    />
  ),
}

export const checkbox = {
  MuiCheckbox,
}