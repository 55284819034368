import React, {FC} from "react"
import { Controller } from "react-hook-form"
import {Checkbox, FormControlLabel} from "@material-ui/core"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"

type Props = {
  name: string;
  label: string;
  required?: boolean
}

const CheckboxController: FC<Props> = ({ name, label,required=false }) => (
  <Controller
    name={name}
    render={(
      {
        field: {
          onChange,
          value
        },
      }) => (
      <FormControlLabel
        control={(
          <Checkbox
            checked={value}
            onChange={onChange}
            checkedIcon={<CheckBoxIcon color="primary"/>}
            icon={<CheckBoxOutlineBlankIcon/>}
            value={value}
            required={required}
          />
        )}
        label={label}
      />
    )}
  />
)

export default CheckboxController
