import {LOCATION_CHANGE} from "connected-react-router"
import { SagaIterator } from "redux-saga"
import { fork, take } from "redux-saga/effects"

import consultationDocumentationSaga from "./consultationDocumentation/consultationDocumentation.saga"
import consultationsSaga from "./consultations/consultations.saga"
import errorSaga from "./error/error.saga"
import examReferralsSaga from "./examReferrals/examReferrals.saga"
import examsSaga from "./exams/exams.saga"
import logInSaga from "./logIn/logIn.saga"
import medicalExamsPointsSaga from "./medicalExamsPoints/medicalExamsPoints.saga"
import patientDocumentationSaga from "./patientDocumentation/patientDocumentation.saga"
import sessionSaga from "./session/session.saga"
import settingsSaga from "./settings/settings.saga"
import userSaga from "./user/user.saga"
import vouchersSaga from "./vouchers/vouchers.saga"

export default function* rootSaga(): SagaIterator {
  yield take(LOCATION_CHANGE)
  yield fork(sessionSaga)
  yield fork(logInSaga)
  yield fork(userSaga)
  yield fork(consultationsSaga)
  yield fork(errorSaga)
  yield fork(examsSaga)
  yield fork(consultationDocumentationSaga)
  yield fork(patientDocumentationSaga)
  yield fork(medicalExamsPointsSaga)
  yield fork(examReferralsSaga)
  yield fork(vouchersSaga)
  yield fork(settingsSaga)
}
