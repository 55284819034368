import { accordion } from "./accordion"
import { buttons } from "./buttons"
import { divider } from "./divider"
import { icons } from "./icons"
import { input } from "./input"

export const overrides = {
  ...accordion,
  ...buttons,
  ...divider,
  ...icons,
  ...input,
}
