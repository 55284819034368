import {makeStyles} from "@material-ui/core"

import theme from "../../../themes/default/theme"
import {createStyles} from "@material-ui/core/styles"

export const useAppDialogStyles = makeStyles(() => createStyles({
  dialogBox: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "400px",
    }
  },
  dialogTitle: {
    marginRight: theme.spacing(4),
  },
  dialogActions: {
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      "&$dialogActionsSpacing > *": {
        width: "100%",
        marginBottom: theme.spacing(1),
        marginLeft: 0,
      }
    },
  },
  dialogActionsSpacing: {
    padding: theme.spacing(0, 3),
    marginBottom: theme.spacing(2),
  },
  declarationPopup: {
    whiteSpace: "pre-line",
    "& .MuiDialogContent-root": {
      paddingBottom: theme.spacing(4),
    },
  }
}))
