import React, {FC} from "react"
import {ButtonBase, ButtonProps} from "@material-ui/core"
import clsx from "clsx"

import {useButtonTextStyles} from "./ButtonText.styles"

interface ButtonTextProps {
  color: ButtonTextColor;
}

type ButtonTextColor = "whiteOnBlue" | "primary" | "secondary" | "dark";

const ButtonText: FC<ButtonTextProps & Omit<ButtonProps, "color">> =({
  children,
  classes,
  className,
  color,
  ...props
}) => {

  const buttonClasses = useButtonTextStyles()
  return (
    <ButtonBase
      classes={{
        ...classes,
        root: buttonClasses[color]
      }}
      className={clsx(
        buttonClasses.root,
        className,
      )}
      {...props}
    >
      {children}
    </ButtonBase>
  )
}

export default ButtonText
