import React, { FC } from "react"
import { Link } from "react-router-dom"
import { Box, Button, Typography } from "@material-ui/core"

import ImageByIntegrationType from "../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { useResourceButtonStyles } from "./ResourceButton.styles"

interface ResourceButtonProps{
  title: string;
  imageUrl: string;
  path: string;
  imagePath: string|null;
  disabled?: boolean;
}

const ResourceButton: FC<ResourceButtonProps> = ({
  title,
  imageUrl,
  path ,
  imagePath,
  disabled
}) => {
  const {
    buttonTile,
    imageWrapper,
    image,
    buttonTitle
  } = useResourceButtonStyles()

  return (
    <Button
      component={Link}
      to={path}
      className={buttonTile}
      disabled={disabled}
    >
      <Box className={imageWrapper}>
        <ImageByIntegrationType
          imageSrc={imageUrl}
          alt="Image"
          className={image}
          imagePath={imagePath}
        />
      </Box>
      <Typography
        variant="subtitle2"
        className={buttonTitle}
      >
        {title}
      </Typography>
    </Button>
  )
}

export default ResourceButton
