import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import { useAppSelector } from "../../../hooks/storeHooks"
import { selectCancelledConsultationsVisible } from "../../../store/consultations/consultations.selectors"
import AppDialog from "../../common/appDialog/AppDialog.component"
import ButtonText from "../../common/buttonText/ButtonText.component"
import { useLatestConsultationsSectionStyles } from "./LatestConsultationsSection.styles"

interface LatestConsultationsTitleNodeProps {
  onClick(): void;
  disabled: boolean;
  onlyCancelledConsultations: boolean;
}

const LatestConsultationsTitleNode: FC<LatestConsultationsTitleNodeProps> = ({onClick, disabled, onlyCancelledConsultations}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const cancelledVisible = useAppSelector(selectCancelledConsultationsVisible)
  const classes = useLatestConsultationsSectionStyles()
  const [showNoFinishedConsultationsDialog, setShowNoFinishedConsultationsDialog] = useState<boolean>(false)

  const handleToggleNoFinishedConsultationsDialog = () => {
    setShowNoFinishedConsultationsDialog(!showNoFinishedConsultationsDialog)
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="baseline"
      flexWrap="wrap"
    >
      <AppDialog
        open={showNoFinishedConsultationsDialog}
        onClose={handleToggleNoFinishedConsultationsDialog}
        title={t("consultation:archiveTags:noFinishedConsultationsTitle")}
      >
        {t("consultation:archiveTags:noFinishedConsultationsDescription")}
      </AppDialog>
      <Typography
        variant={isMdUp ? "h2" : "h4"}
        component="h2"
      >
        { t("lately") }
      </Typography>
      <ButtonText
        className={classes.toggleVisibility}
        color="primary"
        disabled={disabled}
        onClick={onlyCancelledConsultations ? handleToggleNoFinishedConsultationsDialog : onClick}
      >
        {
          (cancelledVisible || onlyCancelledConsultations)
            ? t("hideCancelledConsultations")
            : t("showCancelledConsultations")
        }
      </ButtonText>
    </Box>
  )
}

export default LatestConsultationsTitleNode