import React from "react"
import {Trans} from "react-i18next"
import {toast} from "react-toastify"

import SuccessToast from "./SuccessToast.component"
import {useSuccessToastStyles} from "./SuccessToast.styles"

export const useSuccessToast = () => {
  const classes = useSuccessToastStyles()

  const showToast = (actionName: string, points: number) => {
    toast.success(
      SuccessToast,
      {
        data: 
          { 
            classes,
            text: (
              <Trans i18nKey="healthPlanner:successToastMessage" tOptions={{ points, actionName }}>
                0<span className={classes.bold}>1</span>2
              </Trans>
            )
          }
      }
    )
  }
  
  return showToast
}