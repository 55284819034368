import {makeStyles} from "@material-ui/core"

import { colors } from "../../../themes/colors"
import { langDropdownShadows } from "../../../themes/shadows"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useLangDropdownOnBasePageStyles = makeStyles((theme: Theme) => createStyles({
  menuPaper: {
    borderRadius: theme.spacing(1),
    boxShadow: langDropdownShadows.shadow1,
  },
  listRoot: {
    padding: 0,
  },
  listItemRoot: {
    padding: theme.spacing(0, 3, 2),
    "&:last-of-type": {
      paddingBottom: theme.spacing(3),
    },
  },
  listItemIconRoot: {
    color: theme.palette.text.primary,
    ...theme.typography.h3,
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  listItemTextRoot: {
    color: theme.palette.text.primary,
    ...theme.typography.h5,
    [theme.breakpoints.up("md")]: {
      ...theme.typography.h4,
    },
  },
  arrowIcon: {
    ...theme.typography.h4,
    marginLeft: theme.spacing(4),
  },
  customHover: {
    "&:hover, &:focus": {
      background: theme.palette.common.white,
    },
    "&:hover .icon-caret-down, &:focus .icon-caret-down": {
      transform: "translate3d(0, 6px, 0)"
    },
  },
  langButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.background.default,
    borderRadius: 0,
    transition: "transform .3s",
    height: theme.spacing(7),
    padding: theme.spacing(0, 2),
    "&:hover, &:focus": {
      background: colors.telemediGray12,
    },
    "&:not(:last-child)": {
      boxShadow: "inset 0px -1px 0px #EBEBEB",
    },
    "&:first-child": {
      borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    }
  },
  currentLangButton: {
    padding: theme.spacing(1.5, 0),
  },
  flagAndTextWrapper: {
    display: "flex",
  },
  flagImage: {
    marginRight: theme.spacing(1),
  }
}))
