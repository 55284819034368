import i18next from "i18next"
import * as yup from "yup"

interface AddressSchema {
  city: string;
  street: string;
}

export const addressFormValidation = (): yup.SchemaOf<AddressSchema> => yup.object().shape({
  city: yup.string().required(i18next.t("errors:required")),
  street: yup.string().required(i18next.t("errors:required"))
})
