import {createStyles, makeStyles} from "@material-ui/core"

export const useServicesToBuyStyles = makeStyles((theme) => createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(3),
    padding: theme.spacing(3, 0, 1),
    borderTop: `1px solid ${theme.palette.grey[100]}`
  },
  button: {
    width: "50%",
    whiteSpace: "nowrap",
    maxWidth: 200
  },
  text: {
    paddingLeft: theme.spacing(3),
  }
}))
