
const dataTestIdMap = {
  submitButton: "submitButton",
  oldPlainPasswordInput: "oldPlainPasswordInput",
  plainPasswordInput: "plainPasswordInput",
  plainPasswordRepeatInput: "plainPasswordRepeatInput"
}

export {
  dataTestIdMap
}
