import {gql} from "@apollo/client"

import {CodeName} from "./getUserActionsQuery"

export const GET_USER_ACTION_BY_ID = gql`
  query GetUserActions($actionId: uuid!) {
    userActions: users_actions(where: {id: {_eq: $actionId}}) {
      ePrescriptions: eprescriptions {
        id
        code
        drugs {
          name
          package
          quantity
        }
      }
      action {
        id
        description
        name
        type
        points
        codeName:code_name
        measurementLog: measurement_logs {
          examinationType: examination_type
        }
        fillQuestionnaire: fill_questionnaire {
          questionnaireId: questionnaire_id
        }
        remoteConsultation: remote_consultation {
          specializationId: specialization_id 
        }
      }
    }
  }
`

export enum ExaminationType {
  BLOOD_PRESSURE = "blood_pressure",
  PULSE = "pulse",
  WEIGHT = "weight",
  GLYCEMIA = "glycemia",
  WAIST = "waist"
}

export enum ActionType {
  HEALTH_PREVENTION_ARTICLES = "health_prevention_articles",
  FILL_QUESTIONNAIRE = "fill_questionnaire",
  REMOTE_CONSULTATION = "remote_consultation",
  YOUR_TASK = "your_task",
  E_PRESCRIPTION = "eprescription",
  MEASUREMENT_LOG = "measurement_log",
  MEDICAL_EXAMINATION = "medical_examination",
  SIGN_DECLARATION = "sign_declaration",
  BMI = "bmi"
}

export type Drug = {
  name: string;
  package: string;
  quantity: number;
}

export type EPrescription = {
  id: string;
  code: string;
  drugs: Drug[];
}

export type ActionUnion = {
  type: ActionType.FILL_QUESTIONNAIRE,
  fillQuestionnaire: {
    questionnaireId: string
  }
} | {
  type: ActionType.REMOTE_CONSULTATION;
  remoteConsultation: {
    specializationId: string;
  }
} | {
  type: ActionType.MEASUREMENT_LOG
  measurementLog: {
    examinationType: ExaminationType;
  }
} | {
  type: ActionType.E_PRESCRIPTION,
  ePrescriptions: EPrescription[]
} | {
  type: ActionType.YOUR_TASK | ActionType.MEDICAL_EXAMINATION | ActionType.SIGN_DECLARATION
}

export type Action = {
  id: string;
  name: string;
  description: string;
  points: number;
  codeName: CodeName;
} & ActionUnion

export type UserAction = {
  action: Action
  ePrescriptions?: EPrescription[]
}

export type UserActionResponse = {
  userActions: [UserAction] | []
}

export type GetUserActionsVariables = {
  actionId: string
}