import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useThankYouPageViewStyles = makeStyles((theme) => createStyles({
  root: {
    paddingTop: theme.spacing(3),
  },
  leftColumn: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      borderRight: `1px solid ${theme.palette.grey[100]}`,
      paddingRight: theme.spacing(2),
      marginBottom: 0,
    },
  },
  rightColumn: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(7),
    },
  },
  orderNumberContainer: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  orderNumber: {
    marginLeft: theme.spacing(.5),
  },
  visitConfirmedIcon: {
    marginTop: theme.spacing(6),
  },
  goToDozButton: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(7),
    },
  },
  pickupInfo: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  pickupInfoItem: {
    lineHeight: `${theme.spacing(2.5)}px`,
    display: "block",
  },
  icon: {
    minWidth: theme.spacing(10),
    width: theme.spacing(10),
  },
  container: {
    marginBottom: theme.spacing(4),

    "&:last-child": {
      marginBottom: 0,
    }
  },
  seeOnMap: {
    marginTop: theme.spacing(2.5),
  },
  visitConfirmedImage: {
    maxWidth: "100%",
  },
  statusInfo: {
    marginTop: theme.spacing(4),
  }
}))
