import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import { useSelector } from "react-redux"
import { useTheme } from "@material-ui/core"

import api from "../../../../api/api"
import { getUserLatestBasicMedicalQuestionnaireAnswers } from "../../../../api/routes"
import { selectUserId } from "../../../../store/session/session.selectors"
import UserDataListItem from "../../common/UserDataListItem"
import LoaderBox from "../../../common/loaderBox/LoaderBox.component"
import UserDataInfoSection from "../../common/UserDataInfoSection.component"

const UserQuestionareData = () => {
  const {t} = useTranslation()
  const theme = useTheme()
  const userId = useSelector(selectUserId)
  const [isQuestionareCompleted, setQuestionareCompleted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    let isMounted = true

    const fetchUserLatestMedicalQuestionaire = async()=>{
      try {
        if (!isMounted) {
          return
        }
        setLoading(true)
        const { data : questionerData } = await api.request({
          ...getUserLatestBasicMedicalQuestionnaireAnswers(userId)
        })
        setLoading(false)
        if ( !questionerData
            || (Array.isArray(questionerData) &&questionerData.length ===0)
        ){
          setQuestionareCompleted(false)
        } else {
          setQuestionareCompleted(true)
        }
      } catch (e) {
        setLoading(false)
      }
    }
    fetchUserLatestMedicalQuestionaire()
    return ()=>{
      isMounted = false
    }
  }, [])

  if (loading) {
    return (
      <UserDataInfoSection
        title={t("user:questionnaire")}
      >
        <LoaderBox size={theme.spacing(8)} thickness={2}/>
      </UserDataInfoSection>
    )
  }

  if (!isQuestionareCompleted) {
    return null
  }

  return (
    <>
      <UserDataInfoSection
        title={t("user:questionnaire")}
      >
        <UserDataListItem
          label={t("user:questionnaire")}
          description={isQuestionareCompleted ? t("user:questionnaireCompleted") : t("user:questionnaireNotCompleted")}
          descriptionProps={{noWrap: true}}
        />
      </UserDataInfoSection>
    </>
  )
}

export default UserQuestionareData
