import React, {FC} from "react"
import {useTranslation} from "react-i18next"

import LoaderBox from "../../../../components/common/loaderBox/LoaderBox.component"
import ActionThankYouPage from "../../components/actionThankYouPage/ActionThankYouPage.component"
import {useGetAction} from "./PreventionActionSummary.utils"
import {RoutePath} from "../../../../routes/Routes.types"

const PreventionActionSummary: FC = () => {
  const {t, i18n} = useTranslation()
  const { actionData, isLoading } = useGetAction()

  if (isLoading || !actionData) {
    return (
      <LoaderBox 
        boxType="appDialog"
      />
    )
  }

  return (
    <ActionThankYouPage
      actionType={actionData.type}
      points={actionData.points}
      buttonLink={`/${i18n.language}${RoutePath.PREVENTION}`}
      buttonLabel={t("healthPlanner:backToProphylaxis")}
    />
  )
}

export default PreventionActionSummary
