import React, {FC} from "react"
import {Box, Typography} from "@material-ui/core"
import clsx from "clsx"

import Testicles1 from "../../../../../../../assets/images/testicles1.png"
import Testicles2 from "../../../../../../../assets/images/testicles2.png"
import Testicles3 from "../../../../../../../assets/images/testicles3.png"
import ImageByIntegrationType from "../../../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import {
  useBreastExaminationDescriptionStyles
} from "../breastExaminationDescription/BreastExaminationDescription.styles"

//brak tłumaczeń docelowo kontent będzie zwracany z BE
//todo: https://telemedico.atlassian.net/browse/LESS-1615
const TesticlesExaminationDescription: FC = () => {
  const classes = useBreastExaminationDescriptionStyles()

  return (
    <Box className={classes.container}>
      <Typography
        variant="h4"
        className={classes.boldFont}
      >
        Regularnie wykonuj samobadanie jąder.
      </Typography>
      <Typography
        variant="h4"
        className={clsx(classes.boldFont, classes.marginBottom)}
      >
        Badanie wykonuj regularnie raz w miesiącu według poniższego schematu.
      </Typography>
      <Box
        className={classes.examinationStep}
      >
        <Typography
          className={classes.stepLabel}
        >
          1. Zbadaj osobno każde jądro obiema rękami trzymając je między kciukiem a palcami środkowymi. Sprawdź powierzchnię jąder.
        </Typography>
        <ImageByIntegrationType
          imageSrc={Testicles1}
          alt="Testicles1"
          className={classes.image}
          imagePath={"testicles1.png"}
        />
      </Box>

      <Box
        className={classes.examinationStep}
      >
        <Typography
          className={classes.stepLabel}
        >
          2. Oceń strukturę i konsystencję jąder poprzez przekładanie ich w dłoniach. Zwróć uwagę czy nie czujesz zgrubienia, guzków, bolesności, obrzęku lub innych zmian w obrębie jąder.
        </Typography>
        <ImageByIntegrationType
          imageSrc={Testicles2}
          alt="Testicles2"
          className={classes.image}
          imagePath={"testicles2.png"}
        />
      </Box>
      <Box
        className={classes.examinationStep}
      >
        <Typography
          className={classes.stepLabel}
        >
          3. Sprawdź obecność płynu w mosznie.
        </Typography>
        <ImageByIntegrationType
          imageSrc={Testicles3}
          alt="Testicles3"
          className={classes.image}
          imagePath={"testicles3.png"}
        />
      </Box>
      <Typography
        variant="h4"
        className={classes.boldFont}
      >
        Dzięki regularnemu samobadaniu jąder szybko wyczujesz ewentualną różnicę.
      </Typography>
      <Typography
        variant="h4"
        className={clsx(classes.boldFont, classes.marginBottom)}
      >
        Jeśli cokolwiek Cię zaniepokoi - umów się na wizytę u lekarza.
      </Typography>
    </Box>
  )
}

export default TesticlesExaminationDescription