import { gql } from "@apollo/client"

export const GET_QUESTIONNAIRE_ANSWERS = gql`
  query GetQuestionnaireAnswersUser($user_id: uuid!, $template_id: uuid!) {
    questionnaire_answers(where: {user_id: {_eq: $user_id}, template_id: {_eq: $template_id}}, order_by: {created_at: desc}) {
      id
      user_id
      status
      created_at
      answer
      questionare_template {
        code_name
      }
    }
  }
`

export type QuestionnaireTemplate = {
  code_name: string;
}

export type Answers = {
  id: string;
  name: string;
  points: number;
  value: string;
}

export type Question = {
  answer: string;
  answers: Answers[],
  enabled: boolean;
  id: string;
  showSubtitle: string|boolean;
  subtitle: string;
  title: string;
  type: string;
}

export type Answer = {
  calculateAnswersPoints?: boolean;
  canFilledAgain?: boolean;
  completedQuestionnaireTitle?: string;
  description: string;
  name: string;
  points: number;
  priority: number;
  specializationId?: string;
  questions: Question[];
}

export type QuestionnaireAnswers = {
  id: string;
  user_id: string;
  status: string;
  created_at: string;
  answer: Answer;
}

export type GetQuestionnaireAnswersResponse = {
  questionnaire_answers: QuestionnaireAnswers[];
}

export type GetQuestionnaireAnswersVariables = {
  user_id: string;
  template_id: string;
}
