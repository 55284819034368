import React, {FC} from "react"
import {Typography, TypographyProps} from "@material-ui/core"

import {ReactComponent as InfoCircle} from "../../assets/svg/infoCircle.svg"
import ImageByIntegrationType from "../imageByIntegrationType/ImageByIntegrationType"
import {useInfoLabelStyles} from "./InfoLabel.styles"

interface InfoLabelProps extends TypographyProps {}

const InfoLabel: FC<InfoLabelProps> = ({ children, ...props }) => {
  const classes = useInfoLabelStyles()

  return (
    <Typography
      variant="body2"
      className={classes.label}
      {...props}
    >
      <ImageByIntegrationType
        imageSrc={<InfoCircle className={classes.icon}/>}
        className={classes.icon}
        imagePath={"infoCircle.svg"}
        returnSvg={true}
      />
      <span>{children}</span>
    </Typography>
  )
}

export default InfoLabel
