import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import { Box, Grid, Typography } from "@material-ui/core"

import { useGetChatThreadsForUser } from "../../../../api/queries/getChatThreadsForUser/getChatThreadsForUser.hook"
import Loader from "../../../../../../components/loader/Loader.component"
import MessengerThreadItem from "./MessengerThreadItem/MessengerThreadItem.component"
import { useMessengerThreadListStyles } from "./MessengerThreadsList.styles"

type MessengerThreadsListProps = {
  threadId: string;
}

const MessengerThreadsList: FC<MessengerThreadsListProps> = ({ threadId }) => {
  const { t } = useTranslation()
  const { threadList } = useMessengerThreadListStyles()

  const { data: { chatThreads } = {}, loading, error } = useGetChatThreadsForUser()

  if(loading) {
    return <Loader m="auto" />
  }

  return (
    <Grid container className={threadList}>
      {error && (
        <Box
          m="auto"
          p={4}
        >
          <Typography variant="body1">{t("errors:defaultError")}</Typography>
        </Box>
      )}
      {!error && chatThreads?.map((thread) => {
        return (
          <Grid key={thread.id} item sm={12}>
            <MessengerThreadItem
              threadId={thread.id}
              title={t("healthPlanner:messengerChat:threadLabel")}
              selected={thread.id === threadId}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default MessengerThreadsList
