export const ssoMiddlewareHost = process.env.REACT_APP_SSO_MIDDLEWARE_HOST as string
export const registerAppUrl = process.env.REACT_APP_REGISTER_URL as string
export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY as string
export const openTokApiKey = process.env.REACT_APP_OPENTOK_API_KEY as string
export const openTokApiKey2 = process.env.REACT_APP_OPENTOK_API_KEY_2 as string
export const dozApiUrl = process.env.REACT_APP_DOZ_API_URL as string
export const alabListUrl = process.env.REACT_APP_ALAB_LIST_URL as string
export const telemediUrl = process.env.REACT_APP_TELEMEDI_URL as string
export const alabOrderHelplinePhoneNumber = process.env.REACT_APP_ALAB_ORDER_EXAMINATION_HELPLINE_PHONE_NUMBER as string
export const appEnv: appEnvType = process.env.REACT_APP_APP_ENV as appEnvType
export const multiclinicHasuraUrl = process.env.REACT_APP_MULTICLINIC_HASURA_URL as string
export const healthPlannerHasuraUrl = process.env.REACT_APP_HEALTHPLANNER_HASURA_URL as string
export const healthPlannerHasuraUrlWsLink = process.env.REACT_APP_WS_LINK_HEALTHPLANNER_HASURA_URL as string
export const prescriptionSpecializationId = process.env.REACT_APP_PRESCRIPTION_SPECIALIZATION_ID as string
export const prescriptionExtensionSpecializationId = process.env.REACT_APP_PRESCRIPTION_EXTENSION_SPECIALIZATION_ID as string
export const sickLeaveSpecializationId = process.env.REACT_APP_SICK_LEAVE_SPECIALIZATION_ID as string
export const openReplayProjectKey = process.env.REACT_APP_OPEN_REPLAY_PROJECT_KEY as string
export const localStorageAppLangKey = "appLang"
export const storedMagicLinkKey = "magicLinkId"
export const showVpozPopupOnStart = "showVpozPopupOnStart"
export const showVpozPopupOnStartDisabled = "showVpozPopupOnStartDisabled"
export const wasEpuapReSignFlowTriedOnLogin = "wasEpuapReSignFlowTriedOnLogin"
export const wasSwitchAccountPageShownOnLogin = "wasSwitchAccountPageShownOnLogin"
export const autoLogout = "autoLogout"
export const GOOGLE_STORE_TELEMEDI_APP_LINK = "https://play.google.com/store/apps/details?id=co.telemedi.prod.app&hl=pl&gl=pl"
export const GOOGLE_APIS_URL = "https://maps.googleapis.com"
export const PASSWORDLESS_MAGIC_LINK_ID_KEY = "passwordlessMagicLinkIdKey"
export const PASSWORDLESS_IS_VERIFY_KEY = "passwordlessIsVerify"
export const internistSpecializationId = "164b5f7f-b72e-4038-a67c-5308082734a9"
export const pediatristSpecializationId = "9b15942e-0267-4f4a-8802-70d8055a53f3"
export const psychologistSpecializationId = "7d616ba7-322e-4d43-85f5-732d26ea6c30"
export const forgingPediatricsSpecializationId = "0b254fd5-de90-4153-b503-b11526ef304a"
export const wordpressApiUrl = "https://content-repository.telemedi.com"

export const MAIN_ACCOUNT_MAGIC_LINK = "mainAccountMagicLink"
export const MAIN_ACCOUNT_FIRST_NAME = "mainAccountFirstName"
export const MAIN_ACCOUNT_LAST_NAME = "mainAccountLastName"

export enum appEnvType {
  DEV = "dev", // @/.env.development
  STAGING = "staging", // @/deployment/values.yaml
  PREPROD = "preprod", // @/deployment/values.preprod.yaml
  PRODUCTION = "production", // @/deployment/values.production.yaml
}

export const isStagingApiEnv = [appEnvType.DEV, appEnvType.STAGING].includes(appEnv)
export const isDevApiEnv = appEnv === appEnvType.DEV

export const benefitSystemLoginUrl = isStagingApiEnv
  ? "https://stg-keycloak.benefitsystems.pl/auth/realms/sso/protocol/openid-connect/auth?client_id=telemedi&redirect_uri=https%3A%2F%2Fsso-middleware.tmdi04.com%2Foidc-callback%3Fx-tenant%3D8f464df1-f399-4ac8-ad67-655342b53410%26identityProviderId%3Dd93741e8-ca95-43a8-9a08-f019b415833b%26redirectUrl%3Dhttps%3A%2F%2Fstaging-dashboard.tmdi01.com%2Fpl&response_type=code&scope=openid"
  : "https://login.emultisport.pl/auth/realms/sso/protocol/openid-connect/auth?client_id=telemedi&redirect_uri=https%3A%2F%2Fsso-middleware.telemedi.com%2Foidc-callback%3Fx-tenant%3D67a78ac1-99e3-4a06-94a7-8e47863e7fce%26identityProviderId%3D402f4bf7-f79d-4eed-9d52-c0fb77e1bba0%26redirectUrl%3Dhttps%3A%2F%2Fbenefitsystems.telemedi.com%2Fpl&response_type=code&scope=openid"

export const mondialcareLoginUrl = "https://www.mondialcare.eu/wp-login.php"
export const SETTINGS_PANEL_ENABLE = [
  appEnvType.DEV,
  appEnvType.STAGING,
  appEnvType.PREPROD,
]
