import React, { FC } from "react"
import { Typography } from "@material-ui/core"

import crisesImg
  from "../../../../../assets/images/healthPrevention/mentalHealth/crises.png"
import HealthPreventionSelfImprovement, {
  HealthPreventionSelfImprovementContentBox,
  HealthPreventionSelfImprovementHeaderBox
} from "../../../../../components/healthPrevention/selfImprovement/HealthPreventionSelfImprovement.component"
import ImageByIntegrationType from "../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { RoutePath } from "../../../../../routes/Routes.types"

import { mentalHealthSelfImprovementBoxItems } from "./SelfImprovementLowMood"

const MentalHealthSelfImprovementCrisesPage: FC = () => {
  const translationKeyPrefix = "mentalHealth:selfImprovement:items:crises"

  const getImage = (src: string, path: string) => {
    return <ImageByIntegrationType imageSrc={src} alt={path} imagePath={path}/>
  }

  const headerBox: HealthPreventionSelfImprovementHeaderBox = {
    title: `${translationKeyPrefix}:title`,
    content: `${translationKeyPrefix}:body`,
    redirectRoute: RoutePath.MENTAL_HEALTH_SELF_IMPROVEMENT,
    image: getImage(crisesImg, "crises.png"),
    backBtnLabel: "consultation:back",
  }

  const contentBox: HealthPreventionSelfImprovementContentBox = {
    content: <>
      <Typography>
        <strong>Kryzys Żałoby:</strong>
        <br /><br />
        Strata bliskiej osoby to jedno z najtrudniejszych doświadczeń w życiu. Może prowadzić do głębokiego smutku, poczucia pustki i dezorientacji. Ważne jest pozwolenie sobie na przeżywanie żałoby, szukanie wsparcia w rodzinie i przyjaciołach, a także rozważenie terapii u specjalisty, który pomoże przejść przez ten trudny czas.
        <br /><br />
        <strong>Kryzys Zdrowotny:</strong>
        <br /><br />
        Nagła choroba lub poważny wypadek mogą całkowicie zmienić nasze życie. W takich momentach kluczowe jest szukanie wsparcia medycznego oraz psychologicznego. Warto również otaczać się bliskimi osobami i korzystać z dostępnych grup wsparcia, które mogą dostarczyć niezbędnej pomocy i zrozumienia.
        <br /><br />
        <strong>Kryzys Finansowy:</strong>
        <br /><br />
        Problemy finansowe mogą wywoływać ogromny stres i poczucie bezsilności. Ważne jest podejmowanie kroków w celu poprawy sytuacji, takich jak stworzenie planu budżetowego, szukanie dodatkowych źródeł dochodu, czy skorzystanie z pomocy doradców finansowych. Dodatkowo, warto zwracać uwagę na swoje zdrowie psychiczne, aby uniknąć nadmiernego stresu.
        <br /><br />
        <strong>Kryzys Relacyjny:</strong>
        <br /><br />
        Kryzysy w związkach, takie jak rozstania, rozwody czy konflikty rodzinne, mogą prowadzić do głębokiego cierpienia emocjonalnego. Warto szukać wsparcia u bliskich, a także rozważyć terapię indywidualną lub małżeńską, która pomoże znaleźć skuteczne sposoby radzenia sobie z trudnościami w relacjach.
        <br /><br />
        <strong>Kryzys Egzystencjalny:</strong>
        <br /><br />
        Chwile, w których kwestionujemy sens naszego życia, mogą być wyjątkowo trudne. Ważne jest poszukiwanie odpowiedzi na nurtujące nas pytania, angażowanie się w działalność, która daje nam satysfakcję i cel, a także szukanie wsparcia w duchowości, filozofii czy psychoterapii.
        <br /><br />
        <strong>Kryzys Kryminalny:</strong>
        <br /><br />
        Stawienie czoła konsekwencjom prawnym, takim jak aresztowanie czy proces sądowy, może być paraliżujące. Kluczowe jest poszukiwanie profesjonalnej pomocy prawnej oraz wsparcia psychologicznego, które pomoże przejść przez ten trudny okres.
        <br /><br />
        W przypadku poważnych kryzysów, nie bój się szukać pomocy profesjonalnej. Psychologowie, terapeuci i doradcy mogą dostarczyć niezbędnego wsparcia i narzędzi, które pomogą w radzeniu sobie z trudnościami. Pamiętaj, że radzenie sobie z kryzysami wymaga czasu, cierpliwości i otwartości na zmiany. Warto szukać różnych rozwiązań i próbować różnych strategii, aby znaleźć te, które najlepiej odpowiadają naszym potrzebom i celom życiowym.
      </Typography>
    </>
  }

  return <HealthPreventionSelfImprovement
    headerBox={headerBox}
    contentBox={contentBox}
    boxItems={mentalHealthSelfImprovementBoxItems}
  />
}

export default MentalHealthSelfImprovementCrisesPage
