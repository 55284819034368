import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {Reducer} from "redux"

import {LogInState, UserCredentials} from "./logIn.types"

const initialState: LogInState = {
  loading: false,
  error: null,
  username: "",
  password: "",
  magicLinkExpired: false,
  redirectData: {
    redirectLoginUrl: null,
    shortLoginUrl: null,
  },
}

const logInSlice = createSlice({
  name: "logIn",
  initialState,
  reducers: {
    logInSubmit: (state: LogInState, action: PayloadAction<UserCredentials>) => {
      return {
        ...state,
        ...action.payload,
        loading: true,
        error: null,
      }
    },

    setRedirectUrlAfterLogIn: (state: LogInState, action: PayloadAction<LogInState["ref"]>) => {
      state.ref = action.payload
      return
    },
    setRedirectData: (state: LogInState, action: PayloadAction<LogInState["redirectData"]>) => {
      state.redirectData = action.payload
      state.loading = false
      return
    },
    setMagicLinkExpired: (state: LogInState, action: PayloadAction<LogInState["magicLinkExpired"]>) => {
      state.magicLinkExpired = action.payload
    },
    clearRedirectData: (state: LogInState) => {
      state.redirectData = {
        redirectLoginUrl: null,
        shortLoginUrl: null,
      }
    },
    logInReset: (state: LogInState) => {
      return {
        ref: state.ref,
        ...initialState
      }
    },
    clearRedirectUrlAfterLogin: (state: LogInState) => {
      state.ref = null
    },
    logInError: (state: LogInState, action: PayloadAction<LogInState["error"]>) => {
      state.error = action.payload
      state.loading = false
      return
    }
  }
})

export const {
  logInSubmit,
  logInReset,
  logInError,
  setRedirectUrlAfterLogIn,
  clearRedirectUrlAfterLogin,
  setRedirectData,
  clearRedirectData,
  setMagicLinkExpired,
} = logInSlice.actions

export default logInSlice.reducer as Reducer<typeof initialState>
