import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {useParams} from "react-router"
import {useHistory} from "react-router"
import {Box, Button, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import {CancelTokenSource} from "axios"
import {format} from "date-fns"
import i18next from "i18next"

import api from "../../api/api"
import {
  getConsultationConfig,
  getConsultationTermsConfig,
} from "../../api/routes"
import {selectToken} from "../../store/session/session.selectors"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import {ConsultationModel} from "../../components/consultation/Consultation.types"
import ConsultationTermSelection from "../../components/consultation/ConsultationTermSelection/ConsultationTermSelectionComponent"
import {useConsultationChangesStyles} from "./ConsultationChanges.styles"

type ConsultationTermSelectionPageProps = {
  consultationId: string;
}

const ConsultationNewTerm: FC = () => {
  const { t } = useTranslation()
  const classes = useConsultationChangesStyles()
  const theme = useTheme()
  const {consultationId} = useParams<ConsultationTermSelectionPageProps>()
  const [loading, setLoading] = useState<boolean>(true)
  const [isPopupOpen, setPopupOpen] = useState<boolean>(true)
  const [termsData, setTermsData] = useState(null)
  const [specializationName, setSpecializationName] = useState("")
  const [consultationLanguage, setConsultationLanguage] = useState("")
  const [specializationId, setSpecializationId] = useState("")
  const [consultationType, setConsultationType] = useState(0)
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const {push} = useHistory()
  const tokenData = useSelector(selectToken)

  const getConsultationTerms = async (consultationType: number, language: string, specializationId: string, day = format(new Date(), "yyyy-MM-dd")) => {
    setTermsData(null)

    const { data } = await api.request(
      {...getConsultationTermsConfig(day, consultationType, specializationId, language)}
    )

    setTermsData(data)

    setLoading(false)
  }

  const getConsultationData = async (cancelToken?: CancelTokenSource["token"]) => {
    try {
      const {data} = await api.request<ConsultationModel>({
        ...getConsultationConfig(consultationId),
        cancelToken,
      })
      const {consultationType, language, specialization: {id, name}} = data

      setSpecializationName(name)
      setConsultationType(consultationType)
      setConsultationLanguage(language)
      setSpecializationId(id)
      await getConsultationTerms(consultationType, language, id)
    } catch (e) {
      push(`/${i18next.language}/403`)
    }
  }

  useEffect(() => {
    if (!specializationId && tokenData) {
      getConsultationData()
    }
  }, [specializationId, tokenData])

  if (loading) {
    return <LoaderBox/>
  }

  return (
    <Box
      className={classes.pageSection}
      component="section"
      justifyContent="center"
    >
      {
        isPopupOpen ? (
          <Box
            display="flex"
            flexDirection="column"
          >
            <Typography
              variant="h3"
              className={classes.cancelLabel}
            >
              {t("consultation:unscheduledVisitMagicLinkChangeTermChoice")}
            </Typography>
            <Box
              display="flex"
              flexDirection={isMdUp ? "row" : "column"}
              justifyContent="center"
            >
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  className={classes.btn}
                  onClick={() => setPopupOpen(false)}
                >
                  {t("consultation:unscheduledVisitMagicLinkChangeTermButton")}
                </Button>
              </Box>
            </Box>
          </Box>
        ) : null
      }
      {
        !isPopupOpen ? (
          <ConsultationTermSelection
            termsData={termsData}
            consultationId={consultationId}
            specializationName={specializationName}
            consultationType={consultationType}
            getConsultationTerms={getConsultationTerms}
            consultationLanguage={consultationLanguage}
            specializationId={specializationId}
          />
        ): null
      }
    </Box>
  )
}

export default ConsultationNewTerm
