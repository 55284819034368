import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import FormSection from "../../PatientsRequirementsForm/FormSection/FormSection.component"
import {PatientsRequirementsStepData} from "../../../MedicalQuestionnaireStepperForm.types"

type Props = {
  patientsRequirements: PatientsRequirementsStepData
}
const PrescriptionSummary: FC<Props> = ({ patientsRequirements })=>{
  const { t } = useTranslation()

  return (
    <FormSection
      title={<Typography gutterBottom variant='h3' color='primary'>{t("prescription:prescription")}</Typography>}
    >
      {
        patientsRequirements.prescription.isDoctorConsultationRequired ? (
          <Typography
            variant='body1'
            color='textSecondary'
          >
            {t("medicalQuestionnaire:doctorWillChooseTheMedicationInfo")}
          </Typography>
        ) : (
          <Box
            component='ol'
            pl={3}
          >
            {
              patientsRequirements.prescription.prescriptions.map(({
                name,
                common_name,
                power,
                package: packageType,
                medical_product_id,
                chosenSize,
                chosenAmount, 
                unit
              }) => {
                const chosenSizeDisplay = unit
                  ? `${chosenSize} ${unit}` 
                  : packageType
                
                return <li key={medical_product_id}>
                  <Typography
                    variant='body1'
                    color='textSecondary'
                  >
                    {name} - {common_name}, {power}
                  </Typography>
                  <Typography
                    variant='body1'
                    color='textSecondary'
                  >
                    {`${t("medicalQuestionnaire:prescriptionPackagesAmount")}: ${chosenAmount}, ${t("medicalQuestionnaire:prescriptionPackageSize")}: ${chosenSizeDisplay}`}
                  </Typography>
                </li>
              })
            }
          </Box>
        )
      }
    </FormSection>
  )
}

export default PrescriptionSummary