import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const usePossibilitiesPageStyles = makeStyles((theme: Theme) => createStyles({
  pageSection: {
    margin: "auto",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    padding: theme.spacing(3, 3, 2, 3),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      flexDirection: "row",
      padding: theme.spacing(8, 8, 6, 8),
    }
  },
  content: {
    flex: "1",
  },
  button: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "unset",
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    }
  },
  imageWrapper: {
    maxHeight: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  image: {
    width: "100%",
    maxWidth: "260px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "450px",
    }
  },
}))
