import {getDozHashConfig} from "../../api/routes"
import {v4Service} from "../store.service"
import {DozHash, DozHashResponse} from "./dozHash.types"

export const dozHashApi = v4Service.injectEndpoints({
  endpoints: build => ({
    getDozHash: build.query<DozHash, void>({
      query: () => ({
        ...getDozHashConfig
      }),
      transformResponse: (response: DozHashResponse) => ({
        client_hash: response.hash,
        timestamp: response.timestamp
      })
    })
  })
})

export const { useGetDozHashQuery } = dozHashApi
