import { AxiosResponse, CancelTokenSource } from "axios"
import { addHours, format,isAfter, parseISO, sub } from "date-fns"
import i18next from "i18next"
import qs from "qs"

import api from "../../api/api"
import { getConsultationsConfig } from "../../api/routes"
import menuStationaryVisitImg from "../../assets/images/menu/menu-stationary-visit.png"
import store from "../../store"
import {
  addComingConsultations,
  clearComingConsultations,
  setComingConsultationsTotal,
} from "../../store/consultations/consultations.slice"
import theme from "../../themes/default/theme"
import { redirectToError500Page } from "../../utils/handleErrors"
import { UserSickLeaveEmploymentType } from "../../store/user/user.types"
import {
  ConsultationListParamsSort,
  ConsultationListParamsStatus,
  ConsultationListResponse,
  ConsultationStatusNumber,
  ConsultationType,
  GetConsultationsListParams,
  StationaryVisitAddress,
} from "./Consultation.types"

export interface ComingConsultationStatusLabel {
  color?: string;
  text?: string;
}

export const getConsultationTypeIcon = (
  type: ConsultationType,
  isStationaryVisit: boolean|undefined|void,
  isOutlineVariant?: boolean
): string => {

  if (isStationaryVisit === true) {
    return "icon-house"
  }

  const outlineSuffix = isOutlineVariant ? "-o" : ""
  
  switch (type) {
    case ConsultationType.VIDEO:
      return "icon-video-camera"+outlineSuffix
    case ConsultationType.CHAT:
      return "icon-chat"+outlineSuffix
    case ConsultationType.PHONE:
      return "icon-phone"+outlineSuffix
    case ConsultationType.ON_SITE:
      return ""
    case ConsultationType.SICK_LEAVE:
      return "icon-chat"+outlineSuffix
    default:
      return ""
  }
}

export const getConsultationTypeShortText = (type: ConsultationType, isStationaryVisit: boolean|undefined|void): string => {
  if (isStationaryVisit === true) {
    return "onSite"
  }

  switch (type) {
    case ConsultationType.VIDEO:
      return "video"
    case ConsultationType.CHAT:
      return "chat"
    case ConsultationType.PHONE:
      return "phone"
    case ConsultationType.ON_SITE:
      return "onSite"
    case ConsultationType.SICK_LEAVE:
      return "chat"
    default:
      return ""
  }
}

export const getComingConsultationTypeText = (type: ConsultationType, isStationaryVisit: boolean|undefined|void): string => {
  if (isStationaryVisit === true) {
    return "coming:stationaryConsultation"
  }

  switch (type) {
    case ConsultationType.VIDEO:
      return "coming:videoConsultation"
    case ConsultationType.CHAT:
      return "coming:chatConsultation"
    case ConsultationType.PHONE:
      return "coming:phoneConsultation"
    case ConsultationType.ON_SITE:
      return "coming:onSiteConsultation"
    case ConsultationType.SICK_LEAVE:
      return "coming:chatConsultation"
    default:
      return ""
  }
}

export const getComingConsultationEventTitle = (type: ConsultationType, isStationaryVisit: boolean|undefined|void): string => {
  if (isStationaryVisit === true) {
    return "comingEventTitle:onSiteConsultation"
  }

  switch (type) {
    case ConsultationType.VIDEO:
      return "comingEventTitle:videoConsultation"
    case ConsultationType.CHAT:
      return "comingEventTitle:chatConsultation"
    case ConsultationType.PHONE:
      return "comingEventTitle:phoneConsultation"
    case ConsultationType.ON_SITE:
      return "comingEventTitle:onSiteConsultation"
    default:
      return ""
  }
}

export const getComingConsultationEventDescription = (type: ConsultationType, isStationaryVisit: boolean|undefined|void): string => {
  if (isStationaryVisit === true) {
    return "comingEventDescription:onSiteConsultation"
  }

  switch (type) {
    case ConsultationType.VIDEO:
      return "comingEventDescription:videoConsultation"
    case ConsultationType.CHAT:
      return "comingEventDescription:chatConsultation"
    case ConsultationType.PHONE:
      return "comingEventDescription:phoneConsultation"
    case ConsultationType.ON_SITE:
      return "comingEventDescription:onSiteConsultation"
    default:
      return ""
  }
}

export const getConsultationLabelTag = (
  statusNumber: ConsultationStatusNumber, 
  consultationType: number, 
  isStationaryVisit: boolean|void,
  isPaid: boolean|void
): string => {
  switch (statusNumber) {
    case ConsultationStatusNumber.CANCELED_AFTER_CHECK_IN:
    case ConsultationStatusNumber.CANCELED_BY_PATIENT_SMS_LATE:
    case ConsultationStatusNumber.CANCELED_BY_PATIENT_SMS:
    case ConsultationStatusNumber.CANCELED_AFTER_TIME:
    case ConsultationStatusNumber.CANCELED:
      return "consultation:archiveTags:canceled"
    case ConsultationStatusNumber.FINISHED_NO_ANSWER:
    case ConsultationStatusNumber.FINISHED_VOICE_MAIL:
      return "consultation:archiveTags:noPatientAnswer"
    case ConsultationStatusNumber.FINISHED_TECHNICAL_ERROR:
    case ConsultationStatusNumber.FINISHED_DUPLICATED:
      return "consultation:archiveTags:technicalError"
    case ConsultationStatusNumber.FINISHED_INCORRECT_PATIENT_DATA:
      return "consultation:archiveTags:noPersonalData"
    case ConsultationStatusNumber.FINISHED_PICKED_UP_ANOTHER_PERSON:
      return "consultation:archiveTags:pickedUpByAnotherPerson"
    case ConsultationStatusNumber.MANUAL_CONSULTATION:
      return "consultation:archiveTags:manualConsultation"
    case ConsultationStatusNumber.FINISHED_NO_ANSWER_OTHER:
      return (consultationType === ConsultationType.PRESCRIPTION_ONLY || consultationType === ConsultationType.SICK_LEAVE) ? "prescription:rejectedStatus" : "consultation:archiveTags:otherError"
    case ConsultationStatusNumber.STARTED:
      return "consultation:started"
    default:
      return ""
  }
}

export const COMING_CONSULTATION_ITEMS_LIMIT = 3
export const COMING_EXAM_ITEMS_LIMIT = 3
export const LATEST_CONSULTATION_ITEMS_LIMIT = 3

export const COMING_CONSULTATION_START_VISIT_AT_HOURS_EARLIER = 1

export const have24HoursPassedAfterConsultationClosing = (consultationFinishedAt: string|null): boolean => {
  if (!consultationFinishedAt) return false
  return isAfter(new Date(), addHours(parseISO(consultationFinishedAt), 24))
}

export const isAfterConsultationStartDate = (consultationStartDate: Date): boolean => {
  return isAfter(new Date(), consultationStartDate)
}

export const getLatestConsultationId = (index: number): string => `lc-${index}`

export const getConsultations = async (
  params: GetConsultationsListParams,
  cancelToken?: CancelTokenSource["token"],
): Promise<AxiosResponse<ConsultationListResponse>> => {
  return api.request({
    ...getConsultationsConfig,
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: "brackets" })
    },
    cancelToken,
  })
}

export const refreshComingConsultationsData = async (setLoading: React.Dispatch<React.SetStateAction<boolean>>, page: number, goToMakeConsultationPage:() => void, B2BClinic: boolean): Promise<void | null> => {
  setLoading(true)
  store.dispatch(clearComingConsultations())

  try {
    const params: GetConsultationsListParams = {
      page: 1,
      limit: COMING_CONSULTATION_ITEMS_LIMIT * page,
      status: ConsultationListParamsStatus.PLANNED,
      sort: ConsultationListParamsSort.ASC,
      lang: i18next.language,
    }
    const {data} = await getConsultations(params)
    const hasBookConsultationAbility = store.getState()?.clinic?.clinicSettings?.hasBookConsultationAbility
    store.dispatch(setComingConsultationsTotal(data.total))
    store.dispatch(addComingConsultations(data.items))

    if(data.total === 0 && !store.getState().consultations.latest.length && B2BClinic && hasBookConsultationAbility) {
      goToMakeConsultationPage()
    }
  } catch (e) {
    if (api.isCancel(e)) return
    console.error(e)
    redirectToError500Page(e)
  }
  setLoading(false)
}

export const getComingConsultationStatusLabel = (
  statusNumber: number,
  isPayed: boolean,
  hasUserCompletePersonalData: boolean | null,
  isPrescritionOnly: boolean | null,
  isAfterConsultationStartDate?: boolean,
  isSickLeave?: boolean | null,
  showGoToHealthPlannerSurvey?: boolean,
  sickLeaveQuestionnaireFilled?: boolean,
  prescriptionQuestionnaireFilled?: boolean
): ComingConsultationStatusLabel | null => {
  const { palette: {error, warning, primary} } = theme
  const showSurveyNotCompletedLabel = !sickLeaveQuestionnaireFilled && isSickLeave || !prescriptionQuestionnaireFilled && isPrescritionOnly

  if (!isPayed) {
    return {
      color: error.main,
      text: i18next.t("consultation:isNotPayed")
    }
  }

  if (!hasUserCompletePersonalData) {
    return {
      color: warning.main,
      text: i18next.t("consultation:noPersonalData")
    }
  }

  if (statusNumber === ConsultationStatusNumber.RESERVED && (isPrescritionOnly || isSickLeave) || showGoToHealthPlannerSurvey) {
    if (showSurveyNotCompletedLabel && !showGoToHealthPlannerSurvey) {
      return null
    }

    return {
      color: error.main,
      text: i18next.t("prescription:surveyNotCompleted")
    }
  }

  if (statusNumber === ConsultationStatusNumber.CONFIRMED && (isPrescritionOnly || isSickLeave)) {
    return {
      color: primary.main,
      text: i18next.t("prescription:inProgress")
    }
  }

  if (statusNumber === ConsultationStatusNumber.STARTED) {
    return {
      color: primary.main,
      text: i18next.t("consultation:started")
    }
  }

  return null
}

export const getStationaryVisitAddress = (stationaryVisitAddress: StationaryVisitAddress | undefined): string  => {
  if (!stationaryVisitAddress) {
    return ""
  }

  if (stationaryVisitAddress?.facility) {
    return `${stationaryVisitAddress?.facility}`
  }

  return `${stationaryVisitAddress?.clinicName ? stationaryVisitAddress?.clinicName + " - " : ""} ${stationaryVisitAddress?.street} 
      ${stationaryVisitAddress?.houseNumber}${stationaryVisitAddress?.apartmentNumber ? `/${stationaryVisitAddress?.apartmentNumber}` : ""},
      ${stationaryVisitAddress?.city}`
}

export const isStationaryVisitConsultation = (type: ConsultationType|undefined, stationaryVisitAddress: StationaryVisitAddress|undefined): boolean => {
  if (!type) {
    return false
  }

  return isStationaryConsultation(type)
      || isStationaryNonIntegrated(type)
      || (isPhoneConsultation(type) && !!stationaryVisitAddress)
}

export const isStationaryConsultation = (type: ConsultationType): boolean => {
  return type === ConsultationType.ON_SITE
}

export const isPhoneConsultation = (type: ConsultationType): boolean => {
  return type === ConsultationType.PHONE
}

export const isInstantChatConsultation = (type: ConsultationType): boolean => {
  return type === ConsultationType.QUESTION
}

export const isPrescriptionOnlyConsultation = (type: ConsultationType|undefined): boolean => {
  return type === ConsultationType.PRESCRIPTION_ONLY
}

export const isSickLeaveConsultation = (type: ConsultationType|undefined): boolean => {
  return type === ConsultationType.SICK_LEAVE
}

export const isStationaryNonIntegrated = (type: ConsultationType|undefined): boolean => {
  return type === ConsultationType.TYPE_STATIONARY_NON_INTEGRATED
}

export const getTimeRanges = (startDate: Date, endDate: Date) => {
  return `${format(new Date(startDate), "HH:mm")} - ${format(sub(new Date(endDate), {minutes: 1}), "HH:mm")}`
}

export const getPrescriptionDrugNames = (drugNamesData?: string[]) => {
  let drugNames = ""

  if (!drugNamesData) {
    return null
  }

  drugNamesData.forEach(item => drugNames += `${item}, `)

  return drugNames.slice(0, -2)
}

export const getSickLeaveEmploymentTypeLabel = (employmentType: number | undefined): string => {
  switch(employmentType) {
    case UserSickLeaveEmploymentType.CIVILIAN_PERSON:
      return i18next.t("user:employmentTypes:civilianPerson")
    case UserSickLeaveEmploymentType.UNIFORMED_SERVICES:
      return i18next.t("user:employmentTypes:uniformedServices")
    case UserSickLeaveEmploymentType.FOREIGN_COMPANY:
      return i18next.t("user:employmentTypes:foreignCompany")
    case UserSickLeaveEmploymentType.STUDENT:
      return i18next.t("user:employmentTypes:student")
    default:
      return ""
  }
}

export const getAvatarUrl = (
  defaultUrl: string | null | undefined, 
  consultationType: ConsultationType | void,
  stationaryVisitAddress: StationaryVisitAddress | undefined | void,
  doctorId: string | undefined | void,
  stationaryVisitImageSource?: string | null
): string | null => {
  if (
    consultationType 
      && isStationaryVisitConsultation(consultationType, stationaryVisitAddress ?? undefined)
  ) {
    if (doctorId && !defaultUrl) {
      return null
    }
    
    return defaultUrl || stationaryVisitImageSource || menuStationaryVisitImg
  }

  return defaultUrl || null
}

export const getPrescriptionConsultationIdParam = (consultationId: string) => `?prescription-consultation-id=${consultationId}`

export const formatOrderedSickLeaveDate = (date: string) => {
  const twoDigitRule = "2-digit"

  return new Date(date).toLocaleDateString("pl-PL",
    {
      year: twoDigitRule,
      month: twoDigitRule,
      day: twoDigitRule
    })
}
