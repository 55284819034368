import {gql} from "@apollo/client"

import {
  CompletedQuestionnaireTemplate
} from "../../pages/preventionQuestionnaire/genericStepForm/GenericStepForm.types"

export const ADD_ANSWER = gql`
  mutation AddAnswer($answer: json!, $templateId: uuid!) {
    addAnswer(answer: {answer: $answer, template_id: $templateId}) {
      id
    }
  }
`

export type AddAnswerResponse = {
  addAnswer: {
    id: string
  }
}

export type AddAnswerVariables = {
  templateId: string;
  answer: CompletedQuestionnaireTemplate
}