import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useFileDropzoneStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadDropzone: {
      height: "250px",
      width: "500px",
      maxWidth: "100%",
      border: `2px dashed ${theme.palette.grey["400"]}`,
      borderRadius: theme.shape.buttonBorderRadius,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      transition: "opacity 0.3s, border 0.3s",
      "&:hover, &:focus": {
        border: `2px dashed ${theme.palette.primary.dark}`,
        opacity: "0.85",
      }
    },
    invalidDropzone: {
      border: `2px dashed ${theme.palette.error.main}`,
    },
    contentWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    dropzoneThumb: {
      display: "inline-flex",
      borderRadius: "2px",
      margin: theme.spacing(0,1,1,0),
    },
    dropzoneImage: {
      display: "block",
      width: "100%",
      height: "100%",
    }
  })
)