import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Reducer } from "redux"

import { isStagingApiEnv } from "../../app.config"
import { ComingEventType, SettingsState, StagingSettings } from "./settings.types"

const initialState: SettingsState = {
  lang: "pl",
  stagingSettingsLoading: false,
  stagingSettings: {
    comingEventsType: ComingEventType.ALL,
  }
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<SettingsState>) => {
      return {...state, ...action.payload}
    },
    setStagingSettings: (state, action: PayloadAction<StagingSettings>) => {
      state.stagingSettings =  {...state.stagingSettings, ...action.payload}
      return
    },
    setLanguage: (state, action: PayloadAction<SettingsState["lang"]>) => {
      state.lang = action.payload
      return
    },
    setStagingSettingsLoading: (state, action: PayloadAction<SettingsState["stagingSettingsLoading"]>) => {
      state.stagingSettingsLoading = action.payload
      return
    },
    clearSettings: () => {
      return {...initialState}
    }
  }
})

export const {
  setSettings,
  setStagingSettings,
  setLanguage,
  setStagingSettingsLoading,
  clearSettings,
} = settingsSlice.actions

export default settingsSlice.reducer as Reducer<typeof initialState>
