import {createStyles, makeStyles} from "@material-ui/core"

export const useDiseaseRisk = makeStyles(theme => createStyles({
  button: {
    width: "100%",
  },
  riskLevelWrapper: {
    marginBottom: theme.spacing(3)
  },
  image: {
    width: 130,
    height: 130,
    marginLeft: theme.spacing(5)
  }
}))