import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import WomanWithDarkThoughts from "../../../../assets/images/womanWithDarkThoughts.png"
import LifebeltIcon from "../../../../assets/svg/mentalHealth/lifebeltIcon.svg"
import ImageByIntegrationType from "../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { useCrisisTileStyles } from "./CrisisTile.styles"

interface CrisisTileProps {}
interface CrisisHelplineProps {
  number: string;
  description: string;
}

const CrisisTile: FC<CrisisTileProps> = () => {
  const { t } = useTranslation()
  const classes = useCrisisTileStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  const CrisisHelplineItem: FC<CrisisHelplineProps> = ({number, description}) =>{
    return(
      <Box className={classes.crisisHelplineItem}>
        <ImageByIntegrationType imageSrc={LifebeltIcon} imagePath={"mentalHealth/lifebeltIcon.svg"}/>
        <Typography variant={"body1"} className={classes.crisisHelplineText}>
          <b>{number}</b>
          &nbsp;-&nbsp;
          {description}
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      pt={3}
      pb={2}
      className={classes.boxCard}
    >
      <Box>
        <Typography
          variant={isMdUp ? "h3" : "h4"}
          component="h3"
          className={classes.title}
        >
          {t("mentalHealth:crisisTileTitle")}
        </Typography>

        <Typography variant={"body2"}>
          {t("mentalHealth:crisisTileTitleDescription")} <br/>
          <b>- {t("mentalHealth:crisisTileTitleDescription2")}</b>
        </Typography>

      </Box>

      <Box className={classes.contentWrapper}>

        <Box className={classes.imageWrapper}>
          <ImageByIntegrationType
            imageSrc={WomanWithDarkThoughts}
            imagePath={"womanWithDarkThoughts.png"}
          />
        </Box>

        <Box className={classes.crisisHelplineWrapper}>
          <CrisisHelplineItem
            number={t("mentalHealth:alarmPhoneNumber")}
            description={t("mentalHealth:alarmPhoneDescription")}
          />
          <CrisisHelplineItem
            number={t("mentalHealth:childrenHelplineNumber")}
            description={t("mentalHealth:childrenHelplineDescription")}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default CrisisTile
