import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import clsx from "clsx"

import { useAppSelector } from "../../../../hooks/storeHooks"
import { selectClinicSettings } from "../../../../store/clinic/clinicSettings.selectors"
import AddToCalendar from "../../../common/addToCalendar/AddToCalendar.component"
import ConsultationTypeIcon from "../../../consultation/consultationTypeIcon/ConsultationTypeIcon.component"
import ConsultationTypeText from "../../../consultation/consultationTypeText/ConsultationTypeText.component"
import {
  formatOrderedSickLeaveDate,
  getStationaryVisitAddress,
  isInstantChatConsultation,
  isPrescriptionOnlyConsultation,
  isSickLeaveConsultation,
  isStationaryVisitConsultation
} from "../../../consultation/Consultation.utils"
import { AddToCalendarEvent } from "../../../common/addToCalendar/AddToCalendar.types"
import { ConsultationType,OrderedSickLeaveDetails,StationaryVisitAddress } from "../../../consultation/Consultation.types"
import { useCommonStyles } from "../../comingServiceSections/common/Common.styles"
import { useComingServiceDetailsStyles } from "../ComingServiceDetails.styles"

type ClassKey = "root" | "textWhite"

interface ConsultationDetailsProps {
  id: string;
  classes?: Record<ClassKey, string>;
  hour: string|null;
  dateWithDay?: string|null;
  event: AddToCalendarEvent | null;
  consultationType: ConsultationType;
  stationaryVisitAddress?: StationaryVisitAddress;
  isInterestedInPoz?: boolean;
  orderedSickLeave?: OrderedSickLeaveDetails;
  detailsTitle?: string | null;
  detailsContent?: string | null;
}

const ConsultationDetails: FC<ConsultationDetailsProps> = ({
  id,
  classes,
  hour,
  dateWithDay,
  event,
  consultationType,
  stationaryVisitAddress,
  isInterestedInPoz,
  orderedSickLeave,
  detailsTitle,
  detailsContent
}) => {
  const isInstantChat = isInstantChatConsultation(consultationType)
  const isPrescriptionOnly = isPrescriptionOnlyConsultation(consultationType)
  const isSickLeave = isSickLeaveConsultation(consultationType)
  const commonClasses = useCommonStyles()
  const detailsStyles = useComingServiceDetailsStyles({})
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))
  const { t } = useTranslation()
  const {
    clinicAllianzSettings: {
      ewkaVerificationEnabled,
      onyxVerificationEnabled
    },
  } = useAppSelector(selectClinicSettings)
  
  const isAllianzEnabled = ewkaVerificationEnabled || onyxVerificationEnabled

  const isStationaryVisitType = isStationaryVisitConsultation(consultationType, stationaryVisitAddress)

  const stationaryAddress = isStationaryVisitType && stationaryVisitAddress
    ? (
      <Box mt={1} mb={1}>
        <Typography variant="body2" className={detailsStyles.stationaryVisitAddress}>
          {getStationaryVisitAddress(stationaryVisitAddress)}
        </Typography>
      </Box>
    )
    : null

  const shouldShowMinsLabel = (!isInstantChat && !isPrescriptionOnly && !isSickLeave) && !isStationaryVisitType

  const getConsultationDetailsTitle = (consultationType: number): string | undefined | null => {
    switch(consultationType) {
      case ConsultationType.QUESTION: // instant-chat
        return `${dateWithDay} - ${t("consultation:now")}`
      case ConsultationType.PRESCRIPTION_ONLY:
        return dateWithDay
      case ConsultationType.SICK_LEAVE:
        return orderedSickLeave ? t("consultation:sickLeavePeriod", { startDate: formatOrderedSickLeaveDate(orderedSickLeave?.start_at), endDate: formatOrderedSickLeaveDate(orderedSickLeave?.end_at) }) : ""
      default:
        return `${dateWithDay} - ${hour}`
    }
  }

  return (
    <Box
      display="flex"
      width="100%"
      className={commonClasses.root}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        whiteSpace={isLgUp ? "nowrap" : "initial"}
      >
        {hour && (
          <Box width="100%">
            <Typography variant="h5" component="span" className="capitalize">
              {getConsultationDetailsTitle(consultationType)}
              {
                shouldShowMinsLabel && (
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className={classes?.textWhite}
                  >
                    { isSmUp && <span>&ensp;(+/- 15 {t("consultation:min")})</span> }
                  </Typography>
                )
              }
            </Typography>
          </Box>
        )}
        {
          !(isMdUp) && (
            stationaryAddress
          )
        }
      </Box>
      {
        (isMdUp) && (
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
            mt={1}
            mb={1}
            fontWeight="400"
          >
            <Box
              display="flex"
              alignItems="flex-start"
            >
              <ConsultationTypeIcon
                consultationType={consultationType}
                isStationaryVisit={isStationaryVisitType}
                className={
                  isAllianzEnabled
                    ? detailsStyles?.allianzIconColor
                    : detailsStyles?.textWhite
                }
              />
              <ConsultationTypeText
                className={clsx(classes?.textWhite, detailsStyles?.consultationTypeName)}
                consultationType={consultationType}
                comingConsultation
                isStationaryVisit={isStationaryVisitType}
              />
            </Box>
            { stationaryAddress }
          </Box>
        )
      }
      {((hour || dateWithDay) && !isPrescriptionOnly && !isSickLeave) && (
        <Box width="100%">
          {event && (
            <AddToCalendar
              event={event}
              eventId={id}
              className={commonClasses.buttonCalendar}
            />
          )}
        </Box>
      )}
      {isPrescriptionOnly && (
        <Box
          alignSelf={isMdUp ? "initial" : "center"}
        >
          <Typography
            variant="h5"
            title={detailsTitle || ""}
          >
            <Box>
              {detailsTitle}
            </Box>
            <Typography
              className={commonClasses.detailsSectionContent}
            >
              {detailsContent}
            </Typography>
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default ConsultationDetails
