import React, {FC, useEffect, useState} from "react"
import { useTranslation } from "react-i18next"
import {useSelector} from "react-redux"
import {useHistory, useParams} from "react-router"
import {Box, Button, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import {CancelTokenSource} from "axios"
import clsx from "clsx"
import {parseISO} from "date-fns"

import api from "../../api/api"
import {getConsultationConfig} from "../../api/routes"
import {selectToken} from "../../store/session/session.selectors"
import Alert from "../../components/alert/Alert.component"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import CancelConsultationPopup
  from "../../components/consultation/cancelConsultationPopup/CancelConsultationPopup.component"
import {ConsultationModel, ConsultationStatusNumber} from "../../components/consultation/Consultation.types"
import {isAfterConsultationStartDate} from "../../components/consultation/Consultation.utils"
import {useConsultationChangesStyles} from "./ConsultationChanges.styles"

type ConsultationChangeTermPageParams = {
  consultationId: string;
}

const AUTO_REDIRECT_DELAY = 5000

const ConsultationChangeTermPage: FC = () => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const {push} = useHistory()
  const params = new URLSearchParams(window.location.search)
  const paramsObject = Object?.fromEntries?.(params)
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [loading, setLoading] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [cancelPopupOpen, setCancelPopupOpen] = useState<boolean>(paramsObject?.cancel ? true : false)
  const tokenData = useSelector(selectToken)
  const [consultationData, setConsultationData] = useState<ConsultationModel>()
  const {consultationId} = useParams<ConsultationChangeTermPageParams>()
  const canCancelConsultation = consultationData && ([ConsultationStatusNumber.CREATED, ConsultationStatusNumber.CONFIRMED].includes(consultationData?.status_number) && !isAfterConsultationStartDate(parseISO(consultationData?.visit_at)))
  const classes = useConsultationChangesStyles()

  const getConsultationData = async (cancelToken?: CancelTokenSource["token"]) => {
    try {
      const {data} = await api.request<ConsultationModel>({
        ...getConsultationConfig(consultationId),
        cancelToken,
      })

      setLoading(false)
      setConsultationData(data)
    } catch (e) {
      push(`/${i18n.language}/403`)
    }
  }

  const navigateFromCancellation = () => {
    push(`/${i18n.language}`)
  }

  const goToTermSelection = () => {
    push(`/${i18n.language}/consultation-term-selection/${consultationId}`)
  }

  const handleOnClosePopup = () => {
    setCancelPopupOpen(false)
  }

  useEffect(() => {
    if (!consultationData && tokenData) {
      getConsultationData()
    }
  }, [tokenData, consultationData])

  useEffect(() => {
    const consultationIsCancelled = consultationData?.status_number === ConsultationStatusNumber.CANCELED
    let redirectTimeout: ReturnType<typeof setTimeout>

    if (typeof canCancelConsultation === "boolean" && !canCancelConsultation) {

      setErrorMessage(t(consultationIsCancelled ? "consultation:cannotCancelOrChangeTermCancelledStatus" :"consultation:cannotCancelOrChangeTerm"))

      redirectTimeout = setTimeout(() => {
        navigateFromCancellation()
      }, AUTO_REDIRECT_DELAY)
    }

    return () => {
      clearTimeout(redirectTimeout)
    }
  }, [canCancelConsultation])

  if (loading || !consultationData) {
    return <LoaderBox />
  }

  if (!canCancelConsultation && errorMessage) {
    return (
      <Box className={classes.pageSection}>
        <Box>
          <Alert message={errorMessage}/>
          <LoaderBox />
        </Box>
      </Box>
    )
  }

  return (
    <Box
      className={classes.pageSection}
      justifyContent="center"
      component="section"
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        <Typography
          variant="h3"
          className={classes.cancelLabel}
        >
          {t("consultation:changeTermChoice")}
        </Typography>
        <Box
          display="flex"
          flexDirection={isMdUp ? "row" : "column"}
          justifyContent="center"
        >
          <Box>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              className={clsx(classes.cancelBtn, classes.btn)}
              onClick={() => setCancelPopupOpen(true)}
            >
              {t("consultation:cancelVisit")}
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              type="button"
              className={classes.btn}
              onClick={goToTermSelection}
            >
              {t("consultation:changeTerm")}
            </Button>
          </Box>
        </Box>
      </Box>
      <CancelConsultationPopup
        open={cancelPopupOpen}
        consultationId={consultationId}
        onClose={handleOnClosePopup}
        onSuccess={() => push(`/${i18n.language}/consultation-cancelled-successfully`)}
        customCancelation
        closeOnSuccess={false}
        goToTermSelection={goToTermSelection}
        dialogCustomParams={{
          label: t("consultation:cancelConfirmationPopupLabel"),
          title: t("consultation:cancelConfirmationPopupHeader"),
          cancelLabel: t("prescription:noLabel"),
          confirmLabel: t("prescription:yesLabel"),
          hideButtonIcon: true,
        }}
      />
    </Box>
  )
}

export default ConsultationChangeTermPage
