import React, {FC} from "react"
import {Box, Tooltip, Typography} from "@material-ui/core"

import useConsultationLang from "../../../../hooks/useConsultationLang"
import IconByIntegrationType from "../../../iconByIntegrationType/IconByIntegrationType"

interface ConsultationAdditionalMessageTooltipProps {}

const ConsultationAdditionalMessageTooltip: FC<ConsultationAdditionalMessageTooltipProps> = () => {
  const consultationLabel = useConsultationLang()

  return (
    <Tooltip
      title={
        <Box p={1}>
          <Typography>{consultationLabel.getLabel("chatConsultation:oneLastQuestionTooltip")}</Typography>
        </Box>
      }>
      <span>
        <IconByIntegrationType {...{ component: "span", variant: "h3", color: "textSecondary" }}
          iconName={"icon-info"}
          returnTypography={true}
        />
      </span>
    </Tooltip>
  )}

export default ConsultationAdditionalMessageTooltip
