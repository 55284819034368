import React, { FC } from "react"
import { Typography } from "@material-ui/core"

import difficultiesInRelationshipsImg
  from "../../../../../assets/images/healthPrevention/mentalHealth/difficulties-in-relationships.png"
import HealthPreventionSelfImprovement, {
  HealthPreventionSelfImprovementContentBox,
  HealthPreventionSelfImprovementHeaderBox
} from "../../../../../components/healthPrevention/selfImprovement/HealthPreventionSelfImprovement.component"
import ImageByIntegrationType from "../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { RoutePath } from "../../../../../routes/Routes.types"

import { mentalHealthSelfImprovementBoxItems } from "./SelfImprovementLowMood"

const MentalHealthSelfImprovementDifficultiesInRelationshipsPage: FC = () => {
  const translationKeyPrefix = "mentalHealth:selfImprovement:items:difficultiesInRelationships"

  const getImage = (src: string, path: string) => {
    return <ImageByIntegrationType imageSrc={src} alt={path} imagePath={path}/>
  }

  const headerBox: HealthPreventionSelfImprovementHeaderBox = {
    title: `${translationKeyPrefix}:title`,
    content: `${translationKeyPrefix}:body`,
    redirectRoute: RoutePath.MENTAL_HEALTH_SELF_IMPROVEMENT,
    image: getImage(difficultiesInRelationshipsImg, "difficulties-in-relationships.png"),
    backBtnLabel: "consultation:back",
  }

  const contentBox: HealthPreventionSelfImprovementContentBox = {
    content: <>
      <Typography>
        <strong>Rodzicielstwo: Balans między miłością a dyscypliną</strong>
        <br /><br />
        Rodzicielstwo to jedno z największych wyzwań życiowych, które może sprawić wiele radości, ale również napotkać na wiele trudności. Balansowanie między miłością i dyscypliną, znajdowanie wspólnego języka z dzieckiem oraz radzenie sobie z poczuciem winy to tylko niektóre z wyzwań, z którymi mogą zmagać się rodzice.
        <br /><br />
        <strong>Trudności z budowaniem i utrzymywaniem relacji: Komunikacja i zaufanie</strong>
        <br /><br />
        Budowanie i utrzymywanie zdrowych relacji wymaga pracy i zaangażowania. Trudności mogą pojawić się w różnych obszarach, począwszy od komunikacyjnych barier, przez brak zaufania, po konflikty związane z różnicami osobowości czy wartości. Warto podejmować wysiłek, aby zrozumieć i rozwiązać te problemy.
        <br /><br />
        <strong>Izolacja i samotność: Szukanie wspólnoty i wsparcia</strong>
        <br /><br />
        Czasem czujemy się samotni nawet wśród tłumu ludzi. Izolacja społeczna i samotność mogą mieć negatywny wpływ na nasze zdrowie psychiczne i emocjonalne. Ważne jest szukanie wspólnoty, czy to poprzez uczestnictwo w grupach wsparcia, angażowanie się w hobby, czy nawiązywanie nowych kontaktów.
        <br /><br />
        <strong>Tożsamość seksualna: Akceptacja i zrozumienie</strong>
        <br /><br />
        Dla niektórych ludzi odkrycie i zaakceptowanie swojej tożsamości seksualnej może być trudnym procesem. Często towarzyszą temu napięcia z rodziną, przyjaciółmi, a także wewnętrzne konflikty. Wsparcie ze strony społeczności LGBTQ+ oraz terapeuty może pomóc w zrozumieniu i zaakceptowaniu siebie.
        <br /><br />
        <strong>Wspólne radzenie sobie:</strong>
        <br /><br />
        Nie ważne, z jakim rodzajem trudności w relacjach się zmagasz, pamiętaj, że nie jesteś sam. Wspólnie możemy szukać rozwiązań, dzielić się doświadczeniami i wspierać się nawzajem w trudnych chwilach. Nie wahaj się szukać pomocy, gdy tylko poczujesz, że nie potrafisz sam poradzić sobie z danym problemem.
        <br /><br />
        <strong>Znajdź wsparcie i zasoby:</strong>
        <br /><br />
        Na naszej stronie znajdziesz wiele artykułów, poradników oraz informacji na temat radzenia sobie z różnorodnymi trudnościami w relacjach. Nie wahaj się skorzystać z tych zasobów i zacznij budować zdrowsze i bardziej satysfakcjonujące relacje już dziś!
      </Typography>
    </>
  }

  return <HealthPreventionSelfImprovement
    headerBox={headerBox}
    contentBox={contentBox}
    boxItems={mentalHealthSelfImprovementBoxItems}
  />
}

export default MentalHealthSelfImprovementDifficultiesInRelationshipsPage
