import {gql} from "@apollo/client"

export const GET_MEASUREMENT_ACTION = gql`
  query MeasurementAction($examinationType: String!) {
    usersActions: users_actions(where: {action: {measurement_logs: {examination_type: {_eq: $examinationType}}}}) {
      id
      completedAt: completed_at
      action {
        points
        name
      }
    }
  }
`

type UserAction = {
  id: string;
  completedAt: string | null;
  action: {
    points: number;
    name: string;
  }
}

export type GetMeasurementActionResponse = {
  usersActions: [UserAction] | []
}

export type GetMeasurementActionVariables = {
  examinationType: string;
}