import { createStyles, makeStyles } from "@material-ui/core"

import { colors } from "../../../../../themes/colors"
import { boxShadows } from "../../../../../themes/shadows"
import { Theme } from "@material-ui/core/styles"

interface Props {
  awardReached: boolean;
}

export const useAwardsListItemStyles = makeStyles((theme: Theme) => createStyles({
  listItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    border: "none",
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 0,
    width: "100%",
    background: theme.palette.background.paper,
    padding: theme.spacing(2, 1),
    "&:last-child": {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    [theme.breakpoints.up("md")]: {
      border: "none",
      boxShadow: boxShadows.shadow2,
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(2,0)
    },
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 69,
    height: 69,
    minWidth: 69,
    overflow: "hidden",
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      width: 126,
      height: 80,
      minWidth: 126,
    },
  },
  image: {
    width: "100%",
    borderRadius: theme.spacing(1.5),
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingLeft: theme.spacing(1.5),
  },
  infoTitle: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    textAlign: "left",
    [theme.breakpoints.up("md")]: {
      maxWidth: "unset",
    },
  },
  label: {
    color: colors.telemediBackground,
    background: (props: Props) => props.awardReached ? theme.palette.primary.main : theme.palette.secondary.main,
    lineHeight: "16px",
    borderRadius: theme.spacing(.5),
    padding: theme.spacing(.5, 1)
  },
  description: {
    textAlign: "left",
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(1),
  },
  progressbarLabel: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    fontWeight: 700,
    "& span": {
      color: theme.palette.primary.dark,
    }
  },
  progressbar: {
    minWidth: 200,
    borderRadius: theme.spacing(.5),
  },
}))