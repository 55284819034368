import {gql} from "@apollo/client"

export const GET_USER_DISEASE_PROBABILITY_BY_QUESTIONNAIRE_ID = gql`
  query UsersDiseasesProbability($questionnaireId: uuid!) {
    usersDiseasesProbability: users_diseases_probability(
      where: {
        disease: {
          questionnaire_id: { _eq: $questionnaireId }
        }
      }
    ) {
      probability
      disease {
        id
        name
      }
    }
  }
`

export type GetUserDiseaseProbabilityResponse = {
  usersDiseasesProbability: [{
    probability: number // from 0 to 1
    disease: {
      id: string
      name: string
    }
  }] | []
}

export type GetUserDiseaseProbabilityVariables = {
  questionnaireId: string
}