import React, { FC, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MenuItem, StandardTextFieldProps, TextField} from "@material-ui/core"
import { CancelTokenSource } from "axios"

import api, { ApiInstance } from "../../../api/api"
import { getCountriesConfig } from "../../../api/routes"
import { Countries,CountryOption } from "./Country.types"

interface CountrySelectProps extends Partial<StandardTextFieldProps> {
  value?: CountryOption["value"] | null;
  onChange?: any; // TODO: any
  name?: string;
  countriesData?: Countries;
}

const CountrySelect: FC<CountrySelectProps> = ({ value, disabled, name, countriesData, ...props}) => {
  const {i18n} = useTranslation()
  const countryOptionsInitialState: CountryOption[] = value
    ? [{
      value,
      label: value,
    }]
    : []
  const [options, setOptions] = useState<CountryOption[]>(countryOptionsInitialState)
  const [loading, setLoading] = useState<boolean>(false)

  const convertToOptions = (countryCodes: Countries) => Object.entries(countryCodes).map(([code, codeWithCountry]) => ({
    value: code,
    label: codeWithCountry,
  }))

  const getCountries = async (cancelToken?: CancelTokenSource["token"]) => {
    const {data: {countries}} = await api.request<{countries: Countries}>({
      ...getCountriesConfig(i18n.language),
      cancelToken
    })
    return countries
  }

  const getCountryOptions = useCallback(async (cancelToken?: CancelTokenSource["token"]) => {
    setLoading(true)
    try {
      const countries = countriesData ?? await getCountries(cancelToken)

      setOptions(convertToOptions(countries))
      setLoading(false)
    } catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
    }
  }, [getCountriesConfig])

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()

    getCountryOptions(requestSource.token)

    return () => {
      setOptions(countryOptionsInitialState)
      requestSource.cancel("Request interrupted by page change")
    }
  }, [])

  return (
    <TextField
      value={value}
      name={name || ""}
      select
      disabled={disabled || loading}
      {...props}
    >
      {options.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default CountrySelect
