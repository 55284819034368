import i18next from "i18next"
import { SagaIterator } from "redux-saga"
import { call, put, takeLatest } from "redux-saga/effects"

import api from "../../api/api"
import {getManyUserAccountsByLogin, logInConfig} from "../../api/routes"
import {showVpozPopupOnStart} from "../../app.config"
import { analytics, LogEventType } from "../../services/analytics"
import {setLoginAccounts} from "../loginAccounts/logInAccounts.slice"
import { initSession } from "../session/session.slice"
import {MAKE_DECLARATION_URL} from "../../pages/declarationSign/DeclarationSignFailed.component"
import {getDifferentRoleRedirectUrl} from "../../pages/login/LoginPage.utils"
import { redirectToError500Page } from "../../utils/handleErrors"
import {LogInValidationError} from "../../pages/login/LoginPage.types"
import { LogInErrorResponse } from "./logIn.types"

import {logInError, logInSubmit, setRedirectData} from "./logIn.slice"

function* logInSubmitSaga({payload}: ReturnType<typeof logInSubmit | any>) {
  sessionStorage.removeItem(MAKE_DECLARATION_URL)
  if (payload?.accessToken) { // MFA login
    yield put(initSession({
      token: payload?.accessToken,
      refresh_token: payload?.refreshToken,
    }))
  } else {
    try {
      const {data} = yield call(api.request,
        {
          ...logInConfig,
          data: payload
        })

      if (data?.token?.accessToken) {
        analytics.sendEventWithDefaultParams(LogEventType.LOGIN_PASSWORD_GRANT_SUCCESS)
        const redirectData = getDifferentRoleRedirectUrl(data?.token?.accessToken)

        localStorage.setItem(showVpozPopupOnStart, "true")

        if (redirectData?.redirectLoginUrl) {
          yield put(setRedirectData(redirectData))

          return
        } else {
          sessionStorage.setItem("isLoggedIn", "true")
          yield put(initSession({
            token: data?.token?.accessToken,
            refresh_token: data?.token?.refreshToken,
          }))
        }
      } else {
        if (data?.error === LogInValidationError.TWO_FACTOR_REQUIRED) {
          yield put(logInError({
            data,
            status: 242 // two factor auth required status
          }))

          return
        }
        throw {
          response: {
            data: {
              detail: i18next.t("errors.unknownError"),
            } as Partial<LogInErrorResponse>
          },
        }
      }
    } catch (e) {
      if (payload.username) {
        try {
          const {data} = yield call(api.request,{
            ...getManyUserAccountsByLogin(i18next.language),
            params: {
              login: payload.username,
            }
          })
          if (data.result.length > 1) {
            analytics.sendEventWithDefaultParams(LogEventType.MANY_BY_LOGIN_SUCCESS)
            yield put(setLoginAccounts(data.result))
          }
        } catch (error) {
          const errorLog = {
            "error_code": error.response.status,
            "error_name": error.response.data.error,
            "login_name": payload.username,
          }
          analytics.sendEventWithDefaultParams(LogEventType.MANY_BY_LOGIN_ERROR, errorLog)
          console.error(error)
        }
      }

      const errorLog = {
        "error_code": e.response.status,
        "error_name": e.response.data.error,
        "login_name": payload.username,
      }
      analytics.sendEventWithDefaultParams(LogEventType.LOGIN_PASSWORD_GRANT_ERROR, errorLog)

      yield put(logInError({
        data: e.response.data,
        status: e.response.status,
      }))
      yield call(redirectToError500Page, e)
    }
  }
}

export default function* (): SagaIterator {
  yield takeLatest(logInSubmit, logInSubmitSaga)
}
