import { useSubscription } from "@apollo/client"

import { GetMessagesForThreadResponseData, GetMessagesForThreadVariables } from "./getMessagesForThread.types"
import { SubscriptionHookOptions } from "@apollo/client/react/types/types"

import { GET_MESSAGES_FOR_THREAD } from "./getMessagesForThread.query"

export const useGetMessagesForThread = (options?: SubscriptionHookOptions<GetMessagesForThreadResponseData, GetMessagesForThreadVariables>) => {
  return useSubscription<GetMessagesForThreadResponseData, GetMessagesForThreadVariables>(
    GET_MESSAGES_FOR_THREAD,
    {
      context: { clientName: "HealthPlanner" },
      ...options
    }
  )
}