import React, {FC, useMemo} from "react"
import {useTranslation} from "react-i18next"
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import clsx from "clsx"
import {add, parse} from "date-fns"

import IconByIntegrationType from "../iconByIntegrationType/IconByIntegrationType"
import PrescriptionFooter from "./prescriptionFooter/PrescriptionFooter.component"
import PrescriptionHeader from "./prescriptionHeader/PrescriptionHeader.component"
import PrescriptionPosition from "./prescriptionPosition/PrescriptionPosition.component"
import {Availability, Doz, DozItem, PrescriptionModel, PrescriptionPositionModel} from "./Prescription.types"
import {usePrescriptionStyles} from "./Prescription.styles"

type ParentClassKey = "root" | "summaryRoot" | "detailsRoot"

interface PrescriptionProps extends PrescriptionModel {
  doz: Doz;
  onChange: AccordionProps["onChange"];
  expanded: AccordionProps["expanded"];
  expandIcon: AccordionSummaryProps["expandIcon"];
  parentClasses: Record<ParentClassKey, string>;
  consultationId: string;
  setShowPrescriptionsPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const Prescription: FC<PrescriptionProps> = ({
  doz,
  packageCode,
  date,
  positions,
  id,
  expanded,
  onChange,
  expandIcon,
  parentClasses,
  consultationId,
  setShowPrescriptionsPopup,
}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const validToDate = add(parse(date, "yyyy-MM-dd HH:mm:ss", new Date()), {days: 30}) // the prescription is valid for 30 days: https://pacjent.gov.pl/e-recepta/jak-dlugo-wazna-jest-e-recepta
  const validTo = t("dates:dayMonthYearShort", {date: validToDate})
  const classes = usePrescriptionStyles()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const isPrescriptionValid = new Date() <= validToDate
  const positionsCount = positions.length

  const getPositionDoz = (ean: PrescriptionPositionModel["code_e_a_n"]): DozItem | undefined => {
    return doz.items.find(item => parseInt(ean, 10) === item.ean)
    //prescription position ean codes (type string) may contain additional "0" on start position
  }

  const dozItems = positions.reduce((items: DozItem[], position: PrescriptionPositionModel) => {
    const item = getPositionDoz(position.code_e_a_n)
    return (item && item.availability === Availability.ACTIVE && position.quantity > 0)
      ? [...items, {
        ...item,
        quantity: position.quantity,
      }]
      : items
  }, [])

  const notAvailableDozItemsList = useMemo(() => {
    const notAvailableItemsList: JSX.Element[]|null = []
    const notAvailableDozItems = positions.reduce((items: DozItem[], position: PrescriptionPositionModel) => {
      const item = getPositionDoz(position.code_e_a_n)
      return (item && item.availability !== Availability.ACTIVE)
        ? [...items, {...item}]
        : []
    }, [])

    if (!notAvailableDozItems) {
      return null
    }

    notAvailableDozItems.forEach(item => {
      notAvailableItemsList.push(
        <Typography 
          variant="body2" 
          color="error"
        >
          {t("prescription:dozItemNotAvailable", {medicineName: item.name})}
        </Typography>
      )
    })

    return (
      <Box mt={1.5}>
        {notAvailableItemsList}
      </Box>
    )
  }, [])

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      square
      elevation={0}
      classes={{
        root: parentClasses.root
      }}
    >
      <AccordionSummary
        id={`${id}-header`}
        aria-controls={`${id}-content`}
        expandIcon={expandIcon}
        classes={{
          root: parentClasses.summaryRoot
        }}
      >
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="space-between"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box
              display="flex"
              alignItems="center"
            >
              <PrescriptionHeader
                packageCode={packageCode}
                setShowPrescriptionsPopup={setShowPrescriptionsPopup}
              />
            </Box>
          </Grid>
          {isMdUp && (
            <Grid
              item
              className={clsx(classes.expand, expanded ? classes.hidden : classes.visible)}
            >
              <PrescriptionFooter
                id={id}
                validTo={validTo}
                date={date}
                consultationId={consultationId}
                dozItems={dozItems}
                isPrescriptionValid={isPrescriptionValid}
                expanded={expanded}
              />
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: parentClasses.detailsRoot
        }}
      >
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Grid
              container
              spacing={6}
              component="ul"
              className={classes.list}
            >
              {positions.map((position, idx) => (
                <Grid
                  key={`${position.code_e_a_n}-${idx}`}
                  item
                  xs={12}
                  sm={6}
                  component="li"
                  classes={{item: classes.listItem}}
                >
                  <PrescriptionPosition
                    {...position}
                    hiddenBorder={idx === positionsCount - 2 && isSmUp} // one before last child
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid
              item
              md={3}
            />
          </Hidden>
          <Grid
            item
            xs={12}
            lg={9}
          >
            <Box display="flex" justifyContent="flex-end">
              <PrescriptionFooter
                id={id}
                date={date}
                validTo={validTo}
                expanded={true}
                consultationId={consultationId}
                dozItems={dozItems}
                isPrescriptionValid={isPrescriptionValid}
              />
              <Hidden smDown>
                {
                  !expanded && (
                    <Box p={1.5} color="transparent">
                      <IconByIntegrationType {...{ color: "inherit" }}
                        iconName={"icon-caret-down"}
                        returnTypography={true}
                      />
                    </Box>
                  )
                }
              </Hidden>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
      {notAvailableDozItemsList}
    </Accordion>
  )
}

export default Prescription
