import React, { FC, useEffect } from "react"
import TagManager from "react-gtm-module"
import clsx from "clsx"

import { useAppSelector } from "../hooks/storeHooks"
import { selectUserId } from "../store/session/session.selectors"
import CookieInfo from "../components/common/cookieInfo/CookieInfo.component"
import useLayoutStyles from "./Layout.styles"

interface BaseLayoutProps {
  className?: string;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children, className }) => {
  const classes = useLayoutStyles()

  const patientId = useAppSelector(selectUserId)
  useEffect(() => {
    if (patientId) {
      TagManager.dataLayer({
        dataLayer: {
          user_id: patientId,
        }
      })
    }
  }, [patientId])

  return (
    <div className={clsx(classes.root, className)}>
      {children}
      <CookieInfo/>
    </div>
  )
}

export default BaseLayout
