import React, {FC, ReactNode} from "react"
import {
  Box,
  Typography,
} from "@material-ui/core"
import clsx from "clsx"

import { useCommonStyles } from "../../comingServiceSections/common/Common.styles"
import { useComingServiceDetailsStyles } from "../ComingServiceDetails.styles"

interface ExamDetailsProps {
  datePlaceholder?: string | ReactNode;
  facilityName?: string;
  facilityAddress?: string;
  isOutdatedVariant?: boolean;
}

const ExamDetails: FC<ExamDetailsProps> = ({
  datePlaceholder,
  facilityName,
  facilityAddress,
  isOutdatedVariant,
}) => {
  const detailsClasses = useComingServiceDetailsStyles({ isOutdatedVariant })
  const commonClasses = useCommonStyles()

  return (
    <Box
      display="flex"
      width="100%"
      className={commonClasses.root}
    >
      <Box mb={.5} width="100%">
        <Typography
          variant="h5"
          component="div"
          className={clsx(detailsClasses?.textWhite, "capitalize")}
        >
          {facilityName}
        </Typography>

        <Typography
          variant="h5"
          component="div"
          className={detailsClasses?.textWhite}
        >
          {facilityAddress}
        </Typography>

        <Typography
          variant="body1"
          component="div"
        >
          { datePlaceholder}
        </Typography>
      </Box>
    </Box>
  )
}

export default ExamDetails
