import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useParams} from "react-router"
import {useLazyQuery, useMutation, useQuery} from "@apollo/client"
import {Box, Typography} from "@material-ui/core"

import {RECEIVE_AWARD, ReceiveAwardResponse, ReceiveAwardVariables} from "../../api/mutations/receiveAward"
import {AwardByIdResponseData, AwardByIdVariables, GET_AWARD_BY_ID} from "../../api/queries/getAwardById"
import {GET_RECEIVED_AWARD, ReceivedAwardResponseData} from "../../api/queries/getReceivedAward"
import LoaderBox from "../../../../components/common/loaderBox/LoaderBox.component"
import AwardDetails from "../../components/awardDetails/AwardDetails.component"
import {useAwardDetailsStyles} from "../../components/awardDetails/AwardDetails.styles"
import AwardReceivingError from "../../components/awardDetails/AwardReceivingError"
import AwardSuccessfullyReceivedModal
  from "../../components/awardDetails/awardSuccessfullyReceivedModal/AwardSuccessfullyReceivedModal.component"
import {useAwards} from "../../components/awardsList/AwardsList.utils"
import PageWrapper from "../../components/pageWrapper/PageWrapper.component"
import {RoutePath} from "../../../../routes/Routes.types"

const AwardsDetailsPage: FC = () => {
  const { t } = useTranslation()
  const { awardId } = useParams<{ awardId: string }>()
  const { getNumberOfUserPoints } = useAwards()
  const { overlay } = useAwardDetailsStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [isError, setIsError] = useState(false)

  const {
    data: { awards, userPoints } = {},
    error: error,
    loading: loading,
  } = useQuery<AwardByIdResponseData, AwardByIdVariables>(GET_AWARD_BY_ID, {
    context: {clientName: "HealthPlanner"},
    variables: { awardId }
  })

  const [receiveAward, {
    data: receiveAwardData,
    loading: receiveAwardLoading,
    error: receiveAwardError
  }] = useMutation<ReceiveAwardResponse, ReceiveAwardVariables>(RECEIVE_AWARD, {
    context: { clientName: "HealthPlanner" }
  })

  const [getReceivedAward, {
    data: { receivedAward = [] } = {},
    error: receivedAwardError,
    loading: receivedAwardLoading,
  }] = useLazyQuery<ReceivedAwardResponseData>(GET_RECEIVED_AWARD, {
    context: {clientName: "HealthPlanner"},
    onCompleted: () => {
      if(receivedAwardError) {
        setIsError(true)
      }
      if(receivedAward && !receivedAwardLoading && !receivedAwardError) {
        setIsOpen(true)
      }
    },
  })

  const handleReceiveAward = () => {
    receiveAward({
      variables: {
        awardId
      }
    })
  }

  useEffect(()=>{
    if(receiveAwardData?.receiveAward.status === "success") {
      getReceivedAward()
    }
  },[receiveAwardData])

  useEffect(()=>{
    if(receiveAwardError) {
      setIsError(true)
    }
  },[receiveAwardError])

  return (
    <PageWrapper navigateBackTo={RoutePath.PREVENTION_AWARDS}>
      {loading && <LoaderBox boxType="appDialog" />}
      {error && <Typography variant="body1">{t("errors:defaultError")}</Typography>}
      {awards && !loading && (
        <AwardDetails
          validTo={awards[0].validTo}
          points={awards[0].points}
          name={awards[0].name}
          description={awards[0].description}
          imageUrl={awards[0].imageUrl}
          awardAvailable={getNumberOfUserPoints(userPoints?.aggregate?.sum?.amount) >= awards[0].points}
          receiveAward={handleReceiveAward}
          receiveAwardLoading={receiveAwardLoading}
        />
      )}

      {receiveAwardLoading && receivedAwardLoading && (
        <Box className={overlay}>
          <LoaderBox boxType="fullPage" />
        </Box>
      )}
      <AwardReceivingError
        showAwardReceivingErrorDialog={isError}
        setShowAwardReceivingErrorDialog={setIsError}
      />
      {awards && !loading && (
        <AwardSuccessfullyReceivedModal
          isOpen={isOpen}
          awardUrl={awards[0].imageUrl}
          code={receivedAward[0]?.code}
        />
      )}
    </PageWrapper>
  )
}

export default AwardsDetailsPage
