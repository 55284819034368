import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Button, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import {useAwardDetailsStyles} from "./AwardDetails.styles"

interface AwardDetailsProps {
  validTo: string;
  points: number;
  name: string;
  description: string;
  imageUrl: string;
  awardAvailable: boolean;
  receiveAward(): void;
  receiveAwardLoading: boolean;
}

const AwardDetails: FC<AwardDetailsProps> = ({
  validTo,
  points,
  name,
  description,
  imageUrl,
  awardAvailable,
  receiveAward,
  receiveAwardLoading
}) => {
  const { t } = useTranslation()
  const {
    boxCard,
    imageWrapper,
    image,
    awardTitle,
    submitButton,
    descWrapper,
    desc,
    awardPoints,
  } = useAwardDetailsStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Box
      px={2}
      py={3}
      className={boxCard}
    >
      <Box
        display="flex"
        flexDirection={isSmUp ? "row" : "column"}
        justifyContent="space-between"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
        >
          <Typography
            variant={isMdUp ? "h2" : "h3"}
            component="h4"
            className={awardTitle}
          >
            {name}
          </Typography>
          <Typography
            variant={isMdUp ? "body1" : "body2"}
          >
            {t("healthPlanner:validToLabel")}: {t("dates:dayMonthYearShort", { date: new Date(validTo) })}
          </Typography>
          {isSmUp && (
            <Typography
              variant="h3"
              component="span"
              className={awardPoints}
            >
              <span>{points}</span> {t("awards:points")}
            </Typography>
          )}
        </Box>
        <Box className={imageWrapper}>
          <img
            className={image}
            src={imageUrl || ""}
            alt=""
          />
        </Box>
      </Box>
      <Box
        className={descWrapper}
      >
        {isSmUp && (
          <Typography
            variant="h3"
            component="h5"
          >
            {t("healthPlanner:moreInfo")}
          </Typography>
        )}
        <Typography
          variant="body1"
          className={desc}
        >
          {description ? description : "----------"}
        </Typography>
      </Box>
      <Button
        color="primary"
        variant="contained"
        className={submitButton}
        onClick={receiveAward}
        disabled={!awardAvailable || receiveAwardLoading}
      >
        {t("healthPlanner:receiveAward")}
      </Button>
    </Box>
  )
}

export default AwardDetails