import React, {FC} from "react"
import {get,useFieldArray, useFormContext} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box, IconButton, Typography} from "@material-ui/core"

import {ReactComponent as TrashIcon} from "../../../../../../../assets/svg/trash.svg"
import ExamsSelect from "../../../../../../../components/exam/examsSelect/ExamsSelect.component"
import {ExamListItem} from "../../../../../../../components/exam/examsSelect/ExamsSelect.types"
import ImageByIntegrationType from "../../../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import {PatientsRequirementsStepData} from "../../../../MedicalQuestionnaireStepperForm.types"
import {useExamFieldsStyles} from "./ExamFields.styles"

const ExamFields: FC = () => {
  const {t} = useTranslation()
  const classes = useExamFieldsStyles()
  const { formState: { errors } } = useFormContext<PatientsRequirementsStepData>()
  const { fields, append, remove } = useFieldArray<PatientsRequirementsStepData, "referral.exams">({
    name: "referral.exams"
  })
  const error = get(errors, "referral.exams")

  const handleAddExam = (event, newExam: ExamListItem | null) => {
    if ( !newExam ){
      return
    }

    const isFieldsArrayNotEmpty = fields && fields.length>0
    if ( isFieldsArrayNotEmpty ){
      const foundExam = fields?.find(exam => exam.id === newExam.id)
      if ( foundExam ) {
        return
      }
    }
    append(newExam)
  }

  return (
    <Box
      mb={2}
    >
      <ExamsSelect
        value={fields}
        onChange={(event, newExam) => handleAddExam(event,newExam)}
        label={t("medicalQuestionnaire:referralSearchInputLabel")}
        isErrorOccurred={error?.type === "min"}
        errorMessage={error?.message}
      />
      {
        fields?.map((field, index) => (
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            mb={2}
            key={field.id}
          >
            <Typography
              variant='h6'
              color='textSecondary'
            >
              {index + 1}. {field.name}
            </Typography>
            <IconButton
              onClick={() => remove(index)}
            >
              <ImageByIntegrationType
                imageSrc={<TrashIcon className={classes.icon}/>}
                className={classes.icon}
                imagePath={"trash.svg"}
                returnSvg={true}
              />
            </IconButton>
          </Box>
        ))
      }
    </Box>
  )
}

export default ExamFields
