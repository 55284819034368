import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useRecommentationsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.grey[100]}`,
    },
    generalInformations: {
      whiteSpace: "pre-line"
    }
  })
)
