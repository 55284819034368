import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useButtonsSectionStyles = makeStyles((theme: Theme) => createStyles({
  outlined: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.common.white,
    },
    "&:disabled": {
      color: theme.palette.info.light,
      borderColor: theme.palette.info.light,
    },
  },
  actionButton: {
    minWidth: 105,
    marginLeft: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
    },
  },
  actionButtonPrescription: {
    "&&": {
      minWidth: 183,
    }
  },
  goToConsultationAdditionalBtn: {
    [theme.breakpoints.up("md")]: {
      position: "relative",
      right: "15px",
    },
  }
}))
