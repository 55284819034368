export enum YesNoAnswer {
  YES = "Tak",
  NO = "Nie",
}

export type SurveyExtraQuestions = {
  type: "radio" | "info" | "checkboxGroup" | "textarea";
  name: string;
  parentName?: string; // if nested question depends of parent answer it helps to match choice with correct nested question
  title: string;
  answers: {
    value: string|number;
    label: string;
  }[] | null;
  correctAnswer: string | null; // correct answer is required to determine what choice should open accordion - if null, accordion will open with any choice
  nested: boolean; // determine if question have another nested items
  nestedItems: SurveyExtraQuestions[] | null; // same structure, if null - question will not return any of nested items and shouldn't expand
}