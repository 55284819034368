import React, {FC, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation } from "react-i18next"
import {NavLink} from "react-router-dom"
import {Box, Button, Grid, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe} from "@stripe/react-stripe-js"
import {push} from "connected-react-router"

import api from "../../../api/api"
import {
  confirmConsultationStripePayment,
  createConsultationStripePayment,
} from "../../../api/routes"
import stripe from "../../../assets/images/stripe.png"
import store from "../../../store"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import ButtonLoader from "../../common/buttonLoader/ButtonLoader.component"
import TextFieldController from "../../commonFormItems/textFieldController/TextFieldController.component"
import PaymentError from "./PaymentError.component"
import {stripeInputStyles} from "./CardDetailsForm.utils"
import {RoutePath} from "../../../routes/Routes.types"
import {CreateConsultationPaymentDetails, PaymentCardDetailsForm, PaymentMethod} from "./CardDetailsForm.types"
import {useConsultationPaymentPageStyles} from "../../../pages/consultationPayment/ConsultationPaymentPage.styles"

interface CardDetailsFormProps {
  cost: string|number;
  currency: string;
  consultationId: string;
}

const CardDetailsForm: FC<CardDetailsFormProps> = ({
  cost,
  currency,
  consultationId
}) => {
  const {t, i18n} = useTranslation()
  const theme = useTheme()
  const form = useForm<PaymentCardDetailsForm>({
    defaultValues: {
      nameOnCard: "",
    },
    mode: "all",
  })
  const [isLoading, setIsLoading] = useState(false)
  const [showFormErrors, setShowFormErrors] = useState(false)
  const [showPaymentErrorDialog, setShowPaymentErrorDialog] = useState(false)

  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))
  const classes = useConsultationPaymentPageStyles({showFormErrors})
  const finalPrice = `${Number(cost).toFixed(2)} ${currency}`
  const paymentSuccessfulUrl = `/${i18n.language}${RoutePath.CONSULTATION_PAYMENT_SUCCESSFULL.replace(":consultationId?", "")}${consultationId}`
  const stripePackage = useStripe()
  const elements = useElements()

  const isInputValid = (inputId: string) => {
    const input = document.getElementById(inputId)

    return (!input?.getElementsByClassName("StripeElement--empty")[0] && !input?.getElementsByClassName("StripeElement--invalid")[0])
      ? true
      : false
  }

  const setPaymentError = () => {
    setShowPaymentErrorDialog(true)
    setIsLoading(false)
  }

  const handleCardAction = (paymentIntentClientSecret: string, paymentId: string) => { // handle 3D-Secure payment
    stripePackage?.handleCardAction(
      paymentIntentClientSecret
    ).then(result => {
      if (result?.error) {
        setPaymentError()
      } else {
        api.request({
          ...confirmConsultationStripePayment(paymentId)
        }).then(result => {
          if (result?.status) {
            goToSuccessfulPaymentPage()
          } else {
            setShowPaymentErrorDialog(true)
          }

          setIsLoading(false)
        }).catch(() => { // card expired/locked/incorrect data
          setPaymentError()
        })
      }
    })
  }

  const createConsultationPayment = (paymentDetails: CreateConsultationPaymentDetails) => { // try to pay for consultation (success/error/3D-Secure required)
    api.request({
      ...createConsultationStripePayment,
      data: paymentDetails
    }).then(response => {
      const {data: {status, requires_action, payment_id, payment_intent_client_secret}} = response

      if(status) {
        goToSuccessfulPaymentPage()
      } else if (requires_action) { // 3D-Secure required - opens popup to complete payment
        handleCardAction(payment_intent_client_secret, payment_id)
      } else {
        setPaymentError()
      }
    }).catch(() => { // card expired/locked/incorrect data
      setPaymentError()
    })
  }

  const setErrors = (nameOnCard: string) => {
    if (!nameOnCard) {
      form.setError("nameOnCard", {
        type: "manual",
        message: t("errors:required")
      },
      {
        shouldFocus: true
      })
    }
    setShowFormErrors(true)
  }

  const goToSuccessfulPaymentPage = () => store.dispatch(push(paymentSuccessfulUrl))

  const handleSubmit = form.handleSubmit(async (values) => {
    const isValidPaymentDetails = isInputValid("card-number") && isInputValid("card-expiry") && isInputValid("card-cvc")
    const cardElement = elements?.getElement("cardNumber")
    const isFormValid =  values.nameOnCard && isValidPaymentDetails

    setShowFormErrors(false)

    if (isFormValid && cardElement) { // form is valid
      setIsLoading(true)
      stripePackage?.createPaymentMethod({
        type: PaymentMethod.CARD,
        card: cardElement,
        billing_details: {
          name: values.nameOnCard
        }
      }).then((data) => {
        createConsultationPayment({
          payment_method_id: data?.paymentMethod?.id,
          consultation_id: consultationId,
          currency
        })
      })
    } else { // form invalid
      setErrors(values.nameOnCard)
    }
  })

  return (
    <>
      <Typography variant="h4">
        {finalPrice}
      </Typography>
      <FormProvider {...form} >
        <form onSubmit={handleSubmit} id="stripe-payment-form">
          <Grid container spacing={1}>
            <Box
              display="flex"
              mt={2}
            >
              <Box ml={.5}>
                {t("payments:paymentMethod")}
              </Box>
              <ImageByIntegrationType
                imageSrc={stripe}
                alt="stripe"
                className={classes.paymentProvider}
                imagePath={"stripe.png"}
              />
            </Box>
            <Grid item xs={12}>
              <TextFieldController
                name="nameOnCard"
                label={t("payments:card:nameOnCard")}
                className={classes.standardInput}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: null,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                id="card-number"
                position="relative"
              >
                <CardNumberElement
                  className={classes.cardFormInput}
                  options={stripeInputStyles}
                />
                <Box className={classes.label}>
                  {t("payments:card:cardNumber")}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box
                id="card-expiry"
                position="relative"
              >
                <CardExpiryElement
                  className={classes.cardFormInput}
                  options={stripeInputStyles}
                />
                <Box className={classes.label}>
                  {t("payments:card:expiryDate")}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box
                id="card-cvc"
                position="relative"
              >
                <CardCvcElement
                  className={classes.cardFormInput}
                  options={stripeInputStyles}
                />
                <Box className={classes.label}>
                  {t("payments:card:securityCode")}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            display="flex"
            flexDirection={isLgUp ? "row" : "column-reverse"}
            justifyContent="flex-end"
            mt={4}
          >
            <Box
              mt={isLgUp ? 0 : 3}
              mr={isLgUp ? 2 : 0}
            >
              <Button
                variant="outlined"
                color="secondary"
                component={NavLink}
                to={`/${i18n.language}`}
                className={classes.button}
                disabled={isLoading}
              >
                {t("backToHomePage")}
              </Button>
            </Box>
            <Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={isLoading && (<ButtonLoader position="prefix"/>)}
                disabled={isLoading}
              >
                {t("consultation:pay")} {finalPrice}
              </Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
      <PaymentError
        showPaymentErrorDialog={showPaymentErrorDialog}
        setShowPaymentErrorDialog={setShowPaymentErrorDialog}
      />
    </>
  )
}

export default CardDetailsForm
