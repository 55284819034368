import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useItemCounterStyles = makeStyles((theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "row"
  },
  valueContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    width: 15,
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0)
    },
  },
  disabledIcon: {
    "& path": {
      stroke: theme.palette.grey[400]
    }
  },
  icon: {
    "& path": {
      stroke: theme.palette.primary.main,
    },
  }
}))
