import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, TextField} from "@material-ui/core"
import {useDebounce} from "use-debounce/esm"

import {alabOrderHelplinePhoneNumber} from "../../../app.config"
import store from "../../../store"
import {useGetExamPointsQuery} from "../../../store/medicalExamsPoints/medicalExamsPoints.api"
import InfoLabel from "../../infoLabel/InfoLabel.component"
import AlabList from "./alabList/AlabList.component"
import {usePostalCodeManager} from "./AlabSearchEngine.utils"
import {useAlabSearchEngineStyles} from "./AlabSearchEngine.styles"

const AlabSearchEngine: FC = () => {
  const classes = useAlabSearchEngineStyles()
  const {t} = useTranslation()
  const {postalCode, handleChange} = usePostalCodeManager()
  const [debouncePostalCode] = useDebounce(postalCode, 1000)
  const { isError } = useGetExamPointsQuery(debouncePostalCode)
  const contactPhoneNumber = store.getState().clinic?.clinicSettings?.frontendSettings?.contactPhoneNumber

  return (
    <Box className={classes.root}>
      <Box className={classes.listWrapper}>
        <TextField
          fullWidth
          label={t("user:postalCode")}
          value={postalCode}
          onChange={handleChange}
          error={isError}
          helperText={isError ? t("referrals:invalidPostalCode") : ""}
        />
        <AlabList
          postalCode={debouncePostalCode}
        />
      </Box>
      <InfoLabel>
        {t("referrals:helpline")} {contactPhoneNumber ?? alabOrderHelplinePhoneNumber}
      </InfoLabel>
    </Box>
  )
}

export default AlabSearchEngine
