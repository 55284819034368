import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useSickLeaveSectionStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(2),
    },
  },
  gridItem: {
    width: "100%",
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  }
}))
