import React, { FC, ReactNode } from "react"
import { Box, Hidden, Paper, Typography, useMediaQuery } from "@material-ui/core"

import DoctorImage from "../../../../../../assets/images/doctor-empty-state.png"
import ImageByIntegrationType from "../../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { usePreventionActionWrapperStyles } from "./PreventionActionWrapper.styles"
import { Theme } from "@material-ui/core/styles"

type PreventionActionWrapperProps = {
  title?: string;
  description?: string;
  isImageHidden?: boolean;
  overrideImage?: ReactNode|null;
}

const PreventionActionWrapper: FC<PreventionActionWrapperProps> = ({ 
  children,
  title,
  description,
  isImageHidden = false,
  overrideImage
}) => {
  const classes = usePreventionActionWrapperStyles()
  const isXs = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"))
  const image = overrideImage ?? (
    <ImageByIntegrationType
      imageSrc={DoctorImage}
      alt="DoctorImage"
      className={classes.image}
      imagePath={"doctor-empty-state.png"}
    />
  )

  return (
    <Box
      component={Paper}
      className={classes.container}
    >
      <Box className={classes.contentWrapper}>
        <Box
          display="flex"
          flexDirection="column"
        >
          <Typography
            className={classes.typography}
            variant={isXs ? "h3" : "h2"}
          >
            {title}
          </Typography>
          {
            !isImageHidden && (
              <Hidden mdUp>
                {image}
              </Hidden>
            )
          }
          {
            description && (
              <Typography
                className={classes.typography}
                variant="body1"
              >
                {description}
              </Typography>
            )
          }
        </Box>
        {children}
      </Box>
      {
        !isImageHidden && (
          <Hidden smDown>
            {image}
          </Hidden>
        )
      }
    </Box>
  )
}

export default PreventionActionWrapper
