import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useUserNfzConsultationsStyles = makeStyles((theme: Theme) => createStyles({
  descriptionWrapper: {
    display: "flex",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  description: {
    marginLeft: theme.spacing(1),
  },
  linkWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
}))
