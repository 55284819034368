import {gql} from "@apollo/client"

export const GET_SIGN_DECLARATION_ACTION = gql`
    query SignDeclarationAction {
        usersActions: users_actions(where: {action: {type: {_eq: "sign_declaration"}}}) {
            id
            completedAt: completed_at
            action {
                name
                points
            }
        }
    }
`
