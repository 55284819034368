import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {useTheme} from "@material-ui/core"

import {useAppDispatch} from "../../hooks/storeHooks"
import {useRequestHandler} from "../../hooks/useRequestHandler"
import {usePutChangePasswordMutation} from "../../store/patients/patients.api"
import {setForcePasswordChange} from "../../store/user/user.slice"
import AppDialog from "../common/appDialog/AppDialog.component"
import ResetPasswordForm from "./resetPasswordForm/ResetPasswordForm.component"
import {useForceResetPasswordModalStyles} from "./ForceResetPasswordModal.styles"

const ForceResetPasswordModal: FC = () => {
  const {t} = useTranslation()
  const classes = useForceResetPasswordModalStyles()
  const theme = useTheme()
  const [handlePutChangePassword, { isLoading, error }] = usePutChangePasswordMutation()
  const dispatch = useAppDispatch()
  const handleSubmit = useRequestHandler(
    handlePutChangePassword,
    {
      successCallback: () => {
        dispatch(setForcePasswordChange(false))
      }
    }
  )

  return (
    <AppDialog
      open={true}
      closeIcon={false}
      closeButton={false}
      title={t("user:forcePasswordChangeModalTitle")}
      className={classes.modal}
      style={{ zIndex: theme.zIndex.forceDisplayModal }}
    >
      <ResetPasswordForm
        onSubmit={handleSubmit}
        isLoading={isLoading}
        error={error}
      />
    </AppDialog>
  )
}

export default ForceResetPasswordModal
