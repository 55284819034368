import React, {FC, useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"
import {useDispatch} from "react-redux"
import {useHistory} from "react-router"
import i18next from "i18next"

import {
  forgingPediatricsSpecializationId,
  internistSpecializationId,
  isStagingApiEnv,
  pediatristSpecializationId,
} from "../../app.config"
import {useAppSelector} from "../../hooks/storeHooks"
import useIsB2BClinic from "../../hooks/useIsB2BClinic"
import { tracker } from "../../hooks/useOpenReplayTracker"
import {useQueryParam} from "../../hooks/useSearchParams"
import {selectClinicSettings} from "../../store/clinic/clinicSettings.selectors"
import {selectMakeConsultationWidgetConfig} from "../../store/makeConsultationWidget/makeConsultationWidget.selectors"
import {
  selectHasUserCompletePersonalData,
  selectUser,
  // selectUserPozDeclarationActiveStatus
} from "../../store/user/user.selectors"
import { selectVouchers, selectVouchersLoading } from "../../store/vouchers/vouchers.selectors"
import { getVouchers } from "../../store/vouchers/vouchers.slice"
import AppBox from "../../components/common/appBox/AppBox.component"
import {useAppBoxStyle} from "../../components/common/appBox/AppBox.styles"
import AppDialog from "../../components/common/appDialog/AppDialog.component"
import {useAppDialogStyles} from "../../components/common/appDialog/AppDialog.styles"
import ConsultationsLoader from "../../components/common/lottieAnimations/animations/ConsultationsLoader.component"
import FillUserDataInformationPanel
  from "../../components/informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import {checkFeatureAvailability} from "../../store/clinic/clinicSettings.utils"
import { isAdult } from "../../utils/isAdult"
import { mapDefaultChannelType, setDetailedSource } from "./MakeConsultationPage.utils"
import {RoutePath} from "../../routes/Routes.types"
import {ConsultationWidgetVisitDatePicker} from "../../store/makeConsultationWidget/makeConsultationWidget.types"

interface MakeConsultationPageProps {}

const ACTIVE_DECLARATION_PARAM = "showActiveDeclarationPopup"
const MEISINGER_CLINIC_SPECIALIZATION_ID = "835ec74f-b170-4412-888c-d689fc42952d"

const MakeConsultationPage: FC<MakeConsultationPageProps> = () => {
  const {push} = useHistory()
  const {t} = useTranslation()
  const defaultDoctorId = useQueryParam("doctorId")
  const defaultChannel = useQueryParam("consultationChannel")
  const showActiveDeclarationPopup = useQueryParam(ACTIVE_DECLARATION_PARAM)
  const widgetConfig = useAppSelector(selectMakeConsultationWidgetConfig)
  const [isLoadedScript, setIsLoadedScript] = useState<boolean>(false)
  const [activeDeclarationPopup, setActiveDeclarationPopup] = useState<boolean>(!!showActiveDeclarationPopup)
  // const isPozDeclarationActive = useSelector(selectUserPozDeclarationActiveStatus)
  const telemedicoWidgetWrapperRef = useRef<HTMLDivElement>(null)
  const clinicSettings = useSelector(selectClinicSettings)
  const clinicId = clinicSettings?.frontendSettings?.clinicId
  const isMeisingerClinic = clinicId === "422bd1c5-63ae-428c-bb1f-ceaa948efb4a" // temporary solution
  const specializationId = isMeisingerClinic ? MEISINGER_CLINIC_SPECIALIZATION_ID : useQueryParam("specializationId")
  const widgetViewLoaded = isLoadedScript && telemedicoWidgetWrapperRef?.current?.innerHTML !== ""
  const dispatch = useDispatch()
  const vouchers = useAppSelector(selectVouchers)
  const vouchersLoading = useAppSelector(selectVouchersLoading)
  const {B2BClinic} = useIsB2BClinic()
  const classes = useAppBoxStyle()
  const declarationPopupClasses = useAppDialogStyles()
  const user = useAppSelector(selectUser)
  const clinicForeign = clinicSettings.clinicForeign
  const defaultSpecialization = isAdult(user?.birth_date) ?? internistSpecializationId
    ? internistSpecializationId
    : clinicForeign ? forgingPediatricsSpecializationId : pediatristSpecializationId
  const params = new URLSearchParams(window.location.search)
  const paramsObject = Object.fromEntries(params)
  const isPrescriptionConsultation = window.location.href.includes(RoutePath.MAKE_PRESCRIPTION_CONSULTATION)
  const hasUserCompletePersonalData = useSelector(selectHasUserCompletePersonalData)
  const hideConsultationBooking = checkFeatureAvailability().hideConsultationBooking

  if (hideConsultationBooking) {
    push(`/${i18next.language}`)

    return null
  }

  if (!clinicSettings.hasBookConsultationAbility && !paramsObject?.["prescription-consultation-id"] && !isPrescriptionConsultation) {
    push(`/${i18next.language}/404`)
  }

  const clearWidgetData = () => {
    window.Telemedico = null
    setIsLoadedScript(false)
    if (telemedicoWidgetWrapperRef.current) {
      telemedicoWidgetWrapperRef.current.innerHTML = ""
    }
  }

  useEffect(() => {
    dispatch(getVouchers())
  }, [])

  /* load widget scripts on componentDidMount hook */
  /* (due to requirement of mounted target element ID in config) */
  useEffect(() => {
    if (isLoadedScript) {
      clearWidgetData()
    }

    if (!widgetConfig.language || vouchersLoading) {
      return // prevent load widget with undefined lang config or no patient's vouchers data - wait for language detect or vouchers data
    }

    if (!hasUserCompletePersonalData) {
      return // prevent load widget if user has not complete personal data
    }

    const telemedicoWidgetElement = document.createElement("div")
    telemedicoWidgetElement.setAttribute("id", "telemedico-widget")
    telemedicoWidgetWrapperRef.current?.appendChild(telemedicoWidgetElement)
    // remove (in clear function) and append telemedicoWidgetElement
    // to fix dynamic widget reconfiguration on make-consultation-page

    const script = document.createElement("script")

    script.src = isStagingApiEnv
      ? "../scripts/make-consultation-widget-stage.js"
      : "../scripts/make-consultation-widget.js"
      // "../" due to lang prefix in params

    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      const freeConsultations = vouchers?.length
        ? vouchers.filter(item => item.discount === "100%" && item.is_valid && item.code !== "POZ").length > 0
        : false

      const {
        enableMedicalDocumentationReminderInfo,
        enablePaymentProviderInfo,
        enableAddVoucherInSummaryFeature,
        enableInstantChatFeature,
        enableSmallSlotsFeature,
        defaultChannelType
      } = clinicSettings.widgetConsbookSettings

      const visitDatePickerType = (paramsObject?.["prescription-consultation-id"] || isPrescriptionConsultation) ? ConsultationWidgetVisitDatePicker.PRESCRIPTION_ONLY : widgetConfig.visitDatePicker

      Telemedico?.({
        ...widgetConfig,
        specializationID: specializationId ?? defaultSpecialization,
        shouldShowMedicalDocumentationReminder: enableMedicalDocumentationReminderInfo,
        shouldHidePaymentProviderInfo: !enablePaymentProviderInfo || freeConsultations,
        shouldHideAddVoucherFeature: !enableAddVoucherInSummaryFeature,
        clinicId,
        apiHost: `https://${clinicSettings?.domain}`,
        showPaidVisitsOnly: B2BClinic,
        showPrices: clinicSettings?.showPrices,
        instantChatEnabled: B2BClinic && enableInstantChatFeature,
        smallSlotsEnabled: B2BClinic && enableSmallSlotsFeature,
        sendCookiesToV4: true,
        defaultChannel: mapDefaultChannelType(defaultChannel ? parseInt(defaultChannel) : defaultChannelType),
        medicalDrugLimit: clinicSettings?.prescriptionsSettings?.medicalDrugLimit,
        medicalDrugsTotalLimit: clinicSettings?.prescriptionsSettings?.medicalDrugsTotalLimit,
        enableCheckMedicineExposed: clinicSettings?.prescriptionsSettings?.enableCheckMedicineExposed,
        pozGlobalMedicalDrugLimit: clinicSettings?.prescriptionsSettings?.pozGlobalMedicalDrugLimit,
        pozGlobalMedicalDrugsTotalLimit: clinicSettings?.prescriptionsSettings?.pozGlobalMedicalDrugsTotalLimit,
        otherMessagesForB2bClinicEnabled: clinicSettings?.prescriptionsSettings?.otherMessagesForB2bClinicEnabled,
        visitDatePicker: visitDatePickerType,
        signalIdunaVerificationEnabled: clinicSettings?.clinicSignalIdunaSettings?.insuranceVerificationEnabled,
        enabledS7HealthIntegration: clinicSettings?.frontendSettings?.modules.includes("s7health") ?? false,
        enabledAllianzEwkaIntegration: clinicSettings.id ? clinicSettings?.clinicAllianzSettings?.ewkaVerificationEnabled : false,
        enabledAllianzOnyxIntegration: clinicSettings.id ? clinicSettings?.clinicAllianzSettings?.onyxVerificationEnabled : false,
        defaultDoctorId: defaultDoctorId ?? "",
        telemediSubscriptionEnabled: checkFeatureAvailability().enabledTelemediGo,
        enabledTelemediGoInEprescription: checkFeatureAvailability().enabledTelemediGoInEprescription,
        enabledTelemediGoInSickLeave: checkFeatureAvailability().enabledTelemediGoInSickLeave,
        enabledTelemediGoForInternist: checkFeatureAvailability().enabledTelemediGoForInternist,
        prescriptionForPozGlobal: clinicSettings?.prescriptionsSettings?.modules.filter((item => item === "5")).length > 0,
        // temporary solution: https://telemedico.atlassian.net/browse/LESS-3943
        // checkConsultationReasonAvailability: !B2BClinic && isPozDeclarationActive ? true : false,
        // TODO: enabled for prod experiment, later implement real checkbox in clinic settings if needed
        checkConsultationReasonAvailability: false,
        //checkConsultationReasonAvailability: !clinicSettings?.frontendSettings.vPozMedicalSurveyEnabled && widgetConfig.visitDatePicker !== ConsultationWidgetVisitDatePicker.STATIONARY_VISIT_DATA_PICKER,

        // e/o temporary solution: https://telemedico.atlassian.net/browse/LESS-3943
        pushMethod: push,
        detailedSource: setDetailedSource(visitDatePickerType) ?? widgetConfig.detailedSource,
        openReplayTracker: tracker,
      })

      setIsLoadedScript(true)
    }

    return () => {
      document.body.removeChild(script)
      clearWidgetData()
    }
  }, [widgetConfig, vouchersLoading])
  /* e/o load widget scripts */

  if (!hasUserCompletePersonalData) {
    return <FillUserDataInformationPanel redirectRoute={`/${i18next.language}${RoutePath.MAKE_CONSULTATION}`} />
  }

  return (
    <AppBox
      className={widgetViewLoaded ? classes.root : ""}
    >
      {!widgetViewLoaded && <ConsultationsLoader/>}
      <div ref={telemedicoWidgetWrapperRef}/>
      <AppDialog
        open={activeDeclarationPopup && widgetViewLoaded}
        title={t("user:activeDeclarationHeader")}
        onClose={() => setActiveDeclarationPopup(false)}
        closeButtonText={t("close")}
        classes={{
          paper: declarationPopupClasses.declarationPopup
        }}
      >
        {t("user:activeDeclarationMessage")}
      </AppDialog>
    </AppBox>
  )
}

export default MakeConsultationPage
