export type ClinicListItem = {
  id: string; // "de16fc73-a7a8-4a9c-8e34-8f58f583b192"
  name: string; // "Telemedico"
  domain: string; // "telemedi.co"
  url: string; // "https://telemedi.co"
  pozfrom_active: boolean;
}

export enum ClinicType {
  B2B = "B2B"
}

export type ClinicListOptions = {
  label: string;
  value: string;
}
