import React, { FC } from "react"
import {useTranslation} from "react-i18next"

import { useAppSelector } from "../../../../hooks/storeHooks"
import { useToggle } from "../../../../hooks/useToggle"
import { selectLoading } from "../../../../store/user/user.selectors"
import UserDataListItem from "../../common/UserDataListItem"
import UserDataInfoSection from "../../common/UserDataInfoSection.component"
import UserParentPersonalDataEditModal from "./UserParentPersonalDataEditModal/UserParentPersonalDataEditModal.component"
import { getUserDocumentData } from "../userPersonalData/UserPersonalData.utils"
import {Gender, UserDataType} from "../../../../store/user/user.types"

interface UserParentPersonalDataProps {
  userParentData: UserDataType["parent"];
  patientEditAllowed: boolean;
}

const UserParentPersonalData: FC<UserParentPersonalDataProps> = ({ userParentData, patientEditAllowed }) => {
  const {t} = useTranslation()
  const loading = useAppSelector(selectLoading)
  const userHiddenPesel = userParentData?.pesel?.replace(/^\d{7}/, "*") || ""
  const userParentGender = userParentData?.gender != null ? t(`user:${Gender[userParentData.gender]}`) : ""
  const [isModalOpen, { handleOff: handleClose, handleOn: handleOpen }] = useToggle()
  const userParentDocumentData = getUserDocumentData(userParentData, userHiddenPesel)

  if (loading) {
    return null
  }

  return (
    <>
      <UserDataInfoSection
        title={t("user:parentPersonalInfo")}
        withButton={patientEditAllowed}
        onClick={handleOpen}
        buttonText={t("edit")}
      >
        <UserDataListItem
          label={t("user:name")}
          description={userParentData?.first_name || ""}
          descriptionProps={{className: "break-word"}}
        />

        <UserDataListItem
          label={t("user:surname")}
          description={userParentData?.last_name || ""}
          descriptionProps={{className: "break-word"}}
        />

        <UserDataListItem
          label={t("user:nationality")}
          description={userParentData?.nationality || ""}
          descriptionProps={{noWrap: true}}
        />
        
        <UserDataListItem
          label={userParentDocumentData.label}
          description={userParentDocumentData.value}
          descriptionProps={{noWrap: true}}
        />

        <UserDataListItem
          label={t("user:gender")}
          description={userParentGender}
          descriptionProps={{noWrap: true}}
        />

        <UserDataListItem
          label={t("user:defaultTimezone")}
          description={userParentData?.default_timezone || ""}
          descriptionProps={{noWrap: true}}
        />
      </UserDataInfoSection>

      <UserParentPersonalDataEditModal
        open={isModalOpen}
        onClose={handleClose}
        userParentData={userParentData}
        userParentDocumentData={{
          label: userParentDocumentData.label,
          value: userParentDocumentData.value,
        }}
      />
    </>
  )
}

export default UserParentPersonalData
