import React, { FC, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { DialogContentText } from "@material-ui/core"

import api from "../../../../../api/api"
import { updateUserParentPersonalDataConfig } from "../../../../../api/routes"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks"
import useGetFormValidationRules from "../../../../../hooks/useGetFormValidationRules"
import { selectUserId } from "../../../../../store/session/session.selectors"
import { getUserData } from "../../../../../store/user/user.slice"
import { userEditAddressDataSchema } from "../../userAddressData/UserAddressDataEditModal/UserAddressDataEditModal.validation"
import AppDialog from "../../../../common/appDialog/AppDialog.component"
import ButtonLoader from "../../../../common/buttonLoader/ButtonLoader.component"
import { UserParentAddressDataEditForm } from "../UserParentAddressDataEditForm/UserParentAddressDataEditForm.component"
import { handleReactHookFormErrors, redirectToError500Page } from "../../../../../utils/handleErrors"
import { formatPolandPostalCode } from "../../../../commonFormItems/postalCodeInput/PostalCodeInput.utils"
import { MainCountriesTypes, UserDataType } from "../../../../../store/user/user.types"

interface ParentAddressDataValues {
  parentStreet: string
  parentHouseNumber: string
  parentApartmentNumber: string;
  parentPostalCode: string;
  parentCity: string;
  parentCountry: string;
}

interface UserParentAddressDataEditModalProps {
  userParentData?: UserDataType["user_parent"];
  open: boolean;
  onClose(): void;
}

const UserParentAddressDataEditModal: FC<UserParentAddressDataEditModalProps> = ({
  userParentData,
  open,
  onClose
}) => {
  const {t} = useTranslation()
  const userId = useAppSelector(selectUserId)
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const form = useForm<ParentAddressDataValues>({
    defaultValues: {
      parentStreet: userParentData?.address?.street ?? "",
      parentHouseNumber: userParentData?.address?.house_number ?? "",
      parentApartmentNumber: userParentData?.address?.apartment_number ?? "",
      parentPostalCode: 
        (userParentData?.address?.country === MainCountriesTypes.PL && userParentData?.address?.postal_code)
          ? formatPolandPostalCode(userParentData?.address?.postal_code)
          : (userParentData?.address?.postal_code ?? ""),
      parentCity: userParentData?.address?.city ?? "",
      parentCountry: userParentData?.address?.country ?? userParentData?.nationality ?? "",
    },
    mode: "all",
    resolver: useGetFormValidationRules(() => userEditAddressDataSchema()),
  })

  const handleClose = () => {
    form.reset()
    onClose()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    setLoading(true)

    const parentAddressData = {
      street: userParentData?.address?.street === values.parentStreet
        ? undefined
        : (values.parentStreet || undefined),
      houseNumber: userParentData?.address?.house_number === values.parentHouseNumber
        ? undefined 
        : (values.parentHouseNumber || undefined),
      apartmentNumber: userParentData?.address?.apartment_number === values.parentApartmentNumber
        ? undefined
        : (values.parentApartmentNumber || undefined),
      postalCode: userParentData?.address?.postal_code === values.parentPostalCode
        ? undefined
        : (values.parentPostalCode || undefined),
      city: userParentData?.address?.city === values.parentCity
        ? undefined
        : (values.parentCity || undefined),
      country: userParentData?.address?.country === values.parentCountry
        ? undefined
        : (values.parentCountry || undefined)
    }

    const shouldUpdateParentData = Object.values(parentAddressData).find(val => !!val)

    try {
      if(shouldUpdateParentData) {
        await api.request({
          ...updateUserParentPersonalDataConfig, 
          data: {
            address: parentAddressData
          },
        })
        await dispatch(getUserData({id: userId}))
      }
      handleClose()
    } catch (e) {
      console.error(e)
      redirectToError500Page(e)
      if (e.response?.data?.errors) {
        handleReactHookFormErrors<ParentAddressDataValues>(e.response.data.errors, form)
      }
    }
    setLoading(false)
  })

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t("user:parentAddressInfo")}
      disabled={loading}
      actionButtonText={t("save")}
      actionButtonProps={{
        onClick: handleSubmit,
        startIcon: loading && <ButtonLoader position="prefix"/>,
      }}
    >
      <FormProvider {...form} >
        <DialogContentText>
          {t("user:editParentAddress")}
        </DialogContentText>
        <UserParentAddressDataEditForm loading={loading} />
      </FormProvider>
    </AppDialog>
  )
}

export default UserParentAddressDataEditModal