import {useTranslation} from "react-i18next"
import {useHistory, useParams} from "react-router"
import {generatePath} from "react-router-dom"

import {useCompleteActionMutation} from "../../../api/mutations/completeAction/completeAction.hook"
import {RoutePath} from "../../../../../routes/Routes.types"

export const useHandleRedirect = (redirectUrl: string) => {
  const { push } = useHistory()
  const { i18n } = useTranslation()

  return () => push(`/${i18n.language}${redirectUrl}`)

}

export const useFinishAction = (redirectUrl: string) => {
  const { actionId } = useParams<{ actionId: string; }>()
  const handleRedirect = useHandleRedirect(redirectUrl)
  const [handleCompleteAction, { loading }] = useCompleteActionMutation()

  const handleFinish = () => {
    handleCompleteAction({
      variables: {
        actionId
      }
    })
      .then(() => {
        handleRedirect()
      })
  }

  return {
    isLoading: loading,
    handleFinish
  }

}

export const useRedirectToSummaryActionView = () => {
  const { actionId } = useParams<{ actionId: string; }>()

  return useFinishAction(
    generatePath(RoutePath.PREVENTION_ACTION_SUMMARY, { actionId })
  )
}
