import { push } from "connected-react-router"
import i18n from "i18next"

import {postPasswordlessLogin, postPasswordlessLoginVerify} from "../../api/routes"
import {PASSWORDLESS_IS_VERIFY_KEY} from "../../app.config"
import {HttpStatusCode} from "../../enums/HttpStatusCode"
import {SearchParams} from "../../enums/SearchParams"
import {SsoErrorCode} from "../../enums/SsoErrorCode"
import {initSession} from "../session/session.slice"
import {ssoService} from "../store.service"
import {isBaseQueryError} from "../store.utils"
import {RoutePath} from "../../routes/Routes.types"
import {
  PasswordlessLoginPayload, PasswordlessLoginVerifyPayload,
  SuccessPasswordlessLoginResponse
} from "./passwordless.types"

const passwordlessApi = ssoService.injectEndpoints({
  endpoints: build => ({
    postPasswordlessLogin: build.mutation<SuccessPasswordlessLoginResponse, PasswordlessLoginPayload>({
      query: ({ code, magicLinkId }) => ({
        ...postPasswordlessLogin,
        data: {
          code,
          magicLinkId
        }
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled

          if (response.data.shouldVerifyBirthDate) {
            sessionStorage.setItem(PASSWORDLESS_IS_VERIFY_KEY, "1")
            dispatch(push(`/${i18n.language}${RoutePath.PASSWORDLESS_BIRTHDAY_VERIFY}`))
            return
          }

          const { accessToken, refreshToken, consultationId } = response.data

          await dispatch(initSession({
            token: accessToken,
            refresh_token: refreshToken
          }))

          if (consultationId) {
            dispatch(push(`/${i18n.language}/consultation/${consultationId}`))
          }
        } catch (error) {
          if (isBaseQueryError(error.error)) {
            if (error.error.data?.code === SsoErrorCode.PASSWORDLESS_INVALID_MAGIC_LINK_ID) {
              dispatch(push(`/${i18n.language}/login?${SearchParams.SSO_ERROR_CODE}=${error.error.data?.code}`))
            }
            if (error.error.data?.status === HttpStatusCode.GONE) {
              dispatch(push(`/${i18n.language}/login?${SearchParams.SSO_ERROR_CODE}=${SsoErrorCode.PASSWORDLESS_INVALID_MAGIC_LINK_ID}`))
            }
          }
        }
      },
    }),
    postPasswordlessLoginVerify: build.mutation<SuccessPasswordlessLoginResponse, PasswordlessLoginVerifyPayload>({
      query: ({ birthDate }) => ({
        ...postPasswordlessLoginVerify,
        data: {
          birthDate
        }
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled
          const { accessToken, refreshToken } = response.data

          await dispatch(initSession({
            token: accessToken,
            refresh_token: refreshToken
          }))
        } catch (error) {
          if (
            isBaseQueryError(error.error) &&
            (
              error.error.data?.code === SsoErrorCode.PASSWORDLESS_LOGIN_MISSING_VERIFY_COOKIE
              || error.error.data?.code === SsoErrorCode.PASSWORDLESS_LOGIN_VERIFY_SESSION_EXPIRED
            )
          ) {
            dispatch(push(`/${i18n.language}/login?${SearchParams.SSO_ERROR_CODE}=${error.error.data?.code}`))
          }
        }
      },
    })
  })
})

export const { usePostPasswordlessLoginMutation, usePostPasswordlessLoginVerifyMutation } = passwordlessApi
