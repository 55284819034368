import {useTranslation} from "react-i18next"

import { useFillUserParentDataForm } from "../components/user/userProfile/UserProfile.styles"

//todo: usunąć logikę po podbicu materiala do wersji 5 https://github.com/telemedico/patient-dashboard/pull/783
export const useEsInputPlaceholderClassName = (isFillDataView?: boolean): string => {
  const classes = useFillUserParentDataForm({ isFillDataView })
  const {i18n} = useTranslation()

  return i18n.language === "es" ? classes.esInputPlaceholder : ""
}