import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useCommonStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexDirection: "column",
    alignItems: "baseline",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  hospitalImage: {
    padding: "10px",
  },
  buttonCalendar: {
    justifyContent: "flex-start",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: "auto",
    },
  },
  langImg: {
    marginRight: theme.spacing(1),
    marginLeft: "-2px"
  },
  detailsSectionContent: {
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: 13
    }
  },
  detailsSectionContainer: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      maxWidth: "45%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "60%",
    },
  },
  doctorName: {
    [theme.breakpoints.up("md")]: {
      whiteSpace: "nowrap"
    },
  },
  controlVisitMobileSection: {
    display: "flex",
    "div MuiButtonBase-root &": {
      justifyContent: "flex-start",
      textAlign: "left"
    }
  },
  stationaryVisitAddress: {
    fontWeight: "bold",
  },
  stationaryVisitAddressLabel: {
    marginLeft: "0px"
  }
}))
