import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"

import {useHealthPlannerInfoModalStyles} from "./HealthPlannerInfoModal.styles"

interface HealthPlannerInfoModalProps {
  isOpen: boolean;
  onClose(): void;
}

const HealthPlannerInfoModal: FC<HealthPlannerInfoModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation()
  const {
    popup,
    list,
    closeButton
  } = useHealthPlannerInfoModalStyles()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Dialog
      open={isOpen}
      maxWidth={"md"}
      classes={{
        paper: popup
      }}
      onClose={onClose}
      fullScreen={!isSmUp}
    >
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <Typography
            variant="body2"
          >
            {t("healthPlanner:healthPlannerDescriptionPart1")}
          </Typography>
          <List className={list}>
            <ListItem>
              <ListItemText>{t("healthPlanner:healthPlannerSteps:firstStep")}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>{t("healthPlanner:healthPlannerSteps:secondStep")}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>{t("healthPlanner:healthPlannerSteps:thirdStep")}</ListItemText>
            </ListItem>
          </List>
          <Typography
            variant="body2"
          >
            {t("healthPlanner:healthPlannerDescriptionPart2")}
          </Typography>
          <Button
            color="secondary"
            variant="outlined"
            className={closeButton}
            onClick={onClose}
          >
            {t("healthPlanner:close")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default HealthPlannerInfoModal
