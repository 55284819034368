import {hasOwnProperty} from "../../utils/hasOwnProperty"
import {isObject} from "../../utils/isObject"
import {DozErrorResponse, StatusCode} from "./doz.types"

export const isSuccessResponse = <SuccessResponse extends { code: StatusCode }>
(data: unknown): data is SuccessResponse => {
  if (isObject(data) && hasOwnProperty(data, "code")) {
    return data.code === 200
  }
  return false
}

export const isDozErrorResponse = (error: unknown): error is DozErrorResponse => {
  return isObject(error) && hasOwnProperty(error, "code")
}