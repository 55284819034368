import {createStyles, makeStyles} from "@material-ui/core"

export const useExtraQuestionsStyles = makeStyles((theme) => createStyles({
  textarea: {
    minHeight: 230,
    maxHeight: 230,
    borderRadius: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    ...theme.typography.body1,
  },
  infoMessage: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  }
}))
