import React, { ChangeEvent, FC, useState } from "react"
import { Controller, FieldError, FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {useSelector} from "react-redux"
import {DialogContentText, Grid, InputAdornment, TextField} from "@material-ui/core"

import api from "../../../../api/api"
import { patchUserPersonalDataConfig } from "../../../../api/routes"
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks"
import useGetFormValidationRules from "../../../../hooks/useGetFormValidationRules"
import {selectClinicSettings} from "../../../../store/clinic/clinicSettings.selectors"
import { selectUserId } from "../../../../store/session/session.selectors"
import {selectUserPozDeclarationActiveStatus} from "../../../../store/user/user.selectors"
import { getUserData } from "../../../../store/user/user.slice"
import AppDialog from "../../../common/appDialog/AppDialog.component"
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader.component"
import DisabledFormFieldTooltip
  from "../../../commonFormItems/disabledFormFieldTooltip/DisabledFormFieldTooltip.component"
import PhoneCountryCodeSelect from "../../../commonFormItems/phoneCountryCodeSelect/PhoneCountryCodeSelect.component"
import TextFieldController from "../../../commonFormItems/textFieldController/TextFieldController.component"
import { handleReactHookFormErrors, redirectToError500Page } from "../../../../utils/handleErrors"
import { UserDataType } from "../../../../store/user/user.types"
import { PhoneNumber,UserContactDataFormValues } from "./UserContactData.types"
import { useUserContactDataEditModalStyles } from "./UserContactDataEditModal.styles"

import { userContactDataValidationSchema } from "./UserContactData.validation"

type PhoneFieldError = {
  [name in keyof PhoneNumber]: FieldError
}

interface UserContactDataEditModalProps {
  user: UserDataType;
  open: boolean;
  onClose(): void;
  benefitSystemIntegrationEnabled?: boolean;
}

const UserContactDataEditModal: FC<UserContactDataEditModalProps> = ({user, open, onClose, benefitSystemIntegrationEnabled}) => {
  const {t} = useTranslation()
  const userId = useAppSelector(selectUserId)
  const dispatch = useAppDispatch()
  const isPozDeclarationActive = useSelector(selectUserPozDeclarationActiveStatus)
  const clinicSettings = useAppSelector(selectClinicSettings)
  const showPhoneNumberOnCompleteDataView = clinicSettings.frontendSettings.showPhoneNumberOnCompleteDataView

  const phoneSelectFieldProps = {
    disabled: isPozDeclarationActive,
    endAdornment: isPozDeclarationActive
      ? (
        <InputAdornment position="end">
          <DisabledFormFieldTooltip/>
        </InputAdornment>
      ) : undefined,
  }

  const classes = useUserContactDataEditModalStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UserContactDataFormValues>({
    defaultValues: {
      email: user.email || "",
      phoneNumber: {
        country: user.phone_country,
        number: user.phone_national_number || "",
      },
    },
    resolver: useGetFormValidationRules(userContactDataValidationSchema),
    mode: "all",
  })

  const handleClose = () => {
    form.reset()
    onClose()
  }

  const handleChange = (
    onChange: (value: PhoneNumber) => void,
    value: PhoneNumber,
    field: keyof PhoneNumber,
  ) => (event: ChangeEvent<HTMLInputElement>) => {
    onChange({...value, [field]: event.target.value})
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    setLoading(true)
    try {
      await api.request({
        ...patchUserPersonalDataConfig(userId), data: {
          email: benefitSystemIntegrationEnabled ? undefined : values.email,
          phoneNumber: {
            country: values.phoneNumber.country,
            number: values.phoneNumber.number,
          },
        },
      })
      await dispatch(getUserData({id: userId}))
      handleClose()
    } catch (e) {
      console.error(e)
      redirectToError500Page(e)
      if (e.response?.data?.errors) {
        handleReactHookFormErrors<UserContactDataFormValues>(e.response.data.errors, form)
      }
    }
    setLoading(false)
  })

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t("user:contactInfo")}
      disabled={loading}
      actionButtonText={t("save")}
      actionButtonProps={{
        onClick: handleSubmit,
        startIcon: loading && <ButtonLoader position="prefix"/>,
      }}
      classes={{
        paperWidthSm: classes.dialogPaperWidth
      }}
    >
      <DialogContentText>
        {t("user:editYourData")}:
      </DialogContentText>

      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <TextFieldController
            name="email"
            label={t("user:email")}
            isRequired
            disabled={loading || benefitSystemIntegrationEnabled}
          />

          <Controller
            name="phoneNumber"
            control={form.control}
            render={({
              field: {onChange, value},
              fieldState: {error, invalid},
            }) => (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <PhoneCountryCodeSelect
                    id="phone-country"
                    label={t("user:phoneCountryCode")}
                    placeholder={t("user:phoneCountryCode")}
                    fullWidth
                    disabled={loading}
                    value={value.country}
                    onChange={handleChange(onChange, value, "country")}
                    error={!!(error as unknown as PhoneFieldError)?.country?.message}
                    helperText={(error as unknown as PhoneFieldError)?.country?.message}
                    InputProps={phoneSelectFieldProps}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    id="phone-national-number"
                    label={t("user:phone")}
                    placeholder={t("user:phone")}
                    fullWidth
                    disabled={loading}
                    value={value.number}
                    onChange={handleChange(onChange, value, "number")}
                    error={invalid}
                    helperText={(error?.message && showPhoneNumberOnCompleteDataView) ? t("user:wrongStationaryPhoneNumber") : (error?.message || (error as unknown as PhoneFieldError)?.number?.message)}
                    InputProps={phoneSelectFieldProps}
                  />
                </Grid>
              </Grid>
            )}
          />
        </form>
      </FormProvider>
    </AppDialog>
  )
}

export default UserContactDataEditModal
