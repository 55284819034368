import React, { FC, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, Checkbox, DialogContentText, FormControlLabel, Typography } from "@material-ui/core"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"

import api from "../../../../../api/api"
import { patchUserPersonalDataConfig, updateUserParentPersonalDataConfig } from "../../../../../api/routes"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks"
import useGetFormValidationRules from "../../../../../hooks/useGetFormValidationRules"
import { selectUserId } from "../../../../../store/session/session.selectors"
import { getUserData } from "../../../../../store/user/user.slice"
import AppDialog from "../../../../common/appDialog/AppDialog.component"
import ButtonLoader from "../../../../common/buttonLoader/ButtonLoader.component"
import { UserParentAddressDataEditForm } from "../../userParentAddressData/UserParentAddressDataEditForm/UserParentAddressDataEditForm.component"
import UserAddressDataEditForm from "../UserAddressDataEditForm/UserAddressDataEditForm.component"
import { handleReactHookFormErrors, redirectToError500Page } from "../../../../../utils/handleErrors"
import { formatPolandPostalCode } from "../../../../commonFormItems/postalCodeInput/PostalCodeInput.utils"
import { compareAddresses } from "../../userPersonalData/UserPersonalData.utils"
import {MainCountriesTypes, UserDataType} from "../../../../../store/user/user.types"
import {UserAddressModel} from "../../../User.types"
import { useUserProfileStyles } from "../../UserProfile.styles"

import { userEditAddressDataSchema } from "./UserAddressDataEditModal.validation"

interface AddressDataValues {
  sameAddressCheckbox: boolean;
  // patient values
  street: string;
  houseNumber: string;
  apartmentNumber: string;
  postalCode: string;
  city: string;
  country: string;
  // parent values
  parentStreet: string
  parentHouseNumber: string
  parentApartmentNumber: string;
  parentPostalCode: string;
  parentCity: string;
  parentCountry: string;
  showPhoneNumber?: boolean;
}

interface UserAddressDataEditModalProps {
  userData?: UserDataType | null;
  address?: UserAddressModel | null;
  userParentData?: UserDataType["user_parent"] | null;
  underageUserWithRegisteredParent?: boolean;
  open: boolean;
  onClose(): void;
}

const UserAddressDataEditModal: FC<UserAddressDataEditModalProps> = ({
  userData,
  address,
  userParentData,
  underageUserWithRegisteredParent,
  open,
  onClose,
}) => {
  const {t} = useTranslation()
  const userId = useAppSelector(selectUserId)
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const sameAddress = compareAddresses(userData?.address, userParentData?.address)
  const { coloredSubtitle } = useUserProfileStyles()
  const userAddress = userData?.address ?? address

  const form = useForm<AddressDataValues>({
    defaultValues: {
      sameAddressCheckbox: sameAddress,
      //patient data
      street: userAddress?.street ?? "",
      houseNumber: userAddress?.house_number ?? "",
      apartmentNumber: userAddress?.apartment_number ?? "",
      postalCode: 
        (userAddress?.country === MainCountriesTypes.PL && userAddress?.postal_code)
          ? formatPolandPostalCode(userAddress?.postal_code)
          : (userAddress?.postal_code ?? ""),
      city: userAddress?.city ?? "",
      country: userAddress?.country ?? userData?.nationality ?? "",
      //parent data
      parentStreet: userParentData?.address?.street ?? "",
      parentHouseNumber: userParentData?.address?.house_number ?? "",
      parentApartmentNumber: userParentData?.address?.apartment_number ?? "",
      parentPostalCode: 
        (userParentData?.address?.country === MainCountriesTypes.PL && userParentData?.address?.postal_code)
          ? formatPolandPostalCode(userParentData?.address?.postal_code)
          : (userParentData?.address?.postal_code ?? ""),
      parentCity: userParentData?.address?.city ?? "",
      parentCountry: userParentData?.address?.country ?? userParentData?.nationality ?? "",
    },
    mode: "all",
    resolver: useGetFormValidationRules(() => userEditAddressDataSchema()),
  })

  const sameData = form.watch("sameAddressCheckbox")

  const handleClose = () => {
    form.reset()
    onClose()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    setLoading(true)

    const patientAddressData = {
      street: userData?.address?.street === values.street
        ? undefined
        : (values.street || undefined),
      houseNumber: userData?.address?.house_number === values.houseNumber
        ? undefined 
        : (values.houseNumber || undefined),
      apartmentNumber: userData?.address?.apartment_number === values.apartmentNumber
        ? undefined
        : (values.apartmentNumber || undefined),
      postalCode: userData?.address?.postal_code === values.postalCode
        ? undefined
        : (values.postalCode || undefined),
      city: userData?.address?.city === values.city
        ? undefined
        : (values.city || undefined),
      country: userData?.address?.country === values.country
        ? undefined
        : (values.country || undefined)
    }

    const parentAddressData = {
      street: values.sameAddressCheckbox
        ? userParentData?.address?.street !== values.street ? values.street : undefined
        : userParentData?.address?.street !== values.parentStreet ? values.parentStreet : undefined,
      houseNumber: values.sameAddressCheckbox
        ? userParentData?.address?.house_number !== values.houseNumber ? values.houseNumber : undefined
        : userParentData?.address?.house_number !== values.parentHouseNumber ? values.parentHouseNumber : undefined,
      apartmentNumber: values.sameAddressCheckbox
        ? userParentData?.address?.apartment_number !== values.apartmentNumber ? values.apartmentNumber : undefined
        : userParentData?.address?.apartment_number !== values.parentApartmentNumber ? values.parentApartmentNumber : undefined,
      postalCode: values.sameAddressCheckbox
        ? userParentData?.address?.postal_code !== values.postalCode ? values.postalCode : undefined
        : userParentData?.address?.postal_code !== values.parentPostalCode ? values.parentPostalCode : undefined,
      city: values.sameAddressCheckbox
        ? userParentData?.address?.city !== values.city ? values.city : undefined
        : userParentData?.address?.city !== values.parentCity ? values.parentCity : undefined,
      country: values.sameAddressCheckbox
        ? userParentData?.address?.country !== values.country ? values.country : undefined
        : userParentData?.address?.country !== values.parentCountry ? values.parentCountry : undefined,
    }

    const shouldUpdatePatientData = Object.values(patientAddressData).find(val => !!val)
    const shouldUpdateParentData = Object.values(parentAddressData).find(val => !!val) && underageUserWithRegisteredParent

    try {
      if(shouldUpdatePatientData) {
        await api.request({
          ...patchUserPersonalDataConfig(userId), data: {
            address: patientAddressData
          },
        })
      }

      if(shouldUpdateParentData) {
        await api.request({
          ...updateUserParentPersonalDataConfig, data: {
            address: parentAddressData
          },
        })
      }
      await dispatch(getUserData({id: userId}))
      handleClose()
    } catch (e) {
      console.error(e)
      redirectToError500Page(e)
      if (e.response?.data?.errors) {
        handleReactHookFormErrors<AddressDataValues>(e.response.data.errors, form)
      }
    }
    setLoading(false)
  })

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t("user:addressInfo")}
      disabled={loading}
      actionButtonText={t("save")}
      actionButtonProps={{
        onClick: handleSubmit,
        startIcon: loading && <ButtonLoader position="prefix"/>,
      }}
    >
      <FormProvider {...form} >
        <Box>
          <DialogContentText classes={{ root: underageUserWithRegisteredParent ? coloredSubtitle : undefined}}>
            {underageUserWithRegisteredParent
              ? t("user:editYourAddressUnderage")
              : t("user:editYourData")
            }
          </DialogContentText>
          <UserAddressDataEditForm loading={loading} />
        </Box>

        {
          underageUserWithRegisteredParent && (
            <Box>
              <DialogContentText classes={{ root: coloredSubtitle }}>
                {t("user:editParentAddressUnderageUser")}
              </DialogContentText>
              <FormControlLabel
                label={<Typography variant="body2">{t("user:sameAddressLabel")}</Typography>}
                control={
                  <Controller
                    name="sameAddressCheckbox"
                    control={form.control}
                    render={({ field: {onChange, value}}) => (
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        checkedIcon={<CheckBoxIcon color="primary"/>}
                        icon={<CheckBoxOutlineBlankIcon/>}
                      />
                    )}
                  />
                }
              />
              <UserParentAddressDataEditForm
                loading={loading}
                hideForm={sameData}
              />
            </Box>
          )
        }
      </FormProvider>
    </AppDialog>
  )
}

export default UserAddressDataEditModal