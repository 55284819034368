import React, {FC} from "react"
import {useMediaQuery} from "@material-ui/core"

import {UserAction} from "../../../api/queries/getUserActionsByIds"
import DesktopDiseaseDetails from "./dekstopDiseaseDetails/DesktopDeseaseDetails.component"
import MobileDiseaseDetails from "./mobileDiseaseDetails/MobileDiseaseDetails.component"
import {Theme} from "@material-ui/core/styles"

type DiseaseDetailsProps = {
  name: string;
  description: string;
  userActions: UserAction[];
  isUserDisease: boolean;
  imageUrl: string;
  probability?: number;
  questionnaireId?: string;
}

const DiseaseDetails: FC<DiseaseDetailsProps> = ({
  name,
  description,
  userActions,
  isUserDisease, 
  probability,
  imageUrl,
  questionnaireId
}) => {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"))

  return isMobile ? (
    <MobileDiseaseDetails
      name={name}
      description={description}
      imageUrl={imageUrl}
      userActions={userActions}
      probability={probability}
      isUserDisease={isUserDisease}
      questionnaireId={questionnaireId}
    />
  ) : (
    <DesktopDiseaseDetails
      name={name}
      description={description}
      imageUrl={imageUrl}
      userActions={userActions}
      probability={probability}
      isUserDisease={isUserDisease}
      questionnaireId={questionnaireId}
    />
  )
}

export default DiseaseDetails