import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import {useExamRowStyles} from "./ExamRow.styles"

interface ExamRowProps {
  name: string;
  includedInPackage?: boolean;
}

const ExamRow: FC<ExamRowProps> = ({ 
  name,
  includedInPackage
}) => {
  const classes = useExamRowStyles()
  const {t} = useTranslation()

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Typography
        color={includedInPackage ? "textPrimary" : "textSecondary"}
        gutterBottom
      >
        {name}
      </Typography>
      <Typography
        variant="subtitle1"
        color={includedInPackage ? "initial" : "error"}
      >
        {t(includedInPackage ? "referrals:includedInPackage" : "referrals:notIncludedInPackage")}
      </Typography>
    </Box>
  )
}

export default ExamRow
