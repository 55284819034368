import React, {FC} from "react"
import { ListItemIcon, ListItemText } from "@material-ui/core"

import IconByIntegrationType from "../../../iconByIntegrationType/IconByIntegrationType"

type ClassesKey = "iconRoot"|"textRoot"

interface LogoutMenuItemProps {
  classes: Record<ClassesKey, string>;
  logoutLabel: string;
}

const LogoutMenuItem: FC<LogoutMenuItemProps> = ({classes, logoutLabel}) => {
  return (
    <>
      <ListItemIcon classes={{root: classes.iconRoot}}>
        <IconByIntegrationType iconName={"icon-sign-out"} />
      </ListItemIcon>
      <ListItemText
        classes={{root: classes.textRoot}}
        disableTypography
      >
        {logoutLabel}
      </ListItemText>
    </>
  )
}

export default LogoutMenuItem
