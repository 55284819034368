import {useState} from "react"

export const useDatePicker = () => {
  const [datePickerValue, setDatePickerValue] = useState("")

  const handleChangePicker = (value: Date | null) => {
    setDatePickerValue(value?.toString() ?? "")
  }

  return {
    datePickerValue,
    handleChangePicker
  }
}
