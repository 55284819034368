import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {InputAdornment,TextField as MuiTextField} from "@material-ui/core"

import {dateTestIdMap} from "../../../GenericStepForm.config"
import useTextField from "./TextField.utils"
import {CompletedAnswer} from "../../../GenericStepForm.types"
import {useTextFieldStyles} from "./TextField.styles"

type TextFieldProps = {
  onChange: (completedAnswer: CompletedAnswer[]) => void;
  errors: string[];
  value?: CompletedAnswer[];
  unit?: string;
  type?: "number" | "text" | "date";
  fullWidth?: boolean;
  multiline?: boolean;
  minRows?: number;
  dataTestId?: string;
}

const TextField: FC<TextFieldProps> = ({ 
  onChange,
  errors,
  value,
  unit,
  type = "text",
  fullWidth = false,
  multiline = false,
  minRows = 1,
  dataTestId = dateTestIdMap.textFieldInput
}) => {
  const {t} = useTranslation()
  const classes = useTextFieldStyles()
  const { handleChangeInput } = useTextField(onChange, unit)

  return (
    <MuiTextField
      inputProps={{
        "data-testid": dataTestId || dateTestIdMap.textFieldInput,
      }}
      InputProps={{
        endAdornment: <InputAdornment position="start">{unit}</InputAdornment>,
      }}
      className={classes.textField}
      value={value?.[0]?.value ?? ""}
      onChange={handleChangeInput}
      type={type}
      error={!!errors[0]}
      helperText={t(errors[0])}
      fullWidth={fullWidth}
      minRows={minRows}
      multiline={multiline}
    />
  )
}

export default TextField