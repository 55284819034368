import React, {FC} from "react"
import { Box } from "@material-ui/core"

import LoaderBox from "../../../../components/common/loaderBox/LoaderBox.component"
import BmiActionThankYouPage from "./bmiActionThankYouPage/BmiActionThankYouPage.component"
import {useBmiAction} from "./BmiAction.utils"

const BmiAction: FC = () => {
  const { isLoading } = useBmiAction()

  return (
    <Box>
      {
        isLoading
          ? <LoaderBox boxType="appDialog"/>
          : <BmiActionThankYouPage/>
      }
    </Box>
  )
}

export default BmiAction
