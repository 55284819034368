import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useConsultationTypeIconStyles = makeStyles<Theme>((theme) =>
  createStyles({
    icon: {
      fontSize: "24px",
      marginRight: theme.spacing(1),
      "&::before": {
        marginLeft: 0,
        marginRight: 0,
      }
    },
    iconMobile: {
      position: "absolute",
      top: 0,
      right: 0,
    },
    consultationFinished:{
      color: `${theme.palette.secondary.light} !important`
    },
    consultationUnfinished:{
      color: `${theme.palette.info.main} !important`
    }
  })
)
