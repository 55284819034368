import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import { useTheme } from "@material-ui/core"
import {Box, TextField, Typography} from "@material-ui/core"
import {Autocomplete} from "@material-ui/lab"

import api from "../../api/api"
import {getClinicsList} from "../../api/routes"
import LoaderBox from "../common/loaderBox/LoaderBox.component"
import GlobalFormErrorMessage from "../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import {ClinicListItem, ClinicListOptions} from "../settingsPanel/clinicSelect/Clinic.types"
import {useClinicNotFoundStyles} from "../../pages/clinicNotFound/ClinicNotFound.styles"

const MAIN_CLINIC_API_URL = "https://telemedi.co"
const MAIN_CLINIC_DASHBOARD_URL = "https://panel.telemedi.com/"

const ClinicSelect = () => {
  const {t} = useTranslation()
  const theme = useTheme()
  const classes = useClinicNotFoundStyles()
  const [clinicListOptions, setClinicListOptions] = useState<ClinicListOptions[] | null>(null)
  const [clinicListError, setClinicListError] = useState<boolean>(false)

  const convertClinicListToOptions = (clinicList: ClinicListItem[]) => {
    const clinicListOptions: ClinicListOptions[] = []

    clinicList.forEach(item => {
      clinicListOptions.push({
        value: item.url,
        label: item.name
      })
    })

    setClinicListOptions(clinicListOptions)
  }

  const redirectToDashboard = (dashboardUrl: string|undefined) => {
    if (dashboardUrl) {
      window.location.href = dashboardUrl === MAIN_CLINIC_API_URL ? MAIN_CLINIC_DASHBOARD_URL : dashboardUrl
    }
  }

  useEffect(() => {
    const getAllClinics = async () => {
      try {
        const {data: {result}} = await api.request({
          ...getClinicsList(),
        })
        convertClinicListToOptions(result)
      } catch (e) {
        console.error(e)
        setClinicListError(true)
      }
    }

    getAllClinics()
  }, [])
    
  if (!clinicListOptions) {
    return <LoaderBox size={theme.spacing(8)} thickness={2}/>
  }

  return (
    <Box mt={9}>
      <Typography className={classes.label}>
        {t("errors:loginToDashboardLabel")}
      </Typography>
      {clinicListError && <GlobalFormErrorMessage message={t("errors:unknownError")}/>}
      <Autocomplete
        options={clinicListOptions || []}
        noOptionsText={t("prescription:noResults")}
        getOptionLabel={({ label }: { label: string }) => label}
        getOptionSelected={(option, value) => option.label === value.label}
        clearOnEscape
        id="clinicSelect"
        onChange={(e, data) => redirectToDashboard(data?.value)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("errors:chooseInsurer")}
            variant="outlined"
          />
        )}
      />
    </Box>
  )
}

export default ClinicSelect
