import React, { FC, ReactNode } from "react"
import { useFormContext } from "react-hook-form"

import { CollapseCheckboxController } from "./CollapseCheckboxController/CollapseCheckboxController.component"
import { SurveyExtraQuestions } from "../ExtraQuestions.types"

interface CollapseSurveyCheckboxProps {
  question: SurveyExtraQuestions;
  nestedItems: ReactNode[] | null;
}

const COLUMN_ORDER_QUESTION_NAME = [
  "ailments",
  "ailmentsTime"
]

export const CollapseSurveyCheckbox: FC<CollapseSurveyCheckboxProps> = ({ question, nestedItems }) => {
  const form = useFormContext()
  const currentValue = form.watch(question.name)
  const expanded = (currentValue === question.correctAnswer) || (currentValue && currentValue.length > 0 && !question.correctAnswer)
  const columnOrder = COLUMN_ORDER_QUESTION_NAME.includes(question.name)

  return (
    <CollapseCheckboxController
      name={question.name}
      label={question.title}
      options={question.answers}
      nestedItems={nestedItems}
      expanded={expanded}
      row={!columnOrder}
    />
  )
}
