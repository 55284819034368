import { SagaIterator } from "redux-saga"
import { call, put, select, takeLatest } from "redux-saga/effects"

import { appEnv, SETTINGS_PANEL_ENABLE } from "../../app.config"
import { SETTINGS_STORAGE_KEY } from "./settings.types"

import { selectStagingSettings } from "./settings.selectors"
import { clearSettings, setStagingSettings } from "./settings.slice"

function* clearSettingsSaga() {
  yield call(() => localStorage.removeItem(SETTINGS_STORAGE_KEY))
}

function* setStagingSettingsSaga({payload}: ReturnType<typeof setStagingSettings>) {
  const stagingSettings: ReturnType<typeof selectStagingSettings> = yield select(selectStagingSettings)
  yield call(() => localStorage.setItem(SETTINGS_STORAGE_KEY, JSON.stringify({...stagingSettings, ...payload})))
}

export default function* (): SagaIterator {
  if (SETTINGS_PANEL_ENABLE.includes(appEnv)) {
    const stagingSettingsJSON: string = yield call(() => localStorage.getItem(SETTINGS_STORAGE_KEY))
    const stagingSettings = JSON.parse(stagingSettingsJSON)

    if (stagingSettings) {
      yield put(setStagingSettings(stagingSettings))
    }

    yield takeLatest(setStagingSettings, setStagingSettingsSaga)
    yield takeLatest(clearSettings, clearSettingsSaga)
  }
}
