import React, {FC, RefObject,useEffect, useRef, useState} from "react"
import {
  LocalParticipant,
  RemoteParticipant,
  Track,
  TrackPublication,
} from "twilio-video"

import {useConsultationTwilioVideoStyles} from "./ConsultationTwilioVideo.styles"

interface ParticipantProps {
  participant: LocalParticipant | RemoteParticipant;
  isCameraOn: boolean;
}

const VIDEO_TRACK_KIND = "video"

const Participant: FC<ParticipantProps> = ({participant, isCameraOn}) => {
  const [videoTracks, setVideoTracks] = useState<any[]>([])
  const [audioTracks, setAudioTracks] = useState<any[]>([])
  const videoRef: RefObject<HTMLVideoElement> = useRef(null)
  const audioRef: RefObject<HTMLAudioElement> = useRef(null)
  const classes = useConsultationTwilioVideoStyles({isCameraOn})

  const trackpubsToTracks = (trackMap: Map<Track.SID, TrackPublication>) => Array.from(trackMap.values())
    .map((publication: any) => publication.track)
    .filter(track => track !== null)

  useEffect(() => {
    const trackSubscribed = (track: Track) => {
      if (track.kind === VIDEO_TRACK_KIND) {
        setVideoTracks(videoTracks => [...videoTracks, track])
      } else {
        setAudioTracks(audioTracks => [...audioTracks, track])
      }
    }

    const trackUnsubscribed = (track: Track) => {
      if (track.kind === VIDEO_TRACK_KIND) {
        setVideoTracks(videoTracks => videoTracks.filter(videoTrack => videoTrack !== track))
      } else {
        setAudioTracks(audioTracks => audioTracks.filter(audioTrack => audioTrack !== track))
      }
    }

    setVideoTracks(trackpubsToTracks(participant.videoTracks))
    setAudioTracks(trackpubsToTracks(participant.audioTracks))

    participant.on("trackSubscribed", trackSubscribed)
    participant.on("trackUnsubscribed", trackUnsubscribed)

    return () => {
      setVideoTracks([])
      setAudioTracks([])
      participant.removeAllListeners()
    }
  }, [])

  useEffect(() => {
    const videoTrack = videoTracks?.[0]
    const audioTrack = audioTracks?.[0]

    if (videoTrack) {
      videoTrack.attach(videoRef.current)
    }

    if (audioTrack) {
      audioTrack.attach(audioRef.current)
    }

    return () => {
      videoTrack?.detach()
      audioTrack?.detach()
    }
  }, [videoTracks, audioTracks])

  return (
    <div className={classes.participant}>
      <video ref={videoRef} autoPlay />
      <audio ref={audioRef} autoPlay />
    </div>
  )
}

export default Participant
