import {ChangeEvent, useState} from "react"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router"
import {useMutation} from "@apollo/client"

import {
  INSERT_USER_DISEASES,
  InsertUsersDiseasesResponse,
  InsertUsersDiseasesVariables
} from "../../../api/mutations/insertUserDiseases"
import {Disease} from "../../../api/queries/getUserDiseasesFilteredByIds"
import {RoutePath} from "../../../../../routes/Routes.types"

export const useSearchDisease = (diseases: Disease[], onClose: VoidFunction) => {
  const [searchValue, setSearchValue] = useState("")

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchValue(value)
  }
  
  const handleClose = () => {
    setSearchValue("")
    onClose()
  }

  return {
    filteredDiseases: diseases.filter(
      disease => disease.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    ),
    searchValue,
    handleChange,
    handleClose
  }
}

export const useAddDiseases = (onClose: VoidFunction) => {
  const { push } = useHistory()
  const { i18n } = useTranslation()
  const [insertUserDiseases, { loading: isLoading }] = useMutation<
  InsertUsersDiseasesResponse,
  InsertUsersDiseasesVariables
  >(
    INSERT_USER_DISEASES,
    {
      context: { clientName: "HealthPlanner" },
      onCompleted: () => {
        push(`/${i18n.language}${RoutePath.PREVENTION}`)
      }
    }
  )
  const [chosenDiseaseIds, setChosenDiseaseIds] = useState<string[]>([])

  const handleChangeDisease = (diseaseId: string) => () => {
    const isDiseaseAlreadyAdded = chosenDiseaseIds.includes(diseaseId)

    if (isDiseaseAlreadyAdded) {
      setChosenDiseaseIds(
        previousState => previousState.filter(id => diseaseId !== id)
      )
    } else {
      setChosenDiseaseIds(previousState => {
        previousState.push(diseaseId)
        return [...previousState]
      })
    }
  }

  const handleClearStateOnClose = () => {
    setChosenDiseaseIds([])
    onClose()
  }

  const updateUserDiseases = () =>
    insertUserDiseases({ variables: {
      userDiseases: chosenDiseaseIds.map(chosenDiseaseId => ({ disease_id: chosenDiseaseId }))
    }})

  return {
    isLoading,
    chosenDiseaseIds,
    handleChangeDisease,
    updateUserDiseases,
    handleClearStateOnClose
  }
}