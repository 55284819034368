import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const usePrescriptionStyles = makeStyles((theme: Theme) => createStyles({
  expand: {
    flex: 1
  },
  visible: {
    opacity: 1,
    transition: "opacity 300ms",
  },
  hidden: {
    opacity: 0,
    transition: "opacity 300ms",
    pointerEvents: "none",
  },
  list: {
    listStyle: "none",
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    "& > $listItem": {
      paddingTop: 0,
      paddingBottom: 0,
    }
  },
  listItem: {
    "& > div:not(.hiddenBorder)": {
      borderBottom: `2px solid ${theme.palette.grey["100"]}`,
    },
    [theme.breakpoints.up("sm")]: {
      "&:last-child > div": {
        borderBottom: "none",
      },
    }
  },
  actionButton: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  icon: {
    color: theme.palette.text["primary"],
    position: "relative",
    top: "6px",
  },
  tooltipText: {
    color: theme.palette.common["white"],
    whiteSpace: "nowrap",
  },
  tooltip: {
    padding: theme.spacing(1, 1.5),
    backgroundColor: theme.palette.text["primary"],
    borderRadius: theme.spacing(1),
    maxWidth: "none",
  },
  popupOpenBtn: {
    whiteSpace: "nowrap",
    marginTop: theme.spacing(1),
    marginRight: "20%",
    [theme.breakpoints.up("md")]: {
      whiteSpace: "nowrap",
    }
  },
  codeLabel: {
    marginLeft: theme.spacing(.5),
    [theme.breakpoints.up("sm")]: {
      whiteSpace: "nowrap",
      marginTop: "6px",
    }
  },
}))
