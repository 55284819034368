import React, {Dispatch, FC} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Grid} from "@material-ui/core"

import useGetFormValidationRules from "../../../../../hooks/useGetFormValidationRules"
import TextFieldController from "../../../../commonFormItems/textFieldController/TextFieldController.component"
import {useDebounceSubmit} from "./AddressForm.utils"
import {Address} from "../AddressSearchEngine.types"

import {addressFormValidation} from "./AddressForm.validation"

interface AddressFormProps {
  onSubmit: Dispatch<Address>;
  defaultValues?: Address;
}

const AddressForm: FC<AddressFormProps> = ({ onSubmit, defaultValues }) => {
  const {t} = useTranslation()
  const formMethods = useForm<Address>({
    defaultValues: defaultValues,
    resolver: useGetFormValidationRules(addressFormValidation),
    mode: "onSubmit"
  })
  useDebounceSubmit(formMethods, onSubmit)

  return (
    <FormProvider {...formMethods}>
      <form>
        <Grid
          container
          direction="column"
        >
          <TextFieldController
            label={t("user:city")}
            name="city"
            isRequired
          />
          <TextFieldController
            label={t("user:street")}
            name="street"
            isRequired
          />
          <TextFieldController
            label={t("user:houseNumber")}
            name="house_number"
          />
          <TextFieldController
            label={t("user:postalCode")}
            name="postal_code"
          />
        </Grid>
      </form>
    </FormProvider>
  )
}

export default AddressForm
