import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import PageWrapper from "../../components/pageWrapper/PageWrapper.component"
import MessengerChatController from "./MessengerChatController/MessengerChatController.component"

const MessengerChatPage: FC = () => {
  const { t } = useTranslation()

  return (
    <PageWrapper
      title={t("healthPlanner:messengerChat:pageTitle")}
    >
      <MessengerChatController />
    </PageWrapper>
  )
}

export default MessengerChatPage
