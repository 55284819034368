import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Box, Button, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import visitConfirmed from "../../assets/images/visit-confirmed.png"
import {useAppSelector} from "../../hooks/storeHooks"
import {selectClinicSettings} from "../../store/clinic/clinicSettings.selectors"
import ImageByIntegrationType, {
  STATIONARY_CONSULTATION_SUCCESSFUL_PAGE
} from "../../components/imageByIntegrationType/ImageByIntegrationType"

import { useSuccessfullyStationaryPaymentPageStyles } from "./StationaryConsultationSuccessfulPaymentPage.style"

interface StationaryConsultationSuccessfulPaymentPageParams {}

const StationaryConsultationSuccessfulPaymentPage: FC<StationaryConsultationSuccessfulPaymentPageParams> = () => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const classes = useSuccessfullyStationaryPaymentPageStyles()
  const { push } = useHistory()
  const {
    clinicAllianzSettings: { ewkaVerificationEnabled, onyxVerificationEnabled }
  } = useAppSelector(selectClinicSettings)

  const goToHomepage = () => {
    push(`/${i18n.language}`)
  }

  const image = (
    <ImageByIntegrationType
      imageSrc={visitConfirmed}
      step={STATIONARY_CONSULTATION_SUCCESSFUL_PAGE}
      alt="visitConfirmedImage"
      className={classes.image}
      imagePath={"visit-confirmed.png"}
    />
  )

  return (
    <Box className={classes.pageSection} component="section">
      <Box className={classes.content}>
        <Box>
          <Typography className={classes.title}>
            { t("consultation:stationaryVisitPaymentConfirmationTitle_part1") }
            <span className={classes.spanText}>
              { t("consultation:stationaryVisitPaymentConfirmationTitle_part2") }
            </span>
          </Typography>
        </Box>
        <Typography className={classes.thankYouText}>
          { t("consultation:thankYou") }
        </Typography>
        <Box mb={3}>
          <Typography className={classes.bodyText}>
            {t("consultation:stationaryVisitPaymentConfirmationBodyText")}
          </Typography>
        </Box>
        { !isMdUp && (ewkaVerificationEnabled || onyxVerificationEnabled) && <Box className={classes.mobileImageWrapper}>{image}</Box> }
        <Box className={classes.buttonsWrapper}>
          <Button variant="contained" color="primary" onClick={goToHomepage}>
            {t("consultation:goBackToMainPage")}
          </Button>
        </Box>
      </Box>
      { isMdUp && <Box className={classes.desktopImageWrapper}>{image}</Box> }
    </Box>
  )
}

export default StationaryConsultationSuccessfulPaymentPage
