import {gql} from "@apollo/client"

export interface GlycemiaMeasurementsModel {
  createdAt: string;
  id: string;
  pulse: number;
}
    
export interface GlycemiaMeasurementsData {
  glycemiaMeasurements: GlycemiaMeasurementsModel[]
}

export const GET_USER_GLYCEMIA_MEASUREMENTS = gql`
  query getUserGlycemiaMeasurements {
    glycemiaMeasurements: examinations_glycemia_answers(order_by: {created_at: desc}) {
      createdAt: created_at
      id
      glucose
    }
  }
`
