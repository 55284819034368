import React, { FC } from "react"
import { Controller } from "react-hook-form"
import { useFormContext } from "react-hook-form"
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps} from "@material-ui/core"

interface SelectControllerProps extends SelectProps {
  disabled?: boolean;
  fullWidth?: boolean;
  label: string;
  name: string;
  options: {
    value: string | number;
    label: string;
  }[];
  placeholder?: string
  required?: boolean;
}

const SelectController: FC<SelectControllerProps> = (
  {
    name,
    label,
    placeholder = label,
    fullWidth = true,
    disabled = false,
    ...props
  }) => {
  const form = useFormContext()

  return (
    <Controller
      name={name}
      control={form.control}
      render={(
        {
          field: {
            onChange,
            value
          },
          fieldState: {
            error,
            invalid,
          }
        }) => (
        <FormControl
          variant='outlined'
          error={invalid}
          fullWidth={fullWidth}
        >
          <InputLabel
            id={`${name}-label`}
          >{label}</InputLabel>
          <Select
            id={name}
            labelId={`${name}-label`}
            label={label}
            variant="outlined"
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            error={invalid}
            fullWidth={fullWidth}
            onChange={onChange}
            {...props}
          >
            {
              props.options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))
            }
          </Select>
          <FormHelperText>
            {error?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default SelectController
