import { createStyles, makeStyles } from "@material-ui/core"

import { Theme } from "@material-ui/core/styles"

export const useDiseaseManagementTileStyles = makeStyles((theme: Theme) => createStyles({
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    maxWidth: "280px",
    height: "auto",
    background: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  imageWrapper: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "100%",
    maxWidth: "190px",
  },
}))