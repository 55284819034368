import i18next from "i18next"
import isEqual from "lodash.isequal"
import omit from "lodash.omit"

import {
  MainCountriesTypes,
  UserDataType,
  UserDocumentData,
  UserDocumentType,
  UserParentDataType
} from "../../../../store/user/user.types"
  
const ESLabels = { // no translations needed
  DNI: "DNI",
  TIE: "TIE",
  NIE: "NIE"
}

export const getUserDocumentData = (
  user?: UserDataType | UserParentDataType | null, 
  userHiddenPesel?: string,
  translationKeyPrefix = "user"
): UserDocumentData => {
  const identityDocumentType = user?.identity_document_type

  if (!identityDocumentType || user?.pesel) {
    return (
      {
        label: i18next.t(`${translationKeyPrefix}:pesel`),
        value: userHiddenPesel || user?.pesel || "",
      }
    )
  }

  switch (identityDocumentType) {
    case UserDocumentType.IDENTITY_CARD_NUMBER:
      return (
        {
          label: i18next.t("user:identityCardNumber"),
          value: user?.identity_number || ""
        }
      )
    case UserDocumentType.SEAMANS_BOOK:
      return (
        {
          label: i18next.t("user:seamansBook"),
          value: user?.identity_number || ""
        }
      )
    case UserDocumentType.DRIVERS_LICENSE_NUMBER:
      return (
        {
          label: i18next.t("user:driversLicenseNumber"),
          value: user?.identity_number || ""
        }
      )
    case UserDocumentType.PASSPORT:
      return (
        {
          label: i18next.t("user:passportNumber"),
          value: user?.identity_number || ""
        }
      )
    default:
      return (
        {
          label: "",
          value: "",
        }
      )
  }
}

export const getDocumentTypesForCountry = (nationality?: string | null): Array<UserDocumentData> => {
  switch (nationality) {
    case MainCountriesTypes.PL:
      return [
        {
          value: UserDocumentType.PESEL,
          label: i18next.t("user:pesel"),
        },
      ]
    case MainCountriesTypes.ES:
      return [
        {
          value: UserDocumentType.PESEL,
          label: i18next.t("user:pesel"),
        },
        {
          value: UserDocumentType.DNI,
          label: ESLabels.DNI,
        },
        {
          value: UserDocumentType.TIE,
          label: ESLabels.TIE
        },
        {
          value: UserDocumentType.NIE,
          label: ESLabels.NIE
        },
        {
          value: UserDocumentType.ES_IDENTITY_CARDS_TOGETHER, // ES Documents exceptions: hidden option to show all documents as label
          label: `${ESLabels.DNI} / ${ESLabels.TIE} / ${ESLabels.NIE}`
        },
        {
          value: UserDocumentType.PASSPORT,
          label: i18next.t("user:passportNumber"),
        },
      ]
    default:
      return [
        {
          value: UserDocumentType.PESEL,
          label: i18next.t("user:pesel"),
        },
        {
          value: UserDocumentType.IDENTITY_CARD_NUMBER,
          label: i18next.t("user:identityCardNumber"),
        },
        {
          value: UserDocumentType.PASSPORT,
          label: i18next.t("user:passportNumber"),
        },
      ]
  }
}
// ES Documents exceptions: set proper default value - for example, we cant define it by identity_document_type so should show all of them
export const setProperDefaultDocumentType = (userNationality?: string | null, documentType?: string | null) => {
  if(userNationality === MainCountriesTypes.ES && documentType === UserDocumentType.IDENTITY_CARD_NUMBER) {
    return UserDocumentType.ES_IDENTITY_CARDS_TOGETHER
  }
  return documentType ?? ""
}

// ES Documents exceptions: send proper ID for this documents - for example IDENTITY_CARD_NUMBER
export const setProperDocumentType = (documentType: string) => {
  if(documentType === UserDocumentType.DNI || documentType === UserDocumentType.TIE || documentType === UserDocumentType.NIE) {
    return UserDocumentType.IDENTITY_CARD_NUMBER
  }
  return documentType
}

export const compareAddresses = (userAddress?: UserDataType["address"] | null, parentAddress?: UserParentDataType["address"] | null) => {
  if(!userAddress || !parentAddress) {
    return false
  }
  
  return isEqual(
    omit(userAddress, "id"),
    omit(parentAddress, "id")
  )
}