import { selectUser } from "../store/user/user.selectors"

import { useAppSelector } from "./storeHooks"

export const useUserIsUnderage = (): boolean | null => {
  const user = useAppSelector(selectUser)
  const birthDate = user?.birth_date
  if (!birthDate) {
    return null
  }

  const age = 18
  const userDate = new Date(birthDate)
  const currentDate = new Date()

  currentDate.setFullYear(currentDate.getFullYear() - age)

  if((currentDate.getTime() - userDate.getTime()) < 0) {
    return true
  }
  return false
}