import {makeStyles} from "@material-ui/core"

import {createStyles, Theme} from "@material-ui/core/styles"

export const useUserMenuMobileStyles = makeStyles((theme: Theme) => createStyles({
  badge: {
    top: theme.spacing(1),
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: "100%",
  },
  menuPaper: {
    maxWidth: `calc(100% - ${ theme.spacing(2) }px)`,
    width: "300px",
    paddingBottom: theme.spacing(1.5),
  },
  notificationsBadge : {
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: "100%",
  },
  listItemRoot: {
    padding: theme.spacing(2, 3),
  },
  listItemIconRoot: {
    color: theme.palette.text.primary,
    ...theme.typography.h5,
    marginRight: theme.spacing(2),
  },
  listItemTextRoot: {
    color: theme.palette.text.primary,
    ...theme.typography.h5,
  },
  childListItemRoot: {
    padding: theme.spacing(3, 4),
  },
  listItemAvatarRoot: {
    paddingRight: theme.spacing(2),
  },
  listItemLogout: {
    paddingLeft: "3px",
  },
}))
