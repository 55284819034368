import {ChangeEvent} from "react"

import {CompletedAnswer} from "../../../GenericStepForm.types"

const TEXT_FIELD_ID = "1"

const useTextField = (
  onChange: (completedAnswer: CompletedAnswer[]) => void,
  unit?: string
) => {

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const answerToAdd: CompletedAnswer = {
      name: "",
      value,
      id: TEXT_FIELD_ID,
    }

    if (unit) {
      answerToAdd.unit = unit
    }

    onChange([answerToAdd])
  }
  
  return {
    handleChangeInput
  }
}

export default useTextField