import React, {FC, useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {useParams} from "react-router"
import {CancelTokenSource} from "axios"
import {push} from "connected-react-router"
import i18next from "i18next"

import api from "../../api/api"
import {getConsultationConfig, getCountriesConfig} from "../../api/routes"
import {useAppDispatch} from "../../hooks/storeHooks"
import {selectToken} from "../../store/session/session.selectors"
import {selectHasUserCompletePersonalData, selectLoading} from "../../store/user/user.selectors"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import PageSection from "../../components/common/pageSection/PageSection.component"
import {Countries} from "../../components/commonFormItems/countrySelect/Country.types"
import {
  ConsultationModel,
  ConsultationStatusNumber,
  ConsultationType
} from "../../components/consultation/Consultation.types"
import FillUserDataInformationPanel
  from "../../components/informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import SickLeaveSurvey from "../../components/SickLeaveSurvey/SickLeaveSurvey.component"
import {redirectToError500Page} from "../../utils/handleErrors"

const SickLeavePage: FC = () => {
  const [consultationData, setConsultationData] = useState<ConsultationModel>()
  const [loading, setLoading] = useState(true)
  const [countriesData, setCountriesData] = useState<Countries|null>(null)
  const tokenData = useSelector(selectToken)
  const userDataLoading = useSelector(selectLoading)
  const { consultationId } = useParams<{ consultationId: string }>()
  const hasUserCompletePersonalData = useSelector(selectHasUserCompletePersonalData)
  const dispatch = useAppDispatch()

  const getCountries = async (cancelToken?: CancelTokenSource["token"]) => {
    const {data: {countries}} = await api.request({
      ...getCountriesConfig(i18next.language),
      cancelToken
    })
    setCountriesData(countries)
  }

  const getConsultationData = async (cancelToken?: CancelTokenSource["token"]) => {
    try {
      const {data} = await api.request<ConsultationModel>({
        ...getConsultationConfig(consultationId),
        cancelToken,
      })

      setConsultationData(data)
    } catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
      redirectToError500Page(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!consultationData && tokenData) {
      getConsultationData()
    }
  }, [tokenData, consultationData])

  useEffect(() => {
    try {
      getCountries()
    } catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
    }
  }, [])

  if (userDataLoading || loading || !consultationData || !countriesData) {
    return <LoaderBox />
  }

  if (consultationData && (consultationData?.status_number !== ConsultationStatusNumber.RESERVED || consultationData?.consultationType !== ConsultationType.SICK_LEAVE || consultationData?.sickLeaveQuestionnaireFilled)) {
    dispatch(push(`/${i18next.language}`))
  }

  return (
    <PageSection>
      {hasUserCompletePersonalData
        ? <SickLeaveSurvey
          consultationId={consultationData?.id}
          orderedSickLeave={consultationData?.orderedSickLeave}
          countriesData={countriesData}
        />
        : <FillUserDataInformationPanel/>
      }
    </PageSection>
  )
}

export default SickLeavePage
