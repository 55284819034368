import { createStyles } from "@material-ui/core"

import {boxShadows} from "../../../themes/shadows"
import { makeStyles, Theme } from "@material-ui/core/styles"

export const useAppBoxStyle = makeStyles((theme: Theme) => createStyles({
  root: {
    marginBottom: theme.spacing(7),
    padding: theme.spacing(3, 2),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    boxShadow: boxShadows.shadow1,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4, 5.5),
    },
  }
}))