import {format} from "date-fns"

import {getConsultationExamReferralsConfig, postExamCartConfig} from "../../api/routes"
import {v4Service} from "../store.service"
import {ConsultationExamReferral} from "../../components/exam/Exam.types"
import {RootState} from "../store.types"
import {ConsultationExamReferralsResponse, ExaminationOrder} from "./examReferrals.types"

const examReferralsApi = v4Service.injectEndpoints({
  endpoints: build => ({
    getExaminationReferrals: build.query<ConsultationExamReferral[], string>({
      query: (id) => ({
        ...getConsultationExamReferralsConfig(id)
      }),
      transformResponse: (response: ConsultationExamReferralsResponse) => response.referrals
    }),
    createExamOrder: build.mutation<void, ExaminationOrder>({
      async queryFn(examinationOrder, { getState }, extraOptions, baseQuery) {
        const state = (getState() as RootState)
        const patientId = state.user.userId
        const laboratoryId = state.clinic.clinicSettings.patientLaboratories[0]

        const { data, error } = await baseQuery({
          ...postExamCartConfig,
          data: {
            laboratory_id: laboratoryId,
            patient_id: patientId,
            consultation_id: examinationOrder.consultationId,
            language: examinationOrder.language,
            is_poz: false,
            payment_redirect_url: false,
            execute_date: format(
              examinationOrder.executeDate
                ? new Date(examinationOrder.executeDate)
                : new Date(),
              "Y-MM-d"
            ),
            exams: examinationOrder.referrals.map(referral => referral.id)
          }
        })

        if (error) {
          return { error }
        }

        return { data: data as void }
      }
    })
  })
})

export const { useCreateExamOrderMutation, useGetExaminationReferralsQuery } = examReferralsApi
