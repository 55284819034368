import React, {FC, useMemo} from "react"
import {useTranslation} from "react-i18next"
import {Redirect} from "react-router"
import {Box, Container, Theme, Typography, useMediaQuery} from "@material-ui/core"

import {PASSWORDLESS_MAGIC_LINK_ID_KEY} from "../../app.config"
import withBaseLayout from "../../hoc/withBaseLayout"
import {useRemoveIsFirstSessionInvoked} from "../../hooks/useRemoveIsFirstSessionInvoked"
import { useQueryParam } from "../../hooks/useSearchParams"
import LangDropdowndOnBasePage from "../../components/common/langDropdown/LangDropdownOnBasePage.component"
import PageSection from "../../components/common/pageSection/PageSection.component"
import VerifyUserCode from "./verifyUserCode/VerifyUserCode.component"
import {useLoginMagicLinkPageStyles} from "../loginMagicLink/LoginMagicLinkPage.styles"

const PasswordlessAccessCode: FC = () => {
  useRemoveIsFirstSessionInvoked()

  const classes = useLoginMagicLinkPageStyles()

  const {t} = useTranslation()
  const id = useQueryParam("id")
  const magicLinkId = useMemo(() => id || sessionStorage.getItem(PASSWORDLESS_MAGIC_LINK_ID_KEY), [])
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"))

  return (
    <Container
      maxWidth="sm"
      className={classes.root}
    >
      <PageSection
        titleNode={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <Typography
              variant={isMdUp ? "h2" : "h4"}
              component="h2"
            >
              {t("login:logIn")}
            </Typography>

            <LangDropdowndOnBasePage/>
          </Box>
        }
      >
        {
          magicLinkId
            ? (
              <VerifyUserCode
                magicLinkId={magicLinkId}
              />
            )
            : <Redirect to={"/"}/>
        }
      </PageSection>
    </Container>
  )
}

export default withBaseLayout(PasswordlessAccessCode)
