import React, { FC } from "react"
import { Box, Button, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import { ALLIANZ_EWKA_INTEGRATION_TYPE, ALLIANZ_ONYX_INTEGRATION_TYPE } from "../../../utils/getIntegrationType"
import { useEmptyConsultationsBoxStyles } from "../../emptyConsultationsBox/EmptyConsultationsBox.styles"

interface SimpleCardProps {
  intro: string;
  title: string;
  imageSrc: string;
  buttonText: string;
  className?: string;
  onButtonClick?: () => void;
  imagePath: string;
  integrationType?: number|null;
}

const SimpleCard: FC<SimpleCardProps> = ({
  intro,
  title,
  imageSrc,
  buttonText,
  className,
  onButtonClick,
  imagePath,
  integrationType
}) => {
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))
  const classes = useEmptyConsultationsBoxStyles()
  const isAllianz = integrationType === ALLIANZ_ONYX_INTEGRATION_TYPE
      || integrationType === ALLIANZ_EWKA_INTEGRATION_TYPE

  const handleClick = () => {
    if (onButtonClick) {
      onButtonClick()
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      className={className}
      pb={4}
    >
      <Box mb="auto">
        <Typography 
          variant="body2" 
          className={classes.introText}
        >
          {intro}
        </Typography>
        <Typography 
          variant={isAllianz ? undefined : "h4"} 
          component={isAllianz ? "b" : "h4"} >
          {title}
        </Typography>
      </Box>

      <Box
        pt={isLgUp ? 3 : 2}
        pb={isLgUp ? 4 : 2}
      >
        <ImageByIntegrationType
          imageSrc={imageSrc}
          alt="Image"
          imagePath={imagePath}
        />
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        {buttonText}
      </Button>
    </Box>
  )
}

export default SimpleCard
