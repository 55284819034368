import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography} from "@material-ui/core"

import { useGetChatThreadsForUser } from "../../api/queries/getChatThreadsForUser/getChatThreadsForUser.hook"
import Loader from "../../../../components/loader/Loader.component"
import MessengerThreadItem from "../../pages/messengerChatPage/MessengerChatController/MessengerThreadsList/MessengerThreadItem/MessengerThreadItem.component"
import { useMessengerTileStyles } from "./MessengerTile.styles"

const MessengerTile: FC = () => {
  const { t } = useTranslation()
  const { boxCard } = useMessengerTileStyles()

  const { data: { chatThreads } = {}, loading, error } = useGetChatThreadsForUser()

  if (!chatThreads?.length || error || loading) {
    return null
  }

  return (
    <Box
      className={boxCard}
    >
      <Box
        pl={2}
        pt={2}
        pb={1}
      >
        <Typography
          variant="h3"
        >
          {t("healthPlanner:messengerChat:threadsLabel")}
        </Typography>
      </Box>
      {loading && <Loader m="auto" />}
      {error && (
        <Box
          m="auto"
          p={4}
        >
          <Typography variant="body1">{t("errors:defaultError")}</Typography>
        </Box>
      )}
      {chatThreads && !loading && chatThreads.map(( thread ) => (
        <MessengerThreadItem
          key={thread.id}
          threadId={thread.id}
          title={t("healthPlanner:messengerChat:threadLabel")}
        />
      ))}
    </Box>
  )
}

export default MessengerTile