import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useParams} from "react-router"
import {push} from "connected-react-router"
import i18next from "i18next"

import api from "../../api/api"
import {checkDeclarationStatus} from "../../api/routes"
import {ssoMiddlewareHost} from "../../app.config"
import {ApiError} from "../../enums/ApiErrorCode"
import {analytics, LogEventType} from "../../services/analytics"
import store from "../../store"
import {initSession} from "../../store/session/session.slice"
import {selectUser} from "../../store/user/user.selectors"
import {setPozDeclarationActiveStatus} from "../../store/user/user.slice"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import DeclarationSignFailedPage from "./DeclarationSignFailed.component"
import DeclarationSignSuccess from "./DeclarationSignSuccess.component"

const DeclarationSignStatusPage = () => {
  const { id } = useParams<{ id: string }>()
  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [statusChecked, setIsStatusChecked] = useState(false)
  const [errorCode, setErrorCode] = useState<number|null>(null)
  const dispatch = useDispatch()
  const userData = useSelector(selectUser)

  useEffect(() => {
    analytics.sendEventWithDefaultParams(LogEventType.POZ_COMPLETE_PAG_LOAD)
  }, [])

  useEffect(() => {
    if (userData && !statusChecked) {
      const getDeclarationStatus = async () => {
        setIsLoading(true)
        setIsStatusChecked(true)

        try {
          const { data } = await api.request({
            ...checkDeclarationStatus(id)
          })

          if (data?.status === true) {
            setIsSuccess(true)
          }
          setIsLoading(false)
        } catch (e) {
          const errorCode = e?.response?.data?.errorType

          setIsLoading(false)
          setIsSuccess(false)

          if (errorCode === ApiError.ALREADY_ACTIVE_DECLARATION) {
            store.dispatch(push(`/${i18next.language}/make-consultation?showActiveDeclarationPopup=1`))
          } else if (errorCode === ApiError.DIGITAL_SIGNATURE_ERROR) {
            setErrorCode(ApiError.DIGITAL_SIGNATURE_ERROR)
          }
          else if (errorCode === ApiError.DIGITAL_SIGNATURE_REDIRECT_URL_MISSING) {
            setErrorCode(ApiError.DIGITAL_SIGNATURE_REDIRECT_URL_MISSING)
          }
          else if (errorCode === ApiError.DIGITAL_SIGNATURE_IDENTITY_ERROR) {
            setErrorCode(ApiError.DIGITAL_SIGNATURE_IDENTITY_ERROR)
          }
        }

        setIsLoading(false)
      }

      getDeclarationStatus()
    } else {
      const setSession = async () => {
        try {
          const { data } = await api.request(
            {
              method: "GET",
              url: `${ssoMiddlewareHost}/users?disableTokenRefresh=true`,
              withCredentials: true,
            }
          )
          if (data && Object.keys(data).length > 0) {
            const sessionData = {
              id: data.token.sub,
              token: data.token.accessToken,
              refresh_token: data.token.refreshToken,
            }

            dispatch(initSession(sessionData))
            analytics.sendEventWithDefaultParams(LogEventType.MIDDLEWARE_USERS_SUCCESS)
          }
        } catch (error) {}
      }

      setSession()
    }
  }, [userData])

  if (isLoading) {
    return <LoaderBox />
  }

  if (isSuccess) {
    dispatch(setPozDeclarationActiveStatus())
    return <DeclarationSignSuccess/>
  }

  return <DeclarationSignFailedPage errorCode={errorCode}/>
}

export default DeclarationSignStatusPage