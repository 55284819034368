import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

type Props = {
  street?: string;
  houseNumber?: string;
  flatNumber?: string;
  postalCode?: string;
  city?: string;
  country?: string;
}

const AddressTemplate: FC<Props> = ({
  street,
  houseNumber,
  flatNumber,
  postalCode,
  city,
  country,
}) => {
  const {t} = useTranslation()

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Typography
        color='textSecondary'
      >
        { street ? `${t("user:street")}: ${street}, ` : "" }
        { houseNumber ? `${t("user:houseNumber")}: ${houseNumber}, ` : "" }
        { flatNumber ? `${t("user:apartmentNumber")}: ${flatNumber}, ` : "" }
      </Typography>
      <Typography
        color='textSecondary'
      >
        { postalCode ? `${t("user:postalCode")}: ${postalCode}, ` : "" }
        { city ? `${t("user:city")}: ${city}, ` : "" }
        { country ? `${t("user:country")}: ${country}, ` : "" }
      </Typography>
    </Box>
  )
}

export default AddressTemplate
