import React, { FC } from "react"
import { Typography } from "@material-ui/core"

import anxietyImg
  from "../../../../../assets/images/healthPrevention/mentalHealth/anxiety.png"
import HealthPreventionSelfImprovement, {
  HealthPreventionSelfImprovementContentBox,
  HealthPreventionSelfImprovementHeaderBox
} from "../../../../../components/healthPrevention/selfImprovement/HealthPreventionSelfImprovement.component"
import ImageByIntegrationType from "../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { RoutePath } from "../../../../../routes/Routes.types"

import { mentalHealthSelfImprovementBoxItems } from "./SelfImprovementLowMood"

const MentalHealthSelfImprovementAnxietyPage: FC = () => {
  const translationKeyPrefix = "mentalHealth:selfImprovement:items:anxiety"

  const getImage = (src: string, path: string) => {
    return <ImageByIntegrationType imageSrc={src} alt={path} imagePath={path}/>
  }

  const headerBox: HealthPreventionSelfImprovementHeaderBox = {
    title: `${translationKeyPrefix}:title`,
    content: `${translationKeyPrefix}:body`,
    redirectRoute: RoutePath.MENTAL_HEALTH_SELF_IMPROVEMENT,
    image: getImage(anxietyImg, "anxiety.png"),
    backBtnLabel: "consultation:back",
  }

  const contentBox: HealthPreventionSelfImprovementContentBox = {
    content: <>
      <Typography>
        <strong>Czym Jest Lęk?</strong>
        <br /><br />
        Lęk jest silnym uczuciem niepokoju, niepewności i obawy. Może on pojawić się w odpowiedzi na różne sytuacje życiowe, takie jak zagrożenia, wyzwania czy niepewność co do przyszłości. W swojej naturalnej formie, lęk pełni funkcję ochronną, mobilizując organizm do walki lub ucieczki w obliczu rzeczywistego zagrożenia.
        <br /><br />
        <strong>Funkcje Lęku:</strong>
        <ul>
          <li>Mobilizacja do działania w obliczu zagrożenia.</li>
          <li>Wzrost czujności i uwagi w sytuacjach stresujących.</li>
          <li>Sygnał ostrzegawczy przed potencjalnym niebezpieczeństwem.</li>
          <li>Motywacja do poszukiwania rozwiązania problemu.</li>
        </ul>
        <strong>Formy Lęku:</strong>
        <ul>
          <li><strong>Lęk Społeczny:</strong> Obawa przed oceną innych osób i wystąpieniem w sytuacjach społecznych.</li>
          <li><strong>Lęk Specyficzny:</strong> Lęk związany z konkretnymi sytuacjami lub obiektami, np. lęk przed lataniem, pająkami, czy wysokościami.</li>
          <li><strong>Lęk Ogólny:</strong> Stały, przewlekły lęk dotyczący różnych aspektów życia, który może być trudny do określenia konkretnych przyczyn.</li>
          <li><strong>Lęk Związany z Traumą:</strong> Lęk wywołany traumatycznymi doświadczeniami z przeszłości, np. wypadkami, przemocą czy utratą bliskiej osoby.</li>
        </ul>
        <strong>Sposoby Radzenia Sobie z Lękiem:</strong>
        <ul>
          <li><strong>Zrozumienie Lęku:</strong> Poznanie źródeł i mechanizmów lęku może pomóc w radzeniu sobie z nim skuteczniej.</li>
          <li><strong>Praktyki Relaksacyjne:</strong> Ćwiczenia oddechowe, medytacja czy joga mogą pomóc w redukcji napięcia i stresu związanego z lękiem.</li>
          <li><strong>Edukacja i Terapia:</strong> Korzystanie z pomocy profesjonalistów, takich jak psychoterapeuci czy terapeuci poznawczo-behawioralni, może pomóc w radzeniu sobie z lękiem skutecznie.</li>
          <li><strong>Wsparcie Społeczne:</strong> Dzielenie się swoimi uczuciami z bliskimi osobami i szukanie wsparcia ze strony społeczności może pomóc w złagodzeniu emocjonalnego obciążenia.</li>
          <li><strong>Prowadzenie Zdrowego Trybu Życia:</strong> Regularna aktywność fizyczna, zdrowa dieta i odpowiedni sen mogą pomóc w utrzymaniu równowagi psychicznej i fizycznej, co z kolei może zmniejszyć podatność na lęki i stres.</li>
        </ul>
        Pamiętaj, że każdy może doświadczać lęku, ale istnieją sposoby radzenia sobie, które mogą pomóc przezwyciężyć wewnętrzne obawy i prowadzić bardziej satysfakcjonujące życie. Kluczowe jest podejście do lęku z otwartością, cierpliwością i determinacją.
      </Typography>
    </>
  }

  return <HealthPreventionSelfImprovement
    headerBox={headerBox}
    contentBox={contentBox}
    boxItems={mentalHealthSelfImprovementBoxItems}
  />
}

export default MentalHealthSelfImprovementAnxietyPage
