import React, {FC} from "react"
import {Typography} from "@material-ui/core"

import ListItemRadioButton from "../../../listItemRadioButton/listItemRadioButton.component"
import {useCreateFullPharmacyAddress, useCreateOpenHours} from "./PharmacyRadioButton.utils"
import {NearbyPharmacy} from "../../../../store/doz/doz.types"

interface PharmacyRadioButtonProps extends NearbyPharmacy {
  isChecked: boolean
  onClick: VoidFunction
}

const PharmacyRadioButton: FC<PharmacyRadioButtonProps> = ({
  isChecked,
  onClick,
  name,
  street,
  postcode,
  city,
  all_day,
  hours_week,
  hours_saturday,
  hours_sunday,
}) => {
  const address = useCreateFullPharmacyAddress(street, postcode, city)
  const openHours = useCreateOpenHours(all_day, hours_week, hours_saturday, hours_sunday)

  return (
    <ListItemRadioButton
      isChecked={isChecked}
      onClick={onClick}
      name={name}
    >
      <Typography variant="body2">
        {address}
      </Typography>
      <Typography variant="body2">
        {openHours}
      </Typography>
    </ListItemRadioButton>
  )
}

export default PharmacyRadioButton
