import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useSendMessageFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up("md")]: {
        maxWidth: "70%",
        padding: theme.spacing(0),
      },
    },
    textField: {
      ...theme.typography.body1,
      border: "none",
      background: theme.palette.grey["A100"],
      padding: theme.spacing(1.5),
    },
  })
)
