import React, { FC, useEffect, useState } from "react"

import { useGetWordpressQuery } from "../../../../store/wordpress/wordpress.api"
import { HealthPreventionLibraryItem } from "../../../../components/healthPrevention/library/HealthPreventionLibrary.component"
import HealthPreventionLibraryItems
  from "../../../../components/healthPrevention/library/items/HealthPreventionLibraryItems.component"
import Loader from "../../../../components/loader/Loader.component"
import { RoutePath } from "../../../../routes/Routes.types"

const MentalHealthWebinarPage: FC = () => {
  const [webinarItems, setWebinarItems] = useState<HealthPreventionLibraryItem[]>([])
  const { data, isLoading } = useGetWordpressQuery({
    query: "webinars&_embed=1&_fields=id,link,excerpt,content,_embedded,_links,title"
  })

  useEffect(() => {
    if (!data || isLoading) {
      return
    }

    const webinarItems: HealthPreventionLibraryItem[] = data.map((item) => ({
      title: item.title.rendered,
      content: item.content.rendered,
      url: item.link,
      image: item._embedded["wp:featuredmedia"][0].source_url
    }))
    setWebinarItems(webinarItems)
  }, [data, isLoading])

  if (isLoading) {
    return <Loader/>
  }

  return <HealthPreventionLibraryItems
    redirectRoute={RoutePath.MENTAL_HEALTH}
    items={webinarItems}
    btnLabel={"mentalHealth:goToWebinar"}
  />
}

export default MentalHealthWebinarPage
