import React, {FC} from "react"
import {Box, Hidden, Typography} from "@material-ui/core"
import clsx from "clsx"

import {ChatMessageMessageType, ConsultationChatItem} from "../ConsultationChat.types"
import {useConsultationChatMessageItemStyles} from "./ConsultationChatMessageItem.styles"

import {parseMessageCreatedAt} from "./ConsultationChatMessageItem.config"

interface PatientConsultationChatMessageItemProps extends ConsultationChatItem {
  isConsultationReasonMessage: boolean;
}

const PatientConsultationChatMessageItemComponent: FC<PatientConsultationChatMessageItemProps> = (
  {
    messageRaw,
    createdAt,
    message,
    isConsultationReasonMessage,
  }
) => {
  const classes = useConsultationChatMessageItemStyles()
  const time = parseMessageCreatedAt(createdAt)

  return (
    <Box className={clsx(
      classes.messageItemBox,
      classes.rightAlignBoxMessage,
      classes.widthConditions,
      message === ChatMessageMessageType.NEW_SENDING_PATIENT_MESSAGE && classes.patientMessageSending
    )}>
      { time && !isConsultationReasonMessage && (
        <Hidden smDown>
          <Typography className={classes.messageTime}>
            { time }
          </Typography>
        </Hidden>
      )}

      <Box
        ml={2}
        className={isConsultationReasonMessage 
          ? clsx(
            classes.cloudMessageWrapper,
            classes.cloudPatientMessageWrapper,
            classes.cloudConsultationReasonMessage,
          )
          : clsx(
            classes.cloudMessageWrapper,
            classes.cloudPatientMessageWrapper,
          )}
      >
        <Typography className="break-spaces">
          { messageRaw }
        </Typography>
      </Box>
    </Box>
  )
}

export default PatientConsultationChatMessageItemComponent
