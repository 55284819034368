import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import AppDialog from "../../common/appDialog/AppDialog.component"

type SickLeaveRejectedPopupProps = {
  open: boolean;
  onClose(): void;
}

const SickLeaveRejectedPopup: FC<SickLeaveRejectedPopupProps> = ({open, onClose}) => {
  const {t} = useTranslation()

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={t("consultation:sickLeaveRejectedTitle")}
      closeButtonText={t("close")}
      maxWidth={"xs"}
    >
      <Box mb={1}>
        <Typography>
          {t("consultation:sickLeaveRejectedInfo")}
        </Typography>
      </Box>
    </AppDialog>
  )
}

export default SickLeaveRejectedPopup
