import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Redirect} from "react-router"
import {Box, Container, Theme, Typography, useMediaQuery} from "@material-ui/core"

import {PASSWORDLESS_IS_VERIFY_KEY} from "../../app.config"
import {SearchParams} from "../../enums/SearchParams"
import {SsoErrorCode} from "../../enums/SsoErrorCode"
import withBaseLayout from "../../hoc/withBaseLayout"
import {useRemoveIsFirstSessionInvoked} from "../../hooks/useRemoveIsFirstSessionInvoked"
import LangDropdowndOnBasePage from "../../components/common/langDropdown/LangDropdownOnBasePage.component"
import PageSection from "../../components/common/pageSection/PageSection.component"
import VerifyUserBirthday from "./verifyUserBirthday/VerifyUserBirthday.component"
import {useLoginMagicLinkPageStyles} from "../loginMagicLink/LoginMagicLinkPage.styles"

const PasswordlessBirthdayVerify: FC = () => {
  useRemoveIsFirstSessionInvoked()

  const {t, i18n} = useTranslation()
  const classes = useLoginMagicLinkPageStyles()
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"))

  if (!sessionStorage.getItem(PASSWORDLESS_IS_VERIFY_KEY)) {
    return (
      <Redirect
        to={
          `/${i18n.language}/login?${SearchParams.SSO_ERROR_CODE}=${SsoErrorCode.PASSWORDLESS_LOGIN_VERIFY_SESSION_EXPIRED}`
        }
      />
    )
  }

  return (
    <Container
      maxWidth="sm"
      className={classes.root}
    >
      <PageSection
        titleNode={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <Typography
              variant={isMdUp ? "h2" : "h4"}
              component="h2"
            >
              {t("login:logIn")}
            </Typography>

            <LangDropdowndOnBasePage/>
          </Box>
        }
      >
        <VerifyUserBirthday/>
      </PageSection>
    </Container>
  )
}

export default withBaseLayout(PasswordlessBirthdayVerify)
