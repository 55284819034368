import i18next from "i18next"
import * as yup from "yup"

import { validateNip } from "../../utils/nipValidation"
import { YesNoAnswer } from "./ExtraQuestions/ExtraQuestions.types"

type schemaType = {
  fields: {
    [fieldKey: string]: string
  }
}

export const sickLeaveSummarySurveySchema = () => yup.object().shape({
  orderConfirmation1: yup.boolean().oneOf([true], i18next.t("errors:required")),
  orderConfirmation2: yup.boolean().oneOf([true], i18next.t("errors:required")),
  orderConfirmation3: yup.boolean().oneOf([true], i18next.t("errors:required")),
  orderConfirmation4: yup.boolean().oneOf([true], i18next.t("errors:required")),
})

export const sickLeaveSurveyPatientAddressSchema = () => {
  return yup.object().shape({
    street: yup.string().required(i18next.t("errors:required")),
    houseNumber: yup.string().required(i18next.t("errors:required")),
    postalCode: yup.string().required(i18next.t("errors:required")),
    city: yup.string().required(i18next.t("errors:required")),
  })
}

export const sickLeaveSurveySchema = () => {
  const minChars = 10
  const ailmentsOptionalFields = [
    "coldOrPain",
    "stomachIssues",
    "bladderIssues",
    "injuries",
    "backPain",
    "eyesIssues",
    "migraine",
    "stressReaction",
    "menses",
  ] 
  
  const optionalFieldsSchema = ailmentsOptionalFields.reduce((acc, key) => (
    { ...acc,
      [key]: yup
        .array()
        .when("ailments", {
          is: (ailments: string) => ailments,
          then: yup
            .array()
            .min(1, i18next.t("errors:required"))
            .of(yup.string().required())
            .required(i18next.t("errors:required"))
        })
    }), {}) 

  return yup.object().shape({
    nip: yup
      .array()
      .of(yup.object().shape({
        nipItem: yup
          .string()
          .test("valid-nip", i18next.t("errors:incorrectNipNumber"), (value) =>
            validateNip(value)
          )
          .required(i18next.t("errors:required"))
      }))
      .required(i18next.t("errors:required")),
    nip1: yup.string().required(i18next.t("errors:required")),
    nip2: yup.string().required(i18next.t("errors:required")),
    nip3: yup.string().required(i18next.t("errors:required")),
    nipConfirmation: yup.boolean().oneOf([true], i18next.t("errors:required")),
    street: yup.string().required(i18next.t("errors:required")),
    houseNumber: yup.string().required(i18next.t("errors:required")),
    postalCode: yup.string().required(i18next.t("errors:required")),
    city: yup.string().required(i18next.t("errors:required")),
    street_otherAddress: yup.string().required(i18next.t("errors:required")),
    houseNumber_otherAddress: yup.string().required(i18next.t("errors:required")),
    postalCode_otherAddress: yup.string().required(i18next.t("errors:required")),
    city_otherAddress: yup.string().required(i18next.t("errors:required")),
    uniformedServiceType: yup.string().required(i18next.t("errors:required")),
    studentConfirmation: yup.boolean().oneOf([true], i18next.t("errors:required")),
    companyName_foreignWorker: yup.string().required(i18next.t("errors:required")),
    nip_foreignWorker: yup.string().required(i18next.t("errors:required")),
    street_foreignWorker: yup.string().required(i18next.t("errors:required")),
    houseNumber_foreignWorker: yup.string().required(i18next.t("errors:required")),
    postalCode_foreignWorker: yup.string().required(i18next.t("errors:required")),
    city_foreignWorker: yup.string().required(i18next.t("errors:required")),
    foreignCompanyCheckbox1: yup.string().required(i18next.t("errors:required")),
    foreignCompanyCheckbox2: yup.string().required(i18next.t("errors:required")),
    // ExtraQuestions validation
    pregnant: yup.string().required(i18next.t("errors:required")),
    pregnancySickLeave: yup
      .string()
      .when("pregnant", {
        is: (pregnant: string) => pregnant === YesNoAnswer.YES,
        then: yup
          .string()
          .required(i18next.t("errors:required"))
      }),
    chronicDiseases: yup.string().required(i18next.t("errors:required")),
    chronicDiseasesGroup: yup
      .array()
      .when("chronicDiseases", {
        is: (chronicDiseases: string) => chronicDiseases === YesNoAnswer.YES,
        then: yup
          .array()
          .min(1, i18next.t("errors:required"))
          .of(yup.string().required())
          .required(i18next.t("errors:required"))
      }),
    allergy: yup.string().required(i18next.t("errors:required")),
    allergyDetails: yup
      .string()
      .when("allergy", {
        is: (allergy: string) => allergy === YesNoAnswer.YES,
        then: yup
          .string()
          .required(i18next.t("errors:required"))
          .min(minChars, i18next.t("errors:minChars", { minChars }))
      }),
    regularDrugs: yup.string().required(i18next.t("errors:required")),
    regularDrugsDetails: yup
      .string()
      .when("regularDrugs", {
        is: (regularDrugs: string) => regularDrugs === YesNoAnswer.YES,
        then: yup
          .string()
          .required(i18next.t("errors:required"))
          .min(minChars, i18next.t("errors:minChars", { minChars }))
      }),
    duringSickLeave: yup.string().required(i18next.t("errors:required")),
    ailments: yup.string().required(i18next.t("errors:required")),
    ailmentsTime: yup
      .string()
      .when("ailments", {
        is: (ailments: string) => ailments,
        then: yup
          .string()
          .required(i18next.t("errors:required"))
      }),
    ...optionalFieldsSchema,
    importantInformations: yup.string().min(minChars, i18next.t("errors:minChars", { minChars })),
    // e/o ExtraQuestions validation
  }).required().when((values: string, schema: schemaType) => {
    for (const key in schema.fields) {
      const currentField = document.getElementById(key)
  
      if (currentField === null) {
        delete schema.fields[key]
      }
    }
  })
}
