import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Box,Button, Link,Typography } from "@material-ui/core"
import clsx from "clsx"

import {showVpozPopupOnStartDisabled} from "../../../../app.config"
import useGoToConsultationPage from "../../../../hooks/useGoToConsultationPage"
import { analytics, LogEventType } from "../../../../services/analytics"
import {PozFormDetailedSource} from "../../../../services/analytics/PozFormDetailedSource.enum"
import { useNfzStatusInfoStyles } from "./NfzStatusInfo.styles"

interface NfzStatusOffInfoProps {
  handleClosePopover(): void;
  vPOZB2b: boolean;
  hidePopupBtn: boolean;
}

const NfzStatusOffInfo: FC<NfzStatusOffInfoProps> = ({handleClosePopover, vPOZB2b, hidePopupBtn}) => {
  const {t} = useTranslation()
  const classes = useNfzStatusInfoStyles()
  const { goToMakeConsultationPage } = useGoToConsultationPage({visitDatePicker: false, detailedSource: PozFormDetailedSource.PP_SETTINGS})

  const handleInsuranceInactiveButtonClick = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_NAVIGATION_BAR_POZ_CLICK)
    handleClosePopover()
    goToMakeConsultationPage()
  }

  const handleHidePopup = () => {
    localStorage.setItem(showVpozPopupOnStartDisabled, "true")
    handleClosePopover()
  }

  return (
    <>
      <Typography className={clsx(classes.description, "break-spaces")}>
        {t(vPOZB2b ? "insuranceInactiveInfoB2b" : "insuranceInactiveInfo")}
      </Typography>

      <Box
        mt={3}
        mb={1}
        display="flex"
        justifyContent="center"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleInsuranceInactiveButtonClick}
        >
          {t("insuranceInactiveButtonText")}
        </Button>
      </Box>
      {
        hidePopupBtn && (
          <Box
            display="flex"
            justifyContent="center"
            mt={3}
          >
            <Link
              className={classes.disablePopupBtn}
              variant="body2"
              color="textSecondary"
              underline="none"
              onClick={handleHidePopup}
            >
              {t("defaultTranslations:doNotShowAgain")}
            </Link>
          </Box>
        )
      }
    </>
  )
}

export default NfzStatusOffInfo
