import React, { FC } from "react"
import { Typography } from "@material-ui/core"

import addictionsImg
  from "../../../../../assets/images/healthPrevention/mentalHealth/addictions.png"
import HealthPreventionSelfImprovement, {
  HealthPreventionSelfImprovementContentBox,
  HealthPreventionSelfImprovementHeaderBox
} from "../../../../../components/healthPrevention/selfImprovement/HealthPreventionSelfImprovement.component"
import ImageByIntegrationType from "../../../../../components/imageByIntegrationType/ImageByIntegrationType"
import { RoutePath } from "../../../../../routes/Routes.types"

import { mentalHealthSelfImprovementBoxItems } from "./SelfImprovementLowMood"

const MentalHealthSelfImprovementAddictionsPage: FC = () => {
  const translationKeyPrefix = "mentalHealth:selfImprovement:items:addictions"

  const getImage = (src: string, path: string) => {
    return <ImageByIntegrationType imageSrc={src} alt={path} imagePath={path}/>
  }

  const headerBox: HealthPreventionSelfImprovementHeaderBox = {
    title: `${translationKeyPrefix}:title`,
    content: `${translationKeyPrefix}:body`,
    redirectRoute: RoutePath.MENTAL_HEALTH_SELF_IMPROVEMENT,
    image: getImage(addictionsImg, "addictions.png"),
    backBtnLabel: "consultation:back",
  }

  const contentBox: HealthPreventionSelfImprovementContentBox = {
    content: <>
      <Typography>
        <strong>Czym Jest Uzależnienie?</strong>
        <br /><br />
        Uzależnienie to stan, w którym osoba traci kontrolę nad określonym zachowaniem lub substancją, pomimo negatywnych konsekwencji dla zdrowia, pracy, czy relacji. Może to być uzależnienie od substancji chemicznych, takich jak alkohol, narkotyki czy nikotyna, jak również od zachowań, np. uzależnienie od hazardu, internetu, czy jedzenia.
        <br /><br />
        <strong>Rodzaje Uzależnień:</strong>
        <ul>
          <li><strong>Uzależnienie od Substancji:</strong> Dotyczy używania substancji psychoaktywnych, takich jak alkohol, narkotyki, czy leki.</li>
          <li><strong>Uzależnienie Behawioralne:</strong> Związane z nadmiernym zaangażowaniem w określone zachowania, np. hazard, kompulsywne jedzenie, czy nadużywanie internetu.</li>
          <li><strong>Uzależnienie od Relacji:</strong> Polega na silnej zależności emocjonalnej od innej osoby, często zaniedbując własne potrzeby i zdrowie psychiczne.</li>
        </ul>
        <br />
        <strong>Objawy Uzależnienia:</strong>
        <ul>
          <li>Trudności w kontrolowaniu zachowania lub używaniu substancji.</li>
          <li>Konieczność zwiększania ilości substancji lub częstotliwości zachowań, aby uzyskać pożądany efekt.</li>
          <li>Wycofanie się z życia społecznego i zainteresowań.</li>
          <li>Wzrost uczucia lęku, depresji czy irytacji w przypadku braku substancji lub możliwości wykonania zachowania.</li>
          <li>Problemy zdrowotne, finansowe i prawne związane z uzależnieniem.</li>
        </ul>
        <br />
        <strong>Sposoby Pomocy:</strong>
        <ul>
          <li><strong>Terapia Indywidualna:</strong> Psychoterapia może pomóc w zrozumieniu przyczyn uzależnienia oraz opracowaniu strategii radzenia sobie z nim.</li>
          <li><strong>Grupy Wsparcia:</strong> Udział w grupach wsparcia, takich jak np. Anonimowi Alkoholicy, może zapewnić wsparcie emocjonalne i motywację do zmiany.</li>
          <li><strong>Leczenie Farmakologiczne:</strong> W przypadku uzależnień od substancji, leczenie farmakologiczne może być pomocne w zmniejszeniu objawów abstynencji i stabilizacji stanu psychicznego.</li>
          <li><strong>Wsparcie Społeczne:</strong> Wspieranie przez bliskich osób i rodzinę jest kluczowe dla powodzenia procesu rekonwalescencji.</li>
          <li><strong>Zmiana Środowiska:</strong> Czasami konieczne może być zmiana otoczenia, aby uniknąć bodźców wywołujących uzależnienie i zapewnić świeży start.</li>
        </ul>
        <br />
        Pamiętaj, że uzależnienie jest chorobą, która wymaga profesjonalnej pomocy. Nie wahaj się szukać wsparcia i zgłaszać się po pomoc, jeśli masz trudności z kontrolowaniem swojego zachowania lub stosowaniem substancji. Kluczowe jest podjęcie kroków w kierunku zdrowia i dobrobytu.
      </Typography>
    </>
  }

  return <HealthPreventionSelfImprovement
    headerBox={headerBox}
    contentBox={contentBox}
    boxItems={mentalHealthSelfImprovementBoxItems}
  />
}

export default MentalHealthSelfImprovementAddictionsPage
