import {gql} from "@apollo/client"

export const GET_MESSAGES_FOR_THREAD = gql`
  subscription getMessagesForThread($threadId: uuid!) {
    chatMessagesAggregate: chat_messages_aggregate(order_by: {created_at: asc}, where: {thread_id: {_eq: $threadId}}) {
      nodes {
        createdAt: created_at
        id
        body
        senderId: sender_id
      }
    }
  }
`