import React, {FC} from "react"

import SidebarLessLayout from "../layout/SidebarLessLayout"

interface WithSidebarLessLayoutProps {}

// eslint-disable-next-line react/display-name
const withSidebarLessLayout = <T extends WithSidebarLessLayoutProps>(WrappedComponent: FC<T & WithSidebarLessLayoutProps>): FC<T> => (props: T) => {
  return (
    <SidebarLessLayout>
      <WrappedComponent {...props} />
    </SidebarLessLayout>
  )
}

export default withSidebarLessLayout
