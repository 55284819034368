import {useTranslation} from "react-i18next"

import {ActionType} from "../../../api/queries/getUserActionById"

export const useRewardInfo = (type: ActionType, points: number) => {
  const { t } = useTranslation()

  switch (type) {
    case ActionType.REMOTE_CONSULTATION:
    case ActionType.MEDICAL_EXAMINATION:
      return t("healthPlanner:remoteConsultationRewardInfo", { points })
    case ActionType.FILL_QUESTIONNAIRE:
      return t("healthPlanner:fillQuestionnaireRewardInfo", { points })
    case ActionType.MEASUREMENT_LOG:
      return t("healthPlanner:measurementLogRewardInfo", { points })
    case ActionType.SIGN_DECLARATION:
      return t("healthPlanner:signDeclarationRewardInfo", { points })
    case ActionType.E_PRESCRIPTION:
      return t("healthPlanner:ePrescriptionRewardInfo", { points })
    case ActionType.YOUR_TASK:
      return t("healthPlanner:yourTaskRewardInfo")
    default:
      return ""
  }
}
