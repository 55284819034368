import React, {FC} from "react"
import {Box,Container} from "@material-ui/core"

import PageFooter from "../components/pageFooter/PageFooter.component"
import TopBar from "../components/topBar/TopBar.component"
import useLayoutStyles from "./Layout.styles"

import BaseLayout from "./BaseLayout"

interface SidebarLessLayoutProps {}

const SidebarLessLayout: FC<SidebarLessLayoutProps> = ({ children }) => {
  const classes = useLayoutStyles()

  return (
    <BaseLayout className="defaultLayout">

      <TopBar />

      <Box className={classes.pageContainerWithFixedTopBar}>
        <Box
          className={classes.containerWrapper}
        >
          <Container
            maxWidth="lg"
            className={classes.contentContainer}
          >
            <Box>
              { children }
            </Box>
          </Container>
        </Box>
      </Box>

      <PageFooter/>
    </BaseLayout>
  )
}

export default SidebarLessLayout
