import {gql} from "@apollo/client"

export const INSERT_USER_DISEASES = gql`
  mutation InsertUserDisease($userDiseases: [users_diseases_insert_input!]!) {
    insertUsersDiseases: insert_users_diseases(objects: $userDiseases){
      returning {
        id
      }
    }
  }
`

export type InsertUsersDiseasesResponse = {
  insertUsersDiseases: {
    returning: {
      id: string;
    }[]
  }
}

export type InsertUsersDiseasesVariables = {
  userDiseases: {
    disease_id: string;
  }[]
}