import i18n from "i18next"

import {getMedicalDocumentsConfig} from "../../api/routes"
import {v4Service} from "../store.service"
import {ConsultationListItemModel} from "../../components/consultation/Consultation.types"
import {MedicalDocuments} from "../../components/documentation/Documentation.type"

export const consultationDocumentationApi = v4Service.injectEndpoints({
  endpoints: build => ({
    getConsultationDocumentation: build.query<
    MedicalDocuments,
    ConsultationListItemModel["id"]
    >({
      query: (consultationId) => ({
        ...getMedicalDocumentsConfig,
        params: {
          consultation: consultationId,
          lang: i18n.language
        }
      }),
      transformResponse: (response: { items: MedicalDocuments }) => response.items
    })
  })
})

export const { useGetConsultationDocumentationQuery } = consultationDocumentationApi
