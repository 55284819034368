import React, {FC} from "react"

import Checkboxes from "./checkboxes/Checkboxes.component"
import Picker from "./picker/Picker.component"
import TextField from "./textField/TextField.component"
import TextFields from "./textFields/TextFields.component"
import {Answer, CompletedAnswer, InputType} from "../../GenericStepForm.types"

type QuestionInputProps = {
  inputType: InputType;
  onChange: (completedAnswer: CompletedAnswer[]) => void
  answers: Answer[];
  errors: string[];
  unit?: string;
  value: CompletedAnswer[];
}

const QuestionInput: FC<QuestionInputProps> = ({ 
  inputType,
  onChange,
  errors,
  answers,
  value,
  unit
}) => {
  //TODO: https://telemedico.atlassian.net/browse/LESS-1615
  if (inputType === InputType.TEXT_FIELD && answers.length > 1) {
    return (
      <TextFields
        errors={errors}
        answers={answers}
        onChange={onChange}
        value={value}
        unit={unit}
      />
    )
  }

  switch (inputType) {
    case InputType.TEXT_FIELD:
      return (
        <TextField
          errors={errors}
          unit={unit}
          onChange={onChange}
          value={value}
        />
      )
    case InputType.TEXT_FIELDS:
      return (
        <TextFields
          errors={errors}
          answers={answers}
          onChange={onChange}
          value={value}
          unit={unit}
        />
      )
    case InputType.CHECKBOXES:
      return (
        <Checkboxes
          errors={errors}
          answers={answers}
          onChange={onChange}
          value={value}
        />
      )
    case InputType.PICKER:
      return (
        <Picker
          errors={errors}
          answers={answers}
          onChange={onChange}
          value={value}
        />
      )
    case InputType.NUMBER:
      return (
        <TextField
          errors={errors}
          unit={unit}
          onChange={onChange}
          value={value}
          type="number"
        />
      )
    case InputType.NUMBERS:
      return (
        <TextFields
          errors={errors}
          answers={answers}
          onChange={onChange}
          value={value}
          unit={unit}
          type="number"
        />
      )
    case InputType.TEXTAREA:
      return (
        <TextField
          errors={errors}
          unit={unit}
          onChange={onChange}
          value={value}
          fullWidth
          multiline
          minRows={5}
          dataTestId="textArea"
        />
      )
    case InputType.DATE:
      return (
        <TextField
          errors={errors}
          unit={unit}
          onChange={onChange}
          value={value}
          type="date"
        />
      )
    default:
      return null
  }
}

export default QuestionInput
