import React, {FC, MouseEvent, ReactElement, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import { useSelector } from "react-redux"
import { ClickAwayListener, List, Popover} from "@material-ui/core"
import Box from "@material-ui/core/Box"

import useIsB2BClinic from "../../../../../hooks/useIsB2BClinic"
import { selectUser } from "../../../../../store/user/user.selectors"
import NestedMenuItem from "./NestedMenuItem.component"
import {NestedMenuListItem} from "./NestedMenu.types"
import {useNestedMenuStyles} from "./NestedMenu.styles"

interface NestedMenuProps {
  trigger: ReactElement;
  menuListItemsArray: NestedMenuListItem[]
  isEnabled?: boolean
}

const NestedMenu: FC<NestedMenuProps> = ({trigger,menuListItemsArray, isEnabled=true}) => {
  const {t} = useTranslation()
  const classes = useNestedMenuStyles()
  const { B2BClinic } = useIsB2BClinic()
  const userData = useSelector(selectUser)
  const menuListItems = useMemo(() => {
    return B2BClinic
      ? menuListItemsArray.filter(item => item.B2BClinicVisibility && item.featureEnabled)
      : menuListItemsArray
  }, [userData?.isPozDeclarationActive])

  /* menu popover */
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = !!anchorEl

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    if (!!userData) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  /* e/o menu popover */

  if (!menuListItems.length || !isEnabled) {
    return null
  }
    
  return (
    <ClickAwayListener onClickAway={handleMenuClose}>
      <>
        <div onClick={handleMenuOpen}>
          {trigger}
        </div>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            paper: classes.popoverPaper
          }}
          BackdropProps={{
            className: classes.backdrop
          }}
        >
          <List
            component="nav"
            aria-label={t("consultationMenu")}
          >
            {menuListItems
              .filter(item => item.featureEnabled)
              .map((listItem) => (
                <NestedMenuItem
                  key={listItem.text}
                  listItem={listItem}
                  handleMenuClose={handleMenuClose}
                />
              ))}
          </List>
        </Popover>
      </>
    </ClickAwayListener>
  )
}

export default NestedMenu
