import i18next from "i18next"
import * as yup from "yup"

import { UserContactDataFormValues } from "./UserContactData.types"

export const userContactDataValidationSchema = (): yup.SchemaOf<UserContactDataFormValues> => yup.object().shape({
  email: yup.string()
    .required(i18next.t("errors:required"))
    .email(i18next.t("errors:invalidEmail")),
  phoneNumber: yup.object().shape({
    country: yup.string()
      .defined()
      .nullable(),
    number: yup.string()
      .defined()
      .required(i18next.t("errors:noPhoneNumberError"))
      .when("country", {
        is: (country: string) => country === "PL",
        then: yup
          .string()
          .test("phone number error",
            i18next.t("errors:phoneNumberError"),
            number => /^\d{9}$/.test(number),
          )

      })
      .required(i18next.t("errors:required")),
  })
})
