import {ChannelType} from "../../store/clinic/clinicSettings.types"
import {ConsultationChannelTypes, ConsultationWidgetVisitDatePicker, DetailedSourceWidgetType, MakeConsultationWidgetDataConfig} from "../../store/makeConsultationWidget/makeConsultationWidget.types"

export const mapDefaultChannelType = (defaultChannelType: ChannelType|string) => {
  switch (defaultChannelType) {
    case ChannelType.TYPE_CHAT:
      return ConsultationChannelTypes.CHAT
    case ChannelType.TYPE_PHONE:
      return ConsultationChannelTypes.PHONE
    case ChannelType.TYPE_VIDEO:
      return ConsultationChannelTypes.VIDEO
  }
}

export const setDetailedSource = (type: MakeConsultationWidgetDataConfig["visitDatePicker"]) => {
  switch (type) {
    case ConsultationWidgetVisitDatePicker.PRESCRIPTION_ONLY:
      return DetailedSourceWidgetType.PRESCRIPTION
    case ConsultationWidgetVisitDatePicker.SICK_LEAVE_ONLY:
      return DetailedSourceWidgetType.SICK_LEAVE
    case ConsultationWidgetVisitDatePicker.CONSBOOK_VISIT_DATA_PICKER:
      return DetailedSourceWidgetType.REMOTE
    default:
      return null
  }
}