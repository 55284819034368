import {createStyles, makeStyles} from "@material-ui/core"

export const useCollapseCheckboxControllerStyles = makeStyles((theme) => createStyles({
  accordion: {
    "&, &.MuiAccordion-root.Mui-expanded": {
      margin: theme.spacing(2, 0, 0),
    },
    "&.MuiAccordion-root:before": {
      backgroundColor: theme.palette.background.paper
    },
  },
  accordionSummary: {
    padding: 0,
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
  },
  accordionDetails: {
    padding: 0,
  },
}))
