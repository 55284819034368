import {Dispatch, useEffect, useRef, useState} from "react"

import {useAppSelector} from "../../../../hooks/storeHooks"
import {selectUserAddress} from "../../../../store/user/user.selectors"
import {objectKeys} from "../../../../utils/objectKeys"
import {Location} from "../../../../store/geolocation/geolocation.types"
import {UserAddressModel} from "../../../user/User.types"
import {Coordinates} from "../PharmacySearchEngine.types"
import {Address} from "./AddressSearchEngine.types"

const setNullFields = (userAddress: UserAddressModel): UserAddressModel => {
  const copyUserAddress = { ...userAddress }

  for (const key of objectKeys(copyUserAddress)) {
    if (copyUserAddress[key] === null) {
      copyUserAddress[key] = ""
    }
  }

  return copyUserAddress
}

export const useAddressManager = (): {
  address?: Address;
  fullAddress?: string;
  setAddress: Dispatch<Address>;
  isCoreAddressDataProvided: boolean;
} => {
  const userAddress = useAppSelector(selectUserAddress)
  const [address, setAddress] = useState<Address | undefined>(
    (userAddress?.city && userAddress.street)
      ? setNullFields(userAddress)
      : undefined
  )
  const isCoreAddressDataProvided = !!(address?.city && address?.street)
  const fullAddress = address
    ? `${address.city ? address.city + ", " : ""}${address.postal_code ? address.postal_code + ", " : ""}${address.street ? address.street + ", " : ""}${address.house_number ? address.house_number : ""}`
    : undefined

  return {
    address,
    setAddress,
    fullAddress,
    isCoreAddressDataProvided
  }
}

export const useInitializeCoordinates = (
  locations: Location[],
  isLoadingLocations: boolean,
  setCoordinates: Dispatch<Coordinates>,
  setLocationId: Dispatch<string | undefined>,
): void => {
  const isAnyInitialUserDataProvidedRef = useRef(true)
  const isAlreadyInitializedRef = useRef(false)
  const userAddress = useAppSelector(selectUserAddress)

  useEffect(() => {
    if (!userAddress) {
      isAnyInitialUserDataProvidedRef.current = false
      return
    }
    const isAnyInitialUserDataProvided = Object.values(userAddress).some(value => !!value)

    if (!isAnyInitialUserDataProvided) {
      isAlreadyInitializedRef.current = true
    }

    isAnyInitialUserDataProvidedRef.current = isAnyInitialUserDataProvided
  }, [])

  useEffect(() => {
    if (
      isLoadingLocations ||
      isAlreadyInitializedRef.current ||
      !isAnyInitialUserDataProvidedRef.current
    ) {
      return
    }

    isAlreadyInitializedRef.current = true

    if (locations?.length > 0) {
      setLocationId(locations[0].place_id)
      setCoordinates({
        gps_latitude: locations[0].lat,
        gps_longitude: locations[0].lon
      })
    }
  },[locations, isLoadingLocations])
}
