import { createStyles, makeStyles } from "@material-ui/core"

import { Theme } from "@material-ui/core/styles"

export const useDiaryMeasurementStyles = makeStyles((theme: Theme) => createStyles({
  boxCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(4, 2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: theme.spacing(8),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  measurementWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))