import {gql} from "@apollo/client"

import {ActionType} from "./getUserActionById"

export const GET_USER_ACTIONS_BY_ID = gql`
  query GetUserActions($actionIds: [uuid!]!) {
    userActions: users_actions(where: {action: {id: {_in: $actionIds}}}) {
      id
      deadline
      source
      completedAt: completed_at
      action {
        id
        points
        image_url
        name
        type
        fill_questionnaire {
          questionnaireId: questionnaire_id
        }
      }
    }
  }
`

export type UserAction = {
  id: string;
  deadline: string;
  source: string;
  completedAt: string | null;
  action: {
    id: string;
    points: number;
    imageUrl: string;
    name: string;
    type: ActionType;
    fill_questionnaire: {
      questionnaireId: string;
    } | null
  }
}

export type UserActionsResponse = {
  userActions: UserAction[];
}

export type GetUserActionsVariables = {
  actionIds: string[];
}
