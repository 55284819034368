import {gql} from "@apollo/client"

export type ReceiveAwardResponse = {
  receiveAward: {
    status: string;
  }
}

export type ReceiveAwardVariables = {
  awardId: string;
}

export const RECEIVE_AWARD = gql`
  mutation ReceiveAward(
    $awardId: uuid!,
  ) {
    receiveAward(award_id: $awardId) {
      status
    }
  }
`