import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"

import {useAppSelector} from "../../../hooks/storeHooks"
import {selectClinicSettings} from "../../../store/clinic/clinicSettings.selectors"
import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import {isNotNullish} from "../../../utils/isNotNullish"
import { getCustomExamPriceFormat,getExamPriceFormat } from "../Exam.utils"
import { ExamListItem } from "../examsSelect/ExamsSelect.types"
import {useOrderExamsListStyles} from "./OrderExamsList.styles"

interface OrderedExamsListProps {
  exams: (ExamListItem & { priceAfterDiscount?: number })[];
  removeExam(examId: number): void
}

const OrderedExamsList: FC<OrderedExamsListProps> = ({ exams, removeExam }) => {
  const { t } = useTranslation()
  const { clinicSignalIdunaSettings: { insuranceVerificationEnabled }, frontendSettings: { modules } } = useAppSelector(selectClinicSettings)
  const isInsuranceVerificationEnabled = insuranceVerificationEnabled || modules.includes("s7health")
  const classes = useOrderExamsListStyles()

  const examsTotalPrice = exams.reduce(
    (price, exam) => price + (exam.priceAfterDiscount ?? parseFloat(exam.labPrice)),
    0
  )

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow hover>
              <TableCell component="th"> {t("exam:index")} </TableCell>
              <TableCell component="th"> {t("exam:labName")} </TableCell>
              { !isInsuranceVerificationEnabled && <TableCell component="th" align="right"> {t("exam:labPrice")} </TableCell> }
              <TableCell component="th" align="right"> </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            { exams.map((exam, index) => (
              <TableRow hover key={exam.id}>
                <TableCell>
                  {index + 1}
                </TableCell>

                <TableCell>
                  {exam.name}
                </TableCell>

                {
                  !isInsuranceVerificationEnabled && (
                    <TableCell align="right">
                      {
                        isNotNullish(exam.priceAfterDiscount)
                          ? (
                            <div>
                              <s>{ getCustomExamPriceFormat(exam.labPrice) }</s>
                              <p className={classes.discountPrice}>
                                { getExamPriceFormat(exam.priceAfterDiscount) + "\u00A0zł" }
                              </p>
                            </div>
                          )
                          : <>{ getCustomExamPriceFormat(exam.labPrice) }</>
                      }
                    </TableCell>
                  )
                }

                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => removeExam(exam.id)}
                  >
                    <IconByIntegrationType iconName={"icon-trash"}/>
                    {t("remove")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {
        !isInsuranceVerificationEnabled && (
          <Box p={4} display="flex" justifyContent="space-between">
            <Typography>
              {t("exam:summary")}
            </Typography>

            <Typography>
              {t("exam:price")}: { getExamPriceFormat(examsTotalPrice) } zł
            </Typography>
          </Box>
        )
      }
    </>
  )
}

export default OrderedExamsList
