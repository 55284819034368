import React, { FC, useState } from "react"
import {Controller, useForm} from "react-hook-form"
import { useTranslation } from "react-i18next"
import {useSelector} from "react-redux"
import { Box, Button, TextField, Typography } from "@material-ui/core"
import clsx from "clsx"

import api from "../../api/api"
import {completeTwoFactorConfig, sendTwoFactorCodeConfig} from "../../api/routes"
import useGetFormValidationRules from "../../hooks/useGetFormValidationRules"
import store from "../../store"
import {selectLoginRedirectData} from "../../store/logIn/logIn.selectors"
import {clearRedirectData,logInSubmit, setRedirectData} from "../../store/logIn/logIn.slice"
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader.component"
import ButtonTextUnderlined from "../common/buttonTextUnderlined/ButtonTextUnderlined.component"
import PageSection from "../common/pageSection/PageSection.component"
import GlobalFormErrorMessage from "../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import {getDifferentRoleRedirectUrl, goToDedicatedLoginPage} from "../../pages/login/LoginPage.utils"
import { MethodTypes,SendAccessCodeSubmitData } from "./SendTwoFactorCode.types"
import {useSendTwoFactorCodeStyles} from "./SendTwoFactorCode.styles"

import SendTwoFactorCodeTittleNode from "./common/SendTwoFactorCodeTittleNode"
import {twoFactorSettings} from "./SendTwoFactorCode.config"
import {sendSmsCodeValidationSchema} from "./SendTwoFactorCode.validation"

interface SendSmsCodeFormProps {
  MFACode?: string | undefined;
  methodId?: string | undefined;
  methodType?: string | undefined;
  setTwoFactorFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const SendTwoFactorCodeForm: FC<SendSmsCodeFormProps> = ({MFACode, setTwoFactorFormVisible, methodId, methodType}) => {
  const { t } = useTranslation()
  const classes = useSendTwoFactorCodeStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const [globalError, setGlobalError] = useState<string>("")
  const [showResendCodeSuccessfullMsg, setShowResendCodeSuccessfullMsg] = useState<boolean>(false)
  const differentRoleRedirectData = useSelector(selectLoginRedirectData)

  const form = useForm<SendAccessCodeSubmitData>({
    mode: "all",
    resolver: useGetFormValidationRules(sendSmsCodeValidationSchema),
    defaultValues: {
      code: "",
      twoFactorId: MFACode || "",
    }
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    setLoading(true)
    store.dispatch(clearRedirectData())
    setGlobalError("")

    try {
      const {data} = await api.request({
        ...completeTwoFactorConfig,
        data: values
      })

      if (data?.token?.accessToken) {
        const redirectData = getDifferentRoleRedirectUrl(data?.token?.accessToken)

        if (redirectData?.redirectLoginUrl) {
          setGlobalError(`${t("login:useDedicatedLoginPanel")}`)

          await store.dispatch(setRedirectData(redirectData))
          setLoading(false)
          return false
        }

        await store.dispatch(logInSubmit(data?.token))
      }
    } catch (e) {
      console.error(e)
      if (e?.response) {
        setGlobalError(t("errors:This value is not valid."))
      }
    }

    setLoading(false)
  })

  const handleResendCode = async () => {
    setLoading(true)
    setGlobalError("")
    setShowResendCodeSuccessfullMsg(false)

    try {
      const triggerResendCode = await api.request({
        ...sendTwoFactorCodeConfig,
        data: {
          methodId,
          twoFactorId: MFACode,
        }
      })

      if (triggerResendCode?.status === twoFactorSettings.codeSentSuccessfully) {
        setShowResendCodeSuccessfullMsg(true)

        setTimeout(() => {
          setShowResendCodeSuccessfullMsg(false)
        }, twoFactorSettings.successMsgHideTimeout)

        setLoading(false)
      }
    } catch (e) {
      setGlobalError(t("errors:unknownError"))
      setLoading(false)
    }
  }

  const generateFormLabel = (methodType: string|undefined) => {
    if (!methodType) {
      return null
    }

    switch (methodType) {
      case MethodTypes.SMS:
        return t("login:enterCodeDescription")
      case MethodTypes.EMAIL:
        return t("login:enterEmailCodeDescription")
      default:
        return t("login:enterAppCodeDescription")
    }
  }

  return (
    <PageSection titleNode={
      <SendTwoFactorCodeTittleNode title="enterCode"/>
    }>
      <Box className={classes.sectionBox}>
        <Box mb={1}>
          {showResendCodeSuccessfullMsg && (
            <Box className={classes.sendCodeSuccess}>
              {t("login:codeSentSuccessfully")}
            </Box>
          )}
          <Typography variant={"body2"}>
            {generateFormLabel(methodType)}
          </Typography>
        </Box>

        {globalError && (
          <GlobalFormErrorMessage
            message={globalError}
            messageComponent={differentRoleRedirectData?.redirectLoginUrl ? (
              <ButtonTextUnderlined
                color="primary"
                onClick={() => goToDedicatedLoginPage(differentRoleRedirectData.redirectLoginUrl)}
              >
                <Typography variant="body1">
                  {differentRoleRedirectData.shortLoginUrl}
                </Typography>
              </ButtonTextUnderlined>
            ) : null}
          />
        )}

        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <Controller
            name="code"
            control={form.control}
            render={({
              field: {onChange, value},
              fieldState: {error, invalid}
            }) => (
              <TextField
                id="accessCode"
                label={t("forgotPassword:enterCode")}
                placeholder={t("forgotPassword:enterCode")}
                fullWidth
                disabled={loading}
                value={value}
                onChange={onChange}
                error={invalid}
                helperText={error?.message}
                required={true}
                autoFocus
              />
            )}
          />

          <Box className={classes.buttonsWrapper}>
            <Button
              disabled={loading}
              variant="outlined"
              color="primary"
              type="button"
              className={classes.button}
              onClick={() => setTwoFactorFormVisible(false)}
            >
              {t("forgotPassword:backToLoginPage")}
            </Button>

            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              startIcon={loading && <ButtonLoader position="prefix"/>}
            >
              {t("send")}
            </Button>
          </Box>
        </form>
        {
          methodType !== MethodTypes.AUTHENTICATOR && (
            <Button
              disabled={loading}
              variant="text"
              color="primary"
              onClick={() => handleResendCode()}
              className={clsx(classes.button, classes.resendButton)}
              startIcon={loading && <ButtonLoader position="prefix"/>}
            >
              {t("login:resendCode")}
            </Button>
          )
        }
      </Box>
    </PageSection>
  )
}

export default SendTwoFactorCodeForm
