import React, {FC} from "react"
import { Box, Typography, useTheme } from "@material-ui/core"

interface GlobalFormSuccessMessageProps {
  message: string;
  className?: string;
  messageComponent?: JSX.Element | JSX.Element[] | null;
}

const GlobalFormSuccessMessage: FC<GlobalFormSuccessMessageProps> = ({message, className, messageComponent}) => {
  const  theme = useTheme()
  return (
    <Box
      className={className}
      my={2}
      p={2}
      border={`1px solid ${theme.palette.success.main}`}
      borderRadius={theme.spacing(1)}
    >
      <Typography color="primary" className="break-spaces">
        {message}
        {messageComponent}
      </Typography>
    </Box>

  )
}

export default GlobalFormSuccessMessage
