import React, {FC, useEffect} from "react"
import {useFieldArray, useWatch} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {Typography} from "@material-ui/core"

import useClearField from "../../useClearFields.hook"
import CheckboxController from "../../../../../../components/CheckboxController/CheckboxController.component"
import FormSection from "../FormSection/FormSection.component"
import PrescriptionFields from "./PrescriptionFields/PrescriptionFields.component"
import { PatientsRequirementsStepData } from "../../../MedicalQuestionnaireStepperForm.types"

type Props = {
  perDrugLimit: number;
  totalDrugsLimit: number;
}
const PrescriptionSection: FC<Props> = ({ perDrugLimit,totalDrugsLimit }) => {
  const {t} = useTranslation()
  const isDoctorConsultationRequired = useWatch({
    name: "prescription.isDoctorConsultationRequired",
  })

  const { fields, remove } = useFieldArray<PatientsRequirementsStepData, "prescription.prescriptions">({
    name: "prescription.prescriptions"
  })
  useClearField(fields,remove, isDoctorConsultationRequired)

  return (
    <FormSection
      title={
        <Typography
          variant='h3'
        >
          <Trans
            i18nKey="medicalQuestionnaire:prescriptionSectionTitle"
          >
            0 <Typography variant='inherit' component='span' color='primary'>1</Typography>2
          </Trans>
        </Typography>
      }
      description={t("medicalQuestionnaire:prescriptionSectionDescription",{ totalDrugsLimit })}
    >
      {
        !isDoctorConsultationRequired && (
          <PrescriptionFields
            perDrugLimit = {perDrugLimit}
            totalDrugsLimit = {totalDrugsLimit}
          />
        )
      }
      <CheckboxController
        name="prescription.isDoctorConsultationRequired"
        label= {t("medicalQuestionnaire:referralCheckboxLabel")}
      />
    </FormSection>
  )
}

export default PrescriptionSection
