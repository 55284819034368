import { useSubscription } from "@apollo/client"

import { useAppSelector } from "../../../../../hooks/storeHooks"
import { selectUserId } from "../../../../../store/session/session.selectors"
import { ChatThreadsForUserResponseData, ChatThreadsForUserVariables } from "./getChatThreadsForUser.types"
import { SubscriptionHookOptions } from "@apollo/client/react/types/types"

import { GET_CHAT_THREADS_FOR_USER } from "./getChatThreadsForUser.query"

export const useGetChatThreadsForUser = (options?: SubscriptionHookOptions<ChatThreadsForUserResponseData, ChatThreadsForUserVariables>) => {
  const userId = useAppSelector(selectUserId)

  return useSubscription<ChatThreadsForUserResponseData, ChatThreadsForUserVariables>(
    GET_CHAT_THREADS_FOR_USER,
    {
      context: { clientName: "HealthPlanner" },
      skip: !userId,
      variables: { userId: userId ?? "" },
      ...options
    }
  )
}