import {useTranslation} from "react-i18next"

const MAX_LOW_RISK_PERCENTAGE = 0.35
const MAX_MEDIUM_RISK_PERCENTAGE = 0.65

export const useGetLabelByProbability = (probability = 0) => {
  const {t} = useTranslation()

  switch (true) {
    case probability <= MAX_LOW_RISK_PERCENTAGE:
      return t("healthPlanner:lowRisk")
    case MAX_LOW_RISK_PERCENTAGE < probability && probability < MAX_MEDIUM_RISK_PERCENTAGE:
      return t("healthPlanner:mediumRisk")
    default:
      return t("healthPlanner:highRisk")
  }
}