import { ConsultationListItemModel } from "../../components/consultation/Consultation.types"
import { MedicalDocuments } from "../../components/documentation/Documentation.type"
import {RootState} from "../store.types"
import { ConsultationDocumentationState } from "./consultationDocumentation.types"

export const selectAllConsultationDocumentation: ((state: RootState) => ConsultationDocumentationState["items"]) = ({consultationDocumentation}) => consultationDocumentation.items

export const selectConsultationDocumentationConsultationsIds: ((state: RootState) => ConsultationListItemModel["id"][]) = ({consultationDocumentation}) => Object.keys(consultationDocumentation.items)

export const selectConsultationDocumentationByConsultationId: ((
  state: RootState,
  consultationId: ConsultationListItemModel["id"]
) => MedicalDocuments | Record<string, never>) = (
  {consultationDocumentation},
  consultationId
) => consultationDocumentation.items[consultationId]

export const selectConsultationDocumentationLoading: ((state: RootState) => ConsultationDocumentationState["loading"]) = ({consultationDocumentation}) => consultationDocumentation.loading
