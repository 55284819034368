export interface SendAccessCodeSubmitData {
  code: string;
  twoFactorId: string;
}

export enum MethodTypes {
  SMS = "sms",
  EMAIL = "email",
  AUTHENTICATOR = "authenticator",
}
