import {useTranslation} from "react-i18next"
import {useSelector} from "react-redux"

import {selectSelectedConsultationLang} from "../store/consultations/consultations.selectors"
import {LabelForConsultationLanguageParam} from "../components/consultation/Consultation.types"

const useConsultationLang = (): {
  getLabel(key: string, params?: LabelForConsultationLanguageParam): string;
} => {
  const {i18n} = useTranslation()
  const consultationLanguage = useSelector(selectSelectedConsultationLang) || ""

  const getLabel = (key: string, params?: LabelForConsultationLanguageParam): string => {
    const consultationSelectLanguage = i18n.getFixedT(consultationLanguage)

    return consultationSelectLanguage(key, params ? params : {})
  }

  return {
    getLabel
  }
}

export default useConsultationLang