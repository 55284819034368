import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import RiskLevel from "./riskLevel/RiskLevel.component"
import {useRiskStyles} from "./Risk.styles"

type RiskProps = {
  probability: number;
}

const Risk: FC<RiskProps> = ({ probability }) => {
  const {t} = useTranslation()
  const classes = useRiskStyles()

  return (
    <Box className={classes.container}>
      <Typography
        variant="body2"
        className={classes.riskLabel}
      >
        {t("healthPlanner:diseasesRisk")}
      </Typography>
      <RiskLevel
        probability={probability}
      />
    </Box>
  )
}

export default Risk
