import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import { Box, InputLabel, MenuItem, TextField } from "@material-ui/core"

import { ComingEventType } from "../../../store/settings/settings.types"

interface ComingEventsTypeSelectProps {
  selectedItem: ComingEventType;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const ComingEventsTypeSelect: FC<ComingEventsTypeSelectProps> = (
  {
    selectedItem,
    handleChange,
  }) => {
  const {t} = useTranslation()

  return (
    <Box mb={2}>
      <Box>
        <InputLabel>
          { t("stagingSettings:selectComingConsultationType") }
        </InputLabel>
      </Box>

      <TextField
        variant="outlined"
        select
        value={selectedItem}
        onChange={handleChange}
        fullWidth
      >
        { Object.keys(ComingEventType).map((comingEventsType) => (
          <MenuItem
            key={comingEventsType}
            value={comingEventsType}
          >
            { t(`stagingSettings:comingConusltationType:${comingEventsType}`) }
          </MenuItem>
        ))}
      </TextField>
    </Box>
  )
}

export default ComingEventsTypeSelect
