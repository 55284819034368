import React, {FC} from "react"
import {Grid, Radio, Typography} from "@material-ui/core"

import {useLocationRadioButton} from "./LocationRadioButton.styles"

interface LocationRadioButtonProps {
  isChecked: boolean
  onChange: VoidFunction
}

const LocationRadioButton: FC<LocationRadioButtonProps> = ({
  children,
  isChecked,
  onChange,
}) => {
  const classes = useLocationRadioButton({ isChecked })
  return (
    <Grid
      className={classes.root}
      container
      direction="row"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid>
        <Radio
          className={classes.radioButton}
          checked={isChecked}
          onChange={onChange}
        />
      </Grid>
      <Grid>
        <Typography
          variant="subtitle1"
          color="textPrimary"
        >
          {children}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default LocationRadioButton
