import React, {FC} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"

import {useCreateExamOrderMutation} from "../../store/examReferrals/examReferrals.api"
import ActionButtons from "../actionButtons/ActionButtons.component"
import GlobalFormErrorMessage from "../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import HiddenInput from "../commonFormItems/hiddenInput/HiddenInput.component"
import AlabSearchEngine from "./alabSearchEngine/AlabSearchEngine.component"
import ExaminationDate from "./ExaminationDate/ExaminationDate.component"
import ExaminationList from "./ExaminationList/ExaminationList.component"
import FormField from "./FormField/FormField.component"
import SummaryView from "./summaryView/SummaryView.component"
import TermsOfServiceAndPrivacyPolicy from "./termsOfServiceAndPrivacyPolicy/TermsOfServiceAndPrivacyPolicy.component"
import {ExaminationOrder} from "../../store/examReferrals/examReferrals.types"
import {ConsultationExamReferral} from "../exam/Exam.types"
import {useOrderExaminationsByAlabForm} from "./OrderExaminationsByAlabForm.styles"

interface OrderExaminationsByAlabFormProps {
  referrals: ConsultationExamReferral[];
  consultationId: string;
  visitAtDate: string;
}

const OrderExaminationsByAlabForm: FC<OrderExaminationsByAlabFormProps> = ({
  referrals,
  consultationId,
  visitAtDate
}) => {
  const {t, i18n} = useTranslation()
  const classes = useOrderExaminationsByAlabForm()
  const [handleCreateExamOrder, { isLoading, isError, isSuccess }] = useCreateExamOrderMutation()
  const formMethods = useForm<ExaminationOrder>({
    defaultValues: {
      referrals: referrals.filter(referral => !referral.is_bought && referral.included_in_package),
      executeDate: new Date(),
      consultationId,
      language: i18n.language,
    }
  })

  if (isSuccess) {
    return (
      <SummaryView
        executeDate={formMethods.watch("executeDate")}
      />
    )
  }

  return (
    <FormProvider {...formMethods}>
      <form
        className={classes.root}
        onSubmit={formMethods.handleSubmit(handleCreateExamOrder)}
      >
        <HiddenInput name="consultationId" />
        <HiddenInput name="language" />
        <FormField
          title={`1. ${t("referrals:examinationList")}`}
        >
          <ExaminationList/>
        </FormField>
        <FormField
          title={`2. ${t("referrals:dateOfExamination")}`}
        >
          <ExaminationDate
            visitAtDate={visitAtDate}
          />
        </FormField>
        <FormField
          title={`3. ${t("referrals:nearbyLaboratory")}`}
        >
          <AlabSearchEngine/>
        </FormField>
        {
          isError && (
            <GlobalFormErrorMessage message={t("errors:unknownError")}/>
          )
        }
        <ActionButtons
          isLoading={isLoading}
        />
        <TermsOfServiceAndPrivacyPolicy/>
      </form>
    </FormProvider>
  )
}

export default OrderExaminationsByAlabForm
