import React, {ChangeEvent, FC, useState} from "react"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router"
import {Box, Button, MenuItem, Select, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import {format} from "date-fns"

import api from "../../../api/api"
import {patchChangeConsultationTerm} from "../../../api/routes"
import CountrySelect from "../../commonFormItems/countrySelect/CountrySelect.component"
import Loader from "../../loader/Loader.component"
import ConsultationChangeTermPopup from "./ConsultationChangeTermPopup.component"
import ConsultationSelectChannel from "./ConsultationSelectChannel.component"
import ConsultationTermDaySelection from "./ConsultationTermDaySelection.component"
import {getTimeRanges} from "../Consultation.utils"
import {ConsultationTermsListItems, ConsultationTermsListResponse} from "../Consultation.types"
import {useConsultationTermSelectionStyles} from "./ConsultationTermSelection.styles"

type ConsultationTermSelectionProps = {
  termsData: ConsultationTermsListResponse | null;
  consultationId: string;
  consultationLanguage: string;
  specializationId: string;
  consultationType: number;
  specializationName: string | null;
  simplifiedBooking?: boolean;
  getConsultationTerms(consultationType: number, language: string, specializationId: string, day: string): void;
}

const ConsultationTermSelection: FC<ConsultationTermSelectionProps> = ({
  specializationName,
  termsData,
  consultationId,
  consultationType,
  getConsultationTerms,
  consultationLanguage,
  specializationId,
  simplifiedBooking = false
}) => {
  const {t, i18n} = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const [loading, setLoading] = useState<boolean>(false)
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [termChangeSuccess, setTermChangeSuccess] = useState<boolean>(false)
  const [selectedDay, setSelectedDay] = useState<string>(format(new Date(), "yyyy-MM-dd"))
  const [selectedChannel, setSelectedChannel] = useState<number>(consultationType)
  const [currentCountry, setCurrentCountry] = useState<string>("")
  const [currentCountryError, setCurrentCountryError] = useState()
  const {push} = useHistory()
  const classes = useConsultationTermSelectionStyles()

  const handleOnChangeCountry = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentCountry(event.target.value)

    setCurrentCountryError(undefined)
  }

  const handleChangeConsultationTerm = async (newDate: Date) => {
    if (simplifiedBooking && !currentCountry) {
      setCurrentCountryError(t("errors:required"))

      return
    }

    setCurrentCountryError(undefined)

    const startAt = format(new Date(newDate), "yyyy-MM-dd HH:mm")

    setLoading(true)

    try {
      await api.request({
        ...patchChangeConsultationTerm(consultationId),
        data: {
          checkAvailableSlots: false,
          startAt,
          isMagicLinkFlow: true,
          channel: selectedChannel,
          currentCountry: simplifiedBooking ? currentCountry : undefined,
        },
      })
      setTermChangeSuccess(true)
      setPopupOpen(true)
    } catch (e) {
      setPopupOpen(true)
      setLoading(false)
    }
  }

  const handleClosePopup = () => {
    setPopupOpen(false)
  }

  return (
    <Box
      width="100%"
    >
      <Box
        mb={4}
      >
        <Typography
          variant="h4"
        >
          1. {t("consultation:specialization")}
          <Box
            mt={2}
          >
            <Select
              value={specializationName}
              disabled={true}
              variant="outlined"
              fullWidth
            >
              <MenuItem
                value={specializationName || ""}
              >
                {specializationName}
              </MenuItem>
            </Select>
          </Box>
        </Typography>
      </Box>
      <Box
        mb={4}
      >
        <Typography
          variant="h4"
        >
          2. {t("consultation:channel")}
        </Typography>
        <ConsultationSelectChannel
          consultationType={consultationType}
          getConsultationTerms={getConsultationTerms}
          consultationLanguage={consultationLanguage}
          specializationId={specializationId}
          setLoading={setLoading}
          setSelectedChannel={setSelectedChannel}
          selectedChannel={selectedChannel}
          loading={loading}
          selectedDay={selectedDay}
        />
      </Box>
      <Box
        mb={4}
        className={classes.daySelection}
      >
        <Typography
          variant="h4"
        >
          3. {t("consultation:term")}
        </Typography>
        <ConsultationTermDaySelection
          setSelectedDay={setSelectedDay}
          loading={loading}
          setLoading={setLoading}
          getConsultationTerms={getConsultationTerms}
          consultationLanguage={consultationLanguage}
          specializationId={specializationId}
          selectedChannel={selectedChannel}
          selectedDay={selectedDay}
        />
      </Box>
      {
        simplifiedBooking &&
        (
          <Box
            mb={4}
          >
            <Typography
              variant="h4"
            >
              {t("consultation:currentCountry")}
              <Box
                mt={2}
              >
                <CountrySelect
                  fullWidth
                  required
                  name="country"
                  value={currentCountry}
                  onChange={handleOnChangeCountry}
                  error={currentCountryError !== undefined}
                  helperText={currentCountryError}
                  label={t("consultation:currentCountryPlaceholder")}
                />
              </Box>
            </Typography>
          </Box>
        )
      }
      {loading && <Loader/>}
      {!termsData?.items?.length && !loading && (
        <Box
          ml={isMdUp ? 1 : 0}
          mb={4}
        >
          <Typography variant="body2">{t("consultation:noFreeTerms")}</Typography>
        </Box>
      )}
      {
        termsData?.items?.map((item: ConsultationTermsListItems, index: number) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            className={classes.item}
          >
            <Box
              className={classes.hour}
            >
              {getTimeRanges(item.start, item.end)}
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => handleChangeConsultationTerm(item.start)}
              >
                {t("login:choose")}
              </Button>
            </Box>
          </Box>
        ))
      }
      <Box
        mt={3}
      >
        <Button
          variant="outlined"
          color="primary"
          disabled={loading}
          onClick={() => push(`/${i18n.language}/consultation-change-term/${consultationId}`)}
        >
          {t("consultation:back")}
        </Button>
      </Box>
      <ConsultationChangeTermPopup
        open={popupOpen}
        onClose={handleClosePopup}
        setPopupOpen={setPopupOpen}
        consultationId={consultationId}
        termChangeSuccess={termChangeSuccess}
        handleClosePopupOnSuccess={() => push(`/${i18n.language}/consultation/${consultationId}`)}
      />
    </Box>
  )
}

export default ConsultationTermSelection
