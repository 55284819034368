export type medicalSurveyFields = {
  pregnant: string;
  breastFeed: string;
  chronicDiseases: string;
  chronicDiseasesInput: string;
  smoking: string;
  smokingInput: string;
  drugs: string;
  drugsInput: string;
  treatmentContinuation: string;
  alergy: string;
  alergyInput: string;
  importantInformations: string;
  [key: string]: any;
}

export type surveyExtraQuestions = {
  id: number;
  question: string;
  __typename: string;
}

export type medicalSurveyData = {
  id: number;
  question: string;
  answer: string;
}

export type checkboxOptions = {
  value: string | number;
  label: string;
}

export enum surveyType {
  BASIC = "basic",
  CUSTOM = "custom"
}

export type medicinesFromCategoryType = {
  activeSubstances: string;
  characterName: string;
  ean: string;
  id: number;
  medicalProductId: number;
  power: string;
  productName: string;
  size: string;
  unit: string;
  __typename: string;
}

export const surveyDefaults = {
  basic: {
    temperature: "",
    pregnant: "",
    breastFeed: "",
    smoking: "",
    smokingInput: "",
    chronicDiseases: "",
    chronicDiseasesInput: "",
    drugs: "",
    drugsInput: "",
    treatmentContinuation: "",
    alergy: "",
    alergyInput: "",
    importantInformations: "",
    height: null,
    weight: null,
  },
  custom: {
    temperature: null,
    weight: null,
    height: null,
    smoking: "",
    drugsField: "",
    alergy: "",
    chemicals: "",
    chronicDiseases: "",
    pancreaticDiseases: "",
    tumors: "",
    venousDisease: "",
    venousDiseaseInFamily: "",
    thromboembolism: "",
    immobilization: "",
    heartDisease: "",
    myocardialInfarction: "",
    stroke: "",
    cerebralIschemicAttack: "",
    chestPains: "",
    metabolicDisorders: "",
    migraine: "",
    hypertension: "",
    heartValveDisease: "",
    diabetes: "",
    complicatedDiabetes: "",
    malabsorptionSyndrome: "",
    systemicLupusErythematosus: "",
    inflammationOfTheIntestines: "",
    epilepsy: "",
    prohibitionOfHormonalContraception: "",
    pregnant: "",
    importantInformations: ""
  }
}