import React, {FC} from "react"
import {useTranslation} from "react-i18next"

import ActionButtons from "../actionButtons/ActionButtons.component"
import PreventionActionWrapper from "../preventionActionWrapper/PreventionActionWrapper.component"
import {useRedirectToSummaryActionView} from "../PreventionActionCard.utils"

type MedicalExaminationProps = {
  title: string;
  description: string;
}

const MedicalExamination: FC<MedicalExaminationProps> = ({ 
  title,
  description
}) => {
  const { handleFinish, isLoading } = useRedirectToSummaryActionView()
  const {t} = useTranslation()

  return (
    <PreventionActionWrapper
      title={title}
      description={description}
    >
      <ActionButtons
        onClick={handleFinish}
        isLoading={isLoading}
        actionButtonLabel={t("healthPlanner:confirmExaminationAction")}
      />
    </PreventionActionWrapper>
  )
}

export default MedicalExamination