import React, { FC } from "react"
import { useController, useWatch } from "react-hook-form"
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography
} from "@material-ui/core"

import { CheckboxGroupControllerProps } from "./CheckboxGroupController.types"
import { useCheckboxGroupControllerStyles } from "./CheckboxGroupController.styles"

export const CheckboxGroupController: FC<CheckboxGroupControllerProps> = ({
  label,
  name,
  options,
  optionStyles,
}) => {
  const { formGroup } = useCheckboxGroupControllerStyles()
  const checkboxValues = useWatch({ name: name }) || []
  const {
    field: { onChange },
    formState: { errors }
  } = useController({
    name,
  })

  const handleChange = (label) => {
    let newArray = [...checkboxValues]

    if (newArray.length > 0) {
      const index = newArray.indexOf(label)

      index === -1 
        ? (newArray = [label, ...newArray])
        : (newArray.splice(index, 1))
    } else {
      newArray = [label, ...newArray]
    }

    onChange(newArray)
  }

  return (
    <Box mt={2}>
      <FormControl>
        <Typography
          variant="body1"
        >
          {label}
        </Typography>
        <FormGroup
          id={name}
          row
          classes={{ root: formGroup }}
        >
          {options.map((option) => (
            <FormControlLabel
              label={<Typography variant="subtitle2">{option["label"]}</Typography>}
              key={option["label"]}
              classes={{ root: optionStyles }}
              control={
                <Checkbox
                  checked={checkboxValues?.some(checked => checked === option["label"])}
                  onChange={() => handleChange(option["label"])}
                />
              }
            />
          ))}
        </FormGroup>
      </FormControl>
      <FormHelperText error>
        {errors?.[name]?.message}
      </FormHelperText>
    </Box>
  )
}
