export const SIGNAL_IDUNA_INTEGRATION_TYPE = 1
export const S7HEALTH_INTEGRATION_TYPE = 2
export const ALLIANZ_EWKA_INTEGRATION_TYPE = 3
export const ALLIANZ_ONYX_INTEGRATION_TYPE = 4

export const getIntegrationType = (
  isSignalIdunaEnabled: any, 
  isS7HealthEnabled: any, 
  isAllianzEwkaEnabled: any, 
  isAllianzOnyxEnabled: any
) => {
  if (isSignalIdunaEnabled) {
    return SIGNAL_IDUNA_INTEGRATION_TYPE
  }
    
  if (isS7HealthEnabled) {
    return S7HEALTH_INTEGRATION_TYPE
  }

  if (isAllianzEwkaEnabled) {
    return ALLIANZ_EWKA_INTEGRATION_TYPE
  }

  if (isAllianzOnyxEnabled) {
    return ALLIANZ_ONYX_INTEGRATION_TYPE
  }
    
  return null
}
