import React, {FC, useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {useHistory, useLocation, useParams} from "react-router"
import {Box} from "@material-ui/core"
import {CancelTokenSource} from "axios"
import {format, parseISO} from "date-fns"
import i18next from "i18next"

import api from "../../api/api"
import {getConsultationConfig, getConsultationTermsConfig,} from "../../api/routes"
import {selectToken} from "../../store/session/session.selectors"
import {selectHasUserCompletePersonalData} from "../../store/user/user.selectors"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import {ConsultationModel, ConsultationStatusNumber} from "../../components/consultation/Consultation.types"
import {isAfterConsultationStartDate} from "../../components/consultation/Consultation.utils"
import ConsultationTermSelection
  from "../../components/consultation/ConsultationTermSelection/ConsultationTermSelectionComponent"
import FillUserDataInformationPanel
  from "../../components/informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import {useConsultationChangesStyles} from "./ConsultationChanges.styles"

type ConsultationTermSelectionPageProps = {
  consultationId: string;
}

const ConsultationTermSelectionPage: FC = () => {
  const classes = useConsultationChangesStyles()
  const {consultationId} = useParams<ConsultationTermSelectionPageProps>()
  const [loading, setLoading] = useState<boolean>(true)
  const [canChangeTerm, setCanChangeTerm] = useState<boolean>(true)
  const [termsData, setTermsData] = useState(null)
  const [specializationName, setSpecializationName] = useState("")
  const [consultationLanguage, setConsultationLanguage] = useState("")
  const [specializationId, setSpecializationId] = useState("")
  const [consultationType, setConsultationType] = useState(0)
  const tokenData = useSelector(selectToken)
  const {push} = useHistory()
  const location = useLocation()
  const simplifiedBooking = location.pathname.includes("consultations/term")
  const hasUserCompletePersonalData = useSelector(selectHasUserCompletePersonalData)

  const getConsultationTerms = async (consultationType: number, language: string, specializationId: string, day = format(new Date(), "yyyy-MM-dd")) => {
    setTermsData(null)

    const {data} = await api.request(
      {...getConsultationTermsConfig(day, consultationType, specializationId, language)}
    )

    setTermsData(data)

    setLoading(false)
  }

  const getConsultationData = async (cancelToken?: CancelTokenSource["token"]) => {
    try {
      const {data} = await api.request<ConsultationModel>({
        ...getConsultationConfig(consultationId),
        cancelToken,
      })
      const {consultationType, language, specialization: {id, name}} = data
      let changeTermPossibility = false

      if (
        simplifiedBooking && data?.status_number === ConsultationStatusNumber.RESERVED
        || ([ConsultationStatusNumber.CONFIRMED].includes(data?.status_number) && !isAfterConsultationStartDate(parseISO(data?.visit_at)))
      ) {
        changeTermPossibility = true
      }

      if (!changeTermPossibility) {
        setLoading(false)
        setCanChangeTerm(false)
        return
      }

      setSpecializationName(name)
      setConsultationType(consultationType)
      setConsultationLanguage(language)
      setSpecializationId(id)
      await getConsultationTerms(consultationType, language, id)
    } catch (e) {
      push(`/${i18next.language}/403`)
    }
  }

  useEffect(() => {
    if (!specializationId && tokenData) {
      getConsultationData()
    }
  }, [tokenData, specializationId])

  if (loading) {
    return <LoaderBox/>
  }

  if (!canChangeTerm) {
    push(`/${i18next.language}/403`)
    return null
  }

  if (simplifiedBooking && !hasUserCompletePersonalData) {
    return <FillUserDataInformationPanel/>
  }

  return (
    <Box
      className={classes.pageSection}
      component="section"
    >
      <ConsultationTermSelection
        termsData={termsData}
        consultationId={consultationId}
        specializationName={specializationName}
        consultationType={consultationType}
        getConsultationTerms={getConsultationTerms}
        consultationLanguage={consultationLanguage}
        specializationId={specializationId}
        simplifiedBooking={simplifiedBooking}
      />
    </Box>
  )
}

export default ConsultationTermSelectionPage
