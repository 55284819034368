import React, {FC, useEffect, useRef,useState} from "react"
import { useTranslation } from "react-i18next"
import {Prompt} from "react-router-dom"
import {Backdrop, Box, CircularProgress} from "@material-ui/core"
import clsx from "clsx"
import {Channel} from "pusher-js"
import Video, {
  Room,
} from "twilio-video"

import api from "../../../api/api"
import {generateTwilioVideoTokenConfig} from "../../../api/routes"
import doctorAvatarPlaceholder1 from "../../../assets/images/doctorAvatarPlaceholder1.png"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import ConsultationInvitationVideoModal from "../consultationVideo/consultationInvitationVideoModal/ConsultationInvitationVideoModal.component"
import CreateRoomError from "./CreateRoomError.component"
import VideoControlButtons from "./videoControlButtons/VideoControlButtons.component"
import {useConsultationTwilioVideoStyles} from "./ConsultationTwilioVideo.styles"

import Participant from "./Participant.Component"

interface ConsultationTwilioVideoProps {
  pusherChannel: Channel;
  consultationId: string;
  invitationVideoModalOpen: boolean;
  videoChatOpen: boolean;
  setInvitationVideoModalOpen: (invitationVideoModalOpen: boolean) => void;
  setVideoChatOpen: (videoChatOpen: boolean) => void;
}

const ConsultationTwilioVideo: FC<ConsultationTwilioVideoProps> = (
  {
    pusherChannel,
    invitationVideoModalOpen,
    videoChatOpen,
    setInvitationVideoModalOpen,
    setVideoChatOpen,
    consultationId,
  }
) => {
  const [isVideoFullScreenOpen, setIsVideoFullScreenOpen] = useState<boolean>(true)
  const [loadingStream, setLoadingStream] = useState<boolean>(false)
  const [participants, setParticipants] = useState<unknown[]>([])
  const [isCameraOn, setIsCameraOn] = useState<boolean>(true)
  const [isMicrophoneOn, setIsMicrophoneOn] = useState<boolean>(true)
  const [storedRoom, setStoredRoom] = useState<Room|null>(null)
  const [closeVideoConfirmation, setCloseVideoConfirmation] = useState<boolean>(false)
  const [createRoomError, setCreateRoomError] = useState<boolean>(false)
  const remoteParticipant: any = participants?.[0]
  const classes = useConsultationTwilioVideoStyles({isCameraOn})
  const ref = useRef<HTMLDivElement>(null)
  const {t} = useTranslation()

  const generateTwilioVideoToken = async (consultationId: string) => {
    const participantConnected = (participant: unknown) => {
      setParticipants(prevParticipants => [...prevParticipants, participant])
    }

    const participantDisconnected = (participant: unknown) => {
      setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant))
      handleFinishVideoChat()
    }

    if (!storedRoom) {
      try {
        const {data} = await api.request({
          ...generateTwilioVideoTokenConfig(consultationId),
        })

        Video.connect(data?.token, {
          name: consultationId,
          video: true,
          audio: true,
        }).then(room => {
          if (room) {
            setStoredRoom(room)
            room.on("participantConnected", participantConnected)
            room.on("participantDisconnected", participantDisconnected)
            room.participants.forEach(participantConnected)
          }
        })
      } catch (e) {
        setCreateRoomError(true)
      }
    }
  }

  const handleFinishVideoChat = () => {
    setInvitationVideoModalOpen(false)
    setVideoChatOpen(false)
    setIsVideoFullScreenOpen(true)
    setLoadingStream(false)
    setParticipants([])
    setIsCameraOn(true)
    setIsMicrophoneOn(true)

    if (storedRoom && storedRoom.localParticipant.state === "connected") { // stop audio/video tracking
      storedRoom.localParticipant.tracks.forEach((trackPublication: any) => {
        trackPublication.track.stop()
      })
      storedRoom.disconnect()
    }

    setStoredRoom(null)
  }

  const handleStartVideoChat = async () => {
    setLoadingStream(true)
    await generateTwilioVideoToken(consultationId)
  }

  const handleCloseErrorModal = () => {
    setCreateRoomError(false)
    handleFinishVideoChat()
  }

  useEffect(() => {
    if (videoChatOpen) {
      handleStartVideoChat()
    } else {
      handleFinishVideoChat()
    }
  }, [videoChatOpen])

  useEffect(() => {
    return () => {
      if (storedRoom) {
        handleFinishVideoChat()
      }
    }
  }, [storedRoom])

  useEffect(() => {
    if (participants.length) {
      setLoadingStream(false)
    }
  }, [participants])

  useEffect(() => {
    const handleClickOutside = (event: React.ChangeEvent<HTMLInputElement>|MouseEvent) => {

      if (!ref?.current?.contains(event.target as Element) && storedRoom) {
        setCloseVideoConfirmation(true)
      }
    }

    if (ref.current) {
      document.removeEventListener("click", handleClickOutside, true)
      document.addEventListener("click", handleClickOutside, true)
    }

    return () => document.removeEventListener("click", handleClickOutside, true)
  }, [ref, storedRoom])

  return (
    <>
      <Prompt
        when={closeVideoConfirmation}
        message={t("videoChatConsultation:endVideoModalDescription")}
      />
      <Backdrop
        open={videoChatOpen}
        className={clsx(
          classes.backdrop,
          !isVideoFullScreenOpen && classes.mitigatedBackdrop
        )}
        ref={ref}
      >
        <Box className={clsx(isVideoFullScreenOpen ? classes.videosBox : classes.mitigatedVideoBox)}>
          <Box className={classes.videosWrapper}>
            <div
              className={clsx(
                classes.doctorVideo,
                !isVideoFullScreenOpen && classes.mitigatedDoctorVideo,
              )}
            >
              {
                remoteParticipant && (
                  <Participant
                    key={remoteParticipant.sid}
                    participant={remoteParticipant}
                    isCameraOn={isCameraOn}
                  />
                )
              }
            </div>

            { loadingStream && (
              <Box className={classes.loaderBox}>
                <CircularProgress size={isVideoFullScreenOpen ? 100 : 50} thickness={2} color="inherit"/>
              </Box>
            )}

            <Box
              className={clsx(
                classes.patientVideo,
                !isVideoFullScreenOpen && "visually-hidden",
              )}
            >
              {
                storedRoom && (
                  <Box className={classes.localParticipant}>
                    {
                      !isCameraOn && (
                        <ImageByIntegrationType
                          imageSrc={doctorAvatarPlaceholder1}
                          alt="doctorAvatarPlaceholder1"
                          className={classes.cameraOff}
                          imagePath={"doctor-avatar-placeholder-1.png"}
                        />
                      )
                    }
                    <Participant
                      key={storedRoom.localParticipant.sid}
                      participant={storedRoom.localParticipant}
                      isCameraOn={isCameraOn}
                    />
                  </Box>
                )
              }
            </Box>
          </Box>

          <VideoControlButtons
            pusherChannel={pusherChannel}
            finishVideoChat={handleFinishVideoChat}
            consultationId={consultationId}
            toggleFullScreenVideo={() => setIsVideoFullScreenOpen(!isVideoFullScreenOpen)}
            isVideoFullScreenOpen={isVideoFullScreenOpen}
            storedRoom={storedRoom}
            setCameraOnOff={() => setIsCameraOn(!isCameraOn)}
            isCameraOn={isCameraOn}
            setMicrophoneOnOff={() => setIsMicrophoneOn(!isMicrophoneOn)}
            isMicrophoneOn={isMicrophoneOn}
          />
        </Box>
      </Backdrop>
      <ConsultationInvitationVideoModal
        open={invitationVideoModalOpen}
        setClose={() => setInvitationVideoModalOpen(false)}
        setVideoChatOpen={() => setVideoChatOpen(true)}
      />
      <CreateRoomError
        createRoomError={createRoomError}
        onClose={() => handleCloseErrorModal()}
      />
    </>
  )
}

export default ConsultationTwilioVideo
