import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {Typography} from "@material-ui/core"

import AppDialog from "../common/appDialog/AppDialog.component"

const CUSTOM_INTEGRATION_CHECK_PERMISSION_ENABLED = "1"
const BENEFIT_SYSTEM_EXTERNAL_URL = "https://multilife.com.pl/"

const CustomIntegrationPermissionsModal = () => {
  const {t} = useTranslation()
  const params = new URLSearchParams(window.location.search)
  const paramsObject = Object.fromEntries(params)
  const customIntegrationRedirect = paramsObject.customIntegrationLogout === CUSTOM_INTEGRATION_CHECK_PERMISSION_ENABLED
  const [showModal, setShowModal] = useState(customIntegrationRedirect)

  const handleOnClose = () => {
    setShowModal(false)
    window.location.href = BENEFIT_SYSTEM_EXTERNAL_URL
  }

  return (
    <AppDialog
      data-testid="benefitSystemsModal"
      fullWidth
      maxWidth="md"
      open={showModal}
      closeButton={false}
      actionButtonText={t("user:backToMultilife")}
      actionButtonProps={{
        onClick: handleOnClose,
      }}
      onClose={() => setShowModal(false)}
      title={t("user:benefitSystemsErrorTitle")}
    >
      <Typography>
        {t("user:benefitSystemsErrorDescription")}
      </Typography>
    </AppDialog>
  )
}

export default CustomIntegrationPermissionsModal
