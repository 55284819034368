import React, {FC } from "react"
import {useTranslation} from "react-i18next"

import MedicalSurveyAccordionCheckbox from "./medicalSurveyAccordionCheckbox/MedicalSurveyAccordionCheckbox.component"
import MedicalSurveyCheckbox from "./medicalSurveyCheckbox/MedicalSurveyCheckbox.component"
import {checkboxOptions} from "./MedicalSurvey.types"

interface MedicalSurveyFieldsProps {
  checkboxAccordionOptions: checkboxOptions[];
  checkboxOptions: checkboxOptions[];
  isBasicType: boolean;
  customSurveyFields: Record<string, unknown>;
}

const excludedFields = ["chronicDiseasesInput", "importantInformations", "pregnant", "weight", "height", "temperature", "chemicalsInput", "alergyInput"]
const noDetailsFields = ["hypertension", "heartDisease", "diabetes","complicatedDiabetes", "malabsorptionSyndrome", "systemicLupusErythematosus", "epilepsy"]

const MedicalSurveyFields: FC<MedicalSurveyFieldsProps> = ({
  checkboxAccordionOptions, 
  checkboxOptions,
  isBasicType,
  customSurveyFields,
}) => {
  const {t} = useTranslation()

  const renderCustomFields = () => { // custom survey
    const fields = []

    for (const inputName in customSurveyFields) {
      if (!excludedFields.includes(inputName)) {
        
        fields.push(
          noDetailsFields.includes(inputName)
            ? (
              <MedicalSurveyCheckbox
                key={inputName}
                name={inputName}
                label={t(`prescription:surveyQuestions:${inputName}`)}
                options={checkboxOptions}
              />
            ) : (
              <MedicalSurveyAccordionCheckbox
                key={inputName}
                name={inputName}
                checkboxLabel={t(`prescription:surveyQuestions:${inputName}`)}
                inputLabel={t(`prescription:surveyQuestions:${inputName}Input`)}
                options={checkboxAccordionOptions}
              />
            )
        )
      }
    }

    return fields
  }
    
  if (isBasicType) {
    return (
      <>
        <MedicalSurveyAccordionCheckbox
          name="chronicDiseases"
          checkboxLabel={t("prescription:surveyQuestions:chronicDiseasesCustom")}
          inputLabel={t("prescription:surveyQuestions:chronicDiseasesTextFieldLabel")}
          options={checkboxAccordionOptions}
        />
        <MedicalSurveyAccordionCheckbox
          name="smoking"
          checkboxLabel={t("prescription:surveyQuestions:smoking")}
          inputLabel={t("prescription:surveyQuestions:smokingInput")}
          options={checkboxAccordionOptions}
          displayAsInput
        />
        <MedicalSurveyAccordionCheckbox
          name="drugs"
          checkboxLabel={t("prescription:surveyQuestions:drugs")}
          inputLabel={t("prescription:surveyQuestions:drugsTextFieldLabel")}
          options={checkboxAccordionOptions}
        />
        <MedicalSurveyCheckbox
          name={"treatmentContinuation"}
          label={t("prescription:surveyQuestions:treatmentContinuation")}
          options={checkboxOptions}
        />
        <MedicalSurveyAccordionCheckbox
          name="alergy"
          checkboxLabel={t("prescription:surveyQuestions:alergy")}
          inputLabel={t("prescription:surveyQuestions:alergyTextFieldLabel")}
          options={checkboxAccordionOptions}
        />
      </>
    )
  }

  return (
    <>
      {renderCustomFields()}
    </>
  )
}

export default MedicalSurveyFields