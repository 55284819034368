import {createStyles,makeStyles} from "@material-ui/core"

export const useActionButtonsStyles = makeStyles((theme) => createStyles({
  buttonsWrapper: {
    margin: theme.spacing(3, 0, 2),
  },
  actionButton: {
    "&:nth-of-type(1)": {
      margin: theme.spacing(0, 0, 3, 2)
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 0, 0, 3),
      "&:nth-of-type(1)": {
        order: 1,
        margin: theme.spacing(0, 0, 0, 3),
      },
    },
  },
}))
