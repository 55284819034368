import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import i18next from "i18next"

import MentalHealthDashboard from "./components/MentalHealthDashboard/MentalHealthDashboard.component"
import { checkFeatureAvailability } from "../../store/clinic/clinicSettings.utils"

const MentalHealthPageRouter: FC = () => {
  const { i18n } = useTranslation()
  const { push } = useHistory()

  const showMentalHealthTab = checkFeatureAvailability().enabledMentalHealth && i18n.language === "pl"

  if (!showMentalHealthTab) {
    push(`/${i18next.language}`)

    return null
  }
  
  return <MentalHealthDashboard/>
}

export default MentalHealthPageRouter
