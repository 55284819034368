import api from "../../api/api"
import {postChatMessageConfig} from "../../api/routes"
import {ChatMessageType} from "../../components/consultationChat/ConsultationChat.types"
import {SetState} from "../../types/SetState"
import {CompletedQuestionnaireTemplate} from "../healthPlanner/pages/preventionQuestionnaire/genericStepForm/GenericStepForm.types"

export const sendMessage = async (
  message: string,
  consultationId: string,
  setSaving: SetState<boolean>,
  paramsObject: CompletedQuestionnaireTemplate,
  setIsCompleted: SetState<boolean>,
  asyncConsultation: boolean,
) => {
  try {
    await api.request({
      ...postChatMessageConfig,
      data: {
        consultation: consultationId,
        messageType: ChatMessageType.CONSULTATION_SURVEY,
        message: message.trim(),
        params: {
          survey: JSON.stringify({paramsObject}),
          asyncConsultation
        }
      }
    })

    setSaving(false)
    setIsCompleted(true)
  } catch (e) {
    console.error(e)
    setSaving(false)
    setIsCompleted(false)
  }
}