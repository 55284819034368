import {createStyles, makeStyles} from "@material-ui/core"

import {InputType} from "../GenericStepForm.types"
import {Theme} from "@material-ui/core/styles"

export const useQuestionStyles = makeStyles<Theme, { inputType: InputType }>(theme => createStyles({
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: ({ inputType }) => inputType === InputType.CHECKBOXES ? "initial" : "center",
    margin: theme.spacing(2, 15, 8),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2, 4, 5)
    }
  },
  inputTitle: {
    marginBottom: theme.spacing(4),
    wordBreak: "break-word"
  },
  subtitleText: {
    marginBottom: theme.spacing(1)
  }
}))
