import React, {FC, useMemo} from "react"
import {useTranslation} from "react-i18next"
import { useSelector } from "react-redux"
import {NavLink} from "react-router-dom"
import {AppBar, Box, Button, Fab, Link,Toolbar, Typography, useTheme} from "@material-ui/core"
import clsx from "clsx"

import { useAppSelector } from "../../../../hooks/storeHooks"
import useGoToConsultationPage from "../../../../hooks/useGoToConsultationPage"
import useIsB2BClinic from "../../../../hooks/useIsB2BClinic"
import { selectClinicSettings } from "../../../../store/clinic/clinicSettings.selectors"
import { selectUserInitialLoading } from "../../../../store/user/user.selectors"
import IconByIntegrationType from "../../../iconByIntegrationType/IconByIntegrationType"
import useMakeConsultationMenuListItems from "../common/nestedMenu/makeConsultationNestedMenu/MakeConsultationMenu.config"
import MakeConsultationNestedMenu from "../common/nestedMenu/makeConsultationNestedMenu/MakeConsultationNestedMenu.component"
import NestedMenu from "../common/nestedMenu/NestedMenu.component"
import {checkFeatureAvailability} from "../../../../store/clinic/clinicSettings.utils"
import {renderMenuElements, useMenuElements} from "../common/MainMenu.utils"
import {useMobileMenuStyles} from "./MobileMenu.styles"

const MobileMenu: FC = () => {
  const classes = useMobileMenuStyles()
  const {t, i18n} = useTranslation()
  const theme = useTheme()
  const userDataInitialLoading = useSelector(selectUserInitialLoading)
  const {goToMakeConsultationPage} = useGoToConsultationPage()
  const {B2BClinic} = useIsB2BClinic()
  const {renderMenuElements} = useMenuElements(B2BClinic, i18n.language)
  const hideConsultationBooking = checkFeatureAvailability().hideConsultationBooking
  const { makeList: makeConsultationMenuItemList } = useMakeConsultationMenuListItems()
  const navItemContent = (iconClass: string, text: string, skipIconOverwrite?: boolean) => (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        color={theme.palette.text.primary}
      >
        <IconByIntegrationType iconName={iconClass} className={classes.linkIcon} skip={skipIconOverwrite}/>

        <Typography
          variant="subtitle2"
          className={classes.linkText}
        >
          {t(text)}
        </Typography>
      </Box>
    </>
  )

  const renderSetDateSection = (goToConsultationPage?: boolean) => (
    <Fab
      component={Button}
      color="primary"
      aria-label={t("setDate")}
      onClick={goToConsultationPage ? goToMakeConsultationPage : undefined}
      classes={{
        root: clsx(classes.fabButton, userDataInitialLoading && classes.disabledFabButton),
        label: classes.fabButtonLabel,
      }}
    >
      <IconByIntegrationType iconName={"icon-calendar-blank"} className={classes.fabButtonIcon} />
      <span className={classes.fabButtonText}>{t("setDate")}</span>
    </Fab>
  )

  return (
    <AppBar position="fixed" color="default" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Box mb={5}>
          <NestedMenu
            trigger={renderSetDateSection()}
            menuListItemsArray={makeConsultationMenuItemList()}
            isEnabled={!hideConsultationBooking}
          />
        </Box>
        <Box
          maxWidth="500px"
          margin="auto"
          width="100%"
          height="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {renderMenuElements().map((item) => {
            if (item.nestedItems){
              const nestedMenuTrigger = (
                <div
                  className={clsx(classes.link, userDataInitialLoading && classes.disabled)}
                  onClick={item.onClickHandler ?? undefined}
                >
                  { navItemContent(item.iconClass, item.text, item.skipIconOverwrite) }
                </div>
              )

              return (
                <NestedMenu
                  key={item.text}
                  trigger={nestedMenuTrigger}
                  menuListItemsArray={item.nestedItems}
                />
              )
            }

            return item.isExternalLink
              ? (
                <Link
                  key={item.text}
                  href={item.navigateTo}
                  className={clsx(classes.link, userDataInitialLoading && classes.disabled)}
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={item.onClickHandler ?? undefined}
                >
                  { navItemContent(item.iconClass, item.text, item.skipIconOverwrite) }
                </Link>
              )
              : (
                <NavLink
                  key={item.text}
                  to={item.navigateTo}
                  exact
                  className={clsx(classes.link, userDataInitialLoading && classes.disabled)}
                  onClick={item.onClickHandler ?? undefined}
                >
                  { navItemContent(item.iconClass, item.text, item.skipIconOverwrite) }
                </NavLink>
              )
          })}
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default MobileMenu
