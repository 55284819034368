import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import {Link} from "react-router-dom"
import { Box, Button, Typography } from "@material-ui/core"

import api from "../../../api/api"
import { getMedicalDocumentFileConfig } from "../../../api/routes"
import {useAppSelector} from "../../../hooks/storeHooks"
import useFileDownload from "../../../hooks/useFileDownload"
import useIsB2BClinic from "../../../hooks/useIsB2BClinic"
import {selectClinicSettings} from "../../../store/clinic/clinicSettings.selectors"
import GlobalErrorSnackbar from "../../commonFormItems/globalErrorSnackbar/GlobalErrorSnackbar"
import { useReferralSectionFooterStyles } from "./ReferralSectionFooter.styles"

interface ReferralSectionFooterProps {
  price: number;
  expanded?: boolean;
  isBought: boolean;
  consultationId: string;
  isPoz: boolean;
  referralId?: string;
  date?: string;
  isNoExaminationIncludedInPackage?: boolean;
}

const ReferralSectionFooter: FC<ReferralSectionFooterProps> = (
  {
    price,
    isBought,
    consultationId,
    referralId,
    date,
    isNoExaminationIncludedInPackage,
    isPoz
  }
) => {
  const {push} = useHistory()
  const {t, i18n} = useTranslation()
  const classes = useReferralSectionFooterStyles()
  const clinicSettings = useAppSelector(selectClinicSettings)
  const {clinicSignalIdunaSettings: {dashboardLaboratoryModuleEnabled}} = clinicSettings
  const orderExamsEnabled = dashboardLaboratoryModuleEnabled

  const referralPrice = price?.toFixed(2).replace(".", ",")

  const fileName = `${date} ${t("referrals:referral")}`
  const getReferralPdf = () => api.request<Blob>({...getMedicalDocumentFileConfig(referralId || "")})
  const {download, downloading, downloadError} = useFileDownload(getReferralPdf)

  const handleDownloadPdfClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    (event.target as HTMLInputElement).blur()
    download(fileName)
  }

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="baseline"
    >

      <Box className={classes.buttonsWrapper}>
        {orderExamsEnabled && (!isPoz && clinicSettings.consultationListSettings.enableReferralPriceInfo && (!isBought || !isNoExaminationIncludedInPackage)) && (
          <Typography variant="h4" component="p">{referralPrice} zł</Typography>
        )}

        {
          orderExamsEnabled && !isPoz && (
            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              disabled={isBought || isNoExaminationIncludedInPackage}
              component={Link}
              to={clinicSettings.examCartSettings.enableReferralBasketFeature
                ? `/${i18n.language}/order-examinations/${consultationId}`
                : `/${i18n.language}/order-exams/${consultationId}`
              }
              onClick={() => push(`/${i18n.language}/order-exams/${consultationId}`)}
            >
              {t("referrals:order")}
            </Button>
          )
        }

        <Button
          variant="outlined"
          color="secondary"
          className={classes.actionButton}
          disabled={!referralId || downloading}
          onClick={handleDownloadPdfClick}
        >
          {t("referrals:download")}
        </Button>

        <GlobalErrorSnackbar isError={!!downloadError}/>
      </Box>
    </Box>
  )
}

export default ReferralSectionFooter
