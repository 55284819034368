import { createStyles, makeStyles } from "@material-ui/core"

import { colors } from "../../../../../themes/colors"
import { Theme } from "@material-ui/core/styles"

export const useUserPointsTileStyles = makeStyles((theme: Theme) => createStyles({
  pointsLabel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  userPointsCircle: {
    background: colors.telemediGreen10,
    border: `4px solid ${colors.telemediGreen30}`,
    color: theme.palette.primary.main,
    borderRadius: "50%",
    minWidth: 120,
    minHeight: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    ...theme.typography.h1,
    fontWeight: 900,
  },
}))