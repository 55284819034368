import React, {FC, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box, Button, Checkbox, Typography} from "@material-ui/core"

import api from "../../../api/api"
import {postChatMessageConfig} from "../../../api/routes"
import useConsultationLang from "../../../hooks/useConsultationLang"
import AppDialog from "../../common/appDialog/AppDialog.component"
import ButtonLoader from "../../common/buttonLoader/ButtonLoader.component"
import GlobalFormErrorMessage from "../../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import {ConsultationSurveyParams} from "../../consultation/Consultation.types"
import {ChatMessageMessageType, ChatMessageType, ConsultationChatItem} from "../ConsultationChat.types"
import {useConsultationChatStyles} from "../ConsultationChat.styles"

interface ConsultationSurveyProps {
  open: boolean;
  consultationId: string;
  patientId?: string;
  onClose(): void;
  addNewPatientMessage: (newPatientMessage: ConsultationChatItem) => void;
  magicLinkId: string | null;
}

const REASONS_COUNT = 6

const ConsultationSurvey: FC<ConsultationSurveyProps> = ({
  open, 
  onClose, 
  addNewPatientMessage,
  consultationId,
  patientId,
  magicLinkId,
}) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>("")
  const consultationLang = useConsultationLang()
  const classes = useConsultationChatStyles()

  const form = useForm<ConsultationSurveyParams>({
    mode: "all",
    defaultValues: {
      reason1: false,
      reason2: false,
      reason3: false,
      reason4: false,
      reason5: false,
      reason6: false,
    }
  })
  
  const handleSendMessage = async (message: string) => {
    const newChatMessage = {
      consultationId: consultationId,
      createdAt: Date.now() / 1000, // unify with createdAt value from pusher messages (time in seconds)
      messageRaw: message,
      message: ChatMessageMessageType.START_MESSAGE,
      messageRawType: ChatMessageType.CONSULTATION_REASONS,
      messageType: ChatMessageType.CONSULTATION_REASONS,
      type: 1,
      sender: patientId || null,
    }
    
    addNewPatientMessage(newChatMessage)

    try {
      await api.request({
        ...postChatMessageConfig,
        data: {
          consultation: consultationId,
          messageType: ChatMessageType.CONSULTATION_REASONS,
          message: message.trim(),
          params: {magicLinkId}
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    const selectedReasons = Object.keys(values).filter(item => values[item] === true)
    const anyReasonSelected = selectedReasons.length
    let message = `${consultationLang.getLabel("consultation:selectedReason")}\n\n`

    setLoading(true)

    if (anyReasonSelected) {
      selectedReasons.map(item => {
        message += `${consultationLang.getLabel(`consultation:${item}`)}\n`
      })

      await handleSendMessage(message)
      setLoading(false)
      onClose()
    } else {
      setError(t("consultation:reason"))
      setLoading(false)
    }
  })

  const handleOnChange = (event: {target: HTMLInputElement}) => {
    const field = event.target

    setError("")

    form.setValue(field.name, field.checked)
  }
  
  const renderFormFields = () => {
    const formFields = []

    for (let index = 1; index <= REASONS_COUNT; index++) {
      formFields.push(
        <Box
          display="flex"
          key={index}
        >
          <Checkbox
            id={`reason${index}`}
            name={`reason${index}`}
            onChange={handleOnChange}
            className={classes.checkbox}
          />
          <Typography
            variant="body2"
            className={classes.consultationReason}
          >
            <label htmlFor={`reason${index}`}>
              {t(`consultation:reason${index}`)}
            </label>
          </Typography>
        </Box>
      )
    }

    return formFields
  }

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      closeButton={false}
      title={t("consultation:leaveMessage")}
    >
      <Box
        mb={2}
      >
        <Typography
          variant="body1"
        >
          {t("consultation:reason")}
        </Typography>
      </Box>
      {error && (
        <GlobalFormErrorMessage
          message={error}
        />
      )}
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          {renderFormFields()}
          <Box
            mt={4}
          >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              startIcon={loading && <ButtonLoader position="prefix"/>}
              disabled={loading}
            >
              {t("send")}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </AppDialog>
  )
}

export default ConsultationSurvey
