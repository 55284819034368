import { Gender } from "../store/user/user.types"

const GENDER_NUMBER_INDEX = 9
export const getGenderFromPesel = (pesel: string | null) => {
  if (!pesel) {
    return null
  }

  const genderNumber = Number(pesel[GENDER_NUMBER_INDEX])

  if (!genderNumber) {
    return null
  }
  
  return genderNumber % 2 === 0 ? Gender.female : Gender.male
}