import {createTheme} from "@material-ui/core"

const theme = createTheme()

// https://material-ui.com/components/accordion/#CustomizedAccordions.js
const MuiAccordionSummary = {
  root: {
    minHeight: theme.spacing(12),
    "&$expanded": {
      minHeight: theme.spacing(12),
    },
  },
}

export const accordion = {
  MuiAccordionSummary,
}
