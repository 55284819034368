import React, {FC, RefObject} from "react"
import {useTranslation} from "react-i18next"
import {Box, Button, TextField, Typography} from "@material-ui/core"

import Alert from "../../alert/Alert.component"
import ButtonLoader from "../../common/buttonLoader/ButtonLoader.component"
import LoginAccountsModal from "../../loginUsersModal/LoginAccountsModal.component"
import {useSendAccessCodeInput} from "./SendAccessCodeInput.utils"
import {useSendAccessCodeInputStyles} from "./SendAccessCodeInput.styles"

type SendAccessCodeInputProps = {
  passwordLessInputRef: RefObject<HTMLInputElement> | null
}

const SendAccessCodeInput: FC<SendAccessCodeInputProps> = ({passwordLessInputRef}) => {
  const classes = useSendAccessCodeInputStyles()
  const {t} = useTranslation()
  const {
    errorMessage,
    apiError,
    inputError,
    inputValue,
    handleChangeInput,
    isLoading,
    handleClickSubmit,
    users,
    isModalOpen,
    handleClose,
    handleChooseAccount,
  } = useSendAccessCodeInput()

  return (
    <Box
      className={classes.container}
    >
      <Typography
        className={classes.bold}
        variant="body1"
      >
        {t("passwordless:loginLabel")}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
      >
        {t("passwordless:loginDescription")}
      </Typography>
      {
        errorMessage && (
          <Alert message={errorMessage}/>
        )
      }
      {
        apiError && (
          <Alert message={t("errors:unknownError")}/>
        )
      }
      <TextField
        className={classes.input}
        label={t("passwordless:loginInputLabel")}
        value={inputValue}
        onChange={handleChangeInput}
        error={!!inputError}
        helperText={inputError}
        inputRef={passwordLessInputRef}
      />
      <Button
        className={classes.submitButton}
        variant="contained"
        color="primary"
        onClick={handleClickSubmit}
        disabled={isLoading}
        startIcon={isLoading && <ButtonLoader/>}
      >
        {t("passwordless:submitButtonLabel")}
      </Button>

      <LoginAccountsModal
        open={isModalOpen}
        handleClose={handleClose}
        users={users}
        chooseAccount={handleChooseAccount}
      />
    </Box>
  )
}

export default SendAccessCodeInput
