import { useTranslation } from "react-i18next"

import { ActionType } from "../../api/queries/getUserActionById"

export const useParseActionTypeToTile = (actionType?: ActionType) => {
  const { t } = useTranslation()

  switch (actionType) {
    case ActionType.YOUR_TASK:
      return t("healthPlanner:yourTaskActionTitle")
    case ActionType.E_PRESCRIPTION:
      return t("healthPlanner:ePrescriptionActionTitle")
    case ActionType.FILL_QUESTIONNAIRE:
      return t("healthPlannerActions:thankYouViewTitle")
    default:
      return t("healthPlanner:defaultActionTitle")
  }
}
