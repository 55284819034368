import { createStyles, makeStyles, Theme } from "@material-ui/core"

import { colors } from "../../../../../../../themes/colors"

export const useMessengerThreadItemStyles = makeStyles((theme: Theme) => createStyles({
  threadButton: {
    display: "block",
    borderBottom: `1px solid ${colors.telemediGray40}`,
    borderRadius: 0,
    padding: theme.spacing(3, 2),
    textDecoration: "none",
    color: theme.palette.text.primary,
    "&:last-child": {
      borderBottom: 0,
    },
  },
  selectedThreadButton: {
    background: theme.palette.background.appBar,
  },
  coverText: {
    maxWidth: "300px",
    overflow: "hidden",
  },
  message: {
    whiteSpace: "nowrap",
    color: theme.palette.text.secondary,
  }
}))