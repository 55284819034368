import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useExamFieldsStyles = makeStyles((theme) => createStyles({
  icon: {
    "& path": {
      stroke: theme.palette.text.secondary,
    }
  },
}))
