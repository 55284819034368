import {createStyles, makeStyles, Theme} from "@material-ui/core"

export const useAddressSearchEngineStyles = makeStyles<Theme, { isCoreAddressDataProvided: boolean }>((theme) => createStyles({
  label: {
    border: props => `2px solid ${props.isCoreAddressDataProvided ? theme.palette.text.primary : theme.palette.error.main}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5, 2),
    margin: props => theme.spacing(2, 0, props.isCoreAddressDataProvided ? 2 : 1),
    cursor: "pointer",
    "&:hover": {
      border: `2px solid ${theme.palette.primary.main}`,
    }
  },
  locationWrapper: {
    margin: theme.spacing(2, 0)
  }
}))