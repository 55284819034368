import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Box, Button, useTheme } from "@material-ui/core"
import { Gear } from "phosphor-react"

import { useAppSelector } from "../../hooks/storeHooks"
import {
  selectComingEventsType,
} from "../../store/settings/settings.selectors"
import { setStagingSettings } from "../../store/settings/settings.slice"
import AppDialog from "../common/appDialog/AppDialog.component"
import ComingEventsTypeSelect from "./comingEventsTypeSelect/ComingEventsTypeSelect.component"
import { ComingEventType, StagingSettings } from "../../store/settings/settings.types"
import { useSettingsPanelStyles } from "./SettingsPanel.styles"

interface SettingsPanelProps {
}

const SettingsPanel: FC<SettingsPanelProps> = () => {
  const classes = useSettingsPanelStyles()
  const theme = useTheme()
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const [settingsDialogOpen, setSettingsDialogOpen] = useState<boolean>(false)

  const comingEventsType = useAppSelector(selectComingEventsType)
  const [selectedComingEventsTypeSelect, setSelectedComingEventsTypeSelect] = useState(comingEventsType)
  const handleComingEventsTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedComingEventsTypeSelect(event.target.value as ComingEventType)
  }

  const changeSettings = () => {
    const stagingSettings: StagingSettings = {
      comingEventsType: selectedComingEventsTypeSelect,
    }

    dispatch(setStagingSettings(stagingSettings))
    setSettingsDialogOpen(false)
  }

  return (
    <Box className={classes.settingsBox}>
      <Button
        title={t("stagingSettings:title")}
        onClick={() => setSettingsDialogOpen(true)}
      >
        <Gear size={theme.spacing(4)}/>
      </Button>

      <AppDialog
        open={settingsDialogOpen}
        onClose={() => setSettingsDialogOpen(false)}
        title={t("stagingSettings:title")}
        actionButtonText={t("stagingSettings:submit")}
        actionButtonProps={{
          onClick: changeSettings,
        }}
        classes={{
          paper: classes.dialog
        }}
      >
        <Box my={2}>
          <ComingEventsTypeSelect
            selectedItem={selectedComingEventsTypeSelect}
            handleChange={handleComingEventsTypeChange}
          />
        </Box>
      </AppDialog>
    </Box>
  )
}

export default SettingsPanel
