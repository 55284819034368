import { SagaIterator } from "redux-saga"
import { all, put, takeEvery } from "redux-saga/effects"

import {
  clearConsultationDocumentation,
  getConsultationDocumentation,
} from "../consultationDocumentation/consultationDocumentation.slice"
import { clearExamReferrals, getExamReferrals } from "../examReferrals/examReferrals.slice"

import {
  addLatestConsultations,
  clearLatestConsultations,
  setLatestConsultations,
} from "./consultations.slice"

function* getConsultationDocumentsSaga() {
  yield all([
    put(getConsultationDocumentation()),
    put(getExamReferrals())
  ])
}

function* clearConsultationDocumentsSaga() {
  yield all([
    put(clearConsultationDocumentation()),
    put(clearExamReferrals())
  ])
}

export default function* (): SagaIterator {
  yield takeEvery(setLatestConsultations, getConsultationDocumentsSaga)
  yield takeEvery(addLatestConsultations, getConsultationDocumentsSaga)
  yield takeEvery(clearLatestConsultations, clearConsultationDocumentsSaga)
}
