import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import {Box, Button, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import { setAddNewVoucherModalOpen } from "../../../../store/vouchers/vouchers.slice"
import IconByIntegrationType from "../../../iconByIntegrationType/IconByIntegrationType"

interface UserFinancesTitleNodeProps {}

const UserFinancesTitleNode: FC<UserFinancesTitleNodeProps> = () => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const dispatch = useDispatch()

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
    >
      <Typography
        variant={isMdUp ? "h2" : "h4"}
        component="h2"
      >
        { t("finances:finances") }
      </Typography>

      <Box ml="auto" my={1}>
        <Button
          variant={"contained"}
          color={"primary"}
          startIcon={<IconByIntegrationType iconName={"icon-plus"}/>}
          onClick={() => dispatch(setAddNewVoucherModalOpen(true))}
        >
          {t("finances:vouchers:addNewVoucher")}
        </Button>
      </Box>
    </Box>
  )
}

export default UserFinancesTitleNode