import React, {FC, RefObject} from "react"
import {Paper} from "@material-ui/core"

import SendAccessCodeInput from "./sendAccessCodeInput/SendAccessCodeInput.component"
import {usePasswordlessBox} from "./PasswordlessBox.styles"

type PasswordlessBoxProps = {
  passwordLessInputRef: RefObject<HTMLInputElement> | null
}

const PasswordlessBox: FC<PasswordlessBoxProps> = ({passwordLessInputRef}) => {
  const classes = usePasswordlessBox()

  return (
    <Paper
      className={classes.container}
    >
      <SendAccessCodeInput passwordLessInputRef={passwordLessInputRef}/>
    </Paper>
  )
}

export default PasswordlessBox
