import {ChatMessageType, ConsultationChatItem} from "../ConsultationChat.types"

export const parseMessageTime = (datetime: ConsultationChatItem["datetime"]): string => {
  // get "HH:MM" from "YYYY-MM-DD HH:MM:SS" format
  return datetime ? datetime.split(" ")[1].slice(0,5) : ""
}

export const parseMessageCreatedAt = (createdAt: ConsultationChatItem["createdAt"]): string => {
  // get "HH:MM" from seconds (not miliseconds!) since January 1 1970
  const milisecondsBase = createdAt * 1000
  const date = new Date(milisecondsBase)
  const zeroPadded = (nr: number) => String(nr).padStart(2, "0")

  return `${ zeroPadded(date.getHours()) }:${ zeroPadded(date.getMinutes()) }`
}

export const getFileName = (messageItemRawType: ChatMessageType): string => {
  switch(messageItemRawType) {
    case (ChatMessageType.PRESCRIPTION):
    case (ChatMessageType.EPRESCRIPTION):
      return "prescription"
    case (ChatMessageType.TRANS_PRESCRIPTION):
      return "transPrescription"
    case (ChatMessageType.REFERRAL):
      return "referral"
    case (ChatMessageType.EREFERRAL):
      return "ereferral"
    case (ChatMessageType.RECOMMENDATIONS_LINK):
      return "recommendation"
    case (ChatMessageType.EZLA):
      return "ezla"
    default:
      return "file"
  }
}

export const getFileIconType = (messageItemRawType: ChatMessageType): string => {
  switch(messageItemRawType) {
    case (ChatMessageType.PRESCRIPTION):
    case (ChatMessageType.EPRESCRIPTION):
    case (ChatMessageType.TRANS_PRESCRIPTION):
      return "icon-pill"
    case (ChatMessageType.REFERRAL):
    case (ChatMessageType.EREFERRAL):
      return "icon-flask"
    case (ChatMessageType.RECOMMENDATIONS_LINK):
      return "icon-clipboard"
    default:
      return ""
  }
}