import React, {FC} from "react"
import {useSelector} from "react-redux"
import {Avatar, Typography} from "@material-ui/core"

import {selectClinicSettings} from "../../../store/clinic/clinicSettings.selectors"
import {useUserAvatarStyles} from "./UserAvatar.styles"
import { Variant } from "@material-ui/core/styles/createTypography"

interface UserAvatarProps {
  name: string;
  surname: string;
  size?: number;
  fontVariant?: Variant;
}

const UserAvatar: FC<UserAvatarProps> = ({name, surname, size, fontVariant}) => {
  const initialLetters = (name && surname)
    ? `${name.charAt(0)}${surname.charAt(0)}`.toUpperCase()
    : ""
  const clinicSettings = useSelector(selectClinicSettings)

  const backgroundColor = clinicSettings?.frontendSettings?.style?.dynamicStylesEnabled ? 
    clinicSettings?.frontendSettings?.style?.dashboardUserDotColor 
    : clinicSettings?.frontendSettings?.style?.hoverColor

  const classes = useUserAvatarStyles({size, customBackgroundColor: backgroundColor})

  return (
    <Avatar classes={classes}>
      <Typography
        variant={fontVariant || "h4"}
        component="span"
      >
        {initialLetters}
      </Typography>
    </Avatar>
  )
}

export default UserAvatar
