import React, {FC, useState} from "react"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router"
import {Box, Button} from "@material-ui/core"

import api from "../../../api/api"
import {userEventsConfig} from "../../../api/routes"
import AppDialog from "../../common/appDialog/AppDialog.component"
import ButtonLoader from "../../common/buttonLoader/ButtonLoader.component"

interface ConsultationWithDoctorQuestionPopupProps {
  open: boolean;
  consultationId: string;
  onClose(): void;
}

const ANSWER_YES = "multiprescription.patient_popup_answer_yes"
const ANSWER_NO = "multiprescription.patient_popup_answer_no"

const ConsultationWithDoctorQuestionPopup: FC<ConsultationWithDoctorQuestionPopupProps> = ({
  open,
  onClose,
  consultationId,
}) => {
  const {t, i18n} = useTranslation()
  const [loading, setLoading] = useState(false)
  const { push } = useHistory()

  const sendLogs = async (name: string) => {
    try {
      await api.request({
        ...userEventsConfig,
        data: {
          name,
          consultationId: consultationId
        }
      })

      onClose()

      if (name === ANSWER_NO) {
        push(`/${i18n.language}`)
      }

    } catch (e) {
      console.error(e)
    }
  }

  const handleSubmit = async (answerYes: boolean) => {
    setLoading(true)
    sendLogs(answerYes ? ANSWER_YES : ANSWER_NO)
    setLoading(false)
  }

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      closeIcon={false}
      closeButton={false}
      title={t("consultation:consultationNeeded")}
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={loading && <ButtonLoader position="prefix"/>}
          disabled={loading}
          onClick={() => handleSubmit(true)}
        >
          {t("consultation:consultatioNeededYesAnswer")}
        </Button>
        <Box
          mt={4}
        >
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            type="submit"
            startIcon={loading && <ButtonLoader position="prefix"/>}
            disabled={loading}
            onClick={() => handleSubmit(false)}
          >
            {t("consultation:consultatioNeededNoAnswer")}
          </Button>
        </Box>
      </Box>
    </AppDialog>
  )
}

export default ConsultationWithDoctorQuestionPopup
