import React, { FC, ReactNode } from "react"
import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import {usePageSectionStyles} from "./PageSection.styles"

interface PageSectionProps {
  title?: string;
  titleNode?: ReactNode;
  customStyles?: React.CSSProperties;
}

const PageSection: FC<PageSectionProps> = ({title, titleNode,customStyles , children}) => {
  const classes = usePageSectionStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Box component="section" className={classes.root} style={customStyles}>
      <Box mb={isMdUp ? 8 : 5}>
        <Box
          mb={2}
          px={2}
        >
          { title && (
            <Typography
              variant={isMdUp ? "h2" : "h4"}
              component="h2"
            >
              { title }
            </Typography>
          )}

          { titleNode &&
            titleNode
          }
        </Box>

        { children }
      </Box>
    </Box>
  )
}

export default PageSection
