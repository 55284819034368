import React, {FC, useEffect} from "react"
import {useFieldArray} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box, useMediaQuery, useTheme} from "@material-ui/core"

import addIcon from "../../assets/svg/plusCircle.svg"
import trashIcon from "../../assets/svg/trash.svg"
import ImageByIntegrationType from "../imageByIntegrationType/ImageByIntegrationType"
import TextFieldController from "../commonFormItems/textFieldController/TextFieldController.component"
import {useSickLeaveSectionStyles} from "./SickLeaveSurvey.styles"

interface NipFieldsProps {
  limit: number;
  name?: string;
}

const NipFields: FC<NipFieldsProps> = ({limit, name = "nip"}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))
  const { fields, remove, append } = useFieldArray({
    name
  })
  const classes = useSickLeaveSectionStyles()

  //add first field on mount
  useEffect(() => {
    if(fields?.length!==0){
      return
    }
    append({nipField: ""})
  }, [])

  const nipFieldsItems = fields.map((item, index) => {
    const indexIncrement = index + 1

    const showAddIcon = (indexIncrement < limit) && indexIncrement === fields.length
    const showTrashIcon = fields.length > 1
    const trashIconContent = (
      <span onClick={() => remove(index)}>
        <ImageByIntegrationType
          imageSrc={trashIcon}
          alt="trashIcon"
          className={classes.trashIcon}
          imagePath={"trash.svg"}
        />
      </span>
    )
    const addIconContent = (
      <span onClick={() => append({nipField: ""})}>
        <ImageByIntegrationType
          imageSrc={addIcon}
          alt="addIcon"
          className={classes.addIcon}
          imagePath={"plusCircle.svg"}
        />
        <span className={classes.addNewItemText}>{t("consultation:addNewNip")}</span>
      </span>
    )

    return (
      <Box
        display="flex"
        flexDirection="column"
        key={item.id}
        id="nip" //needed for yup validation
      >
        <Box
          display="flex"
          flexDirection={isSmUp ? "row" : "column"}
        >
          <TextFieldController
            name={`${name}.${index}.nipItem`}
            label={`${t("consultation:sickLeaveNip")} ${index > 0 ? index + 1 : ""}*`}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: null,
              required: true
            }}
            className={classes.nipField}
          />
          {
            showAddIcon && (
              <Box
                display="flex"
              >
                {
                  showTrashIcon && trashIconContent
                }
                {addIconContent}
              </Box>
            )
          }
          {
            (showTrashIcon && !showAddIcon) && trashIconContent
          }
        </Box>
      </Box>
    )
  })

  return (
    <Box>
      {nipFieldsItems}
    </Box>
  )
}

export default NipFields
