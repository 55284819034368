import { getWordpressPostsConfig } from "../../api/routes"
import { wordpressService } from "../store.service"
import { WordpressData, WordpressPayload, WordpressResponse } from "./wordpress.types"

export const wordpressApi = wordpressService.injectEndpoints({
  endpoints: build => ({
    getWordpress: build.query<WordpressData[], WordpressPayload>({
      query: (query) => ({
        ...getWordpressPostsConfig(query)
      }),
      transformResponse: (response: WordpressResponse[]) => response.map(result => ({
        id: result?.id,
        title: result?.title,
        content: result?.content,
        link: result?.link,
        _embedded: result._embedded,
        date: result.date,
      }))
    })
  })
})

export const { useGetWordpressQuery } = wordpressApi
