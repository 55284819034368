import {useTranslation} from "react-i18next"
import Cookies from "js-cookie"
const CLINIC_DOMAIN = "clinicDomain"
const LANGUAGE = "language"

const useUserCookies = (): void => {
  const host = window.location.hostname
  const firstDotIndex = window.location.hostname.indexOf(".")
  const domain = window.location.hostname.substring(firstDotIndex + 1)
  const { i18n } = useTranslation()
  const lang = i18n.language

  Cookies.get(CLINIC_DOMAIN) !== host && Cookies.set(CLINIC_DOMAIN, host, { expires: 1, domain: `.${domain}` })
  Cookies.get(LANGUAGE) !== lang && Cookies.set(LANGUAGE, lang, { expires: 1, domain: `.${domain}` })
}

export default useUserCookies
