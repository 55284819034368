import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import {useHistory} from "react-router"
import {Box, Button, Typography} from "@material-ui/core"
import i18next from "i18next"

import { analytics, LogEventType } from "../../services/analytics"
import PageSection from "../common/pageSection/PageSection.component"
import {useForgotPasswordStyles} from "./ForgotPassword.styles"

interface PasswordChangesSucessBoxProps {}

const PasswordChangesSucessBox: FC<PasswordChangesSucessBoxProps> = () => {
  const { t } = useTranslation()
  const {push} = useHistory()
  const classes = useForgotPasswordStyles()

  const goToLoginPage = () => {
    push(`/${i18next.language}/login`)
    analytics.sendEventWithDefaultParams(LogEventType.FORGOT_PASSWORD_BACK_TO_LOGIN)
  }

  return (
    <PageSection title={t("forgotPassword:passwordChanged")}>
      <Box className={classes.sectionBox}>
        <Box mb={1}>
          <Typography variant={"body2"}>
            {t("forgotPassword:passwordChangedDescription")}
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          mt={3}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={goToLoginPage}
          >
            {t("forgotPassword:goToLoginPage")}
          </Button>
        </Box>
      </Box>
    </PageSection>
  )
}

export default PasswordChangesSucessBox
