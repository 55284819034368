import {createStyles, makeStyles} from "@material-ui/core"

export const usePhysicalActivityMeasurementLogStyles = makeStyles(theme => createStyles({
  imageContainer: {
    margin: theme.spacing(0, 4, 0, 8)
  },
  downloadAppImage: {
    width: 160,
    height: 100,
    margin: theme.spacing(0, "auto", 4)
  },
  description: {
    marginBottom: theme.spacing(4)
  }
}))