import { createTheme } from "@material-ui/core"

const theme = createTheme()

const MuiDialog = {
  PaperProps: {
    style: {
      padding: theme.spacing(3),
    },
  },
}

export const dialog = {
  MuiDialog,
}
