import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useUserDetailsSectionStyles = makeStyles((theme) => createStyles({
  userDetailsSection: {
    marginTop: theme.spacing(3.5),
  },
  userData: {
    fontWeight: 700,
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(.5),
  },
  addressItem: {
    lineHeight: `${theme.spacing(2.5)}px`
  },
  editBtn: {
    position: "relative",
    left: "10px",
    top: "-2px"
  },
  phoneNumberField: {
    marginBottom: theme.spacing(5),
  },
  header: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
    },
  }
}))
