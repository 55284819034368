import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useConfirmationButtonsStyles = makeStyles(theme => createStyles({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    }
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }
  },
  marginButton: {
    margin: theme.spacing(0, 2, 0, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0, 2, 0),
    }
  }
}))