import {add, parse} from "date-fns"

import {ConsultationExamReferral} from "../../../exam/Exam.types"
import {
  Availability,
  Doz,
  DozItem,
  PrescriptionModel,
  PrescriptionPositionModel
} from "../../../prescription/Prescription.types"

const calculateDozItemPrice = (
  prescriptionItems: PrescriptionModel[],
  doz: Doz
): string =>
  prescriptionItems.map(item => item.positions.reduce((items: DozItem[], position: PrescriptionPositionModel) => {
    const item = doz.items.find(item => parseInt(position.code_e_a_n, 10) === item.ean)
    return (item && item.availability === Availability.ACTIVE && position.quantity > 0)
      ? [...items, {
        ...item,
        quantity: position.quantity,
      }]
      : items
  }, []))
    .flat()
    .reduce((total, item) => total + (item.quantity * item.price), 0)
    .toFixed(2)
    .replace(".", ",")

const calculateExaminationPrice = (examReferrals: ConsultationExamReferral[]): string =>
  examReferrals
    .reduce((cost, item) => cost + parseFloat(item.lab_price), 0)
    .toFixed(2)
    .replace(".", ",")

const checkIfPrescriptionValid = (date?: string) => {
  if (!date) {
    return false
  }

  const validToDate = add(parse(date, "yyyy-MM-dd HH:mm:ss", new Date()), {days: 30}) // the prescription is valid for 30 days: https://pacjent.gov.pl/e-recepta/jak-dlugo-wazna-jest-e-recepta
  return new Date() <= validToDate
}

export const useOrderExaminationButton = (examReferrals: ConsultationExamReferral[]): {
  price: string;
  shouldShowOrderExaminationButton: boolean;
} => {
  const price = calculateExaminationPrice(examReferrals)
  const isBought = examReferrals.every(item => item.is_bought)
  const isNoExaminationIncludedInPackage = !(examReferrals.some(item => item.included_in_package))
  const shouldShowOrderExaminationButton = !isBought || !isNoExaminationIncludedInPackage

  return {
    price,
    shouldShowOrderExaminationButton
  }
}

export const useOrderMedicinesButton = (prescriptionItems: PrescriptionModel[], doz: Doz): {
  dozItemPrice: string;
  shouldShowOrderMedicinesButton: boolean;
} => {
  const dozItemPrice = calculateDozItemPrice(prescriptionItems, doz)
  const prescription: PrescriptionModel | undefined = prescriptionItems[0]
  const getPositionDoz = (ean: PrescriptionPositionModel["code_e_a_n"]): DozItem | undefined => {
    return doz.items.find(item => parseInt(ean, 10) === item.ean)
  }
  const dozItems = prescription?.positions.reduce((items: DozItem[], position: PrescriptionPositionModel) => {
    const item = getPositionDoz(position.code_e_a_n)
    return (item && item.availability === Availability.ACTIVE && position.quantity > 0)
      ? [...items, {
        ...item,
        quantity: position.quantity,
      }]
      : items
  }, [])
  const shouldShowOrderMedicinesButton =
    dozItems?.length > 0 &&
    checkIfPrescriptionValid(prescription?.date) &&
    dozItemPrice !== "0,00"

  return {
    dozItemPrice,
    shouldShowOrderMedicinesButton
  }
}