import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"

import { RoutePath } from "../../../../../routes/Routes.types"
import {useAwardSuccessfullyReceivedModalStyles} from "./AwardSuccessfullyReceivedModal.styles"

interface AwardSuccessfullyReceivedModalProps {
  isOpen: boolean;
  awardUrl: string;
  code?: string;
}

const AwardSuccessfullyReceivedModal: FC<AwardSuccessfullyReceivedModalProps> = ({
  isOpen,
  awardUrl,
  code,
}) => {
  const { t, i18n } = useTranslation()
  const {
    popup,
    imageWrapper,
    image,
    codeWrapper,
    button,
  } = useAwardSuccessfullyReceivedModalStyles()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const { goBack } = useHistory()

  return (
    <Dialog
      open={isOpen}
      maxWidth={"md"}
      classes={{
        paper: popup
      }}
      fullScreen={!isSmUp}
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="center"
        >
          <Typography
            variant={isSmUp ? "h2" : "h3"}
            component="h5"
          >
            {t("healthPlanner:successfullyReceivedAwardTitle")}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            variant="body1"
          >
            {t("healthPlanner:successfullyReceivedAwardDescription")}
          </Typography>
          <Box className={imageWrapper}>
            <img
              className={image}
              src={awardUrl || ""}
              alt=""
            />
          </Box>
          <Typography
            variant="h3"
            component="span"
            className={codeWrapper}
          >
            {t("healthPlanner:voucherCode")}: <span>{code}</span>
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            width={isSmUp ? "unset" : "100%"}
            minWidth={isSmUp ? "300px" : "unset"}
            mt={8}
            order={2}
          >
            <Button
              variant="contained"
              color="primary"
              className={button}
              onClick={goBack}
            >
              {t("healthPlanner:goBackToAwardsList")}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={button}
              component={Link}
              to={`/${i18n.language}${RoutePath.HOMEPAGE}`}
            >
              {t("healthPlanner:goBackToHomepage")}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AwardSuccessfullyReceivedModal
