import React, { FC } from "react"
import { generatePath, Link } from "react-router-dom"
import { Box, Button, Typography} from "@material-ui/core"
import clsx from "clsx"
import i18next from "i18next"

import { useGetLastMessageForThread } from "../../../../../api/queries/getLastMessageForThread/getLastMessageForThread.hook"
import { RoutePath } from "../../../../../../../routes/Routes.types"
import { useMessengerThreadItemStyles } from "./MessengerThreadItem.styles"

type MessengerThreadItemProps = {
  threadId: string;
  title: string;
  selected?: boolean;
}

const MessengerThreadItem: FC<MessengerThreadItemProps> = ({
  threadId,
  title,
  selected,
}) => {
  const {
    threadButton,
    selectedThreadButton,
    coverText,
    message
  } = useMessengerThreadItemStyles()

  const { data: { chatMessagesAggregate: lastMessage } = {} } = useGetLastMessageForThread({
    variables: { threadId }
  })

  const selectedThreadRedirect = generatePath(
    `/${i18next.language}${RoutePath.PREVENTION_MESSENGER_CHAT}`,
    { threadId }
  )

  return (
    <Button
      component={Link}
      to={selectedThreadRedirect}
      className={clsx(selected && selectedThreadButton, threadButton)}
    >
      <>
        <Typography
          variant="body1"
        >
          {title}
        </Typography>
        <Box
          className={coverText}>
          <Typography
            variant="body2"
            className={message}
          >
            {lastMessage?.nodes?.[0]?.body ?? "-----"}
          </Typography>
        </Box>
      </>
    </Button>
  )
}

export default MessengerThreadItem