import React, {FC, ReactNode} from "react"
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core"

import {useMobileTable} from "./MobileTable.styles"

type Props = {
  title: string;
  records: {
    id: string | number;
    rows: {
      name?: string;
      element: ReactNode;
    }[]
  }[]
}

const MobileTable: FC<Props> = ({ title, records }) => {
  const classes = useMobileTable()

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th">
              {title}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            records.map(record => (
              <TableRow key={record.id}>
                <TableCell>
                  {
                    record.rows.map(row => (
                      <Box
                        key={row.name}
                        className={classes.cellContent}
                      >
                        {
                          row.name && (
                            <Box className={classes.recordName}>
                              {row.name}
                            </Box>
                          )
                        }
                        {row.element}
                      </Box>
                    ))
                  }
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MobileTable
