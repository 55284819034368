import React, { ChangeEvent, FC, KeyboardEvent, useEffect,useState } from "react"
import { Controller,useForm } from "react-hook-form"
import {Box, Button, InputAdornment, TextField, useMediaQuery, useTheme} from "@material-ui/core"
import clsx from "clsx"

import api from "../../../api/api"
import {postChatMessageConfig} from "../../../api/routes"
import useConsultationLang from "../../../hooks/useConsultationLang"
import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"
import ConsultationChannelChange from "../consultationChatTopBox/consultationChannelChange/ConsultationChannelChange.component"
import AddConsultationFile from "./addConsultationFile/AddConsultationFile.component"
import { have24HoursPassedAfterConsultationClosing } from "../../consultation/Consultation.utils"
import {ChatMessageMessageType, ChatMessageType, ConsultationChatItem} from "../ConsultationChat.types"
import {SendNewMessageDateType} from "./SendNewMessageForm.types"
import {useSendNewMessageFormStyles} from "./SendNewMessageForm.styles"

import ConsultationAdditionalMessageConfirmPopup from "./consultationAdditionalMessageConfirmPopup/ConsultationAdditionalMessageConfirmPopup"
import ConsultationAdditionalMessageTooltip from "./consultationAdditionalMessageTooltip/ConsultationAdditionalMessageTooltip"

interface SendNewMessageFormProps {
  consultationId: string;
  patientId?: string;
  consultationClosedMessage: ConsultationChatItem | null;
  consultationFinishedAt: string | null;
  hasBeenAdditionalMessageSent: boolean;
  addNewPatientMessage: (newPatientMessage: ConsultationChatItem) => void;
  consultationStatus: number;
  consultationType: number;
}

const SendNewMessageForm: FC<SendNewMessageFormProps> = (
  {
    consultationId,
    patientId,
    consultationClosedMessage,
    consultationFinishedAt,
    hasBeenAdditionalMessageSent,
    addNewPatientMessage,
    consultationStatus,
    consultationType,
  }
) => {
  const classes = useSendNewMessageFormStyles()

  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const consultationLang = useConsultationLang()

  const [isMessageSending, setIsMessageSending] = useState<boolean>(false)
  const [globalError, setGlobalError] = useState<string>("")
  const [openLastMessageConfirmPopper, setOpenLastMessageConfirmPopper] = useState<boolean>(false)

  const form = useForm<SendNewMessageDateType>({
    defaultValues: {
      newMessageValue: "",
    }
  })
  const newMessageValue = form.watch("newMessageValue")

  const consultationClosed = !!consultationClosedMessage

  /* one last message for 24h since consultation closing condition */
  const isAbove24HoursAfterConsultationClose = () => {
    if (!consultationClosed) return false
    if (consultationFinishedAt) {
      return have24HoursPassedAfterConsultationClosing(consultationFinishedAt)
    }
    const now = Date.now() / 1000 // unify with createdAt value from pusher messages (time in seconds)
    const oneDay = 60 * 60 * 24
    const consultationCloseTime = consultationClosedMessage!.createdAt
    return now > (consultationCloseTime + oneDay)
  }

  const isSendAdditionalMessageAllowed = consultationClosed
   && !isAbove24HoursAfterConsultationClose()
   && !hasBeenAdditionalMessageSent
  /* e/o one last message for 24h conditions */

  const sendMessage = async (message: string) => {
    const newChatMessage = {
      consultationId: consultationId,
      createdAt: Date.now() / 1000, // unify with createdAt value from pusher messages (time in seconds)
      messageRaw: message,
      message: ChatMessageMessageType.NEW_SENDING_PATIENT_MESSAGE,
      messageRawType: ChatMessageType.OWN_NORMAL,
      messageType: ChatMessageType.OWN_NORMAL,
      type: 1,
      sender: patientId || null,
    }
    addNewPatientMessage(newChatMessage)

    try {
      await api.request({
        ...postChatMessageConfig,
        data: {
          consultation: consultationId,
          messageType: "1",
          message: message.trim()
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  const onSubmit = form.handleSubmit(async (values) => {
    if (!newMessageValue) {
      return
    }

    if (consultationClosed && hasBeenAdditionalMessageSent) {
      setGlobalError(consultationLang.getLabel("errors:additionalMessageWasSent"))
      return
    }

    if (consultationClosed && isAbove24HoursAfterConsultationClose()) {
      setGlobalError(consultationLang.getLabel("errors:additionalMessageAbove24HoursError"))
      return
    }

    setIsMessageSending(true)
    const newMessage = values.newMessageValue.trim()
    form.reset()
    sendMessage(newMessage)

    setIsMessageSending(false)
  })

  const handleMessageChange = (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value.trimStart())
  }

  const handleKeyPress = async (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      if (consultationClosed) {
        setOpenLastMessageConfirmPopper(true)
      } else {
        await onSubmit()
      }
    }
  }

  /* last message confirm popup */
  const handleSendButtonClick = async () => {
    if (consultationClosed) {
      setOpenLastMessageConfirmPopper(true)
    } else {
      await onSubmit()
    }
  }

  const onLastMessagePopupConfirm = async () => {
    setOpenLastMessageConfirmPopper(false)
    await onSubmit()
  }
  /* e/o last message confirm popup */

  useEffect(() => () => {
    setIsMessageSending(false)
    setGlobalError("")
  }, [])

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={onSubmit}
      className={classes.form}
    >
      <Controller
        name="newMessageValue"
        control={form.control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            autoFocus
            value={value}
            placeholder={(consultationClosed && !isSendAdditionalMessageAllowed)
              ? ""
              : consultationLang.getLabel(`chatConsultation:${consultationClosed  ? "additionalMessageHere" : "messageHere"}`)
            }
            error={!!error || !!globalError}
            helperText={error?.message || globalError}
            fullWidth
            multiline
            minRows={3}
            maxRows={5}
            disabled={consultationClosed && !isSendAdditionalMessageAllowed}
            InputProps={{
              classes: {
                root: classes.textField,
              },
              endAdornment: isSendAdditionalMessageAllowed && (
                <InputAdornment position="end">
                  <ConsultationAdditionalMessageTooltip/>
                </InputAdornment>
              )
            }}
            onKeyPress={handleKeyPress}
            onChange={handleMessageChange(onChange)}
          />
        )}
      />

      <Box className={classes.buttonsWrapper}>
        <Box
          display="flex"
          flexDirection="row"
        >
          <ConsultationChannelChange
            sendMessage={sendMessage}
            consultationType={consultationType}
            consultationStatus={consultationStatus}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
        >
          <AddConsultationFile
            consultationId={consultationId}
            disabled={consultationClosed || (consultationClosed && !isSendAdditionalMessageAllowed)}
          />

          <Button
            variant="contained"
            color="primary"
            type={consultationClosed ? "button" : "submit"}
            disabled={isMessageSending || (consultationClosed && !isSendAdditionalMessageAllowed)}
            className={clsx(!newMessageValue && "pointer-events-none")}
            onClick={handleSendButtonClick}
          >
            { isMdUp
              ? consultationLang.getLabel("send")
              : <IconByIntegrationType iconName={"icon-paperplane"}/>
            }
          </Button>
        </Box>
      </Box>

      <ConsultationAdditionalMessageConfirmPopup
        open={openLastMessageConfirmPopper}
        onClose={() => {setOpenLastMessageConfirmPopper(false)}}
        onConfirm={onLastMessagePopupConfirm}
      />
    </form>
  )
}

export default SendNewMessageForm
