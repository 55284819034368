import {add} from "date-fns"

import {EXAMINATION_EXPIRATION_TIME_IN_DAYS} from "../../consultation/Consultation.config"
import {isAfterDate} from "../../../utils/dateFns/isAfterDate"
import {COMING_EXAM_ITEMS_LIMIT} from "../../consultation/Consultation.utils"
import {ExamModel} from "../Exam.types"

export const filterOutdatedExams = (exams: ExamModel[]): ExamModel[] => 
  exams.filter(exam => !isAfterDate(
    new Date(),
    add(new Date(exam.created_at), { days: EXAMINATION_EXPIRATION_TIME_IN_DAYS })
  ))

export const sliceExamsByPage = (exams: ExamModel[], page: number): ExamModel[] => {
  return exams.slice(
    0,
    page === 1
      ? 1
      : ((page - 1) * COMING_EXAM_ITEMS_LIMIT) + 1
  )
}