import {useAppSelector} from "../hooks/storeHooks"
import {selectAuthenticated} from "../store/session/session.selectors"

export const IsUnauthenticatedAllianzUser = (): boolean => {
  const authenticated = useAppSelector(selectAuthenticated)

  const hostname = window.location.hostname

  const allianzHostnames = [
    "tst.zdrowie.allianz.pl",
    "zdrowietst.allianz.pl",
    "zdrowie.allianz.pl"
  ]

  if(allianzHostnames.includes(hostname) && !authenticated) {
    return true
  }

  return false
}

export const IsUnauthenticated = (): boolean => {
  const authenticated = useAppSelector(selectAuthenticated)

  if(authenticated) {
    return true
  }

  return false
}

export const getRedirectUrl = (): string|null => {
  const hostname = window.location.hostname

  const allianzHostnames = {
    "tst.zdrowie.allianz.pl": "https://zaloguj-pre.allianz.pl/cas/login",
    "zdrowietst.allianz.pl": "https://zaloguj-pre.allianz.pl/cas/login",
    "zdrowie.allianz.pl": "https://zaloguj.allianz.pl/cas/login",
  }

  if(allianzHostnames.hasOwnProperty(hostname)){
    return allianzHostnames[hostname]
  }

  return null
}
