import { MouseEvent,useState } from "react"

import { useAppDispatch } from "../../../hooks/storeHooks"
import { analytics, LogEventType } from "../../../services/analytics"
import { clearSession } from "../../../store/session/session.slice"

export const useUserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const dispatch = useAppDispatch()

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logOut = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_NAVIGATION_BAR_LOGOUT_CLICK)
    handleClose()
    dispatch(clearSession())
  }

  const handleGoToMyProfilePage = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_NAVIGATION_BAR_MY_DATA_CLICK)
    handleClose()
  }

  const handleGoToFinancesPage = () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_NAVIGATION_BAR_FINANCE_CLICK)
    handleClose()
  }

  const handleGoToSwitchProfilePage = ()=> {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_NAVIGATION_BAR_SWITCH_ACCOUNT_CLICK)
    handleClose()
  }

  return {
    anchorEl,
    open,
    handleMenu,
    handleClose,
    logOut,
    handleGoToMyProfilePage,
    handleGoToFinancesPage,
    handleGoToSwitchProfilePage
  }
}