import {createSlice, PayloadAction, Reducer} from "@reduxjs/toolkit"

import { getUniqueItemsByKey } from "../../utils/getUniqueItems"
import {ExamsState} from "./exams.types"

const initialState: ExamsState = {
  comingExams: {
    items: [],
    loading: false,
    error: null,
    total: 0,
  },
  examsWithResults: {
    items: null,
    loading: false,
    error: null,
    total: 0,
  }
}

export const examsSlice = createSlice({
  name: "exams",
  initialState,
  reducers: {
    getComingExams: (state: ExamsState) => {
      state.comingExams.loading = true
      state.comingExams.error = null
      return
    },
    setComingExams: (state: ExamsState, action: PayloadAction<ExamsState["comingExams"]["items"]>) => {
      state.comingExams.items = getUniqueItemsByKey([...state.comingExams.items, ...action.payload], "id")
      state.comingExams.loading = false
      return
    },
    setComingExamsTotal: (state: ExamsState, action: PayloadAction<ExamsState["comingExams"]["total"]>) => {
      state.comingExams.total = action.payload
    },
    setComingExamsError: (state: ExamsState, action: PayloadAction<ExamsState["comingExams"]["error"]>) => {
      state.comingExams.error = action.payload
      state.comingExams.loading = false
      return
    },
    clearComingExams: (state: ExamsState) => {
      state.comingExams = initialState.comingExams
      return
    },
    getExamsWithResults: (state: ExamsState) => {
      state.examsWithResults.loading = true
      state.examsWithResults.error = null
      return
    },
    setExamsWithResults: (state: ExamsState, action: PayloadAction<ExamsState["examsWithResults"]["items"]>) => {
      state.examsWithResults.items = action.payload
      state.examsWithResults.loading = false
      return
    },
    setExamsWithResultsError: (state: ExamsState, action: PayloadAction<ExamsState["comingExams"]["error"]>) => {
      state.examsWithResults.error = action.payload
      state.examsWithResults.loading = false
      return
    },
    clearExamsWithResults: (state: ExamsState) => {
      state.examsWithResults = initialState.examsWithResults
      return
    },
  },
})

export const {
  getComingExams,
  setComingExams,
  setComingExamsTotal,
  setComingExamsError,
  getExamsWithResults,
  setExamsWithResults,
  setExamsWithResultsError,
  clearComingExams,
  clearExamsWithResults,
} = examsSlice.actions

export default examsSlice.reducer as Reducer<typeof initialState>
