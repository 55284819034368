import React, { FC, useState } from "react"
import { Box, Typography } from "@material-ui/core"
import clsx from "clsx"

import { useAppSelector } from "../../../../hooks/storeHooks"
import useConsultationLang from "../../../../hooks/useConsultationLang"
import { selectClinicSettings } from "../../../../store/clinic/clinicSettings.selectors"
import AppDialog from "../../../common/appDialog/AppDialog.component"
import ConsultationTypeIcon from "../../../consultation/consultationTypeIcon/ConsultationTypeIcon.component"
import { ConsultationStatusNumber, ConsultationType } from "../../../consultation/Consultation.types"
import { useConsultationChannelChangeStyles } from "./ConsultationChannelChange.styles"

interface ConsultationTypeSelectProps {
  sendMessage: (message: string) => void;
  consultationType: ConsultationType;
  consultationStatus?: number;
}

const ConsultationChannelChange: FC<ConsultationTypeSelectProps> = (
  {
    sendMessage,
    consultationType,
    consultationStatus,
  }
) => {
  const classes = useConsultationChannelChangeStyles()
  const consultationLang = useConsultationLang()
  const [cannotChangeChannelPopupOpen, setCannotChangeChannelPopupOpen] = useState<boolean>(false)
  const [changeChannelPopupOpen, setChangeChannelPopupOpen] = useState<boolean>(false)
  const [requestMsg, setRequestMsg] = useState<string>("")
  const {
    clinicAllianzSettings: {
      ewkaVerificationEnabled,
      onyxVerificationEnabled
    }
  } = useAppSelector(selectClinicSettings)

  const isAllianz = ewkaVerificationEnabled || onyxVerificationEnabled
  const canChangeConsultationChannelType = (
    consultationStatus === ConsultationStatusNumber.CREATED ||
    consultationStatus === ConsultationStatusNumber.RESERVED ||
    consultationStatus === ConsultationStatusNumber.CONFIRMED
  )

  const isSelected = (type: number): boolean => consultationType === type

  const handleOnConsultationChannelClick = (requestMessage: string) => {
    setRequestMsg(requestMessage)
    setChangeChannelPopupOpen(true)
  }

  const sendChangeConsultationChannelRequest = async () => {
    if (consultationType === ConsultationType.QUESTION) {
      setCannotChangeChannelPopupOpen(true)
      return
    }

    sendMessage(requestMsg)
    setChangeChannelPopupOpen(false)
    setRequestMsg("")
  }

  if (!canChangeConsultationChannelType) {
    return null
  }
  
  const getClassNameByConsultationType = (type: number) => {
    const allianzClass = isAllianz
      ? classes.allianzIconBorder
      : null

    return isSelected(type)
      ? clsx(classes.consultationIconSelected, classes.consultationIconBox, allianzClass)
      : clsx(classes.consultationIconBox, allianzClass)
  }

  return (
    <>
      <Box
        className={getClassNameByConsultationType(ConsultationType.CHAT)}
        onClick={() => handleOnConsultationChannelClick(consultationLang.getLabel("chatConsultation:chatRequest"))}>
        <ConsultationTypeIcon
          consultationType={ConsultationType.CHAT}
          className={classes.consultationIcon}
          isOutlineVariant={true}
        />
      </Box>
      <Box
        className={getClassNameByConsultationType(ConsultationType.PHONE)}
        onClick={() => handleOnConsultationChannelClick(consultationLang.getLabel("chatConsultation:callRequest"))}>
        <ConsultationTypeIcon
          consultationType={ConsultationType.PHONE}
          className={classes.consultationIcon}
          isOutlineVariant={true}
        />
      </Box>
      <Box
        className={getClassNameByConsultationType(ConsultationType.VIDEO)}
        onClick={() => handleOnConsultationChannelClick(consultationLang.getLabel("chatConsultation:videoRequest"))}>
        <ConsultationTypeIcon
          consultationType={ConsultationType.VIDEO}
          className={classes.consultationIcon}
          isOutlineVariant={true}
        />
      </Box>
      <AppDialog
        open={cannotChangeChannelPopupOpen}
        title={consultationLang.getLabel("consultation:archiveTags:otherError")}
        onClose={() => setCannotChangeChannelPopupOpen(false)}
        closeButtonText={consultationLang.getLabel("close")}
      >
        <Box mb={2}>
          <Typography>
            {consultationLang.getLabel("chatConsultation:cannotChangeTypeForInstantChat")}
          </Typography>
        </Box>
      </AppDialog>
      <AppDialog
        open={changeChannelPopupOpen}
        title={consultationLang.getLabel("chatConsultation:requestConfirmation")}
        onClose={() => {
          setRequestMsg("")
          setChangeChannelPopupOpen(false)
        }}
        closeButtonText={consultationLang.getLabel("close")}
        actionButtonText={consultationLang.getLabel("confirm")}
        actionButtonProps={{
          onClick: sendChangeConsultationChannelRequest,
        }}
      >
        <Box mb={2}>
          <Typography>
            {consultationLang.getLabel("chatConsultation:requestConfirmationLabel")}
          </Typography>
        </Box>
      </AppDialog>
    </>
  )
}

export default ConsultationChannelChange
