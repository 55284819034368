import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Snackbar, Typography} from "@material-ui/core"
import {Alert} from "@material-ui/lab"

interface GlobalErrorSnackbarProps {
  isError: boolean;
  errorMessage?: string | JSX.Element;
}

const GlobalErrorSnackbar: FC<GlobalErrorSnackbarProps> = ({isError, errorMessage}) => {
  const {t} = useTranslation()

  const [openErrorSnackbar, setOpenErrorSnackbar] = useState<boolean>(false)
  const handleErrorSnackbarClose = () => {
    setOpenErrorSnackbar(false)
  }

  useEffect(() => {
    setOpenErrorSnackbar(isError)
  }, [isError])

  return (
    <Snackbar
      open={openErrorSnackbar}
      autoHideDuration={6000}
      onClose={handleErrorSnackbarClose}
    >
      <Alert
        variant="filled"
        severity="error"
      >
        <Typography>
          { errorMessage || t("errors:unknownError") }
        </Typography>
      </Alert>
    </Snackbar>
  )
}

export default GlobalErrorSnackbar