import React, {FC} from "react"
import {Trans, useTranslation} from "react-i18next"
import {Link, Typography} from "@material-ui/core"

import {telemediUrl} from "../../../app.config"

const TermsOfServiceAndPrivacyPolicy: FC = () => {
  const {i18n} = useTranslation()

  return (
    <Typography
      variant="body2"
    >
      <Trans
        i18nKey="referrals:regulationsAndPrivacyPolicy"
      >
        whenPlacingAnOrder <Link href={`${telemediUrl}/${i18n.language}/terms-and-conditions`} target="_blank">termsOfService</Link> and <Link href={`${telemediUrl}/${i18n.language}/privacy-policy`}>PrivacyPolicy</Link>
      </Trans>
    </Typography>
  )
}

export default TermsOfServiceAndPrivacyPolicy
