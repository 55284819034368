import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const usePageSectionStyles = makeStyles(() => createStyles({
  root: {
    margin: "auto",
    width: "100%",
    height: "100%",
  },
}))
