import React, { useState } from "react"
import {useTranslation} from "react-i18next"
import {useParams} from "react-router"
import {Box, Button, Typography, useTheme} from "@material-ui/core"
import clsx from "clsx"

import api from "../../api/api"
import {getDeclarationPreview} from "../../api/routes"
import DeclarationStatusImg from "../../assets/images/declaration-sign-success.png"
import downloadDeclartation from "../../assets/images/download-declaration.png"
import {useAppSelector} from "../../hooks/storeHooks"
import {selectUser} from "../../store/user/user.selectors"
import LoyalityProgramPage from "../loyalityProgram/LoyalityProgramPage"
import LoaderBox from "../../components/common/loaderBox/LoaderBox.component"
import ImageByIntegrationType from "../../components/imageByIntegrationType/ImageByIntegrationType"
import DeclarationSignSteps from "./DeclarationSignSteps.component"
import {useDeclarationSignStyles} from "./DeclarationSign.styles"

const DeclarationSignSuccess = () => {
  const {t, i18n} = useTranslation()
  const theme = useTheme()
  const { id } = useParams<{ id: string }>()
  const userData = useAppSelector(selectUser)
  const [isDownloading, setIsDownloading] = useState(false)
  const downloadDeclarationPreview = async () => {
    let previewSrc = null
    setIsDownloading(true)

    const previewData = await api.request({
      ...getDeclarationPreview(id)})

    const { data } = previewData
    const blob = new Blob([data], { type: "application/pdf" })
    if (previewSrc !== null) {
      URL.revokeObjectURL(previewSrc)
    }

    previewSrc = URL.createObjectURL(blob)

    const downloadPDFDeclaration = async () => {
      const link = document.createElement("a")
      link.href = previewSrc
      link.download = `${userData?.first_name}_${userData?.last_name}.pdf`
      link.click()
    }

    await downloadPDFDeclaration()
    setIsDownloading(false)
  }

  const classes = useDeclarationSignStyles()

  return (
    <Box className={classes.container}>
      <Box
        borderRadius={theme.shape.borderRadius}
        bgcolor={theme.palette.background.paper}
        className={classes.pageBox}
      >
        <Box className={classes.section}>
          <DeclarationSignSteps />
        </Box>
        <Box className={classes.statusInfoContainer}>
          <Box>
            <Box className={classes.statusInfo}>
              <Box>
                <Box>
                  <Typography variant="h2">
                    {t("consultation:changeTermReady")}
                  </Typography>
                </Box>
                <Box className={classes.failMessage}>
                  <Typography className={classes.declarationSignFailed}>
                    {t("user:declarationSignSuccess")}
                  </Typography>
                  <Box className={classes.actionBtn}>
                    <div onClick={downloadDeclarationPreview} className={classes.downloadAction}>
                      <ImageByIntegrationType
                        imageSrc={downloadDeclartation}
                        alt="downloadDeclartation"
                        imagePath={"download-declaration.png"}
                      />
                      <span className={isDownloading ? clsx(classes.downloadDeclaration, classes.isDownloading) : classes.downloadDeclaration}>
                        {t("user:downloadDeclaration")}
                      </span>
                    </div>
                    {isDownloading && <LoaderBox boxType="fullPage" boxClassName="b2b-loader"/>}
                    <Box mt={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => window.location.href = `/${i18n.language}`}
                      >
                        {t("passwordless:accessCodeTitle")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <ImageByIntegrationType
              imageSrc={DeclarationStatusImg}
              alt="DeclarationStatusImg"
              className={classes.image}
              imagePath={"declaration-sign-success.png"}
            />
          </Box>
        </Box>
      </Box>
      <LoyalityProgramPage showMedicalSurvey={true}/>
    </Box>
  )
}

export default DeclarationSignSuccess