import {useQuery} from "@apollo/client"

import {GetSignDeclarationActionResponse} from "./getSignDeclarationAction.types"
import {QueryHookOptions} from "@apollo/client/react/types/types"

import {GET_SIGN_DECLARATION_ACTION} from "./getSignDeclarationAction.query"

export const useGetSignDeclarationAction = (options?: QueryHookOptions<GetSignDeclarationActionResponse>) => {
  return useQuery<GetSignDeclarationActionResponse>(
    GET_SIGN_DECLARATION_ACTION,
    {
      context: { clientName: "HealthPlanner" },
      ...options
    }
  )
}