import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import AppDialog from "../../common/appDialog/AppDialog.component"

type PrescriptionRejectedPopupProps = {
  open: boolean;
  onClose(): void;
}

const PrescriptionRejectedPopup: FC<PrescriptionRejectedPopupProps> = ({open, onClose}) => {
  const {t} = useTranslation()

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={t("prescription:rejected")}
      closeButtonText={t("close")}
    >
      <Box mb={1}>
        <Typography>
          {t("prescription:rejectedInfo")}
        </Typography>
        <Box mt={3}>
          <Typography>
            {t("prescription:rejectedAdditionalInfo")}
          </Typography>
        </Box>
      </Box>
    </AppDialog>
  )
}

export default PrescriptionRejectedPopup
