import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Route, Switch } from "react-router-dom"
import { push } from "connected-react-router"

import withBaseLayout from "../../hoc/withBaseLayout"
import { useAppDispatch } from "../../hooks/storeHooks"
import Error404Page from "../errors/Error404Page"
import HealthPlannerDashboard from "./components/healthPlannerDashboard/HealthPlannerDashboard.component"
import HealthPlannerProvider from "./components/healthPlannerProvider/HealthPlannerProvider.component"
import BmiAction from "./pages/bmiAction/BmiAction.component"
import PreventionActionSummary from "./pages/preventionActionSummary/PreventionActionSummary.component"
import { checkFeatureAvailability } from "../../store/clinic/clinicSettings.utils"
import { RoutePath } from "../../routes/Routes.types"

import AwardsDetailsPage from "./pages/awardDetailsPage/AwardDetailsPage"
import AwardsPage from "./pages/awards/AwardsPage"
import DiaryBloodPressure from "./pages/diaryBloodPressure/DiaryBloodPressure"
import DiaryGlycemia from "./pages/diaryGlycemia/DiaryGlycemia"
import DiaryPulse from "./pages/diaryPulse/DiaryPulse"
import DiaryWeight from "./pages/diaryWeight/DiaryWeight"
import MessengerChatPage from "./pages/messengerChatPage/MessengerChatPage"
import PreventionAction from "./pages/preventionAction/PreventionAction"
import PreventionDiseaseDetails from "./pages/preventionDiseaseDetails/PreventionDiseaseDetails"
import PreventionDiseases from "./pages/preventionDiseases/PreventionDiseases"
import PreventionDiseasesQuestionnaire from "./pages/preventionDiseasesQuestionnaire/PreventionDiseasesQuestionnaire"
import PreventionQuestionnaire from "./pages/preventionQuestionnaire/PreventionQuestionnaire"

const HealthPlanner: FC = () => {
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch()

  if (
    !checkFeatureAvailability().enabledHealthPlanner
      && !checkFeatureAvailability().enabledMentalHealth
  ) {
    dispatch(push(`/${i18n.language}`))
  }
    
  return (
    <HealthPlannerProvider>
      <Switch>
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION}`}
          component={HealthPlannerDashboard}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_AWARDS}`}
          component={AwardsPage}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_AWARDS_AWARD}`}
          component={AwardsDetailsPage}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_ACTION}`}
          component={PreventionAction}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_ACTION_SUMMARY}`}
          component={PreventionActionSummary}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_DISEASES}`}
          component={PreventionDiseases}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_DISEASE_DETAILS}`}
          component={PreventionDiseaseDetails}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_DISEASES_QUESTIONNAIRE}`}
          component={PreventionDiseasesQuestionnaire}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_QUESTIONNAIRE}`}
          component={PreventionQuestionnaire}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_DIARY_PULSE}`}
          component={DiaryPulse}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_DIARY_BLOOD_PRESSURE}`}
          component={DiaryBloodPressure}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_DIARY_GLYCEMIA}`}
          component={DiaryGlycemia}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_DIARY_WEIGHT}`}
          component={DiaryWeight}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_BMI_ACTION}`}
          component={BmiAction}
        />
        <Route
          exact
          path={`/${i18n.language}${RoutePath.PREVENTION_MESSENGER_CHAT}`}
          component={MessengerChatPage}
        />
        <Route
          path={RoutePath.FALLBACK}
          component={withBaseLayout(Error404Page)}
        />
      </Switch>
    </HealthPlannerProvider>
  )
}

export default HealthPlanner