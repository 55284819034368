import React, { FC } from "react"
import {useTranslation} from "react-i18next"

import error404Image from "../../assets/images/page-404.png"
import ErrorPageCard from "../../components/common/errorPageCard/ErrorPageCard.component"

const Error404Page: FC = () => {
  const {t} = useTranslation()

  return (
    <ErrorPageCard
      errorCode={404}
      title={t("errors:page404:title")}
      description={t("errors:page404:description")}
      image={error404Image}
      imagePath={"page-404.png"}
    />
  )
}

export default Error404Page