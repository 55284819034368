import React, {FC} from "react"
import {Box, Typography} from "@material-ui/core"
import Rating from "@material-ui/lab/Rating"

import DoctorImageAvatar from "../../common/imageAvatar/doctorImageAvatar/DoctorImageAvatar.component"
import {useDoctorDetailsStyles} from "./DoctorDetails.styles"

interface ConsultationPaymentDoctorDetailsProps {
  doctorGrade: number;
  doctorImage: string | null;
  doctorSpecialization: string;
  doctorTitle: string;
}

const ConsultationPaymentDoctorDetails: FC<ConsultationPaymentDoctorDetailsProps>  = (
  {
    doctorImage,
    doctorSpecialization,
    doctorTitle,
    doctorGrade,
  }
) => {
  const classes = useDoctorDetailsStyles({customConsultationTypeColor: null})

  return (
    <Box
      display="flex"
    >
      <DoctorImageAvatar
        className={classes.doctorImageAvatar}
        src={doctorImage || undefined}
        alt=""
      />
      <Box
        ml={2.5}
        mt={1}
      >
        <Typography variant="subtitle1">
          {doctorTitle}
        </Typography>

        <Typography variant="subtitle1">
          {doctorSpecialization}
        </Typography>
        <Rating
          value={doctorGrade}
          size="medium"
          disabled
        />
      </Box>
    </Box>
  )
}

export default ConsultationPaymentDoctorDetails