import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import {PrescriptionPositionModel} from "../Prescription.types"

interface PrescriptionPositionProps extends PrescriptionPositionModel {}

const PrescriptionPosition: FC<PrescriptionPositionProps> = ({
  name,
  quantity,
  package: pack,
  refoundation,
  hiddenBorder,
}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"))

  const positionQuantity = (() => {
    if (quantity && pack) {
      return `${quantity}x${pack}`
    }
    return pack || ""
  })()

  return (
    <Box
      p={2}
      className={hiddenBorder ? "hiddenBorder" : ""}
    >
      <Box
        display="flex"
        alignItems="flex-start"
      >
        <Typography variant={isSmUp ? "body1" : "subtitle1"}>
          {name}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="baseline"
      >
        <Typography variant="subtitle1" color="textSecondary">
          {positionQuantity}
        </Typography>
        <Box ml="auto">
          <Typography variant="subtitle1" color="textSecondary">
            {refoundation ? t(`prescription:${refoundation}`) : "\u2014"}
            {/*\u2014 === &mdash;*/}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default PrescriptionPosition
