import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"

import {useAppSelector} from "../../../hooks/storeHooks"
import {useSendMedicalQuestionnaireMutation} from "../../../store/medicalQuestionnaire/medicalQuestionnaire.api"
import {selectUser} from "../../../store/user/user.selectors"
import {ExamListItem} from "../../../components/exam/examsSelect/ExamsSelect.types"
import {unwrap} from "@material-ui/core/test-utils"
import {EmploymentType, QuestionAnswer, Sex, StepsData} from "./MedicalQuestionnaireStepperForm.types"

import { StepNumber } from "./MedicalQuestionnaireStepperForm.enums"

export const useMedicalStepperFormManager = () => {
  const user = useAppSelector(selectUser)
  const [stepsData, setStepData] = useState<Partial<StepsData>>({
    [0]: {
      weight: undefined,
      height: undefined,
      sex: user?.gender == undefined ? undefined : (user.gender == 0 ? Sex.FEMALE : Sex.MALE),
      sexChangeIsLocked: user?.gender != undefined,
      isPregnant: undefined,
      isBreastFeeding: undefined,
      hasChronicDiseases: undefined,
      hasChronicDiseasesInput: "",
      isTakingAnyMedicines: undefined,
      isTakingAnyMedicinesInput: "",
      hasAnyAllergies: undefined,
      hasAnyAllergiesInput: "",
      visitReason: {
        isPrescriptionNeeded: false,
        isSickLeaveNeeded: false,
        isReferralNeeded: false,
        isTalkWithDoctorNeeded: false
      }
    }
  })
  const [sendMedicalQuestionnaire] = useSendMedicalQuestionnaireMutation({
    fixedCacheKey: "sendMedicalQuestionnaire",
  })
  const {consultationId} = useParams<{ consultationId: string }>()
  const {push} = useHistory()
  const {i18n} = useTranslation()

  const handleStepData = <StepNumberType extends keyof StepsData>(stepNumber: StepNumberType) =>
    (stepData: StepsData[StepNumberType]) => {
      setStepData(previousState => ({
        ...previousState,
        [stepNumber]: stepData,
      }))
      const shouldRedirectToConsultation = stepNumber === StepNumber.BASIC_INFORMATION
                && "visitReason" in stepData
                && stepData.visitReason.isTalkWithDoctorNeeded
                && Object.values(stepData.visitReason).filter(Boolean).length === 1

      if (shouldRedirectToConsultation) {
        push(`/${i18n.language}/consultation/${consultationId}`)
      }

      const basicInformation = stepsData[StepNumber.BASIC_INFORMATION]
      const patientsRequirements = stepsData[StepNumber.PATIENTS_REQUIREMENTS]
      if (
        stepNumber === StepNumber.SUMMARY
                && basicInformation
                && patientsRequirements
      ) {
        const formattedData = formatDataForAPI(stepsData)
        sendMedicalQuestionnaire({...formattedData, consultationId: consultationId})
          .unwrap()
          .then(() => {
            push(`/${i18n.language}/consultation/${consultationId}`)
          })
      }
    }

  const redirectToConsultation = () => {
    push(`/${i18n.language}/consultation/${consultationId}`)
  }

  return {
    stepsData,
    handleStepData,
    redirectToConsultation,
  }

  function formatDataForAPI(steps: Partial<StepsData>): any {
    const examList = formatExamsListDataForApi( steps[StepNumber.PATIENTS_REQUIREMENTS]?.referral)
    const prescriptionList = formatPrescriptionListDataForApi(steps[StepNumber.PATIENTS_REQUIREMENTS]?.prescription)
    const nips = steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.nip?.map((nip: any) => {
      return nip.nipItem
    })
    const formattedJSON: any = {
      "sickLeaveSurvey": {
        "employmentType": steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.employmentType ? steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.employmentType?.valueOf() : 1,
        "startAt": steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.startAt || "",
        "endAt": steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.endAt || "",
        "nip": nips,
        "foreignCompanyData": {
          "nip": (steps[StepNumber.PATIENTS_REQUIREMENTS] as any)?.nip_foreignWorker,
          "name": (steps[StepNumber.PATIENTS_REQUIREMENTS] as any)?.companyName_foreignWorker,
          "street": (steps[StepNumber.PATIENTS_REQUIREMENTS] as any)?.street_foreignWorker,
          "houseNumber": (steps[StepNumber.PATIENTS_REQUIREMENTS] as any)?.houseNumber_foreignWorker,
          "apartmentNumber": (steps[StepNumber.PATIENTS_REQUIREMENTS] as any)?.apartmentNumber_foreignWorker,
          "postalCode": (steps[StepNumber.PATIENTS_REQUIREMENTS] as any)?.postalCode_foreignWorker,
          "city": (steps[StepNumber.PATIENTS_REQUIREMENTS] as any)?.city_foreignWorker,
          "country": (steps[StepNumber.PATIENTS_REQUIREMENTS] as any)?.country_foreignWorker
        },
        "addressOfResidence": {
          "street": steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.isOtherAddressSameAsUserAddress ? steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.address?.street : steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.addressOfResidence?.street_otherAddress,
          "houseNumber": steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.isOtherAddressSameAsUserAddress ? steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.address?.houseNumber : steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.addressOfResidence?.houseNumber_otherAddress,
          "apartmentNumber": steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.isOtherAddressSameAsUserAddress ? steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.address?.apartmentNumber : steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.addressOfResidence?.apartmentNumber_otherAddress,
          "postalCode": steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.isOtherAddressSameAsUserAddress ? steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.address?.postalCode : steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.addressOfResidence?.postalCode_otherAddress,
          "city": steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.isOtherAddressSameAsUserAddress ? steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.address?.city : steps[StepNumber.PATIENTS_REQUIREMENTS]?.sickLeave?.addressOfResidence?.city_otherAddress
        }
      },
      "prescriptionSurvey": {
        "prescriptions": prescriptionList || [],
        "isTalkWithDoctorNeeded": steps[StepNumber.PATIENTS_REQUIREMENTS]?.prescription?.isDoctorConsultationRequired || false
      },
      "referralSurvey": {
        "exams": examList ?? [],
        "isTalkWithDoctorNeeded": steps[StepNumber.PATIENTS_REQUIREMENTS]?.referral?.isDoctorConsultationRequired || false,
      },
      "patientSurvey": {
        "weight": steps[StepNumber.BASIC_INFORMATION]?.weight || 0,
        "height": steps[StepNumber.BASIC_INFORMATION]?.height || 0,
        "sex": steps[StepNumber.BASIC_INFORMATION]?.sex == Sex.MALE ? true : false,
        "isPregnant": steps[StepNumber.BASIC_INFORMATION]?.isPregnant || false,
        "isBreastFeeding": steps[StepNumber.BASIC_INFORMATION]?.isBreastFeeding || false,
        "diseases": steps[StepNumber.BASIC_INFORMATION]?.hasChronicDiseases ? steps[StepNumber.BASIC_INFORMATION]?.hasChronicDiseasesInput : null, // null (NIE) | string (TAK - czyli odpowiedz)
        "medicines": steps[StepNumber.BASIC_INFORMATION]?.isTakingAnyMedicines ? steps[StepNumber.BASIC_INFORMATION]?.isTakingAnyMedicinesInput : null, // null (NIE) | string (TAK - czyli odpowiedz)
        "allergies": steps[StepNumber.BASIC_INFORMATION]?.hasAnyAllergies ? steps[StepNumber.BASIC_INFORMATION]?.hasAnyAllergiesInput : null // null (NIE) | string (TAK - czyli odpowiedz)
      },
      "reasonSurvey": {
        "isPrescriptionNeeded": steps[StepNumber.BASIC_INFORMATION]?.visitReason?.isPrescriptionNeeded || false,
        "isSickLeaveNeeded": steps[StepNumber.BASIC_INFORMATION]?.visitReason?.isSickLeaveNeeded || false,
        "isReferralNeeded": steps[StepNumber.BASIC_INFORMATION]?.visitReason?.isReferralNeeded || false,
        "isTalkWithDoctorNeeded": steps[StepNumber.BASIC_INFORMATION]?.visitReason?.isTalkWithDoctorNeeded || false,
      }
    }
    return formattedJSON
  }

  function formatExamsListDataForApi(formReferralExamsData: Partial<StepsData> | undefined) {
    if (!formReferralExamsData || !Array.isArray(formReferralExamsData.exams)) {
      return []
    }

    const isReferralExamsDataNeeded = formReferralExamsData.isTalkWithDoctorNeeded
    if ( !isReferralExamsDataNeeded ){
      return null
    }

    return formReferralExamsData
      .reduce((result, exam) => {
        const isComplete = !!exam.id
        if ( isComplete ) {
          const newExam = {
            id: exam.id,
          }
          result.push(newExam)
        }

        return result
      }, [])
  }

  function formatPrescriptionListDataForApi(formPrescriptionData: Partial<StepsData> | undefined) {
    if (!formPrescriptionData || !Array.isArray(formPrescriptionData.prescriptions)) {
      return []
    }

    const isDataNeeded = formPrescriptionData.isTalkWithDoctorNeeded
    if ( !isDataNeeded ){
      return null
    }

    const prescriptionArray = prescription.prescriptions
    return prescriptionArray
      .reduce((result, prescription: (Prescription & { chosenAmount: number; chosenSize: number; })) => {
        const isPrescriptionDataComplete = prescription?.chosenAmount && prescription.chosenSize
        if ( isPrescriptionDataComplete ) {
          const newPrescription = {
            id: prescription.medical_product_id,
            chosenAmount: prescription.chosenAmount,
            chosenSize: prescription.chosenSize
          }
          result.push(newPrescription)
        }

        return result
      }, [])
  }

}
