import {createStyles,makeStyles} from "@material-ui/core"

export const useDiseaseTileStyles = makeStyles((theme) => createStyles({
  container: {
    width: 130,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    textAlign: "center"
  },
  wrapper: {
    padding: theme.spacing(1.5),
    height: 130,
    boxShadow: (props: { isActive?: boolean }) => props.isActive
      ? `0px 0px 20px -5px ${theme.palette.primary.main}`
      : theme.shadows[2]
  },
  image: {
    width: 70,
    height: 70
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&:visited": {
      color: "initial"
    }
  }
}))
