import {createStyles, makeStyles, Theme} from "@material-ui/core"

export const TOP_BAR_HEIGHT_SPACING = 11
export const TOP_BAR_MARGIN_BOTTOM_MD_UP_SPACING = 8

export const useTopBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&&": {
        marginBottom: theme.spacing(3),
        backgroundColor: theme.palette.background.default,
        position: "static",
        [theme.breakpoints.up("md")]: {
          backgroundColor: theme.palette.background.appBar,
          marginBottom: theme.spacing(TOP_BAR_MARGIN_BOTTOM_MD_UP_SPACING),
          minHeight: theme.spacing(TOP_BAR_HEIGHT_SPACING),
          position: "fixed",
        } 
      }
    },
    toolbarRoot: {
      minHeight: theme.spacing(TOP_BAR_HEIGHT_SPACING),
    },
    backButton: {
      position: "absolute",
      top: theme.spacing(3),
      left: "0",
    },
    backButtonIcon: {
      fontSize: theme.spacing(3.5),
    },
    customLogoType: {
      maxWidth: theme.spacing(21.5),
      maxHeight: theme.spacing(6),
      margin: theme.spacing(2, 0),
    },
  })
)
