import i18next from "i18next"
import * as yup from "yup"

export interface AcceptRegulationsFormValues {
  termsAndConditions: boolean | undefined;
}

export const AcceptRegulationsValidationSchema = (): yup.SchemaOf<AcceptRegulationsFormValues> => yup.object().shape({
  termsAndConditions: yup.boolean().oneOf([true], i18next.t("errors:required")),
})
