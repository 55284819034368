import {makeStyles} from "@material-ui/core"

import {boxShadows} from "../../themes/shadows"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useEmptyConsultationsBoxStyles = makeStyles((theme:Theme) => createStyles({
  root: {
    boxShadow: boxShadows.shadow1
  },
  intro: {
    marginBottom: theme.spacing(4),
  },
  introText: {
    marginBottom: theme.spacing(1.5),
  },
  col: {
    [theme.breakpoints.up("lg")]: {
      flex: 1,
    }
  },
  colContent: {
    height: "100%",
    maxWidth: "400px",
    justifyContent: "center",
    alignItems: "center"
  },
  colContentFullWidth: {
    maxWidth: "unset"
  },
  rootAdditionalStyle: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(7),
    },
  },
  emptyStateLabel: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.spacing(70),
    },
  },
  image: {
    maxWidth: "100%",
  },
  imageContainer: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    }
  }
}))
