import {gql} from "@apollo/client"

export interface DiaryModel {
  name: string;
  title: string;
  imageUrl: string;
}
    
export interface DiariesResponseData {
  diaries: DiaryModel[]
}

export const GET_DIARIES = gql`
  query getDiaries {
    diaries: examination_types {
      name
      title
      imageUrl: image_url
    }
  }
`
