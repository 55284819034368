import { combineReducers } from "@reduxjs/toolkit"
import { connectRouter } from "connected-react-router"
import { History } from "history"

import apiErrorReducer from "./apiError/apiError.slice"
import clinicSettingsReducer from "./clinic/clinicSettings.slice"
import consultationDocumentationReducer from "./consultationDocumentation/consultationDocumentation.slice"
import consultationsReducer from "./consultations/consultations.slice"
import dozPharmacyReducer from "./dozPharmacy/dozPharmacy.slice"
import examReferralsReducer from "./examReferrals/examReferrals.slice"
import examsReducer from "./exams/exams.slice"
import healthPlannerReducer from "./healthPlanner/healthPlanner.slice"
import logInReducer from "./logIn/logIn.slice"
import loginAccountsReducer from "./loginAccounts/logInAccounts.slice"
import makeConsultationWidgetReducer from "./makeConsultationWidget/makeConsultationWidget.slice"
import medicalExamsPointsReducer from "./medicalExamsPoints/medicalExamsPoints.slice"
import patientDocumentationReducer from "./patientDocumentation/patientDocumentation.slice"
import sessionReducer from "./session/session.slice"
import settingsReducer from "./settings/settings.slice"
import userReducer from "./user/user.slice"
import vouchersReducer from "./vouchers/vouchers.slice"
import {
  dozService,
  googleApisService,
  ssoService,
  v4Service,
  wordpressService
} from "./store.service"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  session: sessionReducer,
  logIn: logInReducer,
  apiError: apiErrorReducer,
  user: userReducer,
  settings: settingsReducer,
  consultations: consultationsReducer,
  exams: examsReducer,
  consultationDocumentation: consultationDocumentationReducer,
  patientDocumentation: patientDocumentationReducer,
  makeConsultationWidget: makeConsultationWidgetReducer,
  medicalExamsPoints: medicalExamsPointsReducer,
  examReferrals: examReferralsReducer,
  loginAccounts: loginAccountsReducer,
  dozPharmacy: dozPharmacyReducer,
  vouchers: vouchersReducer,
  clinic: clinicSettingsReducer,
  healthPlanner: healthPlannerReducer,
  [v4Service.reducerPath]: v4Service.reducer,
  [dozService.reducerPath]: dozService.reducer,
  [wordpressService.reducerPath]: wordpressService.reducer,
  [ssoService.reducerPath]: ssoService.reducer,
  [googleApisService.reducerPath]: googleApisService.reducer,
})

export default createRootReducer
