import React, {FC} from "react"
import {Box, Typography} from "@material-ui/core"

import ActionButtons from "./actionButtons/ActionButtons.component"
import QuestionInput from "./questionInput/QuestionInput.component"
import {useQuestionManager} from "./Question.utils"
import {CompletedQuestion, Question as QuestionType} from "../GenericStepForm.types"
import {useQuestionStyles} from "./Question.styles"

type QuestionProps = {
  data: QuestionType;
  isLastQuestion: boolean;
  isFirstQuestion: boolean;
  isLoading: boolean;
  onSaveAnswer: (completedQuestion: CompletedQuestion) => void;
  goToPreviousQuestion: VoidFunction;
  completedQuestion?: CompletedQuestion;
  onScrollToQuestion: VoidFunction;
  sendAnalytics?: boolean;
}

const Question: FC<QuestionProps> = ({
  isLastQuestion,
  isFirstQuestion,
  isLoading,
  completedQuestion,
  goToPreviousQuestion,
  onSaveAnswer,
  data,
  onScrollToQuestion,
  sendAnalytics
}) => {
  const classes = useQuestionStyles({
    inputType: data.type
  })
  
  const {
    errors,
    answers,
    relatedQuestion,
    completedRelatedQuestion,
    isNextButtonDisabled,
    handleSkip,
    handleBack,
    handleChange,
    handleSaveAnswer,
    handleSaveRelatedAnswer,
    handleBackToPreviousRelatedQuestion,
  } = useQuestionManager(
    data,
    completedQuestion,
    onSaveAnswer,
    onScrollToQuestion,
    goToPreviousQuestion,
    sendAnalytics
  )

  return relatedQuestion
    ? (
      <Question
        key={relatedQuestion.id}
        data={relatedQuestion}
        isLastQuestion={isLastQuestion}
        isFirstQuestion={false}
        isLoading={isLoading}
        onScrollToQuestion={onScrollToQuestion}
        onSaveAnswer={handleSaveRelatedAnswer}
        goToPreviousQuestion={handleBackToPreviousRelatedQuestion}
        completedQuestion={completedRelatedQuestion}
        sendAnalytics={sendAnalytics}
      />
    ) : (
      <Box>
        <Box className={classes.inputWrapper}>
          {data.showSubtitle && data.subtitle && (
            <Typography 
              variant="body1"
              className={classes.subtitleText}
            >
              {data.subtitle}
            </Typography>
          )}
          <Typography
            className={classes.inputTitle}
            variant="h3"
          >
            {data.title}
          </Typography>
          <QuestionInput
            errors={errors}
            unit={data.unit}
            answers={data.answers}
            value={answers}
            inputType={data.type}
            onChange={handleChange}
          />
        </Box>
        <ActionButtons
          onClickNextButton={handleSaveAnswer}
          onClickBackButton={handleBack}
          onClickSkipButton={handleSkip}
          isSkippable={data.skippable ?? false}
          isLastQuestion={isLastQuestion}
          isFirstQuestion={isFirstQuestion}
          isNextButtonDisabled={isNextButtonDisabled}
          isLoading={isLoading}
        />
      </Box>
    )
}

export default Question
