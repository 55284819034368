import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {Box, Tooltip, Typography} from "@material-ui/core"

import IconByIntegrationType from "../../iconByIntegrationType/IconByIntegrationType"

interface DisabledFormFieldTooltipProps {}

const DisabledFormFieldTooltip: FC<DisabledFormFieldTooltipProps> = () => {
  const {t} = useTranslation()
  return (
    <Tooltip title={
      <Box p={1}>
        <Typography>{t("user:fieldChangedByServiceDeskOnly")}</Typography>
      </Box>
    }>
      <span>
        <IconByIntegrationType {...{ component: "span", variant: "h3", color: "textSecondary" }}
          iconName={"icon-info"}
          returnTypography={true}
        />
      </span>
    </Tooltip>
  )
}

export default DisabledFormFieldTooltip
