import {
  getExamResultFileConfig,
  getMedicalDocumentFileConfig,
  getPrescriptionMedicalDocumentFileConfig
} from "../../../../api/routes"
import {FileExtension} from "../../Documentation.type"

export const mapDocumentTypeToRequestConfig = (fileId: string, documentType: FileExtension["documentType"]) => {
  switch (documentType) {
    case "trans_prescription":
      return getPrescriptionMedicalDocumentFileConfig(fileId)
    case "examsResults":
      return getExamResultFileConfig(fileId)
    default:
      return getMedicalDocumentFileConfig(fileId)
  }
}
