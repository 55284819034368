import {makeStyles} from "@material-ui/core"

import {createStyles} from "@material-ui/core/styles"

export const useNfzStatusInfoStyles = makeStyles(() => createStyles({
  description: {
    whiteSpace: "break-spaces",
  },
  disablePopupBtn: {
    cursor: "pointer",
  }
}))
