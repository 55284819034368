import React, {FC} from "react"
import {Box, CircularProgress, CircularProgressProps, useTheme} from "@material-ui/core"
import clsx from "clsx"

import {useLoaderBoxStyles} from "./LoaderBox.styles"

type BoxType = "fullPage" | "appDialog"

interface LoaderBoxProps {
  boxClassName?: string;
  boxType?: BoxType;
}

const LoaderBox: FC<LoaderBoxProps & CircularProgressProps> = (
  {
    boxClassName,
    boxType,
    ...props
  }
) => {
  const theme = useTheme()
  const classes = useLoaderBoxStyles()

  const defaultProps = {
    size: theme.spacing(16),
    thickness: 2, 
    color: "primary",
  }

  return (
    <Box
      className={clsx(
        classes.root,
        boxClassName,
        boxType && classes[boxType]
      )}
    >
      <CircularProgress
        {...defaultProps}
        {...props}
      />
    </Box>
  )
}

export default LoaderBox
