import React, { FC } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, Grid, TextField } from "@material-ui/core"

import CountrySelect from "../../../../commonFormItems/countrySelect/CountrySelect.component"
import PhoneCountryCodeSelect
  from "../../../../commonFormItems/phoneCountryCodeSelect/PhoneCountryCodeSelect.component"
import { PostalCodeInputController } from "../../../../commonFormItems/postalCodeInput/PostalCodeInput.component"
import TextFieldController from "../../../../commonFormItems/textFieldController/TextFieldController.component"
import {
  disabledFormFieldTooltipInputProps
} from "../../../../informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import { checkFeatureAvailability } from "../../../../../store/clinic/clinicSettings.utils"
import { useEsInputPlaceholderClassName } from "../../../../../utils/useEsInputPlaceholderClass"
import { UserDataType } from "../../../../../store/user/user.types"

interface UserAddressDataEditFormProps {
  loading: boolean;
  phoneNumber?: string | null;
  showPhoneNumber?: boolean;
  userData?: UserDataType;
  isEditingBlocked?: boolean;
}

const UserAddressDataEditForm: FC<UserAddressDataEditFormProps> = ({
  loading,
  phoneNumber,
  showPhoneNumber,
  userData,
  isEditingBlocked
}) => {
  const { t } = useTranslation()
  const benefitSystemIntegrationEnabled = checkFeatureAvailability().enableBenefitSystemIntegration // todo change to general checkbox
  const inputPlaceholderClassName = useEsInputPlaceholderClassName()
  const form = useFormContext()
  const countrySelected = form.watch("country")

  const handleChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setValue("phoneNumber", event.target.value)
  }

  return (
    <Box mb={4}>
      <Grid container>
        <Grid item xs={12}>
          <TextFieldController
            name="street"
            label={t("user:street")}
            disabled={loading || isEditingBlocked}
            InputProps={disabledFormFieldTooltipInputProps(userData?.address?.street, loading, isEditingBlocked)}
          />
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextFieldController
              name="houseNumber"
              label={t("user:houseNumber")}
              disabled={loading || isEditingBlocked}
              InputProps={disabledFormFieldTooltipInputProps(userData?.address?.house_number, loading, isEditingBlocked)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldController
              className={inputPlaceholderClassName}
              name="apartmentNumber"
              label={t("user:apartmentNumber")}
              disabled={loading || isEditingBlocked}
              InputProps={disabledFormFieldTooltipInputProps(userData?.address?.apartment_number, loading, isEditingBlocked)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <PostalCodeInputController
              country={countrySelected}
              name="postalCode"
              loading={!!userData?.address?.postal_code || loading}
              label={t("user:postalCode")}
              shrink={true}
              additionalProps={disabledFormFieldTooltipInputProps(userData?.address?.postal_code, loading, isEditingBlocked)}
              disabled={isEditingBlocked}
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <TextFieldController
              name="city"
              label={t("user:city")}
              disabled={loading || isEditingBlocked}
              InputProps={disabledFormFieldTooltipInputProps(userData?.address?.city, loading, isEditingBlocked)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="country"
            control={form.control}
            render={({
              field: {onChange, value},
              fieldState: {invalid, error}
            }) => (
              <CountrySelect
                id="country"
                label={t("user:country")}
                placeholder={t("user:country")}
                fullWidth
                disabled={isEditingBlocked || !!userData?.nationality || loading}
                value={value}
                onChange={onChange}
                error={invalid}
                helperText={error?.message}
                InputProps={disabledFormFieldTooltipInputProps(userData?.nationality, loading,isEditingBlocked)}
              />
            )}
          />
        </Grid>
        {(showPhoneNumber || (benefitSystemIntegrationEnabled && !phoneNumber)) && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Controller
                name="phoneCountryCode"
                control={form.control}
                render={({
                  field: {onChange, value},
                  fieldState: {invalid, error}
                }) => (
                  <PhoneCountryCodeSelect
                    id="phoneCountryCode"
                    label={t("user:phoneCountryCode")}
                    placeholder={t("user:phoneCountryCode")}
                    fullWidth
                    disabled={isEditingBlocked || !!userData?.phone_country_code || loading}
                    value={value}
                    onChange={onChange}
                    error={invalid}
                    helperText={error?.message}
                    InputProps={disabledFormFieldTooltipInputProps(userData?.phone_country_code, loading,isEditingBlocked)}
                    name="phoneNumber"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller
                name="phoneNumber"
                control={form.control}
                render={({
                  field: {onChange, value},
                  fieldState: {invalid}
                }) => (
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    label={t("user:phone")}
                    placeholder={t("user:phone")}
                    fullWidth
                    value={value}
                    disabled={isEditingBlocked || loading}
                    onChange={showPhoneNumber ? handleChangePhoneNumber : onChange}
                    error={invalid}
                    helperText={invalid ? (showPhoneNumber ? t("user:wrongStationaryPhoneNumber") : t("user:wrongPhoneNumber")) : ""}
                    InputProps={disabledFormFieldTooltipInputProps(userData?.phone_national_number, loading,isEditingBlocked)}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default UserAddressDataEditForm