import React, {FC} from "react"
import {useController} from "react-hook-form"

import DatePicker, {DatePickerProps} from "../datePicker/DatePicker.component"

interface DatePickerControllerProps extends Omit<DatePickerProps, "onChange" | "value"> {
  name: string;
}

const DatePickerController: FC<DatePickerControllerProps> = ({ name, ...props }) => {
  const { field } = useController({
    name
  })

  return (
    <DatePicker
      {...props}
      {...field}
    />
  )
}

export default DatePickerController
