import React, {FC} from "react"
import {useWatch} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Box, Typography} from "@material-ui/core"

import {calculateFullPrice} from "./OrderMedicinesSummary.utils"
import {DozOrder} from "../../../../store/doz/doz.types"
import {DozItem} from "../../../prescription/Prescription.types"
import {useOrderMedicinesSummaryStyles} from "./OrderMedicinesSummary.styles"

interface OrderMedicinesSummaryProps {
  dozMedicinesMap: Map<number, DozItem>;
}

const OrderMedicinesSummary: FC<OrderMedicinesSummaryProps> = ({ dozMedicinesMap }) => {
  const requestedItemList = useWatch<DozOrder, "requested_item_list">({ name: "requested_item_list" })
  const price = calculateFullPrice(dozMedicinesMap, requestedItemList)
  const {t} = useTranslation()
  const classes = useOrderMedicinesSummaryStyles()

  return (
    <Box
      className={classes.root}
    >
      <Typography
        variant="h3"
        color="primary"
      >
        {price} zł
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.label}
      >
        {t("orderMedicines:refundInfo")}
      </Typography>
    </Box>
  )
}

export default OrderMedicinesSummary
