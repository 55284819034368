import i18next from "i18next"
import * as yup from "yup"

import {UserPasswordChangeFormValues} from "./UserPasswordChange.types"

export const userPasswordChangeValidationSchema = (): yup.SchemaOf<UserPasswordChangeFormValues> => {
  const passwordRegExp = new RegExp("^(?=.*[a-zżźćńółęąś])(?=.*[A-ZŻŹĆĄŚĘŁÓŃ])(?=.*[\\d])(?=.*[~!@#$%\\^*\\(\\)\\-_=+\\{\\}\\[\\]\\|:;'\",./\\?€£¥₹])[a-zżźćńółęąśA-ZŻŹĆĄŚĘŁÓŃ\\d~!@#$%\\^*\\(\\)\\-_=+\\{\\}\\[\\]\\|:;'\",./\\?€£¥₹]{8,}$")

  return yup.object().shape({
    oldPlainPassword: yup.string().required(i18next.t("errors:required")),
    plainPassword: yup.string()
      .required(i18next.t("errors:required"))
      .matches(passwordRegExp, i18next.t("user:plainPasswordRequirements")),
    plainPasswordRepeat: yup.string()
      .required(i18next.t("errors:required"))
      .test("password match", i18next.t("errors:passwordMatchError"), (plainPasswordRepeat, testContext) => {
        return plainPasswordRepeat === testContext.parent.plainPassword
      }),
  })
}
