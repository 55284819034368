import React, { FC } from "react"
import {useTranslation} from "react-i18next"
import {Box,Typography, useMediaQuery, useTheme} from "@material-ui/core"

import RecommendationSectionWrapper from "../recommendationsSectionWrapper/RecommendationsSectionWrapper.component"
import {removeOrphansHTML} from "../../../utils/removeOrphansHTML"

interface GeneralRecommendationProps {
  general?: string,
}

const GeneralRecommendation: FC<GeneralRecommendationProps> = ({general}) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <RecommendationSectionWrapper titleName="extraInformations">
      <Box mb={ isSmUp ? 0 : 3 }>
        { (general)
          ? (
            <Typography
              color="textSecondary"
              variant="body1"
              className="break-word"
              dangerouslySetInnerHTML={{__html: removeOrphansHTML(general)}}
            />
          )
          : (
            <Typography
              color="textSecondary"
              variant="body1"
              className="break-word"
            >
              { t("recommendations:noRecommendationDescription") }
            </Typography>
          )
        }
      </Box>
    </RecommendationSectionWrapper>
  )
}

export default GeneralRecommendation
