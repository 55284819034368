import React, {FC, useEffect} from "react"
import {useTranslation} from "react-i18next"
import {Button, Grid, Link,Typography} from "@material-ui/core"

import pharmacy from "../../../assets/images/pharmacy.png"
import visit from "../../../assets/images/visit.png"
import { useAppSelector } from "../../../hooks/storeHooks"
import { analytics, LogEventType } from "../../../services/analytics"
import {useCreateOrderByEanMutation} from "../../../store/doz/doz.api"
import {selectSelectedPharmacy} from "../../../store/dozPharmacy/dozPharmacy.selectors"
import PickupSection from "./pickupSection/PickupSection.component"
import {useCreateOpenHours} from "../pharmacySearchEngine/pharmacyRadioButton/PharmacyRadioButton.utils"
import {useThankYouPageViewStyles} from "./ThankYouPageView.styles"

interface ThankYouPageViewProps {
  fixedCacheKey: string
}

const ThankYouPageView: FC<ThankYouPageViewProps> = ({ fixedCacheKey }) => {
  const {t} = useTranslation()
  const classes = useThankYouPageViewStyles()
  const [, orderByEanData] = useCreateOrderByEanMutation({
    fixedCacheKey,
  })
  const orderData = orderByEanData?.data?.data
  const {
    name,
    street,
    hours_week,
    hours_saturday,
    hours_sunday,
    postcode,
    city,
    all_day,
    gps_latitude,
    gps_longitude,

  } = useAppSelector(selectSelectedPharmacy)

  const formattedPostCode = postcode.replace(/^(\d{2})(\d{3})/, "$1-$2")
  const openHours = useCreateOpenHours(all_day, hours_week, hours_saturday, hours_sunday, true)

  useEffect(() => analytics.sendEventWithDefaultParams(LogEventType.DOZ_THANK_YOU_PAGE_LOAD), [])

  return (
    <Grid
      className={classes.root}
      container
    >
      <Grid
        className={classes.leftColumn}
        item
        xs={12}
        sm={6}
      >
        <Typography
          variant="h2"
        >
          {t("orderMedicines:thankYouHeader")}
        </Typography>
        <Grid
          className={classes.orderNumberContainer}
          container
        >
          <Typography
            variant="h3"
            color="primary"
          >
            {t("orderMedicines:orderNumber")}:
          </Typography>
          <Typography
            className={classes.orderNumber}
            variant="h3"
            color="primary"
          >
            {orderData?.order_id}
          </Typography>
          <Grid
            className={classes.visitConfirmedIcon}
            container
          >
            <Typography
              variant="body1"
            >
              {t("orderMedicines:orderInstructions")}
            </Typography>
            <Typography
              variant="body1"
              className={classes.statusInfo}
            >
              {t("orderMedicines:orderStatusInfo")}
            </Typography>
          </Grid>
          <Link
            color="primary"
            underline="none"
            href={orderData?.order_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              color="primary"
              variant="outlined"
              className={classes.goToDozButton}
              onClick={() => analytics.sendEventWithDefaultParams(LogEventType.DOZ_REDIRECT_BUTTON_CLICK)}
            >
              {t("orderMedicines:goToOrder")}
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid
        className={classes.rightColumn}
        item
        xs={12}
        sm={6}
      >
        <PickupSection
          title={t("consultation:stationaryDetailsView:when")}
          labels={
            [
              t("orderMedicines:prescriptionValidUntil"),
            ]
          }
          image={visit}
          imagePath={"visit.png"}
        />
        <PickupSection
          title={t("consultation:stationaryDetailsView:where")}
          labels={
            [
              name,
              `${t("user:street")} ${street}, ${formattedPostCode} ${city}`,
              openHours[0],
              openHours[1],
              openHours[2],
            ]
          }
          image={pharmacy}
          imagePath={"pharmacy.png"}
          seeOnMapButton={true}
          coords={{
            gps_latitude,
            gps_longitude
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ThankYouPageView
