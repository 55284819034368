import React, {FC} from "react"
import {Box, Typography, useTheme} from "@material-ui/core"

import UserAvatarComponent from "../../components/user/userAvatar/UserAvatar.component"

import {useSwitchAccountPageStyles} from "./SwitchAccountPage.style"

interface SwitchAccountButtonProps {
  name:string;
  surname:string;
  isCurrentlyLoggedIn?:boolean;
  highlightCurrentAccount?: boolean;
  onClickHandler?: React.MouseEventHandler<HTMLButtonElement>;
}

const SwitchAccountButton: FC<SwitchAccountButtonProps> = ({name,isCurrentlyLoggedIn, highlightCurrentAccount = true, surname, onClickHandler}) => {
  const classes = useSwitchAccountPageStyles()
  const buttonClasses = highlightCurrentAccount && isCurrentlyLoggedIn
    ? classes.highlightedSwitchAccountButton
    : classes.switchAccountButton
  const theme = useTheme()

  return (
    <Box
      display="flex"
      onClick={onClickHandler}
      component={"a"}
      alignItems={"center"}
      className={buttonClasses}
      py={1}
      px={1}
      borderRadius={ theme.shape.borderRadius }
    >
      <UserAvatarComponent
        name={name}
        surname={surname}
        size={8}
      />
      <Box ml={2.5}>
        <Typography variant="h5">
          {name} {surname}
        </Typography>
      </Box>
    </Box>
  )
}

export default SwitchAccountButton