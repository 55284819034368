import React, {FC, useEffect, useState} from "react"
import {useTranslation } from "react-i18next"
import {useSelector} from "react-redux"
import {RouteComponentProps, useParams} from "react-router"
import {Box, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import {Elements} from "@stripe/react-stripe-js"
import {loadStripe} from "@stripe/stripe-js"
import { CancelTokenSource } from "axios"
import {push} from "connected-react-router"
import {parseISO} from "date-fns"

import api, { ApiInstance } from "../../api/api"
import {
  getConsultationConfig,
  getDoctorDataConfig,
  postCheckPriceConfig,
} from "../../api/routes"
import doctorCheckData from "../../assets/images/doctor-check-data.png"
import time from "../../assets/images/time.png"
import store from "../../store"
import {selectClinicSettings} from "../../store/clinic/clinicSettings.selectors"
import {selectToken} from "../../store/session/session.selectors"
import { selectUserFirstName, selectUserLastName } from "../../store/user/user.selectors"
import ConsultationsLoader from "../../components/common/lottieAnimations/animations/ConsultationsLoader.component"
import SafeDataInfoLabel from "../../components/common/safeDataInfoLabel/SafeDataInfoLabel.component"
import CardDetailsForm from "../../components/consultation/cardDetailsForm/CardDetailsForm.component"
import {stripeInputFont} from "../../components/consultation/cardDetailsForm/CardDetailsForm.utils"
import {
  ConsultationModel,
  ConsultationStatusNumber,
  ConsultationStripePaymentGateway,
} from "../../components/consultation/Consultation.types"
import {getConsultationTypeShortText} from "../../components/consultation/Consultation.utils"
import ConsultationPaymentDoctorDetails from "../../components/doctor/doctorDetails/ConsultationPaymentDoctorDetails.component"
import ImageByIntegrationType from "../../components/imageByIntegrationType/ImageByIntegrationType"
import UserAvatarComponent from "../../components/user/userAvatar/UserAvatar.component"
import {StripeElementLocale} from "@stripe/stripe-js/types/stripe-js/elements-group"
import {useConsultationPaymentPageStyles} from "./ConsultationPaymentPage.styles"

interface ConsultationPaymentPageParams {
  consultationId: string;
}

const ConsultationPaymentPage: FC<RouteComponentProps<ConsultationPaymentPageParams>> = () => {
  const {consultationId} = useParams<ConsultationPaymentPageParams>()
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const classes = useConsultationPaymentPageStyles({showFormErrors: false})
  const clinicSettings = useSelector(selectClinicSettings)
  const [loading, setLoading] = useState(true)
  const [consultationData, setConsultationData] = useState<ConsultationModel>()
  const [doctorGrade, setDoctorGrade] = useState(0)
  const tokenData = useSelector(selectToken)
  const firstName = useSelector(selectUserFirstName)
  const lastName = useSelector(selectUserLastName)
  const visitAt = parseISO(consultationData?.visit_at || "")
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const consultationStatus = consultationData?.status_number
  const paymentAvailable = (!loading && consultationStatus && !consultationData?.is_payed && consultationData?.paymentGateway === ConsultationStripePaymentGateway.STRIPE) && (
    consultationStatus === ConsultationStatusNumber.CREATED ||
    consultationStatus === ConsultationStatusNumber.RESERVED
  )
  const [cost, setCost] = useState<string|number>(0)
  const [currency, setCurrency] = useState<string|null>(null)
  const stationaryVisitAddress = consultationData?.stationaryVisitAddress

  const getConsultationData = async (cancelToken?: CancelTokenSource["token"]) => {
    try {
      const {data} = await api.request<ConsultationModel>({
        ...getConsultationConfig(consultationId),
        cancelToken,
      })

      const {data: {grade}} = await api.request({
        ...getDoctorDataConfig(data?.doctorId),
      })

      const consultationPaymentData = await api.request({
        ...postCheckPriceConfig,
        data: {
          patientId: data?.patientId,
          specializationId: data?.specialization?.id,
          doctorId: data?.doctorId,
          directContact: false,
        }
      })

      const currencyData = consultationPaymentData?.data?.currencyTable?.current
      const currency =  currencyData ? Object.keys(currencyData)[0] : null
      const cost = currency ? currencyData?.[currency] : null

      setCost(cost)
      setCurrency(currency)
      setConsultationData(data)
      setDoctorGrade(grade)

    } catch (e) {
      if (api.isCancel(e)) return
      console.error(e)
    }

    setLoading(false)
  }

  const stripePromise = loadStripe(clinicSettings?.stripePublicKey, {locale: i18n.language as StripeElementLocale})

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()

    if (tokenData && !consultationData) {
      getConsultationData(requestSource.token)
    }

    return () => {
      setLoading(false)
      requestSource.cancel("Request interrupted by page change")
    }
  }, [consultationId, tokenData, consultationData])

  if (loading || !consultationData) {
    return (
      <Box className="page-loader-box">
        <ConsultationsLoader/>
      </Box>
    )
  }

  if (consultationStatus === ConsultationStatusNumber.CANCELED) {
    store.dispatch(push(`/${i18n.language}`))

    return null
  }

  if (!paymentAvailable) { // consultation is already paid or has started/finished status
    store.dispatch(push(`/${i18n.language}/403`))
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{fonts: stripeInputFont}}
    >
      <Box
        className={classes.pageSection}
        component="section"
      >
        <Box
          width={isMdUp ? "50%" : "100%"}
          className={classes.userData}
        >
          <Box className={classes.content}>
            <Typography
              variant="h3"
              className={classes.header}
            >
              {t("consultation:yourData")}
            </Typography>

            <Box
              display="flex"
            >
              <UserAvatarComponent
                name={firstName}
                surname={lastName}
              />
              <Box ml={2.5} mt={1.5}>
                <Typography variant="h5">
                  {firstName} {lastName}
                </Typography>
              </Box>
            </Box>
            
            <ImageByIntegrationType
              imageSrc={doctorCheckData}
              alt="doctorCheckData"
              className={classes.doctorImage}
              imagePath={"doctor-check-data.png"}
            />

            <SafeDataInfoLabel/>
          </Box>
        </Box>
        <Box
          width={isMdUp ? "50%" : "100%"}
          mt={isMdUp ? 0 : 3}
        >
          <Typography variant="h4">
            {t("consultation:yourVisit")}
          </Typography>

          <Box
            display="flex"
          >
            <Box>
              <ImageByIntegrationType
                imageSrc={time}
                alt="Time"
                className={classes.timeImage}
                imagePath={"time.png"}
              />
            </Box>
            <Box
              ml={2}
              mt={3}
            >
              <Typography variant="subtitle1">
                {t("dates:consultationFullDate", { date: visitAt })}
              </Typography>

              <Typography variant="subtitle1">
                {t(`consultation:${getConsultationTypeShortText(consultationData.consultationType, !!stationaryVisitAddress)}`)}
              </Typography>
            </Box>
          </Box>

          <Typography variant="h4">
            {t("consultation:stationaryDetailsView:yourDoctor")}
          </Typography>

          <ConsultationPaymentDoctorDetails
            doctorImage={consultationData?.doctor.avatar}
            doctorSpecialization={consultationData?.specialization.name}
            doctorTitle={consultationData?.doctorNameWithDegree}
            doctorGrade={doctorGrade}
          />

          {
            currency && cost && (
              <CardDetailsForm // payment card details form
                cost={cost}
                currency={currency}
                consultationId={consultationId}
              />
            )
          }
        </Box>
      </Box>
    </Elements>
  )
}

export default ConsultationPaymentPage
