import React, {FC} from "react"
import {Prompt} from "react-router-dom"
import {Box, Typography, useMediaQuery} from "@material-ui/core"

import PageSection from "../../../../components/common/pageSection/PageSection.component"
import NavigationArrow from "../navigationArrow/NavigationArrow.component"
import {RoutePath} from "../../../../routes/Routes.types"
import {Theme} from "@material-ui/core/styles"

type PageWrapperProps = {
  navigateBackTo?: RoutePath;
  title?: string;
  promptMessage?: string;
}

const PageWrapper: FC<PageWrapperProps> = ({ 
  children,
  promptMessage,
  title,
  navigateBackTo = RoutePath.PREVENTION
}) => {
  const isSmUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"))

  return (
    <PageSection
      titleNode={
        <Box
          display="flex"
          flexDirection="row"
        >
          <NavigationArrow
            navigateTo={navigateBackTo}
          />
          {
            isSmUp && title && (
              <Typography
                variant="h2"
                component="h2"
              >
                {title}
              </Typography>
            )
          }
        </Box>
      }
    >
      {
        promptMessage && (
          <Prompt message={promptMessage}/>
        )
      }
      {children}
    </PageSection>
  )
}

export default PageWrapper