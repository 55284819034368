import { createStyles, makeStyles } from "@material-ui/core"

import { Theme } from "@material-ui/core/styles"

export const useUserContactDataEditModalStyles = makeStyles((theme: Theme) => createStyles({
  dialogPaperWidth: {
    [theme.breakpoints.up("sm")]: {
      width: theme.breakpoints.values.sm,
    }
  }
}))