import { createStyles, makeStyles } from "@material-ui/core"

import { boxShadows } from "../../../../themes/shadows"
import { Theme } from "@material-ui/core/styles"

export const useResourcesTileStyles = makeStyles((theme: Theme) => createStyles({
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    padding: theme.spacing(3),
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "338px",
    width: "100%",
    height: "100%",
    background: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      height: "auto",
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  contentWrapper: {
    display: "flex",
    rowGap: theme.spacing(2),
    flexWrap: "wrap",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(1),
  },
  personalGrowthTile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: "100%",
    minHeight: "125px",
    borderRadius: theme.shape.buttonBorderRadius,
    border: "none",
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    boxShadow: boxShadows.shadow5,
    color: theme.palette.text.secondary,
  },
  personalGrowthImageContainer: {
    display:"flex",
    flexDirection:"column",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "35%",
  },
  personalGrowthDescriptionContainer: {
    display:"flex",
    flexDirection:"column",
    alignItems: "flex-end",
    justifyContent:"space-between",
    "& button":{
      maxHeight: "32px",
    }
  }
}))
