import {alpha, makeStyles} from "@material-ui/core"

import { boxShadows } from "../../../themes/shadows"
import {createStyles, Theme} from "@material-ui/core/styles"

export const useAddToCalendarStyles = makeStyles((theme: Theme) => createStyles({
  backdrop: {
    backgroundColor: `${alpha(theme.palette.background.default, .8)} !important`,
    [theme.breakpoints.up("md")]: {
      backgroundColor: "transparent !important"
    },
  },
  popoverPaper: {
    boxShadow: boxShadows.shadow4,
    borderRadius: theme.shape.borderRadius,
    width: theme.spacing(51),
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: theme.spacing(2, 3),
    borderRadius: "0",
    transition: "background-color 0.3s ease-in-out",
    overflow: "hidden",
    ...theme.typography.h4,
    "&:hover, &:focus": {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
}))
