import React, {FC} from "react"
import {useTranslation} from "react-i18next"

import LoaderBox from "../../../../components/common/loaderBox/LoaderBox.component"
import PageWrapper from "../../components/pageWrapper/PageWrapper.component"
import ThankYouPageQuestionnaire
  from "../../components/thankYouPageQuestionnaire/ThankYouPageQuestionnaire.component"
import GenericStepForm from "../preventionQuestionnaire/genericStepForm/GenericStepForm.component"
import {usePreventionDiseasesQuestionnaire} from "./PreventionDiseasesQuestionnaire.utils"

const PreventionDiseasesQuestionnaire: FC = () => {
  const { t } = useTranslation()
  const {
    template,
    isLoading,
    questionnaireId,
    handleSubmit,
    isSubmittingAnswer,
    isSuccess
  } = usePreventionDiseasesQuestionnaire()

  if (isLoading || !template) {
    return (
      <LoaderBox
        boxType="appDialog"
      />
    )
  }

  if (!isSubmittingAnswer && isSuccess) {
    return (
      <ThankYouPageQuestionnaire
        questionnaireId={questionnaireId ?? undefined}
      />
    )
  }
  
  return (
    <PageWrapper
      promptMessage={t("healthPlanner:leaveQuestionnairePrompt")}
    >
      <GenericStepForm
        questionnaireTemplate={template}
        onSubmit={handleSubmit}
        isLoading={isSubmittingAnswer}
      />
    </PageWrapper>
  )
}

export default PreventionDiseasesQuestionnaire
