import {createSlice, PayloadAction} from "@reduxjs/toolkit"

import {Gender, HealthPlannerState} from "./healthPlanner.types"

const initialState: HealthPlannerState = {
  gender: null
}

export const healthPlannerSlice = createSlice({
  name: "healthPlanner",
  initialState,
  reducers: {
    setGender: (state, action: PayloadAction<Gender>) => {
      state.gender = action.payload
    },
  },
})

export const {
  setGender
} = healthPlannerSlice.actions

export default healthPlannerSlice.reducer
