import {googleApisService} from "../store.service"
import {GeoLocationResponse, GetGeolocationPayload, Location} from "./geolocation.types"

export const geolocationApi = googleApisService.injectEndpoints({
  endpoints: build => ({
    getCoordinates: build.query<Location[], GetGeolocationPayload>({
      query: ({
        city = "",
        street = "",
        postalCode = "",
        houseNumber = ""
      }) => ({
        url: "/maps/api/geocode/json",
        method: "GET",
        params: {
          address: `${city ?? ""} ${street ?? ""} ${houseNumber ?? ""} ${postalCode ?? ""}`,
          language: "pl",
          region: "pl",
        }
      }),
      transformResponse: (response: GeoLocationResponse) => response.results.map(result => ({
        place_id: result.place_id,
        lat: result.geometry.location.lat,
        lon: result.geometry.location.lng,
        display_name: result.formatted_address
      }))
    }),
  })
})

export const { useGetCoordinatesQuery } = geolocationApi
