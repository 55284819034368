import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {CircularProgress, Grid, Typography} from "@material-ui/core"

import {useNearbyPharmaciesLoaderStyles} from "./NearbyPharmaciesLoader.styles"

interface NearbyPharmaciesLoaderProps {}

const NearbyPharmaciesLoader: FC<NearbyPharmaciesLoaderProps> = () => {
  const {t} = useTranslation()
  const classes = useNearbyPharmaciesLoaderStyles()

  return (
    <Grid
      className={classes.root}
      container
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress/>
      <Typography
        className={classes.label}
      >
        {t("orderMedicines:checkingAvailabilityLabel")}
      </Typography>
    </Grid>
  )
}

export default NearbyPharmaciesLoader