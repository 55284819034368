import { createStyles, makeStyles, Theme } from "@material-ui/core"

import { colors } from "../../../../themes/colors"

export const useMoodTileStyles = makeStyles((theme: Theme) => createStyles({
  boxCard: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "338px",
    width: "100%",
    height: "100%",
    padding: theme.spacing(3),
    background: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      height: "auto",
    },
  },
  averageMoodLabel: {
    fontSize: "10px",
    marginBottom: theme.spacing(1)
  },
  moodIcon: {
    cursor: "pointer"
  },
  arcProgressWrapper: {
    position: "relative",
    alignSelf: "center",
    marginTop: theme.spacing(3),
    maxHeight: "130px",
  },
  arcProgressWrapperRotated: {
    position: "relative",
    transform: "rotate(-90deg)",
    width: "200px",
  },
  bottomArc: {
    position: "relative",
    color: colors.telemediGray12,
  },
  topArc: {
    animationDuration: "550ms",
    position: "absolute",
    top: 0,
    left: 0,
  },
  averageMoodLabelContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    display: "flex",
    alignItems: "center",
    flexDirection:"column"
  },
  ratingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    rowGap: theme.spacing(1),
    marginTop: theme.spacing(1),
    zIndex: 100
  },
  facesContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width:"100%"
  },
  infoLabel: {
    marginTop: 0,
    textAlign: "center"
  }
}))
