import React, {FC} from "react"
import {Box, IconButton, Typography} from "@material-ui/core"

import {ReactComponent as TrashIcon} from "../../../../assets/svg/trash.svg"
import ImageByIntegrationType from "../../../imageByIntegrationType/ImageByIntegrationType"
import {useExaminationStyles} from "./Examination.styles"

interface ExaminationProps {
  name: string;
  onRemove: VoidFunction;
  isDisabledRemove: boolean;
}

const Examination: FC<ExaminationProps> = ({ name, onRemove, isDisabledRemove }) => {
  const classes = useExaminationStyles({ isDisabledRemove })

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Typography
        className={classes.bold}
        variant="body1"
      >
        {name}
      </Typography>
      <IconButton
        className={classes.removeButton}
        size="small"
        onClick={onRemove}
        disabled={isDisabledRemove}
      >
        <ImageByIntegrationType
          imageSrc={<TrashIcon/>}
          imagePath={"trash.svg"}
          returnSvg={true}
        />
      </IconButton>
    </Box>
  )
}

export default Examination
