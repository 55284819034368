import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import {RouteComponentProps, useHistory} from "react-router"
import {Box, Button, Link, Typography, useMediaQuery, useTheme} from "@material-ui/core"

import doctorImage from "../../assets/images/doctor-check-data.png"
import ImageByIntegrationType from "../../components/imageByIntegrationType/ImageByIntegrationType"
import { usePossibilitiesPageStyles } from "./PossibilitiesPage.styles"

interface PossibilitiesPageParams {}

const PossibilitiesPage: FC<RouteComponentProps<PossibilitiesPageParams>> = () => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const classes = usePossibilitiesPageStyles()
  const {push} = useHistory()

  const goToHomePage = () => {
    push(`/${i18n.language}`)
  }

  const imageBox = (
    <Box className={classes.imageWrapper}>
      <ImageByIntegrationType
        imageSrc={doctorImage}
        alt="doctorImage"
        className={classes.image}
        imagePath={"doctor-check-data.png"}
      />
    </Box>
  )

  return (
    <Box
      className={classes.pageSection}
      component="section"
    >
      <Box className={classes.content}>
        <Box mb={3}>
          <Typography
            variant={isMdUp ? "h2" : "h3"}
            component="h2"
          >
            {t("user:possibilitiesTitle")}
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography
            variant={isMdUp ? "h3" : "h5"}
            component="span"
            color="textSecondary"
          >
            {t("user:possibilitiesSubtitle")}
          </Typography>
        </Box>

        <Box mb={3}>
          <Link
            href={`tel:${t("serviceDeskPhone")}`}
          >
            <Typography
              variant={isMdUp ? "h3" : "h4"}
              component="span">
              {t("serviceDeskPhone")}
            </Typography>
          </Link>
        </Box>

        {!isMdUp && imageBox}

        <Box mb={8}>
          <Typography
            variant={isMdUp ? "h4" : "body2"}
            color="textSecondary"
          >
            {t("user:possibilitiesDescription")}
          </Typography>
        </Box>

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={goToHomePage}
        >
          {t("goBack")}
        </Button>
      </Box>

      {isMdUp && imageBox}
    </Box>
  )
}

export default PossibilitiesPage
