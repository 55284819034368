import React, {FC} from "react"
import {Box} from "@material-ui/core"
import clsx from "clsx"

import DiseaseTile, {DiseaseTileProps} from "../diseaseTile/DiseaseTile.component"
import {useDiseasesList} from "./DiseasesList.styles"

type DiseasesRiskProps = {
  diseases: DiseaseTileProps[];
  className?: string;
}

const DiseasesLists: FC<DiseasesRiskProps> = ({
  diseases,
  className
}) => {
  const classes = useDiseasesList()

  return (
    <Box className={clsx(classes.diseasesList, className)}>
      {
        diseases.map(diseases => (
          <DiseaseTile
            id={diseases.id}
            key={diseases.id}
            name={diseases.name}
            imageUrl={diseases.imageUrl}
            probability={diseases.probability}
          />
        ))
      }
    </Box>
  )
}

export default DiseasesLists
